import {
	Page,
	Layout,
	Stack,
	ComplexAction,
	MenuGroupDescriptor,
	Loading,
	Frame,
	Card,
} from '@shopify/polaris';
import { useDebouncedValue } from '@shopify/react-hooks';
import {
	ContainerStatus,
	Lane as LaneFulfillmentAPI,
	LaneContainer,
	QueryLaneByIdArgs,
	QueryLaneContainersArgs,
} from '@sixriver/fulfillment-api-schema';
import { useCallback, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { LANE_QUERY } from './Lane.graphql';
import { LaneContainersFilters } from './LaneContainersFilters';
import { LaneContainersTable } from './LaneContainersTable';
import { LANE_CONTAINER_QUERY } from './LaneContainersTable/LaneContainers.graphql';
import { LaneContainersTab, LaneContainersTabs } from './LaneContainersTable/LaneContainersTabs';
import { LaneDetails } from './LaneDetails';
import { AutoRefresh } from 'components/AutoRefresh';
import { DateRangeFilter } from 'components/DateRangeFilter';
import { OptionValues } from 'components/DateRangeSelect';
import { Error } from 'components/Error';
import { ErrorBanner } from 'components/ErrorBanner';
import { LaneStatusBadge } from 'components/LaneStatusBadge/LaneStatusBadge';
import { TimezoneFooter } from 'components/TimezoneFooter';
import { MIN_QUERY_LENGTH } from 'helpers/table';
import { getMidnight } from 'helpers/time';
import { useLocalization } from 'hooks/useLocalization';
import { usePolling } from 'hooks/usePolling';
import { usePollingQuery } from 'hooks/usePollingQuery';
import { useArrayQueryState, useDateQueryState, useQueryState } from 'hooks/useQueryState';

const SEARCH_TEXT_KEY = 'query';
const STATUSES_KEY = 'statuses';
const EXCEPTIONS_KEY = 'exceptions';
const DEFAULT_DATE = getMidnight();
const HISTORY_DATE_LIMITER_KEY = 'historyDateLimiter';

export interface RouteMatchParams {
	laneId: string;
}

export function Lane() {
	const {
		params: { laneId },
	} = useRouteMatch<RouteMatchParams>();

	const [isBannerErrorVisible, setIsBannerErrorVisible] = useState(false);
	const [selectedTab, setSelectedTab] = useState<LaneContainersTab>(LaneContainersTab.all);
	const [searchText, setSearchText] = useQueryState(SEARCH_TEXT_KEY, '');
	const [selectedStatuses, setSelectedStatuses] = useArrayQueryState<ContainerStatus[]>(
		STATUSES_KEY,
		[],
	);
	const [selectedExceptions, setSelectedExceptions] = useArrayQueryState<string[]>(
		EXCEPTIONS_KEY,
		[],
	);
	const [historyDateLimiter, setHistoryDateLimiter] = useDateQueryState(
		HISTORY_DATE_LIMITER_KEY,
		DEFAULT_DATE,
	);

	const { messages } = useLocalization();
	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();
	const debouncedSearchText = useDebouncedValue(searchText);

	/**
	 * Queries
	 */
	const [{ fetching: laneFetching, error: laneError, data: laneData }] = usePollingQuery<
		{ laneById: LaneFulfillmentAPI },
		QueryLaneByIdArgs
	>({
		query: LANE_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			id: decodeURIComponent(laneId),
		},
	});

	const [
		{ fetching: laneContainersFetching, error: laneContainersError, data: laneContainersData },
	] = usePollingQuery<{ laneContainers: LaneContainer[] }, QueryLaneContainersArgs>({
		query: LANE_CONTAINER_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			laneIds: [decodeURIComponent(laneId)],
			containersSearchText:
				debouncedSearchText.length >= MIN_QUERY_LENGTH ? debouncedSearchText : undefined,
			containerStatuses: selectedStatuses,
			hasException: selectedExceptions.includes(messages.isOverridden)
				? true
				: selectedExceptions.includes(messages.isNotOverridden)
				? false
				: null,
			dateCreatedFrom: historyDateLimiter,
		},
	});

	const { laneById } = laneData || {};
	const { laneContainers } = laneContainersData || {};
	const fetching = laneFetching || laneContainersFetching;
	const error = laneError || laneContainersError;

	const secondaryActions: ComplexAction[] = [];
	const actionGroups: MenuGroupDescriptor[] = [];

	const counts = {
		all: laneContainers?.length,
	};

	/**
	 * Filter functions
	 */
	const handleStatusesRemove = useCallback(() => {
		setSelectedStatuses([]);
	}, [setSelectedStatuses]);
	const handleExceptionsRemove = useCallback(() => {
		setSelectedExceptions([]);
	}, [setSelectedExceptions]);
	const handleChangeHistoryLimiter = useCallback(
		(date: Date) => {
			setHistoryDateLimiter(date);
		},
		[setHistoryDateLimiter],
	);

	if (fetching) {
		return (
			<Frame>
				<Loading />
			</Frame>
		);
	}
	if (error) {
		return <Error graphQLError={error} />;
	}

	if (!laneById) {
		return <Error heading={messages.laneNotFound} />;
	}

	return (
		<Page
			title={decodeURIComponent(laneById.laneId)}
			titleMetadata={
				<div data-testid="header">
					<Stack spacing="extraTight">
						<LaneStatusBadge lane={laneById} />
					</Stack>
				</div>
			}
			secondaryActions={secondaryActions}
			actionGroups={actionGroups}
			fullWidth
		>
			<AutoRefresh
				pollingEnabled={pollingEnabled}
				togglePolling={togglePolling}
				discriminatorData={laneContainersData}
			/>
			<Layout>
				<Layout.Section>
					<Stack vertical>
						<ErrorBanner
							isVisible={isBannerErrorVisible}
							onDismiss={() => {
								setIsBannerErrorVisible(false);
							}}
						/>
					</Stack>
				</Layout.Section>

				<Layout.Section>
					<div data-testid="details">
						<LaneDetails lane={laneById} />
					</div>
				</Layout.Section>
				<Layout.Section>
					<Card title={messages.containers}>
						<div style={{ paddingLeft: '2rem', paddingTop: '1rem' }}>
							<DateRangeFilter
								options={[
									OptionValues.today,
									OptionValues.last3Days,
									OptionValues.last7Days,
									OptionValues.last30Days,
									OptionValues.last90Days,
									OptionValues.last180Days,
								]}
								selectedOption={historyDateLimiter}
								onChange={handleChangeHistoryLimiter}
							/>
						</div>
						<div style={{ paddingBottom: '2rem' }}>
							<LaneContainersTabs
								selected={selectedTab}
								onSelect={setSelectedTab}
								counts={counts}
								tabs={[LaneContainersTab.all]}
							/>
						</div>

						<div style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
							<LaneContainersFilters
								queryValue={searchText}
								onQueryChange={(value) => setSearchText(value)}
								selectedStatuses={selectedStatuses}
								onStatusChange={setSelectedStatuses}
								onStatusRemove={handleStatusesRemove}
								selectedExceptions={selectedExceptions}
								onExceptionChange={setSelectedExceptions}
								onExceptionRemove={handleExceptionsRemove}
							/>
						</div>
						<LaneContainersTable loading={laneContainersFetching} data={laneContainers} />
					</Card>
				</Layout.Section>

				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</Page>
	);
}
