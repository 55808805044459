import { Layout, Page } from '@shopify/polaris';
import { StorageLocation } from '@sixriver/fulfillment-api-schema';
import { useRouteMatch } from 'react-router-dom';

import { LocationHealth } from './LocationHealth';
import { LocationHistory } from './LocationHistory';
import { LocationInfo } from './LocationInfo';
import { LocationInventory } from './LocationInventory';
import { LOCATION_QUERY } from './Locations.graphql';
import { AutoRefresh } from 'components/AutoRefresh';
import { Error } from 'components/Error';
import { useConfig } from 'hooks/useConfig';
import { useLocalization } from 'hooks/useLocalization';
import { usePolling } from 'hooks/usePolling';
import { usePollingQuery } from 'hooks/usePollingQuery';

export function Location() {
	const { messages } = useLocalization();
	const { config } = useConfig();

	// Routing
	const {
		params: { locationId },
	} = useRouteMatch<{ locationId: string }>();

	// Polling
	const { pollingEnabled, queryPollInterval, togglePolling } = usePolling();

	// Queries
	const [{ error: queryLocationError, data, fetching }] = usePollingQuery<
		{ location: StorageLocation },
		{ id: string }
	>({
		query: LOCATION_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			id: locationId,
		},
	});

	if (queryLocationError) {
		return <Error graphQLError={queryLocationError} />;
	}

	// Computations
	const location = data?.location;

	if (fetching) {
		return null;
	}

	if (!location) {
		return <Error message={messages.locationNotFound} />;
	}

	const isInventorySectionShown = (location?.products.length || 0) > 0;

	// Render
	return (
		<Page
			fullWidth
			title={location.address}
			subtitle={location.type ? messages.locationTypes[location.type] : '—'}
			additionalNavigation={
				<AutoRefresh
					discriminatorData={data}
					pollingEnabled={pollingEnabled}
					togglePolling={togglePolling}
				/>
			}
		>
			<Layout>
				{config?.inventoryEnabled && (
					<Layout.Section>
						{isInventorySectionShown && (
							<>
								<LocationInventory location={location} />
								<br />
							</>
						)}
						<LocationHistory
							locationId={location.id}
							productsInventory={location.products}
							queryPollInterval={queryPollInterval}
						/>
					</Layout.Section>
				)}
				<Layout.Section secondary>
					{config?.inventoryEnabled && <LocationHealth location={location} />}
					<LocationInfo
						location={location}
						wideList={config?.inventoryEnabled}
						isWorkAreaEnabled={config?.workAreasEnabled}
					/>
				</Layout.Section>
			</Layout>
		</Page>
	);
}
