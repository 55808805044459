import { Banner, Card, Heading, Stack, TextField } from '@shopify/polaris';
import { MapPointConfig } from '@sixriver/fulfillment-api-schema';
import { MapStack } from '@sixriver/map-io';
import { useEffect, useState } from 'react';

import { useMapPointMutation } from './FloorView.graphql';
import styles from './FloorView.module.css';
import { Toggle } from 'components/Toggle';
import { useConfig } from 'hooks/useConfig';
import { useLocalization } from 'hooks/useLocalization';
import { useToast } from 'hooks/useToast';
import closeImg from 'images/close.svg';

interface Props {
	selectedId?: string;
	mapStack: MapStack;
	mapPointConfigs: MapPointConfig[];
	onClose(updated?: boolean): void;
}

function getPoint(
	mapStack: MapStack,
	mapPointConfigs: MapPointConfig[],
	id?: string,
): MapPointConfig | undefined {
	if (id) {
		const features = mapStack.workflowPoints.features;
		const feature = features.find((feature: any) => {
			return feature.properties.id === id;
		});

		return mapPointConfigs.find((cfg) => cfg.mapPointName === feature?.properties.name);
	}

	return undefined;
}

export function WorkflowPoint({ selectedId, mapStack, mapPointConfigs, onClose }: Props) {
	const { messages, translate } = useLocalization();
	const { config } = useConfig();
	const { showToast } = useToast();
	const { mutate } = useMapPointMutation();
	const point = getPoint(mapStack, mapPointConfigs, selectedId);
	const [input, setInput] = useState(point);

	useEffect(() => {
		setInput(point);
	}, [point]);

	if (!input) {
		return (
			<div className={styles.form}>
				<Banner status="critical">{messages.unknownError}</Banner>
			</div>
		);
	}

	const onSave = async () => {
		delete input.__typename;

		const response = await mutate({ input });
		const success = response.data?.editMapPointConfig.success;

		showToast(
			success
				? (translate(messages.dataUpdated, { name: input.mapPointName }) as string)
				: messages.dataNotSaved,
			!success,
		);

		if (success) {
			onClose(true);
		}
	};

	const disabled = !config?.mapPointStatusEditable && !config?.mapPointQueueDepthEditable;

	return (
		<div className={styles.form}>
			<Card
				sectioned
				primaryFooterAction={{
					content: messages.save,
					onAction: onSave,
					disabled,
				}}
				secondaryFooterActions={[
					{
						content: messages.close,
						onAction: () => onClose(false),
						disabled,
					},
				]}
			>
				<Stack vertical>
					<Stack distribution="equalSpacing" alignment="center">
						<Heading>{input.mapPointName}</Heading>
						<button className={styles.close} onClick={() => onClose(false)}>
							<img src={closeImg} width="16" height="16" alt={messages.close} />
						</button>
					</Stack>
					<Toggle
						disabled={!config?.mapPointStatusEditable}
						checked={input.enabled}
						label={input.enabled ? messages.workflowPointEnabled : messages.workflowPointDisabled}
						onCheck={(value) => setInput({ ...input, enabled: value })}
					/>
					<TextField
						autoComplete="off"
						disabled={!config?.mapPointQueueDepthEditable}
						label={messages.chuckQueue}
						value={input.maxReservationCount.toString()}
						type="number"
						maxLength={3}
						onChange={(value) => setInput({ ...input, maxReservationCount: parseInt(value) })}
					/>
				</Stack>
			</Card>
		</div>
	);
}
