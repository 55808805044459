import { Link } from '@shopify/polaris';
import {
	InventoryAdjustedEvent,
	InventoryAmnestyPutEvent,
	InventoryConflictReason,
	InventoryInspectedEvent,
	InventoryMovedEvent,
	InventoryPickedEvent,
	InventoryReplenishedEvent,
	OrderCancelledEvent,
	OrderCompletedEvent,
	OrderCreatedEvent,
	PackoutCompletedEvent,
	PackoutInductedEvent,
	PickingCancelledEvent,
	PickingCompletedEvent,
	PickingCreatedEvent,
	PickingInductedEvent,
	PickingPickedEvent,
	SortationPickedEvent,
	SortationSortedEvent,
} from '@sixriver/fulfillment-api-schema';

import { TimelineEvent } from './Timeline';
import { getMfpUrl } from 'helpers/mfp';
import { useConfig } from 'hooks/useConfig';
import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';

type InventoryEventType =
	| InventoryPickedEvent
	| InventoryReplenishedEvent
	| InventoryMovedEvent
	| InventoryInspectedEvent
	| InventoryAdjustedEvent
	| InventoryAmnestyPutEvent;

type WorkflowEventType =
	| OrderCreatedEvent
	| OrderCancelledEvent
	| OrderCompletedEvent
	| PickingCreatedEvent
	| PickingInductedEvent
	| PickingPickedEvent
	| PickingCompletedEvent
	| PickingCancelledEvent
	| PackoutInductedEvent
	| PackoutCompletedEvent
	| SortationPickedEvent
	| SortationSortedEvent;

const ChuckLink = (props: { deviceId?: string | null }) => {
	if (props.deviceId) {
		return <Link url={getMfpUrl(props.deviceId)}>{props.deviceId}</Link>;
	}

	return <span>{'—'}</span>;
};

const ProductLink = (props: { id?: string | null; externalId?: string | null }) => {
	if (props.id && props.externalId) {
		return <Link url={routes.product(props.id)}>{props.externalId}</Link>;
	}

	return <span>{props.externalId || '—'}</span>;
};

const LicensePlateLink = (props: { lpn?: string | null; pickingProjectionId?: string | null }) => {
	if (props.lpn && props.pickingProjectionId) {
		return <Link url={routes.outboundJob(props.pickingProjectionId)}>{props.lpn}</Link>;
	}

	return <span>{props.lpn || '–'}</span>;
};

const LocationLink = (props: { id?: string | null; address?: string | null }) => {
	if (props.id && props.address) {
		return <Link url={routes.location(props.id)}>{props.address}</Link>;
	}

	return <span>{props.address || '—'}</span>;
};

export const useWorkflowEvents = (
	events: WorkflowEventType[],
	mode: 'JOB' | 'ORDER',
	isSortation = false, // TODO this should be a property of the event
): TimelineEvent[] => {
	const { messages, translate } = useLocalization();
	const { config } = useConfig();

	const getExceptionReason = (reason?: string) => {
		if (reason?.includes('DAMAGED_PRODUCT')) {
			return messages.exceptionReasons.damaged;
		} else if (reason?.includes('MISSING_PRODUCT')) {
			return messages.exceptionReasons.missing;
		} else if (reason?.includes('TOTE_TOO_FULL')) {
			return messages.exceptionReasons.full;
		} else if (reason?.includes('MFP_OFFLINE')) {
			return messages.exceptionReasons.offline;
		}

		return messages.exceptionReasons.otherReason;
	};

	return (events || [])
		.map((event) => {
			const id = event.timestamp;
			const timestamp = new Date(event.timestamp);

			switch (event.__typename) {
				case 'OrderCreatedEvent':
					return {
						id,
						timestamp,
						title: isSortation ? messages.orderAccepted : messages.orderReceived,
					};

				case 'OrderCancelledEvent':
					return {
						id,
						timestamp,
						title: messages.orderCancelled,
					};

				case 'OrderCompletedEvent':
					return {
						id,
						timestamp,
						title: messages.orderCompleted,
					};

				case 'PickingCreatedEvent':
					return mode === 'JOB'
						? {
								id,
								timestamp,
								title: messages.jobCreated,
						  }
						: undefined;

				case 'PickingInductedEvent': {
					const msg = event.isHealing ? messages.resolutionJobAssigned : messages.jobAssigned;

					return {
						id,
						timestamp,
						variation: event.isHealing ? 'positive' : undefined,
						title: translate(msg, {
							jobId: (
								<LicensePlateLink
									lpn={event.externalContainerId}
									pickingProjectionId={event.projectionId}
								/>
							),
						}),
						details: [
							...(event.isHealing
								? [
										{
											label: messages.originalJob,
											value: event.healedJobExternalContainerId,
										},
								  ]
								: []),
							{
								label: messages.chuck,
								value: <ChuckLink deviceId={event.deviceId} />,
							},
							{
								label: messages.associate,
								value: event.user?.name || '—',
							},
						],
					};
				}

				case 'PickingPickedEvent': {
					let msg = event.isHealing ? messages.itemRepicked : messages.itemPicked;

					if (event.exception) {
						msg = event.isHealing
							? messages.itemRepickedWithException
							: messages.itemPickedWithException;
					}

					return {
						id,
						timestamp,
						variation: event.exception ? 'negative' : event.isHealing ? 'positive' : undefined,
						title: translate(msg, {
							externalId: <ProductLink id={event.productId} externalId={event.productExternalId} />,
							address: (
								<LocationLink address={event.storageLocationAddress} id={event.storageLocationId} />
							),
						}),
						actionButton:
							event.exception && event.orderProjectionId && config?.healingEnabled
								? {
										content: messages.manageExceptions,
										url: routes.exception(event.orderProjectionId),
								  }
								: undefined,
						details: [
							...(event.exception
								? [
										{
											label: messages.exceptionReason,
											value: getExceptionReason(event.exception),
										},
										{
											label: messages.unitsWithException,
											value: event.quantity - event.doneQuantity,
										},
								  ]
								: []),
							{
								label: messages.unitsPicked,
								value: translate(messages.xOfY, {
									x: event.doneQuantity,
									y: event.quantity,
								}),
							},
							{
								label: messages.licensePlate,
								value: (
									<LicensePlateLink
										lpn={event.externalContainerId}
										pickingProjectionId={event.projectionId}
									/>
								),
							},
							{
								label: messages.chuck,
								value: <ChuckLink deviceId={event.deviceId} />,
							},
							{
								label: messages.associate,
								value: event.user?.name || '—',
							},
						],
					};
				}

				case 'PickingCompletedEvent':
					return {
						id,
						timestamp,
						title: translate(messages.toteRemoved, {
							takeoffDestination: event.takeoffDestination || '—',
						}),
						details: [
							{
								label: messages.associate,
								value: event.user?.name || '—',
							},
						],
					};

				case 'PickingCancelledEvent':
					return {
						id,
						timestamp,
						title: messages.jobCanceled,
					};

				case 'PackoutInductedEvent':
					return {
						id,
						timestamp,
						title: translate(messages.toteScanned, {
							mfpId: <ChuckLink deviceId={event?.deviceId} />,
						}),
						details: [
							{
								label: messages.licensePlate,
								value: event.externalContainerId,
							},
							{
								label: messages.associate,
								value: event.user?.name || '—',
							},
						],
					};

				case 'PackoutCompletedEvent':
					return {
						id,
						timestamp,
						title: messages.packoutCompleted,
						details: [
							{
								label: messages.licensePlate,
								value: event.externalContainerId,
							},
							{
								label: messages.associate,
								value: event.user?.name || '—',
							},
						],
					};

				case 'SortationPickedEvent':
					return {
						id,
						timestamp,
						title: messages.orderPicked,
					};

				case 'SortationSortedEvent':
					return {
						id,
						timestamp,
						title: messages.orderSorted,
					};

				default:
					return undefined;
			}
		})
		.filter((event) => event !== undefined) as TimelineEvent[];
};

export const useLocationInventoryEvents = (events: InventoryEventType[]): TimelineEvent[] => {
	const { messages, translate, formatNumber } = useLocalization();

	const getDiscrepancyReason = (reason?: InventoryConflictReason) => {
		switch (reason) {
			case InventoryConflictReason.Miscount:
				return messages.exceptionReasons.miscount;
			case InventoryConflictReason.PickShort:
				return messages.exceptionReasons.pickShort;
			case InventoryConflictReason.NegativeQuantity:
				return messages.exceptionReasons.negativeQuantity;
			case InventoryConflictReason.Other:
			default:
				return messages.exceptionReasons.otherReason;
		}
	};

	return (events || [])
		.map((event) => {
			const id = event.timestamp;
			const timestamp = new Date(event.timestamp);
			const conflicted = event.conflictReasons.length > 0;
			const variation = conflicted ? 'negative' : undefined;

			const title = (str: string) => {
				if (conflicted) {
					return str + '. ' + messages.suspectedDiscrepancy;
				}

				return str;
			};

			const conflictReasons = event.conflictReasons
				.map((reason) => getDiscrepancyReason(reason))
				.join(', ');

			switch (event.__typename) {
				case 'InventoryAdjustedEvent':
					return {
						id,
						timestamp,
						variation: 'positive',
						title: title(
							translate(messages.quantityAdjusted, {
								quantity: event.quantity,
							}) as string,
						),
						details: [
							{
								label: messages.workflow,
								value: messages.auditCount,
							},
							{
								label: messages.associate,
								value: event.user?.name || '—',
							},
						],
					};

				case 'InventoryInspectedEvent':
					return {
						id,
						timestamp,
						variation,
						title: title(
							translate(messages.countUnitsCounted, {
								count: event.quantity,
							}) as string,
						),
						details: [
							{
								label: messages.workflow,
								value: messages.inspectionCount,
							},

							...(conflicted
								? [
										{
											label: messages.conflictState,
											value: conflictReasons,
										},
								  ]
								: []),

							{
								label: messages.associate,
								value: event.user?.name || '—',
							},
						],
					};

				case 'InventoryMovedEvent': {
					const msg = event.quantity >= 0 ? messages.countUnitsAdded : messages.countUnitsRemoved;

					return {
						id,
						timestamp,
						variation,
						title: title(
							translate(msg, {
								count: Math.abs(event.quantity),
							}) as string,
						),
						details: [
							{
								label: messages.workflow,
								value: messages.workflowTypes.move,
							},
							event.quantity > -1
								? {
										label: messages.originLocation,
										value: (
											<LocationLink
												address={event.detail?.sourceLocationAddress}
												id={event.detail?.sourceLocationId}
											/>
										),
								  }
								: {
										label: messages.destination,
										value: (
											<LocationLink
												address={event.detail?.destLocationAddress}
												id={event.detail?.destLocationId}
											/>
										),
								  },
							...(conflicted
								? [
										{
											label: messages.conflictState,
											value: conflictReasons,
										},
								  ]
								: []),
							{
								label: messages.associate,
								value: event.user?.name || '—',
							},
							{
								label: messages.balance,
								value: formatNumber(event.balance),
							},
						],
					};
				}

				case 'InventoryAmnestyPutEvent': {
					return {
						id,
						timestamp,
						variation,
						title: title(
							translate(messages.countUnitsAdded, {
								count: Math.abs(event.quantity),
							}) as string,
						),
						details: [
							{
								label: messages.workflow,
								value: messages.workflowTypes.amnesty,
							},
							...(conflicted
								? [
										{
											label: messages.conflictState,
											value: conflictReasons,
										},
								  ]
								: []),
							{
								label: messages.associate,
								value: event.user?.name || '—',
							},
							{
								label: messages.balance,
								value: formatNumber(event.balance),
							},
						],
					};
				}

				case 'InventoryPickedEvent':
					return {
						id,
						timestamp,
						variation,
						title: title(
							translate(messages.countUnitsRemoved, {
								count: Math.abs(event.quantity),
							}) as string,
						),
						details: [
							{
								label: messages.workflow,
								value: messages.workflowTypes.picking,
							},
							{
								label: messages.unitsPicked,
								value: translate(messages.xOfY, {
									x: Math.abs(event.quantity),
									y: Math.abs(event.quantityAttempted || 0),
								}),
							},
							...(conflicted
								? [
										{
											label: messages.conflictState,
											value: conflictReasons,
										},
								  ]
								: []),
							{
								label: messages.lpn,
								value: event.detail?.projectionId ? (
									<Link url={routes.outboundJob(event.detail?.projectionId)}>
										{event.detail?.containerId}
									</Link>
								) : (
									'—'
								),
							},
							{
								label: messages.associate,
								value: event.user?.name || '—',
							},
							{
								label: conflicted ? messages.expectedBalance : messages.balance,
								value: formatNumber(event.balance),
							},
						],
					};

				case 'InventoryReplenishedEvent':
					return {
						id,
						timestamp,
						variation,
						title: title(
							translate(
								event.quantity > 0 ? messages.countUnitsAdded : messages.countUnitsRemoved,
								{
									count: Math.abs(event.quantity),
								},
							) as string,
						),
						details: [
							{
								label: messages.workflow,
								value: messages.workflowTypes.replen,
							},
							{
								label: messages.lpn,
								value: event.detail?.projectionId ? (
									<Link url={routes.replenishJob(event.detail?.projectionId)}>
										{event.detail?.containerId}
									</Link>
								) : (
									'—'
								),
							},
							event.quantity > -1
								? {
										label: messages.originLocation,
										value: (
											<LocationLink
												address={event.detail?.sourceLocationAddress}
												id={event.detail?.sourceLocationId}
											/>
										),
								  }
								: {
										label: messages.destination,
										value: (
											<LocationLink
												address={event.detail?.destLocationAddress}
												id={event.detail?.destLocationId}
											/>
										),
								  },
							...(conflicted
								? [
										{
											label: messages.conflictState,
											value: conflictReasons,
										},
								  ]
								: []),
							{
								label: messages.associate,
								value: event.user?.name || '—',
							},
							{
								label: messages.balance,
								value: formatNumber(event.balance),
							},
						],
					};
				default:
					return undefined;
			}
		})
		.filter((event) => event !== undefined) as TimelineEvent[];
};
