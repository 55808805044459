import gql from 'graphql-tag';

export const LANE_QUERY = gql`
	query lane($id: String!) {
		laneById(id: $id) {
			laneId
			status
			carrier
			carrierSort
			carrierClassMapping {
				carrier
				mailClasses
			}
			containers {
				id
				packages {
					packageInternalId
					packageType
					timeScanned
				}
			}
		}
	}
`;
