import { ChoiceList } from '@shopify/polaris';

import { noop } from 'helpers/noop';
import { useLocalization } from 'hooks/useLocalization';

export enum Alert {
	Late = 'late',
	Exception = 'exception',
	AwaitingInventory = 'awaiting-inventory',
}

interface AlertsFilterProps {
	selected?: Alert[];
	onChange?: (selected: Alert[], name: string) => void;
}

export function AlertsFilter({ selected = [], onChange = noop }: AlertsFilterProps) {
	const { messages } = useLocalization();

	return (
		<ChoiceList
			title={messages.alert}
			titleHidden
			choices={[
				{
					label: messages.late,
					value: Alert.Late,
				},
				{
					label: messages.exception,
					value: Alert.Exception,
				},
				{
					label: messages.awaitingInventory,
					value: Alert.AwaitingInventory,
				},
			]}
			selected={selected}
			onChange={onChange}
			allowMultiple
		/>
	);
}
