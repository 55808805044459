import gql from 'graphql-tag';

export const JOB_EVENTS_QUERY = gql`
	query GetJobEvents($id: String!) {
		jobEventsV2(id: $id) {
			timestamp
			__typename
			... on PickingEvent {
				user {
					id
					name
					badge
				}
				deviceId
				externalContainerId
				jobId
				projectionId
				orderProjectionId
				takeoffDestination
				isHealing
			}
			... on PickingInductedEvent {
				healedJobExternalContainerId
			}
			... on PickingPickedEvent {
				storageLocationId
				storageLocationAddress
				productId
				productExternalId
				productName
				quantity
				doneQuantity
				exception
			}
			... on PackoutEvent {
				user {
					id
					name
				}
				deviceId
				externalContainerId
				projectionId
			}
		}
	}
`;
