import {
	Button,
	Card,
	Collapsible,
	Heading,
	Link,
	Stack,
	TextContainer,
	TextStyle,
} from '@shopify/polaris';
import { ChevronUpMinor, ChevronDownMinor } from '@shopify/polaris-icons';
import { ReworkJob } from '@sixriver/fulfillment-api-schema';
import { useCallback, useEffect, useState } from 'react';

import { DateTime } from 'components/DateTime';
import { Divider } from 'components/Divider';
import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';

export function ProgressLog({ reworkJob }: { reworkJob?: ReworkJob }) {
	const { messages } = useLocalization();

	// state
	const [progressCollapsibleOpen, setProgressCollapsibleOpen] = useState(false);

	const handleToggle = useCallback(
		() => setProgressCollapsibleOpen((progressCollapsibleOpen) => !progressCollapsibleOpen),
		[],
	);

	// open progress dropdown on load if there are progress logs
	useEffect(() => {
		if (reworkJob?.progress && reworkJob?.progress.length > 0) {
			setProgressCollapsibleOpen(true);
		}
	}, [reworkJob?.progress]);

	return (
		<Card>
			<Card.Section subdued>
				<Stack distribution="equalSpacing">
					<Heading>{messages.progressLog}</Heading>
					<Button
						icon={progressCollapsibleOpen ? ChevronUpMinor : ChevronDownMinor}
						plain
						onClick={handleToggle}
						ariaExpanded={progressCollapsibleOpen}
						ariaControls="basic-collapsible"
						removeUnderline={true}
						size="medium"
						disabled={reworkJob?.progress?.length === 0}
					>
						{progressCollapsibleOpen ? messages.hide : messages.show}
					</Button>
				</Stack>
			</Card.Section>
			<Collapsible
				open={progressCollapsibleOpen}
				id="basic-collapsible"
				transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
				expandOnPrint
			>
				<Divider />
				<Card.Section>
					<Stack distribution="fillEvenly">
						<TextContainer>
							<TextStyle variation="strong">{messages.quantity}</TextStyle>
						</TextContainer>
						<TextContainer>
							<TextStyle variation="strong">{messages.condition}</TextStyle>
						</TextContainer>
						<TextContainer>
							<TextStyle variation="strong">{messages.location}</TextStyle>
						</TextContainer>
						<TextContainer>
							<TextStyle variation="strong">{messages.date}</TextStyle>
						</TextContainer>
						<TextContainer>
							<TextStyle variation="strong">{messages.employee}</TextStyle>
						</TextContainer>
					</Stack>
				</Card.Section>
				{reworkJob?.progress &&
					reworkJob?.progress.map((progressItem, key) => {
						const { condition, quantity, locationId, location, createdAt, userId, user } =
							progressItem;
						return (
							<Card.Section key={key}>
								<Stack distribution="fillEvenly">
									<p>{quantity}</p>
									<p>{condition}</p>
									<Link key={locationId} url={routes.location(locationId)} removeUnderline>
										{location.address}
									</Link>
									<DateTime date={createdAt} />
									<Link url={routes.employee(userId)} removeUnderline>
										{user?.name ? user.name : messages.unnamed}
									</Link>
								</Stack>
							</Card.Section>
						);
					})}
			</Collapsible>
		</Card>
	);
}
