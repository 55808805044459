import {
	MutationStartHealPlanAndPrintLabelsArgs,
	MutationStartManualHealPlanArgs,
	StartHealPlanPayload,
} from '@sixriver/fulfillment-api-schema';
import { useCallback } from 'react';
import { CombinedError, useMutation } from 'urql';

import { MANUAL_RESOLVE_MUTATION } from './ManuallyResolve.graphql';
import { PRINT_LABEL_MUTATION } from './PrintLabel.graphql';

export function usePrintLabelForException(): {
	fetching: boolean;
	error?: CombinedError;
	printLabel: (id: string) => Promise<void>;
} {
	const [{ fetching, error }, doPrintLabel] = useMutation<
		{ startHealPlanAndPrintLabelsV2: StartHealPlanPayload },
		MutationStartHealPlanAndPrintLabelsArgs
	>(PRINT_LABEL_MUTATION);

	const printLabel = useCallback(
		async (id) => {
			await doPrintLabel({ id });
		},
		[doPrintLabel],
	);

	return {
		fetching,
		error,
		printLabel,
	};
}

export function useManuallyResolveForException(): {
	fetching: boolean;
	error?: CombinedError;
	manuallyResolve: (id: string) => Promise<void>;
} {
	const [{ fetching, error }, doManuallyResolve] = useMutation<
		{ startManualHealPlanV2: StartHealPlanPayload },
		MutationStartManualHealPlanArgs
	>(MANUAL_RESOLVE_MUTATION);

	const manuallyResolve = useCallback(
		async (id) => {
			await doManuallyResolve({ id });
		},
		[doManuallyResolve],
	);

	return {
		fetching,
		error,
		manuallyResolve,
	};
}
