import {
	Button,
	Card,
	Collapsible,
	Heading,
	Link,
	Modal,
	Stack,
	TextContainer,
	TextField,
	TextStyle,
} from '@shopify/polaris';
import { ChevronUpMinor, ChevronDownMinor } from '@shopify/polaris-icons';
import {
	MutationResponse,
	ReworkJobsSummaryPage,
	ShippingNotice,
	ShippingNoticeNewNoteInput,
	ShippingNoticeNoteInput,
	ShippingNoticeLineNoteInput,
} from '@sixriver/fulfillment-api-schema';
import { useState, useCallback, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useQuery, useMutation } from 'urql';

import {
	ADD_SHIPPING_NOTICE_NOTE_MUTATION,
	UPDATE_SHIPPING_NOTICE_NOTE_MUTATION,
	UPDATE_SHIPPING_NOTICE_LINE_NOTE_MUTATION,
} from '../InboundShipment.graphql';
import { InboundShipmentIssue } from '../InboundShipmentIssue';
import styles from './IssueSummary.module.css';
import { DateTime } from 'components/DateTime';
import { Divider } from 'components/Divider';
import { IssueCount } from 'components/IssueCount';
import { NoData } from 'components/NoData';
import { useAuth } from 'hooks/useAuth';
import { useLocalization } from 'hooks/useLocalization';
import { useToast } from 'hooks/useToast';
import { REWORK_JOBS_QUERY } from 'pages/ReworkJobs/ReworkJobs.graphql';
import * as routes from 'routes';

function convertShippingNoticeIssueToAPIReason(reason?: string) {
	const issues: { [key: string]: string } = {
		ShipmentNotLabelled: 'SHIPMENT_NOT_LABELLED',
		MixedCartons: 'MIXED_CARTONS',
		NoAppointment: 'NO_APPOINTMENT',
		UnsafeToUnload: 'UNSAFE_TO_UNLOAD',
		CancelledByAPI: 'CANCELLED_BY_API',
		CancelledByAdmin: 'CANCELLED_BY_ADMIN',
		ItemNotOnAsn: 'ITEM_NOT_ON_ASN',
		Damaged: 'DAMAGED',
		Shortage: 'SHORTAGE',
		Overage: 'OVERAGE',
		Barcode: 'BARCODE',
		Ineligible: 'INELIGIBLE',
		Other: 'OTHER',
	};

	return reason ? issues[reason] || issues.OTHER : issues.OTHER;
}

interface Props {
	shippingNotice?: ShippingNotice;
	refetchShipment: () => void;
}

export function IssueSummary({ shippingNotice, refetchShipment }: Props) {
	const { messages } = useLocalization();
	const { showToast } = useToast();
	const {
		params: { inboundShipmentId },
	} = useRouteMatch<{ inboundShipmentId: string }>();

	// state
	const [issueCollapsibleOpen, setIssueCollapsibleOpen] = useState(false);
	const [addShippingNoticeNoteModalOpen, setAddShippingNoticeNoteModalOpen] = useState(false);
	const [updateShippingNoticeNoteModalOpen, setUpdateShippingNoticeNoteModalOpen] = useState(false);
	const [updateShippingNoticeLineNoteModalOpen, setUpdateShippingNoticeLineNoteModalOpen] =
		useState(false);
	const [selectedIssue, setSelectedIssue] = useState({
		issueId: '',
		inboundLineId: '',
		quantity: '',
		reason: '',
	});
	const [noteValue, setNoteValue] = useState('');

	const handleToggle = useCallback(
		() => setIssueCollapsibleOpen((issueCollapsibleOpen) => !issueCollapsibleOpen),
		[],
	);

	// Auth
	const { user } = useAuth();
	const loggedInUserId = user?.id;

	const [{ data: reworkJobsData }] = useQuery<{
		reworkJobs: ReworkJobsSummaryPage;
	}>({
		query: REWORK_JOBS_QUERY,
		variables: {
			externalId: shippingNotice?.externalId,
		},
	});

	const reworkJobs = reworkJobsData?.reworkJobs;

	// Mutations
	const [, addShippingNoticeNote] = useMutation<
		{ addShippingNoticeNote: MutationResponse },
		{ input: ShippingNoticeNewNoteInput }
	>(ADD_SHIPPING_NOTICE_NOTE_MUTATION);

	const [, updateShippingNoticeNote] = useMutation<
		{ updateShippingNoticeNote: MutationResponse },
		{ input: ShippingNoticeNoteInput }
	>(UPDATE_SHIPPING_NOTICE_NOTE_MUTATION);

	const [, updateShippingNoticeLineNote] = useMutation<
		{ updateShippingNoticeLineNote: MutationResponse },
		{ input: ShippingNoticeLineNoteInput }
	>(UPDATE_SHIPPING_NOTICE_LINE_NOTE_MUTATION);

	// // Methods
	const openAddShippingNoticeNoteModal = () => setAddShippingNoticeNoteModalOpen(true);
	const closeAddShippingNoticeNoteModal = () => setAddShippingNoticeNoteModalOpen(false);

	const openUpdateShippingNoticeNoteModal = (issue: any) => {
		setSelectedIssue(issue);
		setNoteValue(issue.comments!);
		setUpdateShippingNoticeNoteModalOpen(true);
	};

	const openUpdateShippingNoticeLineNoteModal = (issue: any) => {
		setSelectedIssue(issue);
		setNoteValue(issue.comments!);
		setUpdateShippingNoticeLineNoteModalOpen(true);
	};

	const closeUpdateShippingNoticeNoteModal = () => setUpdateShippingNoticeNoteModalOpen(false);
	const closeUpdateShippingNoticeLineNoteModal = () =>
		setUpdateShippingNoticeLineNoteModalOpen(false);

	const saveShippingNoticeNote = async () => {
		if (!loggedInUserId) {
			return;
		}

		const input: ShippingNoticeNewNoteInput = {
			asnId: inboundShipmentId,
			comments: noteValue,
			userId: loggedInUserId,
		};

		const { data } = await addShippingNoticeNote({ input });
		if (data?.addShippingNoticeNote.success) {
			refetchShipment();
			setNoteValue('');
			closeAddShippingNoticeNoteModal();
		} else {
			showToast(messages.addNoteFailed);
		}
	};

	const editShippingNoticeNote = async () => {
		if (!loggedInUserId) return;

		const input: ShippingNoticeNoteInput = {
			asnId: inboundShipmentId,
			comments: noteValue,
			issueId: selectedIssue.issueId,
			reason: convertShippingNoticeIssueToAPIReason(selectedIssue.reason),
			userId: loggedInUserId,
		};

		const { data } = await updateShippingNoticeNote({ input });
		if (data?.updateShippingNoticeNote.success) {
			refetchShipment();
			setSelectedIssue({
				issueId: '',
				inboundLineId: '',
				quantity: '',
				reason: '',
			});
			setNoteValue('');
			closeUpdateShippingNoticeNoteModal();
		} else {
			showToast(messages.updateNoteFailed);
		}
	};

	const editShippingNoticeLineNote = async () => {
		if (!loggedInUserId) {
			return;
		}

		const input: ShippingNoticeLineNoteInput = {
			asnId: inboundShipmentId,
			comments: noteValue,
			issueId: selectedIssue.issueId,
			inboundLineId: selectedIssue.inboundLineId,
			reason: convertShippingNoticeIssueToAPIReason(selectedIssue.reason),
			userId: loggedInUserId,
			quantity: Number(selectedIssue.quantity),
		};

		const { data } = await updateShippingNoticeLineNote({ input });
		if (data?.updateShippingNoticeLineNote.success) {
			refetchShipment();
			setSelectedIssue({
				issueId: '',
				inboundLineId: '',
				quantity: '',
				reason: '',
			});
			setNoteValue('');
			closeUpdateShippingNoticeLineNoteModal();
		} else {
			showToast(messages.updateNoteFailed);
		}
	};

	const cancelSaveShippingNoticeNote = () => {
		setNoteValue('');
		closeAddShippingNoticeNoteModal();
	};

	const cancelUpdateShippingNoticeNote = () => {
		setNoteValue('');
		closeUpdateShippingNoticeNoteModal();
	};

	const cancelUpdateShippingNoticeLineNote = () => {
		setNoteValue('');
		closeUpdateShippingNoticeLineNoteModal();
	};

	const handleNoteValueChange = useCallback((newValue) => setNoteValue(newValue), []);

	const asnIssueCount = shippingNotice?.issues?.length || 0;
	const lineIssueCount =
		shippingNotice?.inboundLines?.reduce(
			(accumulator, line) => accumulator + line.issues.length,
			0,
		) || 0;

	const issueCount = asnIssueCount + lineIssueCount;

	useEffect(() => {
		if (issueCount > 0) {
			setIssueCollapsibleOpen(true);
		}
	}, [issueCount]);

	return (
		<>
			<Card>
				<Card.Section>
					<Stack distribution="equalSpacing">
						<Stack alignment="center" spacing="extraTight">
							<Button
								icon={issueCollapsibleOpen ? ChevronUpMinor : ChevronDownMinor}
								plain
								onClick={handleToggle}
								ariaExpanded={issueCollapsibleOpen}
								ariaControls="basic-collapsible"
								monochrome={true}
								removeUnderline={true}
								size="large"
								disabled={issueCount === 0}
							/>
							<Heading>{messages.summaryOfIssues}</Heading>
							<IssueCount count={issueCount} />
						</Stack>
						<Button plain onClick={openAddShippingNoticeNoteModal}>
							{messages.addNoteToShipment}
						</Button>
					</Stack>
				</Card.Section>
				<Collapsible
					open={issueCollapsibleOpen}
					id="basic-collapsible"
					transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
					expandOnPrint
				>
					<Divider />
					{shippingNotice?.issues
						? shippingNotice?.issues.map((issue) => {
								const { id, reason, comments, updatedAt } = issue;
								return (
									<Card.Section key={id}>
										<div className={styles.issueContainer}>
											<TextContainer>
												<TextStyle>{messages.shipment}</TextStyle>
											</TextContainer>
											<InboundShipmentIssue issue={reason} />
											<TextContainer>
												{comments && (
													<>
														{comments}{' '}
														<TextStyle variation="subdued">
															<DateTime date={updatedAt} />
														</TextStyle>
													</>
												)}
											</TextContainer>
											<Button plain onClick={() => openUpdateShippingNoticeNoteModal(issue)}>
												{comments ? messages.editNote : messages.addNote}
											</Button>
										</div>
									</Card.Section>
								);
						  })
						: null}
					{shippingNotice?.inboundLines
						? shippingNotice?.inboundLines.map(({ issues }) =>
								issues.map((issue) => {
									const { id, issueId, quantity, reason, comments, updatedAt, product } = issue;
									return (
										<Card.Section key={id}>
											<div className={styles.issueContainer}>
												{product?.id ? (
													<Link url={routes.product(product.id)} removeUnderline>
														{product.name}
													</Link>
												) : (
													<NoData />
												)}
												<InboundShipmentIssue
													issueId={issueId}
													issue={reason}
													quantity={quantity}
													reworkJobs={reworkJobs}
												/>
												<TextContainer>
													{comments && (
														<>
															{comments}{' '}
															<TextStyle variation="subdued">
																<DateTime date={updatedAt} />
															</TextStyle>
														</>
													)}
												</TextContainer>
												<Button plain onClick={() => openUpdateShippingNoticeLineNoteModal(issue)}>
													{comments ? messages.editNote : messages.addNote}
												</Button>
											</div>
										</Card.Section>
									);
								}),
						  )
						: null}
					<Card.Section subdued>
						<TextContainer>
							<p>{messages.issueNotesAndAttachmentsMessage}</p>
						</TextContainer>
					</Card.Section>
				</Collapsible>
			</Card>
			{/* generic issue modal */}
			<Modal
				title={messages.receivingIssue}
				open={addShippingNoticeNoteModalOpen}
				onClose={closeAddShippingNoticeNoteModal}
				primaryAction={{
					content: messages.save,
					onAction: saveShippingNoticeNote,
				}}
				secondaryActions={[
					{
						content: messages.cancel,
						destructive: false,
						onAction: cancelSaveShippingNoticeNote,
					},
				]}
			>
				<Modal.Section>
					<Stack vertical>
						<TextField
							autoComplete="off"
							label={messages.regarding}
							placeholder={messages.shipment.toLowerCase()}
							disabled
						/>
						<TextField
							autoComplete="off"
							label={messages.notes}
							value={noteValue}
							placeholder={messages.maxCharacter500}
							onChange={handleNoteValueChange}
							multiline={4}
						/>
					</Stack>
				</Modal.Section>
			</Modal>
			{/* shipment level issue modal */}
			<Modal
				title={messages.receivingIssue}
				open={updateShippingNoticeNoteModalOpen}
				onClose={closeUpdateShippingNoticeNoteModal}
				primaryAction={{
					content: messages.save,
					onAction: editShippingNoticeNote,
				}}
				secondaryActions={[
					{
						content: messages.cancel,
						destructive: false,
						onAction: cancelUpdateShippingNoticeNote,
					},
				]}
			>
				<Modal.Section>
					<Stack vertical>
						<TextField
							autoComplete="off"
							label={messages.regarding}
							placeholder={messages.shipment}
							disabled
						/>
						<TextField
							autoComplete="off"
							label={messages.issue}
							placeholder={selectedIssue.reason}
							disabled
						/>
						<TextField
							autoComplete="off"
							label={messages.notes}
							value={noteValue}
							placeholder={messages.maxCharacter500}
							onChange={handleNoteValueChange}
							multiline={4}
						/>
					</Stack>
				</Modal.Section>
			</Modal>
			{/* line level issue modal */}
			<Modal
				title={messages.receivingIssue}
				open={updateShippingNoticeLineNoteModalOpen}
				onClose={closeUpdateShippingNoticeLineNoteModal}
				primaryAction={{
					content: messages.save,
					onAction: editShippingNoticeLineNote,
				}}
				secondaryActions={[
					{
						content: messages.cancel,
						destructive: false,
						onAction: cancelUpdateShippingNoticeLineNote,
					},
				]}
			>
				<Modal.Section>
					<Stack vertical>
						<TextField
							autoComplete="off"
							label={messages.regarding}
							placeholder={messages.line}
							disabled
						/>
						<Stack>
							<TextField
								autoComplete="off"
								label={messages.issue}
								placeholder={selectedIssue.reason}
								disabled
							/>
							<TextField
								autoComplete="off"
								label={messages.quantity}
								placeholder={selectedIssue.quantity}
								disabled
							/>
						</Stack>
						<TextField
							autoComplete="off"
							label={messages.notes}
							value={noteValue}
							placeholder={messages.maxCharacter500}
							onChange={handleNoteValueChange}
							multiline={4}
						/>
					</Stack>
				</Modal.Section>
			</Modal>
		</>
	);
}
