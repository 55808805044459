import { Card, Link } from '@shopify/polaris';
import { Sortation, LineV2 } from '@sixriver/fulfillment-api-schema';

import { CardDetails } from 'components/CardDetails';
import { DateTime } from 'components/DateTime';
import { NoData } from 'components/NoData';
import { calculateUnits } from 'helpers/order';
import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';

interface Props {
	loading?: boolean;
	sortWall?: Sortation;
	lines?: LineV2[];
}

export function SortationDetails({ loading, sortWall, lines }: Props) {
	const { messages, translate } = useLocalization();

	const { picked: unitsPicked, total: unitsTotal } = calculateUnits(lines);
	const linesPicked = lines?.reduce(
		(picked, line) => (line.doneQuantity === line.quantity ? (picked += 1) : picked),
		0,
	);

	return (
		<Card sectioned title={messages.sortationDetails}>
			<CardDetails
				loading={loading}
				primary={[
					{
						label: messages.sortWall,
						content: sortWall?.wall ? (
							<Link url={routes.sortWall(sortWall.jobId)}>{sortWall.wall}</Link>
						) : (
							<NoData />
						),
					},
					{
						label: messages.unitsPicked,
						content: translate(messages.pickedOfTotal, {
							picked: unitsPicked,
							total: unitsTotal,
						}),
					},
					{
						label: messages.linesPicked,
						content: translate(messages.pickedOfTotal, {
							picked: linesPicked || 0,
							total: lines?.length || 0,
						}),
					},
				]}
				secondary={[
					{
						label: messages.batchId,
						content: sortWall?.batchId || <NoData />,
					},
					{
						label: messages.assignedKiosk,
						content: sortWall?.kiosk || <NoData />,
					},
					{
						label: messages.completedAt,
						content: <DateTime date={sortWall?.timeline?.completedAt} />,
					},
				]}
			/>
		</Card>
	);
}
