import gql from 'graphql-tag';

import { PAGE_INFO_FRAGMENT } from 'graphql/page-info';

export const REPLENISHMENT_JOBS_QUERY = gql`
	query ReplenishmentJobs(
		$after: String
		$before: String
		$first: Int
		$last: Int
		$statuses: [ReplenJobState!]
		$searchText: String
		$hasExceptions: Boolean
		$updatedAtFrom: DateTime
	) {
		replenJobs(
			after: $after
			before: $before
			first: $first
			last: $last
			statuses: $statuses
			searchText: $searchText
			hasExceptions: $hasExceptions
			updatedAtFrom: $updatedAtFrom
		) {
			edges {
				node {
					id
					status
					containerId
					createdAt
					updatedAt
					mfp {
						name
					}
					status
					hasExceptions
					tasks {
						id
						destination {
							id
							address
							type
							description
							externalAisleId
						}
						product {
							id
							customerIdentifier
							name
							description
							image
						}
						quantity
						doneQuantity
						exceptions {
							status
						}
					}
				}
			}
			pageInfo {
				...PageInfo
			}
		}
	}
	${PAGE_INFO_FRAGMENT}
`;

export const REPLEN_JOBS_COUNTS_QUERY = gql`
	query CountReplenJobs($searchText: String, $hasExceptions: Boolean, $updatedAtFrom: DateTime) {
		AllCount: replenJobsCount(
			searchText: $searchText
			statuses: [Open, Assigned, Inducted, InProgress, Interrupted, Cancelled, Completed]
			hasExceptions: $hasExceptions
			updatedAtFrom: $updatedAtFrom
		) {
			count
		}

		UnassignedCount: replenJobsCount(
			searchText: $searchText
			statuses: [Open]
			hasExceptions: $hasExceptions
		) {
			count
		}

		InProgressCount: replenJobsCount(
			searchText: $searchText
			statuses: [InProgress, Inducted, Assigned]
			hasExceptions: $hasExceptions
		) {
			count
		}

		CompleteCount: replenJobsCount(
			searchText: $searchText
			statuses: [Completed]
			hasExceptions: $hasExceptions
			updatedAtFrom: $updatedAtFrom
		) {
			count
		}

		CanceledCount: replenJobsCount(
			searchText: $searchText
			statuses: [Cancelled]
			hasExceptions: $hasExceptions
			updatedAtFrom: $updatedAtFrom
		) {
			count
		}

		InterruptedCount: replenJobsCount(
			searchText: $searchText
			statuses: [Interrupted]
			hasExceptions: $hasExceptions
		) {
			count
		}
	}
`;
