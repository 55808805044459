import { LaneConnection } from '@sixriver/fulfillment-api-schema';
import gql from 'graphql-tag';

import { PAGE_INFO_FRAGMENT } from 'graphql/page-info';

export interface LanesData {
	lanes: LaneConnection;
}

export const LANES_QUERY = gql`
	query Lanes(
		$after: String
		$first: Int
		$laneIds: [String!]
		$lanesSearchText: String
		$carriers: [String!]
		$carrierSorts: [String!]
		$laneStatuses: [LaneStatus!]
	) {
		lanes(
			after: $after
			first: $first
			laneIds: $laneIds
			lanesSearchText: $lanesSearchText
			carriers: $carriers
			carrierSorts: $carrierSorts
			laneStatuses: $laneStatuses
		) {
			edges {
				node {
					laneId
					status
					carrier
					carrierSort
					carrierClassMapping {
						carrier
						mailClasses
					}
					containers {
						id
						containerStatus
						carrierPickupDetails {
							vehicleScan
							vehicleLaneId
							loadedAt
							isOverridden
						}
					}
				}
			}
			pageInfo {
				...PageInfo
			}
		}
	}
	${PAGE_INFO_FRAGMENT}
`;

export const UNIQUE_CARRIERS_AND_CARRIER_SORTS_QUERY = gql`
	query {
		uniqueCarriersAndCarrierSorts {
			carriers
			carrierSorts
		}
	}
`;
