import { ViewTabs } from 'components/ViewTabs';
import { noop } from 'helpers/noop';
import { useLocalization } from 'hooks/useLocalization';

export enum LanesTab {
	All = 'all',
}

export interface LanesTabsProps {
	selected: string;
	onSelect?: (tab: LanesTab) => void;
}

export function LanesTabs({ selected, onSelect = noop }: LanesTabsProps) {
	const { messages } = useLocalization();
	const tabs = [
		{
			label: messages.all,
			id: LanesTab.All,
		},
	];

	return <ViewTabs tabs={tabs} selected={selected} onSelect={onSelect} />;
}
