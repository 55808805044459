import {
	Card,
	Checkbox,
	ContextualSaveBar,
	Form,
	Icon,
	Layout,
	Stack,
	TextField,
	Tooltip,
	Select,
	InlineError,
} from '@shopify/polaris';
import { QuestionMarkMinor } from '@shopify/polaris-icons';
import { AddContainerInput, ContainerType } from '@sixriver/fulfillment-api-schema';
import { useMemo, useState } from 'react';

import { GenericContainerIllustration } from './images/GenericContainerIllustration';
import { PickingContainerIllustration } from './images/PickingContainerIllustration';
import { SubsectionIllustration } from './images/SubsectionIllustration';
import { FormFeedback } from 'components/FormFeedback';
import { ImageUpload } from 'components/ImageUpload/ImageUpload';
import { useConfig } from 'hooks/useConfig';
import { FormProps, useForm } from 'hooks/useForm';
import { useLocalization } from 'hooks/useLocalization';
import { transformImageUrl } from 'utils/image';
import { uuidv4 } from 'utils/uuid';

type ContainerInput = AddContainerInput;

export function ContainerForm({
	data: container,
	onSubmit,
	error,
}: FormProps<ContainerInput>): JSX.Element {
	const { messages, formatLength } = useLocalization();

	// GRM Config
	const { config } = useConfig();
	const containerTypeOptions = [
		ContainerType.Picking,
		...(config?.packoutEnabled ? [ContainerType.Shipping] : []),
		...(config?.inventoryEnabled ? [ContainerType.Storage] : []),
	].map((type) => ({
		label: messages.containerTypes[type],
		value: type,
	}));

	// State
	const [containerHasSubsections, setContainerHasSubsections] = useState(
		(container.rows || 0) > 1 || (container.columns || 0) > 1,
	);
	const [isNameUsedForExternalId, setIsNameUsedForExternalId] = useState(
		container.name === container.externalId,
	);

	// Form
	const defaultValues: ContainerInput = useMemo(
		() => ({
			length: container.length,
			width: container.width,
			height: container.height,
			weight: container.weight,
			description: container?.description === container?.name ? '' : container?.description,
			columns: container.columns,
			rows: container.rows,
			name: container.name,
			externalId: container.externalId,
			imageURL: container.imageURL ? transformImageUrl(container.imageURL) : '',
			barcode: container.barcode,
			instructions: container.instructions,
			enabled: !!container.enabled,
			inventory: !!container.inventory,
			type: container.type,
		}),
		[container],
	);

	const {
		input: formValues,
		dirty,
		editForm,
		feedback,
		discardForm,
		validations,
	} = useForm<ContainerInput>(defaultValues, error);

	// Methods
	const generateBarcode = () => editForm({ barcode: uuidv4() });

	const submitForm = (data: ContainerInput) => {
		// Cast the form data into an input
		const input: ContainerInput = {
			...data,
			columns: data?.columns || 1,
			description: data?.description || data.name,
			externalId: isNameUsedForExternalId ? data.name : data.externalId,
			rows: data?.rows || 1,
			weight: data?.weight || 0,
			barcode: data?.barcode || undefined,
		};

		onSubmit(input);
	};

	const clearForm = () => {
		const hasSubsections = (defaultValues.rows || 0) > 1 || (defaultValues.columns || 0) > 1;
		setContainerHasSubsections(hasSubsections);
		setIsNameUsedForExternalId(defaultValues.name === defaultValues.externalId);
		discardForm();
	};

	const handleExternalIdReset = (ticked: boolean): void => {
		setIsNameUsedForExternalId(ticked);
		if (ticked && formValues.name !== formValues.externalId) {
			editForm({ externalId: formValues.name });
		} else {
			editForm({ externalId: '' });
		}
	};

	// Render
	return (
		<>
			{/* Error Banner */}
			<FormFeedback feedback={feedback} />

			{/* Form */}
			<Form onSubmit={() => submitForm(formValues)} noValidate>
				{/* Container Details */}
				<Layout.AnnotatedSection title={messages.containerDetails}>
					<Card sectioned>
						<Stack distribution="fill" vertical>
							<Select
								name="type"
								label={messages.containerType}
								requiredIndicator
								placeholder={messages.select}
								options={containerTypeOptions}
								error={validations.type}
								value={formValues.type || undefined}
								onChange={(selected) => {
									if (selected !== ContainerType.Picking) {
										setContainerHasSubsections(false);
									}
									editForm({
										type: selected as ContainerType,
										weight: selected !== ContainerType.Shipping ? 0 : defaultValues.weight || 0,
										inventory: false,
										enabled: true,
										...(selected !== ContainerType.Picking ? { rows: 1, columns: 1 } : {}),
									});
								}}
							/>

							<TextField
								autoComplete="off"
								name="name"
								requiredIndicator
								label={messages.containerName}
								error={validations.name}
								value={formValues.name}
								onChange={(name) =>
									editForm({
										name,
										externalId: isNameUsedForExternalId ? name : formValues.externalId,
									})
								}
							/>

							<Stack vertical spacing="extraTight">
								<TextField
									autoComplete="off"
									name="externalId"
									requiredIndicator
									label={messages.externalId}
									error={validations.externalId}
									value={formValues.externalId}
									onChange={(externalId) => editForm({ externalId })}
									disabled={isNameUsedForExternalId}
								/>

								<Checkbox
									name="toggleNameToExternalIdSyncing"
									label={
										<Stack>
											<div>{messages.useContainerNameAsExternalId}</div>
											<div>
												<Tooltip content={messages.containerExternalIdPurpose}>
													<Icon source={QuestionMarkMinor} color="base" />
												</Tooltip>
											</div>
										</Stack>
									}
									checked={isNameUsedForExternalId}
									onChange={(ticked) => handleExternalIdReset(ticked)}
								/>
							</Stack>

							<TextField
								autoComplete="off"
								name="description"
								label={`${messages.description} (${messages.optional})`}
								error={validations.description}
								value={formValues.description || undefined}
								onChange={(description) => editForm({ description })}
							/>

							{/* Barcode */}
							{formValues.type === ContainerType.Shipping ? (
								<Stack vertical>
									<TextField
										autoComplete="off"
										name="barcode"
										requiredIndicator
										label={messages.barcode}
										labelAction={{
											content: messages.generateBarcode,
											onAction: generateBarcode,
										}}
										error={validations.barcode}
										helpText={messages.barcodesUniquePerContainer}
										value={formValues.barcode || undefined}
										onChange={(barcode) => editForm({ barcode })}
									/>
									<Checkbox
										name="enabled"
										label={messages.enableContainer}
										error={validations.enabled}
										checked={formValues.enabled}
										onChange={(isChecked) => editForm({ enabled: isChecked })}
									/>

									<Checkbox
										name="inventory"
										label={messages.trackContainerAsInventory}
										error={validations.inventory}
										checked={formValues.inventory}
										onChange={(isChecked) => editForm({ inventory: isChecked })}
									/>
								</Stack>
							) : null}
						</Stack>
					</Card>
				</Layout.AnnotatedSection>

				{/* Dimensions */}
				<Layout.AnnotatedSection title={messages.containerDimensions}>
					<Card>
						{/* Has-Subsections toggle */}
						{formValues.type === ContainerType.Picking ? (
							<Card.Section>
								<Checkbox
									label={messages.containerHasSubections}
									checked={containerHasSubsections}
									name="containerHasSubsections"
									onChange={setContainerHasSubsections}
								/>
							</Card.Section>
						) : null}

						<Card.Section>
							{/* Container Subsections + WHOLE container dimensions (read-only) */}
							{containerHasSubsections ? (
								<Stack vertical>
									{/* Length */}
									<Stack.Item>
										<Stack vertical={false} distribution="fillEvenly">
											<TextField
												autoComplete="off"
												type="number"
												name="length"
												requiredIndicator
												suffix="mm"
												min="1"
												step={1}
												label={messages.subsectionLength}
												inputMode="numeric"
												error={validations.length}
												value={formValues.length.toString()}
												onChange={(length) => editForm({ length: parseFloat(length) })}
											/>
											<TextField
												autoComplete="off"
												type="number"
												name="rows"
												requiredIndicator
												min={0}
												label={messages.rows}
												error={validations.rows}
												value={formValues.rows?.toString()}
												onChange={(rows) => editForm({ rows: parseInt(rows) })}
											/>
										</Stack>
									</Stack.Item>

									<Stack.Item>
										<small>
											{messages.totalLength +
												'=' +
												formatLength((formValues.length || 10) * (formValues.rows || 0))}
										</small>
									</Stack.Item>

									{/* Width */}
									<Stack.Item>
										<Stack vertical={false} distribution="fillEvenly">
											<TextField
												autoComplete="off"
												type="number"
												name="width"
												requiredIndicator
												suffix="mm"
												min="1"
												step={1}
												label={messages.subsectionWidth}
												inputMode="numeric"
												error={validations.width}
												value={formValues.width.toString()}
												onChange={(width) => editForm({ width: parseFloat(width) })}
											/>

											<TextField
												autoComplete="off"
												type="number"
												name="columns"
												requiredIndicator
												min={0}
												label={messages.columns}
												error={validations.columns}
												value={formValues.columns?.toString()}
												onChange={(columns) => editForm({ columns: parseInt(columns) })}
											/>
										</Stack>
									</Stack.Item>

									<Stack.Item>
										<small>
											{messages.totalWidth +
												'=' +
												formatLength((formValues.width || 10) * (formValues.columns || 0))}
										</small>
									</Stack.Item>

									{/* Height */}
									<Stack.Item>
										<Stack vertical={false} distribution="fillEvenly">
											<TextField
												autoComplete="off"
												type="number"
												name="height"
												requiredIndicator
												suffix="mm"
												min="1"
												step={1}
												label={messages.subsectionHeight}
												inputMode="numeric"
												error={validations.height}
												value={formValues.height.toString()}
												onChange={(height) => editForm({ height: parseFloat(height) })}
											/>
											<Stack.Item />
										</Stack>
									</Stack.Item>

									<Stack vertical>
										<em>{messages.exampleNotToScale}</em>
										<SubsectionIllustration />
									</Stack>
								</Stack>
							) : (
								/* (NO subsections) Length / Width / Height + Weight  */
								<Stack vertical distribution="fillEvenly">
									<TextField
										autoComplete="off"
										type="number"
										name="length"
										requiredIndicator
										suffix="mm"
										min="1"
										step={1}
										label={messages.length}
										inputMode="numeric"
										error={validations.length}
										value={formValues.length.toString()}
										onChange={(length) => editForm({ length: parseFloat(length) })}
									/>
									<TextField
										autoComplete="off"
										type="number"
										name="width"
										requiredIndicator
										suffix="mm"
										min="1"
										step={1}
										label={messages.width}
										error={validations.width}
										value={formValues.width.toString()}
										onChange={(width) => editForm({ width: parseFloat(width) })}
									/>
									<TextField
										autoComplete="off"
										type="number"
										name="height"
										requiredIndicator
										suffix="mm"
										min="1"
										step={1}
										label={messages.height}
										error={validations.height}
										value={formValues.height.toString()}
										onChange={(height) => editForm({ height: parseFloat(height) })}
									/>
									{formValues.type === ContainerType.Shipping ? (
										<TextField
											autoComplete="off"
											type="number"
											name="weight"
											requiredIndicator
											suffix="g"
											step={1}
											label={messages.weight}
											error={validations.weight}
											value={(formValues.weight || 0).toString()}
											onChange={(weight) => editForm({ weight: parseFloat(weight) })}
										/>
									) : null}
									<Stack vertical>
										<em>{messages.exampleNotToScale}</em>
										{formValues.type === ContainerType.Picking ? (
											<PickingContainerIllustration />
										) : (
											<GenericContainerIllustration />
										)}
									</Stack>
								</Stack>
							)}
						</Card.Section>
					</Card>
				</Layout.AnnotatedSection>

				{/* Image */}
				<Layout.AnnotatedSection title={messages.image} description={'JPEG, PNG, GIF'}>
					<Card sectioned>
						{validations.imageURL && (
							<InlineError fieldID={messages.imageURL} message={validations.imageURL} />
						)}
						<ImageUpload
							defaultImage={container.imageURL || undefined}
							imgUrl={formValues.imageURL || undefined}
							onImgUrl={(url) => editForm({ imageURL: url || '' })}
							resourceName={messages.containerImage}
						/>
					</Card>
				</Layout.AnnotatedSection>
			</Form>

			{/* Save Bar */}
			{dirty ? (
				<ContextualSaveBar
					fullWidth={false}
					alignContentFlush={false}
					message={messages.unsavedChanges}
					saveAction={{
						content: messages.save,
						onAction: () => submitForm(formValues),
					}}
					discardAction={{
						content: messages.discard,
						onAction: clearForm,
					}}
				/>
			) : null}
		</>
	);
}
