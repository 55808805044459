import gql from 'graphql-tag';

export const REWORK_JOB_QUERY = gql`
	query ReworkJob($id: String!) {
		reworkJob(id: $id) {
			id
			createdAt
			merchant
			status
			reworkQuantity
			processedQuantity
			issue
			jobOriginType
			jobOriginExternalId
			jobOriginId
			workStartedAt
			workCompletedAt
			quantitySellable
			quantityDamaged
			product {
				id
				image
				productId
				description
				name
			}
			subAssetTypeDefinitionId
			stagedLocations {
				id
				address
			}
			stagedSubLocations {
				createdAt
				definition {
					externalId
				}
			}
			progress {
				userId
				quantity
				condition
				createdAt
				locationId
				location {
					address
				}
				user {
					name
				}
				location {
					address
				}
			}
		}
	}
`;
