import { IndexTable } from '@shopify/polaris';
import { Lane } from '@sixriver/fulfillment-api-schema';

import { LaneStatusBadge } from 'components/LaneStatusBadge/LaneStatusBadge';

interface LaneStatusCellProps {
	lane: Lane;
}

export function LaneStatusCell({ lane }: LaneStatusCellProps) {
	return (
		<IndexTable.Cell>
			<LaneStatusBadge lane={lane} />
		</IndexTable.Cell>
	);
}
