import { Button, Icon } from '@shopify/polaris';
import { DuplicateMinor } from '@shopify/polaris-icons';

import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	text: string;
}

export function CopyButton({ text: textToCopy }: Props) {
	const { messages } = useLocalization();
	const { copyToClipboard } = useCopyToClipboard();

	return (
		<Button
			accessibilityLabel={messages.copyToClipboard}
			icon={<Icon source={DuplicateMinor} />}
			onClick={() => copyToClipboard(textToCopy)}
			plain
			monochrome
			textAlign="left"
		>
			{textToCopy}
		</Button>
	);
}
