import { OrderState } from '@sixriver/fulfillment-api-schema';

export enum ExceptionViews {
	Unresolvable = 'Unresolvable',
	Resolvable = 'Resolvable',
	Resolving = 'Resolving',
	Resolved = 'Resolved',
}

export const ExceptionViewStateMap: { [key in ExceptionViews]: OrderState[] } = {
	[ExceptionViews.Unresolvable]: [OrderState.Exception],
	[ExceptionViews.Resolvable]: [OrderState.Exception],
	[ExceptionViews.Resolving]: [OrderState.InProgress],
	[ExceptionViews.Resolved]: [
		OrderState.Done,
		OrderState.Packable,
		OrderState.Packing,
		OrderState.Packed,
		OrderState.Sorting,
		OrderState.Sorted,
		OrderState.Cancelled,
	],
};
