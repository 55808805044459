interface WifiProps {
	signal?: number;
	connected?: boolean;
	width?: number;
}

export function Wifi({ signal = 0, connected = false, width = 16 }: WifiProps) {
	return (
		<svg width={width} height={width / 1.25} viewBox="0 0 19 15" fill="none" data-testid="wifi">
			<path
				d="M1.65 4.27 C2.47 3.34 5.21 1.49 9.57 1.49 C13.9 1.49 16.57 3.34 17.34 4.27"
				stroke="#5C5F62"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeOpacity={signal < -57 || !connected ? 0.3 : 1}
			/>
			<path
				d="M5.43 8.10 C5.87 7.65 7.31 6.74 9.57 6.74 C11.83 6.74 13.10 7.65 13.46 8.10"
				stroke="#5C5F62"
				strokeWidth="2.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeOpacity={signal < -67 || !connected ? 0.3 : 1}
			/>
			<circle
				cx="9.53583"
				cy="12.5171"
				r="1.93872"
				fill="#5C5F62"
				fillOpacity={signal < -75 || !connected ? 0.3 : 1}
			/>
			{connected ? null : (
				<path d="M3 12 15 2" stroke="#D72C0D" strokeWidth="2.5" strokeLinecap="round" />
			)}
		</svg>
	);
}
