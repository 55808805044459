import { Card, Heading, Pagination, Stack } from '@shopify/polaris';
import {
	Job,
	LineConnectionV2,
	LinesViews,
	OrderV2,
	QueryJobsLinesV2Args,
	QueryJobsLinesV2CountArgs,
} from '@sixriver/fulfillment-api-schema';
import { useCallback, useState } from 'react';

import { LinesFilter } from './LinesFilter';
import { LinesProvider } from './LinesProvider';
import { LinesTable, LinesTableColumn } from './LinesTable';
import { COUNTS_QUERY, JobLineCounts } from './LinesTableCounts.graphql';
import { LINES_QUERY } from './LinesTables.graphql';
import { LinesTab, LinesTabs } from './LinesTabs';
import { getPageSize } from 'helpers/page-size';
import { useLocalization } from 'hooks/useLocalization';
import { usePolling } from 'hooks/usePolling';
import { usePollingQuery } from 'hooks/usePollingQuery';
import { useQueryState } from 'hooks/useQueryState';

const SEARCH_TEXT_KEY = 'query';
const SELECTED_TAB = 'selectedTab';

export interface LinesProps {
	job: Job | OrderV2;
	columns: LinesTableColumn[];
	tabs?: LinesTab[];
}

export function Lines({ job, columns, tabs = [] }: LinesProps) {
	const { queryPollInterval } = usePolling();
	const { messages } = useLocalization();
	const [selectedTab, setSelectedTab] = useQueryState<LinesViews>(SELECTED_TAB, LinesViews.All);
	const [searchText, setSearchText] = useQueryState(SEARCH_TEXT_KEY, '');
	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);

	const [{ data: countsData }] = usePollingQuery<JobLineCounts, QueryJobsLinesV2CountArgs>({
		query: COUNTS_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			id: job.id,
			searchText: searchText.length >= 3 ? searchText : undefined,
		},
	});
	const [{ fetching, data }, refetch] = usePollingQuery<
		{ jobsLinesV2: LineConnectionV2 },
		QueryJobsLinesV2Args
	>({
		query: LINES_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			id: job.id,
			first: getPageSize(),
			after: paginationCursors[0],
			view: selectedTab,
			searchText: searchText.length >= 3 ? searchText : undefined,
		},
	});
	const lines = data?.jobsLinesV2.edges;
	const linesPageInfo = data?.jobsLinesV2.pageInfo;
	const counts = {
		all: countsData?.All.count,
		exceptions: countsData?.Exceptions.count,
		unassigned: countsData?.Unassigned.count,
		inProgress: countsData?.InProgress.count,
		complete: countsData?.Completed.count,
	};
	const totalLinesCount = counts.all ?? 0;
	const totalLinesPages = Math.max(Math.trunc(totalLinesCount / getPageSize()), 1);

	const handleOnNextPage = useCallback(() => {
		const endCursor = linesPageInfo?.endCursor ?? undefined;
		if (endCursor) {
			setPaginationCursors((current) => [endCursor].concat(current));
			refetch();
		}
	}, [linesPageInfo?.endCursor, refetch]);

	const handleOnPreviousPage = useCallback(() => {
		setPaginationCursors((current) => current.slice(1));
		refetch();
	}, [refetch]);

	return (
		<LinesProvider job={job}>
			<Card>
				<Card.Section>
					<Heading>{messages.lines}</Heading>
				</Card.Section>
				<Card.Section fullWidth flush>
					<LinesTabs tabs={tabs} counts={counts} selected={selectedTab} onSelect={setSelectedTab} />
					<LinesFilter queryValue={searchText} onQueryChange={(value) => setSearchText(value)} />
					<LinesTable loading={fetching} data={lines} columns={columns} />
				</Card.Section>
				<Card.Section>
					<Stack distribution="center">
						<Pagination
							label={`${paginationCursors.length + 1} ${messages.of} ${totalLinesPages}`}
							hasNext={linesPageInfo?.hasNextPage}
							hasPrevious={linesPageInfo?.hasPreviousPage}
							onNext={handleOnNextPage}
							onPrevious={handleOnPreviousPage}
						/>
					</Stack>
				</Card.Section>
			</Card>
		</LinesProvider>
	);
}
