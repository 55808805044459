import { ChoiceList } from '@shopify/polaris';
import { ContainerStatus } from '@sixriver/fulfillment-api-schema';

import { noop } from 'helpers/noop';
import { useLocalization } from 'hooks/useLocalization';

interface ContainerStatusesFilterProps {
	selected?: ContainerStatus[];
	onChange?: (selected: ContainerStatus[], name: string) => void;
}

export function ContainerStatusesFilter({
	selected = [],
	onChange = noop,
}: ContainerStatusesFilterProps) {
	const { messages } = useLocalization();

	return (
		<ChoiceList
			title={messages.containerStatus}
			titleHidden
			choices={[
				{
					label: messages.containerCreated,
					value: ContainerStatus.Created,
				},
				{
					label: messages.readyToLoad,
					value: ContainerStatus.ReadyToLoad,
				},
				{
					label: messages.shipped,
					value: ContainerStatus.Shipped,
				},
			]}
			selected={selected}
			onChange={onChange}
			allowMultiple
		/>
	);
}
