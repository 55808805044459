import gql from 'graphql-tag';

export const ORDER_QUERY = gql`
	query GetOrder($id: String!) {
		orderV2(id: $id) {
			id
			externalId
			isOrder
			isLate
			isAwaitingInventory
			pickStrategy
			status
			jobStatus
			expectedShipDate
			isCancelable
			createdAt
			updatedAt
			completedAt
			isPackoutRequired
			isShorted
			lines {
				id
				status
				quantity
				doneQuantity
				completedAt
				productId
				productCustomerIdentifier
				productName
				isShorted
				customer {
					name
				}
			}
			exceptions {
				id
				isHealable
			}
			servicingSorations {
				id
			}
			servicedBy {
				id
				pickStrategy
				externalContainerId
				isHealJob
				isOrder
				lines {
					id
					status
					quantity
					doneQuantity
					productId
					productDescription
					productName
				}
				servicedBy {
					id
					isOrder
					isHealJob
				}
				packout {
					id
					timeline {
						finishedAt
					}
				}
				servicedBy {
					pickStrategy
					isHealJob
					id
				}
			}
			packout {
				id
				timeline {
					finishedAt
				}
				status
			}
			healPlans {
				status
			}
			tasks {
				workArea {
					name
				}
			}
			workOrderTypes
			destinationNode
			canShipShort {
				quantity
				doneQuantity
				exceptionCount
				unassignedCount
			}
			isShippedShort
			# jobFlowRuleLpn  bulk order work
			# bulkOrderProjectionId bulk order work
		}
	}
`;
