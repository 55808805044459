import { Config, Device, DeviceType } from '@sixriver/fulfillment-api-schema';

import { Battery } from 'components/Battery';
import { NoData } from 'components/NoData';

interface BatteryLevelProps {
	device: Device;
	config?: Config;
}

export function DeviceBattery({ device, config }: BatteryLevelProps): JSX.Element {
	if (
		device.type === DeviceType.Chuck &&
		device.batteryLevel !== null &&
		device.batteryLevel !== undefined
	) {
		return (
			<Battery
				level={device.batteryLevel}
				highThreshold={config?.batteryThresholds.viable || undefined}
				lowThreshold={config?.batteryThresholds.critical || undefined}
			/>
		);
	}

	return <NoData />;
}
