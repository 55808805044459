import { Layout, Page } from '@shopify/polaris';
import { ReworkJob } from '@sixriver/fulfillment-api-schema';
import { useRouteMatch } from 'react-router-dom';
import { useQuery } from 'urql';

import { Details } from './Details';
import { JobOrigin } from './JobOrigin';
import { Overview } from './Overview';
import { Progress } from './Progress';
import { ProgressLog } from './ProgressLog';
import { REWORK_JOB_QUERY } from './ReworkJob.graphql';
import { Error } from 'components/Error';
import { useLocalization } from 'hooks/useLocalization';
import { ReworkJobStatus } from 'pages/ReworkJobs/ReworkJobsTable/ReworkJobStatus';

export function ReworkJobDetails() {
	const { messages, translate } = useLocalization();

	const {
		params: { reworkJobId },
	} = useRouteMatch<{ reworkJobId: string }>();

	// query logic
	const [{ data, error }] = useQuery<{ reworkJob: ReworkJob }>({
		query: REWORK_JOB_QUERY,
		variables: {
			id: reworkJobId,
		},
	});

	const { reworkJob } = data || {};

	const shortenedId = reworkJob?.id.substring(0, 5);

	return error ? (
		<Error graphQLError={error} />
	) : (
		<Page
			breadcrumbs={[{ content: messages.rework, url: '/rework-jobs' }]}
			title={
				translate(messages.reworkJobByExternalId, {
					externalId: `RW-${shortenedId}`,
				}) as string
			}
			titleMetadata={<ReworkJobStatus status={reworkJob?.status} />}
			fullWidth
		>
			<Layout>
				<Layout.Section>
					<Overview reworkJob={reworkJob} />
					<ProgressLog reworkJob={reworkJob} />
				</Layout.Section>
				<Layout.Section secondary>
					<Details reworkJob={reworkJob} />
					<JobOrigin reworkJob={reworkJob} />
					<Progress reworkJob={reworkJob} />
				</Layout.Section>
			</Layout>
		</Page>
	);
}
