import { PropsWithChildren, createContext, useState } from 'react';

import { noop } from 'helpers/noop';

interface ToastContextProps {
	activeToasts: Toast[];
	showToast: (newToast: Toast) => void;
	hideToast: (toastId: string) => void;
}

export const ToastContext = createContext<ToastContextProps>({
	activeToasts: [],
	showToast: noop,
	hideToast: noop,
});

export interface Toast {
	id: string;
	content: string;
	error?: boolean;
}

export function ToastProvider({ children }: PropsWithChildren<{}>) {
	const [activeToasts, setActiveToasts] = useState<Toast[]>([]);

	const showToast = (newToast: Toast) => {
		setActiveToasts((toasts) => [...toasts, newToast]);
	};

	const hideToast = (toastId: string) => {
		setActiveToasts((toasts) => toasts.filter((toast) => toast.id !== toastId));
	};

	return (
		<ToastContext.Provider value={{ activeToasts, showToast, hideToast }}>
			{children}
		</ToastContext.Provider>
	);
}
