import { IndexTable, TextStyle } from '@shopify/polaris';
import { LineStatus, LineV2 } from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';
import { useLocalization } from 'hooks/useLocalization';

interface ReasonCellProps {
	line: LineV2;
}

export function ReasonCell({ line }: ReasonCellProps) {
	const { messages } = useLocalization();

	if (line.status?.includes(LineStatus.DamagedProduct)) {
		return (
			<IndexTable.Cell>
				<TextStyle>{messages.exceptionTypes.DAMAGED_PRODUCT}</TextStyle>
			</IndexTable.Cell>
		);
	}

	if (line.status?.includes(LineStatus.MissingProduct)) {
		return (
			<IndexTable.Cell>
				<TextStyle>{messages.exceptionTypes.MISSING_PRODUCT}</TextStyle>
			</IndexTable.Cell>
		);
	}

	if (line.status?.includes(LineStatus.WorkflowException)) {
		return (
			<IndexTable.Cell>
				<TextStyle>{messages.exceptionTypes.WORKFLOW_EXCEPTION}</TextStyle>
			</IndexTable.Cell>
		);
	}

	return <NoData />;
}
