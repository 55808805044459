import {
	Card,
	TextStyle,
	Icon,
	Button,
	Subheading,
	Spinner,
	DisplayText,
	TextContainer,
	Collapsible,
} from '@shopify/polaris';
import { CaretDownMinor } from '@shopify/polaris-icons';
import { ReactNode, useState, Fragment, PropsWithChildren, useEffect } from 'react';
import { CombinedError } from 'urql';

import styles from './Timeline.module.css';
import { NoData } from 'components/NoData';
import { useLocalization } from 'hooks/useLocalization';

export interface TimelineEvent {
	id: string;
	timestamp: Date;
	title: string | ReactNode;
	details?: TimelineEventDetail[];
	actionButton?: TimelineEventButton;
	variation?: 'positive' | 'negative';
}

interface TimelineEventButton {
	content: string | string[];
	url?: string;
	disabled?: boolean;
	onClick?(): void;
}

interface TimelineEventDetail {
	label: string;
	value?: string | number | ReactNode;
}

type TimelineProps = PropsWithChildren<{
	title?: string;
	events?: TimelineEvent[];
	loading?: boolean;
	loadingMore?: boolean;
	onLoadMore?(): void;
	error?: CombinedError;
}>;

const sortByDateDescending = (a: Date, b: Date) => {
	return a > b ? -1 : 1;
};

export function Timeline(props: TimelineProps) {
	const { messages, formatDate, formatTime } = useLocalization();
	const [expandedIds, setExpandedIds] = useState<string[]>([]);
	const expandableIds = (props.events || []).filter((e) => !!e.details).map((evt) => evt.id);
	const allExpanded = expandedIds.length === expandableIds.length;

	const toggleEvent = (eventId: string) => {
		const ids = expandedIds.includes(eventId)
			? expandedIds.filter((id) => id !== eventId)
			: expandedIds.concat(eventId);

		setExpandedIds(ids);
	};

	useEffect(() => {
		setExpandedIds(expandableIds);
	}, [props.events?.length]); // eslint-disable-line

	const eventsByDay = (props.events || []).reduce<{
		[key: string]: TimelineEvent[];
	}>((byDay, event) => {
		const dayDate = new Date(event.timestamp);
		dayDate.setHours(0, 0, 0, 0);
		const day = dayDate.toISOString();

		return {
			...byDay,
			[day]: byDay[day] ? [...byDay[day], event] : [event],
		};
	}, {});

	const sortedEventsByDay = Object.keys(eventsByDay)
		.sort((a, b) => sortByDateDescending(new Date(a), new Date(b)))
		.reduce<{ [key: string]: TimelineEvent[] }>(
			(sorted, day) => ({
				...sorted,
				[day]: eventsByDay[day].sort((a, b) => sortByDateDescending(a.timestamp, b.timestamp)),
			}),
			{},
		);

	return (
		<div className={styles.container}>
			<Card
				title={props.title || messages.timeline}
				sectioned
				actions={[
					{
						content: allExpanded ? messages.hideDetails : messages.showDetails,
						onAction: () => {
							setExpandedIds(!allExpanded ? expandableIds : []);
						},
					},
				]}
			>
				{props.loading ? (
					<Spinner size="large" />
				) : props.error ? (
					<TextContainer>
						<DisplayText size="large" element="h3">
							{messages.problemLoadingHistory}
						</DisplayText>
					</TextContainer>
				) : (
					<>
						{props.children}
						<div className={styles.timeline}>
							<div className={styles.verticalLine}></div>
							{Object.keys(sortedEventsByDay).map((day) => (
								<Fragment key={day}>
									<div className={styles.day}>
										<Subheading>
											<TextStyle variation="subdued">{formatDate(new Date(day))}</TextStyle>
										</Subheading>
									</div>
									{sortedEventsByDay[day].map((event) => {
										const isExpanded = expandedIds.includes(event.id);
										const hasDetails = Array.isArray(event.details) && event.details.length > 0;
										const detailsId = `event-${event.id}-details`;

										return (
											<div className={styles.event} data-variation={event.variation} key={event.id}>
												<div
													className={styles.eventHeader}
													data-expandable={hasDetails}
													onClick={hasDetails ? () => toggleEvent(event.id) : undefined}
													aria-expanded={hasDetails ? isExpanded : undefined}
													aria-controls={hasDetails ? detailsId : undefined}
												>
													<span>
														<TextStyle variation={event.variation}>{event.title}</TextStyle>
														{hasDetails ? (
															<span className={styles.titleIcon}>
																<Icon source={CaretDownMinor} />
															</span>
														) : null}
													</span>
													<span className={styles.eventTitleTimestamp}>
														<TextStyle variation="subdued">{formatTime(event.timestamp)}</TextStyle>
													</span>
												</div>
												{event.actionButton ? (
													<div className={styles.eventButton}>
														<Button {...event.actionButton} size="slim">
															{event.actionButton.content}
														</Button>
													</div>
												) : null}
												{hasDetails ? (
													<Collapsible id={detailsId} open={isExpanded}>
														<div className={styles.eventDetails}>
															{(event.details || []).map((detail) => (
																<div className={styles.eventDetail} key={detail.label}>
																	<span className={styles.detailLabel}>{detail.label}:</span>
																	{detail.value || detail.value === 0 ? detail.value : <NoData />}
																</div>
															))}
														</div>
													</Collapsible>
												) : null}
											</div>
										);
									})}
								</Fragment>
							))}
						</div>
					</>
				)}
				<br />
				{props.onLoadMore ? (
					<Button loading={props.loadingMore} onClick={props.onLoadMore.bind(null)}>
						{messages.loadMore}
					</Button>
				) : null}
			</Card>
		</div>
	);
}
