import gql from 'graphql-tag';

import { PAGE_INFO_FRAGMENT } from 'graphql/page-info';

export const PICKING_JOBS_QUERY = gql`
	query PickingJobs(
		$after: String
		$first: Int
		$orderBy: PickingJobOrderByFields
		$orderByDirection: OrderByDirection
		$statuses: [PickingJobStatus!]
		$expectedShipDateFrom: DateTime
		$expectedShipDateTo: DateTime
		$jobCreatedAtFrom: DateTime
		$jobUpdatedAtFrom: DateTime
		$searchText: String
		$pickTypes: [PickStrategy!]
		$projectionIds: [String!]
		$includeHealJobs: Boolean
		$workAreaIds: [String!]
		$workOrderTypes: [WorkOrderType!]
	) {
		pickingJobs(
			after: $after
			first: $first
			orderBy: $orderBy
			orderByDirection: $orderByDirection
			statuses: $statuses
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			jobCreatedAtFrom: $jobCreatedAtFrom
			jobUpdatedAtFrom: $jobUpdatedAtFrom
			searchText: $searchText
			pickTypes: $pickTypes
			projectionIds: $projectionIds
			includeHealJobs: $includeHealJobs
			workAreaIds: $workAreaIds
			workOrderTypes: $workOrderTypes
		) {
			edges {
				node {
					id
					type
					pickingStatus
					exception
					expectedShipDate
					lines {
						id
						status
						quantity
						doneQuantity
						timeline {
							completedAt
						}
					}
					timeline {
						createdAt
					}
					container {
						barcode
					}
					servicing {
						... on Order {
							id
							lines {
								id
								status
							}
						}
					}
					mfp {
						name
					}
					packout {
						packStation {
							name
						}
					}
					workAreas {
						id
						name
					}
					workOrderTypes
					destinationNode
				}
			}
			pageInfo {
				...PageInfo
			}
		}
	}
	${PAGE_INFO_FRAGMENT}
`;

export const BIG_NUMBER_CARDS_STATIC_PICKING_COUNTS_QUERY = gql`
	query BigCardPickingJobCounts(
		$todayStartTime: DateTime!
		$completedStatuses: [PickingJobStatus!]
		$jobCreatedAtFrom: DateTime
	) {
		# This count will be for all jobs completed today (used for Total Jobs Card)
		BigCardJobsCompletedTodayCount: pickingJobsCount(
			statuses: $completedStatuses
			jobUpdatedAtFrom: $todayStartTime
			includeHealJobs: false
			jobCreatedAtFrom: $jobCreatedAtFrom
		) {
			count
		}

		# Picking
		BigCardPickingJobsCount: pickingJobsCount(
			statuses: [Picking]
			includeHealJobs: false
			jobCreatedAtFrom: $jobCreatedAtFrom
		) {
			count
		}

		# Picked
		BigCardPickedJobsCount: pickingJobsCount(
			statuses: [Picked]
			includeHealJobs: false
			jobCreatedAtFrom: $jobCreatedAtFrom
		) {
			count
		}

		# Ready-to-Sort
		BigCardReadyToSortJobsCount: pickingJobsCount(
			statuses: [Sortable]
			includeHealJobs: false
			jobCreatedAtFrom: $jobCreatedAtFrom
		) {
			count
		}

		# Sorting
		BigCardSortingJobsCount: pickingJobsCount(
			statuses: [Sorting]
			includeHealJobs: false
			jobCreatedAtFrom: $jobCreatedAtFrom
		) {
			count
		}

		# Sorted
		BigCardSortedJobsCount: pickingJobsCount(
			statuses: [Sorted]
			includeHealJobs: false
			jobCreatedAtFrom: $jobCreatedAtFrom
		) {
			count
		}

		# Ready-to-Pack
		BigCardReadyToPackJobsCount: pickingJobsCount(
			statuses: [Packable]
			includeHealJobs: false
			jobCreatedAtFrom: $jobCreatedAtFrom
		) {
			count
		}

		# Packing
		BigCardPackingJobsCount: pickingJobsCount(
			statuses: [Packing]
			includeHealJobs: false
			jobCreatedAtFrom: $jobCreatedAtFrom
		) {
			count
		}
	}
`;

export const FILTERED_PICKING_COUNTS_QUERY = gql`
	query FilteredCountPickingJobs(
		$expectedShipDateFrom: DateTime
		$expectedShipDateTo: DateTime
		$includeHealJobs: Boolean
		$pickTypes: [PickStrategy!]
		$projectionIds: [String!]
		$searchText: String
		$workAreaIds: [String!]
		$todayStartTime: DateTime!
		$jobCreatedAtFrom: DateTime
		$workOrderTypes: [WorkOrderType!]
		$statuses: [PickingJobStatus!]
	) {
		filteredTotalCount: pickingJobsCount(
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			includeHealJobs: $includeHealJobs
			jobCreatedAtFrom: $jobCreatedAtFrom
			pickTypes: $pickTypes
			projectionIds: $projectionIds
			searchText: $searchText
			workAreaIds: $workAreaIds
			workOrderTypes: $workOrderTypes
			statuses: $statuses
		) {
			count
		}

		filteredCancelledTodayCount: pickingJobsCount(
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			includeHealJobs: $includeHealJobs
			jobCreatedAtFrom: $jobCreatedAtFrom
			jobUpdatedAtFrom: $todayStartTime
			pickTypes: $pickTypes
			projectionIds: $projectionIds
			searchText: $searchText
			workAreaIds: $workAreaIds
			statuses: [Cancelled]
			workOrderTypes: $workOrderTypes
		) {
			count
		}

		filteredExceptionsCount: pickingJobsCount(
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			includeHealJobs: $includeHealJobs
			pickTypes: $pickTypes
			projectionIds: $projectionIds
			searchText: $searchText
			statuses: [Exception]
			workAreaIds: $workAreaIds
			jobCreatedAtFrom: $jobCreatedAtFrom
			workOrderTypes: $workOrderTypes
		) {
			count
		}

		filteredExceptionsTodayCount: pickingJobsCount(
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			includeHealJobs: $includeHealJobs
			jobUpdatedAtFrom: $todayStartTime
			pickTypes: $pickTypes
			projectionIds: $projectionIds
			searchText: $searchText
			statuses: [Exception]
			workAreaIds: $workAreaIds
			jobCreatedAtFrom: $jobCreatedAtFrom
			workOrderTypes: $workOrderTypes
		) {
			count
		}

		filteredInterruptedCount: pickingJobsCount(
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			includeHealJobs: $includeHealJobs
			pickTypes: $pickTypes
			projectionIds: $projectionIds
			searchText: $searchText
			statuses: [Offline]
			workAreaIds: $workAreaIds
			jobCreatedAtFrom: $jobCreatedAtFrom
			workOrderTypes: $workOrderTypes
		) {
			count
		}
	}
`;

export const CUTOFF_DATES_QUERY = gql`
	query CutoffForPickingJobs($createdAtFrom: DateTime, $datePrecision: DatePrecision!) {
		cutoffForPickingJobs(datePrecision: $datePrecision, createdAtFrom: $createdAtFrom) {
			dates
		}
	}
`;
