import React from 'react';
import ReactDOM from 'react-dom';
import '@shopify/polaris/build/esm/styles.css';

import { App } from 'app';
import { AppProvider } from 'providers';

ReactDOM.render(
	<React.StrictMode>
		{/* PUBLIC_URL is only set in CI for cloud deployment, value will look like "/fms-ui/1.0.0/" */}
		<AppProvider>
			<App />
		</AppProvider>
	</React.StrictMode>,
	document.getElementById('root'),
);
