import { IndexTable, Link } from '@shopify/polaris';

import * as routes from 'routes';

interface LaneNameCellProps {
	laneId: string;
}

export function LaneNameCell({ laneId }: LaneNameCellProps) {
	return (
		<IndexTable.Cell>
			<Link url={routes.lane(encodeURIComponent(laneId))} removeUnderline>
				{laneId}
			</Link>
		</IndexTable.Cell>
	);
}
