import { IndexTable, TextStyle } from '@shopify/polaris';
import { LineV2 } from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';

interface Props {
	line: LineV2;
}

export function ProductDescriptionCell({ line }: Props): JSX.Element {
	if (line.productDescription) {
		return (
			<IndexTable.Cell>
				<TextStyle>{line.productDescription}</TextStyle>
			</IndexTable.Cell>
		);
	}
	return (
		<IndexTable.Cell>
			<NoData />
		</IndexTable.Cell>
	);
}
