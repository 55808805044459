import gql from 'graphql-tag';

import { PAGE_INFO_FRAGMENT } from 'graphql/page-info';

export const DEVICES_QUERY = gql`
	query Devices(
		$types: [DeviceType!]
		$searchText: String
		$states: [DeviceState!]
		$after: String
		$first: Int
		$orderBy: DeviceOrderByFields
		$orderByDirection: OrderByDirection
		$workAreaIds: [String!]
	) {
		devices(
			types: $types
			searchText: $searchText
			states: $states
			after: $after
			first: $first
			orderBy: $orderBy
			orderByDirection: $orderByDirection
			workAreaIds: $workAreaIds
		) {
			edges {
				node {
					id
					name
					type
					batteryLevel
					state
					updatedAt
					workArea {
						id
						name
					}
				}
			}
			pageInfo {
				...PageInfo
			}
		}
	}
	${PAGE_INFO_FRAGMENT}
`;

export const DEVICE_QUERY = gql`
	query Device($name: String!) {
		device(name: $name) {
			id
			name
			type
			batteryLevel
			state
			updatedAt
			calibratedAt
			ssid
			orders {
				id
			}
			pickingJobs {
				id
			}
			packouts {
				id
				orders {
					id
				}
				pickingJobs {
					id
				}
			}
			sortations {
				id
				customerOrders {
					id
				}
				pickingJobs {
					id
				}
			}
			workArea {
				id
				name
			}
		}
	}
`;

export const COUNTS_QUERY = gql`
	query CountDevices($searchText: String, $states: [DeviceState!], $workAreaIds: [String!]) {
		ChuckCount: devicesCount(
			types: [Chuck]
			searchText: $searchText
			states: $states
			workAreaIds: $workAreaIds
		) {
			count
		}
		SortKioskCount: devicesCount(
			types: [SortationKiosk]
			searchText: $searchText
			states: $states
			workAreaIds: $workAreaIds
		) {
			count
		}
		PackoutCount: devicesCount(
			types: [PackoutTerminal]
			searchText: $searchText
			states: $states
			workAreaIds: $workAreaIds
		) {
			count
		}
		TerminalOnWheelsCount: devicesCount(
			types: [TerminalOnWheels]
			searchText: $searchText
			states: $states
			workAreaIds: $workAreaIds
		) {
			count
		}
		HandheldCount: devicesCount(
			types: [Handheld]
			searchText: $searchText
			states: $states
			workAreaIds: $workAreaIds
		) {
			count
		}
	}
`;
