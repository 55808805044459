import { Icon, Tooltip } from '@shopify/polaris';
import { AlertMinor } from '@shopify/polaris-icons';
import { JobAllocationMethod } from '@sixriver/fulfillment-api-schema';

import { Toteboard } from 'components/Toteboard';
import { useConfig } from 'hooks/useConfig';
import { useLocalization } from 'hooks/useLocalization';

export interface OrdersToteboardProps {
	unassignedCount?: number;
	inProgressCount?: number;
	completedCount?: number;
	cancelledCount?: number;
	interruptedCount?: number;
	assignedToBatchCount?: number;
	assignedToSortWallCount?: number;
}

export function OrdersToteboard({
	unassignedCount = 0,
	inProgressCount = 0,
	completedCount = 0,
	interruptedCount = 0,
	assignedToBatchCount = 0,
	assignedToSortWallCount = 0,
}: OrdersToteboardProps) {
	const { messages, formatNumber } = useLocalization();
	const { config } = useConfig();
	let tooltipContent = messages.orderHelperTextActive;
	if (config?.sortationEnabled) {
		tooltipContent = messages.orderHelperTextActiveWithSortation;
	}

	if (config?.jobAllocationMethods.includes(JobAllocationMethod.BulkOrders)) {
		tooltipContent = messages.orderHelperTextActiveWithBulkOrder;
	}

	if (
		config?.sortationEnabled &&
		config?.jobAllocationMethods.includes(JobAllocationMethod.BulkOrders)
	) {
		tooltipContent = messages.orderHelperTextActiveWithBulkOrderAndSortation;
	}

	const toteboardItems = [
		{
			label: messages.activeOrders,
			tooltip: (
				<Tooltip content={tooltipContent} dismissOnMouseOut>
					<Icon source={AlertMinor} color="subdued" />
				</Tooltip>
			),
			primaryValue: (
				<span>
					{formatNumber(
						unassignedCount +
							assignedToSortWallCount +
							assignedToBatchCount +
							inProgressCount +
							interruptedCount +
							completedCount
					)}
				</span>
			),
		},
		{
			label: messages.unassigned,
			primaryValue: <span>{formatNumber(unassignedCount)}</span>,
		},
		...(config?.sortationEnabled
			? [
					{
						label: messages.assignedToWall,
						primaryValue: <span>{formatNumber(assignedToSortWallCount)}</span>,
					},
			  ]
			: []),
		...(config?.jobAllocationMethods.includes(JobAllocationMethod.BulkOrders)
			? [
					{
						label: messages.assignedToBatch,
						primaryValue: <span>{formatNumber(assignedToBatchCount)}</span>,
					},
			  ]
			: []),
		{
			label: messages.inProgress,
			primaryValue: <span>{formatNumber(inProgressCount)}</span>,
		},
		{
			label: messages.completedToday,
			tooltip: (
				<Tooltip content={messages.orderHelperTextCompleted} dismissOnMouseOut>
					<Icon source={AlertMinor} color="subdued" />
				</Tooltip>
			),
			primaryValue: <span>{formatNumber(completedCount)}</span>,
		},
		{
			label: messages.interrupted,
			primaryValue: <span>{formatNumber(interruptedCount)}</span>,
		},
	];

	return <Toteboard items={toteboardItems} />;
}
