import { Layout, Modal, Page, Stack, TextContainer, TextStyle } from '@shopify/polaris';
import { DuplicateMinor, MobileCancelMajor, UpdateInventoryMajor } from '@shopify/polaris-icons';
import {
	ShippingNotice,
	ShippingNoticeStatus,
	ShippingNoticeStatusInput,
} from '@sixriver/fulfillment-api-schema';
import { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useQuery, useMutation } from 'urql';

import { Attachments } from './Attachments';
import { Details } from './Details';
import {
	SHIPPING_NOTICE_QUERY,
	CLOSE_SHIPPING_NOTICE_MUTATION,
	CANCEL_SHIPPING_NOTICE_MUTATION,
	ARRIVE_SHIPPING_NOTICE_MUTATION,
} from './InboundShipment.graphql';
import { InternalNotes } from './InternalNotes';
import { IssueSummary } from './IssueSummary';
import { Lines } from './Lines';
import { Progress } from './Progress';
import { TrackingInformation } from './TrackingInformation';
import { Error } from 'components/Error';
import { ErrorBanner } from 'components/ErrorBanner';
import { TimezoneFooter } from 'components/TimezoneFooter';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import { useLocalization } from 'hooks/useLocalization';
import { InboundShipmentProgress } from 'pages/InboundShipment/InboundShipmentProgress';

interface ModalArgs {
	visible: boolean;
	title?: string;
	message?: string;
	primaryLabel?: string;
	secondaryLabel?: string;
	buttonAction?(): void;
	destructive?: boolean;
}

export function InboundShipment() {
	const { messages } = useLocalization();
	const { copyToClipboard } = useCopyToClipboard();

	const {
		params: { inboundShipmentId },
	} = useRouteMatch<{ inboundShipmentId: string }>();

	const [{ fetching, error, data: shippingNoticeData }, refetchShipment] = useQuery<{
		shippingNotice: ShippingNotice;
	}>({
		query: SHIPPING_NOTICE_QUERY,
		variables: {
			id: inboundShipmentId,
		},
	});

	const shippingNotice = shippingNoticeData?.shippingNotice;

	const [modalArgs, setModalArgs] = useState<ModalArgs>({
		visible: false,
	});

	const [, closeMutation] = useMutation<
		{ completeShippingNotice: Response },
		ShippingNoticeStatusInput
	>(CLOSE_SHIPPING_NOTICE_MUTATION);

	const [, arriveMutation] = useMutation<
		{ arriveShippingNotice: Response },
		ShippingNoticeStatusInput
	>(ARRIVE_SHIPPING_NOTICE_MUTATION);

	const [, cancelMutation] = useMutation<
		{ cancelShippingNotice: Response },
		ShippingNoticeStatusInput
	>(CANCEL_SHIPPING_NOTICE_MUTATION);

	const [isBannerErrorVisible, setIsBannerErrorVisible] = useState(false);
	const [closeShipmentEnabled, setCloseShipmentEnabled] = useState(false);
	const [arriveShipmentEnabled, setArriveShipmentEnabled] = useState(false);
	const [cancelShipmentEnabled, setCancelShipmentEnabled] = useState(false);

	const runMutation = async (mutation: Function) => {
		try {
			const { error } = await mutation.call(null, {
				input: {
					externalId: shippingNotice?.externalId,
				},
			});

			if (error) {
				throw error;
			}

			refetchShipment();
		} catch (e) {
			setIsBannerErrorVisible(true);
		}
	};

	useEffect(() => {
		const status = shippingNotice?.status as ShippingNoticeStatus;

		setCloseShipmentEnabled(
			[ShippingNoticeStatus.Receiving, ShippingNoticeStatus.AuditRequired].includes(status),
		);
		setArriveShipmentEnabled(
			[ShippingNoticeStatus.Submitted, ShippingNoticeStatus.InTransit].includes(status),
		);
		setCancelShipmentEnabled(
			[
				ShippingNoticeStatus.Submitted,
				ShippingNoticeStatus.InTransit,
				ShippingNoticeStatus.Arrived,
			].includes(status),
		);
	}, [shippingNotice]);

	if (error) {
		return <Error graphQLError={error} />;
	}

	if (!fetching && !shippingNotice) {
		return <Error heading={messages.shipmentNotFound} />;
	}

	return (
		<Page
			breadcrumbs={[{ content: messages.inboundShipments, url: '/inbound-shipments' }]}
			title={shippingNotice?.externalId}
			titleMetadata={
				<Stack spacing="extraTight">
					<InboundShipmentProgress status={shippingNotice?.status} />
				</Stack>
			}
			primaryAction={{
				content: messages.closeShipment,
				onAction: () =>
					setModalArgs({
						visible: true,
						title: messages.closeShipment,
						message: messages.confirmCloseShipment,
						primaryLabel: messages.closeShipment,
						secondaryLabel: messages.cancel,
						buttonAction: () => runMutation(closeMutation),
					}),
				disabled: !closeShipmentEnabled,
			}}
			secondaryActions={[
				{
					content: messages.copyShipmentNumber,
					icon: DuplicateMinor,
					onAction: shippingNotice?.externalId
						? () => copyToClipboard(shippingNotice?.externalId)
						: undefined,
					disabled: !shippingNotice?.externalId,
				},
				{
					content: messages.markAsArrived,
					icon: UpdateInventoryMajor,
					onAction: () =>
						setModalArgs({
							visible: true,
							title: messages.markAsArrived,
							message: messages.confirmArriveShipment,
							primaryLabel: messages.markAsArrived,
							secondaryLabel: messages.cancel,
							buttonAction: () => runMutation(arriveMutation),
						}),
					disabled: !arriveShipmentEnabled,
				},
				{
					content: messages.cancelShipment,
					icon: MobileCancelMajor,
					onAction: () =>
						setModalArgs({
							visible: true,
							title: messages.cancelShipment,
							message: messages.confirmCancelShipment,
							primaryLabel: messages.cancelShipment,
							secondaryLabel: messages.goBack,
							buttonAction: () => runMutation(cancelMutation),
							destructive: true,
						}),
					disabled: !cancelShipmentEnabled,
				},
			]}
		>
			<Layout>
				<Layout.Section>
					<ErrorBanner
						isVisible={isBannerErrorVisible}
						onDismiss={() => {
							setIsBannerErrorVisible(false);
						}}
					/>
				</Layout.Section>
				<Layout.Section>
					<Stack distribution="fillEvenly">
						<Details shippingNotice={shippingNotice} />
						<TrackingInformation shippingNotice={shippingNotice} />
					</Stack>
				</Layout.Section>
				<Layout.Section>
					<Progress shippingNotice={shippingNotice} />
				</Layout.Section>
				<Layout.Section>
					<IssueSummary shippingNotice={shippingNotice} refetchShipment={refetchShipment} />
				</Layout.Section>
				<Layout.Section>
					<Attachments shippingNotice={shippingNotice} refetchShipment={refetchShipment} />
				</Layout.Section>
				<Layout.Section>
					<InternalNotes shippingNotice={shippingNotice} refetchShipment={refetchShipment} />
				</Layout.Section>
				<Layout.Section>
					<Lines inboundLines={shippingNotice?.inboundLines} asnStatus={shippingNotice?.status} />
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
			<Modal
				open={modalArgs.visible}
				onClose={() => setModalArgs({ visible: false })}
				title={modalArgs.title}
				primaryAction={{
					content: modalArgs.primaryLabel,
					onAction: () => {
						setIsBannerErrorVisible(false);

						if (modalArgs.buttonAction) {
							modalArgs.buttonAction();
						}

						setModalArgs({ visible: false });
					},
					destructive: modalArgs.destructive,
					loading: false,
				}}
				secondaryActions={[
					{
						content: modalArgs.secondaryLabel,
						onAction: () => setModalArgs({ visible: false }),
					},
				]}
			>
				<Modal.Section>
					<TextContainer>
						<TextStyle>{modalArgs.message}</TextStyle>
					</TextContainer>
				</Modal.Section>
			</Modal>
		</Page>
	);
}
