import { Link } from '@shopify/polaris';
import {
	OrderByDirection,
	StorageLocationInventory,
	StorageLocationOrderByFields,
	StorageLocationTypeCount,
} from '@sixriver/fulfillment-api-schema';

import { Column, DataTable } from 'components/DataTable';
import { NoData } from 'components/NoData';
import { useFilters, useSetFilters } from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';
import { useLocationTabs, LOCATION_TYPES } from 'hooks/useLocationTabs';
import * as routes from 'routes';

interface Props {
	storageLocationInventories: StorageLocationInventory[];
}

export function LocationsTable({ storageLocationInventories }: Props) {
	const { messages, formatNumber } = useLocalization();

	const {
		view = 'all',
		query = '',
		sort = StorageLocationOrderByFields.Address + ' ' + OrderByDirection.Asc,
	} = useFilters([]);

	const setFilters = useSetFilters();

	const sorts = [
		{
			label: messages.address,
			value: StorageLocationOrderByFields.Address + ' ' + OrderByDirection.Asc,
		},
		{
			label: messages.locationType,
			value: StorageLocationOrderByFields.Type + ' ' + OrderByDirection.Asc,
		},
	];

	const columns: Column[] = [
		{
			type: 'text',
			heading: messages.location,
		},
		{
			type: 'text',
			heading: messages.locationType,
		},
		{
			type: 'text',
			heading: messages.quantity,
		},
		{
			type: 'text',
			heading: messages.plannedRemovals,
		},
		{
			type: 'text',
			heading: messages.available,
		},
	];

	const matches = storageLocationInventories.filter(({ location }) => {
		return location.address.toLowerCase().includes(query.trim().toLowerCase());
	});

	const counts: StorageLocationTypeCount[] = LOCATION_TYPES.map((type) => {
		const count = matches.filter(({ location }) => location.type === type).length;

		return {
			type,
			count,
		};
	});

	const rows = matches
		.sort((a, b) => {
			if (sort.startsWith(StorageLocationOrderByFields.Address)) {
				return a.location.address > b.location.address ? 1 : -1;
			}

			return (a.location.type || '') > (b.location.type || '') ? 1 : -1;
		})
		.filter(({ location }) => {
			return view === 'all' || view === location.type;
		})
		.map(({ location, inventory }) => {
			return [
				<Link key={location.address} url={routes.location(location.id)}>
					{location.address}
				</Link>,
				location.type ? messages.locationTypes[location.type] : <NoData />,
				formatNumber(inventory.liveQuantity),
				formatNumber(inventory.totalPlannedRemoveQuantity * -1),
				formatNumber(inventory.balance),
			];
		});

	const views = useLocationTabs(counts);

	return (
		<DataTable
			title={messages.locations}
			views={views}
			columns={columns}
			rows={rows}
			query={query}
			selectedView={view}
			sortChoices={sorts}
			sortValue={sort}
			setFilters={setFilters}
			queryPlaceholder={messages.filterLocations}
			emptyStateHeading={messages.locationsNotFound}
		/>
	);
}
