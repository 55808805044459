import { useLocalization } from 'hooks/useLocalization';
import { useToast } from 'hooks/useToast';

export const useCopyToClipboard = () => {
	const { messages } = useLocalization();
	const { showToast } = useToast();

	return {
		copyToClipboard: async (textToCopy?: string | null) => {
			if (textToCopy) {
				try {
					await navigator.clipboard.writeText(textToCopy);
					showToast(messages.copiedToClipboard);
				} catch (e) {
					showToast(messages.copyFailed, true);
				}
			}
		},
	};
};
