import { Badge } from '@shopify/polaris';
import { ReturnStatus } from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';
import { useLocalization } from 'hooks/useLocalization';

export function ReturnStatusBadge({ status }: { status?: ReturnStatus }) {
	const { messages } = useLocalization();

	const statusToBadgeStatus: {
		[key in ReturnStatus]?: 'info' | 'critical' | 'warning';
	} = {
		[ReturnStatus.Submitted]: 'info',
		[ReturnStatus.InTransit]: 'info',
		[ReturnStatus.Arrived]: 'warning',
		[ReturnStatus.Returning]: 'warning',
		[ReturnStatus.Completed]: undefined,
		[ReturnStatus.Cancelled]: 'critical',
		[ReturnStatus.AuditRequired]: 'warning',
		[ReturnStatus.CancelRequested]: 'warning',
	};

	const labels = {
		[ReturnStatus.Arrived]: messages.arrived,
		[ReturnStatus.AuditRequired]: messages.auditRequired,
		[ReturnStatus.Cancelled]: messages.canceled,
		[ReturnStatus.CancelRequested]: messages.cancellationRequested,
		[ReturnStatus.Completed]: messages.completed,
		[ReturnStatus.InTransit]: messages.inTransit,
		[ReturnStatus.Returning]: messages.inProgress,
		[ReturnStatus.Submitted]: messages.submitted,
	};

	return status ? <Badge status={statusToBadgeStatus[status]}>{labels[status]}</Badge> : <NoData />;
}
