import { Stack, Pagination as PolarisPagination } from '@shopify/polaris';

// Copied from React
type SetState<S> = (prevState: S) => S;
type Dispatch<A> = (value: A) => void;
export type Cursors = string[];
export type SetCursors = Dispatch<SetState<Cursors>>;

interface Props {
	cursors: Cursors;
	endCursor?: string | null;
	setCursors: SetCursors;
	loading?: boolean;
}

export function Pagination({ cursors, endCursor, setCursors, loading }: Props) {
	const onNext = () => {
		if (loading) return;
		setCursors((prevState) => {
			window.scrollTo(0, 0);

			if (endCursor) {
				return [endCursor].concat(prevState);
			}
			return prevState;
		});
	};

	const onPrevious = () => {
		if (loading) return;
		setCursors((prevState) => {
			window.scrollTo(0, 0);

			if (loading) {
				return prevState;
			}
			return prevState.slice(1);
		});
	};

	return (
		<Stack distribution="center">
			<PolarisPagination
				hasNext={!!endCursor}
				hasPrevious={cursors.length > 0}
				onNext={onNext}
				onPrevious={onPrevious}
			/>
		</Stack>
	);
}
