import { useEffect, useState } from 'react';

import { useUserPreferences } from './use-user-preferences';
import { BridgeVersion, UserPreferenceKey } from './user-preferences';

interface UseBridgeVersion {
	bridgeVersion: BridgeVersion;
}

export function useBridgeVersion(): UseBridgeVersion {
	const { userPreferences } = useUserPreferences();
	const [bridgeVersion, setBridgeVersion] = useState(BridgeVersion.Classic);

	useEffect(() => {
		const bridgeVersionUserPreference = userPreferences.find(
			(userPreference) => userPreference.key === UserPreferenceKey.BridgeVersion,
		);
		if (bridgeVersionUserPreference !== undefined) {
			setBridgeVersion(bridgeVersionUserPreference.value as unknown as BridgeVersion);
		}
	}, [userPreferences]);

	return { bridgeVersion };
}
