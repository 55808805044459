import { ReturnStatus } from '@sixriver/fulfillment-api-schema';

export enum InboundReturnViews {
	All = 'All returns',
	InTransit = 'In transit',
	InProgress = 'In progress',
	Complete = 'Complete',
	Cancelled = 'Cancelled',
	CancelRequested = 'Cancel requested',
}

export const InboundReturnViewStateMap: {
	[key in InboundReturnViews]: ReturnStatus[];
} = {
	[InboundReturnViews.All]: [],
	[InboundReturnViews.InTransit]: [ReturnStatus.InTransit],
	[InboundReturnViews.InProgress]: [ReturnStatus.Arrived, ReturnStatus.Returning],
	[InboundReturnViews.Complete]: [ReturnStatus.Completed],
	[InboundReturnViews.Cancelled]: [ReturnStatus.Cancelled],
	[InboundReturnViews.CancelRequested]: [ReturnStatus.CancelRequested],
};
