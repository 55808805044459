import { LinesViews } from '@sixriver/fulfillment-api-schema';

import { Tab, ViewTabs } from 'components/ViewTabs';
import { noop } from 'helpers/noop';
import { useLocalization } from 'hooks/useLocalization';

export enum LaneContainersTab {
	all = 'all',
}

export interface LaneContainersTabsCounts {
	all?: number;
}

export interface LaneContainersTabsProps {
	tabs: LaneContainersTab[];
	counts: LaneContainersTabsCounts;
	selected: string;
	onSelect?: (tab: LaneContainersTab.all) => void;
}

export function LaneContainersTabs({
	tabs,
	counts,
	selected,
	onSelect = noop,
}: LaneContainersTabsProps) {
	const { messages } = useLocalization();
	const tabsToShow = tabs
		.map((tab) => {
			switch (tab) {
				case LaneContainersTab.all:
					return {
						label: messages.all,
						metaLabel: counts.all ?? 0,
						id: LinesViews.All,
					};
				default:
					return null;
			}
		})
		.filter((tab) => !!tab);

	return <ViewTabs tabs={tabsToShow as Tab[]} selected={selected} onSelect={onSelect} />;
}
