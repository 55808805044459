import { PickingJobStatus } from '@sixriver/fulfillment-api-schema';

export enum SortWallPickingJobsViews {
	All = 'All',
	Picking = 'Picking',
	ReadyToSort = 'ReadyToSort',
	Sorting = 'Sorting',
	Sorted = 'Sorted',
	ReadyToPack = 'ReadyToPack',
	Exceptions = 'Exceptions',
}

export const SortWallPickingJobsViewStateMap: {
	[key in SortWallPickingJobsViews]: PickingJobStatus[];
} = {
	[SortWallPickingJobsViews.All]: [
		PickingJobStatus.Unassigned,
		PickingJobStatus.Picking,
		PickingJobStatus.Picked,
		PickingJobStatus.Packable,
		PickingJobStatus.Packed,
		PickingJobStatus.Packing,
		PickingJobStatus.Sortable,
		PickingJobStatus.Sorting,
		PickingJobStatus.Sorted,
		PickingJobStatus.Exception,
		PickingJobStatus.AwaitingInventory,
		PickingJobStatus.Cancelled,
		PickingJobStatus.Offline,
	],
	[SortWallPickingJobsViews.Picking]: [PickingJobStatus.Picking],
	[SortWallPickingJobsViews.ReadyToSort]: [PickingJobStatus.Sortable],
	[SortWallPickingJobsViews.Sorting]: [PickingJobStatus.Sorting],
	[SortWallPickingJobsViews.Sorted]: [PickingJobStatus.Sorted],
	[SortWallPickingJobsViews.ReadyToPack]: [PickingJobStatus.Packable],
	[SortWallPickingJobsViews.Exceptions]: [PickingJobStatus.Exception],
};
