import { TextContainer, TextStyle } from '@shopify/polaris';
import { ReworkJobType } from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';
import { useLocalization } from 'hooks/useLocalization';

export function JobType({ jobType }: { jobType?: ReworkJobType }) {
	const { messages } = useLocalization();

	const labels = {
		[ReworkJobType.AdvanceShippingNotice]: messages.inbound,
		[ReworkJobType.Return]: messages.return,
		[ReworkJobType.Other]: messages.otherType,
	};

	return jobType ? (
		<TextContainer>
			<TextStyle>{labels[jobType]}</TextStyle>
		</TextContainer>
	) : (
		<NoData />
	);
}
