import {
	Count,
	DeviceConnection,
	MapPointConfig,
	MapPointConfigInput,
	MutationResponse,
	User,
	WorkArea,
} from '@sixriver/fulfillment-api-schema';
import gql from 'graphql-tag';
import { useMutation, useQuery } from 'urql';

import { usePollingQuery } from 'hooks/usePollingQuery';

export function useWorkAreasQuery() {
	const query = gql`
		query GetWorkAreas {
			workAreas {
				id
				name
				map
			}
		}
	`;

	const [{ error, fetching, data }] = useQuery<{
		workAreas: WorkArea[];
	}>({
		query,
	});

	const results = data?.workAreas || [];

	return { error, fetching, results };
}

export function useDevicesQuery() {
	const query = gql`
		query GetDevices {
			devices(types: [Chuck], first: 99) {
				edges {
					node {
						id
						name
						badge
						phase
						workAreaId
						updatedAt
					}
				}
			}
		}
	`;

	const [{ error, fetching, data }] = usePollingQuery<{
		devices: DeviceConnection;
	}>({
		query,
		pause: !navigator.onLine,
		pollInterval: 10000, // 10 seconds
	});

	const results = data?.devices?.edges.map((edge) => edge.node) || [];

	return { error, fetching, results };
}

export function useMapPointsQuery() {
	const query = gql`
		query MapPointConfigs {
			mapPointConfigs {
				mapPointName
				enabled
				maxReservationCount
			}
		}
	`;

	const [{ error, fetching, data }, refetch] = usePollingQuery<{
		mapPointConfigs: MapPointConfig[];
	}>({
		query,
		pollInterval: 300000, // 5 minutes
	});

	const results = data?.mapPointConfigs || [];

	return { error, fetching, results, refetch };
}

export function useCountJobsQuery(deviceId: string, visible?: boolean) {
	const query = gql`
		query CountJobs($deviceId: String!) {
			pickingJobsInProgressCount(deviceId: $deviceId) {
				count
			}
		}
	`;

	const [{ error, fetching, data }] = usePollingQuery<{
		pickingJobsInProgressCount: Count;
	}>({
		query,
		pause: !visible,
		variables: {
			deviceId,
		},
		pollInterval: 5000, // 5 seconds
	});

	const result = data?.pickingJobsInProgressCount;

	return { error, fetching, result };
}

export function useUserQuery(badgeBarcode?: string, visible?: boolean) {
	const query = gql`
		query GetUser($badgeBarcode: String!) {
			userByBadgeBarcode(badgeBarcode: $badgeBarcode) {
				name
				id
			}
		}
	`;

	const [{ error, fetching, data }] = useQuery<{
		userByBadgeBarcode: User;
	}>({
		query,
		pause: !badgeBarcode || !visible,
		variables: {
			badgeBarcode,
		},
		requestPolicy: 'cache-first',
	});

	const result = data?.userByBadgeBarcode || null;

	return { error, fetching, result };
}

export function useMapPointMutation() {
	const query = gql`
		mutation EditMapPointConfig($input: MapPointConfigInput!) {
			editMapPointConfig(input: $input) {
				success
			}
		}
	`;

	const [{ error, fetching, data }, mutate] = useMutation<
		{ editMapPointConfig: MutationResponse },
		{ input: MapPointConfigInput }
	>(query);

	const result = data;

	return { error, fetching, result, mutate };
}
