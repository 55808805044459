import { Filters, Stack } from '@shopify/polaris';

import { noop } from 'helpers/noop';
import { useLocalization } from 'hooks/useLocalization';

interface LaneContainerOrdersFiltersProps {
	orderNumberQueryValue?: string;
	trackingNumberQueryValue?: string;
	onOrderNumberQueryChange?: (value: string) => void;
	onTrackingNumberQueryChange?: (value: string) => void;
	onOrderNumberClearAll?: () => void;
	onTrackingNumberClearAll?: () => void;
}

export function LaneContainerOrdersFilters({
	orderNumberQueryValue,
	trackingNumberQueryValue,
	onOrderNumberQueryChange = noop,
	onTrackingNumberQueryChange = noop,
	onOrderNumberClearAll = noop,
	onTrackingNumberClearAll = noop,
}: LaneContainerOrdersFiltersProps) {
	const { messages } = useLocalization();

	return (
		<Stack vertical={false} distribution="fillEvenly">
			<Filters
				queryValue={orderNumberQueryValue}
				queryPlaceholder={messages.filterLaneContainerOrdersByID}
				onQueryChange={onOrderNumberQueryChange}
				onQueryClear={() => onOrderNumberQueryChange('')}
				onClearAll={onOrderNumberClearAll}
				filters={[]}
			></Filters>
			<Filters
				queryValue={trackingNumberQueryValue}
				queryPlaceholder={messages.filterLaneContainerOrdersByTrackingNumber}
				onQueryChange={onTrackingNumberQueryChange}
				onQueryClear={() => onTrackingNumberQueryChange('')}
				onClearAll={onTrackingNumberClearAll}
				filters={[]}
			></Filters>
		</Stack>
	);
}
