import { IndexTable } from '@shopify/polaris';
import { LaneContainer } from '@sixriver/fulfillment-api-schema';

import { ContainerExceptionBadge } from 'components/ContainerExceptionBadge';
import { NoData } from 'components/NoData';

interface Props {
	container: LaneContainer;
}

export function ExceptionCell({ container }: Props): JSX.Element {
	if (container.id) {
		return (
			<IndexTable.Cell>
				<ContainerExceptionBadge container={container} />
			</IndexTable.Cell>
		);
	}

	return (
		<IndexTable.Cell>
			<NoData />
		</IndexTable.Cell>
	);
}
