import {
	MutationCancelOrderArgs,
	MutationResponse,
	MutationCancelOrderV2Args,
} from '@sixriver/fulfillment-api-schema';
import gql from 'graphql-tag';
import { useState } from 'react';
import { useMutation } from 'urql';

import { BridgeVersion, useBridgeVersion } from 'hooks/useUserPreferences';

const CANCEL_ORDER_MUTATION = gql`
	mutation CancelOrder($projectionId: String!) {
		cancelOrder(projectionId: $projectionId) {
			success
		}
	}
`;

const CANCEL_ORDER_V2_MUTATION = gql`
	mutation CancelOrder($id: String!) {
		cancelOrderV2(id: $id) {
			success
		}
	}
`;

export const useCancelOrder = ({
	orderId,
	onSuccessfulCancel,
	onError,
	onBefore,
}: {
	orderId: string;
	onSuccessfulCancel?: () => void;
	onError?: () => void;
	onBefore?: () => void;
}) => {
	const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
	const { bridgeVersion } = useBridgeVersion();

	const closeCancelModal = () => {
		setIsCancelModalOpen(false);
	};

	const openCancelModal = () => {
		setIsCancelModalOpen(true);
	};

	const [{ fetching: cancelOrderLoading, error: cancelOrderError }, cancelOrderMutation] =
		useMutation<{ cancelOrder: MutationResponse }, MutationCancelOrderArgs>(CANCEL_ORDER_MUTATION);

	const [{ fetching: cancelOrderV2Loading, error: cancelOrderV2Error }, cancelOrderV2Mutation] =
		useMutation<{ cancelOrderV2: MutationResponse }, MutationCancelOrderV2Args>(
			CANCEL_ORDER_V2_MUTATION,
		);

	const cancelOrder = async () => {
		try {
			if (onBefore) {
				onBefore();
			}

			const { error } = await (bridgeVersion === BridgeVersion.Next
				? cancelOrderV2Mutation({ id: orderId })
				: cancelOrderMutation({ projectionId: orderId }));
			closeCancelModal();

			if (error) {
				throw error;
			}

			if (onSuccessfulCancel) {
				onSuccessfulCancel();
			}
		} catch (e) {
			if (onError) {
				onError();
			}
		}
	};

	return {
		isCancelModalOpen,
		closeCancelModal,
		openCancelModal,
		cancelOrder,
		cancelOrderLoading: cancelOrderLoading || cancelOrderV2Loading,
		cancelOrderError: cancelOrderError || cancelOrderV2Error,
	};
};
