import { Button, ButtonGroup, Icon } from '@shopify/polaris';
import { MobileAcceptMajor } from '@shopify/polaris-icons';
import { HealingStrategy, Order } from '@sixriver/fulfillment-api-schema';

import styles from './ResolveButtons.module.css';
import { useConfig } from 'hooks/useConfig';
import { onErrorProp, useManualException, usePrintLabelButton } from 'hooks/usePrintLabelButton';

interface ResolveButtonsProps {
	order: Order;
	onError?: onErrorProp;
}

export function ResolveButtons({ order, onError }: ResolveButtonsProps): JSX.Element {
	const { config } = useConfig();
	const {
		onClick: automatedOnClick,
		loading: automatedLoading,
		error: automatedError,
		hasBeenClicked: automatedHasBeenClicked,
		disabled: automatedDisabled,
		buttonText: automatedButtonText,
	} = usePrintLabelButton({
		order,
		onError,
	});
	const {
		onClick: manualOnClick,
		loading: manualLoading,
		error: manualError,
		disabled: manualDisabled,
		buttonText: manualButtonText,
		hasBeenClicked: manualHasBeenClicked,
	} = useManualException({
		order,
		onError,
	});
	let printLabelButton = null;
	let printLabelButtonClicked = false;
	let manualResolveButton = null;
	let manualResolveButtonClicked = false;

	if (config?.healingEnabled && config.healingStrategies.includes(HealingStrategy.Automated)) {
		printLabelButtonClicked = !automatedLoading && automatedHasBeenClicked && !automatedError;
		printLabelButton = (
			<Button disabled={automatedDisabled} loading={automatedLoading} onClick={automatedOnClick}>
				{automatedButtonText}
			</Button>
		);
	}

	if (config?.healingEnabled && config.healingStrategies.includes(HealingStrategy.Manual)) {
		manualResolveButtonClicked = !manualLoading && manualHasBeenClicked && !manualError;
		manualResolveButton = (
			<Button disabled={manualDisabled} loading={manualLoading} onClick={manualOnClick}>
				{manualButtonText}
			</Button>
		);
	}

	return (
		<div style={{ display: 'flex' }}>
			<ButtonGroup>
				{printLabelButton}
				{manualResolveButton}
			</ButtonGroup>
			<div
				className={
					!printLabelButtonClicked || !manualResolveButtonClicked
						? styles.IconContainerHidden
						: styles.IconContainer
				}
			>
				<Icon source={MobileAcceptMajor} color="success"></Icon>
			</div>
		</div>
	);
}
