import { Button, Page, Layout, Stack, Icon, ChoiceList } from '@shopify/polaris';
import { AddMajor } from '@shopify/polaris-icons';
import { useDebouncedValue } from '@shopify/react-hooks';
import {
	ProductSummaryPage,
	QueryProductsArgs,
	StorageLocationType,
	ProductOrderByFields,
	OrderByDirection,
	StorageLocationTypeCount,
	ContainerType,
} from '@sixriver/fulfillment-api-schema';
import { useState } from 'react';

import { PRODUCTS_QUERY, COUNTS_QUERY } from './Products.graphql';
import { ProductsTable } from './ProductsTable';
import { AutoRefresh } from 'components/AutoRefresh';
import { Error } from 'components/Error';
import { TimezoneFooter } from 'components/TimezoneFooter';
import { getPageSize } from 'helpers/page-size';
import { MIN_QUERY_LENGTH } from 'helpers/table';
import { useAuth } from 'hooks/useAuth';
import { useConfig } from 'hooks/useConfig';
import { useFilters, useSetFilters } from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';
import { useLocationTabs } from 'hooks/useLocationTabs';
import { usePolling } from 'hooks/usePolling';
import { usePollingQuery } from 'hooks/usePollingQuery';
import { UserRole } from 'providers/AuthProvider';
import * as routes from 'routes';

const PRODUCT_TYPE = 'productType';

export function Products() {
	const { messages } = useLocalization();

	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);

	const defaultSort = ProductOrderByFields.CustomerIdentifier + ' ' + OrderByDirection.Asc;

	const { view, query, sort = defaultSort, productType = 'all' } = useFilters([PRODUCT_TYPE]);

	const setFilters = useSetFilters();

	const searchText = useDebouncedValue(query) || '';

	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();

	const [{ fetching: productsFetching, data: productsData, error: productsError }] =
		usePollingQuery<{ products: ProductSummaryPage }, QueryProductsArgs>({
			query: PRODUCTS_QUERY,
			pollInterval: queryPollInterval,
			variables: {
				cursor: paginationCursors[0],
				limit: getPageSize(),
				type: view === 'all' ? undefined : (view as StorageLocationType),
				searchText: searchText.length >= MIN_QUERY_LENGTH ? searchText : undefined,
				orderBy: sort ? (sort.split(' ')[0] as ProductOrderByFields) : undefined,
				orderByDirection: sort ? (sort.split(' ')[1] as OrderByDirection) : undefined,
				// Expected args for endpoint /v1/inventory-states/asset-location-type-summary for param containerTypeType
				// See fulfillment-api arg kinds in ProductSummary.args.ts for description
				kinds: productType === 'all' ? undefined : productType === 'shipping' ? ['shipping'] : [''],
			},
		});

	const { config } = useConfig();

	const [{ fetching: countsFetching, data: countsData, error: countsError }] = usePollingQuery<{
		productTypeCountsByLocationType: StorageLocationTypeCount[];
	}>({
		query: COUNTS_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			searchText: searchText.length >= MIN_QUERY_LENGTH ? searchText : undefined,
			view: view === 'all' ? undefined : (view as StorageLocationType),
		},
	});

	const counts = countsData?.productTypeCountsByLocationType || [];

	const views = useLocationTabs(counts);

	const { isUserAllowed } = useAuth();

	const sorts = [
		{
			label: messages.item,
			value: ProductOrderByFields.CustomerIdentifier + ' ' + OrderByDirection.Asc,
		},
		{
			label: config?.inventoryEnabled ? messages.sku : messages.identifier,
			value: ProductOrderByFields.Name + ' ' + OrderByDirection.Asc,
		},
		{
			label: messages.description,
			value: ProductOrderByFields.Description + ' ' + OrderByDirection.Asc,
		},
	];

	const filters = [
		// Product Type
		...(config?.inventoryEnabled
			? [
					{
						key: PRODUCT_TYPE,
						label: messages.productType,
						filter: (
							<ChoiceList
								title={messages.productType}
								titleHidden
								choices={[
									{
										label: messages.allProducts,
										value: 'all',
									},
									{
										label: messages.productTypes.packaging,
										value: ContainerType.Shipping,
									},
									{
										label: messages.productTypes.product,
										value: ContainerType.Storage,
									},
								]}
								selected={[productType]}
								onChange={(selected) => {
									setFilters([{ key: PRODUCT_TYPE, value: selected[0] }]);
								}}
							/>
						),
						shortcut: true,
					},
			  ]
			: []),
	];

	const appliedFilters = [
		...(productType !== 'all'
			? [
					{
						key: PRODUCT_TYPE,
						label:
							productType === 'shipping'
								? messages.productTypes.packaging
								: messages.productTypes.product,
						onRemove: () => {
							setFilters([{ key: PRODUCT_TYPE, value: 'all' }]);
						},
					},
			  ]
			: []),
	];

	return productsError || countsError ? (
		<Error graphQLError={productsError || countsError} />
	) : (
		<Page fullWidth title={messages.products}>
			<Layout>
				<Layout.Section>
					<Stack distribution="trailing" alignment="trailing" spacing="extraLoose">
						<Button
							plain
							monochrome
							removeUnderline
							disabled={!isUserAllowed([UserRole.Admin])}
							icon={<Icon source={AddMajor} />}
							url={routes.addProduct()}
						>
							{messages.addProduct}
						</Button>

						<AutoRefresh
							pollingEnabled={pollingEnabled}
							togglePolling={togglePolling}
							discriminatorData={productsData?.products}
						/>
					</Stack>
				</Layout.Section>
				<Layout.Section>
					<ProductsTable
						loading={productsFetching || countsFetching}
						data={productsData?.products}
						paginationCursors={paginationCursors}
						setPaginationCursors={setPaginationCursors}
						filters={filters}
						appliedFilters={appliedFilters}
						setFilters={setFilters}
						query={query}
						views={views}
						selectedView={(view as StorageLocationType) || 'all'}
						sorts={sorts}
						selectedSort={sort}
					/>
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</Page>
	);
}
