import { Tabs, Stack } from '@shopify/polaris';
import { ReactNode } from 'react';

export interface Tab {
	id: string;
	label: string | ReactNode;
	metaLabel?: string | number;
}

interface Props {
	tabs: Array<Tab>;
	selected: string;
	onSelect(id: string): void;
}

export function ViewTabs({ tabs = [], selected, onSelect }: Props) {
	const tabDescriptors = tabs.map((tab) => {
		return {
			id: tab.id,
			content: (
				<Stack>
					<span>{tab.label}</span>
					{tab.metaLabel !== undefined ? <span>{tab.metaLabel}</span> : null}
				</Stack>
			),
		};
	});
	const selectedIndex = tabs.findIndex((tab) => tab.id === selected);

	return (
		<div>
			<Tabs
				tabs={tabDescriptors}
				selected={selectedIndex}
				onSelect={(idx: number) => onSelect(tabs[idx].id)}
			/>
		</div>
	);
}
