import { ReactNode } from 'react';

export interface Tokens {
	[k: string]: string | number | ReactNode;
}

export interface PluralForms {
	zero?: string;
	one?: string;
	two?: string;
	few?: string;
	many?: string;
	other: string;
}

export function translate(
	locale = 'en',
	message: string | PluralForms,
	tokens: Tokens = {},
	count?: number,
): ReactNode[] | string {
	if (typeof message === 'string') {
		return tokenize(message, tokens);
	}

	const rule = new Intl.PluralRules([locale]);
	const key = rule.select(count || parseInt(tokens.count as string));

	return tokenize(message[key] as string, tokens);
}

function tokenize(message: string, tokens: Tokens): ReactNode[] | string {
	const parts: Array<ReactNode | string> = [];

	let str = message;

	while (str.length) {
		const token = /{\w+}/.exec(str);

		if (token) {
			const key = token[0].substring(1, token[0].length - 1);
			parts.push(str.substring(0, token.index));
			parts.push(tokens[key] ?? '???');
			str = str.substring(token.index + key.length + 2);
		} else {
			parts.push(str);
			break;
		}
	}

	if (parts.some((p) => typeof p === 'object')) {
		return parts;
	}

	return parts.join('');
}
