import { IndexTable } from '@shopify/polaris';

import { LaneCarrierAndLogo } from 'components/LaneCarrierLogo';

interface LaneCarrierCellProps {
	carrier: string;
}

export function LaneCarrierCell({ carrier }: LaneCarrierCellProps) {
	return (
		<IndexTable.Cell>
			<LaneCarrierAndLogo carrier={carrier} />
		</IndexTable.Cell>
	);
}
