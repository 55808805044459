import { TextStyle } from '@shopify/polaris';
import { PickStrategy, OrderV2 } from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';
import { useServicedByPickingJobs } from 'hooks/useServicedByPickingJobs';

interface Props {
	order?: OrderV2;
}
export function OrderTotes({ order }: Props): JSX.Element | null {
	const getServicedByPickingJobs = useServicedByPickingJobs;
	let numberOfTotes;
	switch (order?.pickStrategy) {
		case PickStrategy.Discrete:
		case PickStrategy.Singles:
			numberOfTotes = 1;
			break;
		case PickStrategy.Consolidation:
		case PickStrategy.Group:
			numberOfTotes = getServicedByPickingJobs(order).length;
			break;
		case PickStrategy.Sortation:
			numberOfTotes = order?.servicingSorations.length;
			break;
		default:
			numberOfTotes = null;
	}

	return numberOfTotes || numberOfTotes === 0 ? (
		<TextStyle variation={numberOfTotes === 0 ? 'subdued' : undefined}>{numberOfTotes}</TextStyle>
	) : (
		<NoData />
	);
}
