import { Badge } from '@shopify/polaris';
import { GoalStates } from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';
import { useLocalization } from 'hooks/useLocalization';

export function ReworkJobStatus({ status }: { status?: GoalStates }) {
	const { messages } = useLocalization();

	const statusToBadgeStatus: {
		[key in GoalStates]?: 'info' | 'critical' | 'warning';
	} = {
		[GoalStates.Creating]: 'warning',
		[GoalStates.Created]: 'warning',
		[GoalStates.Ready]: 'warning',
		[GoalStates.Running]: 'info',
		[GoalStates.Paused]: 'info',
		[GoalStates.TerminateRequested]: 'info',
		[GoalStates.Terminating]: 'info',
		[GoalStates.Terminated]: undefined,
		[GoalStates.Complete]: undefined,
		[GoalStates.CancelRequested]: 'info',
		[GoalStates.Cancelling]: 'info',
		[GoalStates.Cancelled]: undefined,
	};

	const labels = {
		[GoalStates.Creating]: messages.pending,
		[GoalStates.Created]: messages.pending,
		[GoalStates.Ready]: messages.pending,
		[GoalStates.Running]: messages.inProgress,
		[GoalStates.Paused]: messages.inProgress,
		[GoalStates.TerminateRequested]: messages.inProgress,
		[GoalStates.Terminating]: messages.inProgress,
		[GoalStates.Terminated]: messages.closed,
		[GoalStates.Complete]: messages.closed,
		[GoalStates.CancelRequested]: messages.inProgress,
		[GoalStates.Cancelling]: messages.inProgress,
		[GoalStates.Cancelled]: messages.closed,
		[GoalStates.Hold]: messages.hold,
	};

	return status ? <Badge status={statusToBadgeStatus[status]}>{labels[status]}</Badge> : <NoData />;
}
