import { ChoiceList } from '@shopify/polaris';

interface FilterPickerProps {
	availableChoices: {
		label: string;
		value: string;
	}[];
	selectedChoices?: string[];
	onChange: (s: string[]) => void;
}

export function FilterPicker({ availableChoices, selectedChoices, onChange }: FilterPickerProps) {
	return (
		<ChoiceList
			title=""
			titleHidden
			allowMultiple
			selected={selectedChoices || []}
			choices={availableChoices}
			onChange={(flags) => {
				onChange(flags);
			}}
		/>
	);
}
