import { ChoiceList } from '@shopify/polaris';

import { noop } from 'helpers/noop';
import { useLocalization } from 'hooks/useLocalization';

interface LaneCarrierSortsFilterProps {
	selected?: string[];
	onChange?: (selected: string[], name: string) => void;
	options: string[];
}

export function LaneCarrierSortsFilter({
	selected = [],
	onChange = noop,
	options = [],
}: LaneCarrierSortsFilterProps) {
	const { messages } = useLocalization();

	return (
		<ChoiceList
			title={messages.carrierSort}
			titleHidden
			choices={options.map((carrierSort) => ({
				label: carrierSort,
				value: carrierSort,
			}))}
			selected={selected}
			onChange={onChange}
			allowMultiple
		/>
	);
}
