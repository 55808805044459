export const MILLISECONDS_PER_HOUR = 3600000;
export const MILLISECONDS_PER_DAY = 86400000;

export function startOfDay(date: Date = new Date()): Date {
	const copy = new Date(date);
	copy.setHours(0, 0, 0, 0);
	return copy;
}

export function endOfDay(date: Date = new Date()): Date {
	const copy = new Date(date);
	copy.setHours(23, 59, 59, 999);
	return copy;
}

export function addDays(date: Date, days: number): Date {
	const copy = new Date(date);
	copy.setDate(copy.getDate() + days);
	return copy;
}

export const getMidnight = (offsetDays = 0): Date => {
	return addDays(startOfDay(), offsetDays);
};

export function isYesterday(date: Date): boolean {
	return startOfDay(date).getTime() === addDays(startOfDay(), -1).getTime();
}

export function isToday(date: Date): boolean {
	return startOfDay(date).getTime() === startOfDay().getTime();
}

export function isTomorrow(date: Date): boolean {
	return startOfDay(date).getTime() === addDays(startOfDay(), 1).getTime();
}

export function getDateDiffInDays(date1: Date, date2: Date): number {
	// rounded to the nearest whole day
	return Math.round((date1.getTime() - date2.getTime()) / MILLISECONDS_PER_DAY);
}
