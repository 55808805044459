import { Modal, Page, PageActions, Spinner } from '@shopify/polaris';
import {
	MutationResponse,
	StorageLocation,
	StorageLocationInput,
	StorageLocationType,
} from '@sixriver/fulfillment-api-schema';
import { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';

import { StorageLocationForm } from './Location.form';
import {
	DELETE_LOCATION_MUTATION,
	EDIT_LOCATION_MUTATION,
	GET_LOCATION_QUERY,
} from './Locations.graphql';
import { Error } from 'components/Error';
import { useLocalization } from 'hooks/useLocalization';
import { useToast } from 'hooks/useToast';
import * as routes from 'routes';

export function EditStorageLocation(): JSX.Element {
	const { messages } = useLocalization();

	// Routing
	const history = useHistory();
	const {
		params: { locationId },
	} = useRouteMatch<{ locationId: string }>();

	// Toasts
	const { showToast } = useToast();

	// Queries
	const [{ fetching: fetchingLocation, data, error: getLocationError }] = useQuery<
		{ location: StorageLocation },
		{ id: string }
	>({
		query: GET_LOCATION_QUERY,
		pause: !locationId, // Do not run this query unless a location id was provided
		variables: {
			id: locationId,
		},
		requestPolicy: 'network-only',
	});

	// Mutations
	const [{ error: editStorageLocationError }, editStorageLocationMutation] = useMutation<
		{ editLocation: MutationResponse },
		{ input: StorageLocationInput }
	>(EDIT_LOCATION_MUTATION);

	const [{ error: deleteLocationError, fetching: isDeleting }, deleteLocationMutation] =
		useMutation<{ deleteLocation: MutationResponse }, { id: string }>(DELETE_LOCATION_MUTATION);

	// State
	const [modalVisible, setModalVisible] = useState(false);

	// Methods
	const onSubmit = async (input: StorageLocationInput) => {
		const { error } = await editStorageLocationMutation({ input });

		if (!error) {
			showToast(messages.dataSaved);
			history.push(routes.location(locationId));
		}
	};

	const deleteLocation = async () => {
		const { error } = await deleteLocationMutation({ id: locationId });

		if (!error) {
			history.push(routes.locations());
		}

		setModalVisible(false);
	};

	// Render
	if (fetchingLocation || !data) {
		return <Spinner />;
	}

	const formData: StorageLocationInput = {
		address: data.location.address,
		containerTypeId: data.location.containerType?.id,
		x: data.location.coordinates?.x || 0,
		y: data.location.coordinates?.y || 0,
		z: data.location.coordinates?.z || 0,
		description: data.location.description,
		externalAisleId: data.location.externalAisleId,
		id: data.location.id,
		type: data.location.type as StorageLocationType,
	};

	if (getLocationError) {
		return <Error graphQLError={getLocationError} />;
	}

	return (
		<Page
			title={data.location.address}
			subtitle={messages.locationTypes[data.location.type || 'unknown']}
			breadcrumbs={[{ content: messages.locations, url: routes.location(locationId) }]}
			fullWidth
		>
			<StorageLocationForm
				mode="edit"
				data={formData}
				onSubmit={onSubmit}
				error={editStorageLocationError || deleteLocationError}
			/>
			<br />
			<PageActions
				secondaryActions={[
					{
						content: messages.deleteLocation,
						destructive: true,
						outline: true,
						onAction: () => setModalVisible(true),
					},
				]}
			/>
			<Modal
				open={modalVisible}
				onClose={() => setModalVisible(false)}
				title={messages.deleteLocation}
				primaryAction={{
					content: messages.deleteLocation,
					onAction: deleteLocation,
					destructive: true,
					loading: isDeleting,
				}}
				secondaryActions={[
					{
						content: messages.keepEditing,
						onAction: () => setModalVisible(false),
					},
				]}
			>
				<Modal.Section>{messages.confirmDeleteLocation}</Modal.Section>
			</Modal>
		</Page>
	);
}
