import { Badge } from '@shopify/polaris';
import { ShippingNoticeStatus } from '@sixriver/fulfillment-api-schema';

import { useLocalization } from 'hooks/useLocalization';

export function InboundShipmentProgress({ status }: { status?: ShippingNoticeStatus }) {
	const { messages } = useLocalization();

	const badges: Record<ShippingNoticeStatus, JSX.Element> = {
		[ShippingNoticeStatus.Arrived]: <Badge status="warning">{messages.arrived}</Badge>,
		[ShippingNoticeStatus.AuditRequired]: <Badge status="warning">{messages.auditRequired}</Badge>,
		[ShippingNoticeStatus.Cancelled]: <Badge status="critical">{messages.canceled}</Badge>,
		[ShippingNoticeStatus.Completed]: <Badge>{messages.closed}</Badge>,
		[ShippingNoticeStatus.InTransit]: <Badge status="warning">{messages.inTransit}</Badge>,
		[ShippingNoticeStatus.Receiving]: <Badge status="info">{messages.receiving}</Badge>,
		[ShippingNoticeStatus.Submitted]: <Badge status="info">{messages.submitted}</Badge>,
	};

	return status && badges[status] ? badges[status] : null;
}
