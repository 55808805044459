import { Link, Badge } from '@shopify/polaris';
import { Line, LineStatus } from '@sixriver/fulfillment-api-schema';
import { useState } from 'react';

import { SortWallLineViews, SortWallLineViewStateMap } from './SortWallLinesTableViews';
import { DataTable, Column } from 'components/DataTable';
import { NoData } from 'components/NoData';
import { countLineExceptions } from 'helpers/exception';
import { paginate } from 'helpers/page-info';
import { getPageSize } from 'helpers/page-size';
import { useFilters, useSetFilters } from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';

interface Props {
	lines?: Line[];
	isInventoryEnabled?: boolean;
	loading?: boolean;
}

const VIEW = 'lineView';

export function SortWallLinesTable({ lines, isInventoryEnabled, loading }: Props) {
	const { messages, translate } = useLocalization();

	const { [VIEW]: view = SortWallLineViews.All } = useFilters([VIEW]);

	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);

	const filteredLines = SortWallLineViewStateMap[view as SortWallLineViews](lines);

	const counts = Object.keys(SortWallLineViewStateMap).reduce<
		{ [key in SortWallLineViews]: number }
	>(
		(counts, orderView) => ({
			...counts,
			[orderView]: SortWallLineViewStateMap[orderView as SortWallLineViews](lines).length || 0,
		}),
		{} as { [key in SortWallLineViews]: number },
	);

	const { itemsInCurrentPage: linesInCurrentPage, pageInfo } = paginate({
		itemsList: filteredLines,
		pageSize: getPageSize(),
		paginationCursors,
	});

	const setFilters = useSetFilters();

	const columns: Column[] = [
		{
			type: 'text',
			heading: messages.item,
		},
		{
			type: 'text',
			heading: isInventoryEnabled ? messages.sku : messages.name,
		},
		{
			type: 'text',
			heading: messages.unitsPicked,
		},
		{
			type: 'text',
			heading: messages.location,
		},
		{
			type: 'text',
			heading: messages.exceptions,
		},
	];

	const rows = (linesInCurrentPage || []).map((line) => {
		const hasException = countLineExceptions([line]) > 0;

		return [
			line.product?.id ? (
				<Link url={routes.product(line.product.id)} key="product-id">
					{line.product.customerIdentifier}
				</Link>
			) : (
				<NoData />
			),
			line.product?.description ? <p key="product-name">{line.product.description}</p> : <NoData />,
			<div key="units">
				{translate(messages.pickedOfTotal, {
					picked: line.doneQuantity,
					total: line.quantity,
				})}
			</div>,
			line.source?.address || <NoData />,
			hasException ? (
				<Badge status="critical">{messages.exception}</Badge>
			) : line.status?.includes(LineStatus.Cleared) ? (
				<Badge status="success">{messages.cleared}</Badge>
			) : (
				<NoData />
			),
		];
	});

	return (
		<DataTable
			title={messages.lines}
			loading={loading}
			columns={columns}
			rows={rows}
			emptyStateHeading={messages.linesNotFound}
			pageInfo={pageInfo}
			paginationCursors={paginationCursors}
			setPaginationCursors={setPaginationCursors}
			views={[
				{
					label: messages.all,
					metaLabel: counts.All,
					id: SortWallLineViews.All,
				},
				{
					label: messages.exceptions,
					metaLabel: counts.Exceptions,
					id: SortWallLineViews.Exceptions,
				},
			]}
			viewKey={VIEW}
			setFilters={setFilters}
			selectedView={view}
			hideFilters
		/>
	);
}
