import { Badge } from '@shopify/polaris';
import { Job, OrderV2 } from '@sixriver/fulfillment-api-schema';

import { countLineExceptionsV2, isExceptionActionableV2 } from 'helpers/exception';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	job: Job | OrderV2;
}

export function JobExceptionBadge({ job }: Props): JSX.Element | null {
	const { messages } = useLocalization();
	const numberOfExceptions = countLineExceptionsV2(job?.lines);

	if (job.isShorted === false && job.exceptions.length > 0) {
		return <Badge status="success">{messages.exceptionCleared}</Badge>;
	}

	if (numberOfExceptions > 0 && isExceptionActionableV2(job)) {
		<Badge status="critical">{messages.exception}</Badge>;
	}

	return null;
}
