import { Button } from '@shopify/polaris';
import { Job } from '@sixriver/fulfillment-api-schema';

import { useServicedByPickingJobs } from 'hooks/useServicedByPickingJobs';
import * as routes from 'routes';

const getParams = (ids?: string[]) => {
	const searchParams = new URLSearchParams();

	if (ids) {
		searchParams.set('ids', ids.join(','));
	}

	return '?' + searchParams.toString();
};

export function getRelatedJobs(job?: Job): Job[] {
	const getServicedByPickingJobs = useServicedByPickingJobs;
	const orders = (job?.servicing || []).flatMap((servicingJob) => {
		if (servicingJob.servicing.length > 0) {
			return servicingJob.servicing;
		}
		return servicingJob;
	});

	return orders.reduce<Job[]>((jobs, order) => [...jobs, ...getServicedByPickingJobs(order)], []);
}

interface ButtonProps {
	relatedJobs: Job[];
	label: string;
}

export function RelatedJobsButton({ relatedJobs, label }: ButtonProps) {
	const unique = relatedJobs
		.map((job) => job.id)
		.reduce((ids, id) => {
			return ids.add(id);
		}, new Set<string>());
	return (
		<Button
			plain
			url={`${routes.outboundJobs()}/${getParams(Array.from(unique))}`}
			disabled={unique.size <= 1}
		>
			{label}
		</Button>
	);
}
