import { Page, Icon, Stack } from '@shopify/polaris';
import { CircleTickMajor, CircleAlertMajor } from '@shopify/polaris-icons';
import { Config } from '@sixriver/fulfillment-api-schema';
import { useState, useEffect } from 'react';

import styles from './Configuration.module.css';
import { useConfig } from 'hooks/useConfig';

const HEALTH_CHECK_URL = `${process.env.REACT_APP_API_URL}/.well-known/apollo/server-health`;

export function Configuration() {
	const [apiStatus, setApiStatus] = useState<'pass' | 'fail' | undefined>();

	const { config } = useConfig();

	useEffect(() => {
		async function ping() {
			try {
				const response = await fetch(HEALTH_CHECK_URL);
				const data = await response.json();
				setApiStatus(data.status);
			} catch {
				setApiStatus('fail');
			}
		}

		ping();
	}, []);

	const keys = Object.keys(config || {}).filter((key) => key !== '__typename');

	const stringify = (obj: any) => {
		delete obj?.__typename;
		return JSON.stringify(obj, null, 2);
	};

	const icon = apiStatus === 'pass' ? CircleTickMajor : CircleAlertMajor;
	const color = apiStatus === 'pass' ? 'success' : 'critical';

	return (
		<Page
			title="Configuration"
			primaryAction={
				apiStatus ? (
					<Stack>
						<strong>{'API'}</strong>
						<Icon source={icon} color={color} />
					</Stack>
				) : null
			}
		>
			<table className={styles.config}>
				<tbody>
					{keys.map((key) => {
						return (
							<tr key={key}>
								<th>{key}</th>
								<td>{config ? stringify(config[key as keyof Config]) : '–'}</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</Page>
	);
}
