import { IndexTable, Link } from '@shopify/polaris';

import * as routes from 'routes';

export function DetailsCell({ id }: { id: string }) {
	// shorten id to first 5 characters
	const shortenedId = id.substring(0, 5);

	return (
		<IndexTable.Cell>
			<Link url={routes.reworkJob(id)} key="rework" removeUnderline>
				{`RW-${shortenedId}`}
			</Link>
		</IndexTable.Cell>
	);
}
