import { TextStyle } from '@shopify/polaris';
import { PickStrategy, Order, OrderStatus } from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';
import { getPickingJobs } from 'helpers/order';

export function OrderTotes({ order }: { order?: Order }) {
	const numberOfTotes =
		order?.type === PickStrategy.Discrete || order?.type === PickStrategy.Singles
			? getPickingJobs(order?.servicedBy).length
			: order?.type === PickStrategy.Consolidation || order?.type === PickStrategy.Group
			? getPickingJobs(order?.servicedBy).length
			: order?.type === PickStrategy.Sortation && order?.status === OrderStatus.Unassigned
			? 1
			: null;

	return numberOfTotes || numberOfTotes === 0 ? (
		<TextStyle variation={numberOfTotes === 0 ? 'subdued' : undefined}>{numberOfTotes}</TextStyle>
	) : (
		<NoData />
	);
}
