import { IndexTable } from '@shopify/polaris';
import {
	OrderV2,
	JobStatus,
	OrderV2Status,
	OrderExceptionStatus,
} from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';
import { ExceptionBadge } from 'pages/ExceptionV1/ExceptionBadge';

interface StatusCellProps {
	order: OrderV2;
}

export function StatusCell({ order }: StatusCellProps) {
	const healableExceptions = order.exceptions.filter((exception) => exception.isHealable);

	if (order.isShorted && order.status === OrderV2Status.Canceled) {
		return (
			<IndexTable.Cell>
				<ExceptionBadge status={OrderExceptionStatus.Canceled} />
			</IndexTable.Cell>
		);
	}

	if (order.isShorted && order.jobStatus === JobStatus.Picking) {
		return (
			<IndexTable.Cell>
				<ExceptionBadge status={OrderExceptionStatus.Resolving} />
			</IndexTable.Cell>
		);
	}

	if (!order.isShorted && order.exceptions.length > 0) {
		return (
			<IndexTable.Cell>
				<ExceptionBadge status={OrderExceptionStatus.Cleared} />
			</IndexTable.Cell>
		);
	}

	if (order.isShorted && healableExceptions.length > 0) {
		return (
			<IndexTable.Cell>
				<ExceptionBadge status={OrderExceptionStatus.Resolvable} />
			</IndexTable.Cell>
		);
	}

	if (order.isShorted && healableExceptions.length === 0) {
		return (
			<IndexTable.Cell>
				<ExceptionBadge status={OrderExceptionStatus.Unresolvable} />
			</IndexTable.Cell>
		);
	}

	return (
		<IndexTable.Cell>
			<NoData />;
		</IndexTable.Cell>
	);
}
