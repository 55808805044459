import gql from 'graphql-tag';

export const ADD_PRODUCT_MUTATION = gql`
	mutation addNewProduct($input: ProductInput!) {
		addProduct(input: $input) {
			success
			referenceId
		}
	}
`;
