import { IndexTable } from '@shopify/polaris';

import { useLocalization } from 'hooks/useLocalization';

export function UnitsProcessedCell({
	reworkQuantity,
	processedQuantity,
}: {
	reworkQuantity: number;
	processedQuantity: number;
}) {
	const { messages, translate } = useLocalization();

	return (
		<IndexTable.Cell>
			<p>
				{translate(messages.xOfY, {
					x: processedQuantity,
					y: reworkQuantity,
				})}
			</p>
		</IndexTable.Cell>
	);
}
