import { Select, FormLayout } from '@shopify/polaris';
import { useCallback } from 'react';

import styles from './CutoffFilter.module.css';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	onChange: (date: Date) => void;
	value?: Date;
	cutoffDates: Date[];
}

export function CutoffFilterV2({ onChange, value, cutoffDates }: Props) {
	const { messages, formatDateTime } = useLocalization();

	const retrievedOptions = cutoffDates.map((date) => ({
		label: formatDateTime(date),
		value: date.toISOString(),
	}));

	const options = [...retrievedOptions];

	const handleOnChange = useCallback(
		(selected: string) => {
			const date = new Date(selected);
			onChange(date);
		},
		[onChange],
	);

	return (
		<div className={styles.cutoffFilter}>
			<FormLayout>
				<Select
					label={messages.date}
					placeholder=" "
					options={options}
					onChange={handleOnChange}
					value={value?.toISOString()}
				/>
			</FormLayout>
		</div>
	);
}
