import { Card } from '@shopify/polaris';
import { Picking, Order } from '@sixriver/fulfillment-api-schema';

import { RelatedJobsButton } from '../RelatedJobs';
import styles from './PackingDetails.module.css';
import { CardDetails } from 'components/CardDetails';
import { CopyButton } from 'components/CopyButton';
import { DateTime } from 'components/DateTime';
import { RelativeDateTime } from 'components/RelativeDateTime';
import { countLineExceptions } from 'helpers/exception';
import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';

interface Props {
	loading?: boolean;
	job?: Picking;
	relatedJobs?: Picking[];
	isHealingEnabled?: boolean;
	cutoff?: string;
}

const getParams = (orderIds: string[]) => {
	const searchParams = new URLSearchParams();

	searchParams.set('projectionIds', orderIds.join(' '));

	return '?' + searchParams.toString();
};

export function PackingDetails({ loading, job, relatedJobs, isHealingEnabled, cutoff }: Props) {
	const { messages, translate } = useLocalization();

	const orders = (job?.servicing || []).filter(
		(servicing) => servicing.__typename === 'Order',
	) as Order[];

	const lines = job?.lines || [];

	const orderIds = orders.map((order) => order.id);

	const packout = job?.packout;

	const exceptionCount = countLineExceptions(lines);

	const exceptionOrders =
		orders?.filter((order) => {
			return countLineExceptions(order.lines) > 0;
		}) || [];

	const exceptionOrderIds = exceptionOrders.map((order) => order.id);

	return (
		<Card
			title={messages.packingDetails}
			primaryFooterAction={{
				content: translate(messages.viewOrders, { count: orderIds.length }) as string,
				disabled: orderIds.length === 0,
				url:
					orderIds.length === 1
						? routes.order(orderIds[0])
						: `${routes.orders()}${getParams(orderIds)}`,
			}}
			secondaryFooterActions={
				isHealingEnabled
					? [
							{
								content: messages.manageExceptions,
								disabled: exceptionCount === 0,
								url:
									exceptionOrderIds.length === 1
										? routes.exception(exceptionOrderIds[0])
										: `${routes.exceptions()}${getParams(exceptionOrderIds)}`,
							},
					  ]
					: undefined
			}
			footerActionAlignment="left"
		>
			<Card.Section>
				<CardDetails
					loading={loading}
					primary={[
						{
							label: messages.packStation,
							content: packout?.packStation?.name,
						},
						{
							label: messages.packedBy,
							content: packout?.user?.badge,
						},
						{
							label: messages.packageType,
							content: packout?.containerTypes.join(', '),
						},
						{
							label: messages.earliestCutoff,
							content: (
								<>
									<DateTime date={cutoff} />
									<RelativeDateTime date={cutoff} mode="deadline" />
								</>
							),
						},
					]}
					secondary={[
						{
							label: messages.createdAt,
							content: (
								<>
									<DateTime date={packout?.timeline.acceptedAt} />
									<RelativeDateTime date={packout?.timeline.acceptedAt} />
								</>
							),
						},
						{
							label: messages.updatedAt,
							content: <DateTime date={packout?.timeline.updatedAt} />,
						},
						{
							label: messages.packingStartedAt,
							content: <DateTime date={packout?.timeline.inductedAt} />,
						},
						{
							label: messages.packingEndedAt,
							content: <DateTime date={packout?.timeline.finishedAt} />,
						},
					]}
				/>
				<div className={styles.button}>
					<RelatedJobsButton relatedJobs={relatedJobs || []} label={messages.viewRelatedJobs} />
				</div>
			</Card.Section>
			<Card.Section title={messages.breakdown}>
				<CardDetails
					loading={loading}
					primary={[
						{
							label: messages.totalUnits,
							content: translate(messages.countUnits, {
								count: lines.reduce((a, b) => a + b.quantity, 0),
							}),
						},
						{
							label: messages.totalLines,
							content: translate(messages.countLines, { count: lines.length }),
						},
					]}
					secondary={[
						{
							label: messages.orderId,
							content:
								orderIds.length === 0 ? null : orderIds.length === 1 ? (
									<CopyButton text={orders?.[0]?.customerIdentifier || '?'} />
								) : (
									messages.multiple
								),
						},
						{
							label: messages.totalOrders,
							content: translate(messages.countOrders, { count: orderIds.length }),
						},
					]}
				/>
			</Card.Section>
			<Card.Section>
				<CardDetails
					loading={loading}
					primary={[
						{
							label: messages.packoutRequestId,
							content: packout?.packoutRequestIdentifier ? (
								<CopyButton text={packout?.packoutRequestIdentifier} />
							) : null,
						},
					]}
				/>
			</Card.Section>
		</Card>
	);
}
