import { IndexTable, TextStyle } from '@shopify/polaris';
import { LaneContainer } from '@sixriver/fulfillment-api-schema';

interface Props {
	container: LaneContainer;
}

export function PackageQtyCell({ container }: Props): JSX.Element {
	const packageQty = container.packages ? container.packages.length : 0;

	return (
		<IndexTable.Cell>
			<TextStyle>{packageQty}</TextStyle>
		</IndexTable.Cell>
	);
}
