import {
	Page,
	Layout,
	Badge,
	Stack,
	Tabs,
	Banner,
	Heading,
	TextStyle,
	ComplexAction,
	MenuGroupDescriptor,
} from '@shopify/polaris';
import { DuplicateMinor } from '@shopify/polaris-icons';
import {
	OrderExceptionStatus,
	Order as FulfillmentApiOrder,
	QueryOrderByProjectionIdArgs,
	PickStrategy,
	WorkflowEvent,
	WorkOrderType,
} from '@sixriver/fulfillment-api-schema';
import { useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { Details } from './Details';
import { Lines } from './Lines';
import { MarkAsCompleteModal } from './MarkAsCompleteModal';
import { ORDER_QUERY, ORDER_EVENTS_QUERY } from './Order.graphql';
import { Progress } from './Progress';
import { AutoRefresh } from 'components/AutoRefresh';
import { CancelOrderModal } from 'components/CancelOrderModal';
import { Error } from 'components/Error';
import { ErrorBanner } from 'components/ErrorBanner';
import { OrderStatusBadge } from 'components/OrderStatusBadge';
import { Timeline } from 'components/Timeline';
import { useWorkflowEvents } from 'components/Timeline/Timeline.hooks';
import { TimezoneFooter } from 'components/TimezoneFooter';
import { countLineExceptions, getExceptionLines, isExceptionActionable } from 'helpers/exception';
import { useAuth } from 'hooks/useAuth';
import { useCancelOrder } from 'hooks/useCancelOrder';
import { useConfig } from 'hooks/useConfig';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import { useFilters, useSetFilters } from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';
import { usePolling } from 'hooks/usePolling';
import { usePollingQuery } from 'hooks/usePollingQuery';
import { UserRole } from 'providers/AuthProvider';
import * as routes from 'routes';

export interface RouteMatchParams {
	orderId: string;
}

export function Order() {
	const { messages } = useLocalization();

	const {
		params: { orderId },
	} = useRouteMatch<RouteMatchParams>();
	const { isUserAllowed } = useAuth();

	const history = useHistory();

	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();

	const [{ fetching: orderFetching, error: orderError, data: orderData }] = usePollingQuery<
		{ orderByProjectionId: FulfillmentApiOrder },
		QueryOrderByProjectionIdArgs
	>({
		query: ORDER_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			projectionId: orderId,
		},
	});

	const { orderByProjectionId: order } = orderData || {};

	const [{ fetching: eventsFetching, error: eventsError, data: eventsData }] = usePollingQuery<
		{ orderEvents: WorkflowEvent[] },
		QueryOrderByProjectionIdArgs
	>({
		query: ORDER_EVENTS_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			projectionId: orderId,
		},
	});

	const [isBannerErrorVisible, setIsBannerErrorVisible] = useState(false);
	const [isReviewModalOpen, setIsReviewModalOpen] = useState<boolean>(false);

	const setFilters = useSetFilters();

	const { isCancelModalOpen, openCancelModal, closeCancelModal, cancelOrder, cancelOrderLoading } =
		useCancelOrder({
			orderId,
			onSuccessfulCancel: () => {
				history.push(routes.orders());
			},
			onError: () => {
				setIsBannerErrorVisible(true);
			},
			onBefore: () => {
				setIsBannerErrorVisible(false);
			},
		});

	const { copyToClipboard } = useCopyToClipboard();

	const numberOfExceptions = countLineExceptions(getExceptionLines(order));

	const { config } = useConfig();

	const fetching = orderFetching || eventsFetching;

	const error = orderError || eventsError;

	const events = useWorkflowEvents(
		eventsData?.orderEvents || [],
		'ORDER',
		order?.type === PickStrategy.Sortation,
	);

	const tabs = [
		{
			id: 'overview',
			content: messages.overview,
		},
		{
			id: 'history',
			content: messages.history,
		},
	];

	const { tab } = useFilters(['tab']);

	const tabIndex = tab === tabs[1].id ? 1 : 0;

	if (error) {
		return <Error graphQLError={error} />;
	}

	if (!orderFetching && !order) {
		return <Error heading={messages.orderNotFound} />;
	}

	const actionableException = isExceptionActionable(
		config?.packoutEnabled,
		config?.healingEnabled,
		order,
	);

	const isInventoryTransferOrder = order?.workOrderTypes?.includes(
		WorkOrderType.InventoryTransferToNode,
	);

	const copyOrderNumberButton = {
		content: messages.copyOrderNumber,
		icon: DuplicateMinor,
		onAction: () => copyToClipboard(order?.customerIdentifier),
		disabled: !order?.customerIdentifier,
	};

	const cancelOrderButton = {
		content: messages.cancelOrder,
		onAction: orderData ? openCancelModal : undefined,
		disabled: !isUserAllowed([UserRole.Admin, UserRole.WarehouseManager]) || !order?.isCancelable,
	};

	const disableMarkAsCompleteButton =
		!isUserAllowed([UserRole.Admin, UserRole.WarehouseManager]) ||
		!order?.canShipShort ||
		order?.isShippedShort;
	const marckAsCompleteButton = {
		content: messages.markAsComplete,
		onAction: () => setIsReviewModalOpen(true),
		disabled: disableMarkAsCompleteButton,
	};

	const secondaryActions: ComplexAction[] = [];
	const actionGroups: MenuGroupDescriptor[] = [];

	if (isInventoryTransferOrder) {
		secondaryActions.push(copyOrderNumberButton);
		actionGroups.push({
			title: messages.moreActions,
			actions: [cancelOrderButton, marckAsCompleteButton],
		});
	} else {
		secondaryActions.push(copyOrderNumberButton);
		secondaryActions.push(cancelOrderButton);
	}

	return (
		<Page
			title={order?.customerIdentifier || undefined}
			titleMetadata={
				<div data-testid="header">
					<Stack spacing="extraTight">
						<OrderStatusBadge orderStatus={order?.status} isEmptyIfNoData />
						{order?.exception?.status === OrderExceptionStatus.Cleared ? (
							<Badge status="success">{messages.exceptionCleared}</Badge>
						) : numberOfExceptions && actionableException && config?.healingEnabled ? (
							<Badge status="critical">{messages.exception}</Badge>
						) : null}
					</Stack>
				</div>
			}
			secondaryActions={secondaryActions}
			actionGroups={actionGroups}
		>
			<AutoRefresh
				pollingEnabled={pollingEnabled}
				togglePolling={togglePolling}
				discriminatorData={orderData}
			/>
			<Tabs
				tabs={tabs}
				selected={tabIndex}
				onSelect={(tabIndex) => {
					setFilters([{ key: 'tab', value: tabs[tabIndex].id }]);
				}}
			>
				<Layout>
					<Layout.Section>
						<Stack vertical>
							<ErrorBanner
								isVisible={isBannerErrorVisible}
								onDismiss={() => {
									setIsBannerErrorVisible(false);
								}}
							/>
							<ShippedShortBanner order={order} />
						</Stack>
					</Layout.Section>
					{tab === 'history' ? (
						<Layout.Section>
							<Timeline
								title={messages.orderHistory}
								events={events}
								loading={eventsFetching}
								error={eventsError}
							/>
						</Layout.Section>
					) : (
						<>
							<Layout.Section>
								<div data-testid="details">
									<Details
										loading={fetching}
										order={order}
										isPackoutEnabled={config?.packoutEnabled}
										isInventoryEnabled={config?.inventoryEnabled}
										isWorkAreaEnabled={config?.workAreasEnabled}
									/>
								</div>
							</Layout.Section>
							<Layout.Section>
								<div data-testid="progress">
									<Progress
										loading={fetching}
										order={order}
										isHealingEnabled={config?.healingEnabled}
										isExceptionActionable={actionableException}
									/>
								</div>
							</Layout.Section>
							<Layout.Section>
								<div data-testid="lines">
									<Lines
										lines={order?.lines}
										isInventoryEnabled={config?.inventoryEnabled}
										isPackoutEnabled={config?.packoutEnabled}
										packoutStatus={order?.packout?.status}
									/>
								</div>
							</Layout.Section>
						</>
					)}
					<Layout.Section>
						<TimezoneFooter />
					</Layout.Section>
				</Layout>
			</Tabs>
			<CancelOrderModal
				isOpen={isCancelModalOpen}
				onClose={closeCancelModal}
				onConfirm={cancelOrder}
				loading={cancelOrderLoading}
			/>
			{order ? (
				<MarkAsCompleteModal
					isOpen={isReviewModalOpen}
					onClose={() => setIsReviewModalOpen(false)}
					order={order}
				/>
			) : null}
		</Page>
	);
}

interface Props {
	order?: FulfillmentApiOrder;
}
function ShippedShortBanner({ order }: Props): JSX.Element | null {
	const { messages } = useLocalization();

	return order?.isShippedShort ? (
		<Banner status="warning">
			<Heading>{messages.shippedShortBannerHeading}</Heading>
			<TextStyle>{messages.shippedShortBannerBody}</TextStyle>
		</Banner>
	) : null;
}
