import {
	MutationResponse,
	MutationUpdateUserPreferenceArgs,
	UserPreference,
} from '@sixriver/fulfillment-api-schema';
import { gql, useMutation } from 'urql';

import { useAuth } from 'hooks/useAuth';

const UPDATE_USER_PREFERENCE_QUERY = gql`
	mutation ($userPreference: UserPreferenceInput!) {
		updateUserPreference(userPreference: $userPreference) {
			success
		}
	}
`;

interface UseUserPreferences {
	isLoading: boolean;
	userPreferences: UserPreference[];
	updateUserPreference: (preference: UserPreference) => Promise<boolean>;
}

export function useUserPreferences(): UseUserPreferences {
	const { isLoading, user, refetchUser } = useAuth();
	const [, update] = useMutation<MutationResponse, MutationUpdateUserPreferenceArgs>(
		UPDATE_USER_PREFERENCE_QUERY,
	);

	const updateUserPreference = async (userPreference: UserPreference) => {
		if (user) {
			await update({ userPreference: { ...userPreference, userId: user.id } });
			refetchUser();
			return true;
		}
		return false;
	};

	return {
		isLoading,
		userPreferences: user?.preferences ?? [],
		updateUserPreference,
	};
}
