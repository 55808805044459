import { Button, Heading, Modal, Stack, TextField } from '@shopify/polaris';
import {
	MutationResponse,
	Return,
	ReturnNoteInput,
	ReturnStatus,
} from '@sixriver/fulfillment-api-schema';
import { useState, useEffect, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useMutation } from 'urql';

import { UPDATE_RETURN_NOTE_MUTATION } from '../InboundReturn.graphql';
import { useAuth } from 'hooks/useAuth';
import { useLocalization } from 'hooks/useLocalization';
import { useToast } from 'hooks/useToast';

interface Props {
	inboundReturn?: Return;
	refetchReturn: () => void;
}

export function ExternalNotes({ inboundReturn, refetchReturn }: Props) {
	const { messages } = useLocalization();
	const { showToast } = useToast();
	const {
		params: { returnId },
	} = useRouteMatch<{ returnId: string }>();

	// State
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [externalNoteValue, setExternalNoteValue] = useState('');

	// Auth
	const { user } = useAuth();
	const loggedInUserId = user?.id;

	const existingNote =
		inboundReturn && inboundReturn.externalComments && inboundReturn.externalComments.length > 0;

	// Mutations
	const [, updateReturnNote] = useMutation<
		{ updateReturnNote: MutationResponse },
		{ input: ReturnNoteInput }
	>(UPDATE_RETURN_NOTE_MUTATION);

	const openEditModal = () => {
		setExternalNoteValue(inboundReturn?.externalComments || '');
		setIsEditModalOpen(true);
	};
	const closeEditModal = () => setIsEditModalOpen(false);

	const saveInternalNote = async () => {
		const input: ReturnNoteInput = {
			returnId,
			commentType: 'external',
			comments: externalNoteValue,
			userId: loggedInUserId || 'leBridge',
		};

		const { data } = await updateReturnNote({ input });
		if (data?.updateReturnNote.success) {
			refetchReturn();
			setExternalNoteValue('');
			closeEditModal();
		} else {
			showToast(messages.saveNoteFailed);
		}
	};

	const cancelInternalNoteSave = () => {
		setExternalNoteValue('');
		closeEditModal();
	};

	const handleInternalNoteChange = useCallback((newValue) => setExternalNoteValue(newValue), []);

	const [returnCompleted, setReturnCompleted] = useState(false);

	useEffect(() => {
		const status = inboundReturn?.returnStatus as ReturnStatus;
		setReturnCompleted([ReturnStatus.Completed, ReturnStatus.Cancelled].includes(status));
	}, [inboundReturn]);

	return (
		<>
			<Stack distribution="equalSpacing">
				<Heading>{messages.notes}</Heading>
				{!returnCompleted && (
					<>
						{existingNote ? (
							<Button plain onClick={openEditModal}>
								{messages.editNote}
							</Button>
						) : (
							<Button plain onClick={openEditModal}>
								{messages.addNote}
							</Button>
						)}
					</>
				)}
			</Stack>
			{existingNote && (
				<div>
					<br />
					<p>{inboundReturn?.externalComments}</p>
				</div>
			)}
			<Modal
				title={messages.notes}
				open={isEditModalOpen}
				onClose={closeEditModal}
				primaryAction={{
					content: messages.save,
					onAction: saveInternalNote,
				}}
				secondaryActions={[
					{
						content: messages.cancel,
						destructive: false,
						onAction: cancelInternalNoteSave,
					},
				]}
			>
				<Modal.Section>
					<TextField
						autoComplete="off"
						label={messages.notes}
						value={externalNoteValue}
						onChange={handleInternalNoteChange}
						multiline={4}
					/>
				</Modal.Section>
			</Modal>
		</>
	);
}
