import { Badge, Tooltip } from '@shopify/polaris';
import { JobStatus, PickingJobStatus } from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';
import { useConfig } from 'hooks/useConfig';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	pickingStatus?: PickingJobStatus | JobStatus;
	isEmptyIfNoData?: boolean;
}

export function OutboundJobStatusBadge({ pickingStatus, isEmptyIfNoData }: Props) {
	const { messages } = useLocalization();

	// GRM
	const { config } = useConfig();

	// Computed
	const isPackoutEnabled = config?.packoutEnabled;

	// Render
	return pickingStatus === PickingJobStatus.Unassigned ? (
		<Badge status="warning">{messages.unassigned}</Badge>
	) : pickingStatus === PickingJobStatus.Picking ? (
		<Badge status="info">{messages.picking}</Badge>
	) : pickingStatus === PickingJobStatus.Sortable ? (
		<Badge status="info">{messages.readyToSort}</Badge>
	) : pickingStatus === PickingJobStatus.Sorting ? (
		<Badge status="info">{messages.sorting}</Badge>
	) : pickingStatus === PickingJobStatus.Packable ? (
		<Badge status="info">{messages.readyToPack}</Badge>
	) : pickingStatus === PickingJobStatus.Packing ? (
		<Badge status="info">{messages.packing}</Badge>
	) : pickingStatus === PickingJobStatus.Offline ? (
		<Tooltip content={messages.jobInterruptedHint}>
			<Badge status="warning">{messages.interrupted}</Badge>
		</Tooltip>
	) : pickingStatus === PickingJobStatus.Picked ? (
		isPackoutEnabled ? (
			<Badge status="info">{messages.picked}</Badge>
		) : (
			<Badge>{messages.completed}</Badge>
		)
	) : pickingStatus === PickingJobStatus.Packed ? (
		<Badge>{messages.completed}</Badge>
	) : pickingStatus === PickingJobStatus.Exception ? (
		<Badge status="critical">{messages.exception}</Badge>
	) : pickingStatus === PickingJobStatus.Sorted ? (
		<Badge>{messages.completed}</Badge>
	) : pickingStatus === PickingJobStatus.Cancelled ? (
		<Badge>{messages.canceled}</Badge>
	) : isEmptyIfNoData ? null : (
		<NoData />
	);
}
