import { AppProvider as PolarisAppProvider } from '@shopify/polaris';
import DefaultThemeColors from '@shopify/polaris-tokens/dist-modern/theme/base.json';
import en from '@shopify/polaris/locales/en.json';
import es from '@shopify/polaris/locales/es.json';
import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import { useLocalization } from 'hooks/useLocalization';
import logo from 'images/logo.svg';

// Create a custom link that uses react-router-dom <Link/> (https://reacttraining.com/react-router/web/api/Link)
function CustomLink({
	children,
	url,
	external,
	...rest
}: PropsWithChildren<{
	url: string;
	external?: boolean;
}>) {
	return external ? (
		<a href={url} target="_blank" rel="noreferrer noopener" {...rest}>
			{children}
		</a>
	) : (
		<Link to={url} {...rest}>
			{children}
		</Link>
	);
}

// Use <CustomLink/> to render links in Polaris
export function PolarisProvider({ children }: PropsWithChildren<{}>) {
	const { locale } = useLocalization();
	const i18nFile = {
		'en-US': en,
		'es-MX': es,
	}[locale];

	// This bundle is used by the Polaris library components only.
	return (
		<PolarisAppProvider
			i18n={i18nFile || en}
			linkComponent={CustomLink}
			theme={{
				colorScheme: 'light',
				colors: {
					...DefaultThemeColors,
					primary: '#2C6ECB',
				},
				logo: {
					topBarSource: logo,
					accessibilityLabel: '6 River Systems',
					width: 200,
				},
			}}
			features={{ newDesignLanguage: true }}
		>
			{children}
		</PolarisAppProvider>
	);
}
