import { PropsWithChildren } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { AuthProvider } from '../AuthProvider';
import { ErrorBoundary } from '../ErrorBoundary';
import { GraphQLProvider } from '../GraphQLProvider';
import { PolarisProvider } from '../PolarisProvider';
import { ToastProvider } from '../ToastProvider';

export function AppProvider({ children }: PropsWithChildren<{}>) {
	return (
		<ErrorBoundary>
			<BrowserRouter basename={process.env.PUBLIC_URL}>
				<GraphQLProvider>
					<AuthProvider>
						<PolarisProvider>
							<ToastProvider>{children}</ToastProvider>
						</PolarisProvider>
					</AuthProvider>
				</GraphQLProvider>
			</BrowserRouter>
		</ErrorBoundary>
	);
}
