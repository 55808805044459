import { Card, Button } from '@shopify/polaris';
import { ReplenJob } from '@sixriver/fulfillment-api-schema';

import { CardDetails } from 'components/CardDetails';
import { CopyButton } from 'components/CopyButton';
import { DateTime } from 'components/DateTime';
import { NoData } from 'components/NoData';
import { RelativeDateTime } from 'components/RelativeDateTime';
import { getMfpUrl } from 'helpers/mfp';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	loading?: boolean;
	job?: ReplenJob;
	relatedJobs?: ReplenJob[];
}

export function ReplenishJobDetails({ loading, job }: Props) {
	const { messages } = useLocalization();

	return (
		<Card title={messages.details}>
			<Card.Section>
				<CardDetails
					loading={loading}
					primary={[
						{
							label: messages.assignedBy,
							content: job?.inductedBy?.name || <NoData />,
						},
						{
							label: messages.sourceLocation,
							content: job?.tasks?.[0].source?.address || <NoData />,
						},
					]}
					secondary={[
						{
							label: messages.createdAt,
							content: (
								<>
									<DateTime date={job?.createdAt} />
									<RelativeDateTime date={job?.createdAt} />
								</>
							),
						},
						{
							label: messages.updatedAt,
							content: <DateTime date={job?.updatedAt} />,
						},
						{
							label: messages.completedAt,
							content: <DateTime date={job?.completedAt} />,
						},
					]}
				/>
			</Card.Section>
			<Card.Section>
				<CardDetails
					loading={loading}
					primary={[
						{
							label: messages.chuck,
							content: job?.mfp?.name,
						},
					]}
					secondary={[
						{
							label: messages.takeoff,
							content: job?.takeoffPoint || <NoData />,
						},
					]}
				/>
				<br />
				<Button plain url={getMfpUrl(job?.mfp?.name)} disabled={!job?.mfp?.name}>
					{messages.findThisChuck}
				</Button>
			</Card.Section>
			<Card.Section>
				<CardDetails
					loading={loading}
					primary={[
						{
							label: messages.uniqueJobId,
							content: job?.id ? <CopyButton text={job.id} /> : null,
						},
					]}
				/>
			</Card.Section>
		</Card>
	);
}
