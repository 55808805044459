import { Badge, Stack } from '@shopify/polaris';
import { LaneContainer } from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	container?: LaneContainer;
}

export function ContainerExceptionBadge({ container }: Props): JSX.Element | null {
	const { messages } = useLocalization();

	if (!container) {
		return null;
	}

	const badge = container.carrierPickupDetails?.isOverridden ? (
		<Badge status="critical">{messages.laneOverride}</Badge>
	) : (
		<NoData />
	);

	return <Stack spacing="extraTight">{badge}</Stack>;
}
