import gql from 'graphql-tag';

import { PAGE_INFO_FRAGMENT } from 'graphql/page-info';

export const LINES_QUERY = gql`
	query GetPickingLines($projectionId: String!, $view: LinesViews, $after: String, $first: Int) {
		linesByPickingProjectionId(
			projectionId: $projectionId
			view: $view
			after: $after
			first: $first
		) {
			edges {
				node {
					id
					status
					quantity
					doneQuantity
					timeline {
						completedAt
					}
					source {
						id
						address
					}
					user {
						id
						name
					}
					product {
						id
						customerIdentifier
						description
					}
				}
			}
			pageInfo {
				...PageInfo
			}
		}
	}
	${PAGE_INFO_FRAGMENT}
`;

export const COUNTS_QUERY = gql`
	query CountLines($projectionId: String!) {
		linesPickingCounts(projectionId: $projectionId) {
			All
			Exceptions
		}
	}
`;
