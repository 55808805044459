import { IndexTable } from '@shopify/polaris';
import { WorkArea } from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';

export function WorkAreasCell({ workAreas }: { workAreas: WorkArea[] }) {
	return (
		<IndexTable.Cell>
			{workAreas.length ? <div>{workAreas.map((area) => area.name).join(', ')}</div> : <NoData />}
		</IndexTable.Cell>
	);
}
