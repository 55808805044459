import { Link, Badge } from '@shopify/polaris';
import { Line, LineStatus } from '@sixriver/fulfillment-api-schema';
import { Fragment } from 'react';

import { DataTable, Column } from 'components/DataTable';
import { NoData } from 'components/NoData';
import { countLineExceptions } from 'helpers/exception';
import { useConfig } from 'hooks/useConfig';
import { useFilters, useSetFilters } from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';
import { product } from 'routes';

export function ExceptionLines({ loading = false, lines }: { loading?: boolean; lines?: Line[] }) {
	const { messages, translate } = useLocalization();
	const { query } = useFilters();
	const setFilters = useSetFilters();

	const { config } = useConfig();

	const filteredLines = (lines || []).filter((line) => {
		if (query) {
			const lowerCaseQuery = query.toLowerCase();
			return (
				line?.product?.customerIdentifier?.toLowerCase()?.includes(lowerCaseQuery) ||
				line?.product?.description?.toLowerCase()?.includes(lowerCaseQuery) ||
				(line.status?.includes?.(LineStatus.WorkflowException) &&
					'exception'.includes(lowerCaseQuery)) ||
				(line.status?.includes?.(LineStatus.DamagedProduct) &&
					'damaged'.includes(lowerCaseQuery)) ||
				(line.status?.includes?.(LineStatus.MissingProduct) &&
					'missing'.includes(lowerCaseQuery)) ||
				(line.status?.includes?.(LineStatus.WorkflowException) && 'other'.includes(lowerCaseQuery))
			);
		}

		return true;
	});

	const rows = filteredLines.map((line) => [
		line.product?.id ? (
			<Link url={product(line.product.id)} key="product-id">
				{line.product.customerIdentifier}
			</Link>
		) : (
			<NoData />
		),
		<Fragment key="product-name">{line.product?.description}</Fragment>,
		translate(messages.pickedOfTotal, {
			picked: line.doneQuantity,
			total: line.quantity,
		}),
		line.status && countLineExceptions([line]) > 0 ? (
			<Badge status="critical" key="exception">
				{messages.exception}
			</Badge>
		) : (
			<NoData />
		),
		line.status ? (
			line.status.includes(LineStatus.DamagedProduct) ? (
				messages.exceptionTypes.DAMAGED_PRODUCT
			) : line.status.includes(LineStatus.MissingProduct) ? (
				messages.exceptionTypes.MISSING_PRODUCT
			) : line.status.includes(LineStatus.WorkflowException) ? (
				messages.exceptionTypes.WORKFLOW_EXCEPTION
			) : (
				<NoData />
			)
		) : (
			<NoData />
		),
		line.source?.address || <NoData />,
	]);

	const columns: Column[] = [
		{
			type: 'text',
			heading: messages.item,
		},
		{
			type: 'text',
			heading: config?.inventoryEnabled ? messages.sku : messages.name,
		},
		{
			type: 'text',
			heading: messages.unitsPicked,
		},
		{
			type: 'text',
			heading: messages.exception,
		},
		{
			type: 'text',
			heading: messages.reason,
		},
		{
			type: 'text',
			heading: messages.location,
		},
	];

	return (
		<DataTable
			title={messages.lines}
			columns={columns}
			rows={rows}
			loading={loading}
			emptyStateHeading={messages.linesNotFound}
			query={query}
			setFilters={setFilters}
		/>
	);
}
