import { IndexTable } from '@shopify/polaris';
import { OrderV2 } from '@sixriver/fulfillment-api-schema';

import { OrderStatusBadgeV2 } from 'components/OrderStatusBadge/OrderStatusBadgeV2';

interface StatusCellProps {
	order: OrderV2;
}

export function StatusCell({ order }: StatusCellProps) {
	return (
		<IndexTable.Cell>
			<OrderStatusBadgeV2 order={order} />
		</IndexTable.Cell>
	);
}
