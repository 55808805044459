import { Card } from '@shopify/polaris';
import { usePrevious } from '@shopify/react-hooks';
import {
	Picking,
	PickingJobStatus,
	PickingJobExceptionStatus,
} from '@sixriver/fulfillment-api-schema';
import { useState, useEffect } from 'react';

import { CardDetails } from 'components/CardDetails';
import { DateTime } from 'components/DateTime';
import { ViewTabs } from 'components/ViewTabs';
import { sortJobs } from 'helpers/exception';
import { countLineExceptions } from 'helpers/exception';
import { useLocalization } from 'hooks/useLocalization';

export function ExceptionJobs({
	loading = false,
	jobs,
	isWorkAreaEnabled,
}: {
	loading?: boolean;
	jobs?: Picking[] | null;
	isWorkAreaEnabled?: boolean;
}) {
	const { messages } = useLocalization();

	const sortedJobs = sortJobs(jobs);
	const initialJob = sortedJobs?.[0];

	const [selectedJobId, setSelectedJobId] = useState(initialJob?.id || '');

	const selectedJob = selectedJobId
		? (jobs || []).find((job) => job.id === selectedJobId)
		: undefined;

	const prevLoading = usePrevious(loading);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		// Make sure to set the right job id in state after loading is done to avoid having no buttons selected
		if (prevLoading && !loading) {
			setSelectedJobId(initialJob?.id || '');
		}
	});

	const items = (sortedJobs || []).map((job, index) => ({
		id: job.id,
		label: index === 0 ? messages.initialJob : `${messages.resolutionJob} ${index + 1}`,
	}));

	return (
		<Card title={messages.jobs}>
			<Card.Section>
				<ViewTabs tabs={items} selected={selectedJobId} onSelect={setSelectedJobId} />
			</Card.Section>
			<Card.Section>
				<CardDetails
					loading={loading}
					primary={[
						{
							label: messages.licensePlate,
							content: selectedJob?.container?.barcode,
						},
						{
							label: messages.status,
							content:
								selectedJob?.exception === PickingJobExceptionStatus.Unresolved
									? messages.exception
									: selectedJob?.pickingStatus === PickingJobStatus.Picking
									? messages.picking
									: selectedJob?.pickingStatus === PickingJobStatus.Picked
									? messages.picked
									: selectedJob?.pickingStatus === PickingJobStatus.Cancelled
									? messages.canceled
									: null,
						},
						{
							label: messages.createdAt,
							content: selectedJob?.timeline?.createdAt ? (
								<DateTime date={selectedJob?.timeline?.createdAt} />
							) : null,
						},
						{
							label: messages.updatedAt,
							content: selectedJob?.timeline?.updatedAt ? (
								<DateTime date={selectedJob?.timeline?.updatedAt} />
							) : null,
						},
					]}
					secondary={[
						{
							label: messages.lines,
							content: selectedJob?.lines?.length || 0,
						},
						{
							label: messages.exceptions,
							content: countLineExceptions(selectedJob?.lines),
						},
						{
							label: messages.takeoffLocation,
							content: selectedJob?.takeoffDestination,
						},
						...(isWorkAreaEnabled
							? [
									{
										label: messages.workArea,
										content: selectedJob?.workAreas?.map((workArea) => workArea.name).join(', '),
									},
							  ]
							: []),
					]}
				/>
			</Card.Section>
		</Card>
	);
}
