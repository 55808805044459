import gql from 'graphql-tag';

export const ORDER_QUERY = gql`
	query GetOrder($projectionId: String!) {
		orderByProjectionId(projectionId: $projectionId) {
			id
			type
			status
			customerIdentifier
			customers {
				name
			}
			expectedShipDate
			isCancelable
			timeline {
				createdAt
				updatedAt
				pickedAt
				completedAt
			}
			lines {
				id
				status
				quantity
				doneQuantity
				timeline {
					completedAt
				}
				product {
					id
					customerIdentifier
					name
				}
			}
			exception {
				status
			}
			servicedBy {
				... on Picking {
					id
					stagingIdentifier
					isHealJob
					lines {
						id
						status
						quantity
						doneQuantity
						product {
							id
							customerIdentifier
							name
						}
					}
					packout {
						id
						timeline {
							finishedAt
						}
					}
				}
				... on Sortation {
					id
				}
				... on HealPlan {
					id
				}
			}
			packout {
				id
				timeline {
					finishedAt
				}
				status
			}
			healPlan {
				status
			}
			workAreas {
				name
			}
			packoutCompletedAt
			workOrderTypes
			destinationNode
			canShipShort {
				quantity
				doneQuantity
				exceptionCount
				unassignedCount
			}
			isShippedShort
			jobFlowRuleLpn
			bulkOrderProjectionId
		}
	}
`;

export const ORDER_EVENTS_QUERY = gql`
	query GetOrderEvents($projectionId: String!) {
		orderEvents(projectionId: $projectionId) {
			timestamp
			__typename
			... on PickingEvent {
				user {
					id
					name
					badge
				}
				deviceId
				externalContainerId
				jobId
				projectionId
				orderProjectionId
				takeoffDestination
				isHealing
			}
			... on PickingInductedEvent {
				healedJobExternalContainerId
			}
			... on PickingPickedEvent {
				storageLocationId
				storageLocationAddress
				productId
				productExternalId
				productName
				quantity
				doneQuantity
				exception
			}
			... on PackoutEvent {
				user {
					id
					name
				}
				deviceId
				externalContainerId
				projectionId
			}
		}
	}
`;

export const MARK_AS_COMPLETE_MUTATION = gql`
	mutation shipShortOrder($id: String!) {
		shipShortOrder(projectionId: $id) {
			success
		}
	}
`;
