import gql from 'graphql-tag';

export const CANCEL_JOB_MUTATION = gql`
	mutation CancelJob($projectionId: String!) {
		cancelPickingJob(projectionId: $projectionId) {
			success
		}
	}
`;

export const PICKING_QUERY = gql`
	query GetPickingJob($projectionId: String!) {
		pickingByProjectionId(projectionId: $projectionId) {
			id
			type
			pickingStatus
			exception
			stagingIdentifier
			takeoffDestination
			taskCoordinatorIdentifier
			isCancelable
			lines {
				id
				quantity
				doneQuantity
				status
				timeline {
					completedAt
				}
			}
			expectedShipDate
			timeline {
				createdAt
				updatedAt
				completedAt
				pickedAt
			}
			isHealJob
			servicing {
				... on Order {
					id
					customerIdentifier
					lines {
						id
						status
					}
					servicedBy {
						... on Picking {
							id
							isHealJob
							lines {
								id
								quantity
								doneQuantity
								status
								timeline {
									completedAt
								}
							}
						}
					}
				}
				... on Sortation {
					id
					jobId
					wall
					batchId
					kiosk
					status
					timeline {
						completedAt
					}
					pickingJobs {
						id
					}
				}
			}
			container {
				name
				barcode
			}
			mfp {
				name
			}
			packout {
				id
				status
				timeline {
					acceptedAt
					updatedAt
					inductedAt
					finishedAt
				}
				packStation {
					name
				}
				containerTypes
				user {
					badge
					id
				}
				packoutRequestIdentifier
			}
			workAreas {
				id
				name
			}
			workOrderTypes
			destinationNode
		}
	}
`;

export const PICKING_EVENTS_QUERY = gql`
	query GetPickingEvents($projectionId: String!) {
		jobEvents(projectionId: $projectionId) {
			timestamp
			__typename
			... on PickingEvent {
				user {
					id
					name
					badge
				}
				deviceId
				externalContainerId
				jobId
				projectionId
				orderProjectionId
				takeoffDestination
				isHealing
			}
			... on PickingInductedEvent {
				healedJobExternalContainerId
			}
			... on PickingPickedEvent {
				storageLocationId
				storageLocationAddress
				productId
				productExternalId
				productName
				quantity
				doneQuantity
				exception
			}
			... on PackoutEvent {
				user {
					id
					name
				}
				deviceId
				externalContainerId
				projectionId
			}
		}
	}
`;
