import styles from './FloorView.module.css';
import { useConfig } from 'hooks/useConfig';
import { useLocalization } from 'hooks/useLocalization';

export type SortMethod = 'idleTime' | 'batteryLevel' | 'priority' | 'faulty';

interface Props {
	sortMethod: SortMethod;
	onSort(sortMethod: SortMethod): void;
}

export function FullScreenOptions({ sortMethod, onSort }: Props) {
	const { messages } = useLocalization();
	const { config } = useConfig();
	const isUsingHighPriority = config?.highPriorityChuckEnabled;

	const nonPrioritySortOptions = (
		<optgroup label={messages.sortBy}>
			<option value="idleTime">{messages.sortOptions.idleTime}</option>
			<option value="batteryLevel">{messages.sortOptions.batteryLevel}</option>
		</optgroup>
	);

	const prioritySortOptions = (
		<optgroup label={messages.moveToTop}>
			<option value="faulty">{messages.chuckStates.faulty}</option>
			<option value="priority">{messages.chuckStates.priority}</option>
		</optgroup>
	);

	return (
		<div className={styles.fsOptions}>
			<select
				title={messages.sortChucks}
				value={sortMethod}
				onChange={(evt) => onSort(evt.target.value as SortMethod)}
			>
				{isUsingHighPriority ? prioritySortOptions : nonPrioritySortOptions}
			</select>
			<button title={messages.exitDashboard} onClick={() => document.exitFullscreen()}>
				{messages.exit}
			</button>
		</div>
	);
}
