import { IndexTable } from '@shopify/polaris';
import { OrderV2 } from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';

interface Props {
	order: OrderV2;
}

export function TagCell({ order }: Props) {
	if (order.tag) {
		return <IndexTable.Cell>{<div>{order.tag}</div>}</IndexTable.Cell>;
	}

	return (
		<IndexTable.Cell>
			{' '}
			<NoData />
		</IndexTable.Cell>
	);
}
