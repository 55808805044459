import { Card, Banner } from '@shopify/polaris';

import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';

interface BannerProps {
	isVisible?: boolean;
	onDismiss: () => void;
}

export function BulkCancelInProgressBanner({
	isVisible = false,
	onDismiss,
}: BannerProps): JSX.Element | null {
	const { messages } = useLocalization();

	if (!isVisible) {
		return null;
	}

	return (
		<Card.Section>
			<Banner
				status="info"
				onDismiss={onDismiss}
				action={{ url: routes.actionLog(), content: messages.viewActionLog }}
			>
				<p>{messages.cancelOrderSubmitted}</p>
			</Banner>
		</Card.Section>
	);
}
