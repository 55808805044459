import { PropsWithChildren } from 'react';

import { NoAccess } from './NoAccess';
import { useAuth } from 'hooks/useAuth';

export interface AccessControlProps {
	allowedRoles?: string[];
	noAccess?: JSX.Element;
}

export function AccessControl({
	children,
	allowedRoles = [],
	noAccess = <NoAccess />,
}: PropsWithChildren<AccessControlProps>): JSX.Element | null {
	const { isLoading, isUserAllowed } = useAuth();

	if (isLoading) {
		return null;
	}

	if (isUserAllowed(allowedRoles)) {
		return <>{children}</>;
	}

	return <>{noAccess}</>;
}
