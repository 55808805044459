import { ReplenTask } from '@sixriver/fulfillment-api-schema';

export function calculateReplenTasksUnits(tasks?: ReplenTask[] | null) {
	return (tasks || []).reduce(
		(result, task) => ({
			replenished: result.replenished + task.doneQuantity,
			total: result.total + task.quantity,
		}),
		{ replenished: 0, total: 0 },
	);
}
