import { Badge, Tooltip } from '@shopify/polaris';
import { ReplenJobState } from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	replenStatus?: ReplenJobState;
	isEmptyIfNoData?: boolean;
}

export function ReplenJobStatusBadge({ replenStatus, isEmptyIfNoData }: Props) {
	const { messages } = useLocalization();

	const badges: Record<ReplenJobState, JSX.Element> = {
		[ReplenJobState.Assigned]: <Badge status="info">{messages.assigned}</Badge>,
		[ReplenJobState.Cancelled]: <Badge>{messages.canceled}</Badge>,
		[ReplenJobState.InProgress]: <Badge status="info">{messages.inProgress}</Badge>,
		[ReplenJobState.Completed]: <Badge>{messages.completed}</Badge>,
		[ReplenJobState.Inducted]: <Badge status="info">{messages.inducted}</Badge>,
		[ReplenJobState.Interrupted]: (
			<Tooltip content={messages.jobInterruptedHint}>
				<Badge status="warning">{messages.interrupted}</Badge>
			</Tooltip>
		),
		[ReplenJobState.Open]: <Badge status="warning">{messages.unassigned}</Badge>,
	};

	return badges[replenStatus as ReplenJobState] ? (
		badges[replenStatus as ReplenJobState]
	) : isEmptyIfNoData ? null : (
		<NoData />
	);
}
