import { Page, Layout, ChoiceList, Stack } from '@shopify/polaris';
import { useDebouncedValue, usePrevious } from '@shopify/react-hooks';
import {
	Count,
	WorkOrderType,
	Cutoff,
	OrderByDirection,
	PickingConnection,
	PickingJobOrderByFields,
	PickingJobStatus,
	PickStrategy,
	QueryPickingJobsArgs,
	QueryPickingJobsCountArgs,
	DatePrecision,
} from '@sixriver/fulfillment-api-schema';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'urql';

import { JobHistoryLimiterConfirmModal } from './JobHistoryLimiterConfirmModal';
import { OutboundJobViews, useOutboundJobViewStateMap } from './OutboundJobViews';
import {
	PICKING_JOBS_QUERY,
	CUTOFF_DATES_QUERY,
	FILTERED_PICKING_COUNTS_QUERY,
	BIG_NUMBER_CARDS_STATIC_PICKING_COUNTS_QUERY,
} from './OutboundJobs.graphql';
import { OutboundJobsTable } from './OutboundJobsTable';
import { AutoRefresh } from 'components/AutoRefresh';
import { CutoffFilter } from 'components/CutoffFilter';
import {
	DateRange,
	DateRangeSelect,
	OptionLabelValue,
	OptionValues,
} from 'components/DateRangeSelect';
import { Error } from 'components/Error';
import { TimezoneFooter } from 'components/TimezoneFooter';
import { Toteboard } from 'components/Toteboard';
import { WorkAreaMenu } from 'components/WorkAreaMenu';
import { getPageSize } from 'helpers/page-size';
import { MIN_QUERY_LENGTH } from 'helpers/table';
import { getDateDiffInDays, getMidnight, startOfDay } from 'helpers/time';
import { useConfig } from 'hooks/useConfig';
import { DEFAULT_VIEW_KEY, useFilters, useSetFilters } from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';
import { usePickStrategies, usePickStrategyName } from 'hooks/usePickStrategies';
import { usePolling } from 'hooks/usePolling';
import { usePollingQuery } from 'hooks/usePollingQuery';
import { useWorkAreas } from 'hooks/useWorkArea/use-work-areas';

const JOB_STATUSES_KEY = 'jobStatuses';
const PICK_STRATEGY_KEY = 'pickStrategy';
const CUTOFF_KEY = 'cutoff';
const PROJECTION_IDS = 'projectionIds';
const WORKAREA_KEY = 'workArea';
const JOB_CREATED_AT_FROM_KEY = 'jobCreatedAtFrom';
const WORK_ORDER_TYPE = 'workOrderType';

const defaultSort = PickingJobOrderByFields.JobCreatedAt + ' ' + OrderByDirection.Desc;
const defaultDate = getMidnight(-7);

export function OutboundJobs() {
	const { messages, translate, formatDateTime, formatNumber } = useLocalization();

	// State
	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);
	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();
	const [proposedHistoryLimitDate, setProposedHistoryLimitDate] = useState<{
		date: Date;
		option: OptionValues;
	} | null>(null);

	const translatedOutboundJobViewNames = {
		[OutboundJobViews.All]: messages.all,
		[OutboundJobViews.Cancelled]: messages.canceledToday,
		[OutboundJobViews.Complete]: messages.completedToday,
		[OutboundJobViews.Exceptions]: messages.exceptions,
		[OutboundJobViews.Interrupted]: messages.interrupted,
		[OutboundJobViews.Packing]: messages.packing,
		[OutboundJobViews.Picked]: messages.picked,
		[OutboundJobViews.Picking]: messages.picking,
		[OutboundJobViews.ReadyToPack]: messages.readyToPack,
		[OutboundJobViews.Sorting]: messages.sorting,
		[OutboundJobViews.InProgress]: messages.inProgress,
	};

	const getPickStrategyName = usePickStrategyName();

	// Config
	const midnight = startOfDay();
	const outboundJobViewStateMap = useOutboundJobViewStateMap();
	const { config } = useConfig();
	const { workAreas } = useWorkAreas();

	const isPackoutEnabled = config?.packoutEnabled;
	const isSortationEnabled = config?.sortationEnabled;

	const openJobStatuses = [
		PickingJobStatus.AwaitingInventory,
		PickingJobStatus.Exception,
		PickingJobStatus.Offline,
		PickingJobStatus.Packable,
		PickingJobStatus.Packing,
		...(isPackoutEnabled ? [PickingJobStatus.Picked] : []),
		PickingJobStatus.Picking,
		PickingJobStatus.Sortable,
		PickingJobStatus.Sorted,
		PickingJobStatus.Sorting,
		PickingJobStatus.Unassigned,
		PickingJobStatus.Unknown,
	];

	const completedJobStatuses = isPackoutEnabled
		? [PickingJobStatus.Packed]
		: [PickingJobStatus.Picked];

	// Params
	const {
		view = OutboundJobViews.All,
		query,
		sort = defaultSort,
		[PICK_STRATEGY_KEY]: pickStrategyParam,
		[CUTOFF_KEY]: cutoffDate,
		[PROJECTION_IDS]: projectionIdsParam,
		[JOB_STATUSES_KEY]: jobStatusesParam,
		[WORKAREA_KEY]: workArea,
		[JOB_CREATED_AT_FROM_KEY]: jobCreatedAtFromParam,
		[WORK_ORDER_TYPE]: workOrderType,
	} = useFilters([
		PICK_STRATEGY_KEY,
		CUTOFF_KEY,
		PROJECTION_IDS,
		WORKAREA_KEY,
		JOB_STATUSES_KEY,
		JOB_CREATED_AT_FROM_KEY,
		WORK_ORDER_TYPE,
	]);

	const setFilters = useSetFilters();

	const searchTextParam = useDebouncedValue(query) || '';
	const searchText = searchTextParam.length >= MIN_QUERY_LENGTH ? searchTextParam : undefined;

	const projectionIds = projectionIdsParam ? projectionIdsParam.split(' ') : undefined;

	const selectedPickStrategies = pickStrategyParam
		? (pickStrategyParam.split(' ') as PickStrategy[])
		: undefined;

	const selectedJobStatuses = jobStatusesParam
		? (jobStatusesParam.split(' ') as (PickingJobStatus | 'Complete')[])
				.map((status) => {
					if (status === 'Complete') {
						return completedJobStatuses;
					}
					return [status];
				})
				.flat()
		: undefined;

	const selectedWorkOrderTypes = workOrderType ? workOrderType.split(' ') : undefined;

	const statuses = selectedJobStatuses
		? selectedJobStatuses
		: outboundJobViewStateMap[view as OutboundJobViews];

	const expectedShipDate = cutoffDate ? new Date(cutoffDate) : undefined;

	const [orderBy, orderByDirection]: [
		PickingJobOrderByFields | undefined,
		OrderByDirection | undefined,
	] = sort
		? (sort.split(' ') as [PickingJobOrderByFields, OrderByDirection])
		: [undefined, undefined];

	const selectedWorkAreas = workArea ? workArea.split(' ') : undefined;
	const historyDateLimiter = useMemo(
		() => (jobCreatedAtFromParam ? new Date(jobCreatedAtFromParam) : defaultDate),
		[jobCreatedAtFromParam],
	);

	// Date Range
	const options: OptionValues[] = [
		OptionValues.today,
		OptionValues.last3Days,
		OptionValues.last7Days,
		OptionValues.last30Days,
		OptionValues.last90Days,
		OptionValues.last180Days,
	];

	function handleDateRangeSelectionChanged(dateRange: DateRange, dateOption: OptionLabelValue) {
		const { start } = dateRange;

		if (!start) {
			return;
		}

		const startDateMidnight: Date = new Date(start);
		startDateMidnight.setHours(0, 0, 0, 0);

		if (getDateDiffInDays(midnight, startDateMidnight) >= 30) {
			setProposedHistoryLimitDate({ date: startDateMidnight, option: dateOption.value });
		} else {
			setFilters([{ key: JOB_CREATED_AT_FROM_KEY, value: startDateMidnight.toISOString() }]);
			setFilters([{ key: CUTOFF_KEY, value: '' }]);
		}
	}

	function handleDiscardJobHistoryLimitChange() {
		setProposedHistoryLimitDate(null);
	}

	function handleChangeJobHistoryLimit() {
		if (proposedHistoryLimitDate) {
			setFilters([
				{ key: JOB_CREATED_AT_FROM_KEY, value: proposedHistoryLimitDate.date.toISOString() },
			]);
			setFilters([{ key: CUTOFF_KEY, value: '' }]);
		}

		setProposedHistoryLimitDate(null);
	}

	useEffect(() => {
		// clear the status filter when switching views (tabs)
		if (jobStatusesParam && view !== OutboundJobViews.All) {
			setFilters([{ key: JOB_STATUSES_KEY, value: undefined }]);
		}
	}, [setFilters, view, jobStatusesParam]);

	// Queries
	const [{ fetching: cutoffDatesFetching, data: cutoffDatesData, error: cutoffDatesError }] =
		useQuery<{ cutoffForPickingJobs: Cutoff }>({
			query: CUTOFF_DATES_QUERY,
			variables: {
				datePrecision: DatePrecision.Minute,
				createdAtFrom: historyDateLimiter,
			},
		});

	const [{ fetching: pickingFetching, data: pickingData, error: pickingError }] = usePollingQuery<
		{ pickingJobs: PickingConnection },
		QueryPickingJobsArgs
	>({
		query: PICKING_JOBS_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			searchText,
			pickTypes: selectedPickStrategies,
			expectedShipDateFrom: expectedShipDate,
			expectedShipDateTo: expectedShipDate
				? new Date(expectedShipDate.getTime() + 60 * 1000)
				: undefined,
			projectionIds: projectionIds || undefined,
			orderBy,
			orderByDirection,
			jobCreatedAtFrom: historyDateLimiter,
			jobUpdatedAtFrom:
				selectedJobStatuses?.some((status) => completedJobStatuses.includes(status)) ||
				selectedJobStatuses?.includes(PickingJobStatus.Cancelled) ||
				view === OutboundJobViews.Complete || // Complete Today
				view === OutboundJobViews.Cancelled // Canceled Today
					? midnight
					: undefined,
			statuses,
			first: getPageSize(),
			after: paginationCursors[0],
			includeHealJobs:
				!!projectionIds || (searchText?.length || 0) >= MIN_QUERY_LENGTH ? true : false,
			workAreaIds: selectedWorkAreas,
			workOrderTypes: selectedWorkOrderTypes as WorkOrderType[],
		},
	});

	const [
		{
			fetching: bigCardPickingJobCountsFetching,
			data: bigCardPickingJobCountsData,
			error: bigCardPickingJobCountsError,
		},
	] = useQuery<
		{
			// Cards (static)
			BigCardJobsWithOpenStatusCount: Count;
			BigCardJobsCompletedTodayCount: Count;

			BigCardPickingJobsCount: Count;
			BigCardPickedJobsCount: Count;

			// Prep-work for HRZ-1215
			// BigCardReadyToSortJobsCount: Count;
			// BigCardSortingJobsCount: Count;
			// BigCardSortedJobsCount: Count;

			BigCardReadyToPackJobsCount: Count;
			BigCardPackingJobsCount: Count;
		},
		{
			openStatuses: PickingJobStatus[];
			todayStartTime: Date;
			completedStatuses: PickingJobStatus[];
			jobCreatedAtFrom: Date;
		}
	>({
		query: BIG_NUMBER_CARDS_STATIC_PICKING_COUNTS_QUERY,
		variables: {
			todayStartTime: midnight,
			openStatuses: openJobStatuses,
			completedStatuses: completedJobStatuses,
			jobCreatedAtFrom: historyDateLimiter,
		},
	});

	const [
		{ fetching: filteredCountsFetching, data: filteredCountsData, error: filteredCountsError },
	] = useQuery<
		{
			// Tabs & Total Count (uses filters)
			filteredTotalCount: Count;
			filteredCancelledTodayCount: Count;
			filteredExceptionsCount: Count;
			filteredExceptionsTodayCount: Count;
			filteredInterruptedCount: Count;
		},
		QueryPickingJobsCountArgs & {
			todayStartTime: Date;
			completedStatuses: PickingJobStatus[];
		}
	>({
		query: FILTERED_PICKING_COUNTS_QUERY,
		variables: {
			completedStatuses: completedJobStatuses,
			expectedShipDateFrom: expectedShipDate,
			expectedShipDateTo: expectedShipDate,
			includeHealJobs: (searchText?.length || 0) >= MIN_QUERY_LENGTH ? true : false,
			pickTypes: selectedPickStrategies,
			projectionIds: projectionIds || undefined,
			searchText: (searchText?.length || 0) >= MIN_QUERY_LENGTH ? searchText : undefined,
			todayStartTime: midnight,
			workAreaIds: selectedWorkAreas,
			jobCreatedAtFrom: historyDateLimiter,
			workOrderTypes: selectedWorkOrderTypes as WorkOrderType[],
			statuses,
		},
	});

	// Query Data
	const {
		BigCardJobsCompletedTodayCount = { count: 0 },
		BigCardPickingJobsCount = { count: 0 },
		BigCardPickedJobsCount = { count: 0 },

		// Prep-work for HRZ-1215
		// BigCardReadyToSortJobsCount = { count: 0 },
		// BigCardSortingJobsCount = { count: 0 },
		// BigCardSortedJobsCount = { count: 0 },

		BigCardReadyToPackJobsCount = { count: 0 },
		BigCardPackingJobsCount = { count: 0 },
	} = bigCardPickingJobCountsData || {};

	const {
		filteredCancelledTodayCount = { count: 0 },
		filteredExceptionsCount = { count: 0 },
		filteredExceptionsTodayCount = { count: 0 },
		filteredInterruptedCount = { count: 0 },
	} = filteredCountsData || {};

	const fetching =
		cutoffDatesFetching ||
		pickingFetching ||
		bigCardPickingJobCountsFetching ||
		filteredCountsFetching;
	const error =
		cutoffDatesError || pickingError || bigCardPickingJobCountsError || filteredCountsError;

	// Previous filters
	const prevHistoryDateLimiter = usePrevious(historyDateLimiter);
	useEffect(() => {
		// Reset pagination when changing last x days filter
		if (prevHistoryDateLimiter !== historyDateLimiter) {
			setPaginationCursors(() => []);
		}
	}, [historyDateLimiter, prevHistoryDateLimiter, setPaginationCursors]);

	/**
	 * Super hacky way to allow some sites to abuse carrier cutoff and have hundreds per day
	 * and still show them the cutoff filter.   Through trial and error testing, it appears
	 * that React starts chocking when it has to do more that 8000 items in a list.
	 * So if the list has less than the max show the filter OR if the history limiter date is
	 * 7 days or less show the filter (this is slightly dangerous) if the resuls are over 8000
	 * but big query shows the largest sites currently are <7500 in a 7 day period.
	 */

	const alwaysShowWhen = historyDateLimiter.getTime() >= defaultDate.getTime();
	const maxLimit = 8000;
	const showCutoffFilter =
		alwaysShowWhen || (cutoffDatesData?.cutoffForPickingJobs.dates.length ?? 0) <= maxLimit;

	// Totals Board (AKA - Big Cards)
	const toteboardItems = [
		// Total Jobs
		{
			label: translate(messages.totalJobs),
			primaryValue: (
				<span>
					{formatNumber(
						BigCardPickingJobsCount.count +
							(isPackoutEnabled
								? BigCardPickedJobsCount.count +
								  BigCardReadyToPackJobsCount.count +
								  BigCardPackingJobsCount.count
								: 0) +
							BigCardJobsCompletedTodayCount.count,
					)}
				</span>
			),
		},
		// Picking
		{
			label: translate(messages.picking),
			primaryValue: <span>{formatNumber(BigCardPickingJobsCount.count)}</span>,
		},
		// Picked
		...(isPackoutEnabled || isSortationEnabled
			? [
					{
						label: translate(messages.picked),
						primaryValue: <span>{formatNumber(BigCardPickedJobsCount.count)}</span>,
					},
			  ]
			: []),
		// Ready-To-Sort, Sorting, Sorted

		// Prep-work for HRZ-1215
		// ...(isSortationEnabled
		// 	? [
		// 			{
		// 				label: translate(messages.readyToSort),
		// 				primaryValue: <span>{formatNumber(BigCardReadyToSortJobsCount.count)}</span>,
		// 			},
		// 			{
		// 				label: translate(messages.sorting),
		// 				primaryValue: <span>{formatNumber(BigCardSortingJobsCount.count)}</span>,
		// 			},
		// 			{
		// 				label: translate(messages.sorted),
		// 				primaryValue: <span>{formatNumber(BigCardSortedJobsCount.count)}</span>,
		// 			},
		// 	  ]
		// 	: []),

		// Ready-To-Pack & Packing
		...(isPackoutEnabled
			? [
					{
						label: translate(messages.readyToPack),
						primaryValue: <span>{formatNumber(BigCardReadyToPackJobsCount.count)}</span>,
					},
					{
						label: translate(messages.packing),
						primaryValue: <span>{formatNumber(BigCardPackingJobsCount.count)}</span>,
					},
			  ]
			: []),
		// Completed Today
		{
			label: translate(messages.completedToday),
			primaryValue: <span>{formatNumber(BigCardJobsCompletedTodayCount.count)}</span>,
		},
	];

	// Views (AKA - The Ribbon)
	const views = [
		// All
		{
			label: translatedOutboundJobViewNames[OutboundJobViews.All],
			id: OutboundJobViews.All,
		},
		// Exceptions (Today)
		{
			label: config?.healingEnabled
				? translatedOutboundJobViewNames[OutboundJobViews.Exceptions]
				: translate(messages.exceptionsToday),
			metaLabel: config?.healingEnabled
				? filteredExceptionsCount.count
				: filteredExceptionsTodayCount.count,
			id: OutboundJobViews.Exceptions,
		},
		// Interrupted
		{
			label: translatedOutboundJobViewNames[OutboundJobViews.Interrupted],
			metaLabel: filteredInterruptedCount.count,
			id: OutboundJobViews.Interrupted,
		},
		// Canceled (Today)
		{
			label: translatedOutboundJobViewNames[OutboundJobViews.Cancelled],
			metaLabel: filteredCancelledTodayCount.count,
			id: OutboundJobViews.Cancelled,
		},
	];

	// Filters
	const availablePickStrategies = usePickStrategies(config?.jobAllocationMethods || []);

	const filterableJobStatuses: (PickingJobStatus | 'Complete')[] = [
		// Picking
		PickingJobStatus.Picking,

		// Picked
		...(isPackoutEnabled || isSortationEnabled ? [PickingJobStatus.Picked] : []),

		// Prep-work for HRZ-1215
		// Ready-To-Sort, Sorting, Sorted
		// ...(isSortationEnabled
		// 	? [
		// 			PickingJobStatus.Sortable, // Ready-to-Sort
		// 			PickingJobStatus.Sorting,
		// 			PickingJobStatus.Sorted,
		// 	  ]
		// 	: []),

		// Ready-to-Pack & Packing
		...(isPackoutEnabled
			? [
					PickingJobStatus.Packable, // Ready-to-Pack
					PickingJobStatus.Packing,
			  ]
			: []),

		// Completed
		'Complete',
	];

	const labels: Record<PickingJobStatus | 'Complete', string> = {
		[PickingJobStatus.AwaitingInventory]: messages.awaitingInventory,
		[PickingJobStatus.Unassigned]: messages.unassigned,
		[PickingJobStatus.Picking]: messages.picking,
		[PickingJobStatus.Picked]: messages.picked,
		[PickingJobStatus.Packable]: messages.readyToPack,
		[PickingJobStatus.Packing]: messages.packing,
		[PickingJobStatus.Packed]: messages.packed,
		[PickingJobStatus.Sortable]: messages.readyToSort,
		[PickingJobStatus.Sorting]: messages.sorting,
		[PickingJobStatus.Sorted]: messages.sorted,
		[PickingJobStatus.Exception]: messages.exception,
		[PickingJobStatus.Cancelled]: messages.canceled,
		[PickingJobStatus.Offline]: messages.offline,
		[PickingJobStatus.Unknown]: messages.unknown,
		Complete: messages.completed,
	};

	const filters = [
		// Job Status
		{
			key: JOB_STATUSES_KEY,
			label: translate(messages.jobStatus) as string,
			filter: (
				<ChoiceList
					title={translate(messages.jobStatus)}
					titleHidden
					choices={filterableJobStatuses.map((status) => ({
						label: labels[status as PickingJobStatus],
						value: status,
					}))}
					selected={jobStatusesParam?.split(' ') || []}
					onChange={(selected) => {
						setFilters([
							{ key: DEFAULT_VIEW_KEY, value: OutboundJobViews.All },
							{ key: JOB_STATUSES_KEY, value: selected.join(' ') },
						]);
					}}
					allowMultiple
				/>
			),
			shortcut: true,
		},

		// Pick Strategy
		...(availablePickStrategies.length > 1
			? [
					{
						key: PICK_STRATEGY_KEY,
						label: translate(messages.pickStrategy) as string,
						filter: (
							<ChoiceList
								title={translate(messages.pickStrategy)}
								titleHidden
								choices={availablePickStrategies.map((strategy) => ({
									label: getPickStrategyName(strategy),
									value: strategy,
								}))}
								selected={selectedPickStrategies || []}
								onChange={(selected) => {
									setFilters([{ key: PICK_STRATEGY_KEY, value: selected.join(' ') }]);
								}}
								allowMultiple
							/>
						),
						shortcut: true,
					},
			  ]
			: []),

		// Cutoff
		...(showCutoffFilter
			? [
					{
						key: CUTOFF_KEY,
						label: messages.carrierCutoff,
						filter: (
							<CutoffFilter
								onChange={(selected) => setFilters([{ key: CUTOFF_KEY, value: selected }])}
								value={cutoffDate}
								cutoffDates={cutoffDatesData?.cutoffForPickingJobs}
							/>
						),
						shortcut: true,
					},
			  ]
			: []),

		// Work oder type
		...(config?.inventoryEnabled
			? [
					{
						key: WORK_ORDER_TYPE,
						label: messages.workOrderType,
						filter: (
							<ChoiceList
								title={messages.workOrderType}
								titleHidden
								choices={[
									{
										label: messages.workOrderTypes.transfer,
										value: WorkOrderType.InventoryTransferToNode,
									},
									{
										label: messages.workOrderTypes.ecommerce,
										value: WorkOrderType.Ecommerce,
									},
								]}
								selected={selectedWorkOrderTypes || []}
								onChange={(selected) => {
									setFilters([{ key: WORK_ORDER_TYPE, value: selected.join(' ') }]);
								}}
							/>
						),
						shortcut: true,
					},
			  ]
			: []),
	];

	const appliedFilters = [
		...(selectedPickStrategies
			? [
					{
						key: PICK_STRATEGY_KEY,
						label: selectedPickStrategies
							.map((orderType) => getPickStrategyName(orderType))
							.join(', '),
						onRemove: () => {
							setFilters([{ key: PICK_STRATEGY_KEY, value: '' }]);
						},
					},
			  ]
			: []),
		...(selectedJobStatuses
			? [
					{
						key: JOB_STATUSES_KEY,
						label: ((jobStatusesParam?.split(' ') as (PickingJobStatus | 'Complete')[]) || [])
							.map((status) => labels[status as PickingJobStatus])
							.join(', '),
						onRemove: () => {
							setFilters([{ key: JOB_STATUSES_KEY, value: '' }]);
						},
					},
			  ]
			: []),
		...(cutoffDate && cutoffDatesData?.cutoffForPickingJobs?.dates.includes(cutoffDate)
			? [
					{
						key: CUTOFF_KEY,
						label: formatDateTime(cutoffDate),
						onRemove: () => {
							setFilters([{ key: CUTOFF_KEY, value: '' }]);
						},
					},
			  ]
			: []),
		...(projectionIds && projectionIds.length
			? [
					{
						key: PROJECTION_IDS,
						label: projectionIds.join(', '),
						onRemove: () => {
							setFilters([{ key: PROJECTION_IDS, value: '' }]);
						},
					},
			  ]
			: []),
		...(selectedWorkAreas && selectedWorkAreas.length
			? [
					{
						key: WORKAREA_KEY,
						label: workAreas
							.filter((area) => selectedWorkAreas.includes(area.id))
							.map((area, i) => area.name || `Work area ${i + 1}`)
							.join(', '),
						onRemove: () => {
							setFilters([{ key: WORKAREA_KEY, value: '' }]);
						},
					},
			  ]
			: []),
		...(workOrderType
			? [
					{
						key: WORK_ORDER_TYPE,
						label:
							workOrderType === WorkOrderType.InventoryTransferToNode
								? messages.workOrderTypes.transfer
								: messages.workOrderTypes.ecommerce,
						onRemove: () => {
							setFilters([{ key: WORK_ORDER_TYPE, value: '' }]);
						},
					},
			  ]
			: []),
	];

	// Render
	return error ? (
		<Error graphQLError={error} />
	) : (
		<>
			<Page
				fullWidth
				title={translate(messages.outboundJobs) as string}
				primaryAction={
					config?.workAreasEnabled ? (
						<WorkAreaMenu
							workAreas={workAreas}
							selectedIds={selectedWorkAreas || []}
							onChange={(selected: string[]) => {
								setFilters([{ key: WORKAREA_KEY, value: selected.join(' ') }]);
							}}
						/>
					) : null
				}
			>
				<Layout>
					<Layout.Section>
						<Stack distribution="equalSpacing">
							<DateRangeSelect
								options={options}
								selectedOption={historyDateLimiter}
								title={messages.createdAt}
								onChange={handleDateRangeSelectionChanged}
							/>
							<AutoRefresh
								pollingEnabled={pollingEnabled}
								togglePolling={togglePolling}
								discriminatorData={pickingData}
							/>
						</Stack>
					</Layout.Section>

					<Layout.Section>
						<Toteboard items={toteboardItems} />
					</Layout.Section>

					<Layout.Section>
						<OutboundJobsTable
							data={pickingData?.pickingJobs}
							loading={fetching}
							paginationCursors={paginationCursors}
							setPaginationCursors={setPaginationCursors}
							views={views}
							filters={filters}
							selectedView={view}
							query={query}
							setFilters={setFilters}
							appliedFilters={appliedFilters}
							sortValue={sort}
							totalCount={filteredCountsData?.filteredTotalCount.count}
						/>
					</Layout.Section>
					<Layout.Section>
						<TimezoneFooter />
					</Layout.Section>
				</Layout>
			</Page>
			<JobHistoryLimiterConfirmModal
				isOpen={!!proposedHistoryLimitDate}
				numOfDaysToRetrieve={
					proposedHistoryLimitDate ? getDateDiffInDays(midnight, proposedHistoryLimitDate.date) : 0
				}
				onClose={handleDiscardJobHistoryLimitChange}
				onSubmit={handleChangeJobHistoryLimit}
			/>
		</>
	);
}
