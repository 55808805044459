import { Card, Layout, Page, Pagination, Stack } from '@shopify/polaris';
import { useDebouncedValue, usePrevious } from '@shopify/react-hooks';
import {
	Cutoff,
	DatePrecision,
	JobOrderBy,
	JobStatus,
	PickStrategy,
	QueryCutoffForJobsV2Args,
	QueryJobsV2Args,
	WorkOrderType,
} from '@sixriver/fulfillment-api-schema';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { JobsData, OUTBOUND_JOBS_QUERY } from './OutboundJobs.graphql';
import { OutboundJobsCounts, OUTBOUND_JOBS_COUNT_QUERY } from './OutboundJobsCounts.graphql';
import { CUTOFF_DATES_QUERY } from './OutboundJobsCutoffs.graphql';
import { OutboundJobsFilters } from './OutboundJobsFilters';
import { Alert } from './OutboundJobsFilters/AlertsFilter';
import { OutboundJobsTable } from './OutboundJobsTable';
import { OutboundJobsTab, OutboundJobsTabs } from './OutboundJobsTabs';
import { OutboundJobsToteboard } from './OutboundJobsToteboard';
import { AutoRefresh } from 'components/AutoRefresh';
import { DateRangeFilter } from 'components/DateRangeFilter';
import { OptionValues } from 'components/DateRangeSelect';
import { TimezoneFooter } from 'components/TimezoneFooter';
import { WorkAreaMenu } from 'components/WorkAreaMenu';
import { getPageSize } from 'helpers/page-size';
import { MIN_QUERY_LENGTH } from 'helpers/table';
import { getMidnight } from 'helpers/time';
import { useConfig } from 'hooks/useConfig';
import { useLocalization } from 'hooks/useLocalization';
import { usePickStrategies } from 'hooks/usePickStrategies';
import { usePolling } from 'hooks/usePolling';
import { usePollingQuery } from 'hooks/usePollingQuery';
import { useArrayQueryState, useDateQueryState, useQueryState } from 'hooks/useQueryState';
import { useWorkAreas } from 'hooks/useWorkArea';

const MIDNIGHT_TODAY = getMidnight();
const MIDNIGHT_TOMORROW = getMidnight(1);
const DEFAULT_DATE = getMidnight(-7);
const IDS_KEY = 'ids';
const SEARCH_TEXT_KEY = 'query';
const CUTOFF_DATE_KEY = 'cutoff';
const PICK_STRATEGY_KEY = 'pickStrategies';
const HISTORY_DATE_LIMITER_KEY = 'historyDateLimiter';
const PAGINATION_CURSORS_KEY = 'paginationCursors';
const ALERTS_KEY = 'alerts';
const STATUSES_KEY = 'statuses';
const WORK_ORDER_TYPES_KEY = 'workOrderTypes';
const WORK_AREA_IDS = 'workAreaIds';

export function OutboundJobs() {
	const { messages } = useLocalization();
	const { config } = useConfig();
	const { workAreas } = useWorkAreas();
	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();
	const [selectedTab, setSelectedTab] = useState<OutboundJobsTab>(OutboundJobsTab.All);
	const [paginationCursors, setPaginationCursors] = useArrayQueryState<string[]>(
		PAGINATION_CURSORS_KEY,
		[],
	);
	const [historyDateLimiter, setHistoryDateLimiter] = useDateQueryState<Date>(
		HISTORY_DATE_LIMITER_KEY,
		DEFAULT_DATE,
	);
	const [searchText, setSearchText] = useQueryState(SEARCH_TEXT_KEY, '');
	const [selectedStatuses, setSelectedStatuses] = useArrayQueryState<JobStatus[]>(STATUSES_KEY, []);
	const [selectedAlerts, setSelectedAlerts] = useArrayQueryState<Alert[]>(ALERTS_KEY, []);
	const [selectedSort, setSelectedSort] = useState<JobOrderBy[]>([]);
	const [selectedCutoffDate, setSelectedCutoffDate] = useDateQueryState<Date | undefined>(
		CUTOFF_DATE_KEY,
		undefined,
	);
	const [selectedPickStrategies, setselectedPickStrategies] = useArrayQueryState<PickStrategy[]>(
		PICK_STRATEGY_KEY,
		[],
	);
	const [selectedWorkOrderTypes, setselectedWorkOrderTypes] = useArrayQueryState<WorkOrderType[]>(
		WORK_ORDER_TYPES_KEY,
		[],
	);
	const [selectedWorkAreaIds, setSelectedWorkAreaIds] = useArrayQueryState<string[]>(
		WORK_AREA_IDS,
		[],
	);
	const [ids, setIds] = useArrayQueryState<string[]>(IDS_KEY, []);
	const availablePickStrategies = usePickStrategies(config?.jobAllocationMethods || []);
	const showPickStrategy = availablePickStrategies.length > 1;
	const showWorkOrderType = config?.inventoryEnabled;
	const showWorkArea = config?.workAreasEnabled;
	const debouncedSearchText = useDebouncedValue(searchText);

	/**
	 * Queries
	 */
	const queryParamsForJobsAndCounts = useMemo(
		() => ({
			ids,
			searchText: debouncedSearchText.length >= MIN_QUERY_LENGTH ? debouncedSearchText : undefined,
			statuses: selectedStatuses,
			isLate: (selectedAlerts.length > 0 && selectedAlerts.includes(Alert.Late)) || undefined,
			isShorted:
				(selectedAlerts.length > 0 && selectedAlerts.includes(Alert.Exception)) || undefined,
			expectedShipDateFrom: selectedCutoffDate ?? undefined,
			expectedShipDateTo: selectedCutoffDate
				? new Date(selectedCutoffDate.getTime() + 60 * 1000)
				: undefined,
			createdAtFrom: historyDateLimiter,
			pickStrategies: selectedPickStrategies.length > 0 ? selectedPickStrategies : undefined,
			workOrderTypes: selectedWorkOrderTypes.length > 0 ? selectedWorkOrderTypes : undefined,
		}),
		[
			debouncedSearchText,
			historyDateLimiter,
			ids,
			selectedAlerts,
			selectedCutoffDate,
			selectedPickStrategies,
			selectedStatuses,
			selectedWorkOrderTypes,
		],
	);
	const [{ data: countData }] = usePollingQuery<OutboundJobsCounts, QueryJobsV2Args>({
		query: OUTBOUND_JOBS_COUNT_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			...queryParamsForJobsAndCounts,
			completedAtFrom: MIDNIGHT_TODAY,
			completedAtTo: MIDNIGHT_TOMORROW,
		},
	});
	const totalCount = countData?.TotalCount.count ?? 0;
	const totalPages = Math.max(Math.ceil(totalCount / getPageSize()), 1);

	const [{ fetching: fetchingOutboundJobs, data: outboundJobsData }, refetchOrders] =
		usePollingQuery<JobsData, QueryJobsV2Args>({
			query: OUTBOUND_JOBS_QUERY,
			pollInterval: queryPollInterval,
			variables: {
				...queryParamsForJobsAndCounts,
				first: getPageSize(),
				after: paginationCursors[0],
				orderBy: selectedSort.length > 0 ? selectedSort : undefined,
			},
		});
	const ordersPageInfo = outboundJobsData?.jobsV2.pageInfo;
	const outboundJobs = outboundJobsData?.jobsV2.edges;

	const [{ data: cutoffDatesData }] = usePollingQuery<
		{ cutoffForJobsV2: Cutoff },
		QueryCutoffForJobsV2Args
	>({
		query: CUTOFF_DATES_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			datePrecision: DatePrecision.Minute,
			createdAtFrom: historyDateLimiter,
		},
	});
	const cutoffDates = cutoffDatesData?.cutoffForJobsV2.dates.map((date) => new Date(date));

	/**
	 * Filter functions
	 */
	const handleStatusesRemove = useCallback(() => {
		setSelectedStatuses([]);
		setSelectedTab(OutboundJobsTab.All);
	}, [setSelectedStatuses, setSelectedTab]);
	const handleAlertsRemove = useCallback(() => {
		setSelectedAlerts([]);
		setSelectedTab(OutboundJobsTab.All);
	}, [setSelectedAlerts, setSelectedTab]);
	const handleCutoffDateRemove = useCallback(() => {
		setSelectedCutoffDate(undefined);
	}, [setSelectedCutoffDate]);
	const handlePickStrategyRemove = useCallback(() => {
		setselectedPickStrategies([]);
	}, [setselectedPickStrategies]);
	const handleWorkOrderTypeRemove = useCallback(() => {
		setselectedWorkOrderTypes([]);
	}, [setselectedWorkOrderTypes]);
	const handleWorkAreaRemove = useCallback(() => {
		setSelectedWorkAreaIds([]);
	}, [setSelectedWorkAreaIds]);
	const handleIdsRemove = useCallback(() => {
		setIds([]);
	}, [setIds]);

	const handleClearAllFilters = useCallback(() => {
		setSelectedStatuses([]);
		setSelectedAlerts([]);
		// TODO should this clear cutoff date filter when switching tabs?
		// TODO should this clear pick strategy filter when switching tabs?
	}, [setSelectedAlerts, setSelectedStatuses]);

	const handleHistoryLimitChange = useCallback(
		(date: Date) => {
			setHistoryDateLimiter(date);
			// clearing cutoff filter because the list is dynamically determined by history limitor range
			handleCutoffDateRemove();
		},
		[handleCutoffDateRemove, setHistoryDateLimiter],
	);

	useEffect(() => {
		handleClearAllFilters();
		switch (selectedTab) {
			case OutboundJobsTab.Late:
				setSelectedAlerts([Alert.Late]);
				return;
			case OutboundJobsTab.Exceptions:
				setSelectedAlerts([Alert.Exception]);
				return;
			case OutboundJobsTab.Interrupted:
				setSelectedStatuses([JobStatus.Interrupted]);
				return;
		}
	}, [handleClearAllFilters, selectedTab, setSelectedAlerts, setSelectedStatuses]);

	/**
	 * Pagination
	 * */
	const handleOnNextPage = useCallback(() => {
		const endCursor = ordersPageInfo?.endCursor ?? undefined;
		if (endCursor) {
			setPaginationCursors((current) => [endCursor].concat(current));
			refetchOrders();
		}
	}, [ordersPageInfo?.endCursor, refetchOrders, setPaginationCursors]);

	const handleOnPreviousPage = useCallback(() => {
		setPaginationCursors((current) => current.slice(1));
		refetchOrders();
	}, [refetchOrders, setPaginationCursors]);

	// Previous filters
	const prevQueryParams = usePrevious(queryParamsForJobsAndCounts);
	useEffect(() => {
		// Reset pagination when changing views or filters
		if (!isEqual(prevQueryParams, queryParamsForJobsAndCounts)) {
			setPaginationCursors(() => []);
		}
	}, [prevQueryParams, queryParamsForJobsAndCounts, setPaginationCursors]);

	return (
		<>
			<Page
				fullWidth
				title={messages.outboundJobs}
				primaryAction={
					showWorkArea ? (
						<WorkAreaMenu
							workAreas={workAreas}
							selectedIds={selectedWorkAreaIds || []}
							onChange={(selected: string[]) => {
								setSelectedWorkAreaIds(selected);
							}}
						/>
					) : null
				}
			>
				<Layout>
					<Layout.Section>
						<Stack distribution="equalSpacing">
							<DateRangeFilter
								options={[
									OptionValues.today,
									OptionValues.last3Days,
									OptionValues.last7Days,
									OptionValues.last30Days,
									OptionValues.last90Days,
									OptionValues.last180Days,
								]}
								selectedOption={historyDateLimiter}
								title={messages.createdAt}
								onChange={handleHistoryLimitChange}
							/>

							<Stack distribution="trailing">
								<AutoRefresh
									pollingEnabled={pollingEnabled}
									togglePolling={togglePolling}
									discriminatorData={{}}
								/>
							</Stack>
						</Stack>
						<OutboundJobsToteboard
							pickingCount={countData?.StaticPickingCount.count}
							pickedCount={countData?.StaticPickedCount.count}
							readyToPackCount={countData?.StaticPackableCount.count}
							packingCount={countData?.StaticPackingCount.count}
							packedCount={countData?.StaticPackedCount.count}
							interruptedCount={countData?.StaticInterruptedCount.count}
							readyToSortCount={countData?.StaticSortableCount.count}
							sortingCount={countData?.StaticSortingCount.count}
							sortededCount={countData?.StaticSortedCount.count}
						/>
					</Layout.Section>

					<Layout.Section>
						<Card>
							<div style={{ paddingBottom: '2rem' }}>
								<OutboundJobsTabs
									tabs={[OutboundJobsTab.All, OutboundJobsTab.Late, OutboundJobsTab.Exceptions]}
									lateCount={countData?.LateCount.count ?? 0}
									exceptionsCount={countData?.ShortedCount.count ?? 0}
									interruptedCount={countData?.InterruptedCount.count ?? 0}
									selected={selectedTab}
									onSelect={setSelectedTab}
								/>
							</div>

							<div style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
								<OutboundJobsFilters
									queryValue={searchText}
									onClearAll={handleClearAllFilters}
									onQueryChange={setSearchText}
									selectedSort={selectedSort}
									onSortChange={setSelectedSort}
									selectedCutoffDate={selectedCutoffDate}
									cutoffDates={cutoffDates}
									onCutoffChange={setSelectedCutoffDate}
									onCutoffRemove={handleCutoffDateRemove}
									selectedStatuses={selectedStatuses}
									onStatusChange={setSelectedStatuses}
									onStatusRemove={handleStatusesRemove}
									selectedAlerts={selectedAlerts}
									onAlertChange={setSelectedAlerts}
									onAlertRemove={handleAlertsRemove}
									showPickStrategy={showPickStrategy}
									selectedPickStrategies={selectedPickStrategies}
									availablePickStrategies={availablePickStrategies}
									onPickStrategyChange={setselectedPickStrategies}
									onPickStrategyRemove={handlePickStrategyRemove}
									showWorkOrderType={showWorkOrderType}
									selectedWorkOrderTypes={selectedWorkOrderTypes}
									onWorkOrderTypeChange={setselectedWorkOrderTypes}
									onWorkOrderTypeRemove={handleWorkOrderTypeRemove}
									selectedWorkAreaIds={selectedWorkAreaIds}
									workAreas={workAreas}
									onWorkAreaRemove={handleWorkAreaRemove}
									ids={ids}
									onIdsRemove={handleIdsRemove}
								/>
							</div>

							<div style={{ padding: '1rem' }}>
								{`${totalCount} ${
									totalCount === 1 ? messages.result.toLowerCase() : messages.results.toLowerCase()
								}`}
							</div>

							<OutboundJobsTable
								loading={fetchingOutboundJobs}
								data={outboundJobs}
								pageInfo={ordersPageInfo}
								showPickStrategyColumn={showPickStrategy}
								showWorkOrderTypeColumn={showWorkOrderType}
								totalFilteredJobs={totalCount}
								onBulkCancel={() => {
									return Promise.resolve();
								}}
							/>
							<Card.Section>
								<Stack distribution="center">
									<Pagination
										label={`${paginationCursors.length + 1} ${messages.of} ${totalPages}`}
										hasNext={ordersPageInfo?.hasNextPage}
										hasPrevious={ordersPageInfo?.hasPreviousPage}
										onNext={handleOnNextPage}
										onPrevious={handleOnPreviousPage}
									/>
								</Stack>
							</Card.Section>
						</Card>
					</Layout.Section>

					<Layout.Section>
						<TimezoneFooter />
					</Layout.Section>
				</Layout>
			</Page>
		</>
	);
}
