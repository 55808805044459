import {
	Card,
	ContextualSaveBar,
	Form,
	FormLayout,
	Layout,
	Select,
	Stack,
	TextField,
} from '@shopify/polaris';
import {
	Container,
	StorageLocationInput,
	StorageLocationType,
} from '@sixriver/fulfillment-api-schema';
import { useQuery } from 'urql';

import { GET_CAPACITY_TYPES_QUERY } from './Locations.graphql';
import { Error } from 'components/Error';
import { FormFeedback } from 'components/FormFeedback';
import { FormProps, useForm } from 'hooks/useForm';
import { useLocalization } from 'hooks/useLocalization';

export function StorageLocationForm({
	data,
	onSubmit,
	error,
}: FormProps<StorageLocationInput>): JSX.Element {
	// Localization
	const { messages } = useLocalization();

	// Queries
	const [{ fetching: fetchingCapacityTypes, data: capacityTypesData, error: capacityTypesError }] =
		useQuery<{ containers: { results: Array<Container> } }>({
			query: GET_CAPACITY_TYPES_QUERY,
		});

	const { dirty, discardForm, editForm, feedback, input, validations } =
		useForm<StorageLocationInput>(data, error);

	const locationTypeOptions = [
		{
			label: messages.locationTypes.bin,
			value: StorageLocationType.Bin,
		},
		{
			label: messages.locationTypes.cart,
			value: StorageLocationType.Cart,
		},
		{
			label: messages.locationTypes.damaged,
			value: StorageLocationType.Damaged,
		},
		{
			label: messages.locationTypes.dock,
			value: StorageLocationType.Dock,
		},
		{
			label: messages.locationTypes.external,
			value: StorageLocationType.External,
		},
		{
			label: messages.locationTypes.pallet,
			value: StorageLocationType.Pallet,
		},
		{
			label: messages.locationTypes.rack,
			value: StorageLocationType.Rack,
		},
		{
			label: messages.locationTypes.reserve,
			value: StorageLocationType.Reserve,
		},
		{
			label: messages.locationTypes.slot,
			value: StorageLocationType.Slot,
		},
		{
			label: messages.locationTypes.staging,
			value: StorageLocationType.Staging,
		},
		{
			label: messages.locationTypes.warehouse,
			value: StorageLocationType.Warehouse,
		},
	];

	const capacityTypeOptions = (capacityTypesData?.containers?.results || [])
		.filter((container) => container.type === 'storage')
		.filter((container) => !!container.name && !!container.id)
		.map((container) => {
			return {
				label: container.name || '',
				value: container.id || '',
			};
		})
		.sort((a, b) => {
			return a.label > b.label ? 1 : -1;
		});

	// Methods

	// Render
	if (fetchingCapacityTypes) {
		return <></>;
	}

	if (capacityTypesError) {
		return <Error graphQLError={capacityTypesError} />;
	}

	return (
		<>
			{/* Error Banner */}
			<FormFeedback feedback={feedback} />

			{/* Form */}
			<Form onSubmit={() => onSubmit(input)}>
				<Layout.AnnotatedSection title={messages.locationDetails}>
					<Card sectioned>
						<Stack vertical>
							<FormLayout>
								<FormLayout.Group condensed>
									<TextField
										autoComplete="off"
										name="address"
										label={messages.address}
										error={validations.address}
										onChange={(address) => editForm({ address })}
										value={input.address}
										requiredIndicator
									/>

									<TextField
										autoComplete="off"
										name="externalAisleId"
										label={messages.aisleId}
										error={validations.externalAisleId}
										onChange={(externalAisleId) => editForm({ externalAisleId })}
										value={input.externalAisleId}
										requiredIndicator
									/>
								</FormLayout.Group>
							</FormLayout>
							<TextField
								autoComplete="off"
								name="description"
								label={messages.description}
								error={validations.description}
								onChange={(description) => editForm({ description })}
								value={input.description}
								requiredIndicator
							/>
							<Select
								name="type"
								label={messages.locationType}
								placeholder={messages.select}
								options={locationTypeOptions}
								error={validations.type}
								onChange={(type: StorageLocationType) => editForm({ type })}
								value={`${input.type}`}
								requiredIndicator
							/>
							<Select
								name="containerTypeId"
								label={messages.capacityType}
								placeholder={messages.select}
								options={capacityTypeOptions}
								error={validations.containerTypeId}
								onChange={(containerTypeId) => editForm({ containerTypeId })}
								value={input.containerTypeId || undefined}
							/>
						</Stack>
					</Card>
				</Layout.AnnotatedSection>

				<Layout.AnnotatedSection title={messages.coordinates}>
					<Card sectioned>
						<FormLayout>
							<FormLayout.Group condensed>
								<TextField
									autoComplete="off"
									name="x"
									label="X"
									type="number"
									inputMode="numeric"
									suffix="mm"
									error={validations.x}
									onChange={(x) => editForm({ x: parseInt(x) || 0 })}
									value={(input.x || 0).toString()}
									requiredIndicator
								/>
								<TextField
									autoComplete="off"
									name="y"
									label="Y"
									type="number"
									inputMode="numeric"
									suffix="mm"
									error={validations.y}
									onChange={(y) => editForm({ y: parseInt(y) || 0 })}
									value={(input.y || 0).toString()}
									requiredIndicator
								/>
								<TextField
									autoComplete="off"
									name="z"
									label="Z"
									type="number"
									inputMode="numeric"
									error={validations.z}
									onChange={(z) => editForm({ z: parseInt(z) || 0 })}
									value={(input.z || 0).toString()}
									requiredIndicator
								/>
							</FormLayout.Group>
						</FormLayout>
					</Card>
				</Layout.AnnotatedSection>
			</Form>

			{/* Save Bar */}
			{dirty ? (
				<ContextualSaveBar
					fullWidth={false}
					alignContentFlush={false}
					message={messages.unsavedChanges}
					saveAction={{
						content: messages.save,
						onAction: () => onSubmit(input),
					}}
					discardAction={{
						content: messages.discard,
						onAction: () => {
							discardForm();
						},
					}}
				/>
			) : null}
		</>
	);
}
