import { EmptySearchResult, IndexTable, IndexTableProps } from '@shopify/polaris';
import { LaneContainer } from '@sixriver/fulfillment-api-schema';

import { ContainerIdCell } from './ContainerIdCell';
import { ExceptionCell } from './ExceptionCell';
import { PackageQtyCell } from './PackageQtyCell';
import { StatusCell } from './StatusCell';
import { VehicleIdCell } from './VehicleIdCell';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	loading: boolean;
	data?: LaneContainer[];
}

export function LaneContainersTable({ loading, data = [] }: Props) {
	const { messages } = useLocalization();

	const resourceName = {
		singular: messages.container,
		plural: messages.containers,
	};
	const emptyStateMarkup = (
		<EmptySearchResult title={messages.noContainersFound} withIllustration />
	);
	const headings: IndexTableProps['headings'] = [
		{ title: messages.containerId },
		{ title: messages.packageQty },
		{ title: messages.status },
		{ title: messages.exceptions },
		{ title: messages.vehicleId },
	] as IndexTableProps['headings'];

	const rows = data.map((container, index) => {
		return (
			<IndexTable.Row id={container.id} key={container.id} position={index}>
				<ContainerIdCell container={container} />
				<PackageQtyCell container={container} />
				<StatusCell container={container} />
				<ExceptionCell container={container} />
				<VehicleIdCell container={container} />
			</IndexTable.Row>
		);
	});

	return (
		<IndexTable
			emptyState={emptyStateMarkup}
			resourceName={resourceName}
			headings={headings}
			hasMoreItems={false}
			itemCount={data.length}
			loading={loading}
			selectable={false}
		>
			{rows}
		</IndexTable>
	);
}
