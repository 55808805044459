import { Page, Layout, Stack, Badge, Modal, TextContainer, Banner } from '@shopify/polaris';
import { DuplicateMinor } from '@shopify/polaris-icons';
import {
	MutationCancelPickingJobArgs,
	QueryReplenJobByProjectionIdArgs,
	ReplenJob,
	ReplenJobState,
} from '@sixriver/fulfillment-api-schema';
import { useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useMutation } from 'urql';

import { REPLENISHMENT_QUERY, CANCEL_JOB_MUTATION } from './ReplenishJob.graphql';
import { ReplenishJobDetails } from './ReplenishJobDetails';
import { ReplenishJobLinesTable } from './ReplenishJobLinesTable';
import { ReplenishJobProgress } from './ReplenishJobProgress';
import { AutoRefresh } from 'components/AutoRefresh';
import { Error } from 'components/Error';
import { ErrorBanner } from 'components/ErrorBanner';
import { ReplenJobStatusBadge } from 'components/ReplenJobStatusBadge';
import { TimezoneFooter } from 'components/TimezoneFooter';
import { countTaskExceptions } from 'helpers/exception';
import { useAuth } from 'hooks/useAuth';
import { useConfig } from 'hooks/useConfig';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import { useFilters } from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';
import { usePolling } from 'hooks/usePolling';
import { usePollingQuery } from 'hooks/usePollingQuery';
import { useToast } from 'hooks/useToast';
import { ReplenishJobsViews } from 'pages/ReplenishJobs/ReplenishJobsViews';
import { UserRole } from 'providers/AuthProvider';
import * as routes from 'routes';

export function ReplenishJob() {
	const { messages, translate } = useLocalization();

	const { isUserAllowed } = useAuth();

	const [isReturnItemsBannerVisible, setIsReturnItemsBannerVisible] = useState(false);

	const { view = ReplenishJobsViews.All, query } = useFilters();

	const {
		params: { replenishmentJobId },
	} = useRouteMatch<{ replenishmentJobId: string }>();

	const history = useHistory();

	const { showToast } = useToast();

	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();

	const projectionId = replenishmentJobId;

	const [{ fetching: jobFetching, error: jobError, data: jobData }] = usePollingQuery<
		{ replenJobByProjectionId: ReplenJob },
		QueryReplenJobByProjectionIdArgs
	>({
		query: REPLENISHMENT_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			projectionId,
		},
	});

	const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

	const closeCancelModal = () => {
		setIsCancelModalOpen(false);
	};

	const openCancelModal = () => {
		setIsCancelModalOpen(true);
	};

	const [{ fetching: cancelLoading }, cancelMutation] = useMutation<
		{ cancelPickingJob: Response },
		MutationCancelPickingJobArgs
	>(CANCEL_JOB_MUTATION);

	const { config } = useConfig();

	const job = jobData?.replenJobByProjectionId;

	const tasks = job?.tasks || [];

	const searchTextFilteredTasks = query
		? tasks.filter((t) => {
				return (
					// Product customerIdentifier
					t.product.customerIdentifier.toLowerCase().includes(query.toLowerCase()) ||
					// Product description
					t.product.description.toLowerCase().includes(query.toLowerCase()) ||
					// Product ID
					t.product.id.toLowerCase().includes(query.toLowerCase()) ||
					// Destination location address
					t.destination.address.toLowerCase().includes(query.toLowerCase()) ||
					// Associate ID
					t.user?.id?.toString().includes(query.toLowerCase()) ||
					// Product name
					t.product.name.toLowerCase().includes(query.toLowerCase())
				);
		  })
		: tasks;

	const searchTextFilteredExceptionTasks = searchTextFilteredTasks.filter((t) => {
		return t.exceptions.some((ex) => !!ex.status);
	});

	const filteredTasks =
		view === ReplenishJobsViews.Exceptions
			? searchTextFilteredExceptionTasks
			: searchTextFilteredTasks;

	const numberOfExceptions = countTaskExceptions(job?.tasks || []);

	const { copyToClipboard } = useCopyToClipboard();

	const [isBannerErrorVisible, setIsBannerErrorVisible] = useState(false);

	const healingEnabled = config?.healingEnabled;

	const jobSourceLocation = job?.tasks?.[0].source?.address;

	const views = [
		{
			label: messages.all,
			metaLabel: searchTextFilteredTasks.length,
			id: ReplenishJobsViews.All,
		},
		{
			label: messages.exceptions,
			metaLabel: searchTextFilteredExceptionTasks.length,
			id: ReplenishJobsViews.Exceptions,
		},
	];

	const cancelJob = async () => {
		setIsBannerErrorVisible(false);

		try {
			const { error } = await cancelMutation({ projectionId: replenishmentJobId });

			closeCancelModal();

			if (error) {
				throw error;
			}

			if (!isJobCancelDisabled) {
				showToast(messages.jobCanceled);
			}

			if (jobSourceLocation) {
				setIsReturnItemsBannerVisible(true);
			}

			if (!jobSourceLocation) {
				history.push(routes.replenishJobs());
			}
		} catch (e) {
			setIsBannerErrorVisible(true);
		}
	};

	if (jobError) {
		return <Error graphQLError={jobError} />;
	}

	if (jobFetching) {
		return null;
	}

	const isJobCancelDisabled =
		(job?.status as ReplenJobState) === ReplenJobState.Completed ||
		(job?.status as ReplenJobState) === ReplenJobState.Cancelled ||
		(job?.status as ReplenJobState) === ReplenJobState.Inducted ||
		(job?.status as ReplenJobState) === ReplenJobState.InProgress;

	return (
		<Page
			title={job?.containerId}
			titleMetadata={
				<Stack spacing="extraTight">
					<ReplenJobStatusBadge replenStatus={job?.status} isEmptyIfNoData />
					{numberOfExceptions ? <Badge status="critical">{messages.exception}</Badge> : null}
				</Stack>
			}
			secondaryActions={[
				{
					content: messages.copyLicensePlate,
					icon: DuplicateMinor,
					onAction: job?.containerId ? () => copyToClipboard(job?.containerId) : undefined,
					disabled: !job?.containerId,
				},
				{
					content: messages.cancelJob,
					onAction: openCancelModal,
					disabled:
						!isUserAllowed([UserRole.Admin, UserRole.WarehouseManager]) || isJobCancelDisabled,
				},
			]}
		>
			<AutoRefresh
				pollingEnabled={pollingEnabled}
				togglePolling={togglePolling}
				discriminatorData={jobData}
			/>

			<Layout>
				<Layout.Section>
					<ErrorBanner
						isVisible={isBannerErrorVisible}
						onDismiss={() => {
							setIsBannerErrorVisible(false);
						}}
					/>
				</Layout.Section>

				{isReturnItemsBannerVisible === true ? (
					<Layout.Section>
						<Banner
							status="warning"
							title={
								translate(messages.returnItemsToLocation, {
									location: jobSourceLocation,
								}) as string
							}
							action={{
								content: messages.markAsComplete,
								onAction: () => {
									setIsReturnItemsBannerVisible(false);
								},
							}}
						/>
					</Layout.Section>
				) : null}

				<Layout.Section>
					<ReplenishJobDetails loading={jobFetching} job={job} />
				</Layout.Section>
				<Layout.Section>
					<ReplenishJobProgress loading={jobFetching} job={job} isHealingEnabled={healingEnabled} />
				</Layout.Section>
				<Layout.Section>
					{!jobFetching ? (
						<ReplenishJobLinesTable views={views} query={query} tasksData={filteredTasks} />
					) : null}
				</Layout.Section>

				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
			<Modal
				open={isCancelModalOpen}
				onClose={closeCancelModal}
				title={jobSourceLocation ? messages.cancelReplenJob : messages.cancelJob}
				primaryAction={{
					content: messages.cancelJob,
					onAction: cancelJob,
					destructive: true,
					loading: cancelLoading,
				}}
				secondaryActions={[
					{
						content: messages.keepJob,
						onAction: closeCancelModal,
					},
				]}
			>
				<Modal.Section>
					<TextContainer>
						<p>
							{jobSourceLocation
								? (translate(messages.confirmCancelReplenFromLocation, {
										location: jobSourceLocation,
								  }) as string)
								: messages.confirmCancelReplen}
						</p>
					</TextContainer>
				</Modal.Section>
			</Modal>
		</Page>
	);
}
