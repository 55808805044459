import { Badge } from '@shopify/polaris';
import { DeviceState } from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	state: DeviceState;
}

export function DeviceBadge({ state }: Props): JSX.Element {
	const { messages } = useLocalization();

	switch (state) {
		case DeviceState.InUse:
			return <Badge status="success">{messages.inUse}</Badge>;
		case DeviceState.Idle:
			return <Badge status="success">{messages.notInUse}</Badge>;
		case DeviceState.Off:
			return <Badge>{messages.disconnected}</Badge>;
		default:
			return <NoData />;
	}
}
