import { ViewTabs } from 'components/ViewTabs';
import { noop } from 'helpers/noop';
import { useLocalization } from 'hooks/useLocalization';

export enum LaneContainerOrdersTab {
	All = 'all',
	Exceptions = 'exceptions',
}

export interface LaneContainerOrdersTabsProps {
	totalCount?: number;
	exceptionsCount?: number;
	selected: string;
	onSelect?: (tab: LaneContainerOrdersTab) => void;
}

export function LaneContainerOrdersTabs({
	totalCount,
	selected,
	exceptionsCount,
	onSelect = noop,
}: LaneContainerOrdersTabsProps) {
	const { messages } = useLocalization();
	const tabs = [
		{
			label: messages.all,
			metaLabel: totalCount,
			id: LaneContainerOrdersTab.All,
		},
		{
			label: messages.exceptions,
			metaLabel: exceptionsCount,
			id: LaneContainerOrdersTab.Exceptions,
		},
	];

	return <ViewTabs tabs={tabs} selected={selected} onSelect={onSelect} />;
}
