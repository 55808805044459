import { Link } from '@shopify/polaris';
import {
	LineConnection,
	LinesViews,
	QueryLinesByPickingProjectionIdArgs,
} from '@sixriver/fulfillment-api-schema';
import { useState } from 'react';

import { LINES_QUERY } from './LinesTable.graphql';
import { DataTable, Column } from 'components/DataTable';
import { NoData } from 'components/NoData';
import { getPageSize } from 'helpers/page-size';
import { useLocalization } from 'hooks/useLocalization';
import { usePolling } from 'hooks/usePolling';
import { usePollingQuery } from 'hooks/usePollingQuery';
import * as routes from 'routes';

interface Props {
	jobId: string;
	isInventoryEnabled?: boolean;
}

export function PackingLinesTable({ jobId, isInventoryEnabled }: Props) {
	const { messages, translate } = useLocalization();
	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);

	const { queryPollInterval } = usePolling();

	const [{ fetching: linesFetching, data: linesData }] = usePollingQuery<
		{ linesByPickingProjectionId: LineConnection },
		QueryLinesByPickingProjectionIdArgs
	>({
		query: LINES_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			projectionId: jobId,
			first: getPageSize(),
			after: paginationCursors[0],
			view: LinesViews.All,
		},
	});

	const { pageInfo, edges = [] } = linesData?.linesByPickingProjectionId || {};

	const columns: Column[] = [
		{
			type: 'text',
			heading: messages.item,
		},
		{
			type: 'text',
			heading: isInventoryEnabled ? messages.sku : messages.name,
		},
		{
			type: 'text',
			heading: messages.units,
		},
	];

	const rows = (edges || []).map(({ node: line }) => {
		return [
			line.product?.id ? (
				<Link url={routes.product(line.product.id)} key="product-id" removeUnderline>
					{line.product.customerIdentifier}
				</Link>
			) : (
				<NoData />
			),
			line.product?.description ? <p key="product-name">{line.product.description}</p> : <NoData />,
			<div key="units">
				{translate(messages.countUnits, {
					count: line.quantity,
				})}
			</div>,
		];
	});

	return (
		<DataTable
			title={messages.lines}
			loading={linesFetching}
			columns={columns}
			rows={rows}
			emptyStateHeading={messages.linesNotFound}
			pageInfo={pageInfo}
			paginationCursors={paginationCursors}
			setPaginationCursors={setPaginationCursors}
			hideFilters
		/>
	);
}
