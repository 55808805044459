import { Page } from '@shopify/polaris';
import { MutationResponse, MutationUpdateUserArgs, User } from '@sixriver/fulfillment-api-schema';
import { useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'urql';

import { EmployeeForm, EmployeeFormData } from './Employee.form';
import { getEmployeeFormDataFromExistingEmployee, getRolesFromFormData } from './Employee.utils';
import { EMPLOYEE_BY_ID_QUERY, UPDATE_EMPLOYEE_MUTATION } from './Employees.graphql';
import { Error } from 'components/Error';
import { useLocalization } from 'hooks/useLocalization';
import { useToast } from 'hooks/useToast';
import * as routes from 'routes';

interface Params {
	employeeId: string;
}

export function EmployeeEdit() {
	const { messages } = useLocalization();
	const { showToast } = useToast();

	// Routing
	const params = useParams<Params>();
	const employeeId = params?.employeeId;
	const history = useHistory();

	// Queries
	const [{ fetching: fetchingUser, error: userError, data: userData }] = useQuery<
		{ user: User },
		{ id: string }
	>({
		query: EMPLOYEE_BY_ID_QUERY,
		variables: {
			id: employeeId,
		},
	});

	const employee = userData?.user;

	const formDefaults: EmployeeFormData = useMemo(
		() => getEmployeeFormDataFromExistingEmployee(employee),
		[employee],
	);

	// Mutations
	const [{ error: updateEmployeeError }, updateEmployeeMutation] = useMutation<
		{ updateUser: MutationResponse },
		MutationUpdateUserArgs
	>(UPDATE_EMPLOYEE_MUTATION);

	// Methods
	const onSubmit = useCallback(
		async (formData: EmployeeFormData) => {
			const { data } = await updateEmployeeMutation({
				input: {
					id: employeeId,
					roles: getRolesFromFormData(formData).map((name) => ({ name })),
					email: formData.email || null,
					locale: formData.locale || null,
					badgeBarcode: formData.badgeBarcode || null,
					name: formData.name || null,
					tags: formData.tags.map((tag) => ({ name: tag })),
				},
			});

			// Success
			if (data?.updateUser?.success && data.updateUser?.referenceId) {
				history.push(routes.employee(data.updateUser.referenceId));
				return;
			}

			showToast(messages.errorToast, true);
		},
		[updateEmployeeMutation, employeeId, showToast, history, messages],
	);

	// Guards
	const loading = fetchingUser;
	const error = userError;

	// Render
	if (loading) {
		return null;
	}

	if (error) {
		return <Error graphQLError={error} />;
	}

	return (
		<Page
			title={formDefaults.name || messages.editEmployee}
			breadcrumbs={[
				{
					content: messages.employee,
					url: routes.employee(params?.employeeId),
				},
			]}
			fullWidth
		>
			<EmployeeForm
				mode="edit"
				data={formDefaults}
				onSubmit={onSubmit}
				error={updateEmployeeError}
			/>
		</Page>
	);
}
