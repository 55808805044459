import gql from 'graphql-tag';

export const CANCEL_JOB_MUTATION = gql`
	mutation CancelJob($projectionId: String!) {
		cancelSortWall(projectionId: $projectionId) {
			success
		}
	}
`;

export const REPRINT_LABELS_MUTATION = gql`
	mutation ReprintLabels($projectionId: String!) {
		printSortationLabels(projectionId: $projectionId) {
			success
		}
	}
`;

export const SORT_WALL_QUERY = gql`
	query GetSortWall($id: String!) {
		sortationByJobId(id: $id) {
			id
			status
			wall
			batchId
			kiosk
			isCancelable
			expectedShipDate
			takeoffDestination
			timeline {
				createdAt
				updatedAt
				completedAt
			}
			lines {
				id
				status
				quantity
				doneQuantity
				source {
					id
					address
				}
				product {
					id
					customerIdentifier
					description
				}
				timeline {
					completedAt
				}
			}
			pickingJobs {
				id
				jobId
				pickingStatus
				exception
				lines {
					id
					status
					quantity
					doneQuantity
					timeline {
						completedAt
					}
				}
				timeline {
					createdAt
				}
				container {
					barcode
				}
				mfp {
					name
				}
				packout {
					packStation {
						name
					}
				}
			}
			customerOrders {
				id
				jobId
				type
				status
				customerIdentifier
				slot
				customers {
					id
					name
				}
				expectedShipDate
				timeline {
					createdAt
				}
				lines {
					id
					status
					quantity
					doneQuantity
					timeline {
						completedAt
					}
				}
				exception {
					status
				}
			}
		}
	}
`;
