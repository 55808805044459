import { Job, OrderV2 } from '@sixriver/fulfillment-api-schema';
import { createContext, PropsWithChildren } from 'react';

export const LinesContext = createContext<
	| {
			job: Job | OrderV2;
	  }
	| undefined
>(undefined);

interface LinesProviderProps {
	job: Job | OrderV2;
}

export function LinesProvider({
	children,
	job,
}: PropsWithChildren<LinesProviderProps>): JSX.Element {
	return <LinesContext.Provider value={{ job }}>{children}</LinesContext.Provider>;
}
