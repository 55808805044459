import { Link, TextStyle, Tooltip } from '@shopify/polaris';
import { PickingJobExceptionStatus, Order, PickingEdge } from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';
import { countLineExceptions, isExceptionActionable } from 'helpers/exception';
import { useConfig } from 'hooks/useConfig';
import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';

const getParams = (orders?: Order[]) => {
	const searchParams = new URLSearchParams();

	if (orders) {
		searchParams.set('projectionIds', orders.map((order) => order.id).join(' '));
	}

	return `?${searchParams.toString()}`;
};

interface ExceptionsProps {
	pickingJob: PickingEdge;
}

export function OutboundJobsTableExceptions({ pickingJob }: ExceptionsProps) {
	const { messages, translate } = useLocalization();
	const { config } = useConfig();
	const { lines, exception, servicing } = pickingJob.node;
	const numberOfExceptions = countLineExceptions(lines);
	const exceptionOrders = (servicing || []).filter(
		(order) => order.__typename === 'Order' && countLineExceptions(order.lines) > 0,
	) as Order[];

	if (exception === PickingJobExceptionStatus.Cleared) {
		return (
			<Tooltip content={messages.jobExceptionsCleared}>
				<TextStyle variation="positive">{messages.cleared}</TextStyle>
			</Tooltip>
		);
	}

	if (
		numberOfExceptions > 0 &&
		isExceptionActionable(config?.packoutEnabled, config?.healingEnabled, pickingJob.node)
	) {
		if (config?.healingEnabled) {
			return (
				<Tooltip content={messages.jobExceptionsReported}>
					<TextStyle variation="negative">
						<Link
							url={
								exceptionOrders.length === 1
									? routes.exception(exceptionOrders[0].id)
									: `${routes.exceptions()}${getParams(exceptionOrders)}`
							}
							monochrome
						>
							{translate(messages.countExceptions, {
								count: numberOfExceptions,
							})}
						</Link>
					</TextStyle>
				</Tooltip>
			);
		}

		return (
			<TextStyle variation="negative">
				{translate(messages.countExceptions, {
					count: numberOfExceptions,
				})}
			</TextStyle>
		);
	}

	return <NoData />;
}
