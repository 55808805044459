import { IndexTable, Link, TextStyle } from '@shopify/polaris';

import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';

interface LicensePlateCellProps {
	jobId: string;
	licensePlate?: string | null;
	destinationNode: string;
}

export function LicensePlateCell({ jobId, licensePlate, destinationNode }: LicensePlateCellProps) {
	const { messages } = useLocalization();

	return (
		<IndexTable.Cell>
			<Link url={routes.outboundJob(jobId)} removeUnderline>
				{licensePlate ?? messages.unknown}
			</Link>
			{destinationNode ? (
				<div>
					<TextStyle variation="subdued">{`${messages.destination} ${destinationNode}`}</TextStyle>
				</div>
			) : null}
		</IndexTable.Cell>
	);
}
