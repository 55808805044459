import { IndexTable } from '@shopify/polaris';

import { DateTime } from 'components/DateTime';
import { RelativeDateTime } from 'components/RelativeDateTime';

export function CarrierCutoffCell({ expectedShipDate }: { expectedShipDate: Date }) {
	return (
		<IndexTable.Cell>
			<DateTime date={expectedShipDate} />
			<RelativeDateTime date={expectedShipDate} mode="deadline" />
		</IndexTable.Cell>
	);
}
