import { Card, Button } from '@shopify/polaris';
import {
	Job,
	JobStatus,
	PickStrategy,
	Sortation,
	WorkArea,
} from '@sixriver/fulfillment-api-schema';

import styles from './PickingDetails.module.css';
import { RelatedJobsButton } from './RelatedJobs';
import { CardDetails } from 'components/CardDetails';
import { CopyButton } from 'components/CopyButton';
import { DateTime } from 'components/DateTime';
import { NoData } from 'components/NoData';
import { RelativeDateTime } from 'components/RelativeDateTime';
import { getMfpUrl } from 'helpers/mfp';
import { useLocalization } from 'hooks/useLocalization';
import { usePickStrategyName } from 'hooks/usePickStrategies';
import { useSortationJobsCount } from 'hooks/useSortationJobsCount';

interface Props {
	loading?: boolean;
	job?: Job;
	relatedJobs?: Job[];
	isPackoutEnabled?: boolean;
	isConsolidationEnabled?: boolean;
	isWorkAreaEnabled?: boolean;
	condensed?: boolean;
	isSortationEnabled?: boolean;
	sortWall?: Sortation;
}

export function PickingDetails({
	loading,
	job,
	relatedJobs,
	isPackoutEnabled,
	isWorkAreaEnabled,
	condensed,
	sortWall,
}: Props) {
	const { messages } = useLocalization();

	const getPickStrategyName = usePickStrategyName();

	const BATCH_JOB_PICK_STRATEGIES = [PickStrategy.Consolidation, PickStrategy.Group];
	const isBatchJob = job?.pickStrategy && BATCH_JOB_PICK_STRATEGIES.includes(job?.pickStrategy);
	const isSortJob = job?.pickStrategy === PickStrategy.Sortation;

	const jobsCount = useSortationJobsCount(sortWall);

	const pickedAt = job?.tasks.find((task) => task.type === 'batchPick')?.completedAt ?? null;
	const tasks = job?.tasks ? [...job.tasks] : [];
	const takeoffDestination = tasks[tasks.length - 1]?.takeoffDestination;
	const container = job?.tasks.find((task) => !!task.container)?.container;
	const uniqueWorkAreas = tasks.reduce((workAreas, task) => {
		if (task.workArea) {
			workAreas.add(task.workArea);
		}
		return workAreas;
	}, new Set<WorkArea>());

	if (condensed) {
		return (
			<Card title={messages.pickingDetails} sectioned>
				<CardDetails
					loading={loading}
					primary={[
						{
							label: messages.lpn,
							content: job?.externalContainerId ? (
								<CopyButton text={job?.externalContainerId} />
							) : null,
						},
						...(job?.stagingIdentifier
							? [{ label: messages.stagingId, content: job.stagingIdentifier }]
							: []),
						{
							label: messages.pickStrategy,
							content: job?.pickStrategy ? getPickStrategyName(job.pickStrategy) : null,
						},
					]}
					secondary={[
						{
							label: messages.chuck,
							content: job?.mfp?.name,
						},
						{
							label: messages.container,
							content: container?.name,
						},
						{
							label: messages.takeoff,
							content: takeoffDestination,
						},
					]}
				/>
			</Card>
		);
	}

	return (
		<Card title={messages.pickingDetails}>
			<Card.Section>
				<CardDetails
					loading={loading}
					primary={[
						{
							label: messages.pickStrategy,
							content: job?.pickStrategy ? getPickStrategyName(job.pickStrategy) : null,
						},
						...(job?.stagingIdentifier
							? [
									{
										label: messages.stagingId,
										content: job?.stagingIdentifier,
									},
							  ]
							: []),
						...(isBatchJob
							? [
									{
										label: messages.totalJobs,
										content: (relatedJobs || []).length,
									},
							  ]
							: []),
						...(isSortJob
							? [
									{
										label: messages.totalJobs,
										content: jobsCount || <NoData />,
									},
							  ]
							: []),
						...(isPackoutEnabled
							? [
									{
										label: messages.packoutRequired,
										content: job?.isPackoutRequired ? messages.yes : messages.no,
									},
							  ]
							: []),
						...(isWorkAreaEnabled
							? [
									{
										label: messages.workArea,
										content: Array.from(uniqueWorkAreas).join(', '),
									},
							  ]
							: []),
						...(isSortJob
							? [
									{
										label: messages.sortationRequired,
										content: messages.yes,
									},
							  ]
							: []),
					]}
					secondary={[
						{
							label: messages.createdAt,
							content: (
								<>
									<DateTime date={job?.createdAt} />
									<RelativeDateTime date={job?.createdAt} />
								</>
							),
						},
						{
							label: messages.updatedAt,
							content: <DateTime date={job?.updatedAt} />,
						},
						{
							label: messages.carrierCutoff,
							content: (
								<>
									<DateTime date={job?.expectedShipDate} />
									<RelativeDateTime
										date={job?.expectedShipDate}
										mode={
											[JobStatus.Packed, JobStatus.Picked, JobStatus.Sorted].includes(
												job?.status as JobStatus,
											)
												? undefined
												: 'deadline'
										}
									/>
								</>
							),
						},
						...(isSortJob
							? [
									{
										label: messages.pickingCompletedAt,
										content: <DateTime date={pickedAt} />,
									},
							  ]
							: [
									{
										label: messages.completedAt,
										content: <DateTime date={job?.completedAt} />,
									},
							  ]),
					]}
				/>
				<div className={styles.button}>
					<RelatedJobsButton relatedJobs={relatedJobs || []} label={messages.viewRelatedJobs} />
				</div>
			</Card.Section>
			<Card.Section>
				<CardDetails
					loading={loading}
					primary={[
						{
							label: messages.chuck,
							content: job?.mfp?.name,
						},
						{
							label: messages.takeoff,
							content: takeoffDestination,
						},
					]}
					secondary={[
						{
							label: messages.container,
							content: container?.name,
						},
					]}
				/>
				<div className={styles.button}>
					<Button plain url={getMfpUrl(job?.mfp?.name)} disabled={!job?.mfp?.name}>
						{messages.findThisChuck}
					</Button>
				</div>
			</Card.Section>
			<Card.Section>
				<CardDetails
					loading={loading}
					primary={[
						{
							label: messages.uniqueJobId,
							content: job?.id ? <CopyButton text={job.id} /> : null,
						},
					]}
				/>
			</Card.Section>
		</Card>
	);
}
