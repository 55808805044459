import { Card, Stack, TextContainer, TextStyle } from '@shopify/polaris';
import { ReworkJob } from '@sixriver/fulfillment-api-schema';

import { DateTime } from './DateTime';
import styles from './Rework.module.css';
import { NoData } from 'components/NoData';
import { useLocalization } from 'hooks/useLocalization';

export function Progress({ reworkJob }: { reworkJob?: ReworkJob }) {
	const { messages } = useLocalization();

	return (
		<Card title={messages.jobProgress}>
			<Card.Section>
				<Stack vertical>
					<Stack.Item>
						<Stack>
							<div className={styles.detailsLabels}>
								<TextContainer>
									<TextStyle>{messages.workStarted}</TextStyle>
								</TextContainer>
							</div>
							<div>
								{reworkJob?.workStartedAt ? (
									<DateTime date={reworkJob?.workStartedAt} />
								) : (
									<NoData />
								)}
							</div>
						</Stack>
					</Stack.Item>
					<Stack.Item>
						<Stack>
							<div className={styles.detailsLabels}>
								<TextContainer>
									<TextStyle>{messages.workCompleted}</TextStyle>
								</TextContainer>
							</div>
							<div>
								{reworkJob?.workCompletedAt ? (
									<DateTime date={reworkJob?.workCompletedAt} />
								) : (
									<NoData />
								)}
							</div>
						</Stack>
					</Stack.Item>
				</Stack>
			</Card.Section>
		</Card>
	);
}
