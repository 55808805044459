import { GoalStates } from '@sixriver/fulfillment-api-schema';

export enum ReworkJobsViews {
	All = 'All',
	Pending = 'Pending',
	InProgress = 'In progress',
	Closed = 'Closed',
}

export const ReworkJobsViewsViewStateMap: {
	[key in ReworkJobsViews]: GoalStates[];
} = {
	[ReworkJobsViews.All]: [],
	[ReworkJobsViews.Pending]: [GoalStates.Creating, GoalStates.Created, GoalStates.Ready],
	[ReworkJobsViews.InProgress]: [
		GoalStates.Running,
		GoalStates.Paused,
		GoalStates.TerminateRequested,
		GoalStates.Terminating,
		GoalStates.CancelRequested,
		GoalStates.Cancelling,
	],
	[ReworkJobsViews.Closed]: [GoalStates.Terminated, GoalStates.Complete, GoalStates.Cancelled],
};
