import { AppliedFilterInterface, Card, Layout, Page } from '@shopify/polaris';
import { useDebouncedValue } from '@shopify/react-hooks';
import {
	Count,
	OrderByDirection,
	QueryReturnsSummariesArgs,
	ReturnsSummaryPage,
	ReturnsSummaryOrderByField,
} from '@sixriver/fulfillment-api-schema';
import { useState } from 'react';
import { useQuery } from 'urql';

import { InboundReturnViews, InboundReturnViewStateMap } from './InboundReturnViews';
import {
	RETURNS_SUMMARIES_QUERY,
	RETURNS_MERCHANTS_QUERY,
	COUNTS_QUERY,
} from './InboundReturns.graphql';
import { InboundReturnsFilters } from './InboundReturnsFilters';
import { InboundReturnsTable } from './InboundReturnsTable';
import { Pagination } from 'components/DataTable/Pagination';
import { Error } from 'components/Error';
import { TimezoneFooter } from 'components/TimezoneFooter';
import { Tab, ViewTabs } from 'components/ViewTabs';
import { MIN_QUERY_LENGTH } from 'helpers/table';
import { addDays, endOfDay, startOfDay } from 'helpers/time';
import { SetFilters, useFilters, useSetFilters, DEFAULT_VIEW_KEY } from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';

const RETURN_TYPE_KEY = 'returnType';
const STORE_KEY = 'store';
const ESTIMATED_ARRIVAL_KEY = 'estimatedArrival';
const viewKey = DEFAULT_VIEW_KEY;

function calculateETAFrom(selectedETA: string | undefined) {
	return selectedETA ? startOfDay() : undefined;
}

function calculateETATo(selectedETA: string | undefined) {
	if (selectedETA) {
		const dayEnd = endOfDay();

		switch (selectedETA) {
			case '30DAYS':
				return addDays(dayEnd, 29);
			case '10DAYS':
				return addDays(dayEnd, 9);
			case '5DAYS':
				return addDays(dayEnd, 4);
			case '3DAYS':
				return addDays(dayEnd, 2);
			case '2DAYS':
				return addDays(dayEnd, 1);
			case 'TODAY':
			default:
				return dayEnd;
		}
	}

	return undefined;
}

export function InboundReturns() {
	const { messages, translate } = useLocalization();

	const defaultView = InboundReturnViews.All;
	const defaultSort = `${ReturnsSummaryOrderByField.ReturnStatus} ${OrderByDirection.Desc}`;
	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);

	// filtring logic
	const {
		query,
		sort = defaultSort,
		view = defaultView,
		[RETURN_TYPE_KEY]: returnTypeParam,
		[STORE_KEY]: storeParam,
		[ESTIMATED_ARRIVAL_KEY]: etaParam,
	} = useFilters([RETURN_TYPE_KEY, STORE_KEY, ESTIMATED_ARRIVAL_KEY]);
	const setFilters: SetFilters = useSetFilters();

	const searchText = useDebouncedValue(query) || '';
	const actualSearchText = searchText.length >= MIN_QUERY_LENGTH ? searchText : undefined;
	const selectedReturnType = returnTypeParam;
	const selectedStores = storeParam ? storeParam.split(',') : undefined;
	const selectedETA = etaParam;

	const statuses = InboundReturnViewStateMap[view as InboundReturnViews];

	// query logic
	const [
		{
			fetching: returnsSummariesFetching,
			data: returnsSummariesData,
			error: returnsSummariesError,
		},
	] = useQuery<{ returnsSummaries: ReturnsSummaryPage }, QueryReturnsSummariesArgs>({
		query: RETURNS_SUMMARIES_QUERY,
		variables: {
			limit: 50,
			orderBy: sort ? (sort.split(' ')[0] as ReturnsSummaryOrderByField) : undefined,
			orderDirection: sort ? (sort.split(' ')[1] as OrderByDirection) : undefined,
			searchText: actualSearchText,
			statuses,
			returnType: selectedReturnType,
			merchants: selectedStores,
			etaFrom: calculateETAFrom(selectedETA),
			etaTo: calculateETATo(selectedETA),
			cursor: paginationCursors[0],
		},
	});

	const [{ fetching: countsFetching, data: countsData, error: countsError }] = useQuery<{
		InTransitCount: Count;
		InProgressCount: Count;
		ClosedCount: Count;
		CancelRequestedCount: Count;
		CancelledCount: Count;
	}>({
		query: COUNTS_QUERY,
		variables: {
			merchants: selectedStores,
			etaFrom: calculateETAFrom(selectedETA),
			etaTo: calculateETATo(selectedETA),
			searchText: actualSearchText,
			returnType: selectedReturnType,
		},
	});

	const [{ fetching: merchantsFetching, data: merchantsData, error: merchantsError }] = useQuery<{
		returnsMerchants: string[];
	}>({
		query: RETURNS_MERCHANTS_QUERY,
	});

	const fetching = returnsSummariesFetching || countsFetching || merchantsFetching;
	const error = returnsSummariesError || countsError || merchantsError;

	const {
		InTransitCount = { count: 0 },
		InProgressCount = { count: 0 },
		ClosedCount = { count: 0 },
		CancelRequestedCount = { count: 0 },
		CancelledCount = { count: 0 },
	} = countsData || {};

	const views: Tab[] = [
		{
			label: messages.all,
			id: InboundReturnViews.All,
		},
		{
			label: messages.inTransit,
			metaLabel: InTransitCount.count,
			id: InboundReturnViews.InTransit,
		},
		{
			label: messages.inProgress,
			metaLabel: InProgressCount.count,
			id: InboundReturnViews.InProgress,
		},
		{
			label: messages.closed,
			metaLabel: ClosedCount.count,
			id: InboundReturnViews.Complete,
		},
		{
			label: messages.cancellationRequested,
			metaLabel: CancelRequestedCount.count,
			id: InboundReturnViews.CancelRequested,
		},
		{
			label: messages.canceled,
			metaLabel: CancelledCount.count,
			id: InboundReturnViews.Cancelled,
		},
	];

	const appliedFilters: AppliedFilterInterface[] = [
		...(selectedReturnType
			? [
					{
						key: RETURN_TYPE_KEY,
						label: messages.returnTypes[selectedReturnType as keyof typeof messages.returnTypes],
						onRemove: () => {
							setFilters([{ key: RETURN_TYPE_KEY, value: '' }]);
						},
					},
			  ]
			: []),
		...(selectedStores
			? [
					{
						key: STORE_KEY,
						label: selectedStores.join(', '),
						onRemove: () => {
							setFilters([{ key: STORE_KEY, value: '' }]);
						},
					},
			  ]
			: []),
		...(selectedETA
			? [
					{
						key: ESTIMATED_ARRIVAL_KEY,
						label:
							selectedETA === 'TODAY'
								? messages.arrivingToday
								: selectedETA === 'LATE'
								? messages.arrivingLate
								: (translate(messages.arrivingWithinDays, {
										count: parseInt(selectedETA),
								  }) as string),
						onRemove: () => {
							setFilters([{ key: ESTIMATED_ARRIVAL_KEY, value: '' }]);
						},
					},
			  ]
			: []),
	];

	return error ? (
		<Error graphQLError={error} />
	) : (
		<Page fullWidth title={messages.returnShipments}>
			<Layout>
				<Layout.Section>
					<Card>
						<Card.Section>
							<div style={{ paddingBottom: '2rem' }}>
								<ViewTabs
									tabs={views}
									selected={view || views?.[0]?.id}
									onSelect={(id) => setFilters([{ key: viewKey, value: id }])}
								/>
							</div>
							<InboundReturnsFilters
								merchantsData={merchantsData}
								selectedETA={selectedETA}
								selectedReturnType={selectedReturnType}
								selectedStores={selectedStores}
								setFilters={setFilters}
								appliedFilters={appliedFilters}
								query={query}
								sort={sort}
							/>
						</Card.Section>
						<InboundReturnsTable data={returnsSummariesData?.returnsSummaries} loading={fetching} />
						<Card.Section>
							<Pagination
								endCursor={returnsSummariesData?.returnsSummaries?.cursor}
								cursors={paginationCursors}
								setCursors={setPaginationCursors}
								loading={fetching}
							/>
						</Card.Section>
					</Card>
				</Layout.Section>
			</Layout>
			<TimezoneFooter />
		</Page>
	);
}
