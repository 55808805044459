import { Page } from '@shopify/polaris';
import {
	AddContainerInput,
	MutationResponse,
	ContainerType,
} from '@sixriver/fulfillment-api-schema';
import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useMutation } from 'urql';

import { ContainerForm } from './Container.form';
import { CREATE_CONTAINER_MUTATION } from './Containers.graphql';
import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';

const formDefaults: AddContainerInput = {
	// enums
	type: ContainerType.Picking,
	// strings
	barcode: '',
	name: '',
	description: '',
	externalId: '',
	imageURL: '',
	instructions: '',
	// ints
	columns: 1,
	rows: 1,
	// floats
	length: 1,
	width: 1,
	height: 1,
	weight: 0,
	// bools
	enabled: true,
	inventory: false,
};

export function AddContainer(): JSX.Element {
	const { messages } = useLocalization();

	// Routing
	const history = useHistory();

	// Mutations
	const [{ error: addContainerError }, addContainerMutation] = useMutation<
		{ createContainer: MutationResponse },
		{ input: AddContainerInput }
	>(CREATE_CONTAINER_MUTATION);

	// Methods
	const onSubmit = useCallback(
		async (input: AddContainerInput) => {
			const { data } = await addContainerMutation({ input });
			if (data?.createContainer?.success) {
				history.push(routes.containers());
			}
		},
		[addContainerMutation, history],
	);

	// Render
	return (
		<Page
			title={messages.newContainer}
			breadcrumbs={[{ content: messages.containers, url: routes.containers() }]}
			fullWidth
		>
			<ContainerForm mode="add" data={formDefaults} onSubmit={onSubmit} error={addContainerError} />
		</Page>
	);
}
