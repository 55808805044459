import { Badge, Card, Stack } from '@shopify/polaris';
import { LaneContainer } from '@sixriver/fulfillment-api-schema';

import { LaneContainerStatusBadge } from 'components/LaneContainerStatusBadge';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	container?: LaneContainer;
}

export function LaneContainerDetailsTitle({ container }: Props): JSX.Element | null {
	const { messages } = useLocalization();
	const badge = container?.carrierPickupDetails?.isOverridden ? (
		<Badge status="critical">{messages.laneOverride}</Badge>
	) : (
		<LaneContainerStatusBadge container={container} />
	);

	return (
		<Stack vertical={false} alignment="baseline" distribution="leading">
			<Card.Header title={messages.containerDetails} />
			<Stack spacing="extraTight">{badge}</Stack>
		</Stack>
	);
}
