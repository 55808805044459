export const DEFAULT_PAGE_SIZE_MOBILE = 20;
export const DEFAULT_PAGE_SIZE_DESKTOP = 50;

export function getPageSize() {
	// TODO: device detection
	const isMobile = false;

	if (isMobile) {
		return DEFAULT_PAGE_SIZE_MOBILE;
	}

	return DEFAULT_PAGE_SIZE_DESKTOP;
}
