import { Chuck } from './Chuck.type';
import {
	isCharging,
	isDestinationOfType,
	isDwelling,
	isFaulty,
	isHighPriority,
	isLowBattery,
	isPicking,
} from './ChuckUtils';
import { FeatureType, FeatureTypeMap } from './FeatureType';
import styles from './FloorView.module.css';
import { FullScreenStat } from './FullScreenStat';
import { useConfig } from 'hooks/useConfig';
import { useLocalization } from 'hooks/useLocalization';
import DWELLING_IMG from 'images/fsDwelling.svg';
import FAULTY_IMG from 'images/fsFaulty.svg';
import LOW_BATTERY_IMG from 'images/fsLowBattery.svg';
import PRIORITY_IMG from 'images/fsPriority.svg';

interface Props {
	chucks: Chuck[];
	featureTypes: FeatureTypeMap;
}

export function FullScreenHeader({ chucks, featureTypes }: Props) {
	const { messages } = useLocalization();
	const { config } = useConfig();
	const isUsingHighPriority = config?.highPriorityChuckEnabled;

	let online = 0;
	let faulty = 0;
	let dwelling = 0;
	let lowBattery = 0;
	let inducting = 0;
	let meeting = 0;
	let picking = 0;
	let charging = 0;
	let priority = 0;

	chucks.forEach((chuck) => {
		if (chuck.online) {
			online++;
		}

		if (isFaulty(chuck)) {
			faulty++;
		}

		if (isDwelling(chuck)) {
			dwelling++;
		}

		if (isLowBattery(chuck)) {
			lowBattery++;
		}

		if (isDestinationOfType(chuck, FeatureType.Induct, featureTypes)) {
			inducting++;
		}

		if (isDestinationOfType(chuck, FeatureType.Meeting, featureTypes)) {
			meeting++;
		}

		if (isPicking(chuck)) {
			picking++;
		}

		if (isCharging(chuck)) {
			charging++;
		}

		if (isHighPriority(chuck)) {
			priority++;
		}
	});

	return (
		<div className={styles.fsHeader}>
			<FullScreenStat label={messages.total} value={chucks.length} background="#222" />
			<FullScreenStat label={messages.chuckStates.online} value={online} background="#222" />
			<FullScreenStat
				label={messages.chuckStates.faulty}
				value={faulty}
				color="#ff2525"
				icon={FAULTY_IMG}
			/>
			<FullScreenStat
				label={
					isUsingHighPriority ? messages.chuckStates.idleInAisle : messages.chuckStates.dwellingIdle
				}
				value={dwelling}
				color="#00e0ff"
				icon={DWELLING_IMG}
			/>
			{isUsingHighPriority ? (
				<FullScreenStat
					label={messages.chuckStates.priority}
					value={priority}
					color="#00e0ff"
					icon={PRIORITY_IMG}
				/>
			) : null}
			<FullScreenStat
				label={messages.chuckStates.lowBattery}
				value={lowBattery}
				color="#ffe924"
				icon={LOW_BATTERY_IMG}
			/>
			<FullScreenStat label={messages.induct} value={inducting} />
			<FullScreenStat label={messages.meetingPoint} value={meeting} />
			<FullScreenStat label={messages.picking} value={picking} />
			<FullScreenStat label={messages.chuckStates.charging} value={charging} />
		</div>
	);
}
