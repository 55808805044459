import { Modal, TextContainer } from '@shopify/polaris';

import { useLocalization } from 'hooks/useLocalization';

export interface historyLimiterModalProps {
	isOpen: boolean;
	onClose: () => void;
	onSubmit: () => void;
	numOfDaysToRetrieve: number;
}

export function OrderHistoryLimiterConfirmModal({
	isOpen: isModalOpen,
	onClose,
	onSubmit,
	numOfDaysToRetrieve,
}: historyLimiterModalProps) {
	// Localization
	const { messages, translate } = useLocalization();

	// Render
	return (
		<Modal
			open={isModalOpen}
			onClose={onClose}
			title={messages.viewMoreOrders}
			primaryAction={{
				content: messages.proceed,
				onAction: onSubmit,
			}}
			secondaryActions={[
				{
					content: messages.cancel,
					onAction: onClose,
				},
			]}
		>
			<Modal.Section>
				<TextContainer>
					<p>{translate(messages.confirmOrderHistoryLength, { count: numOfDaysToRetrieve })}</p>
				</TextContainer>
			</Modal.Section>
		</Modal>
	);
}
