import { AppliedFilterInterface, ChoiceList, Filters, FilterInterface } from '@shopify/polaris';
import { OrderByDirection, ReturnsSummaryOrderByField } from '@sixriver/fulfillment-api-schema';

import { SortBy, SortChoice } from 'components/SortBy';
import { SetFilters, DEFAULT_QUERY_KEY, DEFAULT_SORT_KEY } from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';

const ESTIMATED_ARRIVAL_KEY = 'estimatedArrival';
const RETURN_TYPE_KEY = 'returnType';
const STORE_KEY = 'store';
const queryKey = DEFAULT_QUERY_KEY;
const sortKey = DEFAULT_SORT_KEY;

interface Props {
	appliedFilters?: AppliedFilterInterface[];
	merchantsData?: { returnsMerchants: string[] };
	query?: string;
	selectedETA?: string;
	selectedReturnType?: string;
	selectedStores?: string[];
	setFilters: SetFilters;
	sort?: string;
}

export function InboundReturnsFilters({
	appliedFilters,
	merchantsData,
	query,
	selectedETA,
	selectedReturnType,
	selectedStores,
	setFilters,
	sort,
}: Props) {
	const { messages, translate } = useLocalization();

	const returnTypeChoices = [
		{
			label: messages.returnTypes.RMA,
			value: 'RMA',
		},
		{
			label: messages.returnTypes.RTS,
			value: 'RTS',
		},
	];

	const storeChoices =
		merchantsData?.returnsMerchants.map((merchant) => ({
			label: merchant,
			value: merchant,
		})) || [];

	const etaChoices = [
		{
			label: messages.arrivingToday,
			value: 'TODAY',
		},
		{
			label: translate(messages.arrivingWithinDays, { count: 2 }),
			value: '2DAYS',
		},
		{
			label: translate(messages.arrivingWithinDays, { count: 3 }),
			value: '3DAYS',
		},
		{
			label: translate(messages.arrivingWithinDays, { count: 5 }),
			value: '5DAYS',
		},
		{
			label: translate(messages.arrivingWithinDays, { count: 10 }),
			value: '10DAYS',
		},
		{
			label: translate(messages.arrivingWithinDays, { count: 30 }),
			value: '30DAYS',
		},
	];

	const filters: FilterInterface[] = [
		{
			key: RETURN_TYPE_KEY,
			label: messages.returnType,
			filter: (
				<ChoiceList
					title={messages.returnType}
					titleHidden
					choices={returnTypeChoices}
					selected={selectedReturnType ? [selectedReturnType] : []}
					onChange={(selected) => {
						setFilters([{ key: RETURN_TYPE_KEY, value: selected.join(',') }]);
					}}
				/>
			),
			shortcut: true,
		},
		{
			key: STORE_KEY,
			label: messages.store,
			filter: (
				<ChoiceList
					title={messages.store}
					titleHidden
					choices={storeChoices}
					selected={selectedStores || []}
					onChange={(selected) => {
						setFilters([{ key: STORE_KEY, value: selected.join(',') }]);
					}}
					allowMultiple
				/>
			),
			shortcut: true,
		},
		{
			key: ESTIMATED_ARRIVAL_KEY,
			label: messages.estimatedArrival,
			filter: (
				<ChoiceList
					title={messages.estimatedArrival}
					titleHidden
					choices={etaChoices}
					selected={selectedETA ? [selectedETA] : []}
					onChange={(selected) => {
						setFilters([{ key: ESTIMATED_ARRIVAL_KEY, value: selected.join(',') }]);
					}}
				/>
			),
			shortcut: true,
		},
	];

	const sortChoices: SortChoice[] = [
		{
			label: messages.sortOptions.storeAsc,
			value: `${ReturnsSummaryOrderByField.Merchant} ${OrderByDirection.Asc}`,
		},
		{
			label: messages.sortOptions.storeDsc,
			value: `${ReturnsSummaryOrderByField.Merchant} ${OrderByDirection.Desc}`,
		},
		{
			label: messages.sortOptions.progressDesc,
			value: `${ReturnsSummaryOrderByField.ReturnStatus} ${OrderByDirection.Desc}`,
		},
		{
			label: messages.sortOptions.progressAsc,
			value: `${ReturnsSummaryOrderByField.ReturnStatus} ${OrderByDirection.Asc}`,
		},
		{
			label: messages.sortOptions.expectedUnitsDesc,
			value: `${ReturnsSummaryOrderByField.QuantityExpected} ${OrderByDirection.Desc}`,
		},
		{
			label: messages.sortOptions.expectedUnitsAsc,
			value: `${ReturnsSummaryOrderByField.QuantityExpected} ${OrderByDirection.Asc}`,
		},
	];

	const onClearAll = () => {
		setFilters([{ key: queryKey, value: '' }]);
	};

	return (
		<Filters
			queryValue={query || undefined}
			queryPlaceholder={messages.filterInboundReturns}
			onQueryChange={(value) => setFilters([{ key: queryKey, value }])}
			onQueryClear={() => setFilters([{ key: queryKey, value: '' }])}
			filters={filters || []}
			appliedFilters={appliedFilters}
			onClearAll={onClearAll}
		>
			<SortBy
				choices={sortChoices}
				selected={sort ? [sort] : []}
				onChange={(selected) => setFilters([{ key: sortKey, value: selected[0] }])}
			/>
		</Filters>
	);
}
