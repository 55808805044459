import {
	FormLayout,
	Form,
	Button,
	Layout,
	Banner,
	Page,
	Card,
	TextField,
	Link,
	TextStyle,
	Heading,
} from '@shopify/polaris';
import { ViewMinor, HideMinor } from '@shopify/polaris-icons';
import { useState } from 'react';

import styles from './Login.module.css';
import { PasswordIndicator } from './PasswordIndicator';
import sixrslogo from './sixrslogo.png';
import { useAuth } from 'hooks/useAuth';
import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';

function localValidate(password: string): boolean {
	if (password.length >= 8) {
		let n = 0;

		[/[a-z]/, /[A-Z]/, /\d/].forEach((pattern) => {
			if (pattern.test(password)) {
				n++;
			}
		});

		return n >= 2;
	}

	return false;
}

export function SetPassword(): JSX.Element {
	// custom hooks
	const { messages, translate } = useLocalization();
	const { login } = useAuth();

	// state
	const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
	const [password, setPassword] = useState('');
	const [passwordConfirmation, setPasswordConfirmation] = useState('');
	const [error, setError] = useState<string | undefined>(undefined);

	// local validation
	const isValidFormat = localValidate(password);
	const isMatch = passwordConfirmation.length > 0 && password === passwordConfirmation;

	// router / params
	const params = new URLSearchParams(document.location.search);
	const emailAddress: string = params.get('email') || '?';
	const token: string = params.get('token') || '?';
	const isWelcomeEmail: boolean = params.get('mode') === 'set';

	// methods
	const onSubmit = async () => {
		setError(undefined);

		try {
			const url = `${process.env.REACT_APP_GATEKEEPER_API_URL}/users/reset-password/${token}`;

			const response = await fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ password }),
			});

			if (response.ok) {
				await login(emailAddress, password);
			}

			if (!response.ok) {
				const error: { statusCode: number; message: string } = await response.json();

				if (error?.message === 'INVALID_PASSWORD_FORMAT') {
					setError(messages.passwordPolicy);
				} else {
					// some other improbable error. for now just show the raw message
					setError(messages.unknownError);
				}
			}
		} catch (error: any) {
			setError(error.message);
		}
	};

	const toggle = (
		<Button
			plain
			monochrome
			size="large"
			icon={passwordVisible ? ViewMinor : HideMinor}
			onClick={() => setPasswordVisible((visible) => !visible)}
		/>
	);

	// render
	return (
		<Page>
			<Layout>
				<div className={styles.container}>
					<Card sectioned>
						<Form onSubmit={onSubmit}>
							<FormLayout>
								<img src={sixrslogo} className={styles.logo} alt="6 River Systems" />
								<Heading element="h1">
									{translate(messages.choosePasswordForEmail, { email: emailAddress })}
								</Heading>
								<TextStyle>{messages.passwordPolicy}</TextStyle>
								{error ? <Banner status="critical">{error}</Banner> : null}
								<TextField
									autoComplete="current-password"
									name="password"
									type={passwordVisible ? 'text' : 'password'}
									label={messages.newPassword}
									value={password}
									onChange={setPassword}
									suffix={toggle}
								/>
								{password.length ? (
									<PasswordIndicator
										success={isValidFormat}
										successText={messages.passwordAccepted}
										failureText={messages.passwordRejected}
									/>
								) : null}
								<TextField
									autoComplete="off"
									name="confirm-password"
									type={passwordVisible ? 'text' : 'password'}
									label={messages.confirmNewPassword}
									value={passwordConfirmation}
									onChange={setPasswordConfirmation}
									suffix={toggle}
								/>
								{passwordConfirmation.length ? (
									<PasswordIndicator
										success={isMatch}
										successText={messages.passwordsMatch}
										failureText={messages.passwordsDoNotMatch}
									/>
								) : null}
								<Button submit primary disabled={!isValidFormat || !isMatch}>
									{isWelcomeEmail ? messages.setUpAccount : messages.changePassword}
								</Button>
								<Link removeUnderline url={routes.login()}>
									{messages.cancel}
								</Link>
							</FormLayout>
						</Form>
					</Card>
					<div className={styles.version}>
						<small>{REACT_APP_VERSION || 'DEVELOPMENT'}</small>
					</div>
				</div>
			</Layout>
		</Page>
	);
}
