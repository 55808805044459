import {
	Badge,
	Banner,
	Button,
	Caption,
	Card,
	Collapsible,
	DropZone,
	Heading,
	Icon,
	List,
	Modal,
	Spinner,
	Stack,
	TextContainer,
	TextStyle,
	Thumbnail,
} from '@shopify/polaris';
import {
	ChevronUpMinor,
	ChevronDownMinor,
	NoteMinor,
	CircleCancelMinor,
} from '@shopify/polaris-icons';
import {
	MutationResponse,
	ShippingNotice,
	ShippingNoticeUploadInput,
} from '@sixriver/fulfillment-api-schema';
import { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useMutation } from 'urql';

import {
	ADD_SHIPPING_NOTICE_UPLOADS_MUTATION,
	REMOVE_SHIPPING_NOTICE_UPLOADS_MUTATION,
} from '../InboundShipment.graphql';
import styles from './Attachments.module.css';
import { Divider } from 'components/Divider';
import { useAuth } from 'hooks/useAuth';
import { useLocalization } from 'hooks/useLocalization';
import { useToast } from 'hooks/useToast';
import { fetchSignedUrl, uploadAttachment, Upload, removeAttachment } from 'utils/image';

interface Props {
	shippingNotice?: ShippingNotice;
	refetchShipment: () => void;
}

export function Attachments({ shippingNotice, refetchShipment }: Props) {
	const { messages } = useLocalization();
	const { showToast } = useToast();
	const {
		params: { inboundShipmentId },
	} = useRouteMatch<{ inboundShipmentId: string }>();

	// State
	const [imageCollapsibleOpen, setImageCollapsibleOpen] = useState(false);
	const [isAddUploadModalOpen, setIsAddUploadModalOpen] = useState(false);
	const [isRemoveUploadModalOpen, setIsRemoveUploadModalOpen] = useState(false);
	const [files, setFiles] = useState([] as File[]);
	const [rejectedFiles, setRejectedFiles] = useState([] as File[]);
	const [isUploading, setIsUploading] = useState(false);
	const [isRemovingUpload, setIsRemovingUpload] = useState(false);
	const [uploads, setUploads] = useState([] as Upload[]);
	const [removeUploadId, setRemoveUploadId] = useState('' as string);

	// Auth
	const { user } = useAuth();
	const loggedInUserId = user?.id;

	const existingUploads = shippingNotice?.uploads && shippingNotice?.uploads.length > 0;

	useEffect(() => {
		if (existingUploads) {
			setImageCollapsibleOpen(true);
		}
	}, [existingUploads]);

	useEffect(() => {
		const fetchAttachmentData = async () => {
			if (shippingNotice?.uploads) {
				const imageData = await Promise.all(
					shippingNotice?.uploads.map(async (upload) => {
						return await fetchSignedUrl(upload.id);
					}),
				);
				setUploads(imageData);
			}
		};

		fetchAttachmentData();
	}, [shippingNotice?.uploads]);

	const hasError = rejectedFiles.length > 0;

	// Mutations
	const [, addShippingNoticeUploads] = useMutation<
		{ addShippingNoticeUploads: MutationResponse },
		{ input: ShippingNoticeUploadInput }
	>(ADD_SHIPPING_NOTICE_UPLOADS_MUTATION);

	const [, deleteShippingNoticeUploads] = useMutation<
		{ deleteShippingNoticeUploads: MutationResponse },
		{ input: ShippingNoticeUploadInput }
	>(REMOVE_SHIPPING_NOTICE_UPLOADS_MUTATION);

	// Methods
	const toggleImageCollapsible = useCallback(
		() => setImageCollapsibleOpen((imageCollapsibleOpen) => !imageCollapsibleOpen),
		[],
	);

	const openAddImageModal = () => setIsAddUploadModalOpen(true);
	const closeAddImageModal = () => setIsAddUploadModalOpen(false);
	const openRemoveUploadModal = () => setIsRemoveUploadModalOpen(true);
	const closeRemoveUploadModal = () => setIsRemoveUploadModalOpen(false);

	const handleDrop = useCallback((_droppedFiles, acceptedFiles, rejectedFiles) => {
		setFiles((files) => [...files, ...acceptedFiles]);
		setRejectedFiles(rejectedFiles);
	}, []);

	const addImages = async () => {
		if (!loggedInUserId) {
			showToast(messages.uploadAttachmentFailed);
			return;
		}

		setIsUploading(true);

		// upload image to upload service
		const uploadedImageData = await Promise.all(
			files.map(async (file) => {
				return await uploadAttachment(file, loggedInUserId);
			}),
		);

		// filter out id's of uploaded images
		const uploadIds = uploadedImageData.map(({ id }) => id);

		const input: ShippingNoticeUploadInput = {
			asnId: inboundShipmentId,
			uploads: uploadIds,
		};
		const { data } = await addShippingNoticeUploads({ input });
		if (data?.addShippingNoticeUploads.success) {
			refetchShipment();
			setIsUploading(false);
			setFiles([]);
			setRejectedFiles([]);
			closeAddImageModal();
		} else {
			setIsUploading(false);
			showToast(messages.uploadAttachmentFailed);
		}
	};

	const cancelAddImages = () => {
		setFiles([]);
		setRejectedFiles([]);
		closeAddImageModal();
	};

	const confirmRemoveUpload = (uploadId: string) => {
		setRemoveUploadId(uploadId);
		openRemoveUploadModal();
	};

	const cancelRemoveUpload = () => {
		setRemoveUploadId('');
		closeRemoveUploadModal();
	};

	// remove uploaded file from an ASN
	const removeUpload = async () => {
		const input: ShippingNoticeUploadInput = {
			asnId: inboundShipmentId,
			uploads: [removeUploadId],
		};

		setIsRemovingUpload(true);
		const response = await removeAttachment(removeUploadId);

		if (!response.ok) {
			showToast(messages.removeAttachmentFailed);
			setIsRemovingUpload(false);
			return;
		}

		const { data } = await deleteShippingNoticeUploads({ input });
		if (data?.deleteShippingNoticeUploads.success) {
			refetchShipment();
			setRemoveUploadId('');
			closeRemoveUploadModal();
			setIsRemovingUpload(false);
		} else {
			setIsRemovingUpload(false);
			showToast(messages.removeAttachmentFailed);
		}
	};

	const fileUpload = !files.length && <DropZone.FileUpload />;
	const uploadedFiles = files.length > 0 && (
		<div style={{ padding: 20 }}>
			<Stack vertical>
				{files.map((file, index) => (
					<Stack alignment="center" key={index}>
						<Thumbnail
							size="small"
							alt={file.name}
							source={
								file.type === 'application/pdf' ? NoteMinor : window.URL.createObjectURL(file)
							}
						/>
						<div>
							{file.name} <Caption>{file.size} bytes</Caption>
						</div>
					</Stack>
				))}
			</Stack>
		</div>
	);

	const errorMessage = hasError && (
		<Banner title={messages.imageUploadError} status="critical">
			<List type="bullet">
				{rejectedFiles.map((file, index) => (
					<List.Item key={index}>{`"${file.name}" - ${messages.fileTypeNotSupported}`}</List.Item>
				))}
			</List>
		</Banner>
	);

	return (
		<>
			<Card>
				<Card.Section>
					<Stack distribution="equalSpacing">
						<Stack alignment="center" spacing="extraTight">
							<Button
								icon={imageCollapsibleOpen ? ChevronUpMinor : ChevronDownMinor}
								plain
								onClick={toggleImageCollapsible}
								ariaExpanded={imageCollapsibleOpen}
								ariaControls="basic-collapsible"
								monochrome={true}
								removeUnderline={true}
								size="large"
								disabled={uploads.length === 0}
							/>
							<Heading>{messages.images}</Heading>
							<TextStyle variation="strong">({uploads.length})</TextStyle>
						</Stack>
						<Button plain onClick={openAddImageModal}>
							{messages.addImages}
						</Button>
					</Stack>
				</Card.Section>
				<Collapsible
					open={imageCollapsibleOpen}
					id="basic-collapsible"
					transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
					expandOnPrint
				>
					<Divider />
					{existingUploads && (
						<Card.Section>
							<Stack>
								{uploads.map((upload) => {
									return (
										<div
											className={styles.imageContainer}
											key={upload?.metadata?.id}
											onClick={() => confirmRemoveUpload(upload?.metadata?.id)}
										>
											<Stack alignment="center">
												<Thumbnail
													source={
														upload?.metadata?.fileFormat === 'pdf' ? NoteMinor : upload.signedURL
													}
													alt=""
												/>
												<Stack>
													<div>
														<p>{upload?.metadata?.fileName}</p>
														<Badge>{upload?.metadata?.fileFormat.toUpperCase()}</Badge>
													</div>
													<div className={styles.iconContainer}>
														<Icon source={CircleCancelMinor} color="subdued" />
													</div>
												</Stack>
											</Stack>
										</div>
									);
								})}
							</Stack>
						</Card.Section>
					)}
				</Collapsible>
			</Card>
			<Modal
				title={messages.attachImages}
				open={isAddUploadModalOpen}
				onClose={closeAddImageModal}
				primaryAction={{
					content: messages.upload,
					onAction: addImages,
					disabled: files.length === 0 || isUploading,
				}}
				secondaryActions={[
					{
						content: messages.cancel,
						destructive: false,
						onAction: cancelAddImages,
					},
				]}
			>
				<Modal.Section>
					<Stack vertical>
						{errorMessage}
						<DropZone
							accept="image/jpeg, image/png, application/pdf"
							type="file"
							onDrop={handleDrop}
						>
							{isUploading && (
								<div className={styles.DropZoneSpinnerContainer}>
									<Spinner size="large" />
								</div>
							)}
							{!isUploading && uploadedFiles}
							{fileUpload}
						</DropZone>
						<TextContainer>
							<TextStyle variation="subdued">{messages.acceptedFileFormats}</TextStyle>
						</TextContainer>
					</Stack>
				</Modal.Section>
			</Modal>
			<Modal
				open={isRemoveUploadModalOpen}
				onClose={cancelRemoveUpload}
				primaryAction={{
					content: messages.delete,
					onAction: removeUpload,
					destructive: true,
					disabled: isRemovingUpload,
				}}
				secondaryActions={[
					{
						content: messages.cancel,
						destructive: false,
						onAction: cancelRemoveUpload,
					},
				]}
				title={messages.deleteUpload}
			>
				<Modal.Section>
					<p>{messages.confirmDeleteUpload}</p>
				</Modal.Section>
			</Modal>
		</>
	);
}
