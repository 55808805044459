import { Link, TextContainer, TextStyle } from '@shopify/polaris';
import {
	ReceivingIssueReason,
	ReworkJob,
	ReworkJobsSummaryPage,
} from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';
import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';

interface Props {
	issueId?: string | null | undefined;
	issue?: ReceivingIssueReason | null;
	quantity?: number | null;
	reworkJobs?: ReworkJobsSummaryPage;
}

export function InboundShipmentIssue({ issueId, issue, quantity, reworkJobs }: Props) {
	const { messages, translate } = useLocalization();

	const associatedReworkJob = reworkJobs?.results.find(
		(reworkJob: ReworkJob) => reworkJob.issueId === issueId,
	);

	return issue ? (
		<div>
			<TextContainer>
				<TextStyle variation="negative">
					{messages.receivingIssueReason[issue]}
					{quantity ? ' (' + translate(messages.countUnits, { count: quantity }) + ')' : ''}
				</TextStyle>
			</TextContainer>
			{associatedReworkJob && (
				<Link url={routes.reworkJob(associatedReworkJob.id)} key="rework" removeUnderline external>
					{messages.viewJob}
				</Link>
			)}
		</div>
	) : (
		<NoData />
	);
}
