import { PropsWithChildren } from 'react';
import { createClient, Provider } from 'urql';

const client = createClient({
	url: `${process.env.REACT_APP_API_URL}/graphql`,
	requestPolicy: 'cache-and-network',
	fetchOptions: { credentials: 'include' },
});

export function GraphQLProvider({ children }: PropsWithChildren<{}>) {
	return <Provider value={client}>{children}</Provider>;
}

export const gqlClient = client;
