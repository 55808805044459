import { Card, Stack, TextContainer, TextStyle } from '@shopify/polaris';
import { ShippingNotice } from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';
import { RelativeDateTime } from 'components/RelativeDateTime';
import { useLocalization } from 'hooks/useLocalization';

export function Progress({ shippingNotice }: { shippingNotice?: ShippingNotice }) {
	const { messages, translate } = useLocalization();

	const expectedTotalQuantity =
		shippingNotice?.inboundLines?.reduce(
			(accumulator, { expectedQuantity }) => accumulator + expectedQuantity,
			0,
		) || 0;
	const expectedLines = shippingNotice?.inboundLines?.length || 0;

	return (
		<Card title={messages.progress}>
			<Card.Section>
				<Stack distribution="fillEvenly">
					<div>
						<TextContainer>
							<TextStyle variation="subdued">{translate(messages.expected)}</TextStyle>
						</TextContainer>
						{expectedTotalQuantity > 0 ? (
							<TextContainer spacing="loose">
								<TextStyle>
									{translate(messages.countUnits, { count: expectedTotalQuantity })}
								</TextStyle>{' '}
								<TextStyle variation="subdued">
									{translate(messages.countLines, { count: expectedLines })}
								</TextStyle>
							</TextContainer>
						) : (
							<NoData />
						)}
					</div>
					<div>
						<TextContainer>
							<TextStyle variation="subdued">{messages.received}</TextStyle>
						</TextContainer>
						<TextContainer spacing="loose">
							<TextStyle>
								{translate(messages.countUnits, {
									count: (shippingNotice?.inboundLines || []).reduce(
										(acc, { receivedQuantity }) => acc + receivedQuantity,
										0,
									),
								})}
							</TextStyle>{' '}
							<TextStyle variation="subdued">
								{translate(messages.countLines, {
									count: shippingNotice?.receivedInboundLineCount || 0,
								})}
							</TextStyle>
						</TextContainer>
					</div>
					<div>
						<TextContainer>
							<TextStyle variation="subdued">
								{translate(messages.firstArticleInspection)}
							</TextStyle>
						</TextContainer>
						{shippingNotice?.requiredInspectionLinesCount === 0 ? (
							<NoData />
						) : (
							<TextContainer>
								<TextStyle>
									{translate(messages.xOfYCompleted, {
										x: shippingNotice?.inspectedLinesCount?.toString() || '',
										y: shippingNotice?.requiredInspectionLinesCount?.toString() || '',
									})}
								</TextStyle>
							</TextContainer>
						)}
					</div>
					<div>
						<TextContainer>
							<TextStyle variation="subdued">{translate(messages.sla)}</TextStyle>
						</TextContainer>
						<TextContainer>
							{shippingNotice?.shipments ? (
								<RelativeDateTime
									date={shippingNotice?.shipments[0]?.slaCompletionBy}
									mode="deadline"
								/>
							) : (
								<NoData />
							)}
						</TextContainer>
					</div>
				</Stack>
			</Card.Section>
		</Card>
	);
}
