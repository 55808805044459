import { useCallback, useState } from 'react';

import {
	DateRange,
	DateRangeSelect,
	OptionLabelValue,
	OptionValues,
} from 'components/DateRangeSelect';
import { getDateDiffInDays } from 'helpers/time';
import { OrderHistoryLimiterConfirmModal } from 'pages/OrdersV1/OrderHistoryLimiterConfirmModal';

const MIDNIGHT_TODAY = new Date();
MIDNIGHT_TODAY.setHours(0, 0, 0, 0);

interface DateRangeFilterProps {
	options: OptionValues[];
	selectedOption: OptionValues | Date;
	title?: string;
	onChange: (date: Date) => void;
}

export function DateRangeFilter({
	options,
	selectedOption,
	title,
	onChange,
}: DateRangeFilterProps) {
	const [proposedHistoryLimitDate, setProposedHistoryLimitDate] = useState<{
		date: Date;
		option: OptionValues;
	} | null>(null);

	const handleDateRangeSelectionChanged = useCallback(
		(dateRange: DateRange, selectedOption: OptionLabelValue) => {
			const { start } = dateRange;

			if (!start) {
				return;
			}

			const startDateMidnight: Date = new Date(start);
			startDateMidnight.setHours(0, 0, 0, 0);

			if (getDateDiffInDays(MIDNIGHT_TODAY, startDateMidnight) >= 30) {
				setProposedHistoryLimitDate({ date: startDateMidnight, option: selectedOption.value });
			} else {
				onChange(startDateMidnight);
			}
		},
		[onChange],
	);

	const handleDiscardJobHistoryLimitChange = useCallback(() => {
		setProposedHistoryLimitDate(null);
	}, []);

	const handleChangeJobHistoryLimit = useCallback(() => {
		if (proposedHistoryLimitDate) {
			onChange(proposedHistoryLimitDate.date);
		}
		setProposedHistoryLimitDate(null);
	}, [onChange, proposedHistoryLimitDate]);

	return (
		<>
			<DateRangeSelect
				options={options}
				selectedOption={selectedOption}
				title={title}
				onChange={handleDateRangeSelectionChanged}
			/>
			<OrderHistoryLimiterConfirmModal
				isOpen={!!proposedHistoryLimitDate}
				numOfDaysToRetrieve={
					proposedHistoryLimitDate
						? getDateDiffInDays(MIDNIGHT_TODAY, proposedHistoryLimitDate.date)
						: 0
				}
				onClose={handleDiscardJobHistoryLimitChange}
				onSubmit={handleChangeJobHistoryLimit}
			/>
		</>
	);
}
