import { Card, Badge, Button } from '@shopify/polaris';
import { Job } from '@sixriver/fulfillment-api-schema';

import { CardDetails } from 'components/CardDetails';
import { CopyButton } from 'components/CopyButton';
import { ResolutionJobsBanner } from 'components/ResolutionJobsBanner';
import { countLineExceptionsV2, isExceptionActionableV2 } from 'helpers/exception';
import { calculateUnits } from 'helpers/order';
import { useConfig } from 'hooks/useConfig';
import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';

const getParams = (jobs?: Array<Job>) => {
	const searchParams = new URLSearchParams();

	if (jobs) {
		searchParams.set('ids', jobs.map((job) => job.id).join(','));
	}

	return `?${searchParams.toString()}`;
};

interface Props {
	loading?: boolean;
	job?: Job;
}

export function PickingProgress({ loading, job }: Props) {
	const { messages, translate } = useLocalization();

	const { config } = useConfig();

	const { picked: unitsPicked, total: unitsTotal } = calculateUnits(job?.lines);
	const linesPicked = job?.lines?.reduce(
		(picked, line) => (line.doneQuantity === line.quantity ? (picked += 1) : picked),
		0,
	);

	const numberOfExceptions = countLineExceptionsV2(job?.lines);

	const servicingOrders = (job?.servicing || []).filter((servicing) => servicing.isOrder);
	if (job?.isOrder) {
		servicingOrders.push(job);
	}
	const servicingOrder = servicingOrders.length > 0 ? servicingOrders[0] : undefined;

	const exceptionOrders = servicingOrders.filter((order) => order.isShorted);

	const resolutionJobs =
		job?.isHealJob === false && numberOfExceptions > 0
			? (servicingOrder?.servicedBy || []).filter((j) => j.id !== job?.id && j.isHealJob)
			: [];
	const hasMultipleResolutionJobs = resolutionJobs.length > 1;
	const resolutionJob = resolutionJobs.length > 0 ? resolutionJobs[0] : undefined;

	const originalJob =
		job?.isHealJob === true
			? servicingOrder?.servicedBy?.find((j) => j.id !== job?.id && job.isShorted)
			: undefined;

	const primaryListItems = [
		{
			label: messages.unitsPicked,
			content: translate(messages.pickedOfTotal, {
				picked: unitsPicked,
				total: unitsTotal,
			}),
		},
		{
			label: messages.linesPicked,
			content: job?.lines
				? translate(messages.pickedOfTotal, {
						picked: linesPicked || 0,
						total: job.lines.length,
				  })
				: null,
		},
		{
			label: messages.exceptions,
			content: (
				<Badge status={numberOfExceptions > 0 ? 'critical' : undefined}>
					{numberOfExceptions.toString()}
				</Badge>
			),
		},
		...(hasMultipleResolutionJobs === true
			? [
					{
						label: messages.resolutionJobs,
						content: (
							<Button plain url={routes.outboundJobs() + `${getParams(resolutionJobs || [])}`}>
								{
									translate(messages.countJobs, {
										count: resolutionJobs?.length || 0,
									}) as string
								}
							</Button>
						),
					},
			  ]
			: []),
		...(hasMultipleResolutionJobs === false && resolutionJob?.id
			? [
					{
						label: messages.resolutionJob,
						content: (
							<Button plain url={routes.outboundJob(resolutionJob.id)}>
								{resolutionJob?.id}
							</Button>
						),
					},
			  ]
			: []),
		...(job?.isHealJob && originalJob
			? [
					{
						label: messages.originalJob,
						content: <CopyButton text={originalJob.id} />,
					},
			  ]
			: []),
		...(job?.isHealJob && job?.externalContainerId
			? [
					{
						label: messages.pickingLabel,
						content: <CopyButton text={job.externalContainerId} />,
					},
			  ]
			: []),
	];

	return (
		<Card
			sectioned
			title={messages.pickingProgress}
			primaryFooterAction={{
				content: translate(messages.viewOrders, { count: servicingOrders.length }) as string,
				disabled: !servicingOrders.length,
				url:
					servicingOrders.length === 1
						? routes.order(servicingOrders[0].id)
						: `${routes.orders()}${getParams(servicingOrders)}`,
			}}
			secondaryFooterActions={
				config?.healingEnabled
					? [
							{
								content: messages.manageExceptions,
								disabled: numberOfExceptions === 0 || !isExceptionActionableV2(job),
								url:
									exceptionOrders.length === 1
										? routes.exception(exceptionOrders[0].id)
										: `${routes.exceptions()}${getParams(exceptionOrders)}`,
							},
					  ]
					: []
			}
			footerActionAlignment="left"
		>
			{resolutionJobs.length > 0 && <ResolutionJobsBanner jobs={resolutionJobs} />}

			<br />
			<CardDetails
				loading={loading}
				primary={primaryListItems}
				secondary={[
					{
						label: messages.orders,
						content: servicingOrders.length,
					},
					{
						label: messages.orderId,
						content:
							servicingOrders.length > 1 ? (
								messages.multiple
							) : servicingOrders.length === 1 ? (
								<CopyButton text={servicingOrders[0].externalId} />
							) : null,
					},
				]}
			/>
		</Card>
	);
}
