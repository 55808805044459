import { Badge } from '@shopify/polaris';
import { SortationStatus } from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	sortationStatus?: SortationStatus;
	isEmptyIfNoData?: boolean;
}

export function SortationStatusBadge({ sortationStatus, isEmptyIfNoData }: Props) {
	const { messages } = useLocalization();

	return sortationStatus === SortationStatus.Unassigned ? (
		<Badge status="warning">{messages.unassigned}</Badge>
	) : sortationStatus === SortationStatus.Picking ||
	  sortationStatus === SortationStatus.Picked ||
	  sortationStatus === SortationStatus.Sorting ||
	  sortationStatus === SortationStatus.Exception ? (
		<Badge status="info">{messages.inProgress}</Badge>
	) : sortationStatus === SortationStatus.Sorted ? (
		<Badge>{messages.sorted}</Badge>
	) : sortationStatus === SortationStatus.Canceled ||
	  sortationStatus === SortationStatus.CanceledOrphan ? (
		<Badge>{messages.canceled}</Badge>
	) : !isEmptyIfNoData ? (
		<NoData />
	) : null;
}
