import { Card, Heading, Stack, TextContainer, TextStyle } from '@shopify/polaris';
import { Return, ReturnStatus } from '@sixriver/fulfillment-api-schema';

import styles from './Progress.module.css';
import { NoData } from 'components/NoData';
import { useLocalization } from 'hooks/useLocalization';

export function Progress({ inboundReturn }: { inboundReturn?: Return }) {
	const { messages, translate } = useLocalization();

	const uniqueIssues = [
		...Array.from(
			new Set(
				inboundReturn?.issues.map(
					(issue) =>
						messages.receivingIssueReason[
							issue.reason as unknown as keyof typeof messages['receivingIssueReason']
						],
				),
			),
		),
	];

	return (
		<Card>
			<div className={styles.progressContainer}>
				<div className={styles.unitsContainer}>
					<Stack vertical>
						<TextContainer>
							<TextStyle variation="subdued">{messages.received}</TextStyle>
						</TextContainer>
						<TextContainer spacing="loose">
							{inboundReturn &&
							inboundReturn?.totalActualUnitQuantity >= 0 &&
							inboundReturn?.totalExpectedUnitQuantity >= 0 ? (
								<Heading>
									{translate(messages.xOfYUnits, {
										x: inboundReturn?.totalActualUnitQuantity || 0,
										y: inboundReturn?.totalExpectedUnitQuantity || 0,
									})}
								</Heading>
							) : (
								<NoData />
							)}
						</TextContainer>
					</Stack>
				</div>
				<div className={styles.issuesContainer}>
					<Stack vertical>
						<TextContainer>
							<TextStyle variation="subdued">{messages.issues}</TextStyle>
						</TextContainer>
						{uniqueIssues.length > 0 ? (
							<TextStyle variation="warning">{uniqueIssues.join(', ')}</TextStyle>
						) : (
							<>
								{inboundReturn?.returnStatus === ReturnStatus.Completed ? (
									<p>{messages.noIssues}</p>
								) : (
									<NoData />
								)}
							</>
						)}
					</Stack>
				</div>
			</div>
		</Card>
	);
}
