import gql from 'graphql-tag';

export const PRODUCTS_QUERY = gql`
	query Products(
		$cursor: String
		$limit: Int
		$searchText: String
		$type: StorageLocationType
		$orderBy: ProductOrderByFields
		$orderByDirection: OrderByDirection
		$kinds: [String!]
	) {
		products(
			cursor: $cursor
			limit: $limit
			searchText: $searchText
			type: $type
			orderBy: $orderBy
			orderByDirection: $orderByDirection
			kinds: $kinds
		) {
			results {
				id
				customerIdentifier
				name
				description
				inventories {
					locationType
					address
					liveQuantity
					totalPlannedRemoveQuantity
					totalPlannedAddQuantity
					balance
				}
				isContainerType
			}
			cursor
		}
	}
`;

export const COUNTS_QUERY = gql`
	query ProductByLocationCounts($searchText: String) {
		productTypeCountsByLocationType(searchText: $searchText) {
			type
			count
		}
	}
`;
