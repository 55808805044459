import { Card } from '@shopify/polaris';
import { Lane } from '@sixriver/fulfillment-api-schema';

import { CardDetails } from 'components/CardDetails';
import { LaneCarrierAndLogo } from 'components/LaneCarrierLogo';
import { NoData } from 'components/NoData';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	lane: Lane;
}

export function LaneDetails({ lane }: Props) {
	const { messages } = useLocalization();
	const primary = [];

	primary.push(
		{
			label: messages.carrier,
			content: <LaneCarrierAndLogo carrier={lane.carrier} />,
		},
		{
			label: messages.carrierSort,
			content: lane.carrierSort,
		},
		{
			label: messages.mailClass,
			content: lane.carrierClassMapping ? (
				lane.carrierClassMapping.map((mailClass) => mailClass.mailClasses).join(', ')
			) : (
				<NoData />
			),
		},
		{
			label: messages.containerQty,
			content: lane.containers ? lane.containers.length : 0,
		},
		{
			label: messages.lanePackageQty,
			content: lane.containers.reduce(
				(counter, { packages }) => (counter += packages?.length || 0),
				0,
			),
		},
	);

	return (
		<Card title={`${lane.laneId} ${messages.details.toLowerCase()}`} sectioned>
			<CardDetails primary={primary} />
		</Card>
	);
}
