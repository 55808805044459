import { TextStyle } from '@shopify/polaris';

import { MILLISECONDS_PER_HOUR } from 'helpers/time';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	timeInMs: number;
}

export function TimeLogged({ timeInMs }: Props): JSX.Element {
	const { messages, translate, formatNumber } = useLocalization();

	const decimalHours = formatNumber(timeInMs / MILLISECONDS_PER_HOUR, 2);

	return <TextStyle>{translate(messages.durationInHours, { decimalHours })}</TextStyle>;
}
