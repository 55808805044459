type AnyStringWithSuggestions<T> = T | (string & {});

type CookieNames = AnyStringWithSuggestions<
	'6RS-JWT-REFRESH' | '6RS-JWT' | 'SSO-JWT' | 'SSO-JWT-REFRESH'
>;

// source: https://stackoverflow.com/a/25490531
export const getCookieValue = (name: CookieNames) =>
	RegExp('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')
		.exec(document.cookie)
		?.pop() || '';
