import { IndexTable, Stack } from '@shopify/polaris';
import { LineV2 } from '@sixriver/fulfillment-api-schema';

import { OrderQuantity } from 'components/OrderQuantity';

interface QuantityCellProps {
	lines: LineV2[];
}

export function QuantityCell({ lines }: QuantityCellProps) {
	return (
		<IndexTable.Cell>
			<Stack vertical spacing="extraTight">
				<OrderQuantity lines={lines} />
			</Stack>
		</IndexTable.Cell>
	);
}
