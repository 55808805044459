import { IndexTable } from '@shopify/polaris';
import { TaskV2 } from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';

interface Props {
	tasks: TaskV2[];
}

export function WorkAreaCell({ tasks }: Props) {
	const uniqueWorkAreas = tasks.reduce((workAreas, task) => {
		if (task.workArea?.name) {
			workAreas.add(task.workArea.name);
		}
		return workAreas;
	}, new Set<string>());

	return (
		<IndexTable.Cell>
			{uniqueWorkAreas.size > 0 ? (
				<div>
					{Array.from(uniqueWorkAreas)
						.map((areaName) => areaName)
						.join(', ')}
				</div>
			) : (
				<NoData />
			)}
		</IndexTable.Cell>
	);
}
