import { Popover, Button, ChoiceList } from '@shopify/polaris';
import { SortMinor } from '@shopify/polaris-icons';
import { useState, ReactNode } from 'react';

import styles from './SortBy.module.css';
import { useLocalization } from 'hooks/useLocalization';

export interface SortChoice {
	label: ReactNode;
	value: string;
}

interface Props {
	choices: SortChoice[];
	selected: string[];
	allowMultiple?: boolean;
	onChange: (selected: string[]) => void;
}

export function SortBy({ choices, allowMultiple, selected, onChange }: Props) {
	const { messages } = useLocalization();

	const [popoverActive, setPopoverActive] = useState(false);
	const togglePopoverActive = () => setPopoverActive((popoverActive) => !popoverActive);

	return (
		<Popover
			preferredAlignment="right"
			active={popoverActive}
			activator={
				<div className={styles.ButtonContainer}>
					<Button onClick={togglePopoverActive} icon={SortMinor}>
						{messages.sort}
					</Button>
				</div>
			}
			onClose={togglePopoverActive}
		>
			<div className={styles.ChoiceListContainer}>
				<ChoiceList
					title={messages.sort}
					titleHidden
					choices={choices}
					allowMultiple={allowMultiple}
					selected={selected}
					onChange={(selected) => {
						onChange(selected);
					}}
				/>
			</div>
		</Popover>
	);
}
