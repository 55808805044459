import { Card, Stack, TextContainer, TextStyle } from '@shopify/polaris';
import { ReworkJob } from '@sixriver/fulfillment-api-schema';

import { DateTime } from './DateTime';
import styles from './Rework.module.css';
import { useLocalization } from 'hooks/useLocalization';

export function Details({ reworkJob }: { reworkJob?: ReworkJob }) {
	const { messages } = useLocalization();

	return (
		<Card title={messages.reworkJobDetails}>
			<Card.Section>
				<Stack vertical>
					<Stack.Item>
						<Stack>
							<div className={styles.detailsLabels}>
								<TextContainer>
									<TextStyle>{messages.reworkJobId}</TextStyle>
								</TextContainer>
							</div>
							<div>
								<TextContainer>
									<p>{reworkJob?.id}</p>
								</TextContainer>
							</div>
						</Stack>
					</Stack.Item>
					<Stack.Item>
						<Stack>
							<div className={styles.detailsLabels}>
								<TextContainer>
									<TextStyle>{messages.jobCreated}</TextStyle>
								</TextContainer>
							</div>
							<div>
								<DateTime date={reworkJob?.createdAt} />
							</div>
						</Stack>
					</Stack.Item>
				</Stack>
			</Card.Section>
		</Card>
	);
}
