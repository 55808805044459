import { Card, DataTable, Icon, Link, Stack, TextStyle, Thumbnail } from '@shopify/polaris';
import { DiamondAlertMajor } from '@shopify/polaris-icons';
import {
	InventoryState,
	Product,
	ProductInventory,
	StorageLocation,
	StorageLocationType,
} from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';
import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';
import { transformImageUrl } from 'utils/image';

interface Props {
	location: StorageLocation;
}

export function LocationInventory({ location }: Props) {
	const { messages, formatNumber } = useLocalization();

	const iconStyle = {
		display: 'inline-block',
		transform: 'scale(0.75)',
		verticalAlign: 'bottom',
		marginLeft: '5px',
	};

	const row = ({ product, inventory }: ProductInventory) => {
		const liveCount = inventory.liveQuantity;
		const reservedCount = Math.abs(inventory.totalPlannedRemoveQuantity);
		const availableCount = liveCount - reservedCount;

		return [
			<Thumbnail key="image" source={transformImageUrl(product.image)} alt={product.description} />,

			<div style={{ whiteSpace: 'pre-wrap' }} key="inventory">
				<Stack wrap vertical={true} spacing="extraTight" distribution="leading">
					<Stack.Item>
						<Link url={routes.product(product.id)}>
							<TextStyle variation="strong">{product.name}</TextStyle>
						</Link>
					</Stack.Item>
					<Stack.Item>
						{messages.identifiers + ': '}
						<TextStyle variation="strong">{product.scanValues?.join(', ') || <NoData />}</TextStyle>
					</Stack.Item>
					<Stack.Item>
						<StorageLocationInventoryRemaining
							location={location}
							product={product}
							inventory={inventory}
						/>
					</Stack.Item>
				</Stack>
			</div>,

			<Stack key="quantity" vertical={true} spacing="extraTight" distribution="leading">
				<Stack.Item>
					{location.type === StorageLocationType.Staging ? null : messages.total + ': '}
					{formatNumber(liveCount)}
					{inventory.conflicted ? (
						<span style={iconStyle}>
							<Icon color="critical" source={DiamondAlertMajor} />
						</span>
					) : null}
				</Stack.Item>
				{location.type === StorageLocationType.Staging
					? null
					: [
							<Stack.Item key="a">
								{messages.reserved + ': '}
								{formatNumber(reservedCount)}
							</Stack.Item>,
							<Stack.Item key="b">
								{messages.available + ': '}
								{formatNumber(availableCount)}
							</Stack.Item>,
					  ]}
			</Stack>,
		];
	};

	const rows = location.products.map(row);

	return (
		<Card>
			<DataTable
				headings={[
					'', // Image
					messages.inventory,
					messages.quantity,
				]}
				columnContentTypes={['text', 'text']}
				rows={rows}
			/>
		</Card>
	);
}

interface StorageLocationInventoryRemainingProps {
	location: StorageLocation;
	product: Product;
	inventory: InventoryState;
}

function StorageLocationInventoryRemaining({
	location,
	product,
	inventory,
}: StorageLocationInventoryRemainingProps) {
	const { messages, translate, formatNumber } = useLocalization();

	const containerVolume = location.containerType?.volume || 0;
	const maxFill = containerVolume / product.volume;
	const remainingFill = Math.max(0, maxFill - inventory.liveQuantity);

	if (
		location.type === StorageLocationType.Staging ||
		Number.isNaN(maxFill) ||
		!Number.isFinite(maxFill) ||
		Number.isNaN(remainingFill) ||
		!Number.isFinite(remainingFill)
	) {
		return null;
	}

	const totalUnits = Math.floor(maxFill || 0);
	const remainingUnits = Math.floor(remainingFill || 0);

	return (
		<TextStyle variation="subdued">
			{`${messages.totalCapacity}: ${translate(
				messages.countUnits,
				{ count: formatNumber(totalUnits) },
				totalUnits,
			)}`}
			<br />
			{`${messages.remainingCapacity}: ${translate(
				messages.countUnits,
				{
					count: formatNumber(remainingUnits),
				},
				remainingUnits,
			)}`}
		</TextStyle>
	);
}
