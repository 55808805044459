import { AppliedFilterInterface, FilterInterface, Filters } from '@shopify/polaris';
import { LaneStatus, OrderByDirection } from '@sixriver/fulfillment-api-schema';
import { isEmpty } from 'lodash';

import { LaneCarrierSortsFilter } from './LaneCarrierSortsFilter';
import { LaneCarriersFilter } from './LaneCarriersFilter';
import { LaneStatusesFilter } from './LaneStatusesFilter';
import { SortBy, SortChoice } from 'components/SortBy';
import { noop } from 'helpers/noop';
import { useLocalization } from 'hooks/useLocalization';

export enum LaneOrderByFields {
	Status = 'status',
}

export interface LaneOrderBy {
	direction: OrderByDirection;
	field: LaneOrderByFields;
}

function convertToLaneOrderBy(sortStrings: string[]): LaneOrderBy[] {
	return sortStrings.map((option) => {
		const [field, direction] = option.split(' ') as [LaneOrderByFields, OrderByDirection];
		return { field, direction };
	});
}

const STATUSES_FILTER_KEY = 'statuses';
const CARRIERS_FILTER_KEY = 'carriers';
const CARRIER_SORTS_FILTER_KEY = 'carrierSorts';
const IDS_KEY = 'ids';

interface LanesFiltersProps {
	queryValue?: string;
	onQueryChange?: (value: string) => void;
	onClearAll?: () => void;
	selectedSort?: LaneOrderBy[];
	onSortChange?: (value: LaneOrderBy[]) => void;
	selectedStatuses: LaneStatus[];
	onStatusChange: (selected: LaneStatus[]) => void;
	onStatusRemove: () => void;
	availableCarriers: string[];
	selectedCarriers: string[];
	onCarrierChange: (selected: string[]) => void;
	onCarrierRemove: () => void;
	availableCarrierSorts: string[];
	selectedCarrierSorts: string[];
	onCarrierSortChange: (selected: string[]) => void;
	onCarrierSortRemove: () => void;
	onIdsRemove: () => void;
	ids?: string[];
}

export function LanesFilters({
	queryValue,
	onQueryChange = noop,
	onClearAll = noop,
	selectedSort = [],
	onSortChange = noop,
	selectedStatuses,
	onStatusChange,
	onStatusRemove,
	availableCarriers,
	selectedCarriers,
	onCarrierChange,
	onCarrierRemove,
	availableCarrierSorts,
	selectedCarrierSorts,
	onCarrierSortChange,
	onCarrierSortRemove,
	onIdsRemove = noop,
	ids = [],
}: LanesFiltersProps) {
	const { messages } = useLocalization();

	/**
	 * Sort choices
	 */
	const sortChoices: SortChoice[] = [
		{
			label: messages.sortOptions.laneStatusAsc,
			value: `${LaneOrderByFields.Status} ${OrderByDirection.Asc}`,
		},
		{
			label: messages.sortOptions.laneStatusDesc,
			value: `${LaneOrderByFields.Status} ${OrderByDirection.Desc}`,
		},
	];

	/**
	 * Filters
	 */

	const filters: FilterInterface[] = [
		{
			key: STATUSES_FILTER_KEY,
			label: messages.laneStatus,
			filter: <LaneStatusesFilter selected={selectedStatuses} onChange={onStatusChange} />,
			shortcut: true,
		},
		{
			key: CARRIERS_FILTER_KEY,
			label: messages.carrier,
			filter: (
				<LaneCarriersFilter
					selected={selectedCarriers}
					onChange={onCarrierChange}
					options={availableCarriers}
				/>
			),
			shortcut: true,
		},
		{
			key: CARRIER_SORTS_FILTER_KEY,
			label: messages.carrierSort,
			filter: (
				<LaneCarrierSortsFilter
					selected={selectedCarrierSorts}
					onChange={onCarrierSortChange}
					options={availableCarrierSorts}
				/>
			),
			shortcut: true,
		},
	];

	const appliedFilters: AppliedFilterInterface[] = [];

	if (!isEmpty(selectedStatuses)) {
		appliedFilters.push({
			key: STATUSES_FILTER_KEY,
			label: selectedStatuses
				.map((status) => {
					switch (status) {
						case LaneStatus.Active:
							return messages.active;
						case LaneStatus.WaitingForContainers:
							return messages.waitingForContainers;
						default:
							return messages.unknown;
					}
				})
				.join(', '),
			onRemove: onStatusRemove,
		});
	}

	if (!isEmpty(selectedCarriers)) {
		appliedFilters.push({
			key: CARRIERS_FILTER_KEY,
			label: selectedCarriers.map((carrier) => carrier).join(', '),
			onRemove: onCarrierRemove,
		});
	}

	if (!isEmpty(selectedCarrierSorts)) {
		appliedFilters.push({
			key: CARRIER_SORTS_FILTER_KEY,
			label: selectedCarrierSorts.map((carrrierSort) => carrrierSort).join(', '),
			onRemove: onCarrierSortRemove,
		});
	}

	if (!isEmpty(ids)) {
		appliedFilters.push({
			key: IDS_KEY,
			label: ids.join(', '),
			onRemove: onIdsRemove,
		});
	}

	return (
		<Filters
			queryValue={queryValue}
			queryPlaceholder={messages.filterLanes}
			onQueryChange={onQueryChange}
			onQueryClear={() => onQueryChange('')}
			filters={filters}
			appliedFilters={appliedFilters}
			onClearAll={onClearAll}
		>
			<SortBy
				choices={sortChoices}
				selected={selectedSort.map((sort) => `${sort.field} ${sort.direction}`)}
				onChange={(selected) => onSortChange(convertToLaneOrderBy(selected))}
			/>
		</Filters>
	);
}
