import { Badge, Tooltip, Icon, Stack } from '@shopify/polaris';
import { AlertMinor } from '@shopify/polaris-icons';
import { OrderV2, OrderV2Status } from '@sixriver/fulfillment-api-schema';

import { useLocalization } from 'hooks/useLocalization';

interface Props {
	order?: OrderV2;
}

export function OrderStatusBadgeV2({ order }: Props): JSX.Element | null {
	const { messages } = useLocalization();

	if (!order) {
		return null;
	}

	let badge = <Badge status="warning">{messages.unassigned}</Badge>;

	if (order.status === OrderV2Status.AssignedToBatch) {
		badge = <Badge status="info">{messages.assignedToBatch}</Badge>;
	}

	if (order.status === OrderV2Status.AssignedToSortWall) {
		badge = <Badge status="info">{messages.assignedToWall}</Badge>;
	}

	if (order.status === OrderV2Status.InProgress) {
		badge = <Badge status="info">{messages.inProgress}</Badge>;
	}

	if (order.status === OrderV2Status.Interrupted) {
		badge = <Badge>{messages.interrupted}</Badge>;
	}

	if (order.status === OrderV2Status.Completed) {
		badge = <Badge>{messages.completed}</Badge>;
	}

	if (order.status === OrderV2Status.Canceled) {
		badge = <Badge>{messages.canceled}</Badge>;
	}

	if (order.status === OrderV2Status.Unknown) {
		badge = <Badge status="warning">{messages.unknown}</Badge>;
	}

	let icon;

	if (order.isAwaitingInventory) {
		icon = (
			<Tooltip content={messages.orderAwaitingInventory} dismissOnMouseOut>
				<Icon source={AlertMinor} color="warning" />
			</Tooltip>
		);
	}

	if (order.isShorted) {
		icon = (
			<Tooltip content={messages.orderHasExceptions} dismissOnMouseOut>
				<Icon source={AlertMinor} color="critical" />
			</Tooltip>
		);
	}

	return (
		<Stack spacing="extraTight" wrap={false}>
			{badge}
			{icon}
		</Stack>
	);
}
