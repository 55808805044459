import { ChoiceList } from '@shopify/polaris';
import { WorkOrderType } from '@sixriver/fulfillment-api-schema';

import { noop } from 'helpers/noop';
import { useLocalization } from 'hooks/useLocalization';

interface WorkOrderTypeFilterProps {
	selected?: WorkOrderType[];
	onChange?: (selected: WorkOrderType[], name: string) => void;
}

export function WorkOrderTypesFilter({ selected = [], onChange = noop }: WorkOrderTypeFilterProps) {
	const { messages } = useLocalization();

	return (
		<ChoiceList
			title={messages.workOrderType}
			titleHidden
			choices={[
				{
					label: messages.workOrderTypes.transfer,
					value: WorkOrderType.InventoryTransferToNode,
				},
				{
					label: messages.workOrderTypes.ecommerce,
					value: WorkOrderType.Ecommerce,
				},
			]}
			selected={selected}
			onChange={onChange}
		/>
	);
}
