import { Badge, Stack } from '@shopify/polaris';
import { Lane, LaneStatus } from '@sixriver/fulfillment-api-schema';

import { useLocalization } from 'hooks/useLocalization';

interface Props {
	lane?: Lane;
}

export function LaneStatusBadge({ lane }: Props): JSX.Element | null {
	const { messages } = useLocalization();

	if (!lane) {
		return null;
	}

	let badge = <Badge>{messages.waitingForContainers}</Badge>;

	if (lane.status === LaneStatus.Active) {
		badge = <Badge status="info">{messages.active}</Badge>;
	}

	if (lane.status === LaneStatus.WaitingForContainers) {
		badge = <Badge>{messages.waitingForContainers}</Badge>;
	}

	return <Stack spacing="extraTight">{badge}</Stack>;
}
