import { Page, Layout, Tabs, Stack, Frame, Loading } from '@shopify/polaris';
import { DuplicateMinor } from '@shopify/polaris-icons';
import {
	Job,
	MutationCancelJobArgs,
	QueryJobEventsV2Args,
	WorkflowEvent,
	WorkOrderType,
} from '@sixriver/fulfillment-api-schema';
import { useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useMutation } from 'urql';

import { CANCEL_JOB_MUTATION } from './CancelJob.graphql';
import { CancelJobModal } from './CancelJobModal';
import { JOB_EVENTS_QUERY } from './JobEvents.graphql';
import { JOB_QUERY } from './OutboundJob.graphql';
import { PackingDetails } from './PackingDetails';
import { PickingDetails } from './PickingDetails';
import { PickingProgress } from './PickingProgress';
import { getRelatedJobs } from './RelatedJobs';
import { SortationDetails } from './SortationDetails';
import { TransferDetails } from './TransferDetails';
import { AutoRefresh } from 'components/AutoRefresh';
import { Error } from 'components/Error';
import { ErrorBanner } from 'components/ErrorBanner';
import { JobExceptionBadge } from 'components/JobExceptionBadge';
import { Lines } from 'components/Lines';
import { LinesTableColumn } from 'components/Lines/LinesTable';
import { LinesTab } from 'components/Lines/LinesTabs';
import { OutboundJobStatusBadgeV2 } from 'components/OutboundJobStatusBadge/OutboundJobStatusBadgeV2';
import { Timeline } from 'components/Timeline';
import { useWorkflowEvents } from 'components/Timeline/Timeline.hooks';
import { TimezoneFooter } from 'components/TimezoneFooter';
import { useAuth } from 'hooks/useAuth';
import { useConfig } from 'hooks/useConfig';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import { useFilters, useSetFilters } from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';
import { usePolling } from 'hooks/usePolling';
import { usePollingQuery } from 'hooks/usePollingQuery';
import { UserRole } from 'providers/AuthProvider';
import * as routes from 'routes';

export interface RouteMatchParams {
	outboundJobId: string;
}

export function OutboundJob() {
	const {
		params: { outboundJobId },
	} = useRouteMatch<RouteMatchParams>();
	const { messages } = useLocalization();
	const { isUserAllowed } = useAuth();
	const history = useHistory();
	const { config } = useConfig();
	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();
	const { copyToClipboard } = useCopyToClipboard();

	const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
	const [isBannerErrorVisible, setIsBannerErrorVisible] = useState(false);

	const { tab } = useFilters(['tab']);
	const setFilters = useSetFilters();
	const tabs = [
		{
			id: 'overview',
			content: messages.overview,
		},
		{
			id: 'history',
			content: messages.history,
		},
	];
	const tabIndex = tab === tabs[1].id ? 1 : 0;

	const [{ fetching: jobFetching, error: jobError, data: jobData }] = usePollingQuery<{
		jobV2: Job;
	}>({
		query: JOB_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			id: outboundJobId,
		},
	});

	const [{ fetching: eventsFetching, error: eventsError, data: eventsData }] = usePollingQuery<
		{ jobEventsV2: WorkflowEvent[] },
		QueryJobEventsV2Args
	>({
		query: JOB_EVENTS_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			id: outboundJobId,
		},
	});

	const [{ fetching: cancelLoading }, cancelMutation] = useMutation<
		{ cancelJob: Response },
		MutationCancelJobArgs
	>(CANCEL_JOB_MUTATION);

	const fetching = jobFetching || eventsFetching;
	const error = jobError || eventsError;
	const job = jobData?.jobV2;
	const sortWall = job?.servicingSorations[0];
	const isPackingMode = config?.packoutEnabled && !!job?.packout;
	const isSortationMode = config?.sortationEnabled && !!sortWall;

	const relatedJobs: Job[] = getRelatedJobs(job);

	const events = useWorkflowEvents(eventsData?.jobEventsV2 || [], 'JOB');

	const closeCancelModal = () => {
		setIsCancelModalOpen(false);
	};

	const openCancelModal = () => {
		setIsCancelModalOpen(true);
	};

	const cancelJob = async () => {
		setIsBannerErrorVisible(false);

		try {
			closeCancelModal();

			const { error } = await cancelMutation({ id: outboundJobId });
			if (error) {
				throw error;
			}

			history.push(routes.outboundJobs());
		} catch (e) {
			setIsBannerErrorVisible(true);
		}
	};

	if (fetching) {
		return (
			<Frame>
				<Loading />
			</Frame>
		);
	}
	if (error) {
		return <Error graphQLError={error} />;
	}

	if (!job) {
		return <Error heading={messages.outboundJobNotFound} />;
	}

	return (
		<>
			<Page
				title={job.externalContainerId || job.id}
				titleMetadata={
					<Stack spacing="extraTight">
						<OutboundJobStatusBadgeV2 job={job} />
						<JobExceptionBadge job={job} />
					</Stack>
				}
				secondaryActions={[
					{
						content: messages.copyLicensePlate,
						icon: DuplicateMinor,
						onAction: job.externalContainerId
							? () => copyToClipboard(job.externalContainerId)
							: undefined,
						disabled: !job.externalContainerId,
					},
					{
						content: messages.cancelJob,
						onAction: openCancelModal,
						disabled:
							!isUserAllowed([UserRole.Admin, UserRole.WarehouseManager]) || !job.isCancelable,
					},
				]}
			>
				<AutoRefresh
					pollingEnabled={pollingEnabled}
					togglePolling={togglePolling}
					discriminatorData={jobData}
				/>
				<Tabs
					tabs={tabs}
					selected={tabIndex}
					onSelect={(tabIndex) => {
						setFilters([{ key: 'tab', value: tabs[tabIndex].id }]);
					}}
				>
					<Layout>
						<Layout.Section>
							<ErrorBanner
								isVisible={isBannerErrorVisible}
								onDismiss={() => {
									setIsBannerErrorVisible(false);
								}}
							/>
						</Layout.Section>
						{tab === 'history' ? (
							<Layout.Section>
								<Timeline
									title={messages.outboundJobHistory}
									events={events}
									loading={eventsFetching}
									error={eventsError}
								/>
							</Layout.Section>
						) : (
							<>
								{job.workOrderTypes?.includes(WorkOrderType.InventoryTransferToNode) && (
									<Layout.Section>
										<TransferDetails loading={fetching} job={job} />
									</Layout.Section>
								)}
								<Layout.Section>
									<>
										{isPackingMode ? (
											<PackingDetails
												loading={fetching}
												job={job}
												relatedJobs={relatedJobs}
												isHealingEnabled={config?.healingEnabled}
												cutoff={job.expectedShipDate}
											/>
										) : null}
										<PickingDetails
											loading={fetching}
											job={job}
											relatedJobs={relatedJobs}
											isPackoutEnabled={config?.packoutEnabled}
											isWorkAreaEnabled={config?.workAreasEnabled}
											condensed={isPackingMode}
											sortWall={sortWall}
										/>
									</>
								</Layout.Section>
								<Layout.Section>
									{isSortationMode ? (
										<SortationDetails loading={fetching} sortWall={sortWall} lines={job.lines} />
									) : isPackingMode ? null : (
										<PickingProgress loading={fetching} job={job} />
									)}
								</Layout.Section>
								<Layout.Section>
									{isPackingMode ? (
										<Lines
											job={job}
											columns={[
												LinesTableColumn.id,
												LinesTableColumn.name,
												LinesTableColumn.packingUnits,
											]}
										/>
									) : (
										<Lines
											job={job}
											tabs={[LinesTab.all, LinesTab.exceptions]}
											columns={[
												LinesTableColumn.id,
												LinesTableColumn.name,
												LinesTableColumn.pickingUnits,
												LinesTableColumn.location,
												LinesTableColumn.associate,
												LinesTableColumn.exceptions,
											]}
										/>
									)}
								</Layout.Section>
							</>
						)}
						<Layout.Section>
							<TimezoneFooter />
						</Layout.Section>
					</Layout>
				</Tabs>
			</Page>
			<CancelJobModal
				isOpen={isCancelModalOpen}
				onClose={closeCancelModal}
				onCancelJob={cancelJob}
				loading={cancelLoading}
			/>
		</>
	);
}
