import { useEffect } from 'react';
import { useQuery, UseQueryArgs, UseQueryResponse } from 'urql';

interface UsePollingQueryArgs<TVariables, TData> extends UseQueryArgs<TVariables, TData> {
	pollInterval: number;
}

export function usePollingQuery<TData = any, TVariables = object>(
	args: UsePollingQueryArgs<TVariables, TData>,
): UseQueryResponse<TData, TVariables> {
	const query = useQuery(args);
	const [result, executeQuery] = query;

	useEffect(() => {
		if (args.pollInterval > 0 && args.pause !== true && !result.fetching && !result.stale) {
			const id = setTimeout(() => executeQuery(), args.pollInterval);
			return () => clearTimeout(id);
		}
		return;
	}, [args.pause, args.pollInterval, executeQuery, result.fetching, result.stale]);

	return query;
}
