import { Card } from '@shopify/polaris';
import { Sortation, SortationStatus } from '@sixriver/fulfillment-api-schema';

import { CardDetails } from 'components/CardDetails';
import { CopyButton } from 'components/CopyButton';
import { DateTime } from 'components/DateTime';
import { NoData } from 'components/NoData';
import { RelativeDateTime } from 'components/RelativeDateTime';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	loading?: boolean;
	sortWall?: Sortation;
}

export function SortWallDetails({ loading, sortWall }: Props) {
	const { messages } = useLocalization();

	return (
		<Card title={messages.details}>
			<Card.Section>
				<CardDetails
					loading={loading}
					primary={[
						{
							label: messages.batchId,
							content: sortWall?.batchId || <NoData />,
						},
						{
							label: messages.assignedKiosk,
							content: sortWall?.kiosk || <NoData />,
						},
						{
							label: messages.takeoffLocation,
							content: sortWall?.takeoffDestination || <NoData />,
						},
						{
							label: messages.expectedShipDate,
							content: sortWall?.expectedShipDate ? (
								<>
									<DateTime date={sortWall?.expectedShipDate} />
									<RelativeDateTime
										date={sortWall?.expectedShipDate}
										mode={sortWall.status === SortationStatus.Sorted ? undefined : 'deadline'}
									/>
								</>
							) : (
								<NoData />
							),
						},
					]}
					secondary={[
						{
							label: messages.createdAt,
							content: (
								<>
									<DateTime date={sortWall?.timeline?.createdAt} />
									<RelativeDateTime date={sortWall?.timeline?.createdAt} />
								</>
							),
						},
						{
							label: messages.updatedAt,
							content: <DateTime date={sortWall?.timeline?.updatedAt} />,
						},
						{
							label: messages.completedAt,
							content: <DateTime date={sortWall?.timeline?.completedAt} />,
						},
					]}
				/>
			</Card.Section>
			<Card.Section>
				<CardDetails
					loading={loading}
					primary={[
						{
							label: messages.sortWallId,
							content: sortWall?.id ? <CopyButton text={sortWall.id} /> : null,
						},
					]}
				/>
			</Card.Section>
		</Card>
	);
}
