import { SortationStatus } from '@sixriver/fulfillment-api-schema';

export enum SortWallsViews {
	All = 'All',
	Unassigned = 'Unassigned',
	Picking = 'Picking',
	ReadyToSort = 'ReadyToSort',
	Sorting = 'Sorting',
	CompletedToday = 'CompletedToday',
	Exceptions = 'Exceptions',
}

export const SortWallsViewStateMap: { [key in SortWallsViews]: Array<SortationStatus> } = {
	[SortWallsViews.All]: [
		SortationStatus.Unassigned,
		SortationStatus.Picking,
		SortationStatus.Picked,
		SortationStatus.Sorting,
		SortationStatus.Sorted,
		SortationStatus.Exception,
		SortationStatus.Canceled,
		SortationStatus.CanceledOrphan,
	],
	[SortWallsViews.Unassigned]: [SortationStatus.Unassigned],
	[SortWallsViews.Picking]: [SortationStatus.Picking],
	[SortWallsViews.ReadyToSort]: [SortationStatus.Picked],
	[SortWallsViews.Sorting]: [SortationStatus.Sorting],
	[SortWallsViews.CompletedToday]: [SortationStatus.Sorted],
	[SortWallsViews.Exceptions]: [SortationStatus.Exception],
};
