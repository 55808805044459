import { Card, TextStyle } from '@shopify/polaris';
import { Customer, OrderV2Status, OrderV2, WorkArea } from '@sixriver/fulfillment-api-schema';

import { CardDetails } from 'components/CardDetails';
import { DateTime } from 'components/DateTime';
import { NoData } from 'components/NoData';
import { RelativeDateTime } from 'components/RelativeDateTime';
import { useConfig } from 'hooks/useConfig';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	order: OrderV2;
}

export function Details({ order }: Props) {
	const { messages } = useLocalization();
	const { config } = useConfig();
	const isInventoryEnabled = config?.inventoryEnabled;
	const isWorkAreaEnabled = config?.workAreasEnabled;
	const pickedAt = order.tasks.find((task) => task.type === 'batchPick')?.completedAt ?? null;
	const customers = order.lines.reduce((customers, line) => {
		if (line.customer.name) {
			return customers.add(line.customer);
		}
		return customers;
	}, new Set<Customer>());

	const workAreas =
		order.tasks.reduce((workAreas, task) => {
			if (task.workArea) {
				return workAreas.add(task.workArea);
			}

			return workAreas;
		}, new Set<WorkArea>()) ?? new Set<WorkArea>();

	const primary = [];

	if (isInventoryEnabled) {
		primary.push(
			{
				label: messages.store,
				content: customers?.size ? (
					<>
						{Array.from(customers)
							.map((customer) => customer.name)
							.join(', ')}
					</>
				) : (
					<NoData />
				),
			},
			{
				label: messages.workOrderType,
				content: order?.workOrderTypes?.length ? (
					<TextStyle>{order.workOrderTypes[0]}</TextStyle>
				) : (
					<NoData />
				),
			},
			{
				label: messages.destination,
				content: order?.destinationNode || <NoData />,
			},
		);
	}

	primary.push(
		{
			label: messages.received,
			content: (
				<>
					<DateTime date={order.createdAt} />
					<RelativeDateTime date={order.createdAt} />
				</>
			),
		},
		{
			label: messages.carrierCutoff,
			content: (
				<>
					<DateTime date={order.expectedShipDate} />
					<RelativeDateTime
						date={order.expectedShipDate}
						mode={order.status === OrderV2Status.Completed ? undefined : 'deadline'}
					/>
				</>
			),
		},
	);

	if (isWorkAreaEnabled) {
		primary.push({
			label: messages.workArea,
			content: (
				<>
					{Array.from(workAreas)
						.map((workArea) => workArea.name)
						.join(', ')}
				</>
			),
		});
	}

	const secondary = [
		{
			label: messages.updatedAt,
			content: <DateTime date={order.updatedAt} />,
		},
		{
			label: messages.pickingCompletedAt,
			content: <DateTime date={pickedAt} />,
		},
	];

	if (order.isPackoutRequired) {
		secondary.push({
			label: messages.packoutCompletedAt,
			content: <DateTime date={order.packout?.timeline.finishedAt} />,
		});
	}

	return (
		<Card title={messages.details} sectioned>
			<CardDetails primary={primary} secondary={secondary} />
		</Card>
	);
}
