import gql from 'graphql-tag';

import { PAGE_INFO_FRAGMENT } from 'graphql/page-info';

export const LANE_CONTAINERS_ORDERS_QUERY = gql`
	query Orders(
		$after: String
		$first: Int
		$searchText: String
		$orderBy: OrderOrderByFields
		$orderByDirection: OrderByDirection
		$projectionIds: [String!]
		$containerId: String!
		$statuses: [OrderState!]!
	) {
		getOrdersByContainerId(
			after: $after
			first: $first
			searchText: $searchText
			orderBy: $orderBy
			orderByDirection: $orderByDirection
			projectionIds: $projectionIds
			containerId: $containerId
			statuses: $statuses
		) {
			edges {
				node {
					id
					jobExternalId
					customerIdentifier
					status
					trackingNumbers
				}
			}
			pageInfo {
				...PageInfo
			}
		}
	}
	${PAGE_INFO_FRAGMENT}
`;
