import { Layout, Page } from '@shopify/polaris';
import { useDebouncedValue } from '@shopify/react-hooks';
import { Count, ReworkJobsSummaryPage } from '@sixriver/fulfillment-api-schema';
import { useState } from 'react';
import { useQuery } from 'urql';

import { ReworkJobsViews, ReworkJobsViewsViewStateMap } from './ReworkJobViews';
import { REWORK_JOBS_QUERY, REWORK_COUNTS_QUERY } from './ReworkJobs.graphql';
import { ReworkJobsTable } from './ReworkJobsTable';
import { DateRange, DateRangeSelect, OptionValues } from 'components/DateRangeSelect';
import { Divider } from 'components/Divider';
import { Error } from 'components/Error';
import { TimezoneFooter } from 'components/TimezoneFooter';
import { getPageSize } from 'helpers/page-size';
import { MIN_QUERY_LENGTH } from 'helpers/table';
import { getMidnight } from 'helpers/time';
import { useFilters, useSetFilters } from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';

const JOB_CREATED_AT_FROM_KEY = 'jobCreatedAtFrom';

const defaultDate = getMidnight(-30);
const dateRangeOptions = [
	OptionValues.last30Days,
	OptionValues.last60Days,
	OptionValues.last90Days,
	OptionValues.allTime,
];

export function ReworkJobs() {
	const { messages } = useLocalization();
	const defaultView = ReworkJobsViews.All;

	// Pagination
	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);

	// filtring logic
	const {
		query,
		view = defaultView,
		[JOB_CREATED_AT_FROM_KEY]: jobCreatedAtFromParam,
	} = useFilters([JOB_CREATED_AT_FROM_KEY]);
	const setFilters = useSetFilters();

	const searchText = useDebouncedValue(query) || '';
	const actualSearchText = searchText.length >= MIN_QUERY_LENGTH ? searchText : undefined;
	const historyDateLimiter = jobCreatedAtFromParam ? new Date(jobCreatedAtFromParam) : defaultDate;

	const statuses = ReworkJobsViewsViewStateMap[view as ReworkJobsViews];

	// DATA
	const [{ fetching: reworkJobsFetching, data: reworkJobsData, error: reworkJobsError }] =
		useQuery<{
			reworkJobs: ReworkJobsSummaryPage;
		}>({
			query: REWORK_JOBS_QUERY,
			variables: {
				cursor: paginationCursors[0],
				limit: getPageSize(),
				searchText: actualSearchText,
				statuses,
				dateReceivedFrom: historyDateLimiter ? historyDateLimiter : undefined,
				// orderBy: sort ? sort.split(' ')[0] : undefined,
				// orderByDirection: sort ? sort.split(' ')[1] : undefined,
			},
		});

	const [{ fetching: countsFetching, data: countsData, error: countsError }] = useQuery<{
		PendingCount: Count;
		InProgressCount: Count;
		ClosedCount: Count;
	}>({
		query: REWORK_COUNTS_QUERY,
		variables: {
			cursor: paginationCursors[0],
			searchText: actualSearchText,
			dateReceivedFrom: historyDateLimiter ? historyDateLimiter : undefined,
		},
	});

	const {
		PendingCount = { count: 0 },
		InProgressCount = { count: 0 },
		ClosedCount = { count: 0 },
	} = countsData || {};

	const fetching = reworkJobsFetching || countsFetching;
	const error = reworkJobsError || countsError;

	// Views
	const views = [
		{
			label: messages.allReworkJobs,
			id: ReworkJobsViews.All,
		},
		{
			label: messages.pending,
			metaLabel: PendingCount.count,
			id: ReworkJobsViews.Pending,
		},
		{
			label: messages.inProgress,
			metaLabel: InProgressCount.count,
			id: ReworkJobsViews.InProgress,
		},
		{
			label: messages.closed,
			metaLabel: ClosedCount.count,
			id: ReworkJobsViews.Closed,
		},
	];

	function handleDateRangeSelectionChanged(dateRange: DateRange) {
		const { start } = dateRange;
		if (!start) {
			setFilters([{ key: JOB_CREATED_AT_FROM_KEY, value: undefined }]);
			return;
		}

		const startDateMidnight: Date = new Date(start);
		startDateMidnight.setHours(0, 0, 0, 0);
		setFilters([{ key: JOB_CREATED_AT_FROM_KEY, value: startDateMidnight.toISOString() }]);
	}

	return error ? (
		<Error graphQLError={error} />
	) : (
		<Page
			fullWidth
			title={messages.rework}
			primaryAction={
				<DateRangeSelect
					options={dateRangeOptions}
					selectedOption={historyDateLimiter}
					onChange={handleDateRangeSelectionChanged}
				/>
			}
		>
			<Divider />
			<br />
			<Layout>
				<Layout.Section>
					<ReworkJobsTable
						data={reworkJobsData?.reworkJobs}
						fetching={fetching}
						paginationCursors={paginationCursors}
						query={query}
						selectedView={view}
						setFilters={setFilters}
						setPaginationCursors={setPaginationCursors}
						views={views}
					/>
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</Page>
	);
}
