import { ReactNode } from 'react';

import { NoData } from '../NoData';
import styles from './PendingText.module.css';

interface Props {
	value: ReactNode;
	loading?: boolean;
	empty?: boolean;
}

export function PendingText({ value, loading, empty }: Props) {
	if (loading) {
		return <div role="progressbar" aria-busy className={styles.progressbar} />;
	}

	if (empty || (!value && value !== 0)) {
		return <NoData />;
	}

	return <>{value}</>;
}
