import { IndexTable, TextContainer, TextStyle } from '@shopify/polaris';
import { LineV2 } from '@sixriver/fulfillment-api-schema';

interface LinesCellProps {
	lines: LineV2[];
}

export function LinesCell({ lines }: LinesCellProps) {
	const completedLinesCount = lines.reduce((count, line) => {
		if (line.quantity === line.doneQuantity) {
			return count + 1;
		}
		return count;
	}, 0);
	return (
		<IndexTable.Cell>
			<TextContainer>
				<TextStyle>
					{completedLinesCount} of {lines.length}
				</TextStyle>
			</TextContainer>
		</IndexTable.Cell>
	);
}
