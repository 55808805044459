import {
	AppliedFilterInterface,
	Card,
	EmptySearchResult,
	Filters,
	FilterInterface,
	IndexTable,
	Link,
	Stack,
	TextStyle,
} from '@shopify/polaris';
import { usePrevious } from '@shopify/react-hooks';
import {
	OrderByDirection,
	ShippingNoticeStatus,
	ShippingNoticeSummaryPage,
	ShippingNoticeSummaryOrderByField,
	ReceivingIssueReason,
} from '@sixriver/fulfillment-api-schema';
import { useEffect } from 'react';

import { IconBadge } from './IconBadge';
import { ReceivingInspection } from './ReceivingInspection';
import { Pagination, Cursors, SetCursors } from 'components/DataTable/Pagination';
import { DateTime } from 'components/DateTime';
import { NoData } from 'components/NoData';
import { RelativeDateTime } from 'components/RelativeDateTime';
import { SortBy, SortChoice } from 'components/SortBy';
import { Tab, ViewTabs } from 'components/ViewTabs';
import { MIN_QUERY_LENGTH } from 'helpers/table';
import {
	SetFilters,
	DEFAULT_VIEW_KEY,
	DEFAULT_QUERY_KEY,
	DEFAULT_SORT_KEY,
} from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';
import { InboundShipmentProgress } from 'pages/InboundShipment/InboundShipmentProgress';
import * as routes from 'routes';

export interface Heading {
	title: string;
}

interface Props {
	appliedFilters?: AppliedFilterInterface[];
	data?: ShippingNoticeSummaryPage;
	filters?: FilterInterface[];
	loading?: boolean;
	paginationCursors: Cursors;
	query?: string;
	queryKey?: string;
	setPaginationCursors: SetCursors;
	selectedView?: string;
	setFilters: SetFilters;
	sortValue?: string;
	sortKey?: string;
	viewKey?: string;
	views: Tab[];
}

export function InboundShipmentsTable({
	appliedFilters,
	data,
	filters,
	loading = false,
	query,
	queryKey = DEFAULT_QUERY_KEY,
	setFilters,
	selectedView,
	sortKey = DEFAULT_SORT_KEY,
	sortValue,
	views,
	viewKey = DEFAULT_VIEW_KEY,
	paginationCursors,
	setPaginationCursors,
}: Props) {
	const { messages, translate } = useLocalization();

	const emptyStateMarkup = (
		<EmptySearchResult title={messages.shippingNoticesNotFound} withIllustration />
	);

	const onClearAll = () => {
		if (setFilters) {
			setFilters([
				...(filters || []).map((filter) => ({ key: filter.key, value: '' })),
				{ key: queryKey, value: '' },
			]);
		}
	};

	const prevSelectedView = usePrevious(selectedView);
	const prevQuery = usePrevious(query);
	const prevAppliedFilters = usePrevious(appliedFilters);

	// Reset pagination when changing views or filters
	useEffect(() => {
		const hasQueryChanged =
			((query?.length || 0) >= MIN_QUERY_LENGTH || (prevQuery?.length || 0) >= MIN_QUERY_LENGTH) &&
			prevQuery !== query;

		const haveFiltersChanged = (appliedFilters || []).some((af) => {
			const prev = prevAppliedFilters?.find((paf) => paf.key === af.key);
			return !prev || prev?.label !== af.label;
		});

		if (
			setPaginationCursors &&
			(prevSelectedView !== selectedView || hasQueryChanged || haveFiltersChanged)
		) {
			setPaginationCursors(() => []);
		}
	});

	const sortChoices: SortChoice[] = [
		{
			label: messages.sortOptions.receivingIdDsc,
			value: `${ShippingNoticeSummaryOrderByField.ExternalId} ${OrderByDirection.Desc}`,
		},
		{
			label: messages.sortOptions.receivingIdAsc,
			value: `${ShippingNoticeSummaryOrderByField.ExternalId} ${OrderByDirection.Asc}`,
		},
		{
			label: messages.sortOptions.storeAsc,
			value: `${ShippingNoticeSummaryOrderByField.Merchant} ${OrderByDirection.Asc}`,
		},
		{
			label: messages.sortOptions.storeDsc,
			value: `${ShippingNoticeSummaryOrderByField.Merchant} ${OrderByDirection.Desc}`,
		},
		{
			label: messages.sortOptions.linesPerShipmentDsc,
			value: `${ShippingNoticeSummaryOrderByField.InboundLineCount} ${OrderByDirection.Desc}`,
		},
		{
			label: messages.sortOptions.linesPerShipmentAsc,
			value: `${ShippingNoticeSummaryOrderByField.InboundLineCount} ${OrderByDirection.Asc}`,
		},
		{
			label: messages.sortOptions.expectedUnitsPerShipmentDsc,
			value: `${ShippingNoticeSummaryOrderByField.QuantityExpected} ${OrderByDirection.Desc}`,
		},
		{
			label: messages.sortOptions.expectedUnitsPerShipmentAsc,
			value: `${ShippingNoticeSummaryOrderByField.QuantityExpected} ${OrderByDirection.Asc}`,
		},
		{
			label: messages.sortOptions.numOfIssuesDsc,
			value: `${ShippingNoticeSummaryOrderByField.IssueCount} ${OrderByDirection.Desc}`,
		},
		{
			label: messages.sortOptions.numOfIssuesAsc,
			value: `${ShippingNoticeSummaryOrderByField.IssueCount} ${OrderByDirection.Asc}`,
		},
		{
			label: messages.sortOptions.estArrivalTimeDsc,
			value: `${ShippingNoticeSummaryOrderByField.EarliestEta} ${OrderByDirection.Desc}`,
		},
		{
			label: messages.sortOptions.estArrivalTimeAsc,
			value: `${ShippingNoticeSummaryOrderByField.EarliestEta} ${OrderByDirection.Asc}`,
		},
		{
			label: messages.sortOptions.actArrivalTimeDsc,
			value: `${ShippingNoticeSummaryOrderByField.EarliestArrivedAt} ${OrderByDirection.Desc}`,
		},
		{
			label: messages.sortOptions.actArrivalTimeAsc,
			value: `${ShippingNoticeSummaryOrderByField.EarliestArrivedAt} ${OrderByDirection.Asc}`,
		},
	];

	const { cursor, results = [] } = data || {};

	const headings: [Heading, ...Heading[]] = [
		{ title: messages.receivingId },
		{ title: messages.store },
		{ title: messages.lines },
		{ title: messages.unitsReceived },
		{ title: messages.progress },
		{ title: messages.estimatedArrival },
		{ title: messages.actualArrival },
		{ title: messages.asnType },
		{ title: messages.fai },
	];

	const rows = results.map(
		(
			{
				id,
				externalId,
				merchant,
				inboundLineCount,
				issueCount,
				issueReasons,
				quantityExpected,
				quantityReceived,
				status,
				earliestEta,
				earliestArrivedAt,
				internalTransfer,
				uninspectedLineCount,
			},
			index,
		) => (
			<IndexTable.Row id={id} key={id} position={index}>
				<IndexTable.Cell>
					<Link url={routes.inboundShipment(id)} key="inbound" removeUnderline>
						{externalId}
					</Link>
				</IndexTable.Cell>
				<IndexTable.Cell>
					<TextStyle>{merchant}</TextStyle>
				</IndexTable.Cell>
				<IndexTable.Cell>
					<TextStyle>{translate(messages.countLines, { count: inboundLineCount })}</TextStyle>
				</IndexTable.Cell>
				<IndexTable.Cell>
					<TextStyle>
						{translate(messages.xOfY, {
							x: quantityReceived,
							y: quantityExpected,
						})}
					</TextStyle>
				</IndexTable.Cell>
				<IndexTable.Cell>
					<Stack spacing="extraTight" wrap={false}>
						<InboundShipmentProgress status={status} />
						{issueCount > 0 && !issueReasons?.includes(ReceivingIssueReason.CancelledByApi) && (
							<IconBadge issueCount={issueCount} issueReasons={issueReasons} />
						)}
					</Stack>
				</IndexTable.Cell>
				<IndexTable.Cell>
					{earliestEta ? (
						<div>
							<DateTime date={earliestEta} />
							{!earliestArrivedAt &&
							![ShippingNoticeStatus.Cancelled, ShippingNoticeStatus.AuditRequired].includes(
								status,
							) ? (
								<RelativeDateTime date={earliestEta} mode="deadline" />
							) : (
								<div>
									<br />
								</div>
							)}
						</div>
					) : (
						<NoData />
					)}
				</IndexTable.Cell>
				<IndexTable.Cell>
					{earliestArrivedAt ? (
						<div>
							<DateTime date={earliestArrivedAt} />
							<RelativeDateTime date={earliestArrivedAt} mode="expectation" />
						</div>
					) : (
						<NoData />
					)}
				</IndexTable.Cell>
				<IndexTable.Cell>
					<p>{internalTransfer ? messages.internalTransfer : messages.inbound}</p>
				</IndexTable.Cell>
				<IndexTable.Cell>
					<ReceivingInspection
						inboundLineCount={inboundLineCount}
						uninspectedLineCount={uninspectedLineCount}
					/>
				</IndexTable.Cell>
			</IndexTable.Row>
		),
	);

	return (
		<Card>
			<Card.Section>
				<div style={{ paddingBottom: '2rem' }}>
					<ViewTabs
						tabs={views}
						selected={selectedView || views?.[0]?.id}
						onSelect={(id) => setFilters([{ key: viewKey, value: id }])}
					/>
				</div>
				<Filters
					queryValue={query || undefined}
					queryPlaceholder={messages.filterInboundShipments}
					onQueryChange={(value) => setFilters([{ key: queryKey, value }])}
					onQueryClear={() => setFilters([{ key: queryKey, value: '' }])}
					filters={filters || []}
					appliedFilters={appliedFilters}
					onClearAll={onClearAll}
				>
					<SortBy
						choices={sortChoices}
						selected={sortValue ? [sortValue] : []}
						onChange={(selected) => setFilters([{ key: sortKey, value: selected[0] }])}
					/>
				</Filters>
			</Card.Section>
			<IndexTable
				emptyState={emptyStateMarkup}
				itemCount={results.length}
				loading={loading}
				headings={headings}
				selectable={false}
			>
				{rows}
			</IndexTable>
			<Card.Section>
				<Pagination
					endCursor={cursor}
					cursors={paginationCursors}
					setCursors={setPaginationCursors}
					loading={loading}
				/>
			</Card.Section>
		</Card>
	);
}
