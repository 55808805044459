import { useCallback, useEffect, useState } from 'react';
import { CombinedError } from 'urql';

import { useLocalization } from 'hooks/useLocalization';

export interface FormProps<T> {
	mode: 'add' | 'edit';
	data: T;
	error?: CombinedError;
	onSubmit: (data: T) => Promise<void>;
}

function validate<T>(messages: any, error?: CombinedError): Partial<Record<keyof T, string>> {
	const validations: Partial<Record<keyof T, string>> = {};

	const gqlError = error?.graphQLErrors.find((e) => e.message === 'Argument Validation Error');

	if (gqlError) {
		const errors = gqlError.extensions?.exception.validationErrors;

		if (Array.isArray(errors)) {
			errors.forEach((e: any) => {
				const message = Object.keys(e.constraints)
					.map((constraint) => {
						return messages.validations[constraint as keyof typeof messages.validations] || '??';
					})
					.join('; ');

				validations[e.property as keyof T] = message;
			});
		}
	}

	return validations;
}

export function useForm<T>(data: T, error?: CombinedError) {
	const { messages } = useLocalization();
	// deep clone the source data so it is never mutated and we can revert back to it
	const [input, setInput] = useState<T>(JSON.parse(JSON.stringify(data)));
	const [dirty, setDirty] = useState(false);
	const [feedback, setFeedback] = useState(error);
	const [validations, setValidations] = useState(validate<T>(messages, feedback));

	useEffect(() => {
		setFeedback(error);
	}, [error]);

	useEffect(() => {
		setValidations(validate<T>(messages, feedback));
	}, [messages, feedback]);

	const editForm = useCallback(
		(partialInput: Partial<T>) => {
			// dirty the form
			setDirty(true);

			// update form input
			setInput({ ...input, ...partialInput });

			// discard form validations for changed fields
			Object.keys(partialInput).forEach((key) => {
				delete validations[key as keyof T];
			});

			setValidations(validations);
		},
		[input, validations],
	);

	const discardForm = useCallback(() => {
		setFeedback(undefined);
		setInput(data);
		setDirty(false);
	}, [data]);

	return { editForm, discardForm, input, dirty, feedback, validations };
}
