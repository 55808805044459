import { ShippingNoticeStatus } from '@sixriver/fulfillment-api-schema';

export enum InboundShipmentViews {
	All = 'All',
	Inbound = 'Inbound',
	Arrived = 'Arrived',
	Receiving = 'Receiving',
	Closed = 'Closed',
}

export const InboundShipmentViewStateMap: {
	[key in InboundShipmentViews]: ShippingNoticeStatus[];
} = {
	[InboundShipmentViews.All]: [],
	[InboundShipmentViews.Inbound]: [ShippingNoticeStatus.Submitted, ShippingNoticeStatus.InTransit],
	[InboundShipmentViews.Arrived]: [ShippingNoticeStatus.Arrived],
	[InboundShipmentViews.Receiving]: [ShippingNoticeStatus.Receiving],
	[InboundShipmentViews.Closed]: [ShippingNoticeStatus.Completed, ShippingNoticeStatus.Cancelled],
};
