import { OrderState } from '@sixriver/fulfillment-api-schema';

export enum OrderViews {
	All = 'All',
	Unassigned = 'Unassigned',
	InProgress = 'InProgress',
	Complete = 'Complete',
	Exceptions = 'Exceptions',
	Late = 'Late',
	AwaitingInventory = 'AwaitingInventory',
	Cancelled = 'Cancelled',
	Interrupted = 'Interrupted',
}

export const OrderViewStateMap: { [key in OrderViews]: OrderState[] } = {
	[OrderViews.All]: [
		OrderState.AwaitingInventory,
		OrderState.Unassigned,
		OrderState.BeingServiced,
		OrderState.InProgress,
		OrderState.Done,
		OrderState.Sorting,
		OrderState.Sorted,
		OrderState.Packable,
		OrderState.Packing,
		OrderState.Packed,
		OrderState.Exception,
		OrderState.Cancelled,
		OrderState.Offline,
	],
	[OrderViews.Unassigned]: [OrderState.Unassigned, OrderState.BeingServiced],
	[OrderViews.InProgress]: [
		OrderState.InProgress,
		OrderState.Sorting,
		OrderState.Packing,
		OrderState.Packable,
	],
	[OrderViews.Complete]: [OrderState.Done, OrderState.Sorted, OrderState.Packed],
	[OrderViews.Exceptions]: [OrderState.Exception],
	[OrderViews.Late]: [
		OrderState.Unassigned,
		OrderState.InProgress,
		OrderState.AwaitingInventory,
		OrderState.Sorting,
		OrderState.Packable,
		OrderState.Packing,
	],
	[OrderViews.AwaitingInventory]: [OrderState.AwaitingInventory],
	[OrderViews.Cancelled]: [OrderState.Cancelled],
	[OrderViews.Interrupted]: [OrderState.Offline],
};
