import { Badge, Icon, Stack, Tooltip } from '@shopify/polaris';
import { AlertMinor } from '@shopify/polaris-icons';
import { Job, JobStatus } from '@sixriver/fulfillment-api-schema';

import { useLocalization } from 'hooks/useLocalization';

interface Props {
	job?: Job;
}
export function OutboundJobStatusBadgeV2({ job }: Props): JSX.Element | null {
	const { messages } = useLocalization();

	if (!job) {
		return null;
	}

	let badge = <Badge status="warning">{messages.unassigned}</Badge>;

	if (job.status === JobStatus.Picking) {
		badge = <Badge status="info">{messages.picking}</Badge>;
	}

	if (job.status === JobStatus.Picked) {
		badge = <Badge>{messages.picked}</Badge>;
	}

	if (job.status === JobStatus.Sortable) {
		badge = <Badge status="info">{messages.readyToSort}</Badge>;
	}

	if (job.status === JobStatus.Sorting) {
		badge = <Badge status="info">{messages.sorting}</Badge>;
	}

	if (job.status === JobStatus.Sorted) {
		badge = <Badge>{messages.sorted}</Badge>;
	}

	if (job.status === JobStatus.Packable) {
		badge = <Badge status="info">{messages.readyToPack}</Badge>;
	}

	if (job.status === JobStatus.Packing) {
		badge = <Badge status="info">{messages.packing}</Badge>;
	}

	if (job.status === JobStatus.Packed) {
		badge = <Badge>{messages.packed}</Badge>;
	}

	if (job.status === JobStatus.Interrupted) {
		badge = <Badge>{messages.interrupted}</Badge>;
	}

	if (job.status === JobStatus.Canceled) {
		badge = <Badge>{messages.canceled}</Badge>;
	}

	if (job.status === JobStatus.Unknown) {
		badge = <Badge status="warning">{messages.unknown}</Badge>;
	}

	let icon;

	if (job.isShorted) {
		icon = (
			<Tooltip content={messages.outboundJobHasExceptions} dismissOnMouseOut>
				<Icon source={AlertMinor} color="critical" />
			</Tooltip>
		);
	}

	return (
		<Stack spacing="extraTight" wrap={false}>
			{badge}
			{icon}
		</Stack>
	);
}
