import {
	AppliedFilterInterface,
	Card,
	EmptySearchResult,
	FilterInterface,
	Filters,
	IndexTable,
} from '@shopify/polaris';
import { usePrevious } from '@shopify/react-hooks';
import { ReworkJob, ReworkJobsSummaryPage } from '@sixriver/fulfillment-api-schema';
import { useEffect } from 'react';

import { DetailsCell } from './DetailsCell';
import { IssueCell } from './IssueCell';
import { JobCreatedAtCell } from './JobCreatedAtCell';
import { JobOriginCell } from './JobOriginCell';
import { JobTypeCell } from './JobTypeCell';
import { ProgressCell } from './ProgressCell';
import { StoreCell } from './StoreCell';
import { UnitsProcessedCell } from './UnitsProcessedCell';
import { Pagination, Cursors, SetCursors } from 'components/DataTable/Pagination';
import { Tab, ViewTabs } from 'components/ViewTabs';
import { MIN_QUERY_LENGTH } from 'helpers/table';
import { SetFilters, DEFAULT_VIEW_KEY, DEFAULT_QUERY_KEY } from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';

export interface Heading {
	title: string;
}

interface Props {
	data?: ReworkJobsSummaryPage;
	fetching?: boolean;
	paginationCursors: Cursors;
	query?: string;
	queryKey?: string;
	selectedView?: string;
	setFilters: SetFilters;
	setPaginationCursors: SetCursors;
	viewKey?: string;
	views: Tab[];
}

export function ReworkJobsTable({
	data,
	fetching,
	paginationCursors,
	query,
	queryKey = DEFAULT_QUERY_KEY,
	selectedView,
	setFilters,
	setPaginationCursors,
	viewKey = DEFAULT_VIEW_KEY,
	views,
}: Props) {
	const { messages } = useLocalization();

	const appliedFilters: AppliedFilterInterface[] = [];

	const emptyStateMarkup = (
		<EmptySearchResult title={messages.reworkJobsNotFound} withIllustration />
	);

	const filters: FilterInterface[] = [];

	const prevQuery = usePrevious(query);
	const prevSelectedView = usePrevious(selectedView);
	const prevAppliedFilters = usePrevious(appliedFilters);

	// Reset pagination when changing views or filters
	useEffect(() => {
		const hasQueryChanged =
			((query?.length || 0) >= MIN_QUERY_LENGTH || (prevQuery?.length || 0) >= MIN_QUERY_LENGTH) &&
			prevQuery !== query;

		const haveFiltersChanged = (appliedFilters || []).some((af) => {
			const prev = prevAppliedFilters?.find((paf) => paf.key === af.key);
			return !prev || prev?.label !== af.label;
		});

		if (
			setPaginationCursors &&
			(prevSelectedView !== selectedView || hasQueryChanged || haveFiltersChanged)
		) {
			setPaginationCursors(() => []);
		}
	});

	const onClearAll = () => {
		if (setFilters) {
			setFilters([]);
		}
	};

	const { cursor, results = [] } = data || {};

	const headings: [Heading, ...Heading[]] = [
		{
			title: messages.reworkJobId,
		},
		{
			title: messages.originId,
		},
		{
			title: messages.store,
		},
		{
			title: messages.progress,
		},
		{
			title: messages.unitsProcessed,
		},
		{
			title: messages.issue,
		},
		{
			title: messages.jobCreated,
		},
		{
			title: messages.jobOrigin,
		},
	];

	const rows = results.map((reworkJob: ReworkJob, index) => {
		const {
			createdAt,
			id,
			issue,
			jobOriginExternalId,
			jobOriginId,
			jobOriginType,
			merchant,
			processedQuantity,
			reworkQuantity,
			status,
		} = reworkJob;
		return (
			<IndexTable.Row id={id} key={id} position={index}>
				<DetailsCell id={id} />
				<JobOriginCell
					jobOriginExternalId={jobOriginExternalId}
					jobOriginId={jobOriginId}
					jobType={jobOriginType}
				/>
				<StoreCell merchant={merchant} />
				<ProgressCell status={status} />
				<UnitsProcessedCell reworkQuantity={reworkQuantity} processedQuantity={processedQuantity} />
				<IssueCell issue={issue} />
				<JobCreatedAtCell createdAt={createdAt} />
				<JobTypeCell jobType={jobOriginType} />
			</IndexTable.Row>
		);
	});

	return (
		<Card>
			<Card.Section>
				<div style={{ paddingBottom: '2rem' }}>
					<ViewTabs
						onSelect={(id) => setFilters([{ key: viewKey, value: id }])}
						selected={selectedView || views?.[0]?.id}
						tabs={views}
					/>
				</div>
				<Filters
					appliedFilters={appliedFilters}
					filters={filters || []}
					onClearAll={onClearAll}
					onQueryChange={(value) => setFilters([{ key: queryKey, value }])}
					onQueryClear={() => setFilters([{ key: queryKey, value: '' }])}
					queryPlaceholder={messages.filterReworkJobs}
					queryValue={query || undefined}
				/>
			</Card.Section>
			<IndexTable
				emptyState={emptyStateMarkup}
				headings={headings}
				itemCount={results.length}
				loading={fetching}
				selectable={false}
			>
				{rows}
			</IndexTable>
			<Card.Section>
				<Pagination
					cursors={paginationCursors}
					endCursor={cursor}
					loading={fetching}
					setCursors={setPaginationCursors}
				/>
			</Card.Section>
		</Card>
	);
}
