import { Page, Layout, ChoiceList, Select } from '@shopify/polaris';
import { useDebouncedValue } from '@shopify/react-hooks';
import {
	Count,
	QueryReplenJobsArgs,
	QueryReplenJobsCountArgs,
	ReplenJobConnection,
} from '@sixriver/fulfillment-api-schema';
import { useMemo, useState } from 'react';

import { REPLENISHMENT_JOBS_QUERY, REPLEN_JOBS_COUNTS_QUERY } from './ReplenishJobs.graphql';
import styles from './ReplenishJobs.module.css';
import { ReplenishJobsTable } from './ReplenishJobsTable';
import { ReplenishJobsViews, ReplenishJobsViewStateMap } from './ReplenishJobsViews';
import { AutoRefresh } from 'components/AutoRefresh';
import { Error } from 'components/Error';
import { TimezoneFooter } from 'components/TimezoneFooter';
import { getPageSize } from 'helpers/page-size';
import { MIN_QUERY_LENGTH } from 'helpers/table';
import { startOfDay } from 'helpers/time';
import { useFilters, useSetFilters } from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';
import { usePolling } from 'hooks/usePolling';
import { usePollingQuery } from 'hooks/usePollingQuery';

const REPLEN_JOB_HAS_EXCEPTIONS_KEY = 'hasExceptions';
const REPLEN_JOB_COMPLETED_SINCE_HOURS_AGO_KEY = 'completedSinceHoursAgo';

export function ReplenishJobs() {
	const { messages, translate } = useLocalization();

	// Pagination
	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);

	// Filters
	const {
		view = ReplenishJobsViews.All,
		query,
		[REPLEN_JOB_HAS_EXCEPTIONS_KEY]: hasExceptions,
		[REPLEN_JOB_COMPLETED_SINCE_HOURS_AGO_KEY]: completedSinceHoursAgo,
	} = useFilters([REPLEN_JOB_HAS_EXCEPTIONS_KEY, REPLEN_JOB_COMPLETED_SINCE_HOURS_AGO_KEY]);

	const setFilters = useSetFilters();

	const searchTextQuery = useDebouncedValue(query) || '';
	const hasExceptionsFilter = useDebouncedValue(hasExceptions) || '';

	const midnight = startOfDay();

	const searchText = searchTextQuery.length >= MIN_QUERY_LENGTH ? searchTextQuery : undefined;

	// Polling
	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();

	// only compute date for GQL query when filter changes
	const completedAtFrom = useMemo(() => {
		const currentDateTime = new Date();
		const currentDateTimeHour = currentDateTime.getHours();

		return completedSinceHoursAgo
			? new Date(currentDateTime.setHours(currentDateTimeHour - Number(completedSinceHoursAgo)))
			: undefined;
	}, [completedSinceHoursAgo]);

	// Job counts
	const [{ fetching: countsFetching, data: countsData, error: countsError }] = usePollingQuery<
		{
			AllCount: Count;
			UnassignedCount: Count;
			InProgressCount: Count;
			CompleteCount: Count;
			CanceledCount: Count;
			InterruptedCount: Count;
		},
		QueryReplenJobsCountArgs
	>({
		query: REPLEN_JOBS_COUNTS_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			searchText,
			statuses: ReplenishJobsViewStateMap[view as ReplenishJobsViews],
			hasExceptions: hasExceptionsFilter ? Boolean(hasExceptionsFilter) : undefined,
			updatedAtFrom: completedAtFrom ? completedAtFrom : midnight,
		},
	});

	// ReplenishmentJobs Query
	const [{ fetching: replenFetching, data: replenData, error: replenError }] = usePollingQuery<
		{ replenJobs: ReplenJobConnection },
		QueryReplenJobsArgs
	>({
		query: REPLENISHMENT_JOBS_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			first: getPageSize(),
			after: paginationCursors[0],
			searchText,
			statuses: ReplenishJobsViewStateMap[view as ReplenishJobsViews],
			hasExceptions: hasExceptionsFilter ? Boolean(hasExceptionsFilter) : undefined,
			updatedAtFrom: completedAtFrom
				? completedAtFrom
				: [ReplenishJobsViews.Cancelled, ReplenishJobsViews.Complete].includes(
						view as ReplenishJobsViews,
				  )
				? midnight
				: undefined,
		},
	});

	const {
		UnassignedCount = { count: 0 },
		InProgressCount = { count: 0 },
		CompleteCount = { count: 0 },
		CanceledCount = { count: 0 },
		InterruptedCount = { count: 0 },
	} = countsData || {};

	// Views
	const views = [
		{
			label: messages.all,
			id: ReplenishJobsViews.All,
		},
		{
			label: messages.unassigned,
			metaLabel: UnassignedCount.count,
			id: ReplenishJobsViews.Unassigned,
		},
		{
			label: messages.inProgress,
			metaLabel: InProgressCount.count,
			id: ReplenishJobsViews.InProgress,
		},
		{
			label: messages.completed,
			metaLabel: CompleteCount.count,
			id: ReplenishJobsViews.Complete,
		},
		{
			label: messages.canceled,
			metaLabel: CanceledCount.count,
			id: ReplenishJobsViews.Cancelled,
		},
		{
			label: messages.interrupted,
			metaLabel: InterruptedCount.count,
			id: ReplenishJobsViews.Interrupted,
		},
	];

	const completedSinceOptions = [1, 2, 4, 6, 8, 12, 24].map((count) => {
		return {
			label:
				count === 1 ? messages.lastHour : (translate(messages.countLastHours, { count }) as string),
			value: count.toString(),
		};
	});

	const filters: Array<{
		key: string;
		label: string;
		filter: JSX.Element;
		shortcut: boolean;
	}> = [
		{
			filter: (
				<ChoiceList
					title={messages.exceptions}
					titleHidden
					choices={[
						{
							label: messages.exceptions,
							value: 'true',
						},
					]}
					selected={hasExceptionsFilter ? [hasExceptionsFilter] : []}
					onChange={(selected) => {
						setFilters([{ key: REPLEN_JOB_HAS_EXCEPTIONS_KEY, value: selected.join(' ') }]);
					}}
					allowMultiple
				/>
			),
			key: REPLEN_JOB_HAS_EXCEPTIONS_KEY,
			label: messages.exceptions,
			shortcut: true,
		},
		{
			filter: (
				<div className={styles.completedSinceFilter}>
					<Select
						label={messages.completedSince}
						labelHidden
						options={completedSinceOptions}
						placeholder={' '}
						value={completedSinceHoursAgo}
						onChange={(selected) => {
							setFilters([{ key: REPLEN_JOB_COMPLETED_SINCE_HOURS_AGO_KEY, value: selected }]);
						}}
					/>
				</div>
			),
			key: REPLEN_JOB_COMPLETED_SINCE_HOURS_AGO_KEY,
			label: messages.completedSince,
			shortcut: true,
		},
	];

	const appliedFilters: Array<{
		key: string;
		label: string;
		onRemove: () => void;
	}> = [
		...(hasExceptions
			? [
					{
						key: REPLEN_JOB_HAS_EXCEPTIONS_KEY,
						label: messages.exceptions,
						onRemove: () => {
							setFilters([{ key: REPLEN_JOB_HAS_EXCEPTIONS_KEY, value: '' }]);
						},
					},
			  ]
			: []),
		...(completedSinceHoursAgo
			? [
					{
						key: REPLEN_JOB_COMPLETED_SINCE_HOURS_AGO_KEY,
						label:
							completedSinceHoursAgo === '1'
								? messages.lastHour
								: (translate(messages.countLastHours, { count: completedSinceHoursAgo }) as string),
						onRemove: () => {
							setFilters([{ key: REPLEN_JOB_COMPLETED_SINCE_HOURS_AGO_KEY, value: '' }]);
						},
					},
			  ]
			: []),
	];

	// combine errors
	const error = replenError || countsError;

	// combine fetching statuses
	const fetching = replenFetching || countsFetching;

	return error ? (
		<Error graphQLError={error} />
	) : (
		<Page fullWidth title={messages.replenishJobs}>
			<Layout>
				<Layout.Section>
					<AutoRefresh
						pollingEnabled={pollingEnabled}
						togglePolling={togglePolling}
						discriminatorData={replenData}
					/>
				</Layout.Section>
				<Layout.Section>
					<ReplenishJobsTable
						data={replenData}
						loading={fetching}
						paginationCursors={paginationCursors}
						setPaginationCursors={setPaginationCursors}
						views={views}
						filters={filters}
						selectedView={view}
						query={query}
						setFilters={setFilters}
						appliedFilters={appliedFilters}
					/>
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</Page>
	);
}
