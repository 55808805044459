import { TextStyle } from '@shopify/polaris';
import { SpecialProject, SpecialProjectType } from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	project?: SpecialProject;
}

export default function Type({ project }: Props): JSX.Element {
	const { messages } = useLocalization();

	const labels = {
		[SpecialProjectType.Prekit]: messages.specialProjectTypes.prekit,
		[SpecialProjectType.Kitting]: messages.specialProjectTypes.kitting,
		[SpecialProjectType.Relabelling]: messages.specialProjectTypes.relabelling,
		[SpecialProjectType.CycleCount]: messages.specialProjectTypes.cycleCount,
		[SpecialProjectType.Returns]: messages.specialProjectTypes.returns,
		[SpecialProjectType.InventoryTransferToMerchant]:
			messages.specialProjectTypes.inventoryTransferToMerchant,
		[SpecialProjectType.QualityControl]: messages.specialProjectTypes.qualityControl,
		[SpecialProjectType.Repackaging]: messages.specialProjectTypes.repackaging,
		[SpecialProjectType.Other]: messages.specialProjectTypes.otherType,
	};

	return project?.type ? <TextStyle>{labels[project.type]}</TextStyle> : <NoData />;
}
