import {
	MutationStartHealPlanAndPrintLabelsArgs,
	MutationStartManualHealPlanArgs,
	Order,
	StartHealPlanPayload,
} from '@sixriver/fulfillment-api-schema';
import gql from 'graphql-tag';
import { useMutation } from 'urql';

import { useAuth } from 'hooks/useAuth';
import { useLocalization } from 'hooks/useLocalization';
import { useToast } from 'hooks/useToast';
import { UserRole } from 'providers/AuthProvider';

const PRINT_LABEL_HEAL_PLAN_LOCAL_STORAGE_KEY_PREFIX = 'heal-plan-label-printed-';
const PRINT_LABEL_SORT_WALL_LOCAL_STORAGE_KEY_PREFIX = 'sort-wall-label-printed-';

const PRINT_LABEL_MUTATION = gql`
	mutation PrintLabel($id: String!) {
		startHealPlanAndPrintLabels(id: $id) {
			success
		}
	}
`;

export const cleanUpPrintLabelLocalStorage = () => {
	Object.keys(localStorage).forEach((key) => {
		if (
			key.startsWith(PRINT_LABEL_HEAL_PLAN_LOCAL_STORAGE_KEY_PREFIX) ||
			key.startsWith(PRINT_LABEL_SORT_WALL_LOCAL_STORAGE_KEY_PREFIX)
		) {
			const value = localStorage.getItem(key);

			if (value) {
				const isMoreThanTwoWeeksAgo = Date.now() - new Date(value).getTime() > 1209600000;

				if (isMoreThanTwoWeeksAgo) {
					localStorage.removeItem(key);
				}
			}
		}
	});
};

export type onErrorProp = (error: Error) => void;

export const usePrintLabelButton = ({
	order,
	onError,
	onBefore,
}: {
	order?: Order;
	onError?: onErrorProp;
	onBefore?: () => void;
}) => {
	const { messages } = useLocalization();
	const { isUserAllowed } = useAuth();
	const hasBeenClicked = order?.id
		? !!localStorage.getItem(`${PRINT_LABEL_HEAL_PLAN_LOCAL_STORAGE_KEY_PREFIX}${order.id}`)
		: false;
	const disabled =
		!isUserAllowed([UserRole.Admin, UserRole.EmployeeManager]) ||
		order?.isHealed ||
		order?.healPlan?.status === 'RUNNING' ||
		false;

	const [{ fetching, error }, printLabel] = useMutation<
		{ startHealPlanAndPrintLabels: StartHealPlanPayload },
		MutationStartHealPlanAndPrintLabelsArgs
	>(PRINT_LABEL_MUTATION);
	const { showToast } = useToast();

	const onClick = async () => {
		if (order) {
			try {
				if (onBefore) {
					onBefore();
				}

				const { error, data } = await printLabel({ id: order.id });

				if (error) {
					showToast(messages.errorToast, true);
					if (onError) {
						onError(error);
					}
				} else if (data?.startHealPlanAndPrintLabels.success) {
					// We set the date as the value here to be able to clean up old values from localStorage if it grows too big
					localStorage.setItem(
						`${PRINT_LABEL_HEAL_PLAN_LOCAL_STORAGE_KEY_PREFIX}${order.id}`,
						new Date().toISOString(),
					);

					showToast(messages.labelPrinted);
				} else {
					throw new Error('Unable to start heal plan.');
				}
			} catch (error: any) {
				showToast(messages.errorToast, true);
				if (onError) {
					onError(error);
				}
			}
		}
	};

	const buttonText = disabled
		? messages.unavailable
		: hasBeenClicked
		? messages.reprintLabel
		: messages.printLabel;

	return { onClick, loading: fetching, error, hasBeenClicked, disabled, buttonText };
};

const MANUAL_RESOLVE_MUTATION = gql`
	mutation ManualResolve($id: String!) {
		startManualHealPlan(id: $id) {
			success
		}
	}
`;

export const useManualException = ({
	order,
	onError,
}: {
	order?: Order;
	onError?: onErrorProp;
}) => {
	const { messages } = useLocalization();
	const { isUserAllowed } = useAuth();
	const hasBeenClicked = order?.id
		? !!localStorage.getItem(`${PRINT_LABEL_HEAL_PLAN_LOCAL_STORAGE_KEY_PREFIX}${order.id}`)
		: false;
	const disabled =
		!isUserAllowed([UserRole.Admin, UserRole.EmployeeManager]) ||
		order?.isHealed ||
		order?.healPlan?.status === 'RUNNING' ||
		false;

	const [{ fetching, error }, resolveException] = useMutation<
		{ startManualHealPlan: StartHealPlanPayload },
		MutationStartManualHealPlanArgs
	>(MANUAL_RESOLVE_MUTATION);
	const { showToast } = useToast();

	const onClick = async () => {
		if (order) {
			try {
				const { error, data } = await resolveException({ id: order.id });

				if (error) {
					showToast(messages.errorToast, true);
					if (onError) {
						onError(error);
					}
				} else if (data?.startManualHealPlan.success) {
					// We set the date as the value here to be able to clean up old values from localStorage if it grows too big
					localStorage.setItem(
						`${PRINT_LABEL_HEAL_PLAN_LOCAL_STORAGE_KEY_PREFIX}${order.id}`,
						new Date().toISOString(),
					);

					showToast(messages.labelPrinted);
				} else {
					throw new Error('Unable to start heal plan.');
				}
			} catch (error: any) {
				showToast(messages.errorToast, true);
				if (onError) {
					onError(error);
				}
			}
		}
	};

	const buttonText = disabled ? messages.unavailable : messages.markResolved;

	return { onClick, loading: fetching, error, disabled, buttonText, hasBeenClicked };
};

export const usePrintSortWallLabel = ({ id, disabled }: { id?: string; disabled?: boolean }) => {
	const { messages } = useLocalization();

	const hasBeenClicked = id
		? !!localStorage.getItem(`${PRINT_LABEL_SORT_WALL_LOCAL_STORAGE_KEY_PREFIX}${id}`)
		: false;

	const onLabelPrintSuccess = () => {
		localStorage.setItem(
			`${PRINT_LABEL_SORT_WALL_LOCAL_STORAGE_KEY_PREFIX}${id}`,
			new Date().toISOString(),
		);
	};

	const buttonText = disabled
		? messages.unavailable
		: hasBeenClicked
		? messages.reprintLabel
		: messages.printLabel;

	return { buttonText, onLabelPrintSuccess };
};
