import { NoData } from 'components/NoData';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	date?: Date | number | string;
}

export function DateTime({ date }: Props) {
	const { formatTime, formatDate } = useLocalization();

	if (date) {
		const iso = new Date(date).toISOString();

		return (
			<div>
				<time dateTime={iso}>{formatDate(date)}</time>
				<br />
				<time dateTime={iso}>{formatTime(date)}</time>
			</div>
		);
	}

	return <NoData />;
}
