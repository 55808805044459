import {
	Card,
	Checkbox,
	ContextualSaveBar,
	Form,
	Layout,
	Select,
	Stack,
	TextField,
} from '@shopify/polaris';
import { useCallback, useEffect, useMemo } from 'react';

import { useEmployeeTags } from './Employees.hooks';
import { AutocompleteTags } from 'components/AutocompleteTags';
import { Error } from 'components/Error';
import { FormFeedback } from 'components/FormFeedback';
import { useConfig } from 'hooks/useConfig';
import { FormProps, useForm } from 'hooks/useForm';
import { useLocalization, LocaleType, SUPPORTED_LOCALES } from 'hooks/useLocalization';
import { uuidv4 } from 'utils/uuid';

type Props = FormProps<EmployeeFormData>;

export interface EmployeeFormData {
	name: string;
	email: string;
	phoneNumber?: string;
	badgeBarcode: string;
	tags: string[];
	locale: LocaleType;
	isDeviceAccessAllowed: boolean;
	isBridgeAccessAllowed: boolean;
}

export function EmployeeForm<T extends Props>({
	data,
	error: formError,
	onSubmit,
}: T): JSX.Element {
	const { config } = useConfig();
	const { messages } = useLocalization();

	// Form
	const defaultValues: EmployeeFormData = useMemo(
		() => ({
			badgeBarcode: data?.badgeBarcode || '',
			email: data?.email || '',
			name: data?.name || '',
			phoneNumber: data?.phoneNumber || '',
			tags: data?.tags || [],
			locale: data?.locale || 'en-US',
			isBridgeAccessAllowed: data?.isBridgeAccessAllowed || false,
			isDeviceAccessAllowed: data?.isDeviceAccessAllowed || false,
		}),
		[data],
	);

	const { editForm, discardForm, input, dirty, feedback, validations } = useForm<EmployeeFormData>(
		defaultValues,
		formError,
	);

	// Employee Tags
	const {
		addNewTagFetching,
		addNewTagMutationError,
		onNewTag,
		selectedTags,
		setSelectedTags,
		allTagOptions,
		fetchingTags,
		popularTags,
		tagsError,
	} = useEmployeeTags({ defaultSelectedTags: defaultValues.tags });

	// Methods
	const discardEdits = useCallback(() => {
		setSelectedTags([]);
		discardForm();
	}, [discardForm, setSelectedTags]);

	const submitForm = useCallback(() => {
		onSubmit(input);
	}, [input, onSubmit]);

	const generateBadgeBarcode = () => {
		const badgeBarcode = uuidv4();
		editForm({ badgeBarcode });
	};

	// Effects
	useEffect(
		// sync tags with form values
		() => {
			if (input.tags.length !== selectedTags.length) {
				editForm({ tags: selectedTags });
			}
		},
		[editForm, selectedTags, input],
	);

	// Guards
	const loading = fetchingTags;
	const error = addNewTagMutationError || tagsError;

	// Render
	if (loading) {
		return <></>;
	}

	if (error) {
		return <Error graphQLError={error} />;
	}

	return (
		<Form onSubmit={submitForm} noValidate preventDefault>
			{/* Save Bar */}
			{dirty ? (
				<ContextualSaveBar
					fullWidth={false}
					alignContentFlush={false}
					message={messages.unsavedChanges}
					saveAction={{
						content: messages.save,
						onAction: submitForm,
						loading: false,
						disabled: false,
					}}
					discardAction={{
						content: messages.discard,
						onAction: discardEdits,
					}}
				/>
			) : null}

			<FormFeedback feedback={feedback} />

			<Layout>
				<div style={{ paddingBottom: 100 }}>
					<Layout.Section>
						{/* Employee Details */}
						<Layout.AnnotatedSection title={messages.employeeDetails}>
							<Card sectioned>
								<div style={{ paddingTop: 15, paddingBottom: 15 }}>
									<Card.Section fullWidth flush>
										<Stack vertical>
											<TextField
												autoComplete="off"
												name="name"
												label={messages.fullName}
												error={validations.name}
												value={input.name}
												onChange={(name) => editForm({ name })}
											/>
											{config?.experimentalFlags?.includes('EDIT_USER_LOCALE') ? (
												<Select
													label={messages.language}
													options={SUPPORTED_LOCALES.map((locale) => ({
														label: messages.supportedLocales[locale] || locale,
														value: locale,
													}))}
													value={input.locale}
													onChange={(locale: LocaleType) => editForm({ locale })}
												/>
											) : null}
										</Stack>
									</Card.Section>
								</div>
							</Card>
						</Layout.AnnotatedSection>

						{/* Device Access */}
						<Layout.AnnotatedSection
							title={messages.deviceAccess}
							description={messages.deviceAccessDescription}
						>
							<Card
								sectioned
								title={
									<>
										<Checkbox
											label={messages.allowAccessToDevices}
											name="allowDeviceAccess"
											checked={input.isDeviceAccessAllowed}
											onChange={(isChecked) => {
												editForm({
													isDeviceAccessAllowed: isChecked,
													badgeBarcode: isChecked ? input.badgeBarcode : '',
												});
											}}
										/>
									</>
								}
							>
								<Card.Section fullWidth>
									<TextField
										autoComplete="off"
										name="badge"
										labelAction={
											input.isDeviceAccessAllowed
												? {
														content: messages.generateBarcode,
														onAction: generateBadgeBarcode,
												  }
												: undefined
										}
										disabled={!input.isDeviceAccessAllowed}
										label={messages.badgeBarcode}
										error={validations.badgeBarcode}
										value={input.badgeBarcode}
										onChange={(badge) => editForm({ badgeBarcode: badge })}
									/>
								</Card.Section>
							</Card>
						</Layout.AnnotatedSection>

						{/* Bridge Access */}
						<Layout.AnnotatedSection
							title={messages.bridgeAccess}
							description={messages.bridgeAccessDescription}
						>
							<Card
								sectioned
								title={
									<Checkbox
										label={messages.allowAccessToTheBridge}
										name="allowBridgeAccess"
										checked={input.isBridgeAccessAllowed}
										onChange={(isChecked) => {
											editForm({
												isBridgeAccessAllowed: isChecked,
											});
										}}
									/>
								}
							>
								<Card.Section fullWidth>
									<Stack vertical spacing="loose">
										<TextField
											name="email"
											disabled={!input.isBridgeAccessAllowed}
											label={messages.email}
											autoComplete="off"
											ariaAutocomplete="off"
											error={validations.email}
											value={input.email}
											onChange={(email) => editForm({ email })}
										/>
									</Stack>
								</Card.Section>
							</Card>
						</Layout.AnnotatedSection>

						{/* Tags */}
						<Layout.AnnotatedSection
							title={messages.tags}
							description={messages.employeeTagsDescription}
						>
							<Card sectioned>
								<div style={{ paddingTop: 15, paddingBottom: 15 }}>
									<Card.Section flush fullWidth>
										<AutocompleteTags
											allowNew
											onNewTag={onNewTag}
											availableOptions={allTagOptions}
											popularTags={popularTags}
											selectedOptions={selectedTags}
											onSelectedOptionsChange={setSelectedTags}
											isLoading={addNewTagFetching}
											selectedTagsPlacement="above"
											placeholder={messages.createNewTag}
										/>
									</Card.Section>
								</div>
							</Card>
						</Layout.AnnotatedSection>
					</Layout.Section>
				</div>
			</Layout>
		</Form>
	);
}
