import { MapPointConfig } from '@sixriver/fulfillment-api-schema';
import { TagList } from '@sixriver/map-element';
import { MapStack } from '@sixriver/map-io';
import { useEffect, useState } from 'react';

/**
 * This hook creates "tags" needed by the Map API to determine which workflow
 * points are disabled (grayed out).
 */
export function useTags(mapStack: MapStack, configs: MapPointConfig[]) {
	const [tags, setTags] = useState<TagList>({});

	useEffect(() => {
		if (mapStack && configs.length) {
			const tags: TagList = {};
			const features = mapStack.workflowPoints.features;

			configs
				.filter((config) => {
					return !config.enabled;
				})
				.forEach((config) => {
					// configs do not have IDs (doh!) so we need to cross-reference them with the map stack
					const feature = features.find((f: any) => f.properties.name === config.mapPointName);

					if (feature) {
						tags[feature.properties.id] = 'disabled';
					}
				});

			setTags(tags);
		}
	}, [mapStack, configs]);

	return tags;
}
