import { TextStyle } from '@shopify/polaris';
import { Order, Picking } from '@sixriver/fulfillment-api-schema';

import { useLocalization } from 'hooks/useLocalization';

export function WorkOrderType({ order }: { order?: Order | Picking }) {
	const { messages } = useLocalization();
	const orderType = order?.workOrderTypes?.length
		? messages.workOrderTypes.transfer
		: messages.workOrderTypes.ecommerce;

	return <TextStyle>{orderType}</TextStyle>;
}
