import { Button, ChoiceList, Popover } from '@shopify/polaris';
import { WorkArea } from '@sixriver/fulfillment-api-schema';
import { useState } from 'react';

import { useLocalization } from 'hooks/useLocalization';

interface Props {
	workAreas: WorkArea[];
	selectedIds: string[];
	onChange: (selected: string[]) => void;
}

export function WorkAreaMenu({ workAreas, selectedIds, onChange }: Props) {
	const { messages, translate } = useLocalization();

	const [active, setActive] = useState(false);

	const toggleActive = () => setActive(!active);

	const count = selectedIds.length;

	const choices = workAreas.map((workArea, i) => {
		return {
			label: workArea.name || `Work area ${i + 1}`,
			value: workArea.id,
		};
	});

	return (
		<Popover
			sectioned
			preferredAlignment="left"
			active={active}
			activator={
				<Button onClick={toggleActive} disclosure="down">
					{count === 0
						? messages.allWorkAreas
						: (translate(messages.viewWorkAreas, { count }) as string)}
				</Button>
			}
			onClose={toggleActive}
		>
			<ChoiceList
				title={messages.workArea}
				titleHidden
				choices={choices}
				selected={selectedIds}
				onChange={onChange}
				allowMultiple
			/>
			<Button plain disabled={count <= 0} onClick={() => onChange([])}>
				{messages.clear}
			</Button>
		</Popover>
	);
}
