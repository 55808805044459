import { Badge } from '@shopify/polaris';
import { OrderExceptionStatus } from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';
import { useConfig } from 'hooks/useConfig';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	status?: OrderExceptionStatus | null;
}

export function ExceptionBadge({ status }: Props) {
	const { messages } = useLocalization();
	const { config } = useConfig();

	if (status === OrderExceptionStatus.Cleared) {
		return (
			<Badge status="success">
				{config?.healingEnabled ? messages.cleared : messages.manuallyResolved}
			</Badge>
		);
	}

	if (status === OrderExceptionStatus.Canceled) {
		return <Badge>{messages.canceled}</Badge>;
	}

	if (status === OrderExceptionStatus.Resolvable) {
		return <Badge status="warning">{messages.resolvable}</Badge>;
	}

	if (status === OrderExceptionStatus.Unresolvable) {
		return <Badge status="critical">{messages.unresolvable}</Badge>;
	}

	if (status === OrderExceptionStatus.Resolving) {
		return <Badge status="info">{messages.resolving}</Badge>;
	}

	return <NoData />;
}
