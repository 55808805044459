import { IndexTable, Link } from '@shopify/polaris';

import { NoData } from 'components/NoData';
import * as routes from 'routes';

interface ExternalIdCellProps {
	id?: string;
	jobId?: string;
}

export function OrderIdCell({ id, jobId }: ExternalIdCellProps) {
	return (
		<IndexTable.Cell>
			{id ? (
				<Link url={routes.order(id)} removeUnderline>
					{jobId}
				</Link>
			) : (
				<NoData />
			)}
		</IndexTable.Cell>
	);
}
