import { LinesViews } from '@sixriver/fulfillment-api-schema';

import { Tab, ViewTabs } from 'components/ViewTabs';
import { noop } from 'helpers/noop';
import { useLocalization } from 'hooks/useLocalization';

export enum LinesTab {
	all = 'all',
	exceptions = 'exceptions',
	unassigned = 'unassigned',
	inProgress = 'inProgress',
	completed = 'completed',
}

export interface LinesTabsCounts {
	all?: number;
	exceptions?: number;
	inProgress?: number;
	completed?: number;
	unassigned?: number;
}

export interface LinesTabsProps {
	tabs: LinesTab[];
	counts: LinesTabsCounts;
	selected: string;
	onSelect?: (tab: LinesViews) => void;
}

export function LinesTabs({ tabs, counts, selected, onSelect = noop }: LinesTabsProps) {
	const { messages } = useLocalization();
	const tabsToShow = tabs
		.map((tab) => {
			switch (tab) {
				case LinesTab.all:
					return {
						label: messages.all,
						metaLabel: counts.all ?? 0,
						id: LinesViews.All,
					};
				case LinesTab.exceptions:
					return {
						label: messages.exceptions,
						metaLabel: counts.exceptions ?? 0,
						id: LinesViews.Exceptions,
					};
				case LinesTab.unassigned:
					return {
						label: messages.unassigned,
						metaLabel: counts.unassigned ?? 0,
						id: LinesViews.Unassigned,
					};
				case LinesTab.inProgress:
					return {
						label: messages.inProgress,
						metaLabel: counts.inProgress ?? 0,
						id: LinesViews.InProgress,
					};
				case LinesTab.completed:
					return {
						label: messages.completed,
						metaLabel: counts.completed ?? 0,
						id: LinesViews.Complete,
					};
				default:
					return null;
			}
		})
		.filter((tab) => !!tab);

	return <ViewTabs tabs={tabsToShow as Tab[]} selected={selected} onSelect={onSelect} />;
}
