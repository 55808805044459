import { useContext } from 'react';

import { LinesContext } from './LinesProvider';

export function useLinesContext() {
	const context = useContext(LinesContext);
	if (context === undefined) {
		throw new Error('useLinesContext must be used within a <LinesProvider/>');
	}
	return context;
}
