import { AppliedFilterInterface, FilterInterface, Link } from '@shopify/polaris';
import {
	ProductSummaryPage,
	ProductSummaryInventory,
	StorageLocationType,
	ProductSummary,
} from '@sixriver/fulfillment-api-schema';

import styles from './ProductsTable.module.css';
import {
	DataTable,
	DataTableView,
	Column,
	PaginationCursors,
	SetPaginationCursors,
} from 'components/DataTable';
import { NoData } from 'components/NoData';
import { Peek } from 'components/Peek';
import { useConfig } from 'hooks/useConfig';
import { SetFilters } from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';

interface TableProps {
	loading?: boolean;
	data?: ProductSummaryPage;
	views?: DataTableView[];
	selectedView: StorageLocationType | 'all';
	sorts: Array<{ label: string; value: string }>;
	selectedSort?: string;
	query?: string;
	filters: FilterInterface[];
	appliedFilters?: AppliedFilterInterface[];
	setFilters: SetFilters;
	paginationCursors: PaginationCursors;
	setPaginationCursors: SetPaginationCursors;
}

interface PeekProps {
	productId: string;
	customerId: string;
	locationInventories: ProductSummaryInventory[];
}

function ProductPeek({ productId, customerId, locationInventories }: PeekProps) {
	const { messages, translate } = useLocalization();

	const rows = Array.from(locationInventories).sort((a, b) => b.liveQuantity - a.liveQuantity);

	const count = rows.length;

	const activator = <div>{translate(messages.countLocations, { count })}</div>;

	const action =
		count >= 5
			? {
					url: routes.product(productId),
					content: messages.viewAllLocations,
			  }
			: undefined;

	return (
		<Peek activator={activator} title={customerId} action={action}>
			<table className={styles.peekTable}>
				<thead>
					<tr>
						<th>{messages.location}</th>
						<th>{messages.locationType}</th>
						<th>{messages.quantity}</th>
					</tr>
				</thead>
				<tbody>
					{rows.slice(0, 5).map((locationInventory) => {
						return (
							<tr key={locationInventory.address}>
								<td>{locationInventory.address}</td>
								<td>
									{(locationInventory.locationType
										? messages.locationTypes[locationInventory.locationType]
										: undefined) || <NoData />}
								</td>
								<td>{locationInventory.liveQuantity}</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</Peek>
	);
}

export function ProductsTable({
	loading = false,
	data,
	views,
	selectedView,
	sorts,
	selectedSort,
	query,
	filters,
	appliedFilters,
	setFilters,
	paginationCursors,
	setPaginationCursors,
}: TableProps) {
	const { messages, formatNumber } = useLocalization();

	const { config } = useConfig();

	const { results, cursor } = data || {};

	const columns: Column[] = [
		{
			type: 'text',
			heading: messages.item,
		},
		{
			type: 'text',
			heading: config?.inventoryEnabled ? messages.sku : messages.name,
		},
		{
			type: 'text',
			heading: messages.description,
		},
	];

	if (config?.inventoryEnabled) {
		columns.push(
			{
				type: 'text',
				heading: messages.location,
			},
			{
				type: 'text',
				heading: messages.quantity,
			},
			{
				type: 'text',
				heading: messages.plannedRemovals,
			},
			{
				type: 'text',
				heading: messages.available,
			},
		);
	}

	const rows = (results || []).map((product: ProductSummary) => {
		const { id, customerIdentifier, name, description, inventories } = product;

		const row = [
			<Link url={routes.product(id)} key="id" removeUnderline>
				{customerIdentifier}
			</Link>,
			name ?? <NoData />,
			description ?? <NoData />,
		];

		if (config?.inventoryEnabled) {
			const quantity = inventories.reduce((a, b) => a + b.liveQuantity, 0);
			const reserved = Math.abs(inventories.reduce((a, b) => a + b.totalPlannedRemoveQuantity, 0));
			const balance = inventories.reduce((a, b) => a + b.balance, 0);

			row.push(
				inventories.length === 1 ? (
					inventories[0].address
				) : inventories.length > 1 ? (
					<ProductPeek
						productId={id}
						customerId={customerIdentifier}
						locationInventories={inventories}
					/>
				) : (
					<NoData />
				),
			);
			row.push(formatNumber(quantity));
			row.push(formatNumber(reserved));
			row.push(formatNumber(balance));
		}

		return row;
	});

	return (
		<DataTable
			loading={loading}
			columns={columns}
			rows={rows}
			views={config?.inventoryEnabled && views?.length ? views : undefined}
			selectedView={selectedView}
			sortChoices={sorts}
			sortValue={selectedSort}
			query={query}
			queryPlaceholder={messages.filterProducts}
			filters={filters}
			appliedFilters={appliedFilters}
			setFilters={setFilters}
			pageInfo={{ endCursor: cursor }}
			paginationCursors={paginationCursors}
			setPaginationCursors={setPaginationCursors}
			emptyStateHeading={messages.productsNotFound}
		/>
	);
}
