import { NoData } from 'components/NoData';
import { isToday } from 'helpers/time';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	date?: Date | number | string;
}

export function DateTime({ date }: Props) {
	const { formatTime, formatDateTime } = useLocalization();

	if (date) {
		const str = isToday(new Date(date)) ? formatTime(date) : formatDateTime(date);
		const iso = new Date(date).toISOString();

		return (
			<div>
				<time dateTime={iso}>{str}</time>
			</div>
		);
	}

	return <NoData />;
}
