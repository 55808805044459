import {
	Banner,
	Card,
	Layout,
	Link,
	Page,
	Stack,
	TextStyle,
	Thumbnail,
	Tooltip,
} from '@shopify/polaris';
import { Container, ContainerTypeWithFallback } from '@sixriver/fulfillment-api-schema';
import { useRouteMatch } from 'react-router-dom';
import { useQuery } from 'urql';

import { GET_CONTAINER_QUERY } from './Containers.graphql';
import { CardDetails } from 'components/CardDetails';
import { Error } from 'components/Error';
import { NoData } from 'components/NoData';
import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';
import { transformImageUrl } from 'utils/image';

interface Params {
	containerId: string;
}

export function ContainerDetails() {
	const { messages, formatLength, formatVolume, formatWeight } = useLocalization();

	// Routing
	const {
		params: { containerId },
	} = useRouteMatch<Params>();

	// Queries
	const [{ fetching: fetchingContainer, error: containerError, data: containerData }] = useQuery<
		{ container: Container },
		{ id: string }
	>({
		query: GET_CONTAINER_QUERY,
		requestPolicy: 'network-only',
		variables: {
			id: containerId,
		},
	});

	const container = containerData?.container;

	// Guards
	const loading = fetchingContainer;
	const error = containerError;

	if (loading) {
		return null;
	}

	if (error) {
		return <Error graphQLError={error} />;
	}

	if (!container) {
		return <Error message={messages.dataNotFound} />;
	}

	// computed

	const numberOfContainerSubsections = (container.columns || 1) * (container.rows || 1);
	const containerHasSubsections = numberOfContainerSubsections > 1;
	const wholeContainerDimensions = {
		length: container.length * (container.rows || 1),
		width: container.width * (container.columns || 1),
		height: container.height,
	};
	const wholeContainerVolume = container.volume * numberOfContainerSubsections;

	// Render
	return (
		<Page
			fullWidth
			thumbnail={
				container.imageURL ? (
					<Tooltip
						content={
							<img src={transformImageUrl(container.imageURL)} alt={container.name} width="100%" />
						}
					>
						<Thumbnail
							source={transformImageUrl(container.imageURL)}
							alt={container.name}
							size="large"
						/>
					</Tooltip>
				) : undefined
			}
			title={container?.externalId || ((<NoData />) as unknown as string)}
			subtitle={container ? messages.containerTypes[container.type] : undefined}
			primaryAction={
				<Link removeUnderline url={routes.editContainer(container.id)}>
					{messages.edit}
				</Link>
			}
		>
			<Layout>
				{/* Container Details */}
				<Layout.Section>
					<Card title={messages.containerDetails} sectioned>
						<CardDetails
							loading={fetchingContainer}
							primary={[
								{
									label: messages.containerType,
									content: messages.containerTypes[
										container?.type || ContainerTypeWithFallback.Unknown
									] || <NoData />,
								},
								{
									label: messages.containerName,
									content: container.name || <NoData />,
								},
								{
									label: messages.externalId,
									content: container.externalId || <NoData />,
								},
								{
									label: messages.description,
									content:
										!container?.description || container?.description === container?.name ? (
											<NoData />
										) : (
											container?.description
										),
								},
								...(container.type === ContainerTypeWithFallback.Shipping
									? [
											{
												label: messages.barcode,
												content: container?.barcode || <NoData />,
											},
									  ]
									: []),
								...(container.type === ContainerTypeWithFallback.Shipping
									? [
											{
												label: messages.status,
												content: (
													<Stack spacing="extraTight">
														<input type="checkbox" checked={container.enabled} disabled />
														<span>{container.enabled ? messages.active : messages.inactive}</span>
													</Stack>
												),
											},
											{
												label: messages.trackAsInventory,
												content: (
													<Stack spacing="extraTight">
														<input type="checkbox" checked={container.inventory} disabled />
														<span>
															{container.inventory ? messages.enabled : messages.disabled}
														</span>
													</Stack>
												),
											},
									  ]
									: []),
							]}
						/>
						{container.inventory ? (
							<>
								<br />
								<Banner
									secondaryAction={{
										content: messages.viewInventoryLevels,
										url: routes.product(container.id),
									}}
									status="info"
								>
									<p>{messages.containerTracked}</p>
								</Banner>
							</>
						) : null}
					</Card>
				</Layout.Section>

				{/* Dimensions */}
				<Layout.Section secondary>
					<Card title={messages.containerDimensions}>
						<Card.Section
							title={
								containerHasSubsections ? (
									<TextStyle variation="strong">{messages.wholeContainer}</TextStyle>
								) : null
							}
						>
							<CardDetails
								primary={[
									{
										label: messages.length,
										content: formatLength(wholeContainerDimensions.length),
									},
									{
										label: messages.width,
										content: formatLength(wholeContainerDimensions.width),
									},
									{
										label: messages.height,
										content: formatLength(wholeContainerDimensions.height),
									},
									{
										label: messages.volume,
										content: formatVolume(wholeContainerVolume),
									},
									...(container?.type === ContainerTypeWithFallback.Shipping
										? [
												{
													label: messages.weight,
													content:
														(container?.weight || 0) > 0 ? (
															formatWeight(container?.weight)
														) : (
															<NoData />
														),
												},
										  ]
										: []),
								]}
							/>
						</Card.Section>

						{/* Container subsections info */}
						{containerHasSubsections ? (
							<>
								<hr style={{ width: '100%' }} />
								<Card.Section
									title={<TextStyle variation="strong">{messages.subsection}</TextStyle>}
								>
									<CardDetails
										primary={[
											{
												label: messages.columns,
												content: container?.columns || <NoData />,
											},
											{
												label: messages.rows,
												content: container?.rows || <NoData />,
											},
										]}
									/>
								</Card.Section>
								<hr />
								<Card.Section
									title={<TextStyle variation="strong">{messages.subsectionDimensions}</TextStyle>}
								>
									<CardDetails
										primary={[
											{
												label: messages.length,
												content: formatLength(container?.length),
											},
											{
												label: messages.width,
												content: formatLength(container?.width),
											},
											{
												label: messages.height,
												content: formatLength(container?.height),
											},
											{
												label: messages.volume,
												content: formatVolume(container.volume),
											},
										]}
									/>
								</Card.Section>
							</>
						) : null}
					</Card>
				</Layout.Section>
			</Layout>
		</Page>
	);
}
