import { IndexTable } from '@shopify/polaris';
import { ReworkJobType } from '@sixriver/fulfillment-api-schema';

import { JobType } from './JobType';

export function JobTypeCell({ jobType }: { jobType: ReworkJobType }) {
	return (
		<IndexTable.Cell>
			<JobType jobType={jobType} />
		</IndexTable.Cell>
	);
}
