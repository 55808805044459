import { Badge, Stack } from '@shopify/polaris';
import { ContainerStatus, LaneContainer } from '@sixriver/fulfillment-api-schema';

import { useLocalization } from 'hooks/useLocalization';

interface Props {
	container?: LaneContainer;
}

export function LaneContainerStatusBadge({ container }: Props): JSX.Element | null {
	const { messages } = useLocalization();

	if (!container) {
		return null;
	}

	let badge = <Badge>{messages.waitingForContainers}</Badge>;

	if (container.containerStatus === ContainerStatus.Created) {
		badge = <Badge>{messages.containerCreated}</Badge>;
	}

	if (container.containerStatus === ContainerStatus.ReadyToLoad) {
		badge = <Badge>{messages.readyToLoad}</Badge>;
	}

	if (container.containerStatus === ContainerStatus.Shipped) {
		badge = <Badge status="success">{messages.shipped}</Badge>;
	}

	return <Stack spacing="extraTight">{badge}</Stack>;
}
