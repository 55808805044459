import { Page, Layout, Stack, Card, Heading } from '@shopify/polaris';
import {
	BulkOrder as FulfillmentAPIBulkOrder,
	QueryBulkOrderByIdArgs,
} from '@sixriver/fulfillment-api-schema';
import { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { BULK_ORDER_QUERY } from './BulkOrder.graphql';
import { Details } from './Details';
import { Overview } from './Overview';
import { Products } from './Products';
import { StagingLocations } from './StagingLocations';
import { BulkOrderProgressBadge } from 'components/BulkOrderProgressBadge';
import { Error } from 'components/Error';
import { ErrorBanner } from 'components/ErrorBanner';
import { TimezoneFooter } from 'components/TimezoneFooter';
import { useLocalization } from 'hooks/useLocalization';
import { usePolling } from 'hooks/usePolling';
import { usePollingQuery } from 'hooks/usePollingQuery';

export interface RouteMatchParams {
	bulkOrderId: string;
}

export function BulkOrder() {
	const { messages } = useLocalization();
	const {
		params: { bulkOrderId },
	} = useRouteMatch<RouteMatchParams>();

	const { queryPollInterval } = usePolling();

	const [{ fetching: orderFetching, error: orderError, data: bulkOrderData }] = usePollingQuery<
		{ bulkOrderById: FulfillmentAPIBulkOrder },
		QueryBulkOrderByIdArgs
	>({
		query: BULK_ORDER_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			id: bulkOrderId,
		},
	});

	const { bulkOrderById: order } = bulkOrderData || {};

	const [isBannerErrorVisible, setIsBannerErrorVisible] = useState(false);

	const fetching = orderFetching;

	const error = orderError;

	if (error) {
		return <Error graphQLError={error} />;
	}

	if (!orderFetching && !order) {
		return <Error heading={messages.orderNotFound} />;
	}

	return (
		<Page
			fullWidth
			title={`${messages.batch} ${order?.lpn}` || undefined}
			titleMetadata={
				<Stack spacing="extraTight">
					<BulkOrderProgressBadge progress={order?.progress} />
				</Stack>
			}
		>
			<Layout>
				<Layout.Section>
					<Layout.Section>
						<ErrorBanner
							isVisible={isBannerErrorVisible}
							onDismiss={() => {
								setIsBannerErrorVisible(false);
							}}
						/>
					</Layout.Section>
					<Layout.Section>
						<div data-testid="details">
							<Overview loading={fetching} order={order} />
						</div>
					</Layout.Section>
					<Layout.Section>
						<div data-testid="details">
							<Details loading={fetching} order={order} />
						</div>
					</Layout.Section>
					<Layout.Section>
						<div data-testid="products">
							<Products order={order} />
						</div>
					</Layout.Section>
					<Layout.Section>
						<div data-testid="locations">
							<StagingLocations order={order} />
						</div>
					</Layout.Section>
					<Layout.Section>
						<TimezoneFooter />
					</Layout.Section>
				</Layout.Section>
				<Layout.Section secondary>
					<Layout.Section>
						<Card>
							<Card.Section>
								<Stack distribution="equalSpacing">
									<Heading>{messages.internalNote}</Heading>
								</Stack>
							</Card.Section>
							<Card.Section>
								<p>{order?.description}</p>
							</Card.Section>
						</Card>
					</Layout.Section>
				</Layout.Section>
			</Layout>
		</Page>
	);
}
