import { Card, Badge } from '@shopify/polaris';
import { ReplenJob } from '@sixriver/fulfillment-api-schema';

import { CardDetails } from 'components/CardDetails';
import { countTaskExceptions } from 'helpers/exception';
import { calculateReplenTasksUnits } from 'helpers/replen';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	loading?: boolean;
	job?: ReplenJob;
	isHealingEnabled?: boolean;
}

export function ReplenishJobProgress({ loading, job }: Props) {
	const { messages, translate } = useLocalization();

	const { replenished: unitsReplenished, total: unitsTotal } = calculateReplenTasksUnits(
		job?.tasks,
	);

	const linesReplenished = job?.tasks?.reduce(
		(replenished: number, task) =>
			task.doneQuantity === task.quantity ? (replenished += 1) : replenished,
		0,
	);

	const numberOfExceptions = countTaskExceptions(job?.tasks || []);

	return (
		<Card sectioned title={messages.progress}>
			<CardDetails
				loading={loading}
				primary={[
					{
						label: messages.unitsReplenished,
						content: translate(messages.xOfY, {
							x: unitsReplenished,
							y: unitsTotal,
						}),
					},
					{
						label: messages.linesReplenished,
						content: job?.tasks
							? translate(messages.xOfY, {
									x: linesReplenished,
									y: job.tasks.length,
							  })
							: null,
					},
				]}
				secondary={[
					{
						label: messages.exceptions,
						content: (
							<Badge status={numberOfExceptions > 0 ? 'critical' : undefined}>
								{numberOfExceptions.toString()}
							</Badge>
						),
					},
				]}
			/>
		</Card>
	);
}
