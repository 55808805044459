import { Page, Layout, Card, Thumbnail, Banner } from '@shopify/polaris';
import { Product, ProductKind, QueryProductArgs } from '@sixriver/fulfillment-api-schema';
import { useRouteMatch } from 'react-router-dom';

import { LocationsTable } from './LocationsTable';
import { PRODUCT_QUERY } from './Product.graphql';
import { AutoRefresh } from 'components/AutoRefresh';
import { CardDetails } from 'components/CardDetails';
import { Error } from 'components/Error';
import { NoData } from 'components/NoData';
import { TimezoneFooter } from 'components/TimezoneFooter';
import { useConfig } from 'hooks/useConfig';
import { useLocalization } from 'hooks/useLocalization';
import { usePolling } from 'hooks/usePolling';
import { usePollingQuery } from 'hooks/usePollingQuery';
import * as routes from 'routes';
import { transformImageUrl } from 'utils/image';

export function ProductDetails() {
	const { messages, translate, formatLength, formatWeight } = useLocalization();

	const {
		params: { productId },
	} = useRouteMatch<{ productId: string }>();

	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();

	const [{ fetching, data, error }] = usePollingQuery<{ product: Product }, QueryProductArgs>({
		query: PRODUCT_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			id: productId,
		},
	});

	const { config } = useConfig();

	const product = data?.product;
	const imgPath = product?.image;
	const imgDesc = product?.description || '';
	const imgThumb = <Thumbnail source={transformImageUrl(imgPath)} alt={imgDesc} size="large" />;
	const isContainer = product?.kind === ProductKind.Packaging;

	if (error) {
		return <Error graphQLError={error} />;
	}

	return (
		<Page
			title={product?.customerIdentifier}
			thumbnail={imgThumb}
			secondaryActions={[
				{
					content: messages.edit,
					url: routes.editProduct(product?.id),
					disabled: isContainer,
				},
			]}
		>
			<Layout>
				<Layout.Section>
					<AutoRefresh
						pollingEnabled={pollingEnabled}
						togglePolling={togglePolling}
						discriminatorData={data}
					/>
				</Layout.Section>
				<Layout.Section>
					<Card sectioned title={messages.productDetails}>
						<CardDetails
							loading={fetching}
							primary={[
								{
									label: messages.item,
									content: product?.customerIdentifier,
								},
								{
									label: config?.inventoryEnabled ? messages.sku : messages.name,
									content: product?.name,
								},
								{
									label: messages.description,
									content: product?.description,
								},
								{
									label: messages.identifiers,
									content: product?.scanValues.join(', ') || <NoData />,
								},
								{
									label: messages.productType,
									content: isContainer
										? translate(messages.productTypes.packaging)
										: translate(messages.productTypes.product),
								},
							]}
						/>
						{isContainer ? (
							<>
								<br />
								<Banner
									secondaryAction={{
										content: messages.viewContainer,
										url: routes.container(product?.id),
									}}
									status="info"
								>
									<p>{messages.productIsContainer}</p>
								</Banner>
							</>
						) : null}
					</Card>
				</Layout.Section>
				<Layout.Section>
					<Card sectioned title={messages.productAttributes}>
						<CardDetails
							loading={fetching}
							primary={[
								{
									label: messages.length,
									content: isContainer ? 'N/A' : formatLength(product?.length),
								},
								{
									label: messages.width,
									content: isContainer ? 'N/A' : formatLength(product?.width),
								},
								{
									label: messages.height,
									content: isContainer ? 'N/A' : formatLength(product?.height),
								},
								{
									label: messages.weight,
									content: isContainer ? 'N/A' : formatWeight(product?.weight),
								},
							]}
						/>
					</Card>
				</Layout.Section>
				{config?.inventoryEnabled ? (
					<Layout.Section>
						<LocationsTable storageLocationInventories={product?.locations || []} />
					</Layout.Section>
				) : null}
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</Page>
	);
}
