import { IndexTable } from '@shopify/polaris';

import { DateTime } from 'components/DateTime';

interface DateReceivedCellProps {
	createdAt?: Date;
}

export function DateReceivedCell({ createdAt }: DateReceivedCellProps) {
	return (
		<IndexTable.Cell>
			<DateTime date={createdAt} />
		</IndexTable.Cell>
	);
}
