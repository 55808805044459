import { Button, Card, Layout, Page, Spinner } from '@shopify/polaris';
import { Device as ApiDevice, DeviceType } from '@sixriver/fulfillment-api-schema';
import { useRouteMatch } from 'react-router-dom';

import { DeviceBadge } from './DeviceBadge';
import { DeviceBattery } from './DeviceBattery';
import { DEVICE_QUERY } from './Devices.graphql';
import RelatedJobs from './RelatedJobs';
import RelatedOrders from './RelatedOrders';
import { AutoRefresh } from 'components/AutoRefresh';
import { CardDetails } from 'components/CardDetails';
import { DateTime } from 'components/DateTime';
import { Error } from 'components/Error';
import { NoData } from 'components/NoData';
import { useConfig } from 'hooks/useConfig';
import { useLocalization } from 'hooks/useLocalization';
import { usePolling } from 'hooks/usePolling';
import { usePollingQuery } from 'hooks/usePollingQuery';
import * as routes from 'routes';

export default function Device(): JSX.Element {
	const { messages } = useLocalization();

	const {
		params: { deviceId },
	} = useRouteMatch<{ deviceId: string }>();

	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();

	// Data
	const [{ fetching, data, error }] = usePollingQuery<{ device: ApiDevice }, { name: string }>({
		query: DEVICE_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			name: deviceId,
		},
	});
	const { config } = useConfig();
	const device = data?.device;

	if (fetching && !device) {
		return <Spinner />;
	}

	if (error) {
		return <Error graphQLError={error} />;
	}

	if (!device) {
		return <Error message={messages.deviceNotFound} />;
	}

	return (
		<Page title={device?.name || ''}>
			<Layout>
				<Layout.Section>
					<AutoRefresh
						pollingEnabled={pollingEnabled}
						togglePolling={togglePolling}
						discriminatorData={data}
					/>
				</Layout.Section>
				<Layout.Section>
					<Card title={messages.profile}>
						<Card.Section>
							<CardDetails
								loading={fetching}
								primary={[
									{
										label: messages.name,
										content: device.name,
									},
									{
										label: messages.model,
										content: device ? (
											messages.deviceTypes[device.type as keyof typeof messages.deviceTypes]
										) : (
											<NoData />
										),
									},
									{
										label: messages.network,
										content: device.ssid,
									},
								]}
							/>
						</Card.Section>
						{device.type === DeviceType.TerminalOnWheels ||
						device.type === DeviceType.Handheld ? null : (
							<Card.Section>
								<CardDetails
									loading={fetching}
									primary={[
										{
											label: messages.batteryLevel,
											content: <DeviceBattery device={device} config={config} />,
										},
										{
											label: messages.lastCalibrated,
											content: <DateTime date={device.calibratedAt} />,
										},
									]}
								/>
							</Card.Section>
						)}
					</Card>
				</Layout.Section>
				<Layout.Section>
					<Card title={messages.activity}>
						<Card.Section>
							<CardDetails
								loading={fetching}
								primary={[
									{
										label: messages.status,
										content: <DeviceBadge state={device.state} />,
									},
									{
										label: messages.lastActive,
										content: <DateTime date={device.updatedAt} />,
									},
									{
										label: messages.workArea,
										content: device.workArea?.name,
									},
									...(device.type === DeviceType.TerminalOnWheels ||
									device.type === DeviceType.Handheld
										? []
										: [
												{
													label: messages.orders,
													content: <RelatedOrders device={device} />,
												},
												{
													label: messages.jobs,
													content: <RelatedJobs device={device} />,
												},
										  ]),
								]}
							/>
						</Card.Section>
						{device.type === DeviceType.Chuck ? (
							<Card.Section>
								<Button plain url={`${routes.floorView()}/?chuck=${device.id}`}>
									{messages.findThisChuck}
								</Button>
							</Card.Section>
						) : null}
					</Card>
				</Layout.Section>
			</Layout>
		</Page>
	);
}
