import { Stack } from '@shopify/polaris';

import { Chuck } from './Chuck.type';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	chucks: Chuck[];
}

export function ChuckCount({ chucks }: Props) {
	const { messages, translate } = useLocalization();
	const onlineChucks = chucks.filter((chuck) => chuck.online);

	return (
		<Stack spacing="loose">
			<b>{translate(messages.countOnline, { count: onlineChucks.length })}</b>
			<b>{translate(messages.countOffline, { count: chucks.length - onlineChucks.length })}</b>
		</Stack>
	);
}
