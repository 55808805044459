import { Card, DisplayText, Layout, Page, TextContainer } from '@shopify/polaris';

import Lock from './Lock.svg';
import styles from './NoAccess.module.css';
import { useLocalization } from 'hooks/useLocalization';

export function NoAccess(): JSX.Element {
	const { messages } = useLocalization();

	return (
		<Page>
			<Layout.Section>
				<Card>
					<div className={styles.container}>
						<img src={Lock} alt="Lock" width="200" height="200" />
						<div className={styles.textContainer}>
							<TextContainer>
								<DisplayText size="small">{messages.notAuthorized}</DisplayText>
								<p>{messages.contactAdministrator}</p>
							</TextContainer>
						</div>
					</div>
				</Card>
			</Layout.Section>
		</Page>
	);
}
