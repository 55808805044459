import { Error } from 'components/Error';
import { InlineFrame } from 'components/InlineFrame';
import { useConfig } from 'hooks/useConfig';
import { useLocalization } from 'hooks/useLocalization';

export function Move() {
	const { messages } = useLocalization();
	const { config } = useConfig();

	if (config?.moveJobsUrl) {
		return <InlineFrame title={messages.dashMoveJobs} src={config.moveJobsUrl} />;
	}

	return <Error />;
}
