import gql from 'graphql-tag';

export const REWORK_JOBS_QUERY = gql`
	query ReworkJobs(
		$limit: Float
		$cursor: String
		$searchText: [String!]
		$externalId: String
		$statuses: [GoalStates!]
		$dateReceivedFrom: DateTime
		$dateReceivedTo: DateTime
		$orderByDirection: OrderByDirection
	) {
		reworkJobs(
			cursor: $cursor
			limit: $limit
			searchText: $searchText
			externalId: $externalId
			statuses: $statuses
			dateReceivedFrom: $dateReceivedFrom
			dateReceivedTo: $dateReceivedTo
			orderByDirection: $orderByDirection
		) {
			cursor
			results {
				id
				createdAt
				merchant
				status
				reworkQuantity
				processedQuantity
				issue
				issueId
				jobOriginType
				jobOriginExternalId
				jobOriginId
				product {
					id
				}
			}
		}
	}
`;

export const REWORK_COUNTS_QUERY = gql`
	query reworkJobCount(
		$limit: Float
		$cursor: String
		$searchText: [String!]
		$dateReceivedFrom: DateTime
		$dateReceivedTo: DateTime
	) {
		PendingCount: reworkJobCount(
			limit: $limit
			cursor: $cursor
			searchText: $searchText
			statuses: [CREATING, CREATED, READY]
			dateReceivedFrom: $dateReceivedFrom
			dateReceivedTo: $dateReceivedTo
		) {
			count
		}

		InProgressCount: reworkJobCount(
			limit: $limit
			cursor: $cursor
			searchText: $searchText
			statuses: [RUNNING, PAUSED, TERMINATE_REQUESTED, TERMINATING, CANCEL_REQUESTED, CANCELLING]
			dateReceivedFrom: $dateReceivedFrom
		) {
			count
		}

		ClosedCount: reworkJobCount(
			limit: $limit
			cursor: $cursor
			searchText: $searchText
			statuses: [TERMINATED, CANCELLED, COMPLETE]
			dateReceivedFrom: $dateReceivedFrom
		) {
			count
		}
	}
`;
