import gql from 'graphql-tag';

export const SHIPPING_NOTICE_SUMMARIES_QUERY = gql`
	query ShippingNoticeSummaries(
		$createdAtFrom: DateTime
		$etaFrom: DateTime
		$etaTo: DateTime
		$hasIssues: Boolean
		$slaAtRisk: Boolean
		$limit: Int
		$merchants: [String!]
		$orderBy: ShippingNoticeSummaryOrderByField
		$orderDirection: OrderByDirection
		$searchText: String
		$statuses: [ShippingNoticeStatus!]!
		$cursor: String
		$internalTransfer: Boolean
	) {
		shippingNoticeSummaries(
			createdAtFrom: $createdAtFrom
			etaFrom: $etaFrom
			etaTo: $etaTo
			hasIssues: $hasIssues
			slaAtRisk: $slaAtRisk
			limit: $limit
			merchants: $merchants
			orderBy: $orderBy
			orderDirection: $orderDirection
			searchText: $searchText
			statuses: $statuses
			cursor: $cursor
			internalTransfer: $internalTransfer
		) {
			results {
				id
				externalId
				merchant
				inboundLineCount
				issueCount
				issueReasons
				quantityExpected
				quantityReceived
				status
				earliestEta
				earliestArrivedAt
				uninspectedLineCount
				internalTransfer
			}
			cursor
		}
	}
`;

export const MERCHANTS_QUERY = gql`
	query shippingNoticeMerchants {
		shippingNoticeMerchants
	}
`;

export const STATIC_COUNTS_QUERY = gql`
	query ShippingNoticesCount($createdAtFrom: DateTime, $etaTo: DateTime, $etaFrom: DateTime) {
		StaticArrivingTodayMerchantsCount: shippingNoticesCount(
			createdAtFrom: $createdAtFrom
			etaTo: $etaTo
			etaFrom: $etaFrom
			internalTransfer: false
		) {
			count
		}

		StaticArrivingTodayNodeToNodeCount: shippingNoticesCount(
			createdAtFrom: $createdAtFrom
			etaTo: $etaTo
			etaFrom: $etaFrom
			internalTransfer: true
		) {
			count
		}

		StaticArrivingTodayMerchantsUnitsCount: shippingNoticeSummaries(
			createdAtFrom: $createdAtFrom
			etaTo: $etaTo
			etaFrom: $etaFrom
			internalTransfer: false
		) {
			results {
				id
				quantityExpected
			}
		}

		StaticArrivingTodayNodeToNodeUnitsCount: shippingNoticeSummaries(
			createdAtFrom: $createdAtFrom
			etaTo: $etaTo
			etaFrom: $etaFrom
			internalTransfer: true
		) {
			results {
				id
				quantityExpected
			}
		}

		StaticAtRiskSLACount: shippingNoticesCount(
			createdAtFrom: $createdAtFrom
			statuses: [Arrived, Receiving]
			slaAtRisk: true
		) {
			count
		}
	}
`;

export const COUNTS_QUERY = gql`
	query ShippingNoticesCount(
		$createdAtFrom: DateTime
		$etaTo: DateTime
		$etaFrom: DateTime
		$hasIssues: Boolean
		$merchants: [String!]
		$searchText: String
		$slaAtRisk: Boolean
	) {
		SubmittedCount: shippingNoticesCount(
			createdAtFrom: $createdAtFrom
			etaTo: $etaTo
			etaFrom: $etaFrom
			hasIssues: $hasIssues
			merchants: $merchants
			searchText: $searchText
			statuses: [Submitted]
			slaAtRisk: $slaAtRisk
		) {
			count
		}
		InTransitCount: shippingNoticesCount(
			createdAtFrom: $createdAtFrom
			etaTo: $etaTo
			etaFrom: $etaFrom
			hasIssues: $hasIssues
			merchants: $merchants
			searchText: $searchText
			statuses: [InTransit]
			slaAtRisk: $slaAtRisk
		) {
			count
		}
		ArrivedCount: shippingNoticesCount(
			createdAtFrom: $createdAtFrom
			etaTo: $etaTo
			etaFrom: $etaFrom
			hasIssues: $hasIssues
			merchants: $merchants
			searchText: $searchText
			statuses: [Arrived]
			slaAtRisk: $slaAtRisk
		) {
			count
		}
		ReceivingCount: shippingNoticesCount(
			createdAtFrom: $createdAtFrom
			etaTo: $etaTo
			etaFrom: $etaFrom
			hasIssues: $hasIssues
			merchants: $merchants
			searchText: $searchText
			statuses: [Receiving]
			slaAtRisk: $slaAtRisk
		) {
			count
		}
		ClosedCount: shippingNoticesCount(
			createdAtFrom: $createdAtFrom
			etaTo: $etaTo
			etaFrom: $etaFrom
			hasIssues: $hasIssues
			merchants: $merchants
			searchText: $searchText
			statuses: [Completed]
			slaAtRisk: $slaAtRisk
		) {
			count
		}
		CancelledCount: shippingNoticesCount(
			createdAtFrom: $createdAtFrom
			etaTo: $etaTo
			etaFrom: $etaFrom
			hasIssues: $hasIssues
			merchants: $merchants
			searchText: $searchText
			statuses: [Cancelled]
			slaAtRisk: $slaAtRisk
		) {
			count
		}
	}
`;
