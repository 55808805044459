import { Card, Badge } from '@shopify/polaris';
import { Sortation } from '@sixriver/fulfillment-api-schema';

import { CardDetails } from 'components/CardDetails';
import { NoData } from 'components/NoData';
import { countSortationOrderExceptions, countSortationPickingExceptions } from 'helpers/exception';
import { calculateUnits } from 'helpers/order';
import { useLocalization } from 'hooks/useLocalization';
import { useSortationJobsCount } from 'hooks/useSortationJobsCount';

interface Props {
	loading?: boolean;
	sortWall?: Sortation;
}

export function SortWallBreakdown({ loading, sortWall }: Props) {
	const { messages, translate } = useLocalization();
	const { total: totalUnits } = calculateUnits(sortWall?.lines);

	const sortationExceptions = countSortationOrderExceptions(sortWall?.customerOrders);
	const pickingExceptions = countSortationPickingExceptions(sortWall?.pickingJobs);

	const jobCount = useSortationJobsCount(sortWall);

	return (
		<Card sectioned title={messages.breakdown}>
			<CardDetails
				loading={loading}
				primary={[
					{
						label: messages.totalUnits,
						content: translate(messages.countUnits, {
							count: totalUnits,
						}),
					},
					{
						label: messages.totalLines,
						content:
							sortWall?.lines?.length || sortWall?.lines?.length === 0 ? (
								translate(messages.countLines, {
									count: sortWall?.lines?.length,
								})
							) : (
								<NoData />
							),
					},
					{
						label: messages.totalOrders,
						content:
							sortWall?.customerOrders?.length || sortWall?.customerOrders?.length === 0 ? (
								translate(messages.countOrders, {
									count: sortWall?.customerOrders?.length,
								})
							) : (
								<NoData />
							),
					},
					{
						label: messages.pickingJobs,
						content: jobCount || <NoData />,
					},
				]}
				secondary={[
					{
						label: messages.pickingExceptions,
						content: (
							<Badge status={pickingExceptions > 0 ? 'critical' : undefined}>
								{pickingExceptions.toString()}
							</Badge>
						),
					},
					{
						label: messages.sortationExceptions,
						content: (
							<Badge status={sortationExceptions > 0 ? 'critical' : undefined}>
								{sortationExceptions.toString()}
							</Badge>
						),
					},
				]}
			/>
		</Card>
	);
}
