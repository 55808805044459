import { IndexTable, TextContainer, TextStyle } from '@shopify/polaris';
import { LineV2 } from '@sixriver/fulfillment-api-schema';

interface UnitsCellProps {
	lines: LineV2[];
}

export function UnitsCell({ lines }: UnitsCellProps) {
	const { doneCount, totalCount } = lines.reduce(
		({ doneCount, totalCount }, line) => {
			return {
				doneCount: doneCount + line.doneQuantity,
				totalCount: totalCount + line.quantity,
			};
		},
		{ doneCount: 0, totalCount: 0 },
	);
	return (
		<IndexTable.Cell>
			<TextContainer>
				<TextStyle>
					{doneCount} of {totalCount}
				</TextStyle>
			</TextContainer>
		</IndexTable.Cell>
	);
}
