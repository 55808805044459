import { Banner } from '@shopify/polaris';
import { usePrevious } from '@shopify/react-hooks';
import { useRef, useEffect, ReactNode } from 'react';

import { useLocalization } from 'hooks/useLocalization';

export function ErrorBanner({
	isVisible,
	onDismiss,
	title,
	content,
}: {
	isVisible?: boolean;
	onDismiss(): void;
	title?: string | null;
	content?: string | null | ReactNode;
}) {
	const { messages } = useLocalization();

	const bannerRef = useRef<HTMLDivElement>(null);

	const prevIsVisible = usePrevious(isVisible);

	useEffect(() => {
		if (
			isVisible &&
			!prevIsVisible &&
			bannerRef.current &&
			bannerRef.current.getBoundingClientRect
		) {
			const rect = bannerRef.current.getBoundingClientRect();

			// Only scroll if the banner isn't already within the viewport
			if (
				rect.top < 0 ||
				rect.bottom > (window.innerHeight || document.documentElement.clientHeight)
			) {
				bannerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
		}
	});

	return isVisible ? (
		<div ref={bannerRef}>
			<Banner title={title || messages.errorTitle} status="critical" onDismiss={onDismiss}>
				{content || messages.errorSubtitle}
			</Banner>
		</div>
	) : null;
}
