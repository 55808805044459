import { Card, Link, Stack, TextContainer, TextStyle } from '@shopify/polaris';
import { Return } from '@sixriver/fulfillment-api-schema';

import styles from './TrackingInformation.module.css';
import { DateTime } from 'components/DateTime';
import { NoData } from 'components/NoData';
import { useLocalization } from 'hooks/useLocalization';

export function TrackingInformation({ inboundReturn }: { inboundReturn?: Return }) {
	const { messages } = useLocalization();

	const carrierLabel = inboundReturn?.shipment?.shipmentTracking?.[0]?.carrierCode;
	const trackingNumber = inboundReturn?.shipment?.shipmentTracking?.[0]?.externalId;
	const trackingUrl = inboundReturn?.shipment?.shipmentTracking?.[0]?.trackingUrl;

	return (
		<Card title={messages.trackingInformation}>
			<Card.Section>
				<Stack vertical>
					<Stack.Item>
						<Stack>
							<div className={styles.trackingLabels}>
								<TextContainer>
									<TextStyle>{messages.carrier}</TextStyle>
								</TextContainer>
							</div>
							<div>
								{carrierLabel ? (
									<TextStyle>{carrierLabel?.toUpperCase() || '—'}</TextStyle>
								) : (
									<NoData />
								)}
							</div>
						</Stack>
					</Stack.Item>
					<Stack.Item>
						<Stack>
							<div className={styles.trackingLabels}>
								<TextContainer>
									<TextStyle>{messages.trackingNumber}</TextStyle>
								</TextContainer>
							</div>
							<div>
								<TextContainer>
									{trackingUrl ? (
										<Link url={trackingUrl} removeUnderline external>
											{trackingNumber ? (
												<TextStyle>{trackingNumber}</TextStyle>
											) : (
												<p>{messages.trackingLink}</p>
											)}
										</Link>
									) : (
										<>{trackingNumber ? <TextStyle>{trackingNumber}</TextStyle> : <NoData />}</>
									)}
								</TextContainer>
							</div>
						</Stack>
					</Stack.Item>
					<Stack.Item>
						<Stack>
							<div className={styles.trackingLabels}>
								<TextContainer>
									<TextStyle>{messages.estimatedArrival}</TextStyle>
								</TextContainer>
							</div>
							<div>
								{inboundReturn?.returnType === 'RTS' ? (
									<TextContainer>N/A</TextContainer>
								) : (
									<TextContainer>
										{inboundReturn?.shipment ? (
											<DateTime date={inboundReturn?.shipment?.estimatedArrivalDate} />
										) : (
											<NoData />
										)}
									</TextContainer>
								)}
							</div>
						</Stack>
					</Stack.Item>
				</Stack>
			</Card.Section>
			<Card.Section>
				<Stack>
					<div className={styles.trackingLabels}>
						<TextContainer>
							<TextStyle>{messages.actualArrival}</TextStyle>
						</TextContainer>
					</div>
					<div>
						<TextContainer>
							{inboundReturn?.shipment ? (
								<DateTime date={inboundReturn?.shipment?.actualArrivalDate} />
							) : (
								<NoData />
							)}
						</TextContainer>
					</div>
				</Stack>
			</Card.Section>
		</Card>
	);
}
