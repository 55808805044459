import { Icon, Tooltip } from '@shopify/polaris';
import { AlertMinor } from '@shopify/polaris-icons';
import { ReceivingIssueReason } from '@sixriver/fulfillment-api-schema';

import styles from './IconBadge.module.css';
import { InboundShipmentIssue } from 'pages/InboundShipment/InboundShipmentIssue';

interface Props {
	issueCount: number;
	issueReasons?: (ReceivingIssueReason | undefined)[];
}

export function IconBadge({ issueCount, issueReasons }: Props) {
	const issues = issueReasons?.map((reason, index) => (
		<InboundShipmentIssue key={index} issue={reason} />
	));

	return (
		<Tooltip content={issues}>
			<div className={styles.iconBadgeContainer}>
				<Icon source={AlertMinor} color="critical" />
				{issueCount}
			</div>
		</Tooltip>
	);
}
