import { Modal, Page, PageActions } from '@shopify/polaris';
import { Product, ProductInput, MutationResponse } from '@sixriver/fulfillment-api-schema';
import { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useQuery, useMutation } from 'urql';

import {
	DELETE_PRODUCT_MUTATION,
	EDIT_PRODUCT_MUTATION,
	GET_PRODUCT_QUERY,
} from './EditProduct.graphql';
import { ProductForm } from './ProductForm';
import { Error } from 'components/Error';
import { useLocalization } from 'hooks/useLocalization';
import { useToast } from 'hooks/useToast';
import * as routes from 'routes';

export function EditProduct() {
	const { messages } = useLocalization();

	// routing
	const history = useHistory();
	const {
		params: { productId },
	} = useRouteMatch<{ productId: string }>();

	const [modalVisible, setModalVisible] = useState(false);

	const [{ fetching, data, error: getProductError }] = useQuery<
		{ product: Product },
		{ id: string }
	>({
		query: GET_PRODUCT_QUERY,
		variables: {
			id: productId,
		},
		requestPolicy: 'network-only',
	});

	const [{ error: editProductError }, editProductMutation] = useMutation<
		{ editProduct: MutationResponse },
		{ input: ProductInput }
	>(EDIT_PRODUCT_MUTATION);

	const [{ error: deleteProductError, fetching: isDeleting }, deleteProductMutation] = useMutation<
		{ deleteProduct: MutationResponse },
		{ id: string }
	>(DELETE_PRODUCT_MUTATION);

	const { showToast } = useToast();

	const onSubmit = async (input: ProductInput) => {
		const { error } = await editProductMutation({ input });

		if (!error) {
			showToast(messages.dataSaved);

			history.push(routes.product(productId));
		}
	};

	const deleteProduct = async () => {
		const { error } = await deleteProductMutation({ id: productId });

		setModalVisible(false);

		if (error) {
			showToast(messages.dataNotSaved, true);
			return;
		}

		history.push(routes.products());
	};

	if (getProductError) {
		return <Error graphQLError={getProductError} />;
	}

	if (fetching || !data) {
		return null;
	}

	const input: ProductInput = {
		id: data.product.id,
		customerIdentifier: data.product.customerIdentifier || '',
		description: data.product.description || '',
		name: data.product.name || '',
		image: data.product.image,
		height: data.product.height || 0,
		length: data.product.length || 0,
		weight: data.product.weight || 0,
		width: data.product.width || 0,
	};

	return (
		<Page
			title={data.product.customerIdentifier}
			breadcrumbs={[
				{
					content: messages.products,
					url: routes.product(productId),
				},
			]}
			fullWidth
		>
			<ProductForm
				mode="edit"
				data={input}
				onSubmit={onSubmit}
				error={editProductError || deleteProductError}
			/>
			<br />
			<PageActions
				secondaryActions={[
					{
						content: messages.deleteProduct,
						destructive: true,
						outline: true,
						onAction: () => setModalVisible(true),
					},
				]}
			/>
			<Modal
				open={modalVisible}
				onClose={() => setModalVisible(false)}
				title={messages.deleteProduct}
				primaryAction={{
					content: messages.deleteProduct,
					onAction: deleteProduct,
					destructive: true,
					loading: isDeleting,
				}}
				secondaryActions={[
					{
						content: messages.keepEditing,
						onAction: () => setModalVisible(false),
					},
				]}
			>
				<Modal.Section>{messages.confirmDeleteProduct}</Modal.Section>
			</Modal>
		</Page>
	);
}
