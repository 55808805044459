import { Card, Link, Stack, TextContainer, TextStyle } from '@shopify/polaris';
import { ShippingNotice } from '@sixriver/fulfillment-api-schema';

import styles from './TrackingInformation.module.css';
import { DateTime } from 'components/DateTime';
import { NoData } from 'components/NoData';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	shippingNotice?: ShippingNotice;
}

export function TrackingInformation({ shippingNotice }: Props) {
	const { messages } = useLocalization();

	const carrierLabel = shippingNotice?.shipments?.[0]?.trackings?.[0]?.carrierCode;
	const trackingNumber = shippingNotice?.shipments?.[0]?.trackings?.[0]?.externalId;
	const trackingUrl = shippingNotice?.shipments?.[0]?.trackings?.[0]?.trackingUrl;

	return (
		<Card title={messages.trackingInformation}>
			<Card.Section>
				<Card.Subsection>
					<Stack>
						<div className={styles.trackingLabels}>
							<TextContainer>
								<TextStyle variation="strong">{messages.trackingNumber}</TextStyle>
							</TextContainer>
							<TextContainer>
								<TextStyle variation="strong">{messages.carrier}</TextStyle>
							</TextContainer>
						</div>
						<div className={styles.trackingData}>
							<TextContainer>
								{trackingUrl ? (
									<Link url={trackingUrl} removeUnderline external>
										{trackingNumber ? (
											<TextStyle>{trackingNumber}</TextStyle>
										) : (
											<p>{messages.trackingLink}</p>
										)}
									</Link>
								) : (
									<>{trackingNumber ? <TextStyle>{trackingNumber}</TextStyle> : <NoData />}</>
								)}
							</TextContainer>
							<TextContainer>
								{carrierLabel ? (
									<TextStyle>{carrierLabel?.toUpperCase() || '—'}</TextStyle>
								) : (
									<NoData />
								)}
							</TextContainer>
						</div>
					</Stack>
				</Card.Subsection>
				<Card.Subsection>
					<Stack>
						<div className={styles.trackingLabels}>
							<TextContainer>
								<TextStyle variation="strong">{messages.estimatedArrival}</TextStyle>
							</TextContainer>
							<TextContainer>
								<TextStyle variation="strong">{messages.actualArrival}</TextStyle>
							</TextContainer>
						</div>
						<div className={styles.trackingData}>
							<TextContainer>
								{shippingNotice?.shipments ? (
									<DateTime date={shippingNotice?.shipments[0]?.estimatedArrival} />
								) : (
									<NoData />
								)}
							</TextContainer>
							<TextContainer>
								{shippingNotice?.arrivedAt ? (
									<DateTime date={shippingNotice?.arrivedAt} />
								) : (
									<NoData />
								)}
							</TextContainer>
						</div>
					</Stack>
				</Card.Subsection>
			</Card.Section>
		</Card>
	);
}
