import { Banner, Modal } from '@shopify/polaris';
import { usePrevious } from '@shopify/react-hooks';
import { useRef, useEffect, ReactNode, useState } from 'react';
import { CombinedError } from 'urql';

import styles from '../FormFeedback/FormFeedback.module.css';
import { useLocalization } from 'hooks/useLocalization';

export function ErrorBannerWithDetails({
	isVisible,
	onDismiss,
	title,
	content,
	details,
}: {
	isVisible?: boolean;
	onDismiss(): void;
	title?: string | null;
	content?: string | null | ReactNode;
	details?: CombinedError;
}) {
	const { messages } = useLocalization();
	const bannerRef = useRef<HTMLDivElement>(null);
	const prevIsVisible = usePrevious(isVisible);
	const [modalVisible, setModalVisible] = useState(false);

	useEffect(() => {
		if (
			isVisible &&
			!prevIsVisible &&
			bannerRef.current &&
			bannerRef.current.getBoundingClientRect
		) {
			const rect = bannerRef.current.getBoundingClientRect();

			// Only scroll if the banner isn't already within the viewport
			if (
				rect.top < 0 ||
				rect.bottom > (window.innerHeight || document.documentElement.clientHeight)
			) {
				bannerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
			}
		}
	});

	const errors = details?.graphQLErrors || details?.message;
	const trace = JSON.stringify(errors, null, 2);

	return isVisible ? (
		<div ref={bannerRef}>
			<Banner
				title={title || messages.errorTitle}
				status="critical"
				onDismiss={onDismiss}
				action={
					details ? { content: messages.details, onAction: () => setModalVisible(true) } : undefined
				}
			>
				{content || messages.errorSubtitle}
			</Banner>
			<Modal
				large
				title={messages.error}
				open={modalVisible}
				onClose={() => setModalVisible(false)}
			>
				<Modal.Section>
					<pre className={styles.debug}>{trace}</pre>
				</Modal.Section>
			</Modal>
		</div>
	) : null;
}
