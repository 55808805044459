import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

function noopParse<T>(value: string): T {
	return value as unknown as T;
}

export function useQueryState<S>(
	key: string,
	defaultState: S | (() => S),
	parse: (value: string) => S = noopParse,
	serialize?: (value?: S) => string | undefined,
): [S, React.Dispatch<React.SetStateAction<S>>] {
	const { search, pathname } = useLocation();
	const { replace } = useHistory();
	const queryParams = useMemo(() => new URLSearchParams(search), [search]);
	const queryParam = queryParams.get(key);
	const [value, setValue] = useState(queryParam ? parse(queryParam) : defaultState);

	useEffect(() => {
		const serializedValue = serialize
			? serialize(value)
			: value !== null
			? String(value)
			: undefined;

		if (serializedValue) {
			queryParams.set(key, serializedValue);
		} else {
			queryParams.delete(key);
		}

		replace(`${pathname}?${queryParams.toString()}`);
	}, [key, pathname, queryParams, replace, serialize, value]);

	return [value, setValue];
}

export function serializeArray<T>(values?: T[]): string | undefined {
	if (values && values.length > 0) {
		return values.join(',');
	}
	return undefined;
}

export function parseArray<T>(value: string): T[] {
	return value.split(',') as unknown as T[];
}

export function useArrayQueryState<T>(key: string, defaultState: T | (() => T)) {
	return useQueryState<T>(key, defaultState, parseArray as any, serializeArray as any);
}

export function serializeDate(date?: Date): string | undefined {
	if (date) {
		return date.toISOString();
	}
	return undefined;
}

export function parseDate(value: string): Date {
	return new Date(value);
}

export function useDateQueryState<T = Date>(key: string, defaultState: T | (() => T)) {
	return useQueryState<T>(key, defaultState, parseDate as any, serializeDate as any);
}
