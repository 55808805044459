import { Card, Link, Stack, TextStyle, Thumbnail } from '@shopify/polaris';
import { ImageMajor } from '@shopify/polaris-icons';
import { BulkOrder } from '@sixriver/fulfillment-api-schema';

import styles from '../Cards.module.css';
import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';

interface Props {
	order?: BulkOrder;
}

export function Products({ order }: Props): JSX.Element {
	const { messages } = useLocalization();

	return (
		<Card title={messages.products}>
			<Card.Section>
				<Stack vertical={false} distribution={'fillEvenly'}>
					<Stack vertical={false} distribution={'leading'}>
						<p className={styles.header}>{messages.item}</p>
					</Stack>
					<Stack vertical={false} distribution={'fillEvenly'}>
						<p className={styles.header}>{messages.unitsRequired}</p>
						<p className={styles.header}>{messages.unitsMoved}</p>
						<p className={styles.header}>{messages.unitsPacked}</p>
					</Stack>
				</Stack>
			</Card.Section>
			<Card.Section>
				<Stack vertical={false} distribution={'fillEvenly'}>
					{order?.assetTypes[0] && (
						<Stack vertical={false} distribution={'fillEvenly'}>
							<Stack>
								<Stack.Item>
									<Thumbnail
										source={order?.assetTypes[0].image || ImageMajor}
										alt={order?.assetTypes[0].description || ''}
									/>
								</Stack.Item>
								<Stack.Item fill>
									<p>
										<Link url={routes.product(order?.assetTypes[0].id)}>
											{order?.assetTypes[0].externalId}
										</Link>
									</p>
									<p>
										<TextStyle variation="subdued">{`${messages.sku}: ${order?.assetTypes[0].name}`}</TextStyle>
									</p>
									<p>
										<TextStyle variation="subdued">{`${messages.description}: ${order?.assetTypes[0].description}`}</TextStyle>
									</p>
								</Stack.Item>
							</Stack>
							<Stack vertical={false} distribution={'fillEvenly'}>
								<p>{order?.assetTypes[0].unitsRequired}</p>
								<p>{order?.assetTypes[0].unitsMoved}</p>
								<p>{order?.assetTypes[0].unitsPacked}</p>
							</Stack>
						</Stack>
					)}
				</Stack>
			</Card.Section>
		</Card>
	);
}
