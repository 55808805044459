import {
	Badge,
	Banner,
	Button,
	Caption,
	DropZone,
	Heading,
	Icon,
	List,
	Modal,
	Spinner,
	Stack,
	TextContainer,
	TextStyle,
	Thumbnail,
} from '@shopify/polaris';
import { CircleCancelMinor, NoteMinor } from '@shopify/polaris-icons';
import {
	MutationResponse,
	Return,
	ReturnUploadInput,
	ReturnStatus,
} from '@sixriver/fulfillment-api-schema';
import { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useMutation } from 'urql';

import {
	ADD_RETURN_UPLOADS_MUTATION,
	REMOVE_RETURN_UPLOADS_MUTATION,
} from '../InboundReturn.graphql';
import styles from './Attachments.module.css';
import { useAuth } from 'hooks/useAuth';
import { useLocalization } from 'hooks/useLocalization';
import { useToast } from 'hooks/useToast';
import { fetchSignedUrl, uploadAttachment, Upload, removeAttachment } from 'utils/image';

interface Props {
	inboundReturn?: Return;
	refetchReturn: () => void;
}

export function Attachments({ inboundReturn, refetchReturn }: Props) {
	const { messages } = useLocalization();
	const { showToast } = useToast();
	const {
		params: { returnId },
	} = useRouteMatch<{ returnId: string }>();

	// Auth
	const { user } = useAuth();
	const loggedInUserId = user?.id;

	// State
	const [isAddAttachmentModalOpen, setIsAddAttachmentModalOpen] = useState(false);
	const [isRemoveUploadModalOpen, setIsRemoveUploadModalOpen] = useState(false);
	const [files, setFiles] = useState([] as File[]);
	const [rejectedFiles, setRejectedFiles] = useState([] as File[]);
	const [isUploading, setIsUploading] = useState(false);
	const [isRemovingUpload, setIsRemovingUpload] = useState(false);
	const [uploads, setUploads] = useState([] as Upload[]);
	const [removeUploadId, setRemoveUploadId] = useState('' as string);

	const existingUploads = inboundReturn?.uploads && inboundReturn.uploads.length > 0;

	useEffect(() => {
		const fetchAttachmentData = async () => {
			if (inboundReturn?.uploads) {
				const imageData = await Promise.all(
					inboundReturn?.uploads.map(async (upload) => {
						return await fetchSignedUrl(upload.id);
					}),
				);
				setUploads(imageData);
			}
		};

		fetchAttachmentData();
	}, [inboundReturn?.uploads]);

	const hasError = rejectedFiles.length > 0;

	// Mutations
	const [, addReturnUploads] = useMutation<
		{ addReturnUploads: MutationResponse },
		{ input: ReturnUploadInput }
	>(ADD_RETURN_UPLOADS_MUTATION);

	const [, deleteReturnUploads] = useMutation<
		{ deleteReturnUploads: MutationResponse },
		{ input: ReturnUploadInput }
	>(REMOVE_RETURN_UPLOADS_MUTATION);

	// Methods
	const openAddImageModal = () => setIsAddAttachmentModalOpen(true);
	const closeAddImageModal = () => setIsAddAttachmentModalOpen(false);
	const openRemoveUploadModal = () => setIsRemoveUploadModalOpen(true);
	const closeRemoveUploadModal = () => setIsRemoveUploadModalOpen(false);

	const handleDrop = useCallback((_droppedFiles, acceptedFiles, rejectedFiles) => {
		setFiles((files) => [...files, ...acceptedFiles]);
		setRejectedFiles(rejectedFiles);
	}, []);

	const addImages = async () => {
		setIsUploading(true);

		// upload image to upload service
		const uploadedImageData = await Promise.all(
			files.map(async (file) => {
				return await uploadAttachment(file, loggedInUserId);
			}),
		);

		// filter out id's of uploaded images
		const uploadIds = uploadedImageData.map(({ id }) => id);

		const input: ReturnUploadInput = {
			returnId,
			uploadIds,
		};
		const { data } = await addReturnUploads({ input });
		if (data?.addReturnUploads.success) {
			refetchReturn();
			setIsUploading(false);
			setFiles([]);
			setRejectedFiles([]);
			closeAddImageModal();
		} else {
			setIsUploading(false);
			showToast(messages.uploadAttachmentFailed);
		}
	};

	const cancelAddImages = () => {
		setFiles([]);
		setRejectedFiles([]);
		closeAddImageModal();
	};

	const confirmRemoveUpload = (uploadId: string) => {
		setRemoveUploadId(uploadId);
		openRemoveUploadModal();
	};

	const cancelRemoveUpload = () => {
		setRemoveUploadId('');
		closeRemoveUploadModal();
	};

	// remove uploaded file from an ASN
	const removeUpload = async () => {
		const input: ReturnUploadInput = {
			returnId,
			uploadIds: [removeUploadId],
		};

		setIsRemovingUpload(true);
		const response = await removeAttachment(removeUploadId);

		if (!response.ok) {
			showToast(messages.removeAttachmentFailed);
			setIsRemovingUpload(false);
			return;
		}

		const { data } = await deleteReturnUploads({ input });
		if (data?.deleteReturnUploads.success) {
			refetchReturn();
			setRemoveUploadId('');
			closeRemoveUploadModal();
			setIsRemovingUpload(false);
		} else {
			setIsRemovingUpload(false);
			showToast(messages.removeAttachmentFailed);
		}
	};

	const fileUpload = !files.length && <DropZone.FileUpload />;
	const uploadedFiles = files.length > 0 && (
		<div style={{ padding: 20 }}>
			<Stack vertical>
				{files.map((file, index) => (
					<Stack alignment="center" key={index}>
						<Thumbnail
							size="small"
							alt={file.name}
							source={
								file.type === 'application/pdf' ? NoteMinor : window.URL.createObjectURL(file)
							}
						/>
						<div>
							{file.name} <Caption>{file.size} bytes</Caption>
						</div>
					</Stack>
				))}
			</Stack>
		</div>
	);

	const errorMessage = hasError && (
		<Banner title={messages.imageUploadError} status="critical">
			<List type="bullet">
				{rejectedFiles.map((file, index) => (
					<List.Item key={index}>{`"${file.name}" - ${messages.fileTypeNotSupported}`}</List.Item>
				))}
			</List>
		</Banner>
	);

	const [returnCompleted, setReturnCompleted] = useState(false);

	useEffect(() => {
		const status = inboundReturn?.returnStatus as ReturnStatus;
		setReturnCompleted([ReturnStatus.Completed, ReturnStatus.Cancelled].includes(status));
	}, [inboundReturn]);

	return (
		<>
			<Stack distribution="equalSpacing">
				<Heading>{messages.attachments}</Heading>
				{!returnCompleted && (
					<Button plain onClick={openAddImageModal}>
						{messages.addAttachments}
					</Button>
				)}
			</Stack>
			{existingUploads && (
				<>
					<br />
					<Stack>
						{uploads.map((upload) => (
							<div
								className={styles.attachmentContainer}
								key={upload?.metadata?.id}
								onClick={() => confirmRemoveUpload(upload?.metadata?.id)}
							>
								<Stack alignment="center">
									<Thumbnail
										source={upload?.metadata?.fileFormat === 'pdf' ? NoteMinor : upload.signedURL}
										alt=""
									/>
									<Stack>
										<div>
											<p>{upload?.metadata?.fileName}</p>
											<Badge>{upload?.metadata?.fileFormat.toUpperCase()}</Badge>
										</div>
										<div className={styles.iconContainer}>
											<Icon source={CircleCancelMinor} color="subdued" />
										</div>
									</Stack>
								</Stack>
							</div>
						))}
					</Stack>
				</>
			)}
			<Modal
				title={messages.addAttachments}
				open={isAddAttachmentModalOpen}
				onClose={closeAddImageModal}
				primaryAction={{
					content: messages.upload,
					onAction: addImages,
					disabled: files.length === 0 || isUploading,
				}}
				secondaryActions={[
					{
						content: messages.cancel,
						destructive: false,
						onAction: cancelAddImages,
					},
				]}
			>
				<Modal.Section>
					<Stack vertical>
						{errorMessage}
						<DropZone
							accept="image/jpeg, image/png, application/pdf"
							type="file"
							onDrop={handleDrop}
						>
							{isUploading && (
								<div className={styles.DropZoneSpinnerContainer}>
									<Spinner size="large" />
								</div>
							)}
							{!isUploading && uploadedFiles}
							{fileUpload}
						</DropZone>
						<TextContainer>
							<TextStyle variation="subdued">{messages.acceptedFileFormats}</TextStyle>
						</TextContainer>
					</Stack>
				</Modal.Section>
			</Modal>
			<Modal
				open={isRemoveUploadModalOpen}
				onClose={cancelRemoveUpload}
				primaryAction={{
					content: messages.delete,
					onAction: removeUpload,
					destructive: true,
					disabled: isRemovingUpload,
				}}
				secondaryActions={[
					{
						content: messages.cancel,
						destructive: false,
						onAction: cancelRemoveUpload,
					},
				]}
				title={messages.deleteUpload}
			>
				<Modal.Section>
					<p>{messages.confirmDeleteUpload}</p>
				</Modal.Section>
			</Modal>
		</>
	);
}
