import { WorkArea } from '@sixriver/fulfillment-api-schema';
import gql from 'graphql-tag';
import { useQuery } from 'urql';

export const WORK_AREAS_QUERY = gql`
	query WorkAreas {
		workAreas: workAreas {
			id
			name
		}
	}
`;

export function useWorkAreas() {
	const [{ error, fetching, data }] = useQuery<{
		workAreas: WorkArea[];
	}>({
		query: WORK_AREAS_QUERY,
		requestPolicy: 'cache-first',
	});

	return { error, fetching, workAreas: data?.workAreas || [] };
}
