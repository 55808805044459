import styles from './RelativeDateTime.module.css';
import { NoData } from 'components/NoData';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	date?: Date | string | number;
	mode?: 'deadline' | 'expectation';
}

export function RelativeDateTime({ date, mode }: Props) {
	const { messages, translate, formatDateDiff } = useLocalization();

	if (date) {
		const dateDiff = formatDateDiff(date);
		const offsetMs = new Date(date).getTime() - Date.now();

		let message = dateDiff;
		let severity = 'info';

		if (mode === 'deadline') {
			message = translate(messages.diffDue, { dateDiff }) as string;
			severity = getDeadlineSeverity(offsetMs);
		} else if (mode === 'expectation') {
			message = translate(messages.diffExpected, { dateDiff }) as string;
		}

		return (
			<div className={styles.relativeDate} data-severity={severity}>
				{message}
			</div>
		);
	}

	return <NoData />;
}

function getDeadlineSeverity(offsetMs: number): string {
	if (offsetMs < 0) {
		// deadline passed
		return 'critical';
	} else if (offsetMs < 3600000 * 2) {
		// deadline in less than 2 hours
		return 'warning';
	}

	return 'info';
}
