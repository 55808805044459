import { Button, Card, Heading, Icon, Modal, Stack, TextField } from '@shopify/polaris';
import { NoteMajor } from '@shopify/polaris-icons';
import {
	MutationResponse,
	Return,
	ReturnNoteInput,
	ReturnStatus,
} from '@sixriver/fulfillment-api-schema';
import { useState, useEffect, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useMutation } from 'urql';

import { UPDATE_RETURN_NOTE_MUTATION } from '../InboundReturn.graphql';
import { useAuth } from 'hooks/useAuth';
import { useLocalization } from 'hooks/useLocalization';
import { useToast } from 'hooks/useToast';

interface Props {
	inboundReturn?: Return;
	refetchReturn: () => void;
}

export function InternalNotes({ inboundReturn, refetchReturn }: Props) {
	const { messages } = useLocalization();
	const { showToast } = useToast();
	const {
		params: { returnId },
	} = useRouteMatch<{ returnId: string }>();

	// State
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [internalNoteValue, setInternalNoteValue] = useState('');

	// Auth
	const { user } = useAuth();
	const loggedInUserId = user?.id;

	const existingNote = inboundReturn && inboundReturn.comments && inboundReturn.comments.length > 0;

	// Mutations
	const [, updateReturnNote] = useMutation<
		{ updateReturnNote: MutationResponse },
		{ input: ReturnNoteInput }
	>(UPDATE_RETURN_NOTE_MUTATION);

	const openEditModal = () => {
		setInternalNoteValue(inboundReturn?.comments || '');
		setIsEditModalOpen(true);
	};
	const closeEditModal = () => setIsEditModalOpen(false);

	const saveInternalNote = async () => {
		const input: ReturnNoteInput = {
			returnId,
			commentType: 'internal',
			comments: internalNoteValue,
			userId: loggedInUserId || 'leBridge',
		};

		const { data } = await updateReturnNote({ input });
		if (data?.updateReturnNote.success) {
			refetchReturn();
			setInternalNoteValue('');
			closeEditModal();
		} else {
			showToast(messages.saveNoteFailed);
		}
	};

	const cancelInternalNoteSave = () => {
		setInternalNoteValue('');
		closeEditModal();
	};

	const handleInternalNoteChange = useCallback((newValue) => setInternalNoteValue(newValue), []);

	const [returnCompleted, setReturnCompleted] = useState(false);

	useEffect(() => {
		const status = inboundReturn?.returnStatus as ReturnStatus;
		setReturnCompleted([ReturnStatus.Completed, ReturnStatus.Cancelled].includes(status));
	}, [inboundReturn]);

	return (
		<>
			<Card>
				<Card.Section>
					<Stack distribution="equalSpacing">
						<Stack alignment="center">
							<Icon source={NoteMajor} color="base" />
							<Heading>{messages.internalNote}</Heading>
						</Stack>
						{!returnCompleted && (
							<>
								{existingNote ? (
									<Button plain onClick={openEditModal}>
										{messages.editNote}
									</Button>
								) : (
									<Button plain onClick={openEditModal}>
										{messages.addNote}
									</Button>
								)}
							</>
						)}
					</Stack>
				</Card.Section>
				<Card.Section>
					{existingNote ? <p>{inboundReturn?.comments}</p> : <p>{messages.noNotesToDisplay}</p>}
				</Card.Section>
			</Card>
			<Modal
				title={messages.internalNotes}
				open={isEditModalOpen}
				onClose={closeEditModal}
				primaryAction={{
					content: messages.save,
					onAction: saveInternalNote,
				}}
				secondaryActions={[
					{
						content: messages.cancel,
						destructive: false,
						onAction: cancelInternalNoteSave,
					},
				]}
			>
				<Modal.Section>
					<TextField
						autoComplete="off"
						label={messages.notes}
						value={internalNoteValue}
						onChange={handleInternalNoteChange}
						multiline={4}
					/>
				</Modal.Section>
			</Modal>
		</>
	);
}
