import { Link, TextStyle, Tooltip } from '@shopify/polaris';
import { Order, OrderExceptionStatus } from '@sixriver/fulfillment-api-schema';
import { useState } from 'react';

import { SortWallOrderViews, SortWallOrderViewStateMap } from './SortWallOrdersTableViews';
import { DataTable, Column } from 'components/DataTable';
import { DateTime } from 'components/DateTime';
import { NoData } from 'components/NoData';
import { OrderQuantity } from 'components/OrderQuantity';
import { OrderStatusBadge } from 'components/OrderStatusBadge';
import { RelativeDateTime } from 'components/RelativeDateTime';
import { countLineExceptions, getExceptionLines } from 'helpers/exception';
import { paginate } from 'helpers/page-info';
import { getPageSize } from 'helpers/page-size';
import { useConfig } from 'hooks/useConfig';
import { useFilters, useSetFilters } from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';
import { BridgeVersion, useBridgeVersion } from 'hooks/useUserPreferences';
import * as routes from 'routes';

interface Props {
	orders?: Order[];
	loading?: boolean;
}

const VIEW = 'orderView';

export function SortWallOrdersTable({ orders, loading }: Props) {
	const { messages, translate } = useLocalization();

	const { [VIEW]: view = SortWallOrderViews.All } = useFilters([VIEW]);

	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);

	const { config } = useConfig();
	const { bridgeVersion } = useBridgeVersion();

	const filteredOrders = SortWallOrderViewStateMap[view as SortWallOrderViews](orders);

	const counts = Object.keys(SortWallOrderViewStateMap).reduce<{
		[key in SortWallOrderViews]: number;
	}>(
		(counts, orderView) => ({
			...counts,
			[orderView]: SortWallOrderViewStateMap[orderView as SortWallOrderViews](orders).length || 0,
		}),
		{} as { [key in SortWallOrderViews]: number },
	);

	const { itemsInCurrentPage: ordersInCurrentPage, pageInfo } = paginate({
		itemsList: filteredOrders,
		pageSize: getPageSize(),
		paginationCursors,
	});

	const setFilters = useSetFilters();

	const columns: Column[] = [
		{
			type: 'text',
			heading: messages.order,
		},
		{
			type: 'text',
			heading: messages.receivedAt,
		},
		{
			type: 'text',
			heading: messages.carrierCutoff,
		},
		{
			type: 'text',
			heading: messages.quantity,
		},
		{
			type: 'text',
			heading: messages.slot,
		},
		{
			type: 'text',
			heading: messages.progress,
		},
		{
			type: 'text',
			heading: messages.exception,
		},
		{
			type: 'text',
			heading: messages.store,
		},
	];

	const rows = (ordersInCurrentPage || []).map((order) => {
		const numberOfExceptions = countLineExceptions(getExceptionLines(order));

		const countExceptionsMsg = translate(messages.countExceptions, {
			count: numberOfExceptions,
		});
		const orderUrl =
			bridgeVersion === BridgeVersion.Next ? routes.order(order.jobId) : routes.order(order.id);
		return [
			<Link url={orderUrl} key="order-id">
				{order.customerIdentifier}
			</Link>,
			<div key="date-received">
				<DateTime date={order.timeline?.createdAt} />
				<RelativeDateTime date={order.timeline?.createdAt} />
			</div>,
			<div key="cutoff">
				<DateTime date={order.expectedShipDate} />
				<RelativeDateTime date={order.expectedShipDate} mode="deadline" />
			</div>,
			<OrderQuantity lines={order.lines} key="lines" />,
			order.slot || <NoData />,
			<OrderStatusBadge orderStatus={order.status} key="status" />,
			order.exception?.status === OrderExceptionStatus.Cleared ? (
				<TextStyle variation="positive">{messages.cleared}</TextStyle>
			) : numberOfExceptions === 0 ? (
				<NoData />
			) : (
				<TextStyle variation="negative">
					{config?.healingEnabled ? (
						<Tooltip content={messages.manageExceptionsForOrder}>
							<Link url={routes.exception(order.id)} monochrome>
								{countExceptionsMsg}
							</Link>
						</Tooltip>
					) : (
						countExceptionsMsg
					)}
				</TextStyle>
			),
			order.customers.length > 1 ? (
				<div>{messages.multiple}</div>
			) : (
				order.customers[0]?.name || <NoData />
			),
		];
	});

	return (
		<DataTable
			title={messages.orders}
			loading={loading}
			columns={columns}
			rows={rows}
			emptyStateHeading={messages.noOrdersFound}
			pageInfo={pageInfo}
			paginationCursors={paginationCursors}
			setPaginationCursors={setPaginationCursors}
			viewKey={VIEW}
			views={[
				{
					label: messages.all,
					metaLabel: counts.All,
					id: SortWallOrderViews.All,
				},
				{
					label: messages.inProgress,
					metaLabel: counts.InProgress,
					id: SortWallOrderViews.InProgress,
				},
				{
					label: messages.completed,
					metaLabel: counts.Complete,
					id: SortWallOrderViews.Complete,
				},
				{
					label: messages.exceptions,
					metaLabel: counts.Exceptions,
					id: SortWallOrderViews.Exceptions,
				},
			]}
			setFilters={setFilters}
			selectedView={view}
			hideFilters
		/>
	);
}
