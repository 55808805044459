import { IndexTable } from '@shopify/polaris';
import { GoalStates } from '@sixriver/fulfillment-api-schema';

import { ReworkJobStatus } from './ReworkJobStatus';

export function ProgressCell({ status }: { status?: GoalStates }) {
	return (
		<IndexTable.Cell>
			<ReworkJobStatus status={status} />
		</IndexTable.Cell>
	);
}
