import { Tooltip } from '@shopify/polaris';
import { useEffect, useState } from 'react';

import styles from './AutoRefresh.module.css';
import { Toggle } from 'components/Toggle';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	pollingEnabled: boolean;
	togglePolling(): void;
	discriminatorData: object | undefined;
}

export function AutoRefresh({ pollingEnabled, togglePolling, discriminatorData }: Props) {
	const { messages, translate, formatDateDiff } = useLocalization();

	const [flash, setFlash] = useState(messages.justUpdated);

	const [fetchedAt, setFetchedAt] = useState(new Date());

	useEffect(() => {
		setFetchedAt(new Date());
	}, [discriminatorData]);

	useEffect(() => {
		let elapsed = 0;

		const interval = window.setInterval(() => {
			elapsed += 1000;

			let msg = messages.justUpdated;

			if (elapsed >= 60000) {
				msg = translate(messages.diffUpdated, {
					dateDiff: formatDateDiff(fetchedAt),
				}) as string;
			} else if (elapsed >= 10000) {
				msg = translate(messages.diffUpdated, {
					dateDiff: formatDateDiff(Date.now() - Math.floor(elapsed / 10000) * 10000),
				}) as string;
			}

			setFlash(msg);
		}, 1000);

		return () => {
			window.clearInterval(interval);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchedAt]);

	return (
		<div className={styles.container}>
			<div className={styles.toggle}>
				<Tooltip content={messages.autoRefreshHint} activatorWrapper="div">
					<Toggle
						label={pollingEnabled ? messages.autoRefreshEnabled : messages.autoRefreshDisabled}
						checked={pollingEnabled}
						onCheck={togglePolling}
						align="right"
					/>
				</Tooltip>
			</div>
			<span className={styles.flash}>{flash}</span>
		</div>
	);
}
