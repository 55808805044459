import { EmptySearchResult, IndexTable, IndexTableProps } from '@shopify/polaris';
import { OrderEdge, OrderStatus } from '@sixriver/fulfillment-api-schema';

import { OrderIdCell } from './OrderIdCell';
import { ProgressCell } from './ProgressCell';
import { TrackingNumberCell } from './TrackingNumberCell';
import { useLocalization } from 'hooks/useLocalization';

export interface LaneContainerOrdersTableProps {
	loading: boolean;
	data?: OrderEdge[];
	isShipped?: boolean;
}

export function LaneContainerOrdersTable({
	loading,
	data = [],
	isShipped,
}: LaneContainerOrdersTableProps) {
	const { messages } = useLocalization();

	const resourceName = {
		singular: messages.order.toLowerCase(),
		plural: messages.orders.toLowerCase(),
	};
	const emptyStateMarkup = <EmptySearchResult title={messages.noOrdersFound} withIllustration />;
	const headings: IndexTableProps['headings'] = [
		{ title: messages.orders },
		{ title: messages.trackingNumber },
		{ title: messages.progress },
	] as IndexTableProps['headings'];

	const rows = data.map(({ node }, index) => {
		const { id, customerIdentifier, trackingNumbers, status } = node;

		return (
			<IndexTable.Row id={id} key={id} position={index}>
				<OrderIdCell id={id ?? undefined} jobId={customerIdentifier ?? undefined} />
				<TrackingNumberCell trackingNumbers={trackingNumbers ?? undefined} />
				<ProgressCell status={isShipped ? OrderStatus.Shipped : status ?? undefined} />
			</IndexTable.Row>
		);
	});

	return (
		<>
			<IndexTable
				emptyState={emptyStateMarkup}
				resourceName={resourceName}
				headings={headings}
				hasMoreItems={false}
				itemCount={data.length}
				loading={loading}
				selectable={false}
			>
				{rows}
			</IndexTable>
		</>
	);
}
