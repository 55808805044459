import { Modal, TextContainer } from '@shopify/polaris';

import { useLocalization } from 'hooks/useLocalization';

interface Props {
	onClose: () => void;
	onCancelJob: () => void;
	loading: boolean;
	isOpen: boolean;
}
export function CancelJobModal({ onClose, onCancelJob, loading = false, isOpen = false }: Props) {
	const { messages } = useLocalization();

	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			title={messages.cancelJob}
			primaryAction={{
				content: messages.cancelJob,
				onAction: onCancelJob,
				destructive: true,
				loading,
			}}
			secondaryActions={[
				{
					content: messages.keepJob,
					onAction: onClose,
				},
			]}
		>
			<Modal.Section>
				<TextContainer>
					<p>{messages.confirmCancelJobs}</p>
				</TextContainer>
			</Modal.Section>
		</Modal>
	);
}
