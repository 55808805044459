import { IndexTable } from '@shopify/polaris';
import { LineV2 } from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';

interface StoreCellProps {
	lines: LineV2[];
}

export function StoreCell({ lines }: StoreCellProps) {
	const customers = lines.map((line) => line.customer);
	const customer = customers.shift();
	return <IndexTable.Cell>{customer?.name ?? <NoData />}</IndexTable.Cell>;
}
