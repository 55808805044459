/**
 * Transform an image URL from an intermediate form to a form that is usable in the browser.
 * @see Based on `@sixriver/asset-image`: {@url https://github.com/6RiverSystems/asset-image}
 */
import placeholderImg from '../images/placeholder.svg';

export const transformImageUrl = (imageUrl: string | null | undefined): string => {
	if (!imageUrl) {
		return placeholderImg;
	}

	const cloudinaryBaseUrl = 'https://res.cloudinary.com/six-river/image/upload/t_am-editor,f_auto/';

	// Handle relative URLs (legacy, mostly used in development environments)
	if (!imageUrl.startsWith('http')) {
		return `${cloudinaryBaseUrl}/${imageUrl}`;
	}

	return imageUrl;
};

// fetch signed url from uploads service
export async function fetchSignedUrl(uploadId: string) {
	const response = await fetch(
		`${process.env.REACT_APP_UPLOADS_SERVICE_URL}/v1/uploads/${uploadId}/signedUrl`,
		{
			headers: {
				Accept: 'application/json',
			},
			method: 'GET',
		},
	);
	return response.json();
}

// upload attachment to uploads service
export async function uploadAttachment(
	file: File,
	userId = 'bridgeUser',
	deviceId = 'bridgeDevice',
	fileType = 'image',
) {
	const body = new FormData();
	body.append('file', file);
	body.append('fileName', file.name);
	// appended -bridge to userId b/c user ID could be less than 3 chars failing uploads validation
	body.append('userId', `${userId}-bridge`);
	body.append('deviceId', deviceId);
	body.append('fileType', fileType);

	const response = await fetch(`${process.env.REACT_APP_UPLOADS_SERVICE_URL}/v1/uploads`, {
		body,
		headers: {
			Accept: 'application/json',
		},
		method: 'POST',
	});
	return response.json();
}

// remove attachment from uploads service
export async function removeAttachment(uploadId: string) {
	const response = await fetch(
		`${process.env.REACT_APP_UPLOADS_SERVICE_URL}/v1/uploads/${uploadId}`,
		{
			headers: {
				Accept: 'application/json',
			},
			method: 'DELETE',
		},
	);
	return response;
}

export interface Upload {
	metadata: {
		createdAt: string;
		deviceId: string;
		fileFormat: string;
		fileName: string;
		fileType: string;
		folderPath: string;
		id: string;
	};
	signedURL: string;
}
