import styles from './Toggle.module.css';

interface Props {
	checked: boolean;
	onCheck(pressed: boolean): void;
	label: string;
	disabled?: boolean;
	align?: 'left' | 'right';
}

export function Toggle({ checked, onCheck, label, disabled = false, align = 'left' }: Props) {
	return (
		<span className={styles.container}>
			<label aria-disabled={disabled} data-align={align} className={styles.label}>
				<button
					role="switch"
					aria-checked={checked}
					aria-disabled={disabled}
					disabled={disabled}
					onClick={disabled ? undefined : () => onCheck(!checked)}
					className={styles.button}
				>
					<span />
				</button>
				<span>{label}</span>
			</label>
		</span>
	);
}
