import { Link, Badge } from '@shopify/polaris';
import { LinesViews, ReplenExceptionStatus, ReplenTask } from '@sixriver/fulfillment-api-schema';
import { useState } from 'react';

import { DataTable, Column } from 'components/DataTable';
import { NoData } from 'components/NoData';
import { Tab } from 'components/ViewTabs';
import { countTaskExceptions } from 'helpers/exception';
import { paginate } from 'helpers/page-info';
import { getPageSize } from 'helpers/page-size';
import { useFilters, useSetFilters } from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';

interface Props {
	tasksData: ReplenTask[];
	query?: string;
	views: Tab[];
}

const TASKS_PER_PAGE = getPageSize();

export function ReplenishJobLinesTable({ tasksData, query, views }: Props) {
	const { messages, translate } = useLocalization();

	const { view = LinesViews.All } = useFilters();

	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);

	const setFilters = useSetFilters();

	const taskExceptions: Record<ReplenExceptionStatus, string> = {
		[ReplenExceptionStatus.DamagedProduct]: messages.replenExceptionTypes.DamagedProduct,
		[ReplenExceptionStatus.LocationTooFull]: messages.replenExceptionTypes.LocationTooFull,
		[ReplenExceptionStatus.MissingProduct]: messages.replenExceptionTypes.MissingProduct,
		[ReplenExceptionStatus.Unknown]: messages.replenExceptionTypes.Unknown,
		[ReplenExceptionStatus.WrongProduct]: messages.replenExceptionTypes.WrongProduct,
	};

	const columns: Column[] = [
		{
			type: 'text',
			heading: messages.item,
		},
		{
			type: 'text',
			heading: messages.name,
		},
		{
			type: 'text',
			heading: messages.destination,
		},
		{
			type: 'text',
			heading: messages.units,
		},

		{
			type: 'text',
			heading: messages.associate,
		},
		{
			type: 'text',
			heading: messages.exception,
		},
		{
			type: 'text',
			heading: messages.exceptionReason,
		},
	];

	const allTasks = tasksData || [];

	const { itemsInCurrentPage: tasksInCurrentPage, pageInfo } = paginate({
		itemsList: allTasks,
		pageSize: TASKS_PER_PAGE,
		paginationCursors,
	});

	const rows = (tasksInCurrentPage || []).map((task) => {
		const hasException = countTaskExceptions([task]) > 0;

		const taskException = hasException ? (
			task?.exceptions.map((ex) => taskExceptions[ex.status]).join(', ')
		) : (
			<NoData />
		);

		return [
			// id / customerIdentifier
			task.product?.id ? (
				<Link url={routes.product(task.product.id)} key="product-id">
					{task.product.customerIdentifier}
				</Link>
			) : (
				<NoData />
			),
			// name
			task.product?.description ? <p key="product-name">{task.product.description}</p> : <NoData />,
			// destination
			task.destination.address || <NoData />,
			<div key="units">
				{translate(messages.xOfY, {
					x: task.doneQuantity,
					y: task.quantity,
				})}
			</div>,
			// associate
			task?.user?.name ? <div key="associate">{task.user.name}</div> : <NoData />,
			// Exception
			hasException ? <Badge status="critical">{messages.exception}</Badge> : <NoData />,
			// Exception Reason
			taskException,
		];
	});

	return (
		<DataTable
			title={messages.lines}
			loading={false}
			columns={columns}
			rows={rows}
			emptyStateHeading={messages.linesNotFound}
			pageInfo={pageInfo}
			paginationCursors={paginationCursors}
			setPaginationCursors={setPaginationCursors}
			views={views}
			setFilters={setFilters}
			selectedView={view}
			filters={[]}
			query={query}
		/>
	);
}
