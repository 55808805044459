import gql from 'graphql-tag';

export const EXCEPTION_QUERY = gql`
	query GetException($projectionId: String!) {
		orderByProjectionId(projectionId: $projectionId) {
			id
			type
			status
			expectedShipDate
			customerIdentifier
			isCancelable
			isHealed
			isHealable
			isShippedShort
			healPlan {
				id
				status
			}
			lines {
				id
				quantity
				doneQuantity
				status
				source {
					id
					address
				}
				product {
					id
					customerIdentifier
					description
				}
				timeline {
					completedAt
				}
			}
			timeline {
				createdAt
			}
			servicedBy {
				... on Picking {
					id
					pickingStatus
					exception
					timeline {
						createdAt
						updatedAt
					}
					lines {
						id
						status
						quantity
						doneQuantity
						source {
							id
							address
						}
						product {
							id
							customerIdentifier
							description
						}
						timeline {
							completedAt
						}
					}
					takeoffDestination
					container {
						barcode
					}
				}
				... on HealPlan {
					id
					status
				}
			}
			exception {
				status
			}
			workAreas {
				id
				name
			}
		}
	}
`;
