/* eslint-disable react-hooks/exhaustive-deps */
import { merge } from 'lodash';
import { useCallback, useMemo } from 'react';

import en from '../../messages/en-US/en-US.json';
import es from '../../messages/es-MX/es-MX.json';
import { useAuth } from '../useAuth';
import * as dateOps from 'utils/date';
import * as dateDiffOps from 'utils/date-diff';
import * as numberOps from 'utils/number';
import * as translateOps from 'utils/translate';

export const SUPPORTED_LOCALES = ['en-US', 'es-MX'] as const;
export type LocaleType = typeof SUPPORTED_LOCALES[number];

let _messages = en;
let _locale = 'en-US';

export function useLocalization() {
	const auth = useAuth();
	const userLocale = (auth.user?.locale || '') as LocaleType;
	const locale = SUPPORTED_LOCALES.includes(userLocale) ? userLocale : 'en-US';

	const translate = useCallback(translateOps.translate.bind(null, locale), [locale]);
	const formatNumber = useCallback(numberOps.formatNumber.bind(null, locale), [locale]);
	const formatPercent = useCallback(numberOps.formatPercent.bind(null, locale), [locale]);
	const formatWeight = useCallback(numberOps.formatWeight.bind(null, locale), [locale]);
	const formatLength = useCallback(numberOps.formatLength.bind(null, locale), [locale]);
	const formatVolume = useCallback(numberOps.formatVolume.bind(null, locale), [locale]);
	const formatDateTime = useCallback(dateOps.formatDateTime.bind(null, locale), [locale]);
	const formatDate = useCallback(dateOps.formatDate.bind(null, locale), [locale]);
	const formatTime = useCallback(dateOps.formatTime.bind(null, locale), [locale]);
	const formatDateDiff = useCallback(dateDiffOps.formatDateDiff.bind(null, locale), [locale]);

	const messages = useMemo(() => {
		const msgs = {
			'en-US': en,
			'es-MX': es,
		}[locale];

		// we don't need this to run every time a component mounts. it should run once at most
		if (locale !== _locale) {
			_messages = merge(JSON.parse(JSON.stringify(en)), msgs);
			_locale = locale;
		}

		return _messages;
	}, [locale]);

	return {
		locale,
		messages,
		translate,
		formatNumber,
		formatPercent,
		formatWeight,
		formatLength,
		formatVolume,
		formatDateTime,
		formatDate,
		formatTime,
		formatDateDiff,
	};
}
