import {
	Card,
	Layout,
	Modal,
	Page,
	PageActions,
	Stack,
	Tag,
	TextStyle,
	Link,
} from '@shopify/polaris';
import { MutationResponse, User } from '@sixriver/fulfillment-api-schema';
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useQuery } from 'urql';

import { EmployeeTimestampsCard } from './EmployeeTimestampsCard';
import {
	DEACTIVATE_EMPLOYEE_MUTATION,
	EMPLOYEE_BY_ID_QUERY,
	REACTIVATE_EMPLOYEE_MUTATION,
} from './Employees.graphql';
import { CardDetails } from 'components/CardDetails';
import { Error } from 'components/Error';
import { NoData } from 'components/NoData';
import { useAuth } from 'hooks/useAuth';
import { useLocalization } from 'hooks/useLocalization';
import { useToast } from 'hooks/useToast';
import { UserRole } from 'providers/AuthProvider';
import { gqlClient } from 'providers/GraphQLProvider';
import * as routes from 'routes';

interface Params {
	employeeId: string;
}

// Mocked
export interface EmployeeEvent {
	date: Date;
	type: 'created' | 'updated';
	editedBy?: {
		name: string;
		id: string;
	};
}

export function Employee() {
	const { messages } = useLocalization();

	// State
	const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState<boolean>(false);
	const [isReactivating, setIsReactivating] = useState<boolean>(false);
	const [isDeactivating, setIsDeactivating] = useState<boolean>(false);

	// Auth
	const { user } = useAuth();
	const loggedInUserEmail = user?.email;

	// Toasts
	const { showToast } = useToast();

	// Routing
	const params = useParams<Params>();
	const employeeId = params?.employeeId;
	const history = useHistory();

	// Queries
	const [{ fetching: fetchingUser, error: userError, data: userData }] = useQuery<
		{ user: User },
		{ id: string }
	>({
		query: EMPLOYEE_BY_ID_QUERY,
		variables: {
			id: employeeId,
		},
	});
	const employee = userData?.user;

	// Timestamps
	const timestamps: EmployeeEvent[] = [
		...(employee?.createdAt
			? [
					{
						date: new Date(employee.createdAt),
						// editedBy: {
						// 	id: '123',
						// 	name: 'Bob',
						// },
						type: 'created' as const,
					},
			  ]
			: []),
		...(employee?.updatedAt
			? [
					{
						date: new Date(employee?.updatedAt),
						// editedBy: {
						// 	id: '321',
						// 	name: 'Rob',
						// },
						type: 'updated' as const,
					},
			  ]
			: []),
	];

	// Methods
	const openDeactivateModal = () => setIsDeactivateModalOpen(true);

	const closeDeactivateModal = () => setIsDeactivateModalOpen(false);

	const reactivateEmployee = async () => {
		if (!employee?.id) {
			showToast(messages.errorToast, true);
			return;
		}

		setIsReactivating(true);

		const { data, error } = await gqlClient
			.mutation<{ reactivateUser: MutationResponse }, { userId: string }>(
				REACTIVATE_EMPLOYEE_MUTATION,
				{
					userId: employee.id,
				},
			)
			.toPromise();

		setIsReactivating(false);

		if (error || !data?.reactivateUser?.success) {
			showToast(messages.errorToast, true);
			return;
		}

		// success
		showToast(messages.employeeReactivated);
		history.push(routes.employees());
	};

	const deactivateEmployee = async () => {
		if (!employee?.id) {
			showToast(messages.errorToast, true);
			return;
		}

		if (loggedInUserEmail === employee.email) {
			showToast(messages.errorToast, true);
			return;
		}

		setIsDeactivating(true);

		const { data, error } = await gqlClient
			.mutation<{ deactivateUser: MutationResponse }, { userId: string }>(
				DEACTIVATE_EMPLOYEE_MUTATION,
				{
					userId: employee.id,
				},
			)
			.toPromise();

		setIsDeactivating(false);

		closeDeactivateModal();

		if (error || !data?.deactivateUser?.success) {
			showToast(messages.errorToast, true);
			return;
		}

		// success
		showToast(messages.employeeDeactivated);
		history.push(routes.inactiveEmployees());
	};

	// Guards
	const loading = fetchingUser;

	if (loading) {
		return null;
	}

	if (userError) {
		return <Error graphQLError={userError} />;
	}

	if (!employee) {
		return <Error message={messages.dataNotFound} />;
	}

	// Render
	return (
		<>
			<Page fullWidth title={employee?.name || employee?.badge || employee?.email || '—'}>
				<Stack distribution="trailing" alignment="trailing">
					<Link url={routes.editEmployee(employeeId.toString())} removeUnderline>
						{messages.editEmployee}
					</Link>
				</Stack>

				<br />

				<Layout>
					<Layout.Section>
						{/* Employee Details */}
						<Card title={messages.employeeDetails} sectioned>
							<CardDetails
								primary={[
									{
										label: messages.fullName,
										content: employee?.name || <NoData />,
									},
									{
										label: messages.language,
										content:
											messages.supportedLocales[
												(employee?.locale as keyof typeof messages.supportedLocales) || 'en-US'
											],
									},
								]}
							/>
						</Card>

						{/* 6RS Device Credentials */}
						<Card title={messages.deviceAccess} sectioned>
							{employee?.badge ? (
								<CardDetails
									primary={[
										{
											label: messages.badgeBarcode,
											content: employee?.badge || <NoData />,
										},
									]}
								/>
							) : (
								<TextStyle>{messages.noAccess}</TextStyle>
							)}
						</Card>

						{/* Bridge Credentials */}
						<Card title={messages.bridgeAccess} sectioned>
							{employee?.roles?.includes(UserRole.Admin) ? (
								<CardDetails
									primary={[
										{
											label: messages.email,
											content: employee?.email || <NoData />,
										},
										{
											label: messages.password,
											content: '********',
										},
									]}
								/>
							) : (
								<TextStyle>{messages.noAccess}</TextStyle>
							)}
						</Card>

						<br />
					</Layout.Section>

					<Layout.Section secondary>
						{/* TAGS  */}
						<Card title={messages.tags} sectioned>
							<Card.Section flush>
								<Stack>
									{(employee?.tags || []).map((tag) => {
										return <Tag key={tag.name}>{tag.name}</Tag>;
									})}
								</Stack>
							</Card.Section>
						</Card>

						{/* TimeStamps */}
						<EmployeeTimestampsCard timestamps={timestamps} />
					</Layout.Section>
				</Layout>

				<PageActions
					secondaryActions={
						employee.isActive
							? [
									{
										content: messages.deactivateEmployee,
										destructive: true,
										outline: true,
										onAction: openDeactivateModal,
										disabled: loggedInUserEmail === employee.email,
									},
							  ]
							: [
									{
										content: messages.reactivateEmployee,
										destructive: false,
										outline: true,
										loading: isReactivating,
										onAction: reactivateEmployee,
									},
							  ]
					}
				/>
			</Page>
			<Modal
				title={messages.deactivateEmployee}
				open={isDeactivateModalOpen}
				onClose={closeDeactivateModal}
				primaryAction={{
					content: messages.deactivateEmployee,
					destructive: true,
					onAction: deactivateEmployee,
					loading: isDeactivating,
					disabled: loggedInUserEmail === employee.email,
				}}
				secondaryActions={[
					{
						content: messages.cancel,
						destructive: false,
						onAction: closeDeactivateModal,
					},
				]}
			>
				<Modal.Section>
					<TextStyle>{messages.confirmDeactivateEmployee}</TextStyle>
				</Modal.Section>
			</Modal>
		</>
	);
}
