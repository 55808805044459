import { TopBar } from '@shopify/polaris';
import { LogOutMinor } from '@shopify/polaris-icons';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useAuth } from 'hooks/useAuth';
import { useLocalization } from 'hooks/useLocalization';
import {
	BridgeVersion,
	useBridgeVersion,
	UserPreferenceKey,
	useUserPreferences,
} from 'hooks/useUserPreferences';
import * as routes from 'routes';

export function UserMenu(): JSX.Element {
	const { messages } = useLocalization();
	const { user } = useAuth();
	const { updateUserPreference } = useUserPreferences();
	const history = useHistory();
	const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
	const toggleIsUserMenuOpen = useCallback(
		() => setIsUserMenuOpen((isUserMenuOpen) => !isUserMenuOpen),
		[],
	);
	const name = user?.name || messages.account;
	const { bridgeVersion } = useBridgeVersion();

	const updateBridgeVersion = (bridgeVersion: BridgeVersion) => {
		updateUserPreference({
			key: UserPreferenceKey.BridgeVersion,
			value: bridgeVersion,
			type: 'string',
		});
	};

	return (
		<TopBar.UserMenu
			actions={[
				{
					items: [
						{
							content:
								bridgeVersion === BridgeVersion.Next ? 'Switch to classic UI' : 'Switch to next UI',
							onAction: () => {
								bridgeVersion === BridgeVersion.Next
									? updateBridgeVersion(BridgeVersion.Classic)
									: updateBridgeVersion(BridgeVersion.Next);
							},
						},
						{
							content: messages.signOut,
							icon: LogOutMinor,
							onAction: () => {
								history.push(routes.logout());
							},
						},
					],
				},
			]}
			name={name}
			detail={user?.email || ''}
			initials={name.charAt(0).toUpperCase()}
			open={isUserMenuOpen}
			onToggle={toggleIsUserMenuOpen}
		/>
	);
}
