import gql from 'graphql-tag';

export const LANE_CONTAINER_QUERY = gql`
	query laneContainers(
		$laneIds: [String!]
		$containerIds: [String!]
		$containersSearchText: String
		$containerStatuses: [ContainerStatus!]
		$hasException: Boolean
		$dateCreatedFrom: DateTime
	) {
		laneContainers(
			laneIds: $laneIds
			containerIds: $containerIds
			containersSearchText: $containersSearchText
			containerStatuses: $containerStatuses
			hasException: $hasException
			dateCreatedFrom: $dateCreatedFrom
		) {
			id
			laneId
			packages {
				packageInternalId
				packageType
				timeScanned
			}
			containerStatus
			carrierPickupDetails {
				vehicleScan
				vehicleLaneId
				loadedAt
				isOverridden
			}
		}
	}
`;
