import { Link } from '@shopify/polaris';
import { BulkOrderProduct, Product } from '@sixriver/fulfillment-api-schema';

import * as routes from 'routes';

interface Props {
	product: BulkOrderProduct | Product;
}

export function BulkProduct({ product }: Props) {
	const externalId =
		product.__typename === 'BulkOrderProduct'
			? product.externalId
			: (product as Product).customerIdentifier;
	// Render
	return (
		<div key={`product-${product.id}`}>
			<Link url={routes.product(product.id)} removeUnderline>
				{externalId}
			</Link>
			<div>{product.name}</div>
		</div>
	);
}
