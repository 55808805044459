import { Select, TextStyle } from '@shopify/polaris';
import { ProductInventory } from '@sixriver/fulfillment-api-schema';
import { useEffect, useState } from 'react';

import { useLocationInventoryEventsHistory } from './LocationHistory.hook';
import { Timeline } from 'components/Timeline';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	locationId: string;
	productsInventory?: ProductInventory[];
	queryPause?: boolean;
	queryPollInterval: number;
}

interface StrictOption {
	/** Machine value of the option; this is the value passed to `onChange` */
	value: string;
	/** Human-readable text for the option */
	label: string;
	/** Option will be visible, but not selectable */
	disabled?: boolean;
	/** Element to display to the left of the option label. Does not show in the dropdown. */
	prefix?: React.ReactNode;
}

export function LocationHistory({
	productsInventory = [],
	locationId,
	queryPollInterval,
	queryPause,
}: Props) {
	const { messages } = useLocalization();

	// State
	const [productId, setProductId] = useState<string | undefined>();

	// Computations
	const isSelectEnabled = productsInventory.length > 1;
	const firstProductId = productsInventory?.[0]?.product?.id;

	// Effects
	// Set default product when location is loaded
	useEffect(() => {
		if (firstProductId) {
			setProductId(firstProductId);
		}
	}, [firstProductId]);

	// Events
	const { eventsError, eventsLoading, hasMore, loadMoreEvents, rows } =
		useLocationInventoryEventsHistory({
			locationId,
			productId,
			pause: queryPause,
			pollInterval: queryPollInterval,
		});

	// Markup
	const options: StrictOption[] = productsInventory.map(({ product }) => {
		return {
			label: product.name,
			value: product.id,
		};
	});

	// Render
	return (
		<Timeline
			title={messages.locationHistory}
			loading={rows.length < 1 ? eventsLoading : false}
			loadingMore={eventsLoading}
			events={rows}
			onLoadMore={hasMore ? loadMoreEvents : undefined}
			error={eventsError}
		>
			<Select
				label={
					<TextStyle variation={isSelectEnabled ? undefined : 'subdued'}>
						{messages.selectProduct}
					</TextStyle>
				}
				options={options}
				disabled={!isSelectEnabled}
				onChange={setProductId}
				value={productId}
			/>
		</Timeline>
	);
}
