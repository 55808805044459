import { FooterHelp } from '@shopify/polaris';

import { useLocalization } from 'hooks/useLocalization';

export function TimezoneFooter() {
	const { messages, translate, locale } = useLocalization();

	const formatter = new Intl.DateTimeFormat(locale, { timeZoneName: 'short' });
	const parts = formatter.formatToParts(new Date());
	const part = parts.find((p) => p.type === 'timeZoneName');
	const timezone = part?.value || 'UTC'; // e.g. "EDT" for "en-US" or "UTC-4" for "fr"
	const footer = translate(messages.timestampInfo, { timezone });

	return <FooterHelp>{footer}</FooterHelp>;
}
