import { ChoiceList } from '@shopify/polaris';
import { JobStatus } from '@sixriver/fulfillment-api-schema';

import { noop } from 'helpers/noop';
import { useLocalization } from 'hooks/useLocalization';

interface StatusesFilterProps {
	selected?: JobStatus[];
	onChange?: (selected: JobStatus[], name: string) => void;
}

export function StatusesFilter({ selected = [], onChange = noop }: StatusesFilterProps) {
	const { messages } = useLocalization();

	return (
		<ChoiceList
			title={messages.jobStatus}
			titleHidden
			choices={[
				{
					label: messages.picking,
					value: JobStatus.Picking,
				},
				{
					label: messages.picked,
					value: JobStatus.Picked,
				},
				{
					label: messages.readyToSort,
					value: JobStatus.Sortable,
				},
				{
					label: messages.sorting,
					value: JobStatus.Sorting,
				},
				{
					label: messages.sorted,
					value: JobStatus.Sorted,
				},
				{
					label: messages.readyToPack,
					value: JobStatus.Packable,
				},
				{
					label: messages.packing,
					value: JobStatus.Packing,
				},
				{
					label: messages.packed,
					value: JobStatus.Packed,
				},
				{
					label: messages.interrupted,
					value: JobStatus.Interrupted,
				},
				{
					label: messages.canceled,
					value: JobStatus.Canceled,
				},
			]}
			selected={selected}
			onChange={onChange}
			allowMultiple
		/>
	);
}
