import { Stack, Thumbnail, TextStyle, Link } from '@shopify/polaris';
import { ImageMajor } from '@shopify/polaris-icons';
import {
	KitComponentProductDetail,
	KitProduct as KitProductApi,
	WorkOrderProduct,
} from '@sixriver/fulfillment-api-schema';

import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';

interface Props {
	product: KitComponentProductDetail | KitProductApi | WorkOrderProduct;
	showUnits?: boolean;
	showFai?: boolean;
}

export function Product({ product, showUnits, showFai }: Props): JSX.Element {
	const { messages } = useLocalization();

	return (
		<Stack wrap={false}>
			<Thumbnail source={product.image ? product.image : ImageMajor} alt={product.name} />
			<Stack vertical={true} spacing="tight">
				<Title product={product} />
				<TextStyle>{product.name}</TextStyle>
				{showUnits ? (
					<TextStyle>
						{messages.unitsPerKit}: {(product as KitComponentProductDetail).eachQuantity}
					</TextStyle>
				) : null}
				{showFai ? <FaiMessage product={product} /> : null}
			</Stack>
		</Stack>
	);
}

function Title({ product }: Props): JSX.Element {
	return <Link url={routes.product(product.id)}>{product.customerIdentifier}</Link>;
}

function FaiMessage({ product }: Props): JSX.Element {
	const { messages } = useLocalization();

	return (product as KitProductApi).needsInspection ? (
		<TextStyle variation="negative">{messages.faiStatusIncomplete}</TextStyle>
	) : (
		<TextStyle variation="positive">{messages.faiStatusComplete}</TextStyle>
	);
}
