import { ChoiceList } from '@shopify/polaris';

import { noop } from 'helpers/noop';
import { useLocalization } from 'hooks/useLocalization';

interface LaneCarriersFilterProps {
	selected?: string[];
	onChange?: (selected: string[], name: string) => void;
	options?: string[];
}

export function LaneCarriersFilter({
	selected = [],
	onChange = noop,
	options = [],
}: LaneCarriersFilterProps) {
	const { messages } = useLocalization();

	return (
		<ChoiceList
			title={messages.carrier}
			titleHidden
			choices={options.map((carrier) => ({
				label: carrier,
				value: carrier,
			}))}
			selected={selected}
			onChange={onChange}
			allowMultiple
		/>
	);
}
