import { IndexTable, TextStyle } from '@shopify/polaris';
import { LaneContainer } from '@sixriver/fulfillment-api-schema';

interface ContainerQtyCellProps {
	containers: LaneContainer[];
}

export function ContainerQtyCell({ containers }: ContainerQtyCellProps) {
	return (
		<IndexTable.Cell>
			<TextStyle>{containers ? containers.length : 0} </TextStyle>
		</IndexTable.Cell>
	);
}
