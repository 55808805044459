import { Card, Stack, TextContainer, TextStyle } from '@shopify/polaris';
import { Return, ReturnType } from '@sixriver/fulfillment-api-schema';

import styles from './Details.module.css';
import { NoData } from 'components/NoData';
import { useLocalization } from 'hooks/useLocalization';

export function Details({ inboundReturn }: { inboundReturn?: Return }) {
	const { messages } = useLocalization();

	const returnTypes = {
		[ReturnType.Rma]: messages.returnTypes.RMA,
		[ReturnType.Rts]: messages.returnTypes.RTS,
	};

	return (
		<Card title={messages.returnDetails}>
			<Card.Section>
				<Stack vertical>
					<Stack.Item>
						<Stack>
							<div className={styles.detailsLabels}>
								<TextContainer>
									<TextStyle>{messages.originalOrder}</TextStyle>
								</TextContainer>
							</div>
							<div>
								<TextContainer>
									{inboundReturn?.originalOrderId ? (
										<TextContainer>
											<TextStyle>{inboundReturn?.originalOrderId}</TextStyle>
										</TextContainer>
									) : (
										<NoData />
									)}
								</TextContainer>
							</div>
						</Stack>
					</Stack.Item>
					<Stack.Item>
						<Stack>
							<div className={styles.detailsLabels}>
								<TextContainer>
									<TextStyle>{messages.store}</TextStyle>
								</TextContainer>
							</div>
							<div>
								<TextContainer>
									{inboundReturn?.merchant ? (
										<TextStyle>{inboundReturn?.merchant}</TextStyle>
									) : (
										<NoData />
									)}
								</TextContainer>
							</div>
						</Stack>
					</Stack.Item>
					<Stack.Item>
						<Stack>
							<div className={styles.detailsLabels}>
								<TextContainer>
									<TextStyle>{messages.returnType}</TextStyle>
								</TextContainer>
							</div>
							<div>
								<TextContainer>
									{inboundReturn?.returnType ? (
										<TextStyle>{returnTypes[inboundReturn.returnType]}</TextStyle>
									) : (
										<NoData />
									)}
								</TextContainer>
							</div>
						</Stack>
					</Stack.Item>
				</Stack>
			</Card.Section>
		</Card>
	);
}
