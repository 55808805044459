import styles from './illustrations.module.css';
import { useLocalization } from 'hooks/useLocalization';

export function GenericContainerIllustration() {
	const { messages } = useLocalization();

	return (
		<svg
			width="100%"
			height="200"
			viewBox="0 0 218 152"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M81 72L9.5 87L46 151L154 123L81 72Z" fill="#BCE9D0" />
			<path
				d="M1 42.5L80.5 30V72.5L9.5 86.5L1 42.5Z"
				fill="#BCE9D0"
				stroke="#6EC194"
				strokeLinejoin="round"
			/>
			<path
				d="M161 83L80.5 30V72L154.5 123L161 83Z"
				fill="#BCE9D0"
				stroke="#6EC194"
				strokeLinejoin="round"
			/>
			<path
				d="M43.5 107.5L1 42.5L9.5 87L45.5 150.5L43.5 107.5Z"
				fill="#108043"
				fillOpacity="0.21"
				stroke="#6EC194"
				strokeLinejoin="round"
			/>
			<path
				d="M43.5 107.5L161 84L154.213 123.213L45.5 151L43.5 107.5Z"
				fill="#108043"
				fillOpacity="0.21"
				stroke="#6EC194"
				strokeLinejoin="round"
			/>
			<path
				d="M115.536 45.8143C115.433 46.0707 115.558 46.3617 115.814 46.4642L119.992 48.1355C120.249 48.2381 120.54 48.1133 120.642 47.857C120.745 47.6006 120.62 47.3096 120.364 47.207L116.65 45.7215L118.135 42.0076C118.238 41.7512 118.113 41.4602 117.857 41.3576C117.601 41.2551 117.31 41.3798 117.207 41.6362L115.536 45.8143ZM125.284 33C125.284 36.1748 124.606 38.5579 123.119 40.5095C121.624 42.4731 119.268 44.0554 115.803 45.5404L116.197 46.4596C119.732 44.9446 122.268 43.2769 123.915 41.1155C125.57 38.9421 126.284 36.3252 126.284 33H125.284Z"
				fill="#108043"
			/>
			<path
				d="M23.52 31.9996C23.7959 31.9886 24.0106 31.7559 23.9996 31.48L23.8197 26.9836C23.8087 26.7077 23.5761 26.493 23.3001 26.504C23.0242 26.515 22.8095 26.7477 22.8205 27.0236L22.9804 31.0204L18.9836 31.1803C18.7077 31.1913 18.493 31.4239 18.504 31.6999C18.515 31.9758 18.7477 32.1905 19.0236 32.1795L23.52 31.9996ZM17 17.5C16.5 17.5 16.5 17.5002 16.5 17.5005C16.5 17.5006 16.5 17.5009 16.5 17.5012C16.5 17.5017 16.5 17.5023 16.5 17.5031C16.5 17.5046 16.5 17.5066 16.5 17.509C16.5001 17.514 16.5001 17.5208 16.5002 17.5296C16.5005 17.5471 16.5009 17.5722 16.5018 17.6045C16.5035 17.6691 16.5068 17.7628 16.5135 17.883C16.5267 18.1233 16.553 18.47 16.6051 18.9035C16.7094 19.7701 16.9175 20.9855 17.333 22.3917C18.1636 25.2031 19.8276 28.7906 23.1609 31.8674L23.8391 31.1326C20.6723 28.2094 19.0864 24.7969 18.292 22.1083C17.895 20.7645 17.6968 19.6049 17.598 18.784C17.5486 18.3737 17.5241 18.0486 17.5119 17.828C17.5059 17.7177 17.5029 17.6336 17.5014 17.5781C17.5007 17.5504 17.5003 17.5298 17.5002 17.5167C17.5001 17.5101 17.5 17.5054 17.5 17.5026C17.5 17.5012 17.5 17.5003 17.5 17.4999C17.5 17.4996 17.5 17.4996 17.5 17.4996C17.5 17.4996 17.5 17.4997 17.5 17.4997C17.5 17.4998 17.5 17.5 17 17.5Z"
				fill="#108043"
			/>
			<path
				d="M164.646 111.128C164.519 110.883 164.615 110.581 164.86 110.454L168.855 108.384C169.1 108.257 169.402 108.352 169.529 108.598C169.656 108.843 169.56 109.145 169.315 109.272L165.764 111.112L167.604 114.663C167.731 114.909 167.635 115.21 167.39 115.337C167.145 115.464 166.843 115.369 166.716 115.123L164.646 111.128ZM190.648 102.227C186.18 111.005 174.314 114.35 164.938 111.374L165.241 110.421C174.281 113.29 185.567 110.004 189.757 101.773L190.648 102.227Z"
				fill="#108043"
			/>
			<path
				d="M1.5 42.5L80.5 30L161.5 83.5L154.5 122.5"
				stroke="#108043"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>

			<text className={styles.svgTextBigGreen} x="0" y="15">
				{messages.width}
			</text>

			<text className={styles.svgTextBigGreen} x="100" y="28">
				{messages.length}
			</text>
			<text className={styles.svgTextBigGreen} x="165" y="100">
				{messages.height}
			</text>
		</svg>
	);
}
