import { IndexTable } from '@shopify/polaris';
import { LaneContainer } from '@sixriver/fulfillment-api-schema';

import { LaneContainerStatusBadge } from 'components/LaneContainerStatusBadge';
import { NoData } from 'components/NoData';

interface Props {
	container: LaneContainer;
}

export function StatusCell({ container }: Props): JSX.Element {
	if (container.id) {
		return (
			<IndexTable.Cell>
				<LaneContainerStatusBadge container={container} />
			</IndexTable.Cell>
		);
	}

	return (
		<IndexTable.Cell>
			<NoData />
		</IndexTable.Cell>
	);
}
