import { Count } from '@sixriver/fulfillment-api-schema';
import gql from 'graphql-tag';

export interface OutboundJobsCounts {
	TotalCount: Count;
	StaticUnassignedCount: Count;
	UnassignedCount: Count;
	StaticPickingCount: Count;
	PickingCount: Count;
	StaticPickedCount: Count;
	PickedCount: Count;
	StaticSortableCount: Count;
	SortableCount: Count;
	StaticSortingCount: Count;
	SortingCount: Count;
	StaticSortedCount: Count;
	SortedCount: Count;
	StaticPackableCount: Count;
	PackableCount: Count;
	StaticPackingCount: Count;
	PackingCount: Count;
	StaticPackedCount: Count;
	PackedCount: Count;
	StaticInterruptedCount: Count;
	InterruptedCount: Count;
	LateCount: Count;
	ShortedCount: Count;
}

export const OUTBOUND_JOBS_COUNT_QUERY = gql`
	query JobsCount(
		$ids: [String!]
		$searchText: String
		$statuses: [JobStatus!]
		$isLate: Boolean
		$isShorted: Boolean
		$expectedShipDateFrom: DateTime
		$expectedShipDateTo: DateTime
		$createdAtFrom: DateTime
		$pickStrategies: [PickStrategy!]
		$workOrderTypes: [WorkOrderType!]
	) {
		TotalCount: jobsV2Count(
			ids: $ids
			searchText: $searchText
			statuses: $statuses
			isLate: $isLate
			isShorted: $isShorted
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			createdAtFrom: $createdAtFrom
			pickStrategies: $pickStrategies
			workOrderTypes: $workOrderTypes
			isOrder: false
		) {
			count
		}


		StaticUnassignedCount: jobsV2Count(statuses: [Unassigned], isOrder: false, createdAtFrom: $createdAtFrom) {
			count
		}

		UnassignedCount: jobsV2Count(
			ids: $ids
			searchText: $searchText
			statuses: [Unassigned]
			createdAtFrom: $createdAtFrom
			isOrder: false
		) {
			count
		}

		StaticPickingCount: jobsV2Count(statuses: [Picking], isOrder: false, createdAtFrom: $createdAtFrom) {
			count
		}

		PickingCount: jobsV2Count(
			ids: $ids
			searchText: $searchText
			statuses: [Picking]
			createdAtFrom: $createdAtFrom
			isOrder: false
		) {
			count
		}

		StaticPickedCount: jobsV2Count(statuses: [Picked], isOrder: false, createdAtFrom: $createdAtFrom) {
			count
		}

		PickedCount: jobsV2Count(
			ids: $ids
			searchText: $searchText
			statuses: [Picked]
			createdAtFrom: $createdAtFrom
			isOrder: false
		) {
			count
		}

		StaticSortableCount: jobsV2Count(statuses: [Sortable], isOrder: false, createdAtFrom: $createdAtFrom) {
			count
		}

		SortableCount: jobsV2Count(
			ids: $ids
			searchText: $searchText
			statuses: [Sortable]
			createdAtFrom: $createdAtFrom
			isOrder: false
		) {
			count
		}

		StaticSortingCount: jobsV2Count(statuses: [Sorting], isOrder: false, createdAtFrom: $createdAtFrom) {
			count
		}

		SortingCount: jobsV2Count(
			ids: $ids
			searchText: $searchText
			statuses: [Sorting]
			createdAtFrom: $createdAtFrom
			isOrder: false
		) {
			count
		}

		StaticSortedCount: jobsV2Count(statuses: [Sorted], isOrder: false, createdAtFrom: $createdAtFrom) {
			count
		}

		SortedCount: jobsV2Count(
			ids: $ids
			searchText: $searchText
			statuses: [Sorted]
			createdAtFrom: $createdAtFrom
			isOrder: false
		) {
			count
		}

		StaticPackableCount: jobsV2Count(statuses: [Packable], isOrder: false, createdAtFrom: $createdAtFrom) {
			count
		}

		PackableCount: jobsV2Count(
			ids: $ids
			searchText: $searchText
			statuses: [Packable]
			createdAtFrom: $createdAtFrom
			isOrder: false
		) {
			count
		}

		StaticPackingCount: jobsV2Count(statuses: [Packing], isOrder: false, createdAtFrom: $createdAtFrom) {
			count
		}

		PackingCount: jobsV2Count(
			ids: $ids
			searchText: $searchText
			statuses: [Packing]
			createdAtFrom: $createdAtFrom
			isOrder: false
		) {
			count
		}

		StaticPackedCount: jobsV2Count(statuses: [Packed], isOrder: false, createdAtFrom: $createdAtFrom) {
			count
		}

		PackedCount: jobsV2Count(
			ids: $ids
			searchText: $searchText
			statuses: [Packed]
			createdAtFrom: $createdAtFrom
			isOrder: false
		) {
			count
		}

		StaticInterruptedCount: jobsV2Count(statuses: [Interrupted], isOrder: false, createdAtFrom: $createdAtFrom) {
			count
		}

		InterruptedCount: jobsV2Count(
			ids: $ids
			searchText: $searchText
			statuses: [Interrupted]
			createdAtFrom: $createdAtFrom
			isOrder: false
		) {
			count
		}

		LateCount: jobsV2Count(
			ids: $ids
			searchText: $searchText
			isLate: true
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			createdAtFrom: $createdAtFrom
			pickStrategies: $pickStrategies
			isOrder: false
		) {
			count
		}

		ShortedCount: jobsV2Count(
			ids: $ids
			searchText: $searchText
			isShorted: true
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			createdAtFrom: $createdAtFrom
			pickStrategies: $pickStrategies
			isOrder: false
		) {
			count
		}
	}
`;
