import { Card, Link, Stack, TextContainer, TextStyle, Thumbnail } from '@shopify/polaris';
import {
	Return,
	ReturnLine,
	ReturnIssue,
	ReturnStatus,
	ReworkJobsSummaryPage,
} from '@sixriver/fulfillment-api-schema';

import styles from './Lines.module.css';
import { Divider } from 'components/Divider';
import { NoData } from 'components/NoData';
import { useLocalization } from 'hooks/useLocalization';
import Placeholder from 'images/placeholder.svg';
import * as routes from 'routes';
import { transformImageUrl } from 'utils/image';

interface Props {
	inboundReturn?: Return;
	reworkJobs?: ReworkJobsSummaryPage;
}

export function Lines({ inboundReturn, reworkJobs }: Props) {
	const { messages } = useLocalization();

	const returnLines = inboundReturn?.returnLines || [];
	const returnIssues = inboundReturn?.issues || [];

	const expectedUnits: ReturnLine[] = [];
	const unexpectedUnits: ReturnLine[] = [];

	// TODO: update this mapping to use the new return line unit schema when it is available
	returnLines.forEach((returnLine) => {
		if (returnLine.expectedQuantity > 0) {
			expectedUnits.push(returnLine);
			return;
		}

		// a return line is considered unexpected if the expectedQuantity === 0 and the returnedQuantity > 0
		if (returnLine.expectedQuantity === 0 && returnLine.returnedQuantity > 0) {
			unexpectedUnits.push(returnLine);
		}

		return;
	});

	const UnitCard = ({
		returnLine,
		noData,
		sellable,
		unsellable,
		pending,
		index = 0,
	}: {
		returnLine: ReturnLine;
		noData?: boolean;
		sellable?: boolean;
		unsellable?: boolean;
		pending?: boolean;
		index?: number;
	}) => {
		const returnLineProductId = returnLine.product.id;
		const reworkJobsForProduct = reworkJobs?.results?.filter(
			(reworkJob) => reworkJob.product.id === returnLineProductId,
		);

		const associatedReworkJob = reworkJobsForProduct?.[index];

		return (
			<Card.Section>
				<div className={styles.productColumns}>
					{returnLine.product?.id ? (
						<Stack spacing="tight">
							<Thumbnail
								source={transformImageUrl(returnLine.product?.image)}
								alt={returnLine.product?.description || ''}
								size="small"
							/>
							<Stack vertical spacing="extraTight">
								<Link url={routes.product(returnLine.product.id)} removeUnderline>
									{returnLine.product.description}
								</Link>
								<TextStyle variation="subdued">{returnLine.product.name}</TextStyle>
							</Stack>
						</Stack>
					) : (
						<NoData />
					)}
					{noData ? (
						<>
							{inboundReturn?.returnStatus === ReturnStatus.Completed ? (
								<TextContainer>
									<TextStyle variation="subdued">{messages.missing}</TextStyle>
								</TextContainer>
							) : (
								<NoData />
							)}
						</>
					) : (
						<div>
							<TextContainer>
								<TextStyle variation="subdued">
									{sellable && messages.sellable}
									{unsellable && messages.damaged}
									{pending && messages.sentForRework}
								</TextStyle>
							</TextContainer>
							{pending && associatedReworkJob && (
								<Link
									url={routes.reworkJob(associatedReworkJob.id)}
									key="rework"
									removeUnderline
									external
								>
									{messages.viewJob}
								</Link>
							)}
						</div>
					)}
				</div>
			</Card.Section>
		);
	};

	return (
		<Card title={messages.returnedInventory}>
			<Card.Section>
				<div className={styles.productColumns}>
					<TextContainer>
						<TextStyle variation="strong">{messages.product}</TextStyle>
					</TextContainer>
					<TextContainer>
						<TextStyle variation="strong">{messages.condition}</TextStyle>
					</TextContainer>
				</div>
			</Card.Section>
			{expectedUnits.map((returnLine: ReturnLine, index) => (
				<div key={index}>
					<Divider />
					<div key={`expected-${returnLine.id}-container-${index}`}>
						{returnLine.expectedQuantity - returnLine.returnedQuantity > 0 &&
							[...Array(returnLine.expectedQuantity - returnLine.returnedQuantity)].map(
								(_, index) => (
									<UnitCard
										key={`expected-${returnLine.id}-${index}-units-pending`}
										returnLine={returnLine}
										noData
									/>
								),
							)}
						{[...Array(returnLine.sellableQuantity)].map((_, index) => (
							<UnitCard
								key={`expected-${returnLine.id}-${index}-sellable`}
								returnLine={returnLine}
								sellable
							/>
						))}
						{[...Array(returnLine.damagedQuantity)].map((_, index) => (
							<UnitCard
								key={`expected-${returnLine.id}-${index}-unsellable`}
								returnLine={returnLine}
								unsellable
							/>
						))}
						{[...Array(returnLine.pendingQuantity)].map((_, index) => (
							<UnitCard
								index={index}
								key={`expected-${returnLine.id}-${index}-pending`}
								returnLine={returnLine}
								pending
							/>
						))}
					</div>
				</div>
			))}
			{(returnIssues.length > 0 || unexpectedUnits.length > 0) && (
				<>
					<div className={styles.subduedSection}>
						<TextContainer>
							<TextStyle variation="strong">{messages.unexpectedInventory}</TextStyle>
						</TextContainer>
					</div>
					{unexpectedUnits.map((returnLine: ReturnLine, index) => (
						<div key={index}>
							<Divider />
							<div key={`unexpected-${returnLine.id}-container-${index}`}>
								{[...Array(returnLine.sellableQuantity)].map((_, index) => (
									<UnitCard
										key={`unexpected-${returnLine.id}-${index}-sellable`}
										returnLine={returnLine}
										sellable
									/>
								))}
								{[...Array(returnLine.damagedQuantity)].map((_, index) => (
									<UnitCard
										key={`unexpected-${returnLine.id}-${index}-unsellable`}
										returnLine={returnLine}
										unsellable
									/>
								))}
								{[...Array(returnLine.pendingQuantity)].map((_, index) => (
									<UnitCard
										key={`unexpected-${returnLine.id}-${index}-pending`}
										returnLine={returnLine}
										pending
									/>
								))}
							</div>
						</div>
					))}
					{returnIssues.map(
						(issue: ReturnIssue) =>
							issue.reason === 'UnknownItem' && (
								<div key={issue.id}>
									<Divider />
									<Card.Section>
										<div className={styles.productColumns}>
											<Stack alignment="center">
												<Thumbnail size="small" source={Placeholder} alt={messages.unknownItem} />
												<TextContainer>
													<TextStyle variation="subdued">{messages.unknownItem}</TextStyle>
												</TextContainer>
											</Stack>
											<TextContainer>
												<TextStyle variation="subdued">n/a</TextStyle>
											</TextContainer>
										</div>
									</Card.Section>
								</div>
							),
					)}
				</>
			)}
		</Card>
	);
}
