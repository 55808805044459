import { IndexTable, TextStyle } from '@shopify/polaris';
import { LineV2 } from '@sixriver/fulfillment-api-schema';

import { useLocalization } from 'hooks/useLocalization';

interface Props {
	line: LineV2;
}

export function PackingUnitsCell({ line }: Props): JSX.Element {
	const { messages, translate } = useLocalization();

	return (
		<IndexTable.Cell>
			<TextStyle>
				{translate(messages.countUnits, {
					count: line.quantity,
				})}
			</TextStyle>
		</IndexTable.Cell>
	);
}
