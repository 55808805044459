import styles from './illustrations.module.css';
import { useLocalization } from 'hooks/useLocalization';

export function SubsectionIllustration() {
	const { messages } = useLocalization();

	return (
		<svg
			width="100%"
			height="200"
			viewBox="0 0 385 190"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M238.67 13.0576C236.228 12.6425 234 14.524 234 17.001V43.5302C234 45.1784 235.011 46.6579 236.547 47.2568L250 52.5036V66.7636L263 73.2636V57.5736L278.547 63.6368C281.169 64.6597 284 62.7253 284 59.9102V24.141C284 22.1906 282.593 20.5245 280.67 20.1976L238.67 13.0576Z"
				fill="#D9D9D9"
			/>
			<path
				d="M238.67 13.0576L238.503 14.0435L238.67 13.0576ZM236.547 47.2568L236.91 46.3252H236.91L236.547 47.2568ZM250 52.5036H251V51.8203L250.363 51.572L250 52.5036ZM250 66.7636H249V67.3817L249.553 67.6581L250 66.7636ZM263 73.2636L262.553 74.1581L264 74.8817V73.2636H263ZM263 57.5736L263.363 56.642L262 56.1103V57.5736H263ZM278.547 63.6368L278.183 64.5685L278.547 63.6368ZM280.67 20.1976L280.503 21.1835L280.67 20.1976ZM235 17.001C235 15.1433 236.671 13.7321 238.503 14.0435L238.838 12.0717C235.786 11.5528 233 13.9048 233 17.001H235ZM235 43.5302V17.001H233V43.5302H235ZM236.91 46.3252C235.758 45.876 235 44.7664 235 43.5302H233C233 45.5905 234.264 47.4399 236.183 48.1885L236.91 46.3252ZM250.363 51.572L236.91 46.3252L236.183 48.1885L249.637 53.4353L250.363 51.572ZM251 66.7636V52.5036H249V66.7636H251ZM263.447 72.3692L250.447 65.8692L249.553 67.6581L262.553 74.1581L263.447 72.3692ZM262 57.5736V73.2636H264V57.5736H262ZM278.91 62.7052L263.363 56.642L262.637 58.5053L278.183 64.5685L278.91 62.7052ZM283 59.9102C283 62.0215 280.877 63.4723 278.91 62.7052L278.183 64.5685C281.462 65.847 285 63.4291 285 59.9102H283ZM283 24.141V59.9102H285V24.141H283ZM280.503 21.1835C281.945 21.4286 283 22.6782 283 24.141H285C285 21.703 283.242 19.6204 280.838 19.2117L280.503 21.1835ZM238.503 14.0435L280.503 21.1835L280.838 19.2117L238.838 12.0717L238.503 14.0435Z"
				fill="#D9D9D9"
			/>
			<path
				d="M41.9916 72.0859L208.301 44.4486C210.06 44.1561 211.867 44.3932 213.492 45.1298L320.953 93.8452C321.808 94.233 321.705 95.4796 320.798 95.722L76.5 161L40.4743 74.8303C39.9767 73.6403 40.7192 72.2974 41.9916 72.0859Z"
				fill="white"
				stroke="#D9D9D9"
				strokeWidth="2"
			/>
			<path
				d="M320 96L81.2021 159.745C78.7927 160.388 76.2777 159.157 75.3069 156.86L40.5 74.5V79.2329C40.5 80.3998 40.7269 81.5556 41.1682 82.6359L75.3385 166.299C76.2888 168.625 78.8183 169.885 81.2476 169.241L315.536 107.182C318.168 106.485 320 104.104 320 101.382V96Z"
				fill="#D9D9D9"
				stroke="#D9D9D9"
				strokeWidth="2"
			/>
			<path d="M80.5 160V169.5" stroke="#D9D9D9" strokeWidth="2" />
			<path d="M75.5 157.5V166.5" stroke="#D9D9D9" strokeWidth="2" strokeLinecap="round" />
			<path d="M128.5 61L64 72L85 108L173.5 92L128.5 61Z" fill="white" />
			<path d="M47.5 34.5L126.5 22V64.5L55.5 78.5L47.5 34.5Z" fill="white" stroke="#D9D9D9" />
			<path d="M207 75L126.5 22V64L200.5 115L207 75Z" fill="white" stroke="#D9D9D9" />
			<path
				d="M117 55.2418L87 29L89.8916 63.1503L112.301 84L117 55.2418Z"
				fill="white"
				stroke="#D9D9D9"
			/>
			<path d="M62.5 58.5L158 43V93.5L75 109L62.5 58.5Z" fill="white" />
			<path d="M112 51L158 43V93.5" stroke="#D9D9D9" />
			<path d="M65 60L47 34.5L55.5 79L69 100L65 60Z" fill="white" stroke="#D9D9D9" />
			<path d="M153 87L207 76L200.213 115.213L153 127V87Z" fill="white" stroke="#D9D9D9" />
			<path d="M114.5 90.5L69 100L93 143L154 127L114.5 90.5Z" fill="#BCE9D0" />
			<path
				d="M64 58.5L112.5 50.5L114.5 90.5L68.5 100L64 58.5Z"
				fill="#BCE9D0"
				stroke="#6EC194"
				strokeLinejoin="round"
			/>
			<path
				d="M154 87L112.5 50.5L114.5 90.5L154 127V87Z"
				fill="#BCE9D0"
				stroke="#6EC194"
				strokeLinejoin="round"
			/>
			<path
				d="M154 87L91 100L64 58.5L68.5 100L93 143L154 127.5V87Z"
				fill="#98D3B2"
				fillOpacity="0.8"
				stroke="#6EC194"
				strokeLinejoin="round"
			/>
			<line x1="90.9995" y1="99.9768" x2="92.9995" y2="142.977" stroke="#6EC194" />

			<path
				d="M146.517 69.8695C146.445 70.1361 146.603 70.4106 146.87 70.4827L151.214 71.6568C151.48 71.7288 151.755 71.5711 151.827 71.3045C151.899 71.038 151.741 70.7635 151.475 70.6914L147.613 69.6478L148.657 65.7863C148.729 65.5197 148.571 65.2452 148.305 65.1732C148.038 65.1011 147.763 65.2588 147.691 65.5254L146.517 69.8695ZM165.506 27.0754C166.245 31.9241 166.198 39.8961 163.636 47.9865C161.076 56.0686 156.017 64.2432 146.751 69.5664L147.249 70.4336C156.783 64.9568 161.974 56.548 164.589 48.2885C167.202 40.0373 167.255 31.9092 166.494 26.9246L165.506 27.0754Z"
				fill="#108043"
			/>
			<line x1="19.4684" y1="89.825" x2="53.4684" y2="180.825" stroke="#999999" />
			<line
				x1="51.2794"
				y1="181.238"
				x2="55.0872"
				y2="179.721"
				stroke="#999999"
				strokeLinecap="round"
			/>
			<line
				x1="17.2794"
				y1="90.2381"
				x2="21.0872"
				y2="88.7207"
				stroke="#999999"
				strokeLinecap="round"
			/>
			<rect
				x="24.7797"
				y="122.475"
				width="21.4579"
				height="39.6781"
				transform="rotate(-22.5513 24.7797 122.475)"
				fill="white"
			/>

			<line x1="84.8683" y1="187.477" x2="322.868" y2="122.477" stroke="#999999" />
			<line
				x1="322.441"
				y1="120.35"
				x2="323.523"
				y2="124.304"
				stroke="#999999"
				strokeLinecap="round"
			/>
			<line
				x1="84.6143"
				y1="185.309"
				x2="85.6966"
				y2="189.263"
				stroke="#999999"
				strokeLinecap="round"
			/>
			<rect
				x="191.112"
				y="151.225"
				width="41.777"
				height="18.0137"
				transform="rotate(-16.3351 191.112 151.225)"
				fill="white"
			/>

			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M97.0442 154.595C96.9773 154.944 96.9845 155.314 97.0784 155.681C97.4201 157.019 98.7816 157.827 100.119 157.485L119.244 152.599C120.582 152.258 121.389 150.896 121.047 149.558C120.928 149.091 120.684 148.689 120.36 148.377L97.0442 154.595ZM130.977 145.546C130.934 145.858 130.949 146.183 131.032 146.507C131.374 147.845 132.735 148.652 134.073 148.311L153.197 143.425C154.535 143.084 155.342 141.722 155.001 140.384C154.893 139.963 154.685 139.595 154.407 139.298L130.977 145.546ZM164.916 136.496C164.892 136.769 164.913 137.052 164.985 137.333C165.327 138.671 166.688 139.478 168.026 139.137L187.151 134.251C188.488 133.909 189.296 132.548 188.954 131.21C188.858 130.834 188.681 130.499 188.447 130.221L164.916 136.496ZM198.862 127.443C198.853 127.679 198.877 127.919 198.939 128.159C199.28 129.497 200.642 130.304 201.98 129.962L221.104 125.077C222.442 124.735 223.249 123.374 222.908 122.036C222.823 121.703 222.674 121.403 222.479 121.145L198.862 127.443ZM232.814 118.39C232.816 118.586 232.841 118.786 232.892 118.985C233.234 120.323 234.595 121.13 235.933 120.788L255.058 115.903C256.395 115.561 257.203 114.2 256.861 112.862C256.787 112.571 256.665 112.306 256.505 112.072L232.814 118.39ZM266.771 109.334C266.78 109.492 266.805 109.652 266.846 109.811C267.187 111.148 268.549 111.956 269.886 111.614L289.011 106.729C290.349 106.387 291.156 105.025 290.814 103.688C290.751 103.439 290.652 103.208 290.524 103L266.771 109.334Z"
				fill="#999999"
			/>
			<path
				d="M72.0553 48.4969C72.3298 48.4664 72.5275 48.2192 72.497 47.9448L72.0001 43.4723C71.9696 43.1979 71.7224 43.0001 71.4479 43.0306C71.1735 43.0611 70.9757 43.3083 71.0062 43.5827L71.4479 47.5583L67.4724 48C67.198 48.0305 67.0002 48.2777 67.0307 48.5522C67.0612 48.8266 67.3084 49.0244 67.5828 48.9939L72.0553 48.4969ZM64.5329 14.822C60.4866 25.4435 61.4586 40.2072 71.6878 48.3904L72.3124 47.6096C62.5415 39.7928 61.5136 25.5565 65.4673 15.178L64.5329 14.822Z"
				fill="#108043"
			/>
			<path
				d="M157.529 107.635C157.435 107.375 157.569 107.089 157.829 106.995L162.06 105.463C162.32 105.369 162.606 105.503 162.7 105.763C162.794 106.023 162.66 106.309 162.4 106.403L158.639 107.765L160.001 111.526C160.095 111.786 159.96 112.072 159.701 112.166C159.441 112.26 159.154 112.126 159.06 111.866L157.529 107.635ZM232.392 97.3109C225.521 105.962 212.297 111.152 198.15 112.922C183.989 114.694 168.763 113.059 157.787 107.918L158.211 107.012C168.972 112.053 183.996 113.686 198.026 111.93C212.071 110.172 224.979 105.038 231.608 96.6891L232.392 97.3109Z"
				fill="#108043"
			/>
			<path
				d="M64 58.5L112.5 51L153.5 86.5V127"
				stroke="#108043"
				strokeWidth="2"
				strokeLinejoin="round"
			/>

			<text className={styles.svgTextBigGreen} x="40" y="10">
				{messages.length}
			</text>
			<text className={styles.svgTextBigGreen} x="145" y="20">
				{messages.width}
			</text>
			<text className={styles.svgTextBigGreen} x="210" y="95">
				{messages.height}
			</text>

			<text className={styles.svgTextBigGrey} x="212" y="150">
				{'X'}
			</text>
			<text className={styles.svgTextBigGrey} x="198" y="162">
				({messages.rows})
			</text>
			<text className={styles.svgTextBigGrey} x="25" y="135">
				{'Y'}
			</text>
			<text className={styles.svgTextBigGrey} x="0" y="146">
				({messages.columns})
			</text>

			<text className={styles.svgTextSmall} x="295" y="68">
				{messages.chuckWorkspace}
			</text>
		</svg>
	);
}
