import { Button, Card, Link, Stack } from '@shopify/polaris';
import { BulkOrder, BulkOrderStatus, MutationResponse } from '@sixriver/fulfillment-api-schema';
import { useCallback, useState } from 'react';
import { useMutation } from 'urql';

import styles from '../Cards.module.css';
import { BatchState } from 'components/BatchState';
import { CloseBulkOrderModal } from 'components/CloseBulkOrderModal';
import { Error } from 'components/Error';
import { ErrorBanner } from 'components/ErrorBanner';
import { useLocalization } from 'hooks/useLocalization';
import { CLOSE_BULK_ORDER_MUTATION } from 'pages/BulkOrders/BulkOrders.graphql';
import * as routes from 'routes';

interface Props {
	loading?: boolean;
	order?: BulkOrder;
}

const getParams = (batchId: string, bulkOrderProjectionId: string) => {
	const searchParams = new URLSearchParams();

	searchParams.set('batchId', batchId);
	searchParams.set('bulkOrderProjectionId', bulkOrderProjectionId);

	return '?' + searchParams.toString();
};

export function Overview({ order, loading }: Props) {
	const { messages } = useLocalization();

	const [isCloseBatchModalOpen, setIsCloseBatchModalOpen] = useState(false);
	const [didBulkOrderCloseFail, setDidBulkOrderCloseFail] = useState<boolean>(false);

	const closeCloseBatchModal = () => {
		setIsCloseBatchModalOpen(false);
	};
	const openCloseBatchModal = () => {
		setIsCloseBatchModalOpen(true);
	};

	// Mutations
	const [{ error: closeBulkOrderError }, closeBulkOrderMutation] = useMutation<
		{ closeBulkOrder: MutationResponse },
		{ id: String }
	>(CLOSE_BULK_ORDER_MUTATION);

	// Methods
	const submitBulkOrderCloseRequest = useCallback(async () => {
		setIsCloseBatchModalOpen(false); // close modal

		// Cancel by Id(s)
		const { data, error } = await closeBulkOrderMutation({ id: order?.id || '' });

		// Error
		if (error || !data?.closeBulkOrder?.success) {
			setDidBulkOrderCloseFail(true);
		}

		// Success
		if (!error && data?.closeBulkOrder?.success) {
			setDidBulkOrderCloseFail(false);
		}
	}, [closeBulkOrderMutation, order?.id]);

	const error = closeBulkOrderError;

	if (error) {
		return <Error graphQLError={error} />;
	}

	return (
		<Card title={messages.overview}>
			{didBulkOrderCloseFail ? (
				<Card.Section>
					<ErrorBanner isVisible onDismiss={() => setDidBulkOrderCloseFail(false)} />
				</Card.Section>
			) : null}
			<Card.Section>
				<Stack vertical={false} distribution={'fillEvenly'}>
					<Stack vertical>
						<p className={styles.header}>{messages.ordersCollected}</p>
						<p className={styles.number}>{order?.ordersCollectedCount}</p>
					</Stack>
					<Stack vertical>
						<p className={styles.header}>{messages.ordersPacked}</p>
						<p className={styles.number}>{order?.ordersPackedCount}</p>
					</Stack>
					<Stack vertical>
						<p className={styles.header}>{messages.batchState}</p>
						<BatchState key={`batchState-${order?.id}`} batchState={order?.status} />,
					</Stack>
				</Stack>
			</Card.Section>
			<Card.Section>
				<Stack distribution={'leading'}>
					<Button
						onClick={openCloseBatchModal}
						disabled={
							order?.status === BulkOrderStatus.Sealed || order?.status === BulkOrderStatus.Closed
						}
					>
						{messages.closeBatch}
					</Button>
					<CloseBulkOrderModal
						isOpen={isCloseBatchModalOpen}
						onClose={closeCloseBatchModal}
						onConfirm={submitBulkOrderCloseRequest}
						loading={loading}
					/>
					<Link
						url={`${routes.orders()}${getParams(order?.lpn || '', order?.id || '')}`}
						removeUnderline
					>
						{messages.viewBulkOrders}
					</Link>
				</Stack>
			</Card.Section>
		</Card>
	);
}
