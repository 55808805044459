import { Line } from '@sixriver/fulfillment-api-schema';

import { isLineException } from 'helpers/exception';

export enum SortWallLineViews {
	All = 'All',
	Exceptions = 'Exceptions',
}

export const SortWallLineViewStateMap: {
	[key in SortWallLineViews]: (lines?: Line[]) => Line[];
} = {
	[SortWallLineViews.All]: (lines) => lines || [],
	[SortWallLineViews.Exceptions]: (lines) => (lines || []).filter((line) => isLineException(line)),
};
