import { Link, Badge } from '@shopify/polaris';
import { useDebouncedValue } from '@shopify/react-hooks';
import { LinesViews, LineStatus, Line, PackoutStatus } from '@sixriver/fulfillment-api-schema';
import { useState } from 'react';

import { DataTable, Column } from 'components/DataTable';
import { NoData } from 'components/NoData';
import { countLineExceptions } from 'helpers/exception';
import { useFilters, useSetFilters } from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';

interface Props {
	isInventoryEnabled?: boolean;
	lines?: Line[];
	isPackoutEnabled?: boolean;
	packoutStatus?: PackoutStatus;
}

export function Lines({ lines, isInventoryEnabled, isPackoutEnabled, packoutStatus }: Props) {
	const { messages, translate } = useLocalization();

	const { view = LinesViews.All, query } = useFilters();

	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);

	const searchText = useDebouncedValue(query) || '';

	const setFilters = useSetFilters();

	const columns: Column[] = [
		{
			type: 'text',
			heading: messages.item,
		},
		{
			type: 'text',
			heading: isInventoryEnabled ? messages.sku : messages.name,
		},
		{
			type: 'text',
			heading: messages.units,
		},
		{
			type: 'text',
			heading: messages.progress,
		},
		{
			type: 'text',
			heading: messages.exception,
		},
	];

	const matches = searchText
		? lines?.filter((line) => {
				return (
					line.product?.customerIdentifier.includes(searchText) ||
					line.product?.name.includes(searchText) ||
					line.status?.includes(searchText as LineStatus)
				);
		  })
		: lines;
	const getLineStatusCount = (lineStatus: LineStatus) => {
		if (lines?.length) {
			return lines.filter(({ status }) => status?.includes(lineStatus)).length;
		}
		return 0;
	};
	const getLineExceptionStatusCount = () => {
		if (lines?.length) {
			return lines.filter((line) => countLineExceptions([line]) > 0).length;
		}
		return 0;
	};
	const counts = {
		All: lines?.length || 0,
		Unassigned: getLineStatusCount(LineStatus.Unassigned),
		InProgress: getLineStatusCount(LineStatus.InProgress),
		Complete: getLineStatusCount(LineStatus.Complete) || getLineStatusCount(LineStatus.Done),
		Exceptions: getLineExceptionStatusCount(),
	};

	const rows = (matches || [])
		.filter((line) => {
			if (view === LinesViews.Exceptions) {
				return countLineExceptions([line]) > 0;
			}
			if (view === LinesViews.Complete) {
				return line.status?.includes(LineStatus.Complete) || line.status?.includes(LineStatus.Done);
			}
			return view === LinesViews.All || line.status?.includes(view as LineStatus);
		})
		.map((line) => {
			const hasException = countLineExceptions([line]) > 0;

			return [
				line?.product?.id ? (
					<Link url={routes.product(line.product.id)} removeUnderline>
						{line.product.customerIdentifier}
					</Link>
				) : (
					<NoData />
				),
				line?.product?.name || <NoData />,
				translate(messages.pickedOfTotal, {
					picked: line.doneQuantity,
					total: line.quantity,
				}),
				line.status ? (
					line.status.includes(LineStatus.AwaitingInventory) ? (
						<Badge status="warning">{messages.awaitingInventory}</Badge>
					) : line.status.includes(LineStatus.Unassigned) ? (
						<Badge status="warning">{messages.unassigned}</Badge>
					) : line.status.includes(LineStatus.InProgress) ? (
						<Badge status="info">{messages.inProgress}</Badge>
					) : packoutStatus === PackoutStatus.Inducted ? (
						<Badge status="info">{messages.packing}</Badge>
					) : line.status.includes(LineStatus.Complete) || line.status.includes(LineStatus.Done) ? (
						isPackoutEnabled ? (
							packoutStatus ? (
								<Badge>{messages.readyToPack}</Badge>
							) : (
								<Badge>{messages.picked}</Badge>
							)
						) : (
							<Badge>{messages.completed}</Badge>
						)
					) : line.status.includes(LineStatus.Packed) ? (
						<Badge>{messages.completed}</Badge>
					) : (
						<NoData />
					)
				) : (
					<NoData />
				),
				hasException ? (
					<Badge status="critical">{messages.exception}</Badge>
				) : line?.status && line.status.includes(LineStatus.Cleared) ? (
					<Badge status="success">{messages.cleared}</Badge>
				) : (
					<NoData />
				),
			];
		});

	return (
		<DataTable
			title={messages.lines}
			columns={columns}
			rows={rows}
			emptyStateHeading={messages.linesNotFound}
			paginationCursors={paginationCursors}
			setPaginationCursors={setPaginationCursors}
			views={[
				{
					label: messages.all,
					metaLabel: counts?.All || 0,
					id: LinesViews.All,
				},
				{
					label: messages.unassigned,
					metaLabel: counts?.Unassigned || 0,
					id: LinesViews.Unassigned,
				},
				{
					label: messages.inProgress,
					metaLabel: counts?.InProgress || 0,
					id: LinesViews.InProgress,
				},
				{
					label: messages.completed,
					metaLabel: counts?.Complete || 0,
					id: LinesViews.Complete,
				},
				{
					label: messages.exceptions,
					metaLabel: counts?.Exceptions || 0,
					id: LinesViews.Exceptions,
				},
			]}
			query={query}
			setFilters={setFilters}
			selectedView={view}
		/>
	);
}
