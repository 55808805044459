import { Chuck } from './Chuck.type';
import { durationFaulty, durationIdleOrPaused } from './ChuckUtils';

function sortByIdleTime(a: Chuck, b: Chuck) {
	return durationIdleOrPaused(b) - durationIdleOrPaused(a);
}

function sortByFaultyTime(a: Chuck, b: Chuck) {
	return durationFaulty(b, 60000) - durationFaulty(a, 60000);
}

function sortByBatteryLevel(a: Chuck, b: Chuck) {
	const batteryLevel = (c: Chuck) => c.telemetry?.batteryLevel || 0;
	return batteryLevel(a) - batteryLevel(b);
}

function sortByHighPriority(a: Chuck, b: Chuck) {
	const priorityReasons = (c: Chuck) => c.telemetry?.highPriorityReasons ?? [];
	return priorityReasons(b).length - priorityReasons(a).length;
}

export function sortByPriorityFaultyTimeBatteryLevel(a: Chuck, b: Chuck) {
	return sortByHighPriority(a, b) || sortByFaultyTime(a, b) || sortByBatteryLevel(a, b);
}

export function sortByFaultyTimePriorityBattery(a: Chuck, b: Chuck) {
	return sortByFaultyTime(a, b) || sortByHighPriority(a, b) || sortByBatteryLevel(a, b);
}

export function sortByFaultyTimeIdleTime(a: Chuck, b: Chuck) {
	return sortByFaultyTime(a, b) || sortByIdleTime(a, b);
}

export function sortByFaultyTimeBatteryLevel(a: Chuck, b: Chuck) {
	return sortByFaultyTime(a, b) || sortByBatteryLevel(a, b);
}
