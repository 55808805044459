import { Stack } from '@shopify/polaris';
import { useLayoutEffect, useRef } from 'react';

import { Chuck } from './Chuck.type';
import { ChuckHighPriorityBadge } from './ChuckHighPriorityBadge';
import { ChuckIndications } from './ChuckIndications';
import { ChuckTitle } from './ChuckTitle';
import {
	isDwellingIdle,
	isDwellingPaused,
	isFaulty,
	isHighPriority,
	isLowBattery,
	isPicking,
	isUpgrading,
} from './ChuckUtils';
import { useCountJobsQuery, useUserQuery } from './FloorView.graphql';
import styles from './FloorView.module.css';
import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';

interface Props {
	chuck: Chuck;
	selected?: boolean;
	onSelect(id?: string): void;
}

/**
 * This component displays Chuck details including motion state (e.g. "Idle") and
 * workflow phase (e.g. "Induct"). When the component is selected (expanded), two
 * things happen: a single request is made for the name of the logged-in user, and
 * a polling request is made for the number of jobs in progress.
 */
export function ChuckCard({ chuck, selected, onSelect }: Props) {
	type PhaseMsg = keyof typeof messages.chuckPhases;
	type FaultMsg = keyof typeof messages.chuckFaults;
	type ResolutionMsg = keyof typeof messages.chuckResolutions;

	const { messages, translate, formatDateDiff } = useLocalization();
	const ref = useRef<HTMLDivElement>(null);
	const telemetry = chuck.telemetry;
	const updatedAt = telemetry?.idleAt || telemetry?.pausedAt || chuck.updatedAt;
	const motionState = telemetry?.motionState || 'idle';
	const motionLabel = messages.motionStates[motionState];
	const phaseLabel = messages.chuckPhases[chuck.phase as PhaseMsg] || chuck.phase;
	const userQuery = useUserQuery(chuck.badge || undefined, selected || false);
	const userName = isPicking(chuck) ? userQuery.result?.name || '...' : null;
	const countJobsQuery = useCountJobsQuery(chuck.id, selected || false);
	const jobCount = countJobsQuery.result?.count;
	const onClick = () => onSelect(selected ? undefined : chuck.id);

	let statusMessage = null;

	if (!chuck.online) {
		statusMessage = translate(messages.diffLastSeen, { dateDiff: formatDateDiff(updatedAt) });
	} else if (telemetry?.idleAt || telemetry?.pausedAt) {
		statusMessage = translate(messages.diffLastMoved, { dateDiff: formatDateDiff(updatedAt) });
	}

	if (isUpgrading(chuck)) {
		statusMessage = translate(messages.upgradingToVersion, {
			version: chuck.telemetry?.stageUpgradeInfo?.nextVersion,
		});
	}

	useLayoutEffect(() => {
		const overlaps = (a?: DOMRect, b?: DOMRect) => {
			return a && b ? Math.max(a.top, b.top) <= Math.min(a.bottom, b.bottom) : false;
		};

		// if selected AND not in view, scroll into view
		if (selected) {
			const elem = ref.current;
			const rect1 = elem?.getBoundingClientRect();
			const rect2 = elem?.parentElement?.getBoundingClientRect();

			if (!overlaps(rect1, rect2)) {
				elem?.scrollIntoView({ behavior: 'smooth', block: 'center' });
			}
		}
	}, [selected]);

	return (
		<div
			ref={ref}
			className={styles.listRow}
			aria-selected={selected}
			data-testid="chuck-card"
			onClick={onClick}
		>
			<Stack vertical spacing="tight">
				<Stack distribution="equalSpacing" alignment="center" spacing="tight">
					<ChuckTitle
						chuckId={chuck.id}
						motionState={motionState}
						isDwellingIdle={isDwellingIdle(chuck)}
						isDwellingPaused={isDwellingPaused(chuck)}
						isFaulty={isFaulty(chuck)}
						isLowBattery={isLowBattery(chuck)}
						isUpgrading={isUpgrading(chuck)}
						isOffline={!chuck.online}
						isPriority={isHighPriority(chuck)}
					/>
					<ChuckIndications
						isPicking={isPicking(chuck)}
						isOffline={!chuck.online}
						telemetry={telemetry}
					/>
				</Stack>
				{selected ? (
					<Stack vertical>
						<Stack distribution="equalSpacing" alignment="trailing">
							<div>
								{isHighPriority(chuck) ? (
									<div className={styles.badge}>
										<ChuckHighPriorityBadge
											highPriorityReasons={chuck.telemetry?.highPriorityReasons}
										/>
									</div>
								) : null}
								<div>
									{chuck.online ? motionLabel + ' / ' + phaseLabel : messages.chuckStates.offline}
								</div>
								<div>{statusMessage}</div>
								<div>{userName}</div>
							</div>
							<div>
								{jobCount ? (
									<a
										href={`${routes.outboundJobs()}?query=${chuck.name}&selectedView=Picking`}
										target="_blank"
										rel="noreferrer"
										onClick={(e) => e.stopPropagation()}
									>
										{translate(messages.countJobs, { count: jobCount })}
									</a>
								) : null}
							</div>
						</Stack>
						{isFaulty(chuck)
							? telemetry?.faults?.map((fault) => {
									const faultLabel =
										messages.chuckFaults[
											(fault.code.startsWith('NAV') ? 'NAVIGATION_ANY' : fault.code) as FaultMsg
										] || fault.code;

									const resolutionLabel =
										messages.chuckResolutions[fault.resolution as ResolutionMsg] ||
										fault.resolution;

									return (
										<div className={styles.fault} key={fault.id}>
											<b>{faultLabel}</b>
											<div>{messages.possibleResolution + ': ' + resolutionLabel}</div>
										</div>
									);
							  })
							: null}
					</Stack>
				) : null}
			</Stack>
		</div>
	);
}
