import { Link, IndexTable } from '@shopify/polaris';

import * as routes from 'routes';

export function AssociatedOrderCell({ id, externalId }: { id: string; externalId: string }) {
	return (
		<IndexTable.Cell>
			<Link url={routes.exception(id)} removeUnderline>
				{externalId}
			</Link>
		</IndexTable.Cell>
	);
}
