import { IndexTable } from '@shopify/polaris';
import { WorkOrderType } from '@sixriver/fulfillment-api-schema';

import { useLocalization } from 'hooks/useLocalization';

interface WorkOrderTypeCellProps {
	types: WorkOrderType[];
}

export function WorkOrderTypeCell({ types: [type] }: WorkOrderTypeCellProps) {
	const { messages } = useLocalization();
	return (
		<IndexTable.Cell>
			{type === 'ecommerce' ? messages.workOrderTypes.ecommerce : messages.workOrderTypes.transfer}
		</IndexTable.Cell>
	);
}
