import gql from 'graphql-tag';

export const CANCEL_JOB_MUTATION = gql`
	mutation CancelJob($projectionId: String!) {
		cancelReplenJob(projectionId: $projectionId) {
			success
		}
	}
`;

export const REPLENISHMENT_QUERY = gql`
	query GetReplenishmentJob($projectionId: String!) {
		replenJobByProjectionId(projectionId: $projectionId) {
			id
			containerId
			status
			createdAt
			updatedAt
			hasExceptions
			completedAt
			takeoffPoint

			mfp {
				name
			}

			assignedBy {
				name
			}

			inductedBy {
				name
			}

			tasks {
				id
				quantity
				doneQuantity

				user {
					name
				}

				exceptions {
					status
				}

				product {
					id
					name
					description
					customerIdentifier
				}

				source {
					address
				}

				destination {
					id
					address
					type
					description
					externalAisleId
				}
			}
		}
	}
`;
