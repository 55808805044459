import { OrderV2, Job, PickStrategy } from '@sixriver/fulfillment-api-schema';

export const useServicedByPickingJobs = (job: OrderV2 | Job | undefined): Job[] => {
	const { exceptions = [], servicedBy = [], pickStrategy } = job || {};
	const sjobs = servicedBy
		.flatMap((servicedByJob: Job) => {
			if (servicedByJob.servicedBy.length > 0) {
				return servicedByJob.servicedBy;
			}

			return servicedByJob;
		})
		.filter((job): job is Job => !!job);

	if (
		exceptions.length > 0 &&
		!!pickStrategy &&
		[PickStrategy.Singles, PickStrategy.Sortation].includes(pickStrategy)
	) {
		sjobs.push(job as Job);
	}

	const uniqueJobIds = new Set<string>();
	const unique = sjobs.reduce((jobs, job) => {
		if (!uniqueJobIds.has(job.id)) {
			uniqueJobIds.add(job.id);
			return jobs.add(job);
		}
		return jobs;
	}, new Set<Job>());

	return Array.from(unique);
};
