import { InventoryEvent, InventoryEventPage } from '@sixriver/fulfillment-api-schema';
import uniqBy from 'lodash/uniqBy';
import { useState, useEffect, useCallback } from 'react';

import { LOCATION_EVENTS_QUERY } from './Locations.graphql';
import { useLocationInventoryEvents } from 'components/Timeline/Timeline.hooks';
import { usePollingQuery } from 'hooks/usePollingQuery';

interface Options {
	locationId: string;
	productId?: string;
	pause?: boolean;
	pollInterval: number;
}

export function useLocationInventoryEventsHistory(options: Options) {
	// State
	const [nextCursor, setNextCursor] = useState<string | null | undefined>(undefined);
	const [events, setEvents] = useState<InventoryEvent[]>([]);

	// Queries
	const [
		{ data: eventsData, fetching: eventsLoading, error: eventsError },
		// executeQuery,
	] = usePollingQuery<{
		inventoryEvents: InventoryEventPage;
	}>({
		query: LOCATION_EVENTS_QUERY,
		pause: options.pause || options.productId === undefined,
		pollInterval: options.pollInterval,
		// Note limit is the MAXIMUM number of results that can be returned
		variables: {
			locationId: options.locationId,
			productId: options.productId,
			limit: 20,
			cursor: nextCursor,
		},
	});

	// Computed
	const results = eventsData?.inventoryEvents.results; // used for effect hook
	const timelineEvents = useLocationInventoryEvents(events);
	const cursor = eventsData?.inventoryEvents.cursor;
	const hasMore = !!cursor;

	// Effects
	// Clear event history on product change
	useEffect(() => {
		setEvents([]);
		setNextCursor(undefined);
	}, [options.productId, options.locationId]);

	// Combine event history pages
	useEffect(() => {
		setEvents((events) => uniqBy(events.concat(results || []), (evt) => evt.timestamp));
	}, [results]);

	// Methods
	const loadMoreEvents = useCallback(() => (cursor ? setNextCursor(cursor) : undefined), [cursor]);

	return {
		rows: timelineEvents,
		cursor,
		hasMore,
		eventsLoading,
		eventsError,
		loadMoreEvents,
	};
}
