import styles from './illustrations.module.css';
import { useLocalization } from 'hooks/useLocalization';

export function PickingContainerIllustration() {
	const { messages } = useLocalization();

	return (
		<svg
			width="100%"
			height="200"
			viewBox="0 0 361 196"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M218.67 20.0576C216.228 19.6425 214 21.524 214 24.001V50.5302C214 52.1784 215.011 53.6579 216.547 54.2568L230 59.5036V73.7636L243 80.2636V64.5736L258.547 70.6368C261.169 71.6597 264 69.7253 264 66.9102V31.141C264 29.1906 262.593 27.5245 260.67 27.1976L218.67 20.0576Z"
				fill="#D9D9D9"
			/>
			<path
				d="M218.67 20.0576L218.503 21.0435L218.67 20.0576ZM216.547 54.2568L216.91 53.3252H216.91L216.547 54.2568ZM230 59.5036H231V58.8203L230.363 58.572L230 59.5036ZM230 73.7636H229V74.3817L229.553 74.6581L230 73.7636ZM243 80.2636L242.553 81.1581L244 81.8817V80.2636H243ZM243 64.5736L243.363 63.642L242 63.1103V64.5736H243ZM258.547 70.6368L258.183 71.5685L258.547 70.6368ZM260.67 27.1976L260.503 28.1835L260.67 27.1976ZM215 24.001C215 22.1433 216.671 20.7321 218.503 21.0435L218.838 19.0717C215.786 18.5528 213 20.9048 213 24.001H215ZM215 50.5302V24.001H213V50.5302H215ZM216.91 53.3252C215.758 52.876 215 51.7664 215 50.5302H213C213 52.5905 214.264 54.4399 216.183 55.1885L216.91 53.3252ZM230.363 58.572L216.91 53.3252L216.183 55.1885L229.637 60.4353L230.363 58.572ZM231 73.7636V59.5036H229V73.7636H231ZM243.447 79.3692L230.447 72.8692L229.553 74.6581L242.553 81.1581L243.447 79.3692ZM242 64.5736V80.2636H244V64.5736H242ZM258.91 69.7052L243.363 63.642L242.637 65.5053L258.183 71.5685L258.91 69.7052ZM263 66.9102C263 69.0215 260.877 70.4723 258.91 69.7052L258.183 71.5685C261.462 72.847 265 70.4291 265 66.9102H263ZM263 31.141V66.9102H265V31.141H263ZM260.503 28.1835C261.945 28.4286 263 29.6782 263 31.141H265C265 28.703 263.242 26.6204 260.838 26.2117L260.503 28.1835ZM218.503 21.0435L260.503 28.1835L260.838 26.2117L218.838 19.0717L218.503 21.0435Z"
				fill="#D9D9D9"
			/>
			<path
				d="M21.9916 79.0859L188.301 51.4486C190.06 51.1561 191.867 51.3932 193.492 52.1298L300.953 100.845C301.808 101.233 301.705 102.48 300.798 102.722L56.5 168L20.4743 81.8303C19.9767 80.6403 20.7192 79.2974 21.9916 79.0859Z"
				fill="white"
				stroke="#D9D9D9"
				strokeWidth="2"
			/>
			<path
				d="M300 103L61.2021 166.745C58.7927 167.388 56.2777 166.157 55.3069 163.86L20.5 81.5V86.2329C20.5 87.3998 20.7269 88.5556 21.1682 89.6359L55.3385 173.299C56.2888 175.625 58.8183 176.885 61.2476 176.241L295.536 114.182C298.168 113.485 300 111.104 300 108.382V103Z"
				fill="#D9D9D9"
				stroke="#D9D9D9"
				strokeWidth="2"
			/>
			<path d="M60.5 167V176.5" stroke="#D9D9D9" strokeWidth="2" />
			<path d="M55.5 164.5V173.5" stroke="#D9D9D9" strokeWidth="2" strokeLinecap="round" />
			<line x1="3.46838" y1="91.825" x2="37.4684" y2="182.825" stroke="#999999" />
			<line
				x1="35.2794"
				y1="183.238"
				x2="39.0872"
				y2="181.721"
				stroke="#999999"
				strokeLinecap="round"
			/>
			<line
				x1="1.27938"
				y1="92.238"
				x2="5.08719"
				y2="90.7206"
				stroke="#999999"
				strokeLinecap="round"
			/>
			<rect
				x="8.77972"
				y="124.475"
				width="21.4579"
				height="23.9848"
				transform="rotate(-22.5513 8.77972 124.475)"
				fill="white"
			/>

			<line x1="65.8683" y1="193.518" x2="303.868" y2="128.518" stroke="#999999" />
			<line
				x1="303.441"
				y1="126.391"
				x2="304.523"
				y2="130.345"
				stroke="#999999"
				strokeLinecap="round"
			/>
			<line
				x1="65.6143"
				y1="191.35"
				x2="66.6966"
				y2="195.304"
				stroke="#999999"
				strokeLinecap="round"
			/>
			<rect
				x="179.766"
				y="155.022"
				width="25.8243"
				height="18.0137"
				transform="rotate(-16.3351 179.766 155.022)"
				fill="white"
			/>

			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M77.0442 161.595C76.9773 161.944 76.9845 162.314 77.0784 162.681C77.4201 164.019 78.7816 164.827 80.1194 164.485L99.2439 159.599C100.582 159.258 101.389 157.896 101.047 156.558C100.928 156.091 100.684 155.689 100.36 155.377L77.0442 161.595ZM110.977 152.546C110.934 152.858 110.949 153.183 111.032 153.507C111.374 154.845 112.735 155.652 114.073 155.311L133.197 150.425C134.535 150.084 135.342 148.722 135.001 147.384C134.893 146.963 134.685 146.595 134.407 146.298L110.977 152.546ZM144.916 143.496C144.892 143.769 144.913 144.052 144.985 144.333C145.327 145.671 146.688 146.478 148.026 146.137L167.151 141.251C168.488 140.909 169.296 139.548 168.954 138.21C168.858 137.834 168.681 137.499 168.447 137.221L144.916 143.496ZM178.862 134.443C178.853 134.679 178.877 134.919 178.939 135.159C179.28 136.497 180.642 137.304 181.98 136.962L201.104 132.077C202.442 131.735 203.249 130.374 202.908 129.036C202.823 128.703 202.674 128.403 202.479 128.145L178.862 134.443ZM212.814 125.39C212.816 125.586 212.841 125.786 212.892 125.985C213.234 127.323 214.595 128.13 215.933 127.788L235.058 122.903C236.395 122.561 237.203 121.2 236.861 119.862C236.787 119.571 236.665 119.306 236.505 119.072L212.814 125.39ZM246.771 116.334C246.78 116.492 246.805 116.652 246.846 116.811C247.187 118.148 248.549 118.956 249.886 118.614L269.011 113.729C270.349 113.387 271.156 112.025 270.814 110.688C270.751 110.439 270.652 110.208 270.524 110L246.771 116.334Z"
				fill="#999999"
			/>
			<path d="M106 70L34.5 85L71 149L179 121L106 70Z" fill="#BCE9D0" />
			<path
				d="M26 40.5L105.5 28V70.5L34.5 84.5L26 40.5Z"
				fill="#BCE9D0"
				stroke="#6EC194"
				strokeLinejoin="round"
			/>
			<path
				d="M186 81L105.5 28V70L179.5 121L186 81Z"
				fill="#BCE9D0"
				stroke="#6EC194"
				strokeLinejoin="round"
			/>
			<path
				d="M68.5 105.5L26 40.5L34.5 85L70.5 148.5L68.5 105.5Z"
				fill="#108043"
				fillOpacity="0.21"
				stroke="#6EC194"
				strokeLinejoin="round"
			/>
			<path
				d="M68.5 105.5L186 82L179.213 121.213L70.5 149L68.5 105.5Z"
				fill="#108043"
				fillOpacity="0.21"
				stroke="#6EC194"
				strokeLinejoin="round"
			/>

			<path
				d="M140.536 43.8143C140.433 44.0707 140.558 44.3617 140.814 44.4642L144.992 46.1355C145.249 46.2381 145.54 46.1133 145.642 45.857C145.745 45.6006 145.62 45.3096 145.364 45.207L141.65 43.7215L143.135 40.0076C143.238 39.7512 143.113 39.4602 142.857 39.3576C142.601 39.2551 142.31 39.3798 142.207 39.6362L140.536 43.8143ZM150.284 31C150.284 34.1748 149.606 36.5579 148.119 38.5095C146.624 40.4731 144.268 42.0554 140.803 43.5404L141.197 44.4596C144.732 42.9446 147.268 41.2769 148.915 39.1155C150.57 36.9421 151.284 34.3252 151.284 31H150.284Z"
				fill="#108043"
			/>
			<path
				d="M44.8953 32.9889C45.1653 33.0468 45.4311 32.8748 45.4889 32.6048L46.4318 28.2047C46.4897 27.9346 46.3177 27.6688 46.0477 27.611C45.7777 27.5531 45.5119 27.7251 45.454 27.9951L44.6159 31.9063L40.7047 31.0682C40.4347 31.0104 40.1689 31.1823 40.111 31.4524C40.0532 31.7224 40.2252 31.9882 40.4952 32.046L44.8953 32.9889ZM41.5 18C41.0257 17.8419 41.0256 17.8422 41.0254 17.8425C41.0254 17.8427 41.0253 17.843 41.0252 17.8433C41.025 17.8439 41.0248 17.8446 41.0245 17.8453C41.024 17.8468 41.0234 17.8487 41.0227 17.8509C41.0213 17.8554 41.0194 17.8614 41.017 17.8688C41.0124 17.8836 41.0062 17.9042 40.9986 17.9306C40.9833 17.9833 40.9627 18.0588 40.939 18.1562C40.8916 18.3512 40.8319 18.6341 40.7787 18.998C40.6724 19.726 40.5922 20.7782 40.6889 22.099C40.8824 24.7439 41.7839 28.45 44.5803 32.7716L45.4198 32.2284C42.7162 28.05 41.8677 24.5061 41.6862 22.026C41.5953 20.7843 41.6714 19.8052 41.7682 19.1426C41.8166 18.8112 41.8702 18.559 41.9106 18.3926C41.9309 18.3094 41.9478 18.2477 41.9592 18.2083C41.9649 18.1886 41.9692 18.1745 41.9718 18.1661C41.9731 18.1619 41.974 18.1592 41.9745 18.1578C41.9747 18.1571 41.9748 18.1568 41.9748 18.1569C41.9747 18.1569 41.9747 18.157 41.9746 18.1572C41.9746 18.1573 41.9745 18.1575 41.9745 18.1576C41.9744 18.1578 41.9743 18.1581 41.5 18Z"
				fill="#108043"
			/>
			<path
				d="M185.646 113.128C185.519 112.883 185.615 112.581 185.86 112.454L189.855 110.384C190.1 110.257 190.402 110.352 190.529 110.598C190.656 110.843 190.56 111.145 190.315 111.272L186.764 113.112L188.604 116.663C188.731 116.909 188.635 117.21 188.39 117.337C188.145 117.464 187.843 117.369 187.716 117.123L185.646 113.128ZM211.648 104.227C207.18 113.005 195.314 116.35 185.938 113.374L186.241 112.421C195.281 115.29 206.567 112.004 210.757 103.773L211.648 104.227Z"
				fill="#108043"
			/>
			<path
				d="M26.5 40.5L105.5 28L186.5 81.5L179.5 121"
				stroke="#108043"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>

			<text className={styles.svgTextBigGreen} x="25" y="15">
				{messages.length}
			</text>

			<text className={styles.svgTextBigGreen} x="132" y="28">
				{messages.width}
			</text>
			<text className={styles.svgTextBigGreen} x="192" y="100">
				{messages.height}
			</text>

			<text className={styles.svgTextBigGrey} x="192" y="162">
				{'X'}
			</text>
			<text className={styles.svgTextBigGrey} x="15" y="136">
				{'Y'}
			</text>

			<text className={styles.svgTextSmall} x="275" y="80">
				{messages.chuckWorkspace}
			</text>
		</svg>
	);
}
