import gql from 'graphql-tag';

import { PAGE_INFO_FRAGMENT } from 'graphql/page-info';

export const EXCEPTIONS_QUERY = gql`
	query Orders(
		$after: String
		$first: Int
		$statuses: [OrderState!]!
		$searchText: String
		$jobUpdatedAtFrom: DateTime
		$isShorted: Boolean
		$hasHealPlan: Boolean
		$projectionIds: [String!]
		$healPlanStatuses: [HealPlanStatus!]
		$workAreaIds: [String!]
	) {
		orders(
			after: $after
			first: $first
			statuses: $statuses
			searchText: $searchText
			jobUpdatedAtFrom: $jobUpdatedAtFrom
			isShorted: $isShorted
			hasHealPlan: $hasHealPlan
			projectionIds: $projectionIds
			healPlanStatuses: $healPlanStatuses
			workAreaIds: $workAreaIds
			isShippedShort: false
		) {
			edges {
				node {
					id
					type
					status
					customerIdentifier
					customers {
						name
					}
					expectedShipDate
					timeline {
						createdAt
					}
					healPlan {
						id
						status
					}
					lines {
						id
						quantity
						doneQuantity
						status
					}
					exception {
						status
					}
					servicedBy {
						... on Picking {
							id
							container {
								barcode
							}
							timeline {
								createdAt
								updatedAt
							}
						}
						... on HealPlan {
							id
							status
						}
					}
					isHealed
					isHealable
					workAreas {
						id
						name
					}
				}
			}
			pageInfo {
				...PageInfo
			}
		}
	}
	${PAGE_INFO_FRAGMENT}
`;

export const COUNTS_QUERY = gql`
	query CountOrders($searchText: String, $projectionIds: [String!], $jobUpdatedAtFrom: DateTime) {
		UnresolvableCount: ordersCount(
			searchText: $searchText
			statuses: [Exception]
			projectionIds: $projectionIds
			healPlanStatuses: [ACCEPTED]
			isShippedShort: false
		) {
			count
		}
		ResolvableCount: ordersCount(
			searchText: $searchText
			statuses: [Exception]
			projectionIds: $projectionIds
			healPlanStatuses: [READY]
			isShippedShort: false
		) {
			count
		}
		ResolvingCount: ordersCount(
			searchText: $searchText
			statuses: [InProgress]
			projectionIds: $projectionIds
			healPlanStatuses: [RUNNING]
			isShippedShort: false
		) {
			count
		}
		ResolvedCount: ordersCount(
			searchText: $searchText
			statuses: [Done, Packable, Packing, Packed, Sorting, Sorted, Cancelled]
			jobUpdatedAtFrom: $jobUpdatedAtFrom
			projectionIds: $projectionIds
			hasHealPlan: true
			isShippedShort: false
		) {
			count
		}
	}
`;
