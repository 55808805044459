import { Card, Badge } from '@shopify/polaris';
import { Job, JobStatus, OrderV2, PickStrategy } from '@sixriver/fulfillment-api-schema';
import { useEffect, useState } from 'react';

import { OrderTotes } from './OrderTotes';
import { CardDetails } from 'components/CardDetails';
import { OrderQuantity } from 'components/OrderQuantity';
import { ResolutionJobsBanner } from 'components/ResolutionJobsBanner';
import { countLineExceptionsV2 } from 'helpers/exception';
import { calculateUnits } from 'helpers/order';
import { useConfig } from 'hooks/useConfig';
import { useLocalization } from 'hooks/useLocalization';
import { usePickStrategyName } from 'hooks/usePickStrategies';
import { useServicedByPickingJobs } from 'hooks/useServicedByPickingJobs';
import * as routes from 'routes';

const getParams = (jobs?: (OrderV2 | Job)[]) => {
	const searchParams = new URLSearchParams();

	if (jobs) {
		searchParams.set('ids', jobs.map((job) => job.id).join(','));
	}

	return `?${searchParams.toString()}`;
};

interface Props {
	order: OrderV2;
}

export function Progress({ order }: Props) {
	const { messages, translate } = useLocalization();
	const { config } = useConfig();
	const getPickStrategyName = usePickStrategyName();
	const getServicedByPickingJobs = useServicedByPickingJobs;
	const numberOfExceptions = countLineExceptionsV2(order.lines);
	const units = calculateUnits(order.lines);

	const [servicedByPickingJobs, setServicedByPickingJobs] = useState<(OrderV2 | Job)[]>(
		getServicedByPickingJobs(order),
	);
	const resolutionJobs = servicedByPickingJobs.filter((job) => job.isHealJob) as Job[];
	const disableTrackJobs =
		(order && order.servicingSorations.length > 0) || servicedByPickingJobs.length === 0;

	const primaryAction = {
		content: messages.trackJobs,
		url: `${routes.outboundJobs()}${getParams(servicedByPickingJobs)}`,
		disabled: disableTrackJobs,
	};

	const secondaryActions = config?.healingEnabled
		? [
				{
					content: messages.manageExceptions,
					url: routes.exception(order.id),
					disabled: numberOfExceptions === 0,
				},
		  ]
		: undefined;

	useEffect(() => {
		if (
			order.pickStrategy === PickStrategy.Discrete ||
			(order.pickStrategy === PickStrategy.Sortation &&
				[JobStatus.Packable, JobStatus.Packing, JobStatus.Packed].includes(order.jobStatus))
		) {
			setServicedByPickingJobs([...getServicedByPickingJobs(order), order]);
		}
	}, [getServicedByPickingJobs, order]);

	return (
		<Card
			title={messages.progress}
			footerActionAlignment="left"
			primaryFooterAction={primaryAction}
			secondaryFooterActions={secondaryActions}
			sectioned
		>
			{resolutionJobs.length > 0 && order.isShorted && (
				<ResolutionJobsBanner jobs={resolutionJobs} />
			)}
			<CardDetails
				primary={[
					{
						label: messages.pickStrategy,
						content: order.pickStrategy ? getPickStrategyName(order.pickStrategy) : null,
					},
					// TODO: Bulk order work
					// ...(order?.pickStrategy === PickStrategy.Bulk && order.bulkOrderProjectionId
					// 	? [
					// 			{
					// 				label: messages.batchId,
					// 				content: (
					// 					<Link
					// 						url={routes.bulkOrder(order?.bulkOrderProjectionId as string | undefined)}
					// 						removeUnderline
					// 					>
					// 						{order?.jobFlowRuleLpn}
					// 					</Link>
					// 				),
					// 			},
					// 	  ]
					// 	: []),
					{
						label: messages.quantity,
						content: <OrderQuantity lines={order.lines} />,
					},
					{
						label: messages.totes,
						content: <OrderTotes order={order} />,
					},
					{
						label: messages.exceptions,
						content: (
							<Badge status={numberOfExceptions > 0 ? 'critical' : undefined}>
								{numberOfExceptions.toString()}
							</Badge>
						),
					},
				]}
				secondary={[
					{
						label: messages.unassigned,
						content: translate(messages.countUnits, {
							count: units.unassigned,
						}),
					},
					{
						label: messages.inProgress,
						content: translate(messages.countUnits, {
							count: units.inProgress,
						}),
					},
					{
						label: messages.completed,
						content: translate(messages.countUnits, {
							count: units.picked,
						}),
					},
				]}
			/>
		</Card>
	);
}
