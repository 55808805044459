import { PageInfo } from '@sixriver/fulfillment-api-schema';

import { Cursors } from 'components/DataTable/Pagination';

export function paginate<T>({
	itemsList,
	paginationCursors,
	pageSize,
}: {
	pageSize: number;
	itemsList: Array<T>;
	paginationCursors: Cursors;
}) {
	const pageStartIndex = paginationCursors.length * pageSize;

	const itemsInCurrentPage = itemsList.slice(pageStartIndex, pageStartIndex + pageSize);

	const lastPageNumber = Math.floor((itemsList.length - 1) / pageSize);

	const pageInfo: PageInfo = {
		hasNextPage: Number(paginationCursors[0] || '0') < lastPageNumber,
		hasPreviousPage: paginationCursors.length > 0,
		startCursor: paginationCursors[0] || '0',
		endCursor: (Number(paginationCursors[0] || '0') + 1).toString(),
	};

	return {
		pageInfo,
		itemsInCurrentPage,
	};
}
