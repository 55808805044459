import { IndexTable } from '@shopify/polaris';

import { DateTime } from 'components/DateTime';

export function JobCreatedAtCell({ createdAt }: { createdAt: Date }) {
	return (
		<IndexTable.Cell>
			<DateTime date={createdAt} />
		</IndexTable.Cell>
	);
}
