import { Card, DescriptionList } from '@shopify/polaris';
import { StorageLocation, StorageLocationType } from '@sixriver/fulfillment-api-schema';

import styles from './LocationInfo.module.css';
import { NoData } from 'components/NoData';
import { useAuth } from 'hooks/useAuth';
import { useLocalization } from 'hooks/useLocalization';
import { UserRole } from 'providers/AuthProvider';
import * as routes from 'routes';

interface Props {
	location?: StorageLocation;
	wideList?: Boolean;
	isWorkAreaEnabled?: boolean;
}

export function LocationInfo({ location, wideList, isWorkAreaEnabled }: Props) {
	const { messages, formatVolume, formatLength, formatNumber } = useLocalization();

	const { isUserAllowed } = useAuth();

	if (!location) {
		return null;
	}

	const { length, width, height, volume } = location.containerType || {};
	const { x, y, z } = location.coordinates || {};

	return (
		<Card
			title={messages.locationInformation}
			actions={[
				{
					content: messages.edit,
					url: routes.editLocation(location.id),
					// disable editing unknown storage location b/c the only way a storage location can enter an unknown state is if someone manually modifies location type in the database
					// disabling gives us more safety because if this location was modified outside our APIs we want to prevent further modification
					disabled:
						!isUserAllowed([UserRole.Admin, UserRole.WarehouseManager]) ||
						location?.type === (StorageLocationType.Unknown as StorageLocationType),
				},
			]}
		>
			<Card.Section>
				<div className={wideList ? styles.wideDescriptionListTerm : styles.narrowDescriptionList}>
					<DescriptionList
						spacing="tight"
						items={[
							...(isWorkAreaEnabled
								? [
										{
											term: messages.workArea,
											description: location.workArea?.name || <NoData />,
										},
								  ]
								: []),
							{
								term: messages.address,
								description: location.address,
							},
							{
								term: messages.aisleId,
								description: location.externalAisleId?.trim() || <NoData />,
							},
							{
								term: messages.description,
								description: location.description || <NoData />,
							},
							{
								term: messages.locationType,
								description: location.type ? messages.locationTypes[location.type] : <NoData />,
							},
							{
								term: messages.capacityType,
								description: location.containerType?.name || <NoData />,
							},
							{
								term: messages.length,
								description: length ? formatLength(length) : <NoData />,
							},
							{
								term: messages.width,
								description: width ? formatLength(width) : <NoData />,
							},
							{
								term: messages.height,
								description: height ? formatLength(height) : <NoData />,
							},
							{
								term: messages.volume,
								description: volume ? formatVolume(volume) : <NoData />,
							},
							{
								term: messages.coordinates,
								description: `${formatNumber(x)} mm, ${formatNumber(y)} mm, ${z}`,
							},
						]}
					/>
				</div>
			</Card.Section>
		</Card>
	);
}
