import gql from 'graphql-tag';

export const PRODUCT_QUERY = gql`
	query GetProduct($id: String!) {
		product(id: $id) {
			id
			customerIdentifier
			scanValues
			name
			kind
			image
			description
			length
			width
			height
			weight
			locations {
				location {
					id
					address
					type
				}
				inventory {
					liveQuantity
					totalPlannedAddQuantity
					totalPlannedRemoveQuantity
					balance
				}
			}
		}
	}
`;
