import { Spinner } from '@shopify/polaris';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { AccessControl, AccessControlProps } from './AccessControl';
import { useAuth } from 'hooks/useAuth';
import * as routes from 'routes';

export function ProtectedRoute({
	children,
	allowedRoles = [],
	...rest
}: RouteProps & AccessControlProps) {
	const { isAuthenticated, isLoading } = useAuth();
	return (
		<Route
			{...rest}
			render={(props) => {
				if (isAuthenticated) {
					if (allowedRoles.length > 0) {
						return (
							<AccessControl allowedRoles={allowedRoles} {...rest}>
								{children}
							</AccessControl>
						);
					}

					return children;
				}

				if (isLoading) {
					return <Spinner />;
				}

				return <Redirect to={{ pathname: routes.login(), state: { from: props.location } }} />;
			}}
		/>
	);
}
