import { StorageLocationType, StorageLocationTypeCount } from '@sixriver/fulfillment-api-schema';

import { Tab } from 'components/ViewTabs';
import { useConfig } from 'hooks/useConfig';
import { useLocalization } from 'hooks/useLocalization';

export const LOCATION_TYPES = [
	StorageLocationType.Bin,
	StorageLocationType.Pallet,
	StorageLocationType.Cart,
	StorageLocationType.Damaged,
	StorageLocationType.Reserve,
	StorageLocationType.Staging,
	StorageLocationType.Warehouse,
];

export function useLocationTabs(counts: StorageLocationTypeCount[]): Tab[] {
	const { messages } = useLocalization();
	const { config } = useConfig();

	const types = Array.from(LOCATION_TYPES);

	if (!config?.inventoryEnabled) {
		types.splice(6, 1); // no warehouse
		types.splice(4, 1); // no reserve
	}

	const tabs = [
		{
			label: messages.allLocations,
			id: 'all',
		},
	].concat(
		types.map((type) => {
			return {
				label: messages.locationTypes[type],
				metaLabel: counts.find((c) => c.type === type)?.count || 0,
				id: type,
			};
		}),
	);

	return tabs;
}
