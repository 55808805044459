import { Banner, Card } from '@shopify/polaris';

import { noop } from 'helpers/noop';
import { useLocalization } from 'hooks/useLocalization';

interface PrintLabelsBannerProps {
	isVisible?: boolean;
	onDismiss?: () => void;
}

export function PrintLabelsBanner({
	isVisible = false,
	onDismiss = noop,
}: PrintLabelsBannerProps): JSX.Element | null {
	const { messages } = useLocalization();

	if (!isVisible) {
		return null;
	}

	return (
		<Card.Section>
			<Banner status="info" onDismiss={onDismiss}>
				<p>{messages.labelsPrinted}</p>
			</Banner>
		</Card.Section>
	);
}
