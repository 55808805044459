import { Page, Layout, Card } from '@shopify/polaris';
import { useDebouncedValue } from '@shopify/react-hooks';
import {
	LaneEdge,
	LaneStatus,
	OrderByDirection,
	QueryLanesArgs,
	UniqueCarriersAndCarrierSorts,
} from '@sixriver/fulfillment-api-schema';
import { useCallback, useState } from 'react';

import { LanesData, LANES_QUERY, UNIQUE_CARRIERS_AND_CARRIER_SORTS_QUERY } from './Lanes.graphql';
import { LaneOrderBy, LanesFilters, LaneOrderByFields } from './LanesFilters/LanesFilters';
import { LanesTable } from './LanesTable/LanesTable';
import { LanesTab, LanesTabs } from './LanesTabs';
import { AutoRefresh } from 'components/AutoRefresh';
import { TimezoneFooter } from 'components/TimezoneFooter';
import { getPageSize } from 'helpers/page-size';
import { MIN_QUERY_LENGTH } from 'helpers/table';
import { useLocalization } from 'hooks/useLocalization';
import { usePolling } from 'hooks/usePolling';
import { usePollingQuery } from 'hooks/usePollingQuery';
import { useArrayQueryState, useQueryState } from 'hooks/useQueryState';

const SEARCH_TEXT_KEY = 'query';
const IDS_KEY = 'ids';
const STATUSES_KEY = 'statuses';
const CARRIERS = 'carriers';
const CARRIER_SORTS_KEY = 'carrierSorts';

export function Lanes() {
	const { messages } = useLocalization();
	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();
	const [searchText, setSearchText] = useQueryState(SEARCH_TEXT_KEY, '');
	const [selectedTab, setSelectedTab] = useState<LanesTab>(LanesTab.All);
	const [selectedStatuses, setSelectedStatuses] = useArrayQueryState<LaneStatus[]>(
		STATUSES_KEY,
		[],
	);
	const [selectedCarriers, setSelectedCarriers] = useArrayQueryState<string[]>(CARRIERS, []);
	const [selectedCarrierSorts, setSelectedCarrierSorts] = useArrayQueryState<string[]>(
		CARRIER_SORTS_KEY,
		[],
	);
	const [selectedSort, setSelectedSort] = useState<LaneOrderBy[]>([
		{ field: LaneOrderByFields.Status, direction: OrderByDirection.Asc },
	]);
	const [ids, setIds] = useArrayQueryState<string[]>(IDS_KEY, []);
	const debouncedSearchText = useDebouncedValue(searchText);

	/**
	 * Sort functions
	 * This is not supported on the backend yet, hence why it's on the frontend as a temporary solution
	 */
	const sortLanes = (laneEdges: LaneEdge[], orderBy: LaneOrderBy[]): LaneEdge[] => {
		switch (orderBy[0].field) {
			case LaneOrderByFields.Status:
				if (orderBy[0].direction === OrderByDirection.Asc) {
					return laneEdges.sort(
						(a: LaneEdge, b: LaneEdge) => (a.node.status > b.node.status && 1) || -1,
					);
				}
				return laneEdges.sort(
					(a: LaneEdge, b: LaneEdge) => (b.node.status > a.node.status && 1) || -1,
				);
			default:
				return laneEdges.sort(
					(a: LaneEdge, b: LaneEdge) => (a.node.status > b.node.status && 1) || -1,
				);
		}
	};

	/**
	 * Queries
	 */

	const [{ fetching: fetchingLanes, data: lanesData }] = usePollingQuery<LanesData, QueryLanesArgs>(
		{
			query: LANES_QUERY,
			pollInterval: queryPollInterval,
			variables: {
				first: getPageSize(),
				lanesSearchText:
					debouncedSearchText.length >= MIN_QUERY_LENGTH ? debouncedSearchText : undefined,
				laneStatuses: selectedStatuses,
				carriers: selectedCarriers,
				carrierSorts: selectedCarrierSorts,
			},
		},
	);

	const [
		{ fetching: fetchingUniqueCarriersAndCarrierSorts, data: uniqueCarriersAndCarrierSortsData },
	] = usePollingQuery<{ uniqueCarriersAndCarrierSorts: UniqueCarriersAndCarrierSorts }>({
		query: UNIQUE_CARRIERS_AND_CARRIER_SORTS_QUERY,
		pollInterval: queryPollInterval,
	});

	const laneEdges = lanesData?.lanes.edges ?? [];
	const lanes = selectedSort ? sortLanes(laneEdges, selectedSort) : lanesData?.lanes.edges;
	const { carriers: availableCarriers, carrierSorts: availableCarrierSorts } =
		uniqueCarriersAndCarrierSortsData?.uniqueCarriersAndCarrierSorts || {};

	const loading = fetchingLanes || fetchingUniqueCarriersAndCarrierSorts;

	/**
	 * Filter functions
	 */
	const handleStatusesRemove = useCallback(() => {
		setSelectedStatuses([]);
		setSelectedTab(LanesTab.All);
	}, [setSelectedStatuses, setSelectedTab]);
	const handleCarriersRemove = useCallback(() => {
		setSelectedCarriers([]);
	}, [setSelectedCarriers]);
	const handleCarrierSortsRemove = useCallback(() => {
		setSelectedCarrierSorts([]);
	}, [setSelectedCarrierSorts]);
	const handleIdsRemove = useCallback(() => {
		setIds([]);
	}, [setIds]);

	const handleClearAllFilters = useCallback(() => {
		setSelectedStatuses([]);
	}, [setSelectedStatuses]);

	return (
		<>
			<Page fullWidth title={messages.shipping}>
				<Layout>
					<Layout.Section>
						<AutoRefresh
							pollingEnabled={pollingEnabled}
							togglePolling={togglePolling}
							discriminatorData={{}}
						/>
					</Layout.Section>

					<Layout.Section>
						<Card>
							<div style={{ paddingBottom: '2rem' }}>
								<LanesTabs selected={selectedTab} onSelect={setSelectedTab} />
							</div>

							<div style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
								<LanesFilters
									queryValue={searchText}
									onClearAll={handleClearAllFilters}
									onQueryChange={setSearchText}
									selectedSort={selectedSort}
									onSortChange={setSelectedSort}
									selectedStatuses={selectedStatuses}
									onStatusChange={setSelectedStatuses}
									onStatusRemove={handleStatusesRemove}
									availableCarriers={availableCarriers ?? []}
									selectedCarriers={selectedCarriers}
									onCarrierChange={setSelectedCarriers}
									onCarrierRemove={handleCarriersRemove}
									availableCarrierSorts={availableCarrierSorts ?? []}
									selectedCarrierSorts={selectedCarrierSorts}
									onCarrierSortChange={setSelectedCarrierSorts}
									onCarrierSortRemove={handleCarrierSortsRemove}
									ids={ids}
									onIdsRemove={handleIdsRemove}
								/>
							</div>

							<LanesTable loading={loading} data={lanes} />
						</Card>
					</Layout.Section>

					<Layout.Section>
						<TimezoneFooter />
					</Layout.Section>
				</Layout>
			</Page>
		</>
	);
}
