import { Badge } from '@shopify/polaris';
import { InboundLineStatus, ShippingNoticeStatus } from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	inboundLineStatus?: InboundLineStatus | null;
	asnStatus?: ShippingNoticeStatus;
	receivedQuantity: number;
}

export function InboundLineStatusBadge({ inboundLineStatus, asnStatus, receivedQuantity }: Props) {
	const { messages } = useLocalization();

	const badges: Record<InboundLineStatus, JSX.Element> = {
		[InboundLineStatus.InProgress]:
			receivedQuantity >= 1 && asnStatus === ShippingNoticeStatus.Completed ? (
				<Badge status="success">{messages.received}</Badge>
			) : receivedQuantity < 1 && asnStatus === ShippingNoticeStatus.Completed ? (
				<Badge status="critical">{messages.notReceived}</Badge>
			) : (
				<Badge status="info">{messages.inProgress}</Badge>
			),
		[InboundLineStatus.Pending]:
			asnStatus === ShippingNoticeStatus.Completed ||
			asnStatus === ShippingNoticeStatus.Cancelled ? (
				<Badge status="critical">{messages.notReceived}</Badge>
			) : (
				<Badge status="warning">{messages.pending}</Badge>
			),
		[InboundLineStatus.Received]: <Badge status="success">{messages.received}</Badge>,
	};

	return inboundLineStatus && badges[inboundLineStatus] ? badges[inboundLineStatus] : <NoData />;
}
