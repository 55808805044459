import { LaneConnection } from '@sixriver/fulfillment-api-schema';
import gql from 'graphql-tag';

import { PAGE_INFO_FRAGMENT } from 'graphql/page-info';

export interface LanesData {
	lanes: LaneConnection;
}

export const LANE_CONTAINER_QUERY = gql`
	query Lanes($laneIds: [String!], $containerIds: [String!]) {
		lanes(laneIds: $laneIds, containerIds: $containerIds) {
			edges {
				node {
					carrier
					carrierSort
					carrierClassMapping {
						mailClasses
					}
					containers {
						id
						laneId
						containerStatus
						carrierPickupDetails {
							vehicleScan
							vehicleLaneId
							loadedAt
							isOverridden
						}
						packages {
							packageInternalId
							packageType
							timeScanned
						}
						createdAt
					}
				}
			}
			pageInfo {
				...PageInfo
			}
		}
	}
	${PAGE_INFO_FRAGMENT}
`;
