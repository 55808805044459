import { Page } from '@shopify/polaris';
import {
	MutationCreateUserArgs,
	MutationResponse,
	UserAuthMethod,
} from '@sixriver/fulfillment-api-schema';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'urql';

import { EmployeeForm, EmployeeFormData } from './Employee.form';
import { getRolesFromFormData } from './Employee.utils';
import { CREATE_NEW_EMPLOYEE_MUTATION } from './Employees.graphql';
import { useLocalization } from 'hooks/useLocalization';
import { useToast } from 'hooks/useToast';
import * as routes from 'routes';

const formDefaults: EmployeeFormData = {
	badgeBarcode: '',
	phoneNumber: '',
	email: '',
	name: '',
	tags: [],
	isBridgeAccessAllowed: false,
	isDeviceAccessAllowed: false,
	locale: 'en-US',
};

export function AddEmployee(): JSX.Element {
	const { messages } = useLocalization();
	const { showToast } = useToast();

	// Routing
	const history = useHistory();

	// Mutations
	const [{ error: addEmployeeError }, addEmployeeMutation] = useMutation<
		{ createUser: MutationResponse },
		MutationCreateUserArgs
	>(CREATE_NEW_EMPLOYEE_MUTATION);

	// Methods
	const onSubmit = useCallback(
		async (formData: EmployeeFormData) => {
			const { data } = await addEmployeeMutation({
				input: {
					name: formData.name || null,
					email: formData.email || null,
					locale: formData.locale || null,
					badgeBarcode: formData.badgeBarcode || null,
					roles: getRolesFromFormData(formData).map((name) => ({ name })),
					tags: formData.tags.map((tag) => ({ name: tag })),
					authMethod: UserAuthMethod.Ldap,
				},
			});

			// Success
			if (data?.createUser?.success && data.createUser?.referenceId) {
				history.push(routes.employee(data.createUser.referenceId));
				return;
			}

			// Error
			showToast(messages.errorToast, true);
		},
		[addEmployeeMutation, history, messages, showToast],
	);

	// Render
	return (
		<Page
			title={messages.addEmployee}
			breadcrumbs={[
				{
					content: messages.employees,
					url: routes.employees(),
				},
			]}
			fullWidth
		>
			<EmployeeForm mode="add" data={formDefaults} onSubmit={onSubmit} error={addEmployeeError} />
		</Page>
	);
}
