import { TextStyle, TextContainer } from '@shopify/polaris';
import { Line, LineV2 } from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';
import { calculateUnits } from 'helpers/order';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	lines?: Line[] | LineV2[];
}

export function OrderQuantity({ lines }: Props) {
	const { messages, translate } = useLocalization();

	if (Array.isArray(lines)) {
		const units = calculateUnits(lines).total;

		return (
			<TextContainer>
				<p>{translate(messages.countUnits, { count: units })}</p>
				<TextStyle variation="subdued">
					{translate(messages.countLines, { count: lines.length })}
				</TextStyle>
			</TextContainer>
		);
	}

	return <NoData />;
}
