import { Page, Stack } from '@shopify/polaris';
import { Map as MapElement, ZoomControl, SelectionState } from '@sixriver/map-element';
import { MapStack } from '@sixriver/map-io';
import { NavMenu, NavControls } from '@sixriver/map-nav';
import { useEffect, useState, useCallback, useRef } from 'react';

import { ChuckArgs } from './ChuckArgs';
import { ChuckCount } from './ChuckCount';
import { ChuckFilters } from './ChuckFilters';
import { asChuckIcon } from './ChuckIcon';
import { ChuckList } from './ChuckList';
import { isVisible, matchChucks } from './ChuckUtils';
import { useDevicesQuery, useMapPointsQuery, useWorkAreasQuery } from './FloorView.graphql';
import styles from './FloorView.module.css';
import { FullScreenButton } from './FullScreenButton';
import { FullScreenMode } from './FullScreenMode';
import { mapApi, MapOptions } from './MapOptions';
import { MapSelection } from './MapSelection';
import { WorkflowPoint } from './WorkflowPoint';
import { useChucks } from './use-chucks';
import { useTags } from './use-tags';
import { useTelemetry } from './use-telemetry';
import { Error } from 'components/Error';
import { useLocalization } from 'hooks/useLocalization';

/**
 * The Floor View is a real-time display of activity in a warehouse. It gets
 * its data from two sources: the Fulfillment API and the Map Manager. Some
 * of this data is polled, some is loaded on demand, and some is streamed via a
 * web socket endpoint.
 */
export function FloorView() {
	const { messages } = useLocalization();
	const fsRef = useRef<HTMLDivElement>(null);
	const telemetryEvent = useTelemetry();
	const [fullscreen, setFullScreen] = useState(false);
	const [selection, setSelection] = useState<MapSelection | undefined>(undefined);
	const [args, setArgs] = useState<ChuckArgs>({ searchText: '', statuses: [] });
	const [mapIndex, setMapIndex] = useState(0);
	const devicesQuery = useDevicesQuery();
	const workAreasQuery = useWorkAreasQuery();
	const mapPointsQuery = useMapPointsQuery();
	const chucks = useChucks(devicesQuery.results, telemetryEvent);
	const fetching = devicesQuery.fetching || workAreasQuery.fetching || mapPointsQuery.fetching;
	const error = devicesQuery.error || workAreasQuery.error || mapPointsQuery.error;
	const workAreas = workAreasQuery.results;
	const isMultiWorkArea = workAreas.length > 1;
	const maps: MapStack[] = workAreas.map((area) => area.map);
	const activeMap = maps[mapIndex];
	const mapPointsConfigs = mapPointsQuery.results;
	const tags = useTags(activeMap, mapPointsConfigs);
	const workAreaNames = workAreas.map((area, i) => area.name || 'Work area ' + (i + 1));
	const pageTitle = isMultiWorkArea ? workAreaNames[mapIndex] : messages.floorView;

	// this callback drives selection changes from within the map element
	const onSelectionChange = useCallback(
		(selection: SelectionState) => {
			if (selection.primary) {
				setSelection({
					id: selection.primary,
					type: chucks.find((c) => c.id === selection.primary) ? 'chuck' : 'point',
				});
			} else {
				setSelection(undefined);
			}
		},
		[chucks],
	);

	// filter chucks by selected work area
	const areaChucks = chucks.filter((chuck) => {
		return !isMultiWorkArea || chuck.workAreaId === activeMap?.id;
	});

	// apply "matched" state to chucks based on selected filters
	const matchedChucks = matchChucks(areaChucks, args);

	// if deep-linking into this page ("Find this Chuck"), show the corresponding map
	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const id = params.get('chuck');

		if (id) {
			const chuck = chucks.find((chuck) => chuck.id === id);

			if (chuck?.workAreaId) {
				const index = workAreas.findIndex((area) => area.id === chuck.workAreaId);
				setMapIndex(index);
			}
		}
	}, [chucks, workAreas]);

	// waiting for all GQL queries to resolve
	if (fetching) {
		return null;
	}

	// any GQL error will break this page
	if (error) {
		return <Error graphQLError={error} />;
	}

	return (
		<Page
			fullWidth
			title={pageTitle}
			primaryAction={
				<Stack alignment="center" spacing="extraLoose">
					<ChuckCount chucks={areaChucks} />
					<FullScreenButton target={fsRef.current} onToggle={setFullScreen} />
				</Stack>
			}
		>
			<ChuckFilters args={args} onFilter={setArgs} />

			<div
				ref={fsRef}
				className={styles.container}
				data-filtered={args.statuses.length > 0}
				data-fullscreen={fullscreen}
			>
				{fullscreen ? (
					<FullScreenMode
						chucks={areaChucks}
						map={activeMap}
						tags={tags}
						title={isMultiWorkArea ? workAreaNames[mapIndex] : undefined}
					/>
				) : (
					<>
						{selection?.type === 'point' ? (
							<WorkflowPoint
								selectedId={selection.id}
								mapStack={activeMap}
								mapPointConfigs={mapPointsConfigs}
								onClose={(didUpdate) => {
									mapApi.selectFeatures([]);

									if (didUpdate) {
										mapPointsQuery.refetch();
									}
								}}
							/>
						) : (
							<ChuckList
								chucks={matchedChucks}
								filtered={!!(args.searchText || args.statuses.length)}
								selectedId={selection?.id}
								onSelect={(id) => mapApi.selectFeatures(id || [])}
							/>
						)}
						<MapElement
							mapStack={activeMap}
							chucks={matchedChucks.filter(isVisible).map((chuck) => asChuckIcon(chuck, false))}
							showWorkflowPoint
							showImpassable
							enablePanControl
							enableSelectionControl
							onSelectionChange={onSelectionChange}
							tags={tags}
						>
							<MapOptions fullscreen={false} />
							<ZoomControl position="bottomright" />
							<NavMenu
								workAreaIdx={mapIndex}
								displayNames={workAreaNames}
								onNavigate={setMapIndex}
								position={isMultiWorkArea ? 'topright' : 'none'}
								label={messages.workArea}
							/>
							<NavControls
								workAreaIdx={mapIndex}
								length={workAreas.length}
								onNavigate={setMapIndex}
								position={isMultiWorkArea ? 'topright' : 'none'}
							/>
						</MapElement>
					</>
				)}
			</div>
		</Page>
	);
}
