import {
	Button,
	Card,
	Collapsible,
	Heading,
	Modal,
	Stack,
	TextContainer,
	TextField,
	TextStyle,
} from '@shopify/polaris';
import { ChevronUpMinor, ChevronDownMinor } from '@shopify/polaris-icons';
import {
	MutationResponse,
	ShippingNotice,
	ShippingNoticeInternalNoteInput,
} from '@sixriver/fulfillment-api-schema';
import { useState, useEffect, useCallback } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useMutation } from 'urql';

import { UPDATE_SHIPPING_NOTICE_INTERNAL_NOTE_MUTATION } from '../InboundShipment.graphql';
import { DateTime } from 'components/DateTime';
import { Divider } from 'components/Divider';
import { useAuth } from 'hooks/useAuth';
import { useLocalization } from 'hooks/useLocalization';
import { useToast } from 'hooks/useToast';

interface Props {
	shippingNotice?: ShippingNotice;
	refetchShipment: () => void;
}

export function InternalNotes({ shippingNotice, refetchShipment }: Props) {
	const { messages } = useLocalization();
	const { showToast } = useToast();
	const {
		params: { inboundShipmentId },
	} = useRouteMatch<{ inboundShipmentId: string }>();

	// State
	const [noteCollapsibleOpen, setNoteCollapsibleOpen] = useState(false);
	const [isEditModalOpen, setIsEditModalOpen] = useState(false);
	const [internalNoteValue, setInternalNoteValue] = useState('');

	// Auth
	const { user } = useAuth();
	const loggedInUserId = user?.id;

	const existingNote =
		shippingNotice && shippingNotice.comments && shippingNotice.comments.length > 0;

	useEffect(() => {
		if (existingNote) {
			setNoteCollapsibleOpen(true);
		}
	}, [existingNote]);

	// Mutations
	const [, updateInternalNote] = useMutation<
		{ updateInternalNote: MutationResponse },
		{ input: ShippingNoticeInternalNoteInput }
	>(UPDATE_SHIPPING_NOTICE_INTERNAL_NOTE_MUTATION);

	// Methods
	const toggleNoteCollapsible = useCallback(
		() => setNoteCollapsibleOpen((noteCollapsibleOpen) => !noteCollapsibleOpen),
		[],
	);

	const openEditModal = () => setIsEditModalOpen(true);
	const closeEditModal = () => setIsEditModalOpen(false);

	const saveInternalNote = async () => {
		if (!loggedInUserId) return;

		const input: ShippingNoticeInternalNoteInput = {
			asnId: inboundShipmentId,
			comments: internalNoteValue,
			userId: loggedInUserId,
		};

		const { data } = await updateInternalNote({ input });
		if (data?.updateInternalNote.success) {
			refetchShipment();
			setInternalNoteValue('');
			closeEditModal();
		} else {
			showToast(messages.updateNoteFailed);
		}
	};

	const exitExistingNote = () => {
		setInternalNoteValue(shippingNotice?.comments || '');
		openEditModal();
	};

	const cancelInternalNoteSave = () => {
		setInternalNoteValue('');
		closeEditModal();
	};

	const handleInternalNoteChange = useCallback((newValue) => setInternalNoteValue(newValue), []);

	return (
		<>
			<Card>
				<Card.Section>
					<Stack distribution="equalSpacing">
						<Stack alignment="center" spacing="extraTight">
							<Button
								icon={noteCollapsibleOpen ? ChevronUpMinor : ChevronDownMinor}
								plain
								onClick={toggleNoteCollapsible}
								ariaExpanded={noteCollapsibleOpen}
								ariaControls="basic-collapsible"
								monochrome={true}
								removeUnderline={true}
								size="large"
								disabled={!existingNote}
							/>
							<Heading>{messages.internalNotes}</Heading>
						</Stack>
						{!existingNote && (
							<Button plain onClick={openEditModal}>
								{messages.addInternalNote}
							</Button>
						)}
					</Stack>
				</Card.Section>
				<Collapsible
					open={noteCollapsibleOpen}
					id="basic-collapsible"
					transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
					expandOnPrint
				>
					<Divider />
					{existingNote && (
						<Card.Section>
							<Stack distribution="equalSpacing">
								<TextContainer>
									{shippingNotice?.comments}{' '}
									<TextStyle variation="subdued">
										<DateTime date={shippingNotice?.lastEditedAt || ''} />
									</TextStyle>
								</TextContainer>
								<Button plain onClick={exitExistingNote}>
									{messages.editNote}
								</Button>
							</Stack>
						</Card.Section>
					)}
				</Collapsible>
			</Card>
			<Modal
				title={messages.internalNotes}
				open={isEditModalOpen}
				onClose={closeEditModal}
				primaryAction={{
					content: messages.save,
					onAction: saveInternalNote,
				}}
				secondaryActions={[
					{
						content: messages.cancel,
						destructive: false,
						onAction: cancelInternalNoteSave,
					},
				]}
			>
				<Modal.Section>
					<TextField
						autoComplete="off"
						label={messages.notes}
						value={internalNoteValue}
						onChange={handleInternalNoteChange}
						multiline={4}
					/>
				</Modal.Section>
			</Modal>
		</>
	);
}
