import gql from 'graphql-tag';

export const JOB_QUERY = gql`
	query GetJob($id: String!) {
		jobV2(id: $id) {
			id
			externalId
			pickStrategy
			stagingIdentifier
			status
			externalContainerId
			exceptions {
				id
				isHealable
			}
			isCancelable
			isOrder
			externalId
			lines {
				id
				quantity
				doneQuantity
				status
				completedAt
				isShorted
			}
			expectedShipDate
			createdAt
			updatedAt
			completedAt
			isHealJob
			isShorted
			servicing {
				id
				externalId
				isOrder
				lines {
					id
					status
				}
				servicing {
					id
					isOrder
					servicedBy {
						id
						servicedBy {
							id
						}
					}
				}
				servicedBy {
					id
					isHealJob
					lines {
						id
						quantity
						doneQuantity
						status
						completedAt
					}
					servicedBy {
						id
						isHealJob
					}
				}
			}
			servicingSorations {
				id
				jobId
				wall
				batchId
				kiosk
				status
				timeline {
					completedAt
				}
				pickingJobs {
					id
				}
			}
			mfp {
				name
			}
			tasks {
				id
				type
				completedAt
				takeoffDestination
				container {
					name
				}
				workArea {
					id
					name
				}
			}
			isPackoutRequired
			packout {
				id
				status
				timeline {
					acceptedAt
					updatedAt
					inductedAt
					finishedAt
				}
				packStation {
					name
				}
				containerTypes
				user {
					badge
					id
				}
				packoutRequestIdentifier
			}
			workOrderTypes
			destinationNode
		}
	}
`;
