import { IndexTable, Stack, TextStyle } from '@shopify/polaris';
import { PickStrategy } from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';
import { usePickStrategyName } from 'hooks/usePickStrategies';

interface PickStrategyCellProps {
	pickStrategy?: PickStrategy;
}

export function PickStrategyCell({ pickStrategy }: PickStrategyCellProps) {
	const getPickStrategyName = usePickStrategyName();
	return (
		<IndexTable.Cell>
			<Stack vertical spacing="extraTight">
				<TextStyle>{pickStrategy ? getPickStrategyName(pickStrategy) : <NoData />}</TextStyle>
			</Stack>
		</IndexTable.Cell>
	);
}
