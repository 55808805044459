import { useContext } from 'react';

import { ToastContext } from 'providers/ToastProvider';

export const useToast = () => {
	const { showToast } = useContext(ToastContext);

	return {
		showToast: (content: string, error?: boolean) => {
			const id = Math.random().toString();

			return showToast({ id, content, error });
		},
	};
};
