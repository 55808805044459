import { EmptySearchResult, IndexTable, IndexTableProps } from '@shopify/polaris';
import { LineEdgeV2 } from '@sixriver/fulfillment-api-schema';

import { AssociatesCell } from './AssociateCell';
import { ExceptionBadgeCell } from './ExceptionBadgeCell';
import { PackingUnitsCell } from './PackingUnitsCell';
import { PickingUnitsCell } from './PickingUnitsCell';
import { ProductDescriptionCell } from './ProductDescriptionCell';
import { ProductIdCell } from './ProductIdCell';
import { ProgressCell } from './Progress';
import { ReasonCell } from './ReasonCell';
import { SourceAddressCell } from './SourceAddressCell';
import { useConfig } from 'hooks/useConfig';
import { useLocalization } from 'hooks/useLocalization';

export enum LinesTableColumn {
	id = 'id',
	name = 'name',
	pickingUnits = 'pickingUnits',
	packingUnits = 'packingUnits',
	location = 'location',
	associate = 'associate',
	exceptions = 'exceptions',
	progress = 'progress',
	reason = 'reason',
}
interface Props {
	columns: LinesTableColumn[];
	loading: boolean;
	data?: LineEdgeV2[];
}

export function LinesTable({ columns, loading, data = [] }: Props) {
	const { messages } = useLocalization();
	const { config } = useConfig();

	const resourceName = {
		singular: messages.line.toLowerCase(),
		plural: messages.lines.toLowerCase(),
	};
	const emptyStateMarkup = <EmptySearchResult title={messages.noLinesFound} withIllustration />;

	const headings = columns
		.map((column) => {
			switch (column) {
				case LinesTableColumn.id:
					return { title: messages.item };
				case LinesTableColumn.name:
					return { title: config?.inventoryEnabled ? messages.sku : messages.name };
				case LinesTableColumn.pickingUnits:
					return { title: messages.unitsPicked };
				case LinesTableColumn.packingUnits:
					return { title: messages.units };
				case LinesTableColumn.location:
					return { title: messages.location };
				case LinesTableColumn.associate:
					return { title: messages.associate };
				case LinesTableColumn.exceptions:
					return { title: messages.exceptions };
				case LinesTableColumn.progress:
					return { title: messages.progress };
				case LinesTableColumn.reason:
					return { title: messages.reason };
				default:
					return null;
			}
		})
		.filter((column) => !!column);

	const rows = data.map(({ node: line }, index) => {
		const batchPickTasks = line.tasks.filter((task) => task.type === 'batchPick');

		return (
			<IndexTable.Row id={line.id} key={line.id} position={index}>
				{columns
					.map((column) => {
						switch (column) {
							case LinesTableColumn.id:
								return <ProductIdCell line={line} key="id" />;
							case LinesTableColumn.name:
								return <ProductDescriptionCell line={line} key="name" />;
							case LinesTableColumn.pickingUnits:
								return <PickingUnitsCell line={line} key="picking-units" />;
							case LinesTableColumn.packingUnits:
								return <PackingUnitsCell line={line} key="packing-units" />;
							case LinesTableColumn.location:
								return <SourceAddressCell tasks={batchPickTasks} key="location" />;
							case LinesTableColumn.associate:
								return <AssociatesCell line={line} key="associate" />;
							case LinesTableColumn.exceptions:
								return <ExceptionBadgeCell line={line} key="exceptions" />;
							case LinesTableColumn.progress:
								return <ProgressCell line={line} key="progress" />;
							case LinesTableColumn.reason:
								return <ReasonCell line={line} key="reason" />;
							default:
								return null;
						}
					})
					.filter((column) => !!column)}
			</IndexTable.Row>
		);
	});

	return (
		<IndexTable
			emptyState={emptyStateMarkup}
			resourceName={resourceName}
			headings={headings as IndexTableProps['headings']}
			hasMoreItems={false}
			itemCount={rows.length}
			loading={loading}
			selectable={false}
		>
			{rows}
		</IndexTable>
	);
}
