import gql from 'graphql-tag';

export const SPECIAL_PROJECTS_QUERY = gql`
	query SpecialProjects(
		$limit: Float
		$cursor: String
		$searchText: String
		$statuses: [GoalStates!]
		$types: [SpecialProjectType!]
		$dateReceivedFrom: DateTime
		$orderBy: SpecialProjectsOrderByFields
		$orderByDirection: OrderByDirection
	) {
		specialProjects(
			cursor: $cursor
			limit: $limit
			searchText: $searchText
			statuses: $statuses
			types: $types
			dateReceivedFrom: $dateReceivedFrom
			orderBy: $orderBy
			orderByDirection: $orderByDirection
		) {
			cursor
			results {
				id
				externalId
				dateReceived
				status
				merchant
				type
				hasIssues
			}
		}
	}
`;

export const SPECIAL_PROJECT_QUERY = gql`
	query SpecialProject($id: String!) {
		specialProject(id: $id) {
			id
			externalId
			dateReceived
			type
			status
			merchant
			hasIssues
			totalTimeLogged
			... on KittingProject {
				quantityRequested
				quantity
				instructions {
					text
					media {
						contentType
						url
					}
				}
				product {
					id
					image
					name
					customerIdentifier
					needsInspection
				}
				componentProducts {
					id
					image
					name
					customerIdentifier
					eachQuantity
					totalRequired
					quantityUsed
					exceptions {
						eachQuantity
						reason
					}
				}
			}
			... on WorkOrderProject {
				products {
					id
					customerIdentifier
					name
					image
					eachQuantity
				}
				instructions {
					text
					media {
						contentType
						url
					}
				}
			}
		}
	}
`;

export const COUNTS_QUERY = gql`
	query CountSpecialOrders {
		StaticReadyCount: specialProjectCount(statuses: [READY]) {
			count
		}
		StaticRunningCount: specialProjectCount(statuses: [RUNNING]) {
			count
		}
	}
`;

export const WORK_LOG_QUERY = gql`
	query WorkLog($id: String!, $cursor: String, $limit: Float) {
		getWorkLog(cursor: $cursor, id: $id, limit: $limit) {
			cursor
			results {
				id
				task
				dateLogged
				timeLogged
				isSystemGenerated
				user {
					id
					name
				}
			}
		}
	}
`;

export const MARK_AS_COMPLETE_MUTATION = gql`
	mutation MarkAsComplete($id: String!) {
		markAsComplete(id: $id) {
			success
		}
	}
`;

export const REPORT_ISSUES_MUTATION = gql`
	mutation ReportIssues($input: SpecialProjectIssuesInput!) {
		reportIssues(input: $input) {
			success
		}
	}
`;

export const ADD_TIME_LOG_MUTATION = gql`
	mutation AddTimeLog($input: WorkLogInput!) {
		addTimeLog(input: $input) {
			success
		}
	}
`;

export const EDIT_TIME_LOG_MUTATION = gql`
	mutation EditTimeLog($input: WorkLogInput!) {
		editTimeLog(input: $input) {
			success
		}
	}
`;

export const EMPLOYEES_QUERY = gql`
	query Employees($type: UserActivityType!, $searchText: String) {
		users(type: $type, searchText: $searchText) {
			edges {
				node {
					id
					name
				}
			}
		}
	}
`;
