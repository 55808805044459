import { IndexTable, TextStyle } from '@shopify/polaris';
import { TaskV2 } from '@sixriver/fulfillment-api-schema';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { SourceAddressPopover } from './SourceAddressPopover';
import { NoData } from 'components/NoData';
import * as routes from 'routes';

export interface RouteMatchParams {
	outboundJobId: string;
}

const getParams = (tab: string) => {
	const searchParams = new URLSearchParams();

	searchParams.set('tab', tab);

	return '?' + searchParams.toString();
};

interface Props {
	tasks: TaskV2[];
}

export function SourceAddressCell({ tasks }: Props): JSX.Element {
	const history = useHistory();

	const {
		params: { outboundJobId },
	} = useRouteMatch<RouteMatchParams>();

	if (tasks.length === 1) {
		const sourceLocationAddress = tasks[0].sourceLocationAddress;

		if (sourceLocationAddress) {
			return (
				<IndexTable.Cell>
					<TextStyle>{sourceLocationAddress}</TextStyle>
				</IndexTable.Cell>
			);
		}
	}
	if (tasks.length > 1) {
		return (
			<IndexTable.Cell>
				<SourceAddressPopover
					tasks={tasks}
					onViewJobHistory={() =>
						history.push(`${routes.outboundJob(outboundJobId)}${getParams('history')}`)
					}
				/>
			</IndexTable.Cell>
		);
	}

	return (
		<IndexTable.Cell>
			<NoData />
		</IndexTable.Cell>
	);
}
