import { IndexTable, Link } from '@shopify/polaris';
import { LaneContainer } from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';
import * as routes from 'routes';

interface Props {
	container: LaneContainer;
}

export function ContainerIdCell({ container }: Props): JSX.Element {
	if (container.id) {
		return (
			<IndexTable.Cell>
				<Link
					url={routes.laneContainer(encodeURIComponent(container.laneId!), container.id)}
					removeUnderline
				>
					{container.id}
				</Link>
			</IndexTable.Cell>
		);
	}

	return (
		<IndexTable.Cell>
			<NoData />
		</IndexTable.Cell>
	);
}
