import { Select, FormLayout, SelectProps } from '@shopify/polaris';
import { Cutoff } from '@sixriver/fulfillment-api-schema';

import styles from './CutoffFilter.module.css';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	onChange: SelectProps['onChange'];
	value?: SelectProps['value'];
	cutoffDates?: Cutoff;
}

export const sortByDate = (a: { value: string }, b: { value: string }) => {
	const dateA = new Date(a.value);
	const dateB = new Date(b.value);

	if (dateB > dateA) {
		return -1;
	}

	if (dateA > dateB) {
		return 1;
	}

	return 0;
};

export function CutoffFilter({ onChange, value, cutoffDates }: Props) {
	const { messages, formatDateTime } = useLocalization();

	return (
		<div className={styles.cutoffFilter}>
			<FormLayout>
				<Select
					label={messages.date}
					options={[
						{ label: '', value: '' },
						...(cutoffDates?.dates || [])
							.map((date) => ({
								label: formatDateTime(date),
								value: date,
							}))
							.sort(sortByDate),
					]}
					onChange={onChange}
					value={value}
				/>
			</FormLayout>
		</div>
	);
}
