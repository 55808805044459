import { Badge } from '@shopify/polaris';
import { LineV2, LineStatus, PackoutStatus } from '@sixriver/fulfillment-api-schema';

import { useLinesContext } from 'components/Lines/UseLinesContext';
import { NoData } from 'components/NoData';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	line: LineV2;
}

export function ProgressBadge({ line }: Props): JSX.Element {
	const { messages } = useLocalization();
	const { job } = useLinesContext();

	if (!line.status) {
		return <NoData />;
	}

	if (line.status.includes(LineStatus.AwaitingInventory)) {
		return <Badge status="warning">{messages.awaitingInventory}</Badge>;
	}

	if (line.status.includes(LineStatus.Unassigned)) {
		return <Badge status="warning">{messages.unassigned}</Badge>;
	}

	if (line.status.includes(LineStatus.InProgress)) {
		return <Badge status="info">{messages.inProgress}</Badge>;
	}

	if (job.packout?.status === PackoutStatus.Inducted) {
		return <Badge status="info">{messages.packing}</Badge>;
	}

	if (line.status.includes(LineStatus.Complete) || line.status.includes(LineStatus.Done)) {
		if (job.isPackoutRequired) {
			if (job.packout?.status) {
				return <Badge>{messages.readyToPack}</Badge>;
			}
			return <Badge>{messages.picked}</Badge>;
		}
		return <Badge>{messages.completed}</Badge>;
	}

	if (line.status.includes(LineStatus.Packed)) {
		return <Badge>{messages.completed}</Badge>;
	}

	return <NoData />;
}
