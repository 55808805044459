import { Banner, Modal } from '@shopify/polaris';
import { CircleDisabledMajor } from '@shopify/polaris-icons';
import { useState } from 'react';
import { CombinedError } from 'urql';

import styles from './FormFeedback.module.css';
import { useCopyToClipboard } from 'hooks/useCopyToClipboard';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	feedback?: CombinedError;
	override?: string;
}

export function FormFeedback({ feedback, override }: Props) {
	const { messages } = useLocalization();
	const [modalVisible, setModalVisible] = useState(false);
	const { copyToClipboard } = useCopyToClipboard();

	if (feedback || override) {
		const isValidationError = feedback?.graphQLErrors?.some(
			(e) => e.message === 'Argument Validation Error',
		);

		const errors = feedback?.graphQLErrors;
		const trace = JSON.stringify(errors, null, 2);

		return (
			<div className={styles.banner}>
				<Banner
					status="critical"
					icon={CircleDisabledMajor}
					title={messages.oops}
					action={
						isValidationError
							? undefined
							: { content: messages.details, onAction: () => setModalVisible(true) }
					}
				>
					{override || (isValidationError ? messages.hasValidationErrors : messages.dataNotSaved)}
				</Banner>
				<Modal
					large
					title={messages.error}
					open={modalVisible}
					onClose={() => setModalVisible(false)}
					primaryAction={{
						content: 'Copy',
						onAction: () => copyToClipboard(trace),
					}}
				>
					<Modal.Section>
						<pre className={styles.debug}>{trace}</pre>
					</Modal.Section>
				</Modal>
			</div>
		);
	}

	return null;
}
