import { ChoiceList } from '@shopify/polaris';

import { noop } from 'helpers/noop';
import { useLocalization } from 'hooks/useLocalization';

interface ExceptionsFilterProps {
	selected?: string[];
	onChange?: (selected: string[], name: string) => void;
}

export function ExceptionsFilter({ selected = [], onChange = noop }: ExceptionsFilterProps) {
	const { messages } = useLocalization();

	return (
		<ChoiceList
			title={messages.exceptions}
			titleHidden
			choices={[
				{
					label: messages.noExceptions,
					value: messages.isNotOverridden,
				},
				{
					label: messages.hasExceptions,
					value: messages.isOverridden,
				},
			]}
			selected={selected}
			onChange={onChange}
		/>
	);
}
