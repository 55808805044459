import { Button, Icon, Layout, Page, Stack } from '@shopify/polaris';
import { AddMajor } from '@shopify/polaris-icons';
import { useDebouncedValue } from '@shopify/react-hooks';
import {
	OrderByDirection,
	QueryLocationsArgs,
	QueryLocationTypeCountsArgs,
	StorageLocationSummaryPage,
	StorageLocationOrderByFields,
	StorageLocationType,
	StorageLocationTypeCount,
} from '@sixriver/fulfillment-api-schema';
import { useState } from 'react';

import { GET_LOCATIONS_QUERY, GET_LOCATION_TYPE_COUNTS } from './Locations.graphql';
import { LocationsTable } from './LocationsTable';
import { useLocationsTableFilters } from './use-locations-table-filters';
import { AutoRefresh } from 'components/AutoRefresh';
import { Error } from 'components/Error';
import { WorkAreaMenu } from 'components/WorkAreaMenu';
import { boolStringToTriStateBool } from 'helpers/boolean';
import { getPageSize } from 'helpers/page-size';
import { MIN_QUERY_LENGTH } from 'helpers/table';
import { useAuth } from 'hooks/useAuth';
import { useConfig } from 'hooks/useConfig';
import { useFilters, useSetFilters } from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';
import { usePolling } from 'hooks/usePolling';
import { usePollingQuery } from 'hooks/usePollingQuery';
import { useWorkAreas } from 'hooks/useWorkArea/use-work-areas';
import { UserRole } from 'providers/AuthProvider';
import * as routes from 'routes';

export function Locations() {
	const { messages } = useLocalization();

	// Config
	const { config } = useConfig();

	// Work areas
	const { workAreas } = useWorkAreas();

	const { isUserAllowed } = useAuth();

	// State
	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);

	// Filters / URL Params
	const { filters } = useLocationsTableFilters();

	// Polling
	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();

	// Queries
	const [sortByField, sortByDirection] = filters.sort
		? filters.sort.split(' ')
		: [undefined, undefined];

	const searchText = useDebouncedValue(filters.searchText) || '';

	const { workArea } = useFilters(['workArea']);

	const selectedWorkAreas = workArea ? workArea.split(' ') : undefined;

	const queryVars = {
		cursor: paginationCursors[0],
		limit: getPageSize(),
		fullness: Number(filters.fullness) > -1 ? Number(filters.fullness) : undefined,
		isConflicted: boolStringToTriStateBool(filters.isConflicted),
		isSlotted: boolStringToTriStateBool(filters.isSlotted),
		orderBy: sortByField as StorageLocationOrderByFields,
		orderByDirection: sortByDirection as OrderByDirection,
		searchText: searchText.length >= MIN_QUERY_LENGTH ? searchText : undefined,
		type: filters.view === 'all' ? undefined : (filters.view as StorageLocationType),
		workAreaIds: selectedWorkAreas,
	};

	const [{ fetching: fetchingLocations, data: locationsData, error: locationsError }] =
		usePollingQuery<{ locations: StorageLocationSummaryPage }, QueryLocationsArgs>({
			query: GET_LOCATIONS_QUERY,
			variables: queryVars,
			pollInterval: queryPollInterval,
		});

	const [
		{
			fetching: fetchingLocationTypeCounts,
			data: locationTypeCountsData,
			error: locationsCountError,
		},
	] = usePollingQuery<
		{ locationTypeCounts: StorageLocationTypeCount[] },
		QueryLocationTypeCountsArgs
	>({
		query: GET_LOCATION_TYPE_COUNTS,
		variables: queryVars,
		pollInterval: queryPollInterval,
	});

	const error = locationsError || locationsCountError;

	const setFilters = useSetFilters();

	if (error) {
		return <Error graphQLError={error} />;
	}

	return (
		<Page
			title={messages.locations}
			fullWidth
			primaryAction={
				config?.workAreasEnabled ? (
					<WorkAreaMenu
						workAreas={workAreas}
						selectedIds={selectedWorkAreas || []}
						onChange={(selected: string[]) => {
							setFilters([{ key: 'workArea', value: selected.join(' ') }]);
						}}
					/>
				) : null
			}
		>
			<Layout>
				<Layout.Section>
					<Stack distribution="trailing" alignment="trailing" spacing="extraLoose">
						<Button
							plain
							monochrome
							removeUnderline
							disabled={!isUserAllowed([UserRole.Admin, UserRole.EmployeeManager])}
							icon={<Icon source={AddMajor} />}
							url={routes.addLocation()}
						>
							{messages.addLocation}
						</Button>

						<AutoRefresh
							discriminatorData={locationsData}
							pollingEnabled={pollingEnabled}
							togglePolling={togglePolling}
						/>
					</Stack>
				</Layout.Section>
				<Layout.Section>
					<LocationsTable
						paginationCursors={paginationCursors}
						setPaginationCursors={setPaginationCursors}
						data={locationsData?.locations}
						counts={locationTypeCountsData?.locationTypeCounts}
						loading={fetchingLocations || fetchingLocationTypeCounts}
						isInventoryEnabled={config?.inventoryEnabled || false}
						workAreas={workAreas}
					/>
				</Layout.Section>
			</Layout>
		</Page>
	);
}
