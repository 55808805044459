import { Config } from '@sixriver/fulfillment-api-schema';
import gql from 'graphql-tag';
import { useQuery } from 'urql';

export const CONFIG_QUERY = gql`
	query Config {
		config: configuration {
			batteryThresholds {
				viable
				critical
			}
			countJobsUrl
			deviceDwellThreshold
			deviceOfflineThreshold
			enabledDeviceModelTypes
			enabledDeviceTypes
			environment
			experimentalFlags
			healingEnabled
			healingStrategies
			highPriorityChuckEnabled
			highPriorityChuckReasonsOrdered
			inventoryEnabled
			jobAllocationMethods
			mapManagerUrl
			mapPointQueueDepthEditable
			mapPointStatusEditable
			moveJobsUrl
			orderTagEnabled
			packoutEnabled
			receivingEnabled
			replenishmentEnabled
			returnsEnabled
			shippingEnabled
			reworkEnabled
			siteId
			siteName
			sortationEnabled
			workAreasEnabled
		}
	}
`;

export function useConfig(isAuthenticated = true) {
	const [{ error, fetching, data }] = useQuery<{
		config: Config;
	}>({
		query: CONFIG_QUERY,
		requestPolicy: 'cache-first',
		pause: !isAuthenticated,
	});

	return { error, fetching, config: data?.config };
}
