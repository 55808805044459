import { Button, Card, Layout, Page, Stack, TextStyle } from '@shopify/polaris';

import { InlineFrame } from 'components/InlineFrame';
import { useLocalization } from 'hooks/useLocalization';

export function Support() {
	const { messages } = useLocalization();

	return (
		<Page>
			<Layout>
				<Layout.Section>
					<div style={{ height: '90vh' }}>
						<div style={{ height: '70%' }}>
							<InlineFrame title={messages.support} src={`/6support/`} />
						</div>
						<div style={{ margin: '3em 0' }}>
							<Layout>
								<Layout.Section oneHalf>
									<Card title={messages.feedbackHeading}>
										<Card.Section fullWidth={false}>
											<Stack vertical wrap={true}>
												<TextStyle>{messages.feedbackRequest}</TextStyle>
												<Button external url="https://www.surveymonkey.com/r/6RSBridgeFeedback">
													{messages.feedbackAction}
												</Button>
											</Stack>
										</Card.Section>
									</Card>
								</Layout.Section>

								<Layout.Section oneHalf>
									<Card title={messages.chuck5Documentation}>
										<Card.Section fullWidth={false}>
											<Stack vertical>
												<TextStyle>
													<br />
													&nbsp;
												</TextStyle>
												<Button
													external
													url="https://drive.google.com/file/d/1czGGPaGEMjGJkt2Hfer9qJtuvq2ZIOgp/view"
												>
													{messages.download}
												</Button>
											</Stack>
										</Card.Section>
									</Card>
								</Layout.Section>
							</Layout>
						</div>
					</div>
				</Layout.Section>
			</Layout>
		</Page>
	);
}
