import {
	Page,
	Layout,
	Stack,
	ComplexAction,
	MenuGroupDescriptor,
	Loading,
	Frame,
	Card,
	Pagination,
} from '@shopify/polaris';
import { useDebouncedValue } from '@shopify/react-hooks';
import {
	ContainerStatus,
	OrderConnection,
	OrderState,
	OrderStatus,
	QueryGetOrdersByContainerIdArgs,
	QueryLanesArgs,
} from '@sixriver/fulfillment-api-schema';
import { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';

import { LANE_CONTAINER_QUERY } from './LaneContainerDetail.graphql';
import { LaneContainerDetails } from './LaneContainerDetails';
import { LaneContainerOrdersFilters } from './LaneContainerOrdersFilters/LaneContainerOrdersFilters';
import { LaneContainerOrdersTable } from './LaneContainerOrdersTable';
import { LaneContainerOrdersTab, LaneContainerOrdersTabs } from './LaneContainerOrdersTabs';
import { LANE_CONTAINERS_ORDERS_QUERY } from './LaneContainersOrders.graphql';
import { AutoRefresh } from 'components/AutoRefresh';
import { Error } from 'components/Error';
import { ErrorBanner } from 'components/ErrorBanner';
import { LaneContainerStatusBadge } from 'components/LaneContainerStatusBadge';
import { TimezoneFooter } from 'components/TimezoneFooter';
import { getPageSize } from 'helpers/page-size';
import { MIN_QUERY_LENGTH } from 'helpers/table';
import { useLocalization } from 'hooks/useLocalization';
import { usePolling } from 'hooks/usePolling';
import { usePollingQuery } from 'hooks/usePollingQuery';
import { useArrayQueryState, useQueryState } from 'hooks/useQueryState';
import { LanesData } from 'pages/Lanes/Lanes.graphql';

const STATUSES_KEY = 'statuses';
const SEARCH_ORDER_TEXT_KEY = 'orderNumberquery';
const SEARCH_TRACKING_NUMBER_TEXT_KEY = 'trackingNumberquery';
const PAGINATION_CURSORS_KEY = 'paginationCursors';

export interface RouteMatchParams {
	laneId: string;
	containerId: string;
}

export function LaneContainer() {
	const {
		params: { laneId, containerId },
	} = useRouteMatch<RouteMatchParams>();

	const [isBannerErrorVisible, setIsBannerErrorVisible] = useState(false);
	const [selectedTab, setSelectedTab] = useState<LaneContainerOrdersTab>(
		LaneContainerOrdersTab.All,
	);
	const [selectedStatuses, setSelectedStatuses] = useArrayQueryState<OrderState[]>(
		STATUSES_KEY,
		[],
	);
	const [searchText, setSearchText] = useQueryState(SEARCH_ORDER_TEXT_KEY, '');
	const [trackingNumberSearchText, setTrackingNumberSearchText] = useQueryState(
		SEARCH_TRACKING_NUMBER_TEXT_KEY,
		'',
	);
	const [paginationCursors, setPaginationCursors] = useArrayQueryState<string[]>(
		PAGINATION_CURSORS_KEY,
		[],
	);

	const { messages } = useLocalization();
	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();
	const actualSearchText = searchText.length >= MIN_QUERY_LENGTH ? searchText : undefined;
	const debouncedTrackingNumberSearchText = useDebouncedValue(trackingNumberSearchText);

	/**
	 * Queries
	 */

	const [{ fetching: fetchingLanes, data: lanesData, error: lanesError }] = usePollingQuery<
		LanesData,
		QueryLanesArgs
	>({
		query: LANE_CONTAINER_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			laneIds: [decodeURIComponent(laneId)],
			containerIds: [containerId],
		},
	});

	const lane = lanesData?.lanes.edges[0].node;
	const container = lane?.containers[0];

	const [{ fetching: ordersFetching, error: ordersError, data: ordersData }, refetchOrders] =
		usePollingQuery<{ getOrdersByContainerId: OrderConnection }, QueryGetOrdersByContainerIdArgs>({
			query: LANE_CONTAINERS_ORDERS_QUERY,
			pollInterval: queryPollInterval,
			variables: {
				searchText: actualSearchText,
				containerId,
				first: getPageSize(),
				after: paginationCursors[0],
				statuses: selectedStatuses,
			},
		});

	const ordersPageInfo = ordersData?.getOrdersByContainerId.pageInfo;
	// Note: the ability to search by tracking numbers is not yet supported in classic view of mcqs hence... refactoring of old mcqs to come soon and support this
	const orders = debouncedTrackingNumberSearchText
		? ordersData?.getOrdersByContainerId.edges.filter(({ node }) =>
				node.trackingNumbers?.some((trackingNumber) =>
					trackingNumber.toLowerCase().includes(debouncedTrackingNumberSearchText.toLowerCase()),
				),
		  )
		: ordersData?.getOrdersByContainerId.edges || [];
	const totalCount = orders?.length ?? 0;
	const exceptionsCount = orders?.filter(
		({ node }) => node.status === OrderStatus.Exception,
	).length;
	const totalPages = Math.max(Math.ceil(totalCount / getPageSize()), 1);

	const fetching = fetchingLanes || ordersFetching;
	const error = lanesError || ordersError;

	const secondaryActions: ComplexAction[] = [];
	const actionGroups: MenuGroupDescriptor[] = [];

	useEffect(() => {
		switch (selectedTab) {
			case LaneContainerOrdersTab.All:
				setSelectedStatuses([]);
				return;
			case LaneContainerOrdersTab.Exceptions:
				setSelectedStatuses([OrderState.Exception]);
				return;
		}
	}, [selectedTab, setSelectedStatuses]);

	/**
	 * Pagination
	 * */
	const handleOnNextPage = useCallback(() => {
		const endCursor = ordersPageInfo?.endCursor ?? undefined;
		if (endCursor) {
			setPaginationCursors((current) => [endCursor].concat(current));
			refetchOrders();
		}
	}, [ordersPageInfo?.endCursor, refetchOrders, setPaginationCursors]);

	const handleOnPreviousPage = useCallback(() => {
		setPaginationCursors((current) => current.slice(1));
		refetchOrders();
	}, [refetchOrders, setPaginationCursors]);

	if (fetching) {
		return (
			<Frame>
				<Loading />
			</Frame>
		);
	}
	if (error) {
		return <Error graphQLError={error} />;
	}

	if (!container) {
		return <Error heading={messages.containerNotFound} />;
	}

	return (
		<Page
			title={`${messages.container}: ${container?.id}`}
			titleMetadata={
				<div data-testid="header">
					<Stack spacing="extraTight">
						<LaneContainerStatusBadge container={container} />
					</Stack>
				</div>
			}
			secondaryActions={secondaryActions}
			actionGroups={actionGroups}
			fullWidth
		>
			<AutoRefresh
				pollingEnabled={pollingEnabled}
				togglePolling={togglePolling}
				discriminatorData={ordersData}
			/>
			<Layout>
				<Layout.Section>
					<Stack vertical>
						<ErrorBanner
							isVisible={isBannerErrorVisible}
							onDismiss={() => {
								setIsBannerErrorVisible(false);
							}}
						/>
					</Stack>
				</Layout.Section>

				<Layout.Section>
					<div data-testid="details">
						<LaneContainerDetails lane={lane} />
					</div>
				</Layout.Section>
				<Layout.Section>
					<Card title={messages.orders}>
						<div style={{ paddingBottom: '2rem' }}>
							<LaneContainerOrdersTabs
								selected={selectedTab}
								onSelect={setSelectedTab}
								totalCount={totalCount ?? 0}
								exceptionsCount={exceptionsCount ?? 0}
							/>
						</div>

						<div style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
							<LaneContainerOrdersFilters
								orderNumberQueryValue={searchText}
								trackingNumberQueryValue={trackingNumberSearchText}
								onOrderNumberQueryChange={(value) => setSearchText(value)}
								onTrackingNumberQueryChange={(value) => setTrackingNumberSearchText(value)}
							/>
						</div>
						<LaneContainerOrdersTable
							loading={ordersFetching}
							data={orders}
							isShipped={lane.containers[0].containerStatus === ContainerStatus.Shipped}
						/>
						<Card.Section>
							<Stack distribution="center">
								<Pagination
									label={`${paginationCursors.length + 1} ${messages.of} ${totalPages}`}
									hasNext={ordersPageInfo?.hasNextPage}
									hasPrevious={ordersPageInfo?.hasPreviousPage}
									onNext={handleOnNextPage}
									onPrevious={handleOnPreviousPage}
								/>
							</Stack>
						</Card.Section>
					</Card>
				</Layout.Section>

				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</Page>
	);
}
