import { NoData } from 'components/NoData';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	inboundLineCount: number;
	uninspectedLineCount: number;
}

export function ReceivingInspection({ inboundLineCount, uninspectedLineCount }: Props) {
	const { messages, translate } = useLocalization();

	// no lines have been inspected yet || in progress inspection
	if (uninspectedLineCount === inboundLineCount || uninspectedLineCount > 0) {
		return (
			<p>
				{translate(messages.countRequired, {
					count: uninspectedLineCount,
				})}
			</p>
		);
	}

	// dont have line data on an ASN to get in progress inspection counts
	return <NoData />;
}
