import { IndexTable, Link } from '@shopify/polaris';
import { ReworkJobType } from '@sixriver/fulfillment-api-schema';

import * as routes from 'routes';

export function JobOriginCell({
	jobOriginId,
	jobOriginExternalId,
	jobType,
}: {
	jobOriginId: string;
	jobOriginExternalId: string;
	jobType: ReworkJobType;
}) {
	return (
		<IndexTable.Cell>
			<Link
				url={
					jobType === ReworkJobType.AdvanceShippingNotice
						? routes.inboundShipment(jobOriginId)
						: routes.inboundReturn(jobOriginId)
				}
				key="rework"
				removeUnderline
			>
				{jobOriginExternalId}
			</Link>
		</IndexTable.Cell>
	);
}
