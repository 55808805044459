import { Link, Badge } from '@shopify/polaris';
import {
	LineConnection,
	LinePickingCounts,
	QueryLinesPickingCountsArgs,
	LinesViews,
	LineStatus,
	QueryLinesByPickingProjectionIdArgs,
} from '@sixriver/fulfillment-api-schema';
import { useState } from 'react';

import { LINES_QUERY, COUNTS_QUERY } from './LinesTable.graphql';
import { DataTable, Column } from 'components/DataTable';
import { NoData } from 'components/NoData';
import { countLineExceptions } from 'helpers/exception';
import { getPageSize } from 'helpers/page-size';
import { useFilters, useSetFilters } from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';
import { usePolling } from 'hooks/usePolling';
import { usePollingQuery } from 'hooks/usePollingQuery';
import * as routes from 'routes';

interface Props {
	jobId: string;
	isInventoryEnabled?: boolean;
}

export function PickingLinesTable({ jobId, isInventoryEnabled }: Props) {
	const { messages, translate } = useLocalization();

	const { view = LinesViews.All } = useFilters();

	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);

	const { queryPollInterval } = usePolling();

	const [{ fetching: linesFetching, data: linesData }] = usePollingQuery<
		{ linesByPickingProjectionId: LineConnection },
		QueryLinesByPickingProjectionIdArgs
	>({
		query: LINES_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			projectionId: jobId,
			first: getPageSize(),
			after: paginationCursors[0],
			view: view as LinesViews,
		},
	});

	const [{ fetching: countsFetching, data: countsData }] = usePollingQuery<
		{
			linesPickingCounts: LinePickingCounts;
		},
		QueryLinesPickingCountsArgs
	>({
		query: COUNTS_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			projectionId: jobId,
		},
	});

	const counts = countsData?.linesPickingCounts;

	const { pageInfo, edges = [] } = linesData?.linesByPickingProjectionId || {};

	const setFilters = useSetFilters();

	const columns: Column[] = [
		{
			type: 'text',
			heading: messages.item,
		},
		{
			type: 'text',
			heading: isInventoryEnabled ? messages.sku : messages.name,
		},
		{
			type: 'text',
			heading: messages.unitsPicked,
		},
		{
			type: 'text',
			heading: messages.location,
		},
		{
			type: 'text',
			heading: messages.associate,
		},
		{
			type: 'text',
			heading: messages.exceptions,
		},
	];

	const rows = (edges || []).map(({ node: line }) => {
		const hasException = countLineExceptions([line]) > 0;

		return [
			line.product?.id ? (
				<Link url={routes.product(line.product.id)} key="product-id" removeUnderline>
					{line.product.customerIdentifier}
				</Link>
			) : (
				<NoData />
			),
			line.product?.description ? <p key="product-name">{line.product.description}</p> : <NoData />,
			<div key="units">
				{translate(messages.pickedOfTotal, {
					picked: line.doneQuantity,
					total: line.quantity,
				})}
			</div>,
			line.source?.address || <NoData />,
			line.user?.name || line.user?.id ? (
				<div key="associate">{line.user.name || line.user?.id}</div>
			) : (
				<NoData />
			),
			hasException ? (
				<Badge status="critical">{messages.exception}</Badge>
			) : line.status?.includes(LineStatus.Cleared) ? (
				<Badge status="success">{messages.cleared}</Badge>
			) : (
				<NoData />
			),
		];
	});

	return (
		<DataTable
			title={messages.lines}
			loading={linesFetching || countsFetching}
			columns={columns}
			rows={rows}
			emptyStateHeading={messages.linesNotFound}
			pageInfo={pageInfo}
			paginationCursors={paginationCursors}
			setPaginationCursors={setPaginationCursors}
			views={[
				{
					label: messages.all,
					metaLabel: counts?.All || 0,
					id: LinesViews.All,
				},
				{
					label: messages.exceptions,
					metaLabel: counts?.Exceptions || 0,
					id: LinesViews.Exceptions,
				},
			]}
			setFilters={setFilters}
			selectedView={view}
			hideFilters
		/>
	);
}
