import styles from './FloorView.module.css';

interface Props {
	label: string;
	value: number;
	color?: string;
	background?: string;
	icon?: string;
}

export function FullScreenStat({ label, value, color = 'white', background, icon }: Props) {
	return (
		<div className={styles.fsStat} style={{ background, color }}>
			<div className={styles.fsStatLabel} style={{ color }}>
				{icon ? <img src={icon} alt="" /> : null}
				{label}
			</div>
			<div className={styles.fsStatValue}>{value}</div>
		</div>
	);
}
