import {
	Card,
	Heading,
	Link,
	ProgressBar,
	Stack,
	TextContainer,
	TextStyle,
	Thumbnail,
} from '@shopify/polaris';
import { ReworkJob } from '@sixriver/fulfillment-api-schema';

import styles from './Rework.module.css';
import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';
import { transformImageUrl } from 'utils/image';

export function Overview({ reworkJob }: { reworkJob?: ReworkJob }) {
	const { messages, translate } = useLocalization();
	return (
		<Card>
			<Card.Section subdued>
				<Stack distribution="equalSpacing">
					<Stack.Item>
						<Stack spacing="tight">
							<Thumbnail
								source={transformImageUrl(reworkJob?.product?.image)}
								alt={reworkJob?.product?.description || ''}
								size="medium"
							/>
							<Stack vertical spacing="extraTight">
								<div className={styles.wrapText}>
									<Link url={routes.product(reworkJob?.product.id)} removeUnderline>
										{reworkJob?.product.description}
									</Link>
								</div>
								<TextContainer>
									<TextStyle variation="subdued">{reworkJob?.product.name}</TextStyle>
								</TextContainer>
							</Stack>
						</Stack>
					</Stack.Item>
					<Stack.Item>
						<Stack vertical alignment="trailing" spacing="tight">
							<TextStyle variation="strong">{messages.issue}</TextStyle>
							<Heading>{messages.receivingIssueReason[reworkJob?.issue || 'Other']}</Heading>
						</Stack>
					</Stack.Item>
				</Stack>
			</Card.Section>
			<Card.Section>
				<div className={styles.productColumns}>
					<TextContainer>
						<TextStyle variation="strong">{messages.processed}</TextStyle>
					</TextContainer>
					<TextContainer>
						<TextStyle variation="strong">{messages.sellable}</TextStyle>
					</TextContainer>
					<TextContainer>
						<TextStyle variation="strong">{messages.damaged}</TextStyle>
					</TextContainer>
				</div>
			</Card.Section>
			<Card.Section>
				<div className={styles.productColumns}>
					<div className={styles.processedColumn}>
						<Stack vertical={true} spacing="extraTight">
							<p>
								{translate(messages.xOfYUnits, {
									x: reworkJob?.processedQuantity,
									y: reworkJob?.reworkQuantity,
								})}
							</p>
							<ProgressBar
								progress={
									((reworkJob?.processedQuantity || 0) / (reworkJob?.reworkQuantity || 1)) * 100
								}
								color={
									reworkJob?.processedQuantity === reworkJob?.reworkQuantity ? 'success' : 'primary'
								}
							/>
						</Stack>
					</div>
					<p>
						{translate(messages.countUnits, {
							count: reworkJob?.quantitySellable,
						})}
					</p>
					<p>
						{translate(messages.countUnits, {
							count: reworkJob?.quantityDamaged,
						})}
					</p>
				</div>
			</Card.Section>
		</Card>
	);
}
