import { ChoiceList, Filters } from '@shopify/polaris';
import { useLayoutEffect } from 'react';

import { ChuckArgs, ChuckStatus } from './ChuckArgs';
import { useConfig } from 'hooks/useConfig';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	args: ChuckArgs;
	onFilter(args: ChuckArgs): void;
}

/**
 * This component displays a set of filters. Unlike other filters throughout
 * the UI, this component does not trigger additional queries to the server. All
 * filtering is performed against the data set in memory.
 */
export function ChuckFilters({ args, onFilter }: Props) {
	const { messages, translate } = useLocalization();
	const { searchText, statuses } = args;
	const { config } = useConfig();

	const tokens = {
		minutes: Math.round((config?.deviceDwellThreshold ?? 600) / 60),
	};

	// booooooo
	useLayoutEffect(() => {
		document.querySelector('.Polaris-TextField__Input')?.setAttribute('autocomplete', 'off');
	}, []);

	const choices = (
		[
			'faulty',
			...(config?.highPriorityChuckEnabled ? ['priority'] : []),
			'dwellingIdle',
			'charging',
			'lowBattery',
			'offline',
		] as ChuckStatus[]
	).map((key) => {
		let label = messages.chuckStates[key];
		if (key === 'dwellingIdle' && config?.highPriorityChuckEnabled) {
			label = messages.chuckStates.idleInAisle;
		}
		return { label, value: key };
	});

	const filters = [
		{
			key: 'status',
			label: messages.status,
			shortcut: true,
			filter: (
				<ChoiceList
					title={messages.status}
					titleHidden
					allowMultiple
					choices={choices}
					selected={statuses}
					onChange={(values) => onFilter({ ...args, statuses: values as ChuckStatus[] })}
				/>
			),
		},
	];

	const appliedFilters = [];

	if (statuses.length) {
		appliedFilters.push({
			key: 'status',
			label: statuses.map((s) => translate(messages.chuckStates[s], tokens)).join(', '),
			onRemove: () => onFilter({ ...args, statuses: [] }),
		});
	}

	return (
		<Filters
			filters={filters}
			appliedFilters={appliedFilters}
			queryPlaceholder={messages.searchFloorView}
			queryValue={searchText}
			onQueryClear={() => onFilter({ ...args, searchText: '' })}
			onQueryChange={(searchText) => onFilter({ ...args, searchText })}
			onClearAll={() => onFilter({ searchText: '', statuses: [] })}
		/>
	);
}
