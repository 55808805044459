import { IndexTable } from '@shopify/polaris';

import { NoData } from 'components/NoData';

interface DeviceCellProps {
	deviceName?: string;
}

export function DeviceCell({ deviceName }: DeviceCellProps) {
	return <IndexTable.Cell>{deviceName ?? <NoData />}</IndexTable.Cell>;
}
