import { Link, FilterInterface, AppliedFilterInterface, Tooltip, Badge } from '@shopify/polaris';
import { ReplenJobConnection, ReplenJobEdge } from '@sixriver/fulfillment-api-schema';

import {
	DataTable,
	Column,
	PaginationCursors,
	SetPaginationCursors,
	DataTableView,
} from 'components/DataTable';
import { DateTime } from 'components/DateTime';
import { NoData } from 'components/NoData';
import { RelativeDateTime } from 'components/RelativeDateTime';
import { ReplenJobStatusBadge } from 'components/ReplenJobStatusBadge';
import { calculateReplenTasksUnits } from 'helpers/replen';
import { SetFilters } from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';

interface Props {
	loading?: boolean;
	data?: { replenJobs: ReplenJobConnection };
	paginationCursors: PaginationCursors;
	setPaginationCursors: SetPaginationCursors;
	views: DataTableView[];
	query?: string;
	setFilters: SetFilters;
	selectedView?: string;
	sortValue?: string;
	filters: FilterInterface[];
	appliedFilters?: AppliedFilterInterface[];
}

export function ReplenishJobsTable({
	loading = false,
	data,
	paginationCursors,
	setPaginationCursors,
	views,
	query,
	setFilters,
	selectedView,
	filters,
	appliedFilters,
}: Props) {
	const { messages, translate } = useLocalization();

	const { pageInfo, edges = [] } = data?.replenJobs || {};

	const columns: Column[] = [
		{
			type: 'text',
			heading: messages.licensePlate,
		},
		{
			type: 'text',
			heading: messages.createdAt,
		},
		{
			type: 'text',
			heading: messages.updatedAt,
		},
		{
			type: 'text',
			heading: messages.units,
		},
		{
			type: 'text',
			heading: messages.lines,
		},
		{
			type: 'text',
			heading: messages.progress,
		},
		{
			type: 'text',
			heading: messages.exceptions,
		},
		{
			type: 'text',
			heading: messages.device,
		},
	];

	const rows = (edges || ([] as ReplenJobEdge[])).map(({ node }) => {
		const { id, createdAt, updatedAt, mfp, status, containerId, tasks, hasExceptions } = node;

		const { replenished: unitsPicked, total: unitsTotal } = calculateReplenTasksUnits(tasks);
		const doneTasks = (tasks || []).reduce(
			(done, task) => (task.quantity === task.doneQuantity ? (done += 1) : done),
			0,
		);

		const deviceName = mfp?.name;

		return [
			<Link url={routes.replenishJob(id)} key="lpn">
				{containerId}
			</Link>,
			<div key="date-created">
				<DateTime date={createdAt} />
				<RelativeDateTime date={createdAt} />
			</div>,
			<div key="last-updated">
				<DateTime date={updatedAt} />
				<RelativeDateTime date={updatedAt} />
			</div>,
			<div key="units">
				{translate(messages.xOfY, {
					x: unitsPicked,
					y: unitsTotal,
				})}
			</div>,
			<div key="lines">
				{translate(messages.xOfY, {
					x: doneTasks,
					y: tasks.length,
				})}
			</div>,
			<ReplenJobStatusBadge key="progress" replenStatus={status} />,
			hasExceptions ? (
				<Tooltip content={messages.jobExceptionsReported}>
					<Badge status="critical">{messages.exception}</Badge>
				</Tooltip>
			) : (
				<NoData />
			),
			deviceName ? (
				<Link url={`${routes.floorView()}/?chuck=${deviceName}`}>{deviceName}</Link>
			) : (
				<NoData />
			),
		];
	});

	return (
		<DataTable
			loading={loading}
			columns={columns}
			rows={rows}
			emptyStateHeading={messages.noReplenJobsFound}
			pageInfo={pageInfo}
			paginationCursors={paginationCursors}
			setPaginationCursors={setPaginationCursors}
			views={views}
			query={query}
			queryPlaceholder={messages.filterReplenJobs}
			setFilters={setFilters}
			selectedView={selectedView}
			filters={filters}
			appliedFilters={appliedFilters}
		/>
	);
}
