import { Card, Badge, Button } from '@shopify/polaris';
import { Picking, Order, OrderServicedBy } from '@sixriver/fulfillment-api-schema';

import { CardDetails } from 'components/CardDetails';
import { CopyButton } from 'components/CopyButton';
import { ResolutionJobsBanner } from 'components/ResolutionJobsBanner';
import { countLineExceptions } from 'helpers/exception';
import { calculateUnits } from 'helpers/order';
import { useConfig } from 'hooks/useConfig';
import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';

const getParams = (jobs?: Array<OrderServicedBy | Picking | Order>) => {
	const searchParams = new URLSearchParams();

	if (jobs) {
		searchParams.set('projectionIds', jobs.map((job) => job.id).join(' '));
	}

	return `?${searchParams.toString()}`;
};

interface Props {
	loading?: boolean;
	job?: Picking;
	isExceptionActionable?: boolean;
}

export function PickingProgress({ loading, job, isExceptionActionable }: Props) {
	const { messages, translate } = useLocalization();

	const { config } = useConfig();

	const { picked: unitsPicked, total: unitsTotal } = calculateUnits(job?.lines);
	const linesPicked = job?.lines?.reduce(
		(picked, line) => (line.doneQuantity === line.quantity ? (picked += 1) : picked),
		0,
	);

	const numberOfExceptions = countLineExceptions(job?.lines);

	const numberOfOrders = Array.isArray(job?.servicing) ? job?.servicing?.length : undefined;
	const exceptionOrders = (job?.servicing || []).filter(
		(order) => countLineExceptions(order.lines) > 0,
	);

	const servicingOrder = (job?.servicing || []).filter(
		(servicing) => servicing.__typename === 'Order',
	)?.[0] as Order | undefined;
	const servicingOrderId = servicingOrder?.customerIdentifier;

	const resolutionJobs =
		job?.isHealJob === false && numberOfExceptions > 0
			? (servicingOrder?.servicedBy || []).filter(
					(j) => j.__typename === 'Picking' && j.id !== job?.id && j.isHealJob,
			  )
			: [];

	const hasMultipleResolutionJobs = resolutionJobs.length > 1;

	const resolutionJob = resolutionJobs.length > 0 ? resolutionJobs[0] : undefined;

	const originalJob =
		job?.isHealJob === true
			? servicingOrder?.servicedBy?.find(
					(j) => j.__typename === 'Picking' && j.id !== job?.id && countLineExceptions(j.lines) > 0,
			  )
			: undefined;

	const primaryListItems = [
		{
			label: messages.unitsPicked,
			content: translate(messages.pickedOfTotal, {
				picked: unitsPicked,
				total: unitsTotal,
			}),
		},
		{
			label: messages.linesPicked,
			content: job?.lines
				? translate(messages.pickedOfTotal, {
						picked: linesPicked || 0,
						total: job.lines.length,
				  })
				: null,
		},
		{
			label: messages.exceptions,
			content: (
				<Badge status={numberOfExceptions > 0 ? 'critical' : undefined}>
					{numberOfExceptions.toString()}
				</Badge>
			),
		},
		...(hasMultipleResolutionJobs === true
			? [
					{
						label: messages.resolutionJobs,
						content: (
							<Button plain url={routes.outboundJobs() + `${getParams(resolutionJobs || [])}`}>
								{
									translate(messages.countJobs, {
										count: resolutionJobs?.length || 0,
									}) as string
								}
							</Button>
						),
					},
			  ]
			: []),
		...(hasMultipleResolutionJobs === false && resolutionJob?.id
			? [
					{
						label: messages.resolutionJob,
						content: (
							<Button plain url={routes.outboundJob(resolutionJob.id)}>
								{resolutionJob?.id}
							</Button>
						),
					},
			  ]
			: []),
		...(job?.isHealJob && originalJob
			? [
					{
						label: messages.originalJob,
						content: <CopyButton text={originalJob.id} />,
					},
			  ]
			: []),
		...(job?.isHealJob && job?.stagingIdentifier
			? [
					{
						label: messages.pickingLabel,
						content: <CopyButton text={job.stagingIdentifier} />,
					},
			  ]
			: []),
	];

	return (
		<Card
			sectioned
			title={messages.pickingProgress}
			primaryFooterAction={{
				content: translate(messages.viewOrders, { count: numberOfOrders || 0 }) as string,
				disabled: !numberOfOrders,
				url:
					numberOfOrders === 1
						? routes.order(job?.servicing?.[0]?.id)
						: `${routes.orders()}${getParams(job?.servicing)}`,
			}}
			secondaryFooterActions={
				config?.healingEnabled
					? [
							{
								content: messages.manageExceptions,
								disabled: numberOfExceptions === 0 || !isExceptionActionable,
								url:
									exceptionOrders.length === 1
										? routes.exception(exceptionOrders[0].id)
										: `${routes.exceptions()}${getParams(exceptionOrders)}`,
							},
					  ]
					: []
			}
			footerActionAlignment="left"
		>
			{resolutionJobs.length > 0 && <ResolutionJobsBanner jobs={resolutionJobs} />}

			<br />
			<CardDetails
				loading={loading}
				primary={primaryListItems}
				secondary={[
					{
						label: messages.orders,
						content: job?.servicing?.length,
					},
					{
						label: messages.orderId,
						content:
							numberOfOrders !== undefined && numberOfOrders !== null ? (
								numberOfOrders > 1 ? (
									messages.multiple
								) : numberOfOrders === 1 && servicingOrderId ? (
									<CopyButton text={servicingOrderId} />
								) : null
							) : null,
					},
				]}
			/>
		</Card>
	);
}
