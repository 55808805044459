import gql from 'graphql-tag';

// Queries
export const GET_CONTAINER_QUERY = gql`
	query getContainer($id: String!) {
		container(id: $id) {
			id
			externalId
			name
			type
			width
			height
			length
			weight
			columns
			rows
			barcode
			inventory
			enabled
			imageURL
			description
			volume
			instructions
		}
	}
`;

export const GET_CONTAINERS_QUERY = gql`
	query getContainers(
		$types: [ContainerType!]
		$after: String
		$limit: Int
		$searchText: String
		$orderBy: ContainerOrderByFields
		$orderByDirection: OrderByDirection
		$isInventory: Boolean
	) {
		containers(
			types: $types
			after: $after
			limit: $limit
			searchText: $searchText
			orderBy: $orderBy
			orderByDirection: $orderByDirection
			isInventory: $isInventory
		) {
			cursor
			results {
				id
				name
				type
				description
				inventory
				externalId
				length
				width
				height
				weight
				enabled
			}
		}
	}
`;

export const COUNT_CONTAINERS_QUERY = gql`
	query countContainers($searchText: String, $isInventory: Boolean) {
		pickingContainersCount: containersCount(
			searchText: $searchText
			isInventory: $isInventory
			types: [picking]
		) {
			count
		}

		shippingContainersCount: containersCount(
			searchText: $searchText
			isInventory: $isInventory
			types: [shipping]
		) {
			count
		}

		storageContainersCount: containersCount(
			searchText: $searchText
			isInventory: $isInventory
			types: [storage]
		) {
			count
		}
	}
`;

// Mutations
export const CREATE_CONTAINER_MUTATION = gql`
	mutation createContainer($input: AddContainerInput!) {
		createContainer(input: $input) {
			success
			referenceId
		}
	}
`;

export const UPDATE_CONTAINER_MUTATION = gql`
	mutation updateContainer($input: UpdateContainerInput!) {
		updateContainer(input: $input) {
			success
			referenceId
		}
	}
`;

export const DELETE_CONTAINER_MUTATION = gql`
	mutation deleteContainer($id: String!) {
		deleteContainer(id: $id) {
			success
			referenceId
		}
	}
`;

export const ENABLE_CONTAINER_MUTATION = gql`
	mutation enableContainer($id: String!) {
		enableContainer(id: $id) {
			success
			referenceId
		}
	}
`;

export const DISABLE_CONTAINER_MUTATION = gql`
	mutation disableContainer($id: String!) {
		disableContainer(id: $id) {
			success
			referenceId
		}
	}
`;

export const INVENTORY_CONTAINER_MUTATION = gql`
	mutation addContainerToInventory($id: String!) {
		addContainerToInventory(id: $id) {
			success
			referenceId
		}
	}
`;

export const UNINVENTORY_CONTAINER_MUTATION = gql`
	mutation removeContainerFromInventory($id: String!) {
		removeContainerFromInventory(id: $id) {
			success
			referenceId
		}
	}
`;
