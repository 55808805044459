import { IndexTable, Link } from '@shopify/polaris';
import { LineV2 } from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';
import * as routes from 'routes';

interface Props {
	line: LineV2;
}

export function ProductIdCell({ line }: Props): JSX.Element {
	if (line.productId) {
		return (
			<IndexTable.Cell>
				<Link url={routes.product(line.productId)} removeUnderline>
					{line.productCustomerIdentifier}
				</Link>
			</IndexTable.Cell>
		);
	}

	return (
		<IndexTable.Cell>
			<NoData />
		</IndexTable.Cell>
	);
}
