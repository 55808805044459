import { Frame as PolarisFrame, TopBar } from '@shopify/polaris';
import { PropsWithChildren, useCallback, useState } from 'react';

import { Nav } from 'components/Nav';
import { OfflineBanner } from 'components/OfflineBanner';
import { UserMenu } from 'components/UserMenu';

export function Frame({ children }: PropsWithChildren<{}>): JSX.Element {
	const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
	const toggleMobileNavigationActive = useCallback(
		() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive),
		[],
	);

	return (
		<PolarisFrame
			navigation={<Nav />}
			topBar={
				<TopBar
					showNavigationToggle
					onNavigationToggle={toggleMobileNavigationActive}
					userMenu={<UserMenu />}
				/>
			}
			showMobileNavigation={mobileNavigationActive}
			onNavigationDismiss={toggleMobileNavigationActive}
		>
			<OfflineBanner />
			{children}
		</PolarisFrame>
	);
}
