import { OrderV2Connection } from '@sixriver/fulfillment-api-schema';
import gql from 'graphql-tag';

import { PAGE_INFO_FRAGMENT } from 'graphql/page-info';

export interface OrdersData {
	ordersV2: OrderV2Connection;
}

export const ORDERS_QUERY = gql`
	query Orders(
		$after: String
		$first: Int
		$ids: [String!]
		$searchText: String
		$statuses: [OrderV2Status!]
		$isLate: Boolean
		$isShorted: Boolean
		$isAwaitingInventory: Boolean
		$completedAtFrom: DateTime
		$completedAtTo: DateTime
		$expectedShipDateFrom: DateTime
		$expectedShipDateTo: DateTime
		$createdAtFrom: DateTime
		$pickStrategies: [PickStrategy!]
		$orderBy: [JobOrderBy!]
		$workOrderTypes: [WorkOrderType!]
		$workAreaIds: [String!]
	) {
		ordersV2(
			after: $after
			first: $first
			ids: $ids
			searchText: $searchText
			statuses: $statuses
			isLate: $isLate
			isShorted: $isShorted
			isAwaitingInventory: $isAwaitingInventory
			completedAtFrom: $completedAtFrom
			completedAtTo: $completedAtTo
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			createdAtFrom: $createdAtFrom
			pickStrategies: $pickStrategies
			orderBy: $orderBy
			workOrderTypes: $workOrderTypes
			workAreaIds: $workAreaIds
		) {
			edges {
				node {
					id
					stagingIdentifier
					externalId
					pickStrategy
					createdAt
					expectedShipDate
					isLate
					isAwaitingInventory
					isShorted
					status
					lines {
						quantity
						doneQuantity
						customer {
							name
						}
					}
					tasks {
						workArea {
							id
							name
						}
					}
					destinationNode
					workOrderTypes
					isPackoutRequired
					servicedBy {
						id
						pickStrategy
					}
					tag
				}
			}
			pageInfo {
				...PageInfo
			}
		}
	}
	${PAGE_INFO_FRAGMENT}
`;
