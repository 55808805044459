import { Card } from '@shopify/polaris';
import { Lane } from '@sixriver/fulfillment-api-schema';

import { LaneContainerDetailsTitle } from './LaneContainerDetailsTitle';
import { CardDetails } from 'components/CardDetails';
import { DateTime } from 'components/DateTime';
import { LaneCarrierAndLogo } from 'components/LaneCarrierLogo';
import { NoData } from 'components/NoData';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	lane: Lane;
}

export function LaneContainerDetails({ lane }: Props) {
	const { messages } = useLocalization();
	const primary = [];

	primary.push(
		{
			label: messages.carrier,
			content: <LaneCarrierAndLogo carrier={lane.carrier} />,
		},
		{
			label: messages.carrierSort,
			content: lane.carrierSort,
		},
		{
			label: messages.mailClass,
			content: lane.carrierClassMapping ? (
				lane.carrierClassMapping.map((mailClass) => mailClass.mailClasses).join(', ')
			) : (
				<NoData />
			),
		},
		{
			label: messages.packageQty,
			content: lane.containers[0].packages?.length || 0,
		},
		{
			label: messages.packageType,
			content: lane.containers
				.map((container) =>
					Array.from(
						new Set(container.packages?.map((shippingPackage) => shippingPackage.packageType)),
					),
				)
				.join(', '),
		},
		{
			label: messages.timeCreated,
			content: <DateTime date={lane.containers[0].createdAt} />,
		},
	);

	return (
		<Card>
			<LaneContainerDetailsTitle container={lane.containers[0]} />
			<Card.Section>
				<CardDetails primary={primary} secondary={undefined} />
			</Card.Section>
			<Card.Section>
				<CardDetails
					primary={[
						{
							label: messages.vehicleId,
							content: lane.containers[0].carrierPickupDetails?.vehicleScan,
						},
					]}
				/>
			</Card.Section>
		</Card>
	);
}
