import { ChoiceList, FilterInterface, Link } from '@shopify/polaris';
import { Page, Layout } from '@shopify/polaris';
import { useDebouncedValue } from '@shopify/react-hooks';
import {
	Count,
	DeviceConnection,
	DeviceOrderByFields,
	DeviceState,
	DeviceType,
	OrderByDirection,
} from '@sixriver/fulfillment-api-schema';
import { useState } from 'react';

import { DeviceBadge } from './DeviceBadge';
import { DeviceBattery } from './DeviceBattery';
import { DeviceViews } from './DeviceViews';
import { COUNTS_QUERY, DEVICES_QUERY } from './Devices.graphql';
import { AutoRefresh } from 'components/AutoRefresh';
import { DataTable, Column } from 'components/DataTable';
import { DateTime } from 'components/DateTime';
import { Error } from 'components/Error';
import { NoData } from 'components/NoData';
import { TimezoneFooter } from 'components/TimezoneFooter';
import { getPageSize } from 'helpers/page-size';
import { MIN_QUERY_LENGTH } from 'helpers/table';
import { useConfig } from 'hooks/useConfig';
import { useFilters, useSetFilters } from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';
import { usePolling } from 'hooks/usePolling';
import { usePollingQuery } from 'hooks/usePollingQuery';
import { useWorkAreas } from 'hooks/useWorkArea/use-work-areas';
import * as routes from 'routes';

const STATE_FILTER_KEY = 'state';
const WORKAREA_KEY = 'workArea';

export default function Devices(): JSX.Element {
	const { messages } = useLocalization();
	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();
	const { config } = useConfig();
	const { workAreas } = useWorkAreas();
	const enabledDeviceTypes = config?.enabledDeviceTypes || [];

	// Filters
	const defaultView = DeviceViews.All;
	const {
		view = defaultView,
		query,
		sort = DeviceOrderByFields.Name + ' ' + OrderByDirection.Asc,
		[STATE_FILTER_KEY]: stateFilterValue,
		[WORKAREA_KEY]: workArea,
	} = useFilters([STATE_FILTER_KEY, WORKAREA_KEY]);
	const setFilters = useSetFilters();
	const searchText = useDebouncedValue(query) || '';
	const selectedStates = stateFilterValue ? stateFilterValue.split(' ') : undefined;
	const selectedWorkAreas = workArea ? workArea.split(' ') : undefined;

	const filters: FilterInterface[] = [
		{
			key: STATE_FILTER_KEY,
			label: messages.status,
			filter: (
				<ChoiceList
					title={messages.status}
					titleHidden
					choices={[
						{ value: DeviceState.InUse, label: messages.inUse },
						{ value: DeviceState.Idle, label: messages.notInUse },
						{ value: DeviceState.Off, label: messages.disconnected },
					]}
					selected={selectedStates || []}
					onChange={(selected) => {
						setFilters([{ key: STATE_FILTER_KEY, value: selected.join(' ') }]);
					}}
					allowMultiple
				/>
			),
			shortcut: true,
		},
		...(config?.workAreasEnabled
			? [
					{
						key: WORKAREA_KEY,
						label: messages.workAreas,
						filter: (
							<ChoiceList
								title={messages.workAreas}
								titleHidden
								choices={workAreas.map((workArea, i) => ({
									label: workArea.name || `Work area ${i + 1}`,
									value: workArea.id,
								}))}
								selected={selectedWorkAreas || []}
								onChange={(selected) => {
									setFilters([{ key: WORKAREA_KEY, value: selected.join(' ') }]);
								}}
								allowMultiple
							/>
						),
						shortcut: true,
					},
			  ]
			: []),
	];

	const appliedFilters = [
		...(selectedStates
			? [
					{
						key: STATE_FILTER_KEY,
						label: selectedStates
							.map((state) => {
								switch (state) {
									case DeviceState.InUse:
										return messages.inUse;
									case DeviceState.Idle:
										return messages.notInUse;
									case DeviceState.Off:
										return messages.disconnected;
									default:
										return messages.unknown;
								}
							})
							.join(', '),
						onRemove: () => {
							setFilters([{ key: STATE_FILTER_KEY, value: '' }]);
						},
					},
			  ]
			: []),
		...(selectedWorkAreas && selectedWorkAreas.length
			? [
					{
						key: WORKAREA_KEY,
						label: workAreas
							.filter((area) => selectedWorkAreas.includes(area.id))
							.map((area, i) => area.name || `Work area ${i + 1}`)
							.join(', '),
						onRemove: () => {
							setFilters([{ key: WORKAREA_KEY, value: '' }]);
						},
					},
			  ]
			: []),
	];

	// Sorting
	const sortChoices = [
		{
			label: messages.sortOptions.batteryLevelAsc,
			value: DeviceOrderByFields.BatteryLevel + ' ' + OrderByDirection.Asc,
		},
		{
			label: messages.sortOptions.batteryLevelDesc,
			value: DeviceOrderByFields.BatteryLevel + ' ' + OrderByDirection.Desc,
		},
		{
			label: messages.sortOptions.lastUsedAsc,
			value: DeviceOrderByFields.LastUsed + ' ' + OrderByDirection.Asc,
		},
		{
			label: messages.sortOptions.lastUsedDesc,
			value: DeviceOrderByFields.LastUsed + ' ' + OrderByDirection.Desc,
		},
		{
			label: messages.sortOptions.nameAsc,
			value: DeviceOrderByFields.Name + ' ' + OrderByDirection.Asc,
		},
		{
			label: messages.sortOptions.nameDesc,
			value: DeviceOrderByFields.Name + ' ' + OrderByDirection.Desc,
		},
	];

	// Data
	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);
	const [{ fetching: devicesFetching, data: devicesData, error: devicesError }] = usePollingQuery<{
		devices: DeviceConnection;
	}>({
		query: DEVICES_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			types: view === DeviceViews.All ? null : [view as DeviceType],
			searchText: searchText.length >= MIN_QUERY_LENGTH ? searchText : undefined,
			states: selectedStates,
			first: getPageSize(),
			after: paginationCursors[0],
			orderBy: sort ? (sort.split(' ')[0] as DeviceOrderByFields) : undefined,
			orderByDirection: sort ? (sort.split(' ')[1] as OrderByDirection) : undefined,
			workAreaIds: selectedWorkAreas,
		},
	});

	const [{ fetching: countsFetching, data: countsData, error: countsError }] = usePollingQuery<{
		ChuckCount: Count;
		SortKioskCount: Count;
		PackoutCount: Count;
		TerminalOnWheelsCount: Count;
		HandheldCount: Count;
	}>({
		query: COUNTS_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			searchText: searchText.length >= MIN_QUERY_LENGTH ? searchText : undefined,
			states: selectedStates,
			first: getPageSize(),
			after: paginationCursors[0],
			orderBy: sort ? (sort.split(' ')[0] as DeviceOrderByFields) : undefined,
			orderByDirection: sort ? (sort.split(' ')[1] as OrderByDirection) : undefined,
			workAreaIds: selectedWorkAreas,
		},
	});

	const fetching = devicesFetching || countsFetching;
	const error = devicesError || countsError;
	const {
		ChuckCount = { count: 0 },
		SortKioskCount = { count: 0 },
		PackoutCount = { count: 0 },
		TerminalOnWheelsCount = { count: 0 },
		HandheldCount = { count: 0 },
	} = countsData || {};

	// Views
	const views = [
		{
			label: messages.all,
			id: 'All',
		},
		...(enabledDeviceTypes.includes(DeviceType.Chuck)
			? [
					{
						label: messages.chucks,
						metaLabel: ChuckCount.count,
						id: DeviceViews.Chuck,
					},
			  ]
			: []),
		...(enabledDeviceTypes.includes(DeviceType.SortationKiosk)
			? [
					{
						label: messages.sortWallKiosks,
						metaLabel: SortKioskCount.count,
						id: DeviceViews.SortationKiosk,
					},
			  ]
			: []),

		...(enabledDeviceTypes.includes(DeviceType.PackoutTerminal)
			? [
					{
						label: messages.packStations,
						metaLabel: PackoutCount.count,
						id: DeviceViews.PackoutTerminal,
					},
			  ]
			: []),

		...(enabledDeviceTypes.includes(DeviceType.TerminalOnWheels)
			? [
					{
						label: messages.terminalOnWheels,
						metaLabel: TerminalOnWheelsCount.count,
						id: DeviceViews.TerminalOnWheels,
					},
			  ]
			: []),

		...(enabledDeviceTypes.includes(DeviceType.Handheld)
			? [
					{
						label: messages.handhelds,
						metaLabel: HandheldCount.count,
						id: DeviceViews.Handheld,
					},
			  ]
			: []),
	];

	const columns: Column[] = [
		{ type: 'text', heading: messages.name },
		...(config?.workAreasEnabled ? [{ type: 'text' as const, heading: messages.workArea }] : []),
		{ type: 'text', heading: messages.model },
		{ type: 'text', heading: messages.lastActive },
		{ type: 'text', heading: messages.battery },
		{ type: 'text', heading: messages.status },
	];

	const { pageInfo, edges = [] } = devicesData?.devices || {};
	const rows = edges
		.filter((edge) => edge.node.type === view || view === 'All')
		.map((edge) => {
			const device = edge.node;
			const { id } = device;
			const workAreaCell = device.workArea ? <div>{device.workArea?.name}</div> : <NoData />;

			return [
				<Link url={routes.device(device.name || device.id)} key={`name-${id}`} removeUnderline>
					{device.name}
				</Link>,
				...(config?.workAreasEnabled ? [workAreaCell] : []),
				messages.deviceTypes[device.type as keyof typeof messages.deviceTypes] || <NoData />,
				<DateTime key={`lastActive-${id}`} date={device.updatedAt} />,
				<DeviceBattery key={`battery-${id}`} device={device} config={config} />,
				<DeviceBadge key={`state-${id}`} state={device.state} />,
			];
		});

	return error ? (
		<Error graphQLError={error} />
	) : (
		<Page fullWidth title={messages.devices}>
			<Layout>
				<Layout.Section>
					<AutoRefresh
						pollingEnabled={pollingEnabled}
						togglePolling={togglePolling}
						discriminatorData={devicesData?.devices}
					/>
				</Layout.Section>
				<Layout.Section>
					<DataTable
						views={views}
						selectedView={view}
						query={query}
						queryPlaceholder={messages.filterDevices}
						filters={filters}
						sortChoices={sortChoices}
						sortValue={sort}
						setFilters={setFilters}
						appliedFilters={appliedFilters}
						loading={fetching}
						emptyStateHeading={messages.devicesNotFound}
						columns={columns}
						rows={rows}
						pageInfo={pageInfo}
						paginationCursors={paginationCursors}
						setPaginationCursors={setPaginationCursors}
					/>
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</Page>
	);
}
