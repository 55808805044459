import { AppliedFilterInterface, FilterInterface, Filters } from '@shopify/polaris';
import { ContainerStatus } from '@sixriver/fulfillment-api-schema';
import { isEmpty } from 'lodash';

import { ContainerStatusesFilter } from './ContainerStatusesFilter';
import { ExceptionsFilter } from './ExceptionsFilter';
import { noop } from 'helpers/noop';
import { useLocalization } from 'hooks/useLocalization';

const STATUSES_FILTER_KEY = 'statuses';
const EXCEPTIONS_FILTER_KEY = 'exceptions';

interface LaneContainersFiltersProps {
	queryValue?: string;
	onQueryChange?: (value: string) => void;
	onClearAll?: () => void;
	selectedStatuses: ContainerStatus[];
	onStatusChange: (selected: ContainerStatus[]) => void;
	onStatusRemove: () => void;
	selectedExceptions: string[];
	onExceptionChange: (selected: string[]) => void;
	onExceptionRemove: () => void;
}

export function LaneContainersFilters({
	queryValue,
	onQueryChange = noop,
	onClearAll = noop,
	selectedStatuses,
	onStatusChange,
	onStatusRemove,
	selectedExceptions,
	onExceptionChange,
	onExceptionRemove,
}: LaneContainersFiltersProps): JSX.Element {
	const { messages } = useLocalization();

	const filters: FilterInterface[] = [
		{
			key: STATUSES_FILTER_KEY,
			label: messages.containerStatus,
			filter: <ContainerStatusesFilter selected={selectedStatuses} onChange={onStatusChange} />,
			shortcut: true,
		},
		{
			key: EXCEPTIONS_FILTER_KEY,
			label: messages.exceptions,
			filter: <ExceptionsFilter selected={selectedExceptions} onChange={onExceptionChange} />,
			shortcut: true,
		},
	];

	const appliedFilters: AppliedFilterInterface[] = [];

	if (!isEmpty(selectedStatuses)) {
		appliedFilters.push({
			key: STATUSES_FILTER_KEY,
			label: selectedStatuses
				.map((status) => {
					switch (status) {
						case ContainerStatus.Created:
							return messages.containerCreated;
						case ContainerStatus.ReadyToLoad:
							return messages.readyToLoad;
						case ContainerStatus.Shipped:
							return messages.shipped;
						default:
							return messages.unknown;
					}
				})
				.join(', '),
			onRemove: onStatusRemove,
		});
	}

	if (!isEmpty(selectedExceptions)) {
		appliedFilters.push({
			key: EXCEPTIONS_FILTER_KEY,
			label: selectedExceptions.includes(messages.isOverridden)
				? messages.hasExceptions
				: messages.noExceptions,
			onRemove: onExceptionRemove,
		});
	}

	return (
		<div style={{ paddingTop: '1rem', paddingLeft: '1rem', paddingRight: '1rem' }}>
			<Filters
				queryValue={queryValue}
				queryPlaceholder={messages.filterLaneContainers}
				onQueryChange={onQueryChange}
				onQueryClear={() => onQueryChange('')}
				filters={filters}
				appliedFilters={appliedFilters}
				onClearAll={onClearAll}
			></Filters>
		</div>
	);
}
