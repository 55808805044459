import gql from 'graphql-tag';

export const GET_PRODUCT_QUERY = gql`
	query productById($id: String!) {
		product(id: $id) {
			id
			customerIdentifier
			name
			description
			image
			width
			height
			length
			weight
		}
	}
`;

export const EDIT_PRODUCT_MUTATION = gql`
	mutation editProduct($input: ProductInput!) {
		editProduct(input: $input) {
			success
		}
	}
`;

export const DELETE_PRODUCT_MUTATION = gql`
	mutation deleteProduct($id: String!) {
		deleteProduct(id: $id) {
			success
			referenceId
		}
	}
`;
