import {
	EmptySearchResult,
	IndexTableProps,
	IndexTable,
	useIndexResourceState,
} from '@shopify/polaris';
import { OrderV2Edge, WorkArea } from '@sixriver/fulfillment-api-schema';

import { AssociatedOrderCell } from './AssociatedOrderCell';
import { CarrierCutoffCell } from './CarrierCutoffCell';
import { ContainerCell } from './ContainerCell';
import { CreatedAtCell } from './CreatedAtCell';
import { StatusCell } from './StatusCell';
import { TypesCell } from './TypesCell';
import { UnitsPickedCell } from './UnitsPickedCell';
import { WorkAreasCell } from './WorkAreasCell';
import { noop } from 'helpers/noop';
import { useAuth } from 'hooks/useAuth';
import { useConfig } from 'hooks/useConfig';
import { useLocalization } from 'hooks/useLocalization';
import { UserRole } from 'providers/AuthProvider';

enum SelectionType {
	All = 'all',
	Page = 'page',
	Multi = 'multi',
	Single = 'single',
}

export enum ExceptionsAction {
	PrintLabels,
	ManuallyResolve,
}

export interface ExceptionsTableProps {
	loading: boolean;
	data?: OrderV2Edge[];
	selectable?: boolean;
	actions?: ExceptionsAction[];
	onPrintLabels?: (selectedIds: string[]) => void;
	onManuallyResolve?: (selectedIds: string[]) => void;
}

export function ExceptionsTable({
	loading,
	data = [],
	selectable = false,
	actions = [],
	onPrintLabels = noop,
	onManuallyResolve = noop,
}: ExceptionsTableProps) {
	const { config } = useConfig();
	const { messages } = useLocalization();
	const { isUserAllowed } = useAuth();
	const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(
		data,
		{ resourceIDResolver: (edge) => edge.node.id },
	);
	const emptyStateMarkup = (
		<EmptySearchResult title={messages.exceptionsNotFound} withIllustration />
	);
	const resourceName = {
		singular: messages.exception.toLowerCase(),
		plural: messages.exceptions.toLowerCase(),
	};

	const headings: IndexTableProps['headings'] = [
		{ title: messages.order },
		{ title: messages.exceptionStatus },
		...(!config?.healingEnabled ? [{ title: messages.reason }] : []),
		{ title: messages.unitsPicked },
		{ title: messages.createdAt },
		{ title: messages.carrierCutoff },
		...(config?.workAreasEnabled ? [{ title: messages.workArea }] : []),
		{ title: messages.initialLicensePlate },
	] as IndexTableProps['headings'];

	const rows = data.map(({ node: order }, index) => {
		const { id, externalId, lines, createdAt, expectedShipDate, externalContainerId, tasks } =
			order;
		const uniqueWorkAreas = tasks.reduce((workAreas, task) => {
			if (task.workArea) {
				workAreas.add(task.workArea);
			}
			return workAreas;
		}, new Set<WorkArea>());

		return (
			<IndexTable.Row
				id={id}
				key={id}
				position={index}
				selected={allResourcesSelected || selectedResources.includes(id)}
			>
				<AssociatedOrderCell id={id} externalId={externalId} />
				<StatusCell order={order} />
				<TypesCell lines={lines} />
				<UnitsPickedCell lines={lines} />
				<CreatedAtCell createdAt={createdAt} />
				<CarrierCutoffCell expectedShipDate={expectedShipDate} />
				{config?.workAreasEnabled ? (
					<WorkAreasCell workAreas={Array.from(uniqueWorkAreas)} />
				) : null}
				<ContainerCell externalContainerId={externalContainerId} />
			</IndexTable.Row>
		);
	});

	const promotedBulkActions: IndexTableProps['promotedBulkActions'] = actions
		.map((action) => {
			switch (action) {
				case ExceptionsAction.PrintLabels:
					return {
						content: messages.printLabel,
						onAction: () => {
							onPrintLabels(selectedResources);
							handleSelectionChange(SelectionType.All, false);
						},
						disabled: !isUserAllowed([UserRole.Admin, UserRole.WarehouseManager]),
					};
				case ExceptionsAction.ManuallyResolve:
					return {
						content: messages.markResolved,
						onAction: () => {
							onManuallyResolve(selectedResources);
						},
						disabled: !isUserAllowed([UserRole.Admin, UserRole.WarehouseManager]),
					};
				default:
					return undefined;
			}
		})
		.filter((action): action is any => !!action);

	return (
		<IndexTable
			emptyState={emptyStateMarkup}
			resourceName={resourceName}
			headings={headings}
			hasMoreItems={false}
			itemCount={data.length}
			loading={loading}
			selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
			onSelectionChange={handleSelectionChange}
			promotedBulkActions={promotedBulkActions}
			selectable={selectable}
		>
			{rows}
		</IndexTable>
	);
}
