import { Link, TextStyle } from '@shopify/polaris';
import { Device, DeviceType, Packout, Picking, Sortation } from '@sixriver/fulfillment-api-schema';

import * as routes from 'routes';

interface RelatedJobsProps {
	device: Device;
}

export default function RelatedJobs({ device }: RelatedJobsProps): JSX.Element {
	const pickingJobs = getPickingJobs(device);
	const projectionIds = pickingJobs.map((pickingJob: Picking) => pickingJob.id);
	const params = new URLSearchParams();
	params.set('projectionIds', projectionIds.join(' '));
	const url = `${routes.outboundJobs()}?${params.toString()}`;
	const label = `${projectionIds.length}`;

	if (pickingJobs.length > 0) {
		return (
			<Link url={url} removeUnderline>
				{label}
			</Link>
		);
	}

	return <TextStyle variation="subdued">{label}</TextStyle>;
}

function getPickingJobs(device: Device): Picking[] {
	if (device.type === DeviceType.Chuck) {
		return device.pickingJobs;
	}

	if (device.type === DeviceType.PackoutTerminal) {
		return device.packouts.map((packout: Packout) => packout.pickingJobs).flat(1);
	}

	if (device.type === DeviceType.SortationKiosk) {
		return device.sortations.map((sortation: Sortation) => sortation.pickingJobs || []).flat(1);
	}

	return [];
}
