import { Stack, TextStyle } from '@shopify/polaris';

import aircanada from 'images/laneCarriers/aircanada.svg';
import canadapost from 'images/laneCarriers/canadapost.svg';
import coyote from 'images/laneCarriers/coyote.svg';
import dhl from 'images/laneCarriers/dhl.svg';
import dhlexpress from 'images/laneCarriers/dhlexpress.svg';
import fedex from 'images/laneCarriers/fedex.svg';
import ontrac from 'images/laneCarriers/ontrac.svg';
import tnt from 'images/laneCarriers/tnt.svg';
import ups from 'images/laneCarriers/ups.svg';
import usps from 'images/laneCarriers/usps.svg';

interface LaneCarrierCellProps {
	carrier: string;
}

enum Carrier {
	Fedex = 'fedex',
	UPS = 'ups',
	DHL = 'dhl',
	DHLEXPRESS = 'dhl express',
	USPS = 'usps',
	TNT = 'tnt',
	COYOTE = 'coyote logistics',
	CANADAPOST = 'canada post',
	ONTRAC = 'on trac',
	AIRCANADA = 'air canada',
}
export function LaneCarrierAndLogo({ carrier }: LaneCarrierCellProps) {
	return Object.values(Carrier).includes(carrier.toLowerCase() as unknown as Carrier) ? (
		<Stack vertical={false} spacing="baseTight" alignment="center">
			<Stack.Item>
				<img src={carrierToLogo(carrier)} width="auto" height="30" alt={'carrier'} />
			</Stack.Item>
			<Stack.Item>{carrier}</Stack.Item>
		</Stack>
	) : (
		<TextStyle>{carrier}</TextStyle>
	);
}

export function carrierToLogo(carrier: string) {
	switch (carrier.toLowerCase()) {
		case Carrier.Fedex:
			return fedex;
		case Carrier.UPS:
			return ups;
		case Carrier.DHL:
			return dhl;
		case Carrier.DHLEXPRESS:
			return dhlexpress;
		case Carrier.USPS:
			return usps;
		case Carrier.TNT:
			return tnt;
		case Carrier.COYOTE:
			return coyote;
		case Carrier.CANADAPOST:
			return canadapost;
		case Carrier.ONTRAC:
			return ontrac;
		case Carrier.AIRCANADA:
			return aircanada;
		default:
			return undefined;
	}
}
