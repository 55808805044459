import { Link, TextStyle } from '@shopify/polaris';
import {
	SortationConnection,
	SortationEdge,
	SortationStatus,
} from '@sixriver/fulfillment-api-schema';

import {
	DataTable,
	Column,
	PaginationCursors,
	SetPaginationCursors,
	DataTableView,
} from 'components/DataTable';
import { DateTime } from 'components/DateTime';
import { NoData } from 'components/NoData';
import { RelativeDateTime } from 'components/RelativeDateTime';
import { SortationStatusBadge } from 'components/SortationStatusBadge';
import { countLineExceptions, countSortationPickingExceptions } from 'helpers/exception';
import { calculateUnits } from 'helpers/order';
import { SetFilters } from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';

interface Props {
	loading?: boolean;
	data?: { sortations: SortationConnection };
	paginationCursors: PaginationCursors;
	setPaginationCursors: SetPaginationCursors;
	views: DataTableView[];
	query?: string;
	setFilters: SetFilters;
	selectedView?: string;
}

export function SortWallsTable({
	loading = false,
	data,
	paginationCursors,
	setPaginationCursors,
	views,
	query,
	setFilters,
	selectedView,
}: Props) {
	const { messages, translate } = useLocalization();

	const { pageInfo, edges = [] } = data?.sortations || {};

	const columns: Column[] = [
		{
			type: 'text',
			heading: messages.wallId,
		},
		{
			type: 'text',
			heading: messages.batchId,
		},
		{
			type: 'text',
			heading: messages.createdAt,
		},
		{
			type: 'text',
			heading: messages.earliestCutoff,
		},
		{
			type: 'text',
			heading: messages.progress,
		},
		{
			type: 'text',
			heading: messages.units,
		},
		{
			type: 'text',
			heading: messages.lines,
		},
		{
			type: 'text',
			heading: messages.exceptions,
		},
	];

	const rows = (edges || ([] as SortationEdge[])).map(({ node }) => {
		const {
			jobId,
			wall,
			batchId,
			expectedShipDate,
			timeline: { createdAt },
			lines,
			pickingJobs,
			status,
		} = node;

		const numberOfLineExceptions = countLineExceptions(lines);
		const numberOfPickingExceptions = countSortationPickingExceptions(pickingJobs);
		const numberOfExceptions = numberOfLineExceptions + numberOfPickingExceptions;
		const numberOfUnits = calculateUnits(lines).total;

		return [
			wall ? (
				<Link url={routes.sortWall(jobId)} key="wallId">
					{wall}
				</Link>
			) : (
				<NoData />
			),
			<div key="batchId">{batchId}</div>,
			<div key="date-created">
				<DateTime date={createdAt} />
				<RelativeDateTime date={createdAt} />
			</div>,
			<div key="cutoff">
				<DateTime date={expectedShipDate} />
				<RelativeDateTime
					date={expectedShipDate}
					mode={status === SortationStatus.Sorted ? undefined : 'deadline'}
				/>
			</div>,
			<SortationStatusBadge key="progress" sortationStatus={status} />,
			<div key="units">{numberOfUnits}</div>,
			<div key="lines">{lines?.length ?? 0}</div>,
			numberOfExceptions === 0 ? (
				<NoData />
			) : (
				<TextStyle variation="negative">
					{translate(messages.countExceptions, {
						count: numberOfExceptions,
					})}
				</TextStyle>
			),
		];
	});

	return (
		<DataTable
			loading={loading}
			columns={columns}
			rows={rows}
			emptyStateHeading={messages.noSortWallsFound}
			pageInfo={pageInfo}
			paginationCursors={paginationCursors}
			setPaginationCursors={setPaginationCursors}
			views={views}
			query={query}
			queryPlaceholder={messages.filterSortWalls}
			setFilters={setFilters}
			selectedView={selectedView}
		/>
	);
}
