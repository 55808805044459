import gql from 'graphql-tag';

export const CANCEL_ORDERS_MUTATION = gql`
	mutation CancelOrders(
		$searchText: String
		$statuses: [OrderV2Status!]
		$isLate: Boolean
		$isShorted: Boolean
		$isAwaitingInventory: Boolean
		$completedAtFrom: DateTime
		$completedAtTo: DateTime
		$expectedShipDateFrom: DateTime
		$expectedShipDateTo: DateTime
		$createdAtFrom: DateTime
		$pickStrategies: [PickStrategy!]
		$ids: [String!]
	) {
		cancelOrdersV2(
			searchText: $searchText
			statuses: $statuses
			isLate: $isLate
			isShorted: $isShorted
			isAwaitingInventory: $isAwaitingInventory
			completedAtFrom: $completedAtFrom
			completedAtTo: $completedAtTo
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			createdAtFrom: $createdAtFrom
			pickStrategies: $pickStrategies
			ids: $ids
		) {
			success
			referenceId
		}
	}
`;
