import { IndexTable } from '@shopify/polaris';
import { LineV2 } from '@sixriver/fulfillment-api-schema';

import { ProgressBadge } from './ProgressBadge';

interface Props {
	line: LineV2;
}

export function ProgressCell({ line }: Props): JSX.Element {
	return (
		<IndexTable.Cell>
			<ProgressBadge line={line} />
		</IndexTable.Cell>
	);
}
