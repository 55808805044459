import { Link, TextContainer, TextStyle } from '@shopify/polaris';
import { InboundLine, ShippingNoticeStatus } from '@sixriver/fulfillment-api-schema';

import { InboundLineStatusBadge } from './InboundLineStatusBadge';
import { DataTable, Column } from 'components/DataTable';
import { DateTime } from 'components/DateTime';
import { NoData } from 'components/NoData';
import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';

interface Props {
	inboundLines?: InboundLine[];
	asnStatus?: ShippingNoticeStatus;
}

export function Lines({ inboundLines, asnStatus }: Props) {
	const { messages } = useLocalization();

	// Columns
	const columns: Column[] = [
		{
			type: 'text',
			heading: messages.item,
		},
		{
			type: 'text',
			heading: messages.status,
		},
		{
			type: 'text',
			heading: messages.expected,
		},
		{
			type: 'text',
			heading: messages.received,
		},
		{
			type: 'text',
			heading: messages.damaged,
		},
		{
			type: 'text',
			heading: messages.sellable,
		},
		{
			type: 'text',
			heading: messages.issues,
		},
		{
			type: 'text',
			heading: messages.inspection,
		},
	];

	// Rows
	const rows = (inboundLines || []).map((line) => {
		const receivedQuantity = line.damagedQuantity + line.sellableQuantity;

		const uniqueIssues = Array.from(new Set(line.issues.map((issue) => issue.reason)));

		return [
			// item
			line.product?.id ? (
				<div key="product-id">
					<Link url={routes.product(line.product.id)} removeUnderline>
						{line.product.name}
					</Link>
					<p>{line.product.customerIdentifier}</p>
				</div>
			) : (
				<NoData />
			),

			// status
			<div key="status">
				<InboundLineStatusBadge
					inboundLineStatus={line.status}
					asnStatus={asnStatus}
					receivedQuantity={receivedQuantity}
				/>
			</div>,

			// units
			<TextContainer key="expected-quantity">
				<TextStyle>{line.expectedQuantity}</TextStyle>
			</TextContainer>,

			// received qty
			<div key="received-quantity">
				{line.status === 'pending' ? (
					<NoData />
				) : (
					<>
						<TextContainer>
							<p>{receivedQuantity}</p>
						</TextContainer>
						<TextContainer>
							{receivedQuantity < line.expectedQuantity ? (
								<p style={{ color: '#916A00' }}>-{line.expectedQuantity - receivedQuantity}</p>
							) : receivedQuantity - line.expectedQuantity === 0 ? null : (
								<p style={{ color: '#347C84' }}>+{receivedQuantity - line.expectedQuantity}</p>
							)}
						</TextContainer>
					</>
				)}
			</div>,

			// damaged qty
			<TextContainer key="damaged-quantity">
				{line.status === 'pending' ? <NoData /> : <TextStyle>{line.damagedQuantity}</TextStyle>}
			</TextContainer>,

			// sellable qty
			<TextContainer key="sellable-quantity">
				{line.status === 'pending' ? <NoData /> : <TextStyle>{line.sellableQuantity}</TextStyle>}
			</TextContainer>,

			// issues
			line.issues?.length > 0 ? (
				<>
					{uniqueIssues.map((issue, key) => (
						<TextContainer key={key}>
							<p style={{ color: '#D72C0D' }}>{messages.receivingIssueReason[issue || 'Other']}</p>
						</TextContainer>
					))}
				</>
			) : (
				<NoData />
			),

			// inspection
			<div key="needs-inspection">
				{line.lastInspected ? (
					<DateTime date={line.lastInspected} />
				) : line.needsInspection ? (
					messages.required
				) : (
					<NoData />
				)}
			</div>,
		];
	});

	// Render
	return (
		<DataTable
			title={messages.lines}
			loading={!inboundLines}
			columns={columns}
			rows={rows}
			emptyStateHeading={messages.linesNotFound}
		/>
	);
}
