import { IndexTable } from '@shopify/polaris';
import { OrderStatus } from '@sixriver/fulfillment-api-schema';

import { OrderStatusBadge } from 'components/OrderStatusBadge';

interface LaneStatusCellProps {
	status?: OrderStatus;
}

export function ProgressCell({ status }: LaneStatusCellProps) {
	return (
		<IndexTable.Cell>
			<OrderStatusBadge orderStatus={status} />
		</IndexTable.Cell>
	);
}
