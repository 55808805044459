import gql from 'graphql-tag';

export const ACTION_LOG_QUERY = gql`
	query ActionLog($updatedAtFrom: DateTime!, $cursor: String, $limit: Int) {
		actionLog(updatedAtFrom: $updatedAtFrom, cursor: $cursor, limit: $limit) {
			cursor
			results {
				id
				actionType
				status
				lineStatusSummary {
					success
					containerNotFound
					containerAlreadyCancelled
					containerAlreadyInducted
					containerAlreadyComplete
					unknownError
				}
				createdAt
				startedAt
				completedAt
				user {
					name
				}
			}
		}
	}
`;
