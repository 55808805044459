import { IndexTable, TextStyle } from '@shopify/polaris';
import { LaneContainer } from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';

interface Props {
	container: LaneContainer;
}

export function VehicleIdCell({ container }: Props): JSX.Element {
	if (container.carrierPickupDetails?.vehicleScan) {
		return (
			<IndexTable.Cell>
				<TextStyle>{container.carrierPickupDetails.vehicleScan}</TextStyle>
			</IndexTable.Cell>
		);
	}

	return (
		<IndexTable.Cell>
			<NoData />
		</IndexTable.Cell>
	);
}
