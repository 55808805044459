import { Card, Badge, Link } from '@shopify/polaris';
import { Order, Picking, PickStrategy } from '@sixriver/fulfillment-api-schema';

import { CardDetails } from 'components/CardDetails';
import { OrderQuantity } from 'components/OrderQuantity';
import { OrderTotes } from 'components/OrderTotes';
import { ResolutionJobsBanner } from 'components/ResolutionJobsBanner';
import { countLineExceptions, getExceptionLines } from 'helpers/exception';
import { calculateUnits } from 'helpers/order';
import { getPickingJobs } from 'helpers/order';
import { useLocalization } from 'hooks/useLocalization';
import { usePickStrategyName } from 'hooks/usePickStrategies';
import * as routes from 'routes';

const getParams = (jobs?: Picking[]) => {
	const searchParams = new URLSearchParams();

	if (jobs) {
		searchParams.set('projectionIds', jobs.map((job) => job.id).join(' '));
	}

	return `?${searchParams.toString()}`;
};

interface Props {
	loading?: boolean;
	order?: Order;
	isHealingEnabled?: boolean;
	isExceptionActionable?: boolean;
}

export function Progress({
	loading = false,
	order,
	isHealingEnabled,
	isExceptionActionable,
}: Props) {
	const { messages, translate } = useLocalization();

	const numberOfExceptions = countLineExceptions(getExceptionLines(order));
	const units = calculateUnits(order?.lines);

	const getPickStrategyName = usePickStrategyName();

	const resolutionJobs = (order?.servicedBy || []).filter(
		(job) => job.__typename === 'Picking' && job.isHealJob,
	);

	const primaryAction = {
		content: messages.trackJobs,
		url: `${routes.outboundJobs()}${getParams(getPickingJobs(order?.servicedBy))}`,
		disabled: !(order?.servicedBy || []).filter(
			(servicedBy) => servicedBy.__typename !== 'Sortation',
		).length,
	};

	const secondaryActions = isHealingEnabled
		? [
				{
					content: messages.manageExceptions,
					url: routes.exception(order?.id),
					disabled: !numberOfExceptions || !isExceptionActionable,
				},
		  ]
		: undefined;

	return (
		<Card
			title={messages.progress}
			footerActionAlignment="left"
			primaryFooterAction={primaryAction}
			secondaryFooterActions={secondaryActions}
			sectioned
		>
			{resolutionJobs.length > 0 && <ResolutionJobsBanner jobs={resolutionJobs} />}
			<CardDetails
				loading={loading}
				primary={[
					{
						label: messages.pickStrategy,
						content: order?.type ? getPickStrategyName(order.type) : null,
					},
					...(order?.type === PickStrategy.Bulk && order.bulkOrderProjectionId
						? [
								{
									label: messages.batchId,
									content: (
										<Link
											url={routes.bulkOrder(order?.bulkOrderProjectionId as string | undefined)}
											removeUnderline
										>
											{order?.jobFlowRuleLpn}
										</Link>
									),
								},
						  ]
						: []),
					{
						label: messages.quantity,
						content: <OrderQuantity lines={order?.lines} />,
					},
					{
						label: messages.totes,
						content: <OrderTotes order={order} />,
					},
					{
						label: messages.exceptions,
						content: (
							<Badge status={numberOfExceptions > 0 ? 'critical' : undefined}>
								{numberOfExceptions.toString()}
							</Badge>
						),
					},
				]}
				secondary={[
					{
						label: messages.unassigned,
						content: translate(messages.countUnits, {
							count: units.unassigned,
						}),
					},
					{
						label: messages.inProgress,
						content: translate(messages.countUnits, {
							count: units.inProgress,
						}),
					},
					{
						label: messages.completed,
						content: translate(messages.countUnits, {
							count: units.picked,
						}),
					},
				]}
			/>
		</Card>
	);
}
