import gql from 'graphql-tag';

import { PAGE_INFO_FRAGMENT } from 'graphql/page-info';

export const LINES_QUERY = gql`
	query GetLines(
		$id: String!
		$view: LinesViews
		$searchText: String
		$after: String
		$first: Int
	) {
		jobsLinesV2(id: $id, view: $view, searchText: $searchText, after: $after, first: $first) {
			edges {
				node {
					id
					jobId
					status
					quantity
					doneQuantity
					completedAt
					isShorted
					tasks {
						type
						sourceLocationId
						sourceLocationAddress
						user {
							name
							email
							badge
						}
						isUserDirectedProduct
						isUserDirectedSourceLocation
						isShorted
						quantity
						doneQuantity
					}
					productId
					productCustomerIdentifier
					productDescription
				}
			}
			pageInfo {
				...PageInfo
			}
		}
	}
	${PAGE_INFO_FRAGMENT}
`;
