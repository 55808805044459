import { TextStyle, Stack, ProgressBar, Icon, Tooltip } from '@shopify/polaris';
import { CircleAlertMajor } from '@shopify/polaris-icons';
import { GoalStates, KittingProject as KittingProjectApi } from '@sixriver/fulfillment-api-schema';

import { Issues } from './Issues';
import { Product } from './Product';
import { Column, DataTable } from 'components/DataTable';
import { useLocalization } from 'hooks/useLocalization';

interface Props {
	loading?: boolean;
	kit: KittingProjectApi;
}

export function KittingProject({ loading, kit }: Props) {
	const { messages } = useLocalization();

	const kitColumns: Column[] = [
		{ type: 'text', heading: messages.kit },
		{ type: 'text', heading: messages.kitsRequired },
		{ type: 'text', heading: messages.remaining },
		{ type: 'text', heading: messages.completed },
	];
	const componentColumns: Column[] = [
		{ type: 'text', heading: messages.parts },
		{ type: 'text', heading: messages.totalRequired },
		{ type: 'text', heading: messages.used },
		{ type: 'text', heading: <IssuesInfo /> },
	];

	const kitRows = kit
		? [
				[
					<Product
						key={`${kit.id}-kit`}
						product={kit.product}
						showFai={kit.status === GoalStates.Running}
					/>,
					kit.quantityRequested,
					<Remaining key={`${kit.id}-remaining`} kit={kit} />,
					<Completed key={`${kit.id}-completed`} kit={kit} />,
				],
		  ]
		: [];

	const componentRows =
		kit?.componentProducts.map((component) => {
			return [
				<Product key={`${component.id}-part`} product={component} showUnits />,
				component.totalRequired,
				component.quantityUsed,
				<Issues key={`${component.id}-issues`} componentProduct={component} />,
			];
		}) || [];

	return (
		<>
			<DataTable loading={loading} title={messages.products} columns={kitColumns} rows={kitRows} />
			<DataTable loading={loading} columns={componentColumns} rows={componentRows} />
		</>
	);
}

function Remaining({ kit }: Props): JSX.Element {
	const completedKits = kit?.quantity || 0;

	return <TextStyle>{kit.quantityRequested - completedKits}</TextStyle>;
}

function Completed({ kit }: Props): JSX.Element {
	const { messages, translate } = useLocalization();
	const completedKits = kit?.quantity || 0;

	return (
		<Stack vertical={true} spacing="extraTight">
			<ProgressBar progress={(completedKits / kit.quantityRequested) * 100} color="success" />
			<TextStyle>
				{translate(messages.xOfY, { x: kit.quantity, y: kit.quantityRequested })}
			</TextStyle>
		</Stack>
	);
}

function IssuesInfo(): JSX.Element {
	const { messages } = useLocalization();

	return (
		<Stack>
			<TextStyle>{messages.issues}</TextStyle>
			<Tooltip active={false} content={messages.reportKittingIssues}>
				<Icon source={CircleAlertMajor} color="base" />
			</Tooltip>
		</Stack>
	);
}
