import { MapStack } from '@sixriver/map-io';

export enum FeatureType {
	Aisle = 'Aisle',
	Induct = 'Induct',
	Meeting = 'Meeting',
	Charge = 'Charge',
	Takeoff = 'Takeoff',
	Postpick = 'Postpick',
	Other = 'Other',
}

export type FeatureTypeMap = Map<string, FeatureType>;

/**
 * This function maps a feature name ("Adam's Induct") to a feature type ("Induct").
 */
export function mapFeatureTypes(map: MapStack): FeatureTypeMap {
	const features = new Map();

	map.workflowPoints.features.forEach((point: any) => {
		features.set(point.properties.name, inferFeatureType(point.properties.labels));
	});

	map.aisles.features.forEach((aisle: any) => {
		features.set(aisle.properties.name, FeatureType.Aisle);
	});

	return features;
}

/**
 * The map stack doesn't classify workflow points, but we can use labels for this purpose.
 */
function inferFeatureType(labels: string[]): FeatureType {
	if (labels.includes('induct') || labels.includes('restage') || labels.includes('replenInduct')) {
		return FeatureType.Induct;
	}

	if (labels.includes('picking') || labels.includes('repick') || labels.includes('replen')) {
		return FeatureType.Meeting;
	}

	if (labels.includes('charge')) {
		return FeatureType.Charge;
	}

	if (
		labels.includes('takeoff') ||
		labels.includes('regular') ||
		labels.includes('exception') ||
		labels.includes('replenTakeoff')
	) {
		return FeatureType.Takeoff;
	}

	if (labels.includes('postpick')) {
		return FeatureType.Postpick;
	}

	return FeatureType.Other;
}
