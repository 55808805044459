import { Icon, TextStyle } from '@shopify/polaris';
import { AlertMinor } from '@shopify/polaris-icons';

import styles from './IssueCount.module.css';

export function IssueCount({ count }: { count: number }) {
	return count === 0 ? (
		<TextStyle variation="strong">(0)</TextStyle>
	) : (
		<div className={styles.iconBadgeContainer}>
			<Icon source={AlertMinor} color="critical" />
			{count}
		</div>
	);
}
