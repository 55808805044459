import {
	FilterInterface,
	AppliedFilterInterface,
	ChoiceList,
	Link,
	Icon,
	Stack,
	Tooltip,
} from '@shopify/polaris';
import { DiamondAlertMajor } from '@shopify/polaris-icons';
import {
	OrderByDirection,
	StorageLocationSummaryPage,
	StorageLocationOrderByFields,
	StorageLocationTypeCount,
	StorageLocationSummary,
	WorkArea,
	InventoryTag,
	StorageLocationType,
} from '@sixriver/fulfillment-api-schema';
import { useCallback } from 'react';

import { useLocationsTableFilters } from './use-locations-table-filters';
import { Column, DataTable, PaginationCursors, SetPaginationCursors } from 'components/DataTable';
import { FilterPicker } from 'components/FilterPicker';
import { LocationPeek } from 'components/LocationPeek';
import { NoData } from 'components/NoData';
import { boolStringToTriStateBool } from 'helpers/boolean';
import { getSummaryFullness /* , isPickableType */ } from 'helpers/location';
import { useConfig } from 'hooks/useConfig';
import { useFilters } from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';
import { useLocationTabs } from 'hooks/useLocationTabs';
import * as routes from 'routes';

interface LocationsTableProps {
	data?: StorageLocationSummaryPage;
	counts?: StorageLocationTypeCount[];
	loading?: boolean;
	paginationCursors: PaginationCursors;
	setPaginationCursors: SetPaginationCursors;
	isInventoryEnabled: boolean;
	workAreas: WorkArea[];
}

function conditionalArrayItem<T>(include: boolean, item: T) {
	return include ? [item] : [];
}

export function LocationsTable({
	isInventoryEnabled,
	data,
	loading,
	counts = [],
	paginationCursors,
	setPaginationCursors,
	workAreas,
}: LocationsTableProps) {
	const { config } = useConfig();
	const { messages, formatNumber, formatPercent } = useLocalization();

	// Tabs
	const tabs = useLocationTabs(counts);

	// Filters
	const { filters, keys, setLocationTableFilter, setFilters } = useLocationsTableFilters();
	const { workArea } = useFilters(['workArea']);
	const selectedWorkAreas = workArea ? workArea.split(' ') : undefined;
	const isSlottedFilterParam = boolStringToTriStateBool(filters.isSlotted);
	const selectedLocationCondition = filters.locationCondition
		? filters.locationCondition?.split(',')
		: [];

	// Filter Handlers
	const alterConditionSet = useCallback(
		(newConditions: string[]) => {
			const values = newConditions.join(',');
			setLocationTableFilter(keys.LOCATION_CONDITION_KEY, values);
		},
		[keys, setLocationTableFilter],
	);

	const handleConditionRemoval = useCallback(() => {
		alterConditionSet([]);
	}, [alterConditionSet]);

	const handleConditionChange = useCallback(
		(newValue: string[]) => {
			alterConditionSet(newValue);
		},
		[alterConditionSet],
	);

	const locationConditionChoices = [
		{
			label: messages.inventoryStateNames.damaged,
			value: messages.inventoryStateNames.damaged,
		},
		{
			label: messages.inventoryStateNames.reworkPending,
			value: messages.inventoryStateNames.reworkPending,
		},
		{
			label: messages.inventoryStateNames.sellable,
			value: messages.inventoryStateNames.sellable,
		},
	];

	const filtersMarkup: FilterInterface[] = [
		{
			key: keys.IS_CONFLICTED_KEY,
			label: messages.conflicts,
			filter: (
				<ChoiceList
					title=""
					titleHidden
					selected={[filters.isConflicted || 'none']}
					choices={[
						{
							label: messages.suspectedDiscrepancies,
							value: 'true',
						},
					]}
					onChange={(selected) => setLocationTableFilter('isConflicted', selected[0])}
				/>
			),
			shortcut: true,
		},

		// Location condition
		{
			key: keys.LOCATION_CONDITION_KEY,
			label: messages.locationCondition,
			filter: (
				<FilterPicker
					availableChoices={locationConditionChoices}
					selectedChoices={selectedLocationCondition}
					onChange={handleConditionChange}
				/>
			),
			shortcut: true,
		},

		// In use (formerly Status)
		{
			key: keys.IS_SLOTTED_KEY,
			label: messages.inUse,
			filter: (
				<ChoiceList
					title=""
					titleHidden
					selected={[filters.isSlotted || 'none']}
					choices={[
						{
							label: messages.yes,
							value: true.toString(),
						},
						{
							label: messages.no,
							value: false.toString(),
						},
					]}
					onChange={(selected) => setLocationTableFilter('isSlotted', selected[0])}
				/>
			),
			shortcut: true,
		},

		// Nesting
		{
			key: keys.NESTED_KEY,
			label: 'Nesting',
			filter: (
				<ChoiceList
					title=""
					titleHidden
					selected={['none']}
					choices={[
						{ label: 'All locations', value: '0' },
						{ label: 'Only locations with nested containers', value: '1' },
						{ label: 'Only locations without nested containers', value: '1' },
					]}
				/>
			),
			shortcut: true,
		},
		// Fullness
		{
			key: keys.FULLNESS_KEY,
			label: messages.fullness,
			filter: (
				<ChoiceList
					title=""
					titleHidden
					selected={[filters.fullness || 'none']}
					choices={[
						{ label: messages.fullnessTypes.empty, value: '0' },
						{
							label: messages.fullnessTypes.lessThanTenPercentFull,
							value: '0.10',
						},
						{
							label: messages.fullnessTypes.lessThanFiftyPercentFull,
							value: '0.50',
						},
						{
							label: messages.fullnessTypes.full,
							value: '1',
						},
					]}
					onChange={(selected) => setLocationTableFilter('fullness', selected[0])}
				/>
			),
			shortcut: true,
		},
	].filter((filterInterface) => {
		// TODO: remove this restriction when LoopBack security hole is patched and new query args are available for filtering of the DataTable rows
		return ![messages.locationCondition, 'Nesting'].includes(filterInterface.label);
	});

	const appliedFilters: AppliedFilterInterface[] = [
		...conditionalArrayItem(filters.isConflicted !== undefined, {
			key: keys.IS_CONFLICTED_KEY,
			label: messages.suspectedDiscrepancies,
			onRemove: () => setLocationTableFilter(keys.IS_CONFLICTED_KEY, ''),
		}),
		...conditionalArrayItem(filters.fullness !== undefined, {
			key: keys.FULLNESS_KEY,
			label:
				filters.fullness === '1'
					? messages.fullnessTypes.full
					: filters.fullness === '0'
					? messages.fullnessTypes.empty
					: filters.fullness === '0.10'
					? messages.fullnessTypes.lessThanTenPercentFull
					: messages.fullnessTypes.lessThanFiftyPercentFull,
			onRemove: () => setLocationTableFilter(keys.FULLNESS_KEY, ''),
		}),
		...conditionalArrayItem(filters.locationCondition !== undefined, {
			key: keys.LOCATION_CONDITION_KEY,
			label: `${filters.locationCondition}`,
			onRemove: () => handleConditionRemoval(),
		}),
		...conditionalArrayItem(filters.isSlotted !== undefined, {
			key: keys.IS_SLOTTED_KEY,
			label:
				isSlottedFilterParam === true
					? messages.inUse
					: isSlottedFilterParam === false
					? messages.available
					: '',
			onRemove: () => setLocationTableFilter(keys.IS_SLOTTED_KEY, ''),
		}),
		...(selectedWorkAreas && selectedWorkAreas.length
			? [
					{
						key: 'workArea',
						label: workAreas
							.filter((area) => selectedWorkAreas.includes(area.id))
							.map((area, i) => area.name || `Work area ${i + 1}`)
							.join(', '),
						onRemove: () => {
							setFilters([{ key: 'workArea', value: '' }]);
						},
					},
			  ]
			: []),
	];

	// Sorting
	const sortChoices = [
		{
			label: messages.sortOptions.addressAsc,
			value: `${StorageLocationOrderByFields.Address} ${OrderByDirection.Asc}`,
		},
		{
			label: messages.sortOptions.addressDesc,
			value: `${StorageLocationOrderByFields.Address} ${OrderByDirection.Desc}`,
		},
		{
			label: messages.sortOptions.quantityAsc,
			value: `${StorageLocationOrderByFields.LiveQuantity} ${OrderByDirection.Asc}`,
		},
		{
			label: messages.sortOptions.quantityDesc,
			value: `${StorageLocationOrderByFields.LiveQuantity} ${OrderByDirection.Desc}`,
		},
		{
			label: messages.sortOptions.fullnessAsc,
			value: `${StorageLocationOrderByFields.FillFraction} ${OrderByDirection.Asc}`,
		},
		{
			label: messages.sortOptions.fullnessDesc,
			value: `${StorageLocationOrderByFields.FillFraction} ${OrderByDirection.Desc}`,
		},
	];

	// Data
	const { results, cursor } = data || {};

	// Render
	const workAreaColumn: Column = {
		heading: messages.workArea,
		type: 'text',
		sortable: false,
	};

	const fmsColumns: Column[] = [
		{
			heading: messages.address,
			type: 'text',
			sortable: false,
		},
		...(config?.workAreasEnabled ? [workAreaColumn] : []),
		{
			heading: messages.sku,
			type: 'text',
			sortable: false,
		},
		{
			heading: messages.locationCondition,
			type: 'text',
			sortable: false,
		},
		{
			heading: messages.quantity,
			type: 'text',
			sortable: false,
		},
		{
			heading: messages.fullness,
			type: 'text',
			sortable: false,
		},
		{
			heading: messages.locationType,
			type: 'text',
			sortable: false,
		},
	];

	const fasColumns: Column[] = [
		{
			heading: messages.address,
			type: 'text',
			sortable: false,
		},
		...(config?.workAreasEnabled ? [workAreaColumn] : []),
		{
			heading: messages.capacityType,
			type: 'text',
			sortable: false,
		},
		{
			heading: messages.locationType,
			type: 'text',
			sortable: false,
		},
	];

	const fmsRows = (results || []).map((summary: StorageLocationSummary) => {
		const conflictReasons = summary.inventories.map((inv) => inv.conflictReasons).flat();
		const conflictReason =
			conflictReasons.length === 0
				? undefined
				: conflictReasons.length === 1
				? messages.discrepancyTypes[conflictReasons?.[0]]
				: messages.discrepancyTypes.Other;
		const isSlotted = summary.numAssetTypes > 0;
		const liveCount = summary.liveQuantity;
		const fullness = getSummaryFullness(summary);
		let locationCondition: string | undefined;
		if (liveCount > 0) {
			if (summary.type === StorageLocationType.Damaged) {
				locationCondition = 'damaged';
			} else {
				locationCondition = analyzeTaggage(summary.inventoryTags || undefined);
			}
		}

		return [
			// location address
			<Link key={summary.id} url={routes.location(summary.id)} removeUnderline>
				{summary.address}
			</Link>,

			// SKU
			summary.numAssetTypes > 1 ? (
				<LocationPeek summary={summary} />
			) : (
				summary.inventories[0]?.assetTypeExternalId || <NoData />
			),

			// Inventory State ((sub-asset-type) AKA "Condition")
			conditionValue(locationCondition, messages.inventoryStateNames) || <NoData />,

			// Work Area
			...(config?.workAreasEnabled
				? [summary.workArea ? <div>{summary.workArea?.name}</div> : <NoData />]
				: []),

			// Quantity
			<Stack key="qty" vertical={false}>
				<Stack.Item>{isSlotted ? formatNumber(liveCount) : <NoData />}</Stack.Item>
				{conflictReason ? (
					<Stack.Item>
						<Tooltip content={conflictReason}>
							<Icon source={DiamondAlertMajor} color="critical" />
						</Tooltip>
					</Stack.Item>
				) : null}
			</Stack>,

			// Fullness
			fullness === null ? <NoData /> : formatPercent(fullness),
			// Type
			summary.type ? messages.locationTypes[summary.type] : <NoData />,
		];
	});

	const fasRows = (results || []).map((summary: StorageLocationSummary) => {
		return [
			// Address
			<Link key={summary.id} url={routes.location(summary.id)}>
				{summary.address}
			</Link>,
			// Work Area
			...(config?.workAreasEnabled
				? [summary.workArea ? <div>{summary.workArea?.name}</div> : <NoData />]
				: []),
			// Capacity Type
			summary.containerTypeName || <NoData />,
			// Location Type
			summary.type ? messages.locationTypes[summary.type] : <NoData />,
		];
	});

	const columns = isInventoryEnabled ? fmsColumns : fasColumns;
	const rows = isInventoryEnabled ? fmsRows : fasRows;

	return (
		<DataTable
			sortChoices={sortChoices}
			views={tabs}
			selectedView={filters.view || 'All'}
			viewKey={keys.view}
			columns={columns}
			filters={isInventoryEnabled ? filtersMarkup : []}
			appliedFilters={appliedFilters}
			rows={rows}
			loading={loading}
			pageInfo={{ endCursor: cursor }}
			sortValue={filters.sort}
			setFilters={setFilters}
			query={filters.searchText}
			queryKey={keys.SEARCH_TEXT_KEY}
			queryPlaceholder={messages.filterLocations}
			paginationCursors={paginationCursors}
			setPaginationCursors={setPaginationCursors}
		/>
	);
}

function conditionValue(s: string | undefined, inventoryStateNames: any) {
	switch (s) {
		case 'mixed':
			return inventoryStateNames.mixed;
		case 'reworkPending':
		case 'needsRework':
			return inventoryStateNames.reworkPending;
		case 'sellable':
			return inventoryStateNames.sellable;
		case 'damaged':
			return inventoryStateNames.damaged;
		default:
			return undefined;
	}
}

export function analyzeTaggage(t: InventoryTag[] | undefined) {
	let locationCondition: string | undefined = `sellable`;
	if (t && t.length) {
		// we have a populated list of tags. Only one tag here?
		if (t.length === 1) {
			const tagCondition = t[0].definition?.condition;
			locationCondition = tagCondition || 'sellable';
		} else {
			// check whether all tags indicate the same condition
			const conditionKey = t
				.map((item) => {
					return item.definition?.condition || 'sellable';
				})
				.reduce((_accum, _cur) => {
					return _cur === _accum ? _cur : 'mixed';
				});
			locationCondition = conditionKey || undefined;
		}
	}
	return locationCondition;
}
