import { Page, Layout, MenuActionDescriptor } from '@shopify/polaris';
import { PrintMajor, MarkFulfilledMinor } from '@shopify/polaris-icons';
import {
	OrderExceptionStatus,
	Order,
	QueryOrderByProjectionIdArgs,
	HealingStrategy,
} from '@sixriver/fulfillment-api-schema';
import { useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';

import { EXCEPTION_QUERY } from './Exception.graphql';
import { ExceptionBadge } from './ExceptionBadge';
import { ExceptionDetails } from './ExceptionDetails';
import { ExceptionJobs } from './ExceptionJobs';
import { ExceptionLines } from './ExceptionLines';
import { AutoRefresh } from 'components/AutoRefresh';
import { CancelOrderModal } from 'components/CancelOrderModal';
import { Error } from 'components/Error';
import { ErrorBanner } from 'components/ErrorBanner';
import { TimezoneFooter } from 'components/TimezoneFooter';
import { getPickingJobs } from 'helpers/order';
import { useCancelOrder } from 'hooks/useCancelOrder';
import { useConfig } from 'hooks/useConfig';
import { useLocalization } from 'hooks/useLocalization';
import { usePolling } from 'hooks/usePolling';
import { usePollingQuery } from 'hooks/usePollingQuery';
import { usePrintLabelButton, useManualException } from 'hooks/usePrintLabelButton';
import { exceptions } from 'routes';

interface Params {
	exceptionId: string;
}

export function Exception() {
	const { messages } = useLocalization();
	const {
		params: { exceptionId },
	} = useRouteMatch<Params>();

	const { config } = useConfig();

	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();

	const history = useHistory();

	const [{ fetching, data, error }] = usePollingQuery<
		{ orderByProjectionId: Order },
		QueryOrderByProjectionIdArgs
	>({
		query: EXCEPTION_QUERY,
		pollInterval: queryPollInterval,
		variables: { projectionId: exceptionId },
	});

	const { orderByProjectionId: order } = data || {};

	const [isBannerErrorVisible, setIsBannerErrorVisible] = useState(false);

	const {
		onClick,
		buttonText,
		disabled,
		loading: printLabelButtonLoading,
	} = usePrintLabelButton({
		order,
		onError: () => {
			setIsBannerErrorVisible(true);
		},
		onBefore: () => {
			setIsBannerErrorVisible(false);
		},
	});

	const {
		onClick: handleManualException,
		buttonText: manualButtonText,
		disabled: manualDisabled,
		loading: manualExceptionLoading,
	} = useManualException({
		order,
	});

	const { isCancelModalOpen, openCancelModal, closeCancelModal, cancelOrder, cancelOrderLoading } =
		useCancelOrder({
			orderId: exceptionId,
			onSuccessfulCancel: () => {
				history.push(exceptions());
			},
			onError: () => {
				setIsBannerErrorVisible(true);
			},
			onBefore: () => {
				setIsBannerErrorVisible(false);
			},
		});

	if (error) {
		return <Error graphQLError={error} />;
	}

	const actions: MenuActionDescriptor[] = [
		{
			content: messages.cancel,
			onAction: openCancelModal,
			disabled:
				!order?.isCancelable || order?.exception?.status !== OrderExceptionStatus.Unresolvable,
		},
	];

	if (config?.healingEnabled && !order?.isShippedShort) {
		if (config.healingStrategies.includes(HealingStrategy.Automated)) {
			actions.push({
				content: buttonText,
				icon: PrintMajor,
				onAction: onClick,
				disabled,
				loading: printLabelButtonLoading,
			});
		}

		if (config.healingStrategies.includes(HealingStrategy.Manual)) {
			actions.push({
				content: manualButtonText,
				icon: MarkFulfilledMinor,
				onAction: handleManualException,
				disabled: manualDisabled,
				loading: manualExceptionLoading,
			});
		}
	}

	return (
		<Page
			fullWidth
			title={order?.customerIdentifier || undefined}
			titleMetadata={<ExceptionBadge status={order?.exception?.status} />}
			secondaryActions={actions}
		>
			<Layout>
				<Layout.Section>
					<ErrorBanner
						isVisible={isBannerErrorVisible}
						onDismiss={() => {
							setIsBannerErrorVisible(false);
						}}
					/>
					<AutoRefresh
						pollingEnabled={pollingEnabled}
						togglePolling={togglePolling}
						discriminatorData={data}
					/>
				</Layout.Section>
				<Layout.Section>
					<ExceptionDetails
						loading={fetching}
						order={order}
						isWorkAreaEnabled={config?.workAreasEnabled}
					/>
				</Layout.Section>
				<Layout.Section>
					<ExceptionLines loading={fetching} lines={order?.lines} />
				</Layout.Section>
				{config?.healingEnabled ? (
					<Layout.Section>
						<ExceptionJobs
							loading={fetching}
							jobs={getPickingJobs(order?.servicedBy)}
							isWorkAreaEnabled={config?.workAreasEnabled}
						/>
					</Layout.Section>
				) : null}
			</Layout>
			<Layout>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
			<CancelOrderModal
				isOpen={isCancelModalOpen}
				onClose={closeCancelModal}
				onConfirm={cancelOrder}
				loading={cancelOrderLoading}
			/>
		</Page>
	);
}
