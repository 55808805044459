import { Page, Layout, ChoiceList, FilterInterface } from '@shopify/polaris';
import { useDebouncedValue } from '@shopify/react-hooks';
import { Count, HealPlanStatus, OrderConnection } from '@sixriver/fulfillment-api-schema';
import { useState } from 'react';

import { ExceptionViews, ExceptionViewStateMap } from './ExceptionViews';
import { EXCEPTIONS_QUERY, COUNTS_QUERY } from './Exceptions.graphql';
import { ExceptionsTable } from './ExceptionsTable';
import { AutoRefresh } from 'components/AutoRefresh';
import { Error } from 'components/Error';
import { ErrorBanner } from 'components/ErrorBanner';
import { TimezoneFooter } from 'components/TimezoneFooter';
import { getPageSize } from 'helpers/page-size';
import { MIN_QUERY_LENGTH } from 'helpers/table';
import { startOfDay } from 'helpers/time';
import { useConfig } from 'hooks/useConfig';
import { useFilters, useSetFilters } from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';
import { usePolling } from 'hooks/usePolling';
import { usePollingQuery } from 'hooks/usePollingQuery';
import { useWorkAreas } from 'hooks/useWorkArea/use-work-areas';

const PROJECTION_IDS = 'projectionIds';
const WORKAREA_KEY = 'workArea';

export function Exceptions() {
	const { messages } = useLocalization();
	const { config } = useConfig();
	const { workAreas } = useWorkAreas();

	const {
		view = ExceptionViews.Resolvable,
		query,
		[PROJECTION_IDS]: projectionIdsParam,
		[WORKAREA_KEY]: workArea,
	} = useFilters([PROJECTION_IDS, WORKAREA_KEY]);

	const setFilters = useSetFilters();

	const projectionIds = projectionIdsParam ? projectionIdsParam.split(' ') : undefined;

	const selectedWorkAreas = workArea ? workArea.split(' ') : undefined;

	const searchText = useDebouncedValue(query) || '';

	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);

	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();

	const actualSearchText = searchText.length >= MIN_QUERY_LENGTH ? searchText : undefined;

	const midnight = startOfDay();

	let hasHealPlan = undefined;
	let healPlanStatuses = undefined;

	if (view === ExceptionViews.Unresolvable) {
		healPlanStatuses = [HealPlanStatus.Accepted];
	} else if (view === ExceptionViews.Resolvable) {
		healPlanStatuses = [HealPlanStatus.Ready];
	} else if (view === ExceptionViews.Resolved) {
		hasHealPlan = true;
	}

	const [{ fetching: ordersFetching, data: ordersData, error: ordersError }] = usePollingQuery<{
		orders: OrderConnection;
	}>({
		query: EXCEPTIONS_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			statuses: ExceptionViewStateMap[view as ExceptionViews],
			jobUpdatedAtFrom: view === ExceptionViews.Resolved ? midnight : undefined,
			searchText: actualSearchText,
			first: getPageSize(),
			after: paginationCursors[0],
			projectionIds: projectionIds || undefined,
			hasHealPlan,
			healPlanStatuses,
		},
	});

	const [{ fetching: countsFetching, data: countsData, error: countsError }] = usePollingQuery<{
		UnresolvableCount: Count;
		ResolvableCount: Count;
		ResolvingCount: Count;
		ResolvedCount: Count;
	}>({
		query: COUNTS_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			searchText: actualSearchText,
			projectionIds: projectionIds || undefined,
			jobUpdatedAtFrom: midnight,
			view,
		},
	});

	const {
		UnresolvableCount = { count: 0 },
		ResolvableCount = { count: 0 },
		ResolvingCount = { count: 0 },
		ResolvedCount = { count: 0 },
	} = countsData || {};

	const fetching = ordersFetching || countsFetching;

	const error = ordersError || countsError;

	const [isErrorBannerVisible, setIsErrorBannerVisible] = useState(false);

	const views = [
		{
			label: messages.resolvable,
			metaLabel: ResolvableCount.count,
			id: ExceptionViews.Resolvable,
		},
		{
			label: messages.unresolvable,
			metaLabel: UnresolvableCount.count,
			id: ExceptionViews.Unresolvable,
		},
		{
			label: messages.resolving,
			metaLabel: ResolvingCount.count,
			id: ExceptionViews.Resolving,
		},
		{
			label: messages.resolvedToday,
			metaLabel: ResolvedCount.count,
			id: ExceptionViews.Resolved,
		},
	];

	const filters: FilterInterface[] = [];

	if (config?.workAreasEnabled) {
		filters.push({
			key: WORKAREA_KEY,
			label: messages.workAreas,
			filter: (
				<ChoiceList
					title={messages.workAreas}
					titleHidden
					choices={workAreas.map((workArea) => ({
						label: workArea.name,
						value: workArea.id,
					}))}
					selected={selectedWorkAreas || []}
					onChange={(selected) => {
						setFilters([{ key: WORKAREA_KEY, value: selected.join(' ') }]);
					}}
					allowMultiple
				/>
			),
			shortcut: true,
		});
	}

	const appliedFilters = [
		...(projectionIds && projectionIds.length
			? [
					{
						key: PROJECTION_IDS,
						label: projectionIds.join(', '),
						onRemove: () => {
							setFilters([{ key: PROJECTION_IDS, value: '' }]);
						},
					},
			  ]
			: []),
		...(selectedWorkAreas && selectedWorkAreas.length
			? [
					{
						key: WORKAREA_KEY,
						label: workAreas
							.filter((area) => selectedWorkAreas.includes(area.id))
							.map((area, i) => area.name || `Work area ${i + 1}`)
							.join(', '),
						onRemove: () => {
							setFilters([{ key: WORKAREA_KEY, value: '' }]);
						},
					},
			  ]
			: []),
	];

	return error ? (
		<Error graphQLError={error} />
	) : (
		<Page fullWidth title={messages.exceptions}>
			<Layout>
				<Layout.Section>
					<AutoRefresh
						pollingEnabled={pollingEnabled}
						togglePolling={togglePolling}
						discriminatorData={ordersData}
					/>
					<ErrorBanner
						isVisible={isErrorBannerVisible}
						onDismiss={() => {
							setIsErrorBannerVisible(false);
						}}
					/>
				</Layout.Section>
				<Layout.Section>
					<ExceptionsTable
						loading={fetching}
						data={ordersData?.orders}
						paginationCursors={paginationCursors}
						setPaginationCursors={setPaginationCursors}
						views={config?.healingEnabled ? views : []}
						selectedView={view as ExceptionViews | undefined}
						query={query}
						filters={filters}
						setFilters={setFilters}
						onPrintLabelError={() => {
							setIsErrorBannerVisible(true);
						}}
						appliedFilters={appliedFilters}
					/>
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</Page>
	);
}
