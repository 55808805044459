import { Card, Stack, Page, Layout, Pagination } from '@shopify/polaris';
import { useDebouncedValue } from '@shopify/react-hooks';
import { QueryOrdersV2Args, OrderV2Status } from '@sixriver/fulfillment-api-schema';
import { useCallback, useEffect, useState } from 'react';

import { ExceptionsData, EXCEPTIONS_QUERY } from './Exceptions.graphql';
import { ExceptionsCounts, EXCEPTIONS_COUNT_QUERY } from './ExceptionsCounts.graphql';
import { ExceptionsFilters } from './ExceptionsFilters';
import { ExceptionsAction, ExceptionsTable } from './ExceptionsTable';
import { ExceptionsTabs, ExceptionsTab } from './ExceptionsTabs';
import { PrintLabelsBanner } from './PrintLabelsBanner';
import { AutoRefresh } from 'components/AutoRefresh';
import { Error } from 'components/Error';
import { ErrorBanner } from 'components/ErrorBanner';
import { TimezoneFooter } from 'components/TimezoneFooter';
import { getPageSize } from 'helpers/page-size';
import { MIN_QUERY_LENGTH } from 'helpers/table';
import { getMidnight } from 'helpers/time';
import { usePrintLabelForException, useManuallyResolveForException } from 'hooks/useHealPlan';
import { useLocalization } from 'hooks/useLocalization';
import { usePolling } from 'hooks/usePolling';
import { usePollingQuery } from 'hooks/usePollingQuery';
import { useArrayQueryState, useQueryState } from 'hooks/useQueryState';

const MIDNIGHT_TODAY = getMidnight();
const MIDNIGHT_TOMORROW = getMidnight(1);
const PAGE_SIZE = getPageSize();
const SEARCH_TEXT_KEY = 'query';
const SELECTED_VIEW_KEY = 'selectedView';
const PAGINATION_CURSORS_KEY = 'paginationCursors';

export function Exceptions() {
	const { messages } = useLocalization();
	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();
	const [isErrorBannerVisible, setIsErrorBannerVisible] = useState(false);
	const [paginationCursors, setPaginationCursors] = useArrayQueryState<string[]>(
		PAGINATION_CURSORS_KEY,
		[],
	);
	const [searchText, setSearchText] = useQueryState(SEARCH_TEXT_KEY, '');
	const [selectedTab, setSelectedTab] = useQueryState<ExceptionsTab>(
		SELECTED_VIEW_KEY,
		ExceptionsTab.Resolvable,
	);
	const debouncedSearchText = useDebouncedValue(searchText);
	const [didPrintLabels, setDidPrintLabels] = useState(false);
	const [statuses, setStatuses] = useState<OrderV2Status[] | undefined>(undefined);
	const [isHealable, setIsHealable] = useState<boolean | undefined>(undefined);
	const [isShorted, setIsShorted] = useState<boolean | undefined>(undefined);
	const [hasExceptions, setHasExceptions] = useState<boolean | undefined>(undefined);
	const [totalCount, setTotalCount] = useState(0);
	const [selectable, setSelectable] = useState(false);
	const [actions, setActions] = useState<ExceptionsAction[]>([]);
	const [healPlanCompletedAtFrom, setHealPlanCompletedAtFrom] = useState<Date | undefined>(
		undefined,
	);
	const [healPlanCompletedAtTo, setHealPlanCompletedAtTo] = useState<Date | undefined>(undefined);

	/**
	 * Queries
	 */
	const [{ data: countData }] = usePollingQuery<ExceptionsCounts, QueryOrdersV2Args>({
		query: EXCEPTIONS_COUNT_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			searchText: debouncedSearchText.length >= MIN_QUERY_LENGTH ? debouncedSearchText : undefined,
			healPlanCompletedAtFrom: MIDNIGHT_TODAY,
			healPlanCompletedAtTo: MIDNIGHT_TOMORROW,
		},
	});

	const [{ fetching: ordersFetching, data: ordersData, error: ordersError }, refetchExceptions] =
		usePollingQuery<ExceptionsData, QueryOrdersV2Args>({
			query: EXCEPTIONS_QUERY,
			pollInterval: queryPollInterval,
			variables: {
				first: PAGE_SIZE,
				after: paginationCursors[0],
				searchText:
					debouncedSearchText.length >= MIN_QUERY_LENGTH ? debouncedSearchText : undefined,
				statuses,
				isHealable,
				isShorted,
				hasExceptions,
				healPlanCompletedAtFrom,
				healPlanCompletedAtTo,
			},
		});

	const orders = ordersData?.ordersV2.edges;
	const ordersPageInfo = ordersData?.ordersV2.pageInfo;

	const loading = ordersFetching;
	// const loading = ordersFetching || countsFetching;

	const error = ordersError;
	// const error = ordersError || countsError;

	/**
	 * Pagination
	 * */
	const handleOnNextPage = useCallback(() => {
		const endCursor = ordersPageInfo?.endCursor ?? undefined;
		if (endCursor) {
			setPaginationCursors((current) => [endCursor].concat(current));
			refetchExceptions();
		}
	}, [ordersPageInfo?.endCursor, refetchExceptions, setPaginationCursors]);

	const handleOnPreviousPage = useCallback(() => {
		setPaginationCursors((current) => current.slice(1));
		refetchExceptions();
	}, [refetchExceptions, setPaginationCursors]);

	/**
	 * Healing mutations
	 */
	const { printLabel } = usePrintLabelForException();
	const handleOnPrintLabels = useCallback(
		async (ids: string[]) => {
			await Promise.all(ids.map((id) => printLabel(id)));
			setDidPrintLabels(true);
		},
		[printLabel],
	);

	const { manuallyResolve } = useManuallyResolveForException();
	const handleManuallyResolve = useCallback(
		async (ids: string[]) => {
			await Promise.all(ids.map((id) => manuallyResolve(id)));
			setDidPrintLabels(true);
		},
		[manuallyResolve],
	);

	useEffect(() => {
		switch (selectedTab) {
			case ExceptionsTab.Resolvable:
				setTotalCount(countData?.ResolvableCount.count ?? 0);
				setSelectable(true);
				setActions([ExceptionsAction.PrintLabels, ExceptionsAction.ManuallyResolve]);
				setStatuses([OrderV2Status.Picked, OrderV2Status.Sorted]);
				setIsHealable(true);
				setIsShorted(true);
				setHasExceptions(true);
				setHealPlanCompletedAtFrom(undefined);
				setHealPlanCompletedAtTo(undefined);
				return;
			case ExceptionsTab.Unresolvable:
				setTotalCount(countData?.UnresolvableCount.count ?? 0);
				setSelectable(true);
				setActions([]);
				setStatuses([OrderV2Status.Picked, OrderV2Status.Sorted]);
				setIsHealable(false);
				setIsShorted(true);
				setHasExceptions(true);
				setHealPlanCompletedAtFrom(undefined);
				setHealPlanCompletedAtTo(undefined);
				return;
			case ExceptionsTab.Resolving:
				setTotalCount(countData?.ResolvingCount.count ?? 0);
				setSelectable(false);
				setActions([]);
				setStatuses([OrderV2Status.Picking]);
				setIsHealable(undefined);
				setIsShorted(undefined);
				setHasExceptions(true);
				setHealPlanCompletedAtFrom(undefined);
				setHealPlanCompletedAtTo(undefined);
				return;
			case ExceptionsTab.Resolved:
				setTotalCount(countData?.ResolvedTodayCount.count ?? 0);
				setSelectable(false);
				setActions([]);
				setStatuses([]);
				setIsHealable(false);
				setIsShorted(false);
				setHasExceptions(true);
				setHealPlanCompletedAtFrom(MIDNIGHT_TODAY);
				setHealPlanCompletedAtTo(MIDNIGHT_TOMORROW);
				return;
		}
	}, [countData, selectedTab]);

	return error ? (
		<Error graphQLError={error} />
	) : (
		<Page fullWidth title={messages.exceptions}>
			<Layout>
				<Layout.Section>
					<AutoRefresh
						pollingEnabled={pollingEnabled}
						togglePolling={togglePolling}
						discriminatorData={ordersData}
					/>
					<ErrorBanner
						isVisible={isErrorBannerVisible}
						onDismiss={() => {
							setIsErrorBannerVisible(false);
						}}
					/>
				</Layout.Section>
				<Layout.Section>
					<Card>
						<div style={{ paddingBottom: '2rem' }}>
							<ExceptionsTabs
								resolvableCount={countData?.ResolvableCount.count ?? 0}
								unresolvableCount={countData?.UnresolvableCount.count ?? 0}
								resolvingCount={countData?.ResolvingCount.count ?? 0}
								resolvedTodayCount={countData?.ResolvedTodayCount.count ?? 0}
								selected={selectedTab}
								onSelect={setSelectedTab}
							/>
						</div>
						<div style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
							<ExceptionsFilters queryValue={searchText} onQueryChange={setSearchText} />
						</div>

						<PrintLabelsBanner
							isVisible={didPrintLabels}
							onDismiss={() => setDidPrintLabels(false)}
						/>

						<ExceptionsTable
							loading={loading}
							data={orders}
							onPrintLabels={handleOnPrintLabels}
							onManuallyResolve={handleManuallyResolve}
							selectable={selectable}
							actions={actions}
						/>
						<Card.Section>
							<Stack distribution="center">
								<Pagination
									label={`${paginationCursors.length + 1} ${messages.of} ${Math.max(
										Math.ceil(totalCount / PAGE_SIZE),
										1,
									)}`}
									hasNext={ordersPageInfo?.hasNextPage}
									hasPrevious={ordersPageInfo?.hasPreviousPage}
									onNext={handleOnNextPage}
									onPrevious={handleOnPreviousPage}
								/>
							</Stack>
						</Card.Section>
					</Card>
				</Layout.Section>
				<Layout.Section>
					<TimezoneFooter />
				</Layout.Section>
			</Layout>
		</Page>
	);
}
