import { IndexTable, TextStyle } from '@shopify/polaris';

import { NoData } from 'components/NoData';

interface ExternalIdCellProps {
	trackingNumbers?: string[];
}

export function TrackingNumberCell({ trackingNumbers }: ExternalIdCellProps) {
	return (
		<IndexTable.Cell>
			<TextStyle>{trackingNumbers ? trackingNumbers.join(', ') : <NoData />}</TextStyle>
		</IndexTable.Cell>
	);
}
