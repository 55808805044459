import { Card, Heading, Modal, Stack, TextContainer, TextStyle } from '@shopify/polaris';
import { KittingProject as KittingProjectApi } from '@sixriver/fulfillment-api-schema';

import { InstructionalStep } from './InstructionalStep';
import { Product } from './Product';
import { useLocalization } from 'hooks/useLocalization';

export interface KittingInstructionsModalProps {
	isOpen: boolean;
	onClose: () => void;
	project: KittingProjectApi;
}

export function KittingInstructionsModal({
	isOpen: isKittingInstructionsModalOpen,
	onClose,
	project,
}: KittingInstructionsModalProps) {
	const { messages } = useLocalization();
	const { componentProducts, instructions } = project;

	const totalUnits = componentProducts.reduce((sum, next) => {
		return sum + (next.eachQuantity || 0);
	}, 0);

	// Render
	return (
		<Modal
			large
			open={isKittingInstructionsModalOpen}
			onClose={onClose}
			title={messages.instructions}
			titleHidden
			secondaryActions={[
				{
					content: messages.close,
					onAction: onClose,
				},
			]}
		>
			<Modal.Section>
				<TextContainer>
					<Heading>{messages.parts}</Heading>
					<Stack>
						<TextStyle>{`${messages.skusRequired}: ${componentProducts.length}`}</TextStyle>
						<TextStyle>{`${messages.totalUnitsRequired}: ${totalUnits}`}</TextStyle>
					</Stack>
				</TextContainer>
			</Modal.Section>
			<Modal.Section>
				<Stack distribution="fill">
					{componentProducts.map((component) => (
						<Card key={`${component.id}`}>
							<Card.Section subdued>
								<Product product={component} showUnits />
							</Card.Section>
						</Card>
					))}
				</Stack>
			</Modal.Section>
			{instructions.map((instruction, index) => (
				<InstructionalStep key={index} stepNumber={index + 1} step={instruction} showStepNumber />
			))}
		</Modal>
	);
}
