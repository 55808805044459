import {
	Page,
	Layout,
	ChoiceList,
	IndexTable,
	Link,
	TextStyle,
	Tooltip,
	Card,
	Filters,
	useIndexResourceState,
	EmptySearchResult,
	Banner,
	Stack,
	IndexTableProps,
} from '@shopify/polaris';
import { useDebouncedValue, usePrevious } from '@shopify/react-hooks';
import {
	Count,
	WorkOrderType as FulfillmentWorkOrderType,
	Cutoff,
	JobAllocationMethod,
	MutationResponse,
	OrderByDirection,
	OrderConnection,
	OrderExceptionStatus,
	OrderOrderByFields,
	OrderState,
	PickStrategy,
	QueryOrdersArgs,
	QueryOrdersCountArgs,
	Sortation,
	DatePrecision,
} from '@sixriver/fulfillment-api-schema';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'urql';

import { BulkOrderCancelConfirmationModal } from './BulkOrderCancelConfirmationModal';
import { OrderHistoryLimiterConfirmModal } from './OrderHistoryLimiterConfirmModal';
import { OrderViews, OrderViewStateMap } from './OrderViews';
import {
	ORDERS_QUERY,
	CUTOFF_DATES_QUERY,
	COUNTS_QUERY,
	FILTERED_COUNTS_QUERY,
	CANCEL_ORDERS_MUTATION,
} from './Orders.graphql';
import { AutoRefresh } from 'components/AutoRefresh';
import { CutoffFilter } from 'components/CutoffFilter';
import { Pagination } from 'components/DataTable/Pagination';
import {
	DateRange,
	DateRangeSelect,
	OptionValues,
	OptionLabelValue,
} from 'components/DateRangeSelect';
import { DateTime } from 'components/DateTime';
import { Error } from 'components/Error';
import { ErrorBanner } from 'components/ErrorBanner';
import { NoData } from 'components/NoData';
import { OrderQuantity } from 'components/OrderQuantity';
import { OrderStatusBadge } from 'components/OrderStatusBadge';
import { OrderTotes } from 'components/OrderTotes';
import { RelativeDateTime } from 'components/RelativeDateTime';
import { SortBy, SortChoice } from 'components/SortBy';
import { TimezoneFooter } from 'components/TimezoneFooter';
import { Toteboard } from 'components/Toteboard';
import { ViewTabs } from 'components/ViewTabs';
import { WorkAreaMenu } from 'components/WorkAreaMenu';
import { WorkOrderType } from 'components/WorkOrderType/WorkOrderType';
import { countLineExceptions, getExceptionLines } from 'helpers/exception';
import { getPageSize } from 'helpers/page-size';
import { MIN_QUERY_LENGTH } from 'helpers/table';
import { getDateDiffInDays, getMidnight, startOfDay } from 'helpers/time';
import { useAuth } from 'hooks/useAuth';
import { useConfig } from 'hooks/useConfig';
import {
	DEFAULT_QUERY_KEY,
	DEFAULT_SORT_KEY,
	DEFAULT_VIEW_KEY,
	useFilters,
	useSetFilters,
} from 'hooks/useFilters';
import { useLocalization } from 'hooks/useLocalization';
import { usePickStrategies, usePickStrategyName } from 'hooks/usePickStrategies';
import { usePolling } from 'hooks/usePolling';
import { usePollingQuery } from 'hooks/usePollingQuery';
import { useWorkAreas } from 'hooks/useWorkArea/use-work-areas';
import { UserRole } from 'providers/AuthProvider';
import * as routes from 'routes';

const PICK_STRATEGY_KEY = 'pickStrategy';
const CUTOFF_KEY = 'cutoff';
const PROJECTION_IDS_KEY = 'projectionIds';
const BATCH_ID_KEY = 'batchId';
const JOB_CREATED_AT_FROM_KEY = 'jobCreatedAtFrom';
const BULK_ORDER_PROJECTION_ID_KEY = 'bulkOrderProjectionId';
const SORT_KEY = DEFAULT_SORT_KEY;
const QUERY_KEY = DEFAULT_QUERY_KEY;
const VIEW_KEY = DEFAULT_VIEW_KEY;
const defaultView = OrderViews.All;
const defaultSort = OrderOrderByFields.JobCreatedAt + ' ' + OrderByDirection.Desc;
const WORKAREA_KEY = 'workArea';
const WORK_ORDER_TYPE = 'workOrderType';
const defaultDate = getMidnight(-7);

enum SelectionType {
	All = 'all',
	Page = 'page',
	Multi = 'multi',
	Single = 'single',
}

export function Orders() {
	const { messages, translate, formatDateTime, formatNumber } = useLocalization();

	// State
	const [paginationCursors, setPaginationCursors] = useState<string[]>([]);
	const [isBulkCancelConfirmModalOpen, setIsBulkCancelConfirmModalOpen] = useState<boolean>(false);
	const [isBulkCancelInProgress, setIsBulkCancelInProgress] = useState<boolean>(false);
	const [didBulkCancelFail, setDidBulkCancelFail] = useState<boolean>(false);
	const [proposedHistoryLimitDate, setProposedHistoryLimitDate] = useState<{
		date: Date;
		option: OptionValues;
	} | null>(null);

	// Filters
	const {
		[VIEW_KEY]: view = defaultView,
		[QUERY_KEY]: query,
		[SORT_KEY]: sort = defaultSort,
		[PICK_STRATEGY_KEY]: pickStrategyParam,
		[CUTOFF_KEY]: cutoffDate,
		[WORKAREA_KEY]: workArea,
		[PROJECTION_IDS_KEY]: projectionIdsParam,
		[BATCH_ID_KEY]: batchIdParam,
		[WORK_ORDER_TYPE]: workOrderType,
		[JOB_CREATED_AT_FROM_KEY]: jobCreatedAtFromParam,
		[BULK_ORDER_PROJECTION_ID_KEY]: bulkOrderProjectionId,
	} = useFilters([
		PICK_STRATEGY_KEY,
		CUTOFF_KEY,
		PROJECTION_IDS_KEY,
		BATCH_ID_KEY,
		QUERY_KEY,
		VIEW_KEY,
		SORT_KEY,
		WORKAREA_KEY,
		WORK_ORDER_TYPE,
		JOB_CREATED_AT_FROM_KEY,
		BULK_ORDER_PROJECTION_ID_KEY,
	]);

	const setFilters = useSetFilters();

	const projectionIds = projectionIdsParam ? projectionIdsParam.split(' ') : undefined;
	const batchId = batchIdParam ? batchIdParam : undefined;
	const searchText = useDebouncedValue(query) || '';
	const actualSearchText = searchText.length >= MIN_QUERY_LENGTH ? searchText : undefined;
	const expectedShipDate = useMemo(
		() => (cutoffDate ? new Date(cutoffDate) : undefined),
		[cutoffDate],
	);
	const selectedPickStrategies = pickStrategyParam
		? (pickStrategyParam.split(' ') as PickStrategy[])
		: undefined;

	const selectedWorkAreas = workArea ? workArea.split(' ') : undefined;
	const selectedWorkOrderTypes = workOrderType ? workOrderType.split(' ') : undefined;
	const historyDateLimiter = useMemo(
		() => (jobCreatedAtFromParam ? new Date(jobCreatedAtFromParam) : defaultDate),
		[jobCreatedAtFromParam],
	);

	// Config
	const midnight = startOfDay();
	const { config } = useConfig();
	const { workAreas } = useWorkAreas();

	const availablePickStrategies = usePickStrategies(config?.jobAllocationMethods || []);
	const consolidationEnabled = config?.jobAllocationMethods.includes(
		JobAllocationMethod.Consolidation,
	);
	const statuses = useMemo(
		() =>
			config?.healingEnabled && view === OrderViews.InProgress
				? OrderViewStateMap[view as OrderViews].concat(OrderState.Exception)
				: OrderViewStateMap[view as OrderViews],
		[config?.healingEnabled, view],
	);

	// Auth
	const { isUserAllowed } = useAuth();

	let jobUpdatedAtFrom: Date | undefined = undefined;

	if (view === OrderViews.Complete || view === OrderViews.Cancelled) {
		jobUpdatedAtFrom = midnight;
	} else if (view === OrderViews.Exceptions && !config?.healingEnabled) {
		jobUpdatedAtFrom = midnight;
	}

	// Queries
	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();

	const ordersQueryArgs = useMemo(
		() => ({
			orderBy: sort ? (sort.split(' ')[0] as OrderOrderByFields) : undefined,
			orderByDirection: sort ? (sort.split(' ')[1] as OrderByDirection) : undefined,
			statuses,
			jobUpdatedAtFrom,
			lateOnly: view === OrderViews.Late,
			searchText: actualSearchText,
			pickTypes: selectedPickStrategies,
			expectedShipDateFrom: expectedShipDate,
			expectedShipDateTo: expectedShipDate
				? new Date(expectedShipDate.getTime() + 60 * 1000)
				: undefined,
			projectionIds: projectionIds || undefined,
			hasCompletedAt: view === OrderViews.Complete || undefined,
			workAreaIds: selectedWorkAreas,
			jobCreatedAtFrom: historyDateLimiter,
			jobFlowRuleLpn: batchId,
			workOrderTypes: selectedWorkOrderTypes as FulfillmentWorkOrderType[],
			bulkOrderProjectionId,
		}),
		[
			actualSearchText,
			expectedShipDate,
			jobUpdatedAtFrom,
			projectionIds,
			historyDateLimiter,
			selectedPickStrategies,
			sort,
			statuses,
			view,
			selectedWorkAreas,
			batchId,
			selectedWorkOrderTypes,
			bulkOrderProjectionId,
		],
	);

	const [{ fetching: ordersFetching, data: ordersData, error: ordersError }] = usePollingQuery<
		{ orders: OrderConnection },
		QueryOrdersArgs
	>({
		query: ORDERS_QUERY,
		pollInterval: queryPollInterval,
		variables: { ...ordersQueryArgs, first: getPageSize(), after: paginationCursors[0] },
	});

	const [{ fetching: countsFetching, data: countsData, error: countsError }] = usePollingQuery<{
		StaticTotalCount: Count;
		StaticUnassignedCount: Count;
		StaticAssignedToWallCount: Count;
		StaticInProgressCount: Count;
		StaticCompletedTodayCount: Count;
		UnassignedCount: Count;
		AssignedToWallCount: Count;
		InProgressCount: Count;
		CompletedTodayCount: Count;
		ExceptionsCount: Count;
		ExceptionsTodayCount: Count;
		LateCount: Count;
		AwaitingInventoryCount: Count;
		CancelledCount: Count;
		InterruptedCount: Count;
	}>({
		query: COUNTS_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			searchText: actualSearchText,
			pickTypes: selectedPickStrategies,
			jobUpdatedAtFrom: midnight,
			expectedShipDateFrom: expectedShipDate,
			expectedShipDateTo: expectedShipDate,
			hasCompletedAt: true,
			workAreaIds: selectedWorkAreas,
			jobCreatedAtFrom: historyDateLimiter,
			jobFlowRuleLpn: batchId,
			bulkOrderProjectionId,
		},
	});

	const [
		{ fetching: filteredCountsFetching, data: filteredCountsData, error: filteredCountsError },
	] = usePollingQuery<
		{
			FilteredTotalsCount: Count;
		},
		QueryOrdersCountArgs
	>({
		query: FILTERED_COUNTS_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			...ordersQueryArgs,
		},
	});

	const [{ fetching: cutoffDatesFetching, data: cutoffDatesData, error: cutoffDatesError }] =
		useQuery<{ cutoffForOrders: Cutoff }>({
			query: CUTOFF_DATES_QUERY,
			variables: {
				datePrecision: DatePrecision.Minute,
				createdAtFrom: historyDateLimiter,
			},
		});

	const cutoffDates = cutoffDatesData?.cutoffForOrders;
	/**
	 * Super hacky way to allow some sites to abuse carrier cutoff and have hundreds per day
	 * and still show them the cutoff filter.   Through trial and error testing, it appears
	 * that React starts chocking when it has to do more that 8000 items in a list.
	 * So if the list has less than the max show the filter OR if the history limiter date is
	 * 7 days or less show the filter (this is slightly dangerous) if the resuls are over 8000
	 * but big query shows the largest sites currently are <7500 in a 7 day period.
	 */

	const alwaysShowWhen = historyDateLimiter.getTime() >= defaultDate.getTime();
	const maxLimit = 8000;
	const showCutoffFilter = alwaysShowWhen || (cutoffDates?.dates.length ?? 0) <= maxLimit;

	// Query Data
	const {
		StaticTotalCount = { count: 0 },
		StaticUnassignedCount = { count: 0 },
		StaticInProgressCount = { count: 0 },
		StaticCompletedTodayCount = { count: 0 },
		UnassignedCount = { count: 0 },
		InProgressCount = { count: 0 },
		CompletedTodayCount = { count: 0 },
		ExceptionsCount = { count: 0 },
		ExceptionsTodayCount = { count: 0 },
		LateCount = { count: 0 },
		AwaitingInventoryCount = { count: 0 },
		CancelledCount = { count: 0 },
		InterruptedCount = { count: 0 },
	} = countsData || {};

	const totalFilteredOrdersCount = filteredCountsData?.FilteredTotalsCount?.count || 0;

	const { pageInfo, edges = [] } = ordersData?.orders || {};

	// Mutations
	const [{ error: cancelOrdersError }, cancelOrdersMutation] = useMutation<
		{ cancelOrders: MutationResponse },
		QueryOrdersArgs
	>(CANCEL_ORDERS_MUTATION);

	const getPickStrategyName = usePickStrategyName();

	// Table - resource selection
	const {
		selectedResources: selectedOrderIds,
		allResourcesSelected,
		handleSelectionChange,
	} = useIndexResourceState(edges, { resourceIDResolver: (edge) => edge.node.id });

	// Methods
	const submitBulkCancelRequest = async () => {
		setIsBulkCancelConfirmModalOpen(false); // close modal
		setIsBulkCancelInProgress(true);

		// Bulk cancel mutation uses the same filter args that drive the page results
		const filter = { ...ordersQueryArgs };

		// Override projectionIds with checked values, or empty if user selects "all"
		filter.projectionIds = allResourcesSelected ? [] : selectedOrderIds;

		const { error, data } = await cancelOrdersMutation(filter);

		// Error
		if (error || !data?.cancelOrders?.success) {
			setIsBulkCancelInProgress(false);
			setDidBulkCancelFail(true);
		}

		// Success
		if (!error && data?.cancelOrders?.success) {
			setDidBulkCancelFail(false);
			handleSelectionChange(SelectionType.All, false);
		}
	};

	const onClearAll = () => {
		if (setFilters) {
			setFilters([
				...(filters || []).map((filter) => ({ key: filter.key, value: '' })),
				{ key: QUERY_KEY, value: '' },
			]);
		}
	};

	// Table - Views / Sort / Filters Ribbon
	const views = [
		{
			label: messages.all,
			id: OrderViews.All,
		},
		{
			label: messages.unassigned,
			metaLabel: UnassignedCount.count,
			id: OrderViews.Unassigned,
		},
		{
			label: messages.inProgress,
			metaLabel: InProgressCount.count + (config?.healingEnabled ? ExceptionsCount.count : 0),
			id: OrderViews.InProgress,
		},
		{
			label: messages.completedToday,
			metaLabel: CompletedTodayCount.count,
			id: OrderViews.Complete,
		},
		{
			label: config?.healingEnabled ? messages.exceptions : messages.exceptionsToday,
			metaLabel: config?.healingEnabled ? ExceptionsCount.count : ExceptionsTodayCount.count,
			id: OrderViews.Exceptions,
		},
		{
			label: messages.late,
			metaLabel: LateCount.count,
			id: OrderViews.Late,
		},
		...(config?.inventoryEnabled
			? [
					{
						label: messages.awaitingInventory,
						metaLabel: AwaitingInventoryCount.count,
						id: OrderViews.AwaitingInventory,
					},
			  ]
			: []),
		{
			label: messages.interrupted,
			metaLabel: InterruptedCount.count,
			id: OrderViews.Interrupted,
		},
		{
			label: messages.canceledToday,
			metaLabel: CancelledCount.count,
			id: OrderViews.Cancelled,
		},
	];

	const filters = [
		...(availablePickStrategies.length > 1
			? [
					{
						key: PICK_STRATEGY_KEY,
						label: messages.pickStrategy,
						filter: (
							<ChoiceList
								title={messages.pickStrategy}
								titleHidden
								choices={availablePickStrategies.map((strategy) => ({
									label: getPickStrategyName(strategy),
									value: strategy,
								}))}
								selected={selectedPickStrategies || []}
								onChange={(selected) => {
									setFilters([{ key: PICK_STRATEGY_KEY, value: selected.join(' ') }]);
								}}
								allowMultiple
							/>
						),
						shortcut: true,
					},
			  ]
			: []),
		...(showCutoffFilter
			? [
					{
						key: CUTOFF_KEY,
						label: messages.carrierCutoff,
						filter: (
							<CutoffFilter
								onChange={(selected) => setFilters([{ key: CUTOFF_KEY, value: selected }])}
								value={cutoffDate}
								cutoffDates={cutoffDates}
							/>
						),
						shortcut: true,
					},
			  ]
			: []),

		...(config?.inventoryEnabled
			? [
					{
						key: WORK_ORDER_TYPE,
						label: messages.workOrderType,
						filter: (
							<ChoiceList
								title={messages.workOrderType}
								titleHidden
								choices={[
									{
										label: messages.workOrderTypes.transfer,
										value: FulfillmentWorkOrderType.InventoryTransferToNode,
									},
									{
										label: messages.workOrderTypes.ecommerce,
										value: FulfillmentWorkOrderType.Ecommerce,
									},
								]}
								selected={selectedWorkOrderTypes || []}
								onChange={(selected) => {
									setFilters([{ key: WORK_ORDER_TYPE, value: selected.join(' ') }]);
								}}
							/>
						),
						shortcut: true,
					},
			  ]
			: []),
	];

	const appliedFilters = useMemo(
		() => [
			...(selectedPickStrategies
				? [
						{
							key: PICK_STRATEGY_KEY,
							label: selectedPickStrategies
								.map((orderType) => getPickStrategyName(orderType))
								.join(', '),
							onRemove: () => {
								setFilters([{ key: PICK_STRATEGY_KEY, value: '' }]);
							},
						},
				  ]
				: []),
			...(cutoffDate && cutoffDates?.dates.includes(cutoffDate)
				? [
						{
							key: CUTOFF_KEY,
							label: messages.carrierCutoff + ': ' + formatDateTime(cutoffDate),
							onRemove: () => {
								setFilters([{ key: CUTOFF_KEY, value: '' }]);
							},
						},
				  ]
				: []),
			...(projectionIds && projectionIds.length
				? [
						{
							key: PROJECTION_IDS_KEY,
							label: projectionIds.join(', '),
							onRemove: () => {
								setFilters([{ key: PROJECTION_IDS_KEY, value: '' }]);
							},
						},
				  ]
				: []),
			...(selectedWorkAreas && selectedWorkAreas.length
				? [
						{
							key: WORKAREA_KEY,
							label: workAreas
								.filter((area) => selectedWorkAreas.includes(area.id))
								.map((area, i) => area.name || `Work area ${i + 1}`)
								.join(', '),
							onRemove: () => {
								setFilters([{ key: WORKAREA_KEY, value: '' }]);
							},
						},
				  ]
				: []),
			...(batchId
				? [
						{
							key: BATCH_ID_KEY,
							label: `${messages.batchId}: ${batchId}`,
							onRemove: () => {
								setFilters([
									{ key: BATCH_ID_KEY, value: '' },
									{ key: BULK_ORDER_PROJECTION_ID_KEY, value: '' },
								]);
							},
						},
				  ]
				: []),
			...(workOrderType
				? [
						{
							key: WORK_ORDER_TYPE,
							label:
								workOrderType === FulfillmentWorkOrderType.InventoryTransferToNode
									? messages.workOrderTypes.transfer
									: messages.workOrderTypes.ecommerce,
							onRemove: () => {
								setFilters([{ key: WORK_ORDER_TYPE, value: '' }]);
							},
						},
				  ]
				: []),
		],
		[
			cutoffDate,
			cutoffDates?.dates,
			getPickStrategyName,
			projectionIds,
			selectedPickStrategies,
			selectedWorkAreas,
			setFilters,
			workAreas,
			messages,
			formatDateTime,
			batchId,
			workOrderType,
		],
	);

	const sortChoices: SortChoice[] = [
		{
			label: messages.sortOptions.dateReceivedDesc,
			value: `${OrderOrderByFields.JobCreatedAt} ${OrderByDirection.Desc}`,
		},
		{
			label: messages.sortOptions.dateReceivedAsc,
			value: `${OrderOrderByFields.JobCreatedAt} ${OrderByDirection.Asc}`,
		},
		{
			label: messages.sortOptions.cutoffAsc,
			value: `${OrderOrderByFields.ExpectedShipDate} ${OrderByDirection.Asc}`,
		},
		{
			label: messages.sortOptions.cutoffDesc,
			value: `${OrderOrderByFields.ExpectedShipDate} ${OrderByDirection.Desc}`,
		},
		{
			label: messages.sortOptions.orderNumberAsc,
			value: `${OrderOrderByFields.ExternalId} ${OrderByDirection.Asc}`,
		},
		{
			label: messages.sortOptions.orderNumberDesc,
			value: `${OrderOrderByFields.ExternalId} ${OrderByDirection.Desc}`,
		},
	];

	// Table - Columns / Rows
	const headings = [
		{ title: messages.order },
		{ title: messages.receivedAt },
		{ title: messages.carrierCutoff },
		{ title: messages.quantity },
		...(consolidationEnabled
			? [
					{
						title: messages.totes,
					},
			  ]
			: []),
		...(config?.inventoryEnabled
			? [
					{
						title: messages.workOrderType,
					},
			  ]
			: []),
		...(config?.sortationEnabled
			? [
					{
						title: messages.sortWall,
					},
			  ]
			: []),
		{
			title: messages.progress,
		},
		{
			title: messages.exceptions,
		},
		...(availablePickStrategies.length > 1
			? [
					{
						title: messages.pickStrategy,
					},
			  ]
			: []),
		...(config?.inventoryEnabled && config?.healingEnabled
			? [
					{
						title: messages.store,
					},
			  ]
			: []),
		...(config?.workAreasEnabled
			? [
					{
						title: messages.workArea,
					},
			  ]
			: []),
		...(config?.orderTagEnabled
			? [
					{
						title: messages.tag,
					},
			  ]
			: []),
	];

	const rows = (edges || []).map((order, index) => {
		const {
			id,
			customerIdentifier,
			expectedShipDate,
			timeline,
			lines,
			status,
			exception,
			type,
			customers,
			workAreas,
			tag,
			destinationNode,
		} = order.node;

		const numberOfExceptions = countLineExceptions(getExceptionLines(order.node));

		const countExceptionsMsg = translate(messages.countExceptions, {
			count: numberOfExceptions,
		});

		return (
			<IndexTable.Row
				id={id}
				key={id}
				selected={allResourcesSelected || selectedOrderIds.includes(id)}
				position={index}
			>
				<IndexTable.Cell>
					<Link key={id} url={routes.order(id)} removeUnderline>
						{customerIdentifier}
					</Link>
					{destinationNode ? (
						<div>
							<TextStyle variation="subdued">
								{`${messages.destination}: ${destinationNode}`}
							</TextStyle>
						</div>
					) : null}
				</IndexTable.Cell>
				<IndexTable.Cell>
					<div key={id}>
						<DateTime date={timeline?.createdAt} />
						<RelativeDateTime date={timeline?.createdAt} />
					</div>
				</IndexTable.Cell>
				<IndexTable.Cell>
					<div key={id}>
						<DateTime date={expectedShipDate} />
						<RelativeDateTime date={expectedShipDate} mode="deadline" />
					</div>
				</IndexTable.Cell>
				<IndexTable.Cell>
					<OrderQuantity key={id} lines={lines} />
				</IndexTable.Cell>
				{consolidationEnabled && (
					<IndexTable.Cell>
						<OrderTotes key={id} order={order.node} />
					</IndexTable.Cell>
				)}
				{config?.inventoryEnabled && (
					<IndexTable.Cell>
						<WorkOrderType key={id} order={order.node} />
					</IndexTable.Cell>
				)}
				{config?.sortationEnabled && (
					<IndexTable.Cell>
						{order.node.type === PickStrategy.Sortation ? (
							<Link
								url={routes.sortWall((order.node.servicedBy[0] as Sortation).jobId)}
								key="wallId"
							>
								{(order.node.servicedBy[0] as Sortation).wall}
							</Link>
						) : (
							<NoData />
						)}
					</IndexTable.Cell>
				)}
				<IndexTable.Cell>
					<OrderStatusBadge key={id} orderStatus={status} />
				</IndexTable.Cell>
				<IndexTable.Cell>
					{exception?.status === OrderExceptionStatus.Cleared ? (
						<TextStyle variation="positive">{messages.cleared}</TextStyle>
					) : numberOfExceptions === 0 ? (
						<NoData />
					) : (
						<TextStyle variation="negative">
							{config?.healingEnabled ? (
								<Tooltip content={messages.manageExceptionsForOrder}>
									<Link url={routes.exception(id)} monochrome>
										{countExceptionsMsg}
									</Link>
								</Tooltip>
							) : (
								countExceptionsMsg
							)}
						</TextStyle>
					)}
				</IndexTable.Cell>
				{availablePickStrategies.length > 1 && (
					<IndexTable.Cell>
						{type ? <div>{getPickStrategyName(type)}</div> : <NoData />}
					</IndexTable.Cell>
				)}
				{config?.inventoryEnabled && config?.healingEnabled && (
					<IndexTable.Cell>
						{customers.length > 1 ? (
							<div>{messages.multiple}</div>
						) : customers[0] ? (
							<div>{customers[0]?.name}</div>
						) : (
							<NoData />
						)}
					</IndexTable.Cell>
				)}
				{config?.workAreasEnabled && (
					<IndexTable.Cell>
						{workAreas.length > 0 ? (
							<div>{workAreas.map((area) => area.name).join(', ')}</div>
						) : (
							<NoData />
						)}
					</IndexTable.Cell>
				)}
				{config?.orderTagEnabled && (
					<IndexTable.Cell>{tag ? <div>{tag}</div> : <NoData />}</IndexTable.Cell>
				)}
			</IndexTable.Row>
		);
	});

	// Table - Bulk Actions
	const promotedBulkActions = [
		{
			content: messages.cancelOrders,
			onAction: () => {
				setIsBulkCancelConfirmModalOpen(true);
			},
			disabled: !isUserAllowed([UserRole.Admin, UserRole.WarehouseManager]),
		},
	];

	// Previous filters
	const prevSelectedView = usePrevious(view);
	const prevQuery = usePrevious(query);
	const prevAppliedFilters = usePrevious(appliedFilters);
	const prevHistoryDateLimiter = usePrevious(historyDateLimiter);

	useEffect(() => {
		// Reset pagination when changing views or filters
		const hasQueryChanged =
			((query?.length || 0) >= MIN_QUERY_LENGTH || (prevQuery?.length || 0) >= MIN_QUERY_LENGTH) &&
			prevQuery !== query;

		const haveFiltersChanged = (appliedFilters || []).some((af) => {
			const prev = prevAppliedFilters?.find((paf) => paf.key === af.key);
			return !prev || prev?.label !== af.label;
		});

		if (
			prevSelectedView !== view ||
			hasQueryChanged ||
			haveFiltersChanged ||
			prevHistoryDateLimiter !== historyDateLimiter
		) {
			setPaginationCursors(() => []);
			handleSelectionChange(SelectionType.All, false);
		}
	}, [
		appliedFilters,
		handleSelectionChange,
		historyDateLimiter,
		prevAppliedFilters,
		prevHistoryDateLimiter,
		prevQuery,
		prevSelectedView,
		query,
		view,
	]);

	// Totals board
	const toteboardItems = [
		{
			label: messages.totalOrders,
			primaryValue: (
				<span>{formatNumber(StaticTotalCount.count + StaticCompletedTodayCount.count)}</span>
			),
		},
		{
			label: messages.unassigned,
			primaryValue: <span>{formatNumber(StaticUnassignedCount.count)}</span>,
		},
		{
			label: messages.inProgress,
			primaryValue: (
				<span>
					{formatNumber(
						StaticInProgressCount.count + (config?.healingEnabled ? ExceptionsCount.count : 0),
					)}
				</span>
			),
		},
		{
			label: messages.completedToday,
			primaryValue: <span>{formatNumber(StaticCompletedTodayCount.count)}</span>,
		},
	];

	// Date Range
	const options: OptionValues[] = [
		OptionValues.today,
		OptionValues.last3Days,
		OptionValues.last7Days,
		OptionValues.last30Days,
		OptionValues.last90Days,
		OptionValues.last180Days,
	];

	function handleDateRangeSelectionChanged(dateRange: DateRange, selectedOption: OptionLabelValue) {
		const { start } = dateRange;

		if (!start) {
			return;
		}

		const startDateMidnight: Date = new Date(start);
		startDateMidnight.setHours(0, 0, 0, 0);

		if (getDateDiffInDays(midnight, startDateMidnight) >= 30) {
			setProposedHistoryLimitDate({ date: startDateMidnight, option: selectedOption.value });
		} else {
			setFilters([{ key: JOB_CREATED_AT_FROM_KEY, value: startDateMidnight.toISOString() }]);
			setFilters([{ key: CUTOFF_KEY, value: '' }]);
		}
	}

	function handleDiscardJobHistoryLimitChange() {
		setProposedHistoryLimitDate(null);
	}

	function handleChangeJobHistoryLimit() {
		if (proposedHistoryLimitDate) {
			setFilters([
				{ key: JOB_CREATED_AT_FROM_KEY, value: proposedHistoryLimitDate.date.toISOString() },
			]);
			setFilters([{ key: CUTOFF_KEY, value: '' }]);
		}

		setProposedHistoryLimitDate(null);
	}

	// Empty state
	const emptyStateMarkup = <EmptySearchResult title={messages.noOrdersFound} withIllustration />;

	// Guards
	const fetching =
		cutoffDatesFetching || ordersFetching || countsFetching || filteredCountsFetching;
	const error =
		cutoffDatesError || ordersError || countsError || cancelOrdersError || filteredCountsError;

	if (error) {
		return <Error graphQLError={error} />;
	}

	return (
		<>
			<Page
				fullWidth
				title={messages.orders}
				primaryAction={
					config?.workAreasEnabled ? (
						<WorkAreaMenu
							workAreas={workAreas}
							selectedIds={selectedWorkAreas || []}
							onChange={(selected: string[]) => {
								setFilters([{ key: WORKAREA_KEY, value: selected.join(' ') }]);
							}}
						/>
					) : null
				}
			>
				<Layout>
					<Layout.Section>
						<Stack distribution="equalSpacing">
							<DateRangeSelect
								options={options}
								selectedOption={historyDateLimiter}
								title={messages.receivedAt}
								onChange={handleDateRangeSelectionChanged}
							/>

							<Stack distribution="trailing">
								<Link monochrome url={routes.actionLog()}>
									{messages.actionLog}
								</Link>

								<AutoRefresh
									pollingEnabled={pollingEnabled}
									togglePolling={togglePolling}
									discriminatorData={ordersData}
								/>
							</Stack>
						</Stack>
						<Toteboard items={toteboardItems} />
					</Layout.Section>

					<Layout.Section>
						<Card>
							<Card.Section>
								<div style={{ paddingBottom: '2rem' }}>
									<ViewTabs
										tabs={views}
										selected={view || views?.[0]?.id}
										onSelect={(id) => setFilters([{ key: VIEW_KEY, value: id }])}
									/>
								</div>
								<Filters
									queryValue={query || undefined}
									queryPlaceholder={messages.filterOrders}
									onQueryChange={(value) => setFilters([{ key: QUERY_KEY, value }])}
									onQueryClear={() => setFilters([{ key: QUERY_KEY, value: '' }])}
									filters={filters || []}
									appliedFilters={appliedFilters}
									onClearAll={onClearAll}
								>
									<SortBy
										choices={sortChoices}
										selected={sort ? [sort] : []}
										onChange={(selected) => setFilters([{ key: SORT_KEY, value: selected[0] }])}
									/>
								</Filters>
							</Card.Section>

							{isBulkCancelInProgress ? (
								<Card.Section>
									<Banner
										status="info"
										onDismiss={() => setIsBulkCancelInProgress(false)}
										action={{ url: routes.actionLog(), content: messages.viewActionLog }}
									>
										<p>{messages.cancelOrderSubmitted}</p>
									</Banner>
								</Card.Section>
							) : null}

							{didBulkCancelFail ? (
								<Card.Section>
									<ErrorBanner isVisible onDismiss={() => setDidBulkCancelFail(false)} />
								</Card.Section>
							) : null}

							<IndexTable
								emptyState={emptyStateMarkup}
								resourceName={{
									singular: messages.order,
									plural: messages.orders,
								}}
								itemCount={edges.length}
								hasMoreItems={pageInfo?.hasPreviousPage || pageInfo?.hasNextPage}
								selectedItemsCount={allResourcesSelected ? 'All' : selectedOrderIds.length}
								onSelectionChange={handleSelectionChange}
								loading={fetching}
								headings={headings as IndexTableProps['headings']}
								promotedBulkActions={promotedBulkActions}
							>
								{rows}
							</IndexTable>
							<Card.Section>
								<Pagination
									endCursor={pageInfo?.hasNextPage !== false ? pageInfo?.endCursor : undefined}
									cursors={paginationCursors}
									setCursors={setPaginationCursors}
									loading={fetching}
								/>
							</Card.Section>
						</Card>
					</Layout.Section>
					<Layout.Section>
						<TimezoneFooter />
					</Layout.Section>
				</Layout>
			</Page>
			<BulkOrderCancelConfirmationModal
				isOpen={isBulkCancelConfirmModalOpen}
				onClose={() => setIsBulkCancelConfirmModalOpen(false)}
				submitBulkCancelRequest={submitBulkCancelRequest}
				allResourcesSelected={allResourcesSelected}
				totalFilteredOrdersCount={totalFilteredOrdersCount}
				selectedOrderIds={selectedOrderIds}
			/>
			<OrderHistoryLimiterConfirmModal
				isOpen={!!proposedHistoryLimitDate}
				numOfDaysToRetrieve={
					proposedHistoryLimitDate ? getDateDiffInDays(midnight, proposedHistoryLimitDate.date) : 0
				}
				onClose={handleDiscardJobHistoryLimitChange}
				onSubmit={handleChangeJobHistoryLimit}
			/>
		</>
	);
}
