import { Card, Link } from '@shopify/polaris';
import { OrderV2, WorkArea } from '@sixriver/fulfillment-api-schema';
import { useState } from 'react';

import { CardDetails } from 'components/CardDetails';
import { DateTime } from 'components/DateTime';
import { RelativeDateTime } from 'components/RelativeDateTime';
import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';

interface Props {
	order: OrderV2;
	isWorkAreaEnabled?: boolean;
}

export function Details({ order, isWorkAreaEnabled = false }: Props) {
	const { messages, translate } = useLocalization();
	const { totalPicked, expectedPicked } = order.lines.reduce(
		({ totalPicked, expectedPicked }, line) => {
			return {
				totalPicked: totalPicked + line.doneQuantity,
				expectedPicked: expectedPicked + line.quantity,
			};
		},
		{ totalPicked: 0, expectedPicked: 0 },
	);
	const workAreas = order.tasks.reduce((workAreas, task) => {
		if (task.workArea) {
			return workAreas.add(task.workArea);
		}
		return workAreas;
	}, new Set<WorkArea>());
	const [primary] = useState([
		...(isWorkAreaEnabled
			? [
					{
						label: messages.workArea,
						content: (
							<>
								{Array.from(workAreas)
									.map((workArea) => workArea.name)
									.join(', ')}
							</>
						),
					},
			  ]
			: []),
		{
			label: messages.order,
			content: <Link url={routes.order(order.id)}>{order.externalId}</Link>,
		},
		{
			label: messages.initialLicensePlate,
			content: order.externalContainerId,
		},
		{
			label: messages.unitsPicked,
			content: translate(messages.pickedOfTotal, {
				picked: totalPicked,
				total: expectedPicked,
			}),
		},
		{
			label: messages.lines,
			content: order.lines.length,
		},
		{
			label: messages.exceptions,
			content: order.exceptions.length,
		},
	]);
	const [secondary] = useState([
		{
			label: messages.carrierCutoff,
			content: (
				<>
					<DateTime date={order.expectedShipDate} />
					<RelativeDateTime date={order.expectedShipDate} mode="deadline" />
				</>
			),
		},
		{
			label: messages.inductedAt,
			content: (
				<>
					<DateTime date={order.createdAt} />
					<RelativeDateTime date={order.createdAt} />
				</>
			),
		},
	]);

	return (
		<Card title={messages.details} sectioned>
			<CardDetails primary={primary} secondary={secondary} />
		</Card>
	);
}
