import { Count } from '@sixriver/fulfillment-api-schema';
import gql from 'graphql-tag';

export interface OrdersCounts {
	TotalCount: Count;
	StaticUnassignedCount: Count;
	UnassignedCount: Count;
	StaticAssignedToSortWallCount: Count;
	StaticAssignedToBatchCount: Count;
	StaticInProgressCount: Count;
	InProgressCount: Count;
	StaticInterruptedCount: Count;
	InterruptedCount: Count;
	StaticCompletedCount: Count;
	CompletedCount: Count;
	StaticCancelledCount: Count;
	CancelledCount: Count;
	AwaitingInventoryCount: Count;
	LateCount: Count;
	ShortedCount: Count;
}

export const ORDERS_COUNT_QUERY = gql`
	query OrdersCount(
		$ids: [String!]
		$searchText: String
		$statuses: [OrderV2Status!]
		$isLate: Boolean
		$isShorted: Boolean
		$isAwaitingInventory: Boolean
		$completedAtFrom: DateTime
		$completedAtTo: DateTime
		$expectedShipDateFrom: DateTime
		$expectedShipDateTo: DateTime
		$createdAtFrom: DateTime
		$pickStrategies: [PickStrategy!]
		$workOrderTypes: [WorkOrderType!]
		$workAreaIds: [String!]
	) {
		TotalCount: ordersV2Count(
			ids: $ids
			searchText: $searchText
			statuses: $statuses
			isLate: $isLate
			isShorted: $isShorted
			isAwaitingInventory: $isAwaitingInventory
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			createdAtFrom: $createdAtFrom
			pickStrategies: $pickStrategies
			workOrderTypes: $workOrderTypes
			workAreaIds: $workAreaIds
		) {
			count
		}

		StaticUnassignedCount: ordersV2Count(statuses: [Unassigned], createdAtFrom: $createdAtFrom) {
			count
		}

		UnassignedCount: ordersV2Count(ids: $ids, searchText: $searchText, statuses: [Unassigned]) {
			count
		}

		StaticAssignedToSortWallCount: ordersV2Count(
			statuses: [AssignedToSortWall]
			createdAtFrom: $createdAtFrom
		) {
			count
		}

		StaticAssignedToBatchCount: ordersV2Count(
			statuses: [AssignedToBatch]
			createdAtFrom: $createdAtFrom
		) {
			count
		}

		StaticInProgressCount: ordersV2Count(statuses: [InProgress], createdAtFrom: $createdAtFrom) {
			count
		}

		InProgressCount: ordersV2Count(ids: $ids, searchText: $searchText, statuses: [InProgress]) {
			count
		}

		StaticInterruptedCount: ordersV2Count(statuses: [Interrupted], createdAtFrom: $createdAtFrom) {
			count
		}

		InterruptedCount: ordersV2Count(ids: $ids, searchText: $searchText, statuses: [Interrupted]) {
			count
		}

		StaticCompletedCount: ordersV2Count(
			statuses: [Completed]
			createdAtFrom: $createdAtFrom
			completedAtFrom: $completedAtFrom
			completedAtTo: $completedAtTo
		) {
			count
		}

		CompletedCount: ordersV2Count(
			ids: $ids
			searchText: $searchText
			statuses: [Completed]
			completedAtFrom: $completedAtFrom
			completedAtTo: $completedAtTo
		) {
			count
		}

		StaticCancelledCount: ordersV2Count(
			statuses: [Canceled]
			createdAtFrom: $createdAtFrom
			completedAtFrom: $completedAtFrom
			completedAtTo: $completedAtTo
		) {
			count
		}

		CancelledCount: ordersV2Count(
			ids: $ids
			searchText: $searchText
			statuses: [Canceled]
			completedAtFrom: $completedAtFrom
			completedAtTo: $completedAtTo
		) {
			count
		}

		AwaitingInventoryCount: ordersV2Count(
			ids: $ids
			searchText: $searchText
			isAwaitingInventory: true
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			createdAtFrom: $createdAtFrom
			pickStrategies: $pickStrategies
		) {
			count
		}

		LateCount: ordersV2Count(
			ids: $ids
			searchText: $searchText
			isLate: true
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			createdAtFrom: $createdAtFrom
			pickStrategies: $pickStrategies
		) {
			count
		}

		ShortedCount: ordersV2Count(
			ids: $ids
			searchText: $searchText
			isShorted: true
			expectedShipDateFrom: $expectedShipDateFrom
			expectedShipDateTo: $expectedShipDateTo
			createdAtFrom: $createdAtFrom
			pickStrategies: $pickStrategies
		) {
			count
		}
	}
`;
