import { Chuck } from './Chuck.type';
import {
	isDwelling,
	isFaulty,
	isLowBattery,
	isVisible,
	durationIdleOrPaused,
	isHighPriority,
} from './ChuckUtils';
import styles from './FloorView.module.css';
import { SortMethod } from './FullScreenOptions';
import {
	sortByFaultyTimeIdleTime,
	sortByFaultyTimeBatteryLevel,
	sortByFaultyTimePriorityBattery,
	sortByPriorityFaultyTimeBatteryLevel,
} from './SortUtils';
import { useConfig } from 'hooks/useConfig';
import { useLocalization } from 'hooks/useLocalization';
import BLANK_IMG from 'images/fsBlank.svg';
import BLANK_BATTERY_IMG from 'images/fsBlankBattery.svg';
import BLANK_PRIORITY_IMG from 'images/fsBlankPriority.svg';
import FAULTY_IMG from 'images/fsFaulty.svg';
import LOW_BATTERY_IMG from 'images/fsLowBattery.svg';
import PRIORITY_IMG from 'images/fsPriority.svg';

interface Props {
	chucks: Chuck[];
	sortMethod: SortMethod;
}

const sortFnMap = {
	idleTime: sortByFaultyTimeIdleTime,
	batteryLevel: sortByFaultyTimeBatteryLevel,
	faulty: sortByFaultyTimePriorityBattery,
	priority: sortByPriorityFaultyTimeBatteryLevel,
};

export function FullScreenTable({ chucks, sortMethod }: Props) {
	const { messages, formatPercent } = useLocalization();
	const { config } = useConfig();
	const isUsingHighPriority = config?.highPriorityChuckEnabled;
	const siteName = config?.siteName || 'xxxxxx';
	const pattern = new RegExp(`-${siteName}|${siteName}-`, 'i');
	const maxWidth = window.innerWidth * 0.3 * 0.4;
	const rows = 15;
	const sortFn = sortFnMap[sortMethod];

	const results = chucks.filter(isVisible).sort(sortFn).slice(0, rows);

	return (
		<div className={styles.fsTable}>
			<table>
				<colgroup>
					<col width="40%" />
					<col width="5%" />
					<col width="10%" />
					<col width="5%" />
					<col width="40%" />
				</colgroup>
				<thead>
					<tr>
						<th>{messages.name}</th>
						<th>{messages.chuckStates.faulty}</th>
						<th>{isUsingHighPriority ? messages.chuckStates.priority : messages.idle}</th>
						<th>{messages.battery}</th>
						<th>{messages.destination}</th>
					</tr>
				</thead>
				<tbody>
					{results.map((chuck) => {
						const battery = chuck.telemetry?.batteryLevel;
						const minutes = Math.floor(durationIdleOrPaused(chuck) / 60000);

						return (
							<tr key={chuck.id}>
								<td style={{ maxWidth }}>{chuck.name?.replace(pattern, '')}</td>
								<td data-error>
									<img src={isFaulty(chuck) ? FAULTY_IMG : BLANK_IMG} alt="" />
								</td>
								{isUsingHighPriority ? (
									<td data-priority>
										<img src={isHighPriority(chuck) ? PRIORITY_IMG : BLANK_PRIORITY_IMG} alt="" />
									</td>
								) : (
									<td data-dwelling={isDwelling(chuck)}>
										{minutes < 60 ? minutes + 'm' : Math.floor(minutes / 60) + 'h'}
									</td>
								)}
								<td data-low-battery={isLowBattery(chuck)}>
									<img src={isLowBattery(chuck) ? LOW_BATTERY_IMG : BLANK_BATTERY_IMG} alt="" />
									{battery === undefined ? '–' : formatPercent(battery / 100)}
								</td>
								<td style={{ maxWidth }}>{chuck.telemetry?.destination || '?'}</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
}
