import { ReplenJobState } from '@sixriver/fulfillment-api-schema';

export enum ReplenishJobsViews {
	All = 'All',
	Unassigned = 'Unassigned',
	InProgress = 'InProgress',
	Complete = 'Complete',
	Exceptions = 'Exceptions',
	Cancelled = 'Cancelled',
	Interrupted = 'Interrupted',
}

export const ReplenishJobsViewStateMap: { [key in ReplenishJobsViews]: Array<ReplenJobState> } = {
	[ReplenishJobsViews.All]: [
		ReplenJobState.Assigned,
		ReplenJobState.Cancelled,
		ReplenJobState.Completed,
		ReplenJobState.Inducted,
		ReplenJobState.InProgress,
		ReplenJobState.Interrupted,
		ReplenJobState.Open,
	],
	[ReplenishJobsViews.Unassigned]: [ReplenJobState.Open],
	[ReplenishJobsViews.InProgress]: [
		ReplenJobState.Assigned,
		ReplenJobState.Inducted,
		ReplenJobState.InProgress,
	],
	[ReplenishJobsViews.Complete]: [ReplenJobState.Completed],
	[ReplenishJobsViews.Exceptions]: [ReplenJobState.Interrupted],
	[ReplenishJobsViews.Cancelled]: [ReplenJobState.Cancelled],
	[ReplenishJobsViews.Interrupted]: [ReplenJobState.Interrupted],
};
