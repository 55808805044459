import { IndexTable, Link } from '@shopify/polaris';
import { OrderV2, PickStrategy } from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';
import * as routes from 'routes';

interface Props {
	order: OrderV2;
}

export function SortWallCell({ order }: Props) {
	if (
		order.pickStrategy !== PickStrategy.Sortation ||
		!order.stagingIdentifier ||
		order.servicedBy.length === 0
	) {
		return (
			<IndexTable.Cell>
				<NoData />
			</IndexTable.Cell>
		);
	}

	return (
		<IndexTable.Cell>
			<Link url={routes.sortWall(order.servicedBy[0].id)}>{order.stagingIdentifier}</Link>
		</IndexTable.Cell>
	);
}
