import { IndexTable } from '@shopify/polaris';
import { LineV2 } from '@sixriver/fulfillment-api-schema';

import { calculateUnits } from 'helpers/order';
import { useLocalization } from 'hooks/useLocalization';

export function UnitsPickedCell({ lines }: { lines: LineV2[] }) {
	const { messages, translate } = useLocalization();
	const { picked, total } = calculateUnits(lines);

	return (
		<IndexTable.Cell>
			<p>{translate(messages.pickedOfTotal, { picked, total })}</p>
		</IndexTable.Cell>
	);
}
