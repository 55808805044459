import {
	EmptySearchResult,
	IndexTable,
	Link,
	TextStyle,
	useIndexResourceState,
} from '@shopify/polaris';
import { ReturnsSummaryPage, ReturnStatus } from '@sixriver/fulfillment-api-schema';

import { NoData } from 'components/NoData';
import { RelativeDateTime } from 'components/RelativeDateTime';
import { ReturnStatusBadge } from 'components/ReturnStatusBadge';
import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';

export interface Heading {
	title: string;
}

interface Props {
	data?: ReturnsSummaryPage;
	loading?: boolean;
}

export function InboundReturnsTable({ data, loading = false }: Props) {
	const { messages, translate } = useLocalization();

	const emptyStateMarkup = (
		<EmptySearchResult title={messages.noReturnShipmentsFound} withIllustration />
	);

	const { results = [] } = data || {};

	const { selectedResources, allResourcesSelected, handleSelectionChange } =
		useIndexResourceState(results);

	const headings: [Heading, ...Heading[]] = [
		{ title: messages.order },
		{ title: messages.store },
		{ title: messages.trackingId },
		{ title: messages.unitsReceived },
		{ title: messages.progress },
		{ title: messages.receivingTime },
		{ title: messages.returnType },
	];

	const rows = results.map(
		(
			{
				id,
				externalId,
				merchant,
				trackingNumber,
				quantityExpected,
				quantityReceived,
				returnStatus,
				eta,
				returnType,
			},
			index,
		) => (
			<IndexTable.Row id={id} key={id} selected={selectedResources.includes(id)} position={index}>
				<IndexTable.Cell>
					<Link url={routes.inboundReturn(id)} key="inbound" removeUnderline>
						{externalId}
					</Link>
				</IndexTable.Cell>
				<IndexTable.Cell>
					<TextStyle>{merchant}</TextStyle>
				</IndexTable.Cell>
				<IndexTable.Cell>
					{trackingNumber ? <TextStyle>{trackingNumber}</TextStyle> : <NoData />}
				</IndexTable.Cell>
				<IndexTable.Cell>
					<TextStyle>
						{translate(messages.xOfY, { x: quantityReceived, y: quantityExpected })}
					</TextStyle>
				</IndexTable.Cell>
				<IndexTable.Cell>
					<ReturnStatusBadge status={returnStatus} />
				</IndexTable.Cell>
				<IndexTable.Cell>
					{returnStatus === ReturnStatus.InTransit ? (
						<div>
							<NoData />
							<br />
							<RelativeDateTime date={eta} mode="expectation" />
						</div>
					) : (
						<div>
							<NoData />
							<br />
							<NoData />
						</div>
					)}
				</IndexTable.Cell>
				<IndexTable.Cell>{messages.returnTypes[returnType]}</IndexTable.Cell>
			</IndexTable.Row>
		),
	);

	return (
		<IndexTable
			emptyState={emptyStateMarkup}
			itemCount={results.length}
			selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
			onSelectionChange={handleSelectionChange}
			loading={loading}
			headings={headings}
			selectable={false}
		>
			{rows}
		</IndexTable>
	);
}
