import {
	EmptySearchResult,
	Icon,
	IndexTable,
	IndexTableProps,
	Stack,
	TextStyle,
	Tooltip,
	useIndexResourceState,
} from '@shopify/polaris';
import { QuestionMarkMinor } from '@shopify/polaris-icons';
import { LaneEdge } from '@sixriver/fulfillment-api-schema';

import { ContainerQtyCell } from './ContainerQtyCell';
import { LaneCarrierCell } from './LaneCarrierCell';
import { LaneCarrierSortCell } from './LaneCarrierSortCell';
import { LaneNameCell } from './LaneNameCell';
import { LaneStatusCell } from './LaneStatusCell';
import { useLocalization } from 'hooks/useLocalization';

export interface LanesTableProps {
	loading: boolean;
	data?: LaneEdge[];
}

export function LanesTable({ loading, data = [] }: LanesTableProps) {
	const { messages } = useLocalization();
	const {
		selectedResources: selectedLaneIds,
		allResourcesSelected,
		handleSelectionChange,
	} = useIndexResourceState(data, { resourceIDResolver: (edge) => edge.node.laneId });
	const tooltipContent = 'Containers can be pallets, gaylords, trailers, etc.';
	const resourceName = {
		singular: messages.lane.toLowerCase(),
		plural: messages.lanes.toLowerCase(),
	};
	const emptyStateMarkup = <EmptySearchResult title={messages.noLanesFound} withIllustration />;
	const containerQtyHeading = (
		<Stack vertical={false}>
			<TextStyle>{messages.containerQty}</TextStyle>
			<Tooltip content={tooltipContent} dismissOnMouseOut>
				<Icon source={QuestionMarkMinor}></Icon>
			</Tooltip>
		</Stack>
	);
	const headings: IndexTableProps['headings'] = [
		{ title: messages.lane },
		{ title: messages.carrier },
		{ title: messages.carrierSort },
		{ title: containerQtyHeading },
		{ title: messages.laneStatus },
	] as IndexTableProps['headings'];

	const rows = data.map(({ node }, index) => {
		const { laneId, carrier, carrierSort, containers } = node;

		return (
			<IndexTable.Row
				id={laneId}
				key={laneId}
				position={index}
				selected={allResourcesSelected || selectedLaneIds.includes(laneId)}
			>
				<LaneNameCell laneId={laneId} />
				<LaneCarrierCell carrier={carrier} />
				<LaneCarrierSortCell carrierSort={carrierSort} />
				<ContainerQtyCell containers={containers} />
				<LaneStatusCell lane={node} />
			</IndexTable.Row>
		);
	});

	return (
		<IndexTable
			emptyState={emptyStateMarkup}
			resourceName={resourceName}
			headings={headings}
			hasMoreItems={false}
			itemCount={data.length}
			loading={loading}
			selectedItemsCount={allResourcesSelected ? 'All' : selectedLaneIds.length}
			onSelectionChange={handleSelectionChange}
			selectable={false}
		>
			{rows}
		</IndexTable>
	);
}
