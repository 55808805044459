import { Card, Icon, Stack } from '@shopify/polaris';
import { DiamondAlertMajor, CircleInformationMajor } from '@shopify/polaris-icons';
import { StorageLocation } from '@sixriver/fulfillment-api-schema';

import { getFullness } from 'helpers/location';
import { useLocalization } from 'hooks/useLocalization';
import { useLocationDiscrepancies } from 'hooks/useLocationDiscrepancies';

interface Props {
	location: StorageLocation;
}

export function LocationHealth({ location }: Props) {
	const { messages, translate, formatPercent } = useLocalization();

	const fullness = getFullness(location);
	const skuCount = location.products.length;
	const conflictReasons = location?.products
		.map((product) => product.inventory.conflictReasons)
		.flat();
	const reasons = Array.from(new Set(conflictReasons));
	const reasonMap = useLocationDiscrepancies();

	return (
		<Card title={messages.binHealth}>
			{reasons.length ? (
				<Card.Section>
					<Stack vertical>
						{conflictReasons.map((reason) => {
							return (
								<div key={reason} style={{ display: 'flex' }}>
									<span style={{ marginRight: '0.5em' }}>
										<Icon color="critical" source={DiamondAlertMajor} />
									</span>
									<span>{reasonMap[reason]}</span>
								</div>
							);
						})}
					</Stack>
				</Card.Section>
			) : null}
			{fullness === null ? null : (
				<Card.Section>
					{translate(messages.percentFull, { percent: formatPercent(fullness) })}
				</Card.Section>
			)}
			{skuCount > 0 ? (
				<Card.Section>{translate(messages.countInUseBySkus, { count: skuCount })}</Card.Section>
			) : (
				<Card.Section>
					<Stack>
						<Icon source={CircleInformationMajor} />
						<span>{messages.locationAvailable}</span>
					</Stack>
				</Card.Section>
			)}
		</Card>
	);
}
