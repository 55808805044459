import { Sortation, SortationStatus } from '@sixriver/fulfillment-api-schema';

export const useSortationJobsCount = (sortWall?: Sortation) => {
	const count = sortWall?.pickingJobs?.length;

	return count && sortWall?.status === SortationStatus.Picking
		? count + '+'
		: count &&
		  (sortWall?.status === SortationStatus.Picked ||
				sortWall?.status === SortationStatus.Sorting ||
				sortWall?.status === SortationStatus.Sorted ||
				sortWall?.status === SortationStatus.Exception)
		? count
		: undefined;
};
