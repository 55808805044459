import { Modal, TextContainer } from '@shopify/polaris';

import { useLocalization } from 'hooks/useLocalization';

interface Props {
	isOpen: boolean;
	onClose: () => void;
	onConfirm: () => void;
	loading?: boolean;
}

export function CancelOrderModal({ isOpen, onClose, onConfirm, loading }: Props) {
	const { messages } = useLocalization();

	return (
		<Modal
			open={isOpen}
			onClose={onClose}
			title={messages.cancelOrder}
			primaryAction={{
				content: messages.cancelOrder,
				onAction: onConfirm,
				destructive: true,
				loading,
			}}
			secondaryActions={[
				{
					content: messages.keepOrder,
					onAction: onClose,
				},
			]}
		>
			<Modal.Section>
				<TextContainer>{messages.confirmCancelOrder}</TextContainer>
			</Modal.Section>
		</Modal>
	);
}
