import { Card, Link, Stack, TextContainer, TextStyle } from '@shopify/polaris';
import { ReworkJob } from '@sixriver/fulfillment-api-schema';

import styles from './Rework.module.css';
import { NoData } from 'components/NoData';
import { useLocalization } from 'hooks/useLocalization';
import { JobType } from 'pages/ReworkJobs/ReworkJobsTable/JobType';
import * as routes from 'routes';

export function JobOrigin({ reworkJob }: { reworkJob?: ReworkJob }) {
	const { messages } = useLocalization();

	const stagedLocation = reworkJob?.stagedLocations?.[0];
	const stagedSubLocation = reworkJob?.stagedSubLocations?.[0];

	return (
		<Card title={messages.jobOrigin}>
			<Card.Section>
				<Stack vertical>
					<Stack.Item>
						<Stack>
							<div className={styles.detailsLabels}>
								<TextContainer>
									<TextStyle>{messages.workflowOrigin}</TextStyle>
								</TextContainer>
							</div>
							<div>
								<JobType jobType={reworkJob?.jobOriginType} />
							</div>
						</Stack>
					</Stack.Item>
					<Stack.Item>
						<Stack>
							<div className={styles.detailsLabels}>
								<TextContainer>
									<TextStyle>{messages.originId}</TextStyle>
								</TextContainer>
							</div>
							<div>
								<Link
									url={
										reworkJob?.jobOriginType === 'AdvanceShippingNotice'
											? routes.inboundShipment(reworkJob?.jobOriginId)
											: routes.inboundReturn(reworkJob?.jobOriginId)
									}
									key="rework"
									removeUnderline
									external
								>
									{reworkJob?.jobOriginExternalId}
								</Link>
							</div>
						</Stack>
					</Stack.Item>
					<Stack.Item>
						<Stack>
							<div className={styles.detailsLabels}>
								<TextContainer>
									<TextStyle>{messages.location}</TextStyle>
								</TextContainer>
							</div>
							<div>
								{stagedLocation ? (
									<Link url={routes.location(stagedLocation.id)} removeUnderline>
										{stagedLocation.address}
									</Link>
								) : (
									<NoData />
								)}
							</div>
						</Stack>
					</Stack.Item>
					<Stack.Item>
						<Stack>
							<div className={styles.detailsLabels}>
								<TextContainer>
									<TextStyle>{messages.container}</TextStyle>
								</TextContainer>
							</div>
							<div>
								{stagedSubLocation ? <p>{stagedSubLocation.definition.externalId}</p> : <NoData />}
							</div>
						</Stack>
					</Stack.Item>
				</Stack>
			</Card.Section>
		</Card>
	);
}
