import { useEffect, useRef, useState } from 'react';

import { Telemetry } from './Telemetry';
import { useConfig } from 'hooks/useConfig';

/**
 * This hook listens for messages on the Map Manager's Socket.IO endpoint and
 * returns a new Telemetry payload for each message. (It does NOT use the
 * Socket.IO client library.) This data is then merged with the known list of
 * devices provided by the Fulfillment API.
 */
export function useTelemetry(): Telemetry | null {
	const { config } = useConfig();
	const [event, setEvent] = useState<Telemetry | null>(null);
	const host = config?.mapManagerUrl;
	const interval = useRef<any>();

	useEffect(() => {
		let socket: WebSocket;

		const onVisibilityChange = () => {
			if (socket.readyState !== socket.OPEN) {
				// reconnect if the socket closed
				connectSocket();
			}
		};

		const connectSocket = () => {
			socket = new WebSocket(
				`${host?.replace('http', 'ws').replace('/v1', '')}/socket.io/?transport=websocket`,
			);

			socket.onmessage = (evt) => {
				const messageTypeId = evt.data.match(/\d+/)[0];

				// sample message: '42["message",{"data":{"id":"mfp-debug","name": ...'
				if (messageTypeId === '42') {
					const response = JSON.parse(evt.data.substr(2));
					const payload = response[1];
					const data = payload.data;

					const event: Telemetry = {
						id: data.name, // mfp ID
						updatedAt: new Date(data.updated),
						batteryLevel: data.batteryPercentage,
						x: data.pose.x,
						y: data.pose.y,
						orientation: data.pose.orientation,
						destination: data.destination,
						motionState: data.motionState,
						faultyAt: getFaultyAt(data),
						pausedAt: getIdleAt(data, 'paused'),
						idleAt: getIdleAt(data, 'idle'),
						faults: Object.values(data.faultCodes),
						wifiSignal: data.wifiSignal,
						wifiConnected: data.wifiConnected,
						stageUpgradeInfo: data.stageUpgradeInfo,
						highPriorityReasons: data.highPriority ? [...data.highPriority.reasons] : [],
					};

					if (event.x && event.y) {
						setEvent(event);
					}
				}
			};

			clearInterval(interval.current);

			interval.current = setInterval(() => {
				// ping the socket to keep it open
				if (socket.readyState === socket.OPEN) {
					socket.send('2');
				}
			}, 20000);

			socket.onerror = (evt) => {
				// eslint-disable-next-line
				console.error(evt);
			};
		};

		if (host) {
			try {
				connectSocket();

				document.addEventListener('visibilitychange', onVisibilityChange);
			} catch (e) {
				// eslint-disable-next-line
				console.error(e);
			}
		}

		return () => {
			if (socket) {
				socket.close();
			}

			clearInterval(interval.current);

			document.removeEventListener('visibilitychange', onVisibilityChange);
		};
	}, [host]);

	return event;
}

function getFaultyAt(data: any): Date | undefined {
	const faults = Object.values(data.faultCodes);
	const timestamps = faults.map((f: any) => f.timestamp).sort();

	return timestamps.length ? new Date(timestamps[0]) : undefined;
}

function getIdleAt(data: any, motionState: 'idle' | 'paused'): Date | undefined {
	const updated = data.fieldUpdated.find((f: any) => f[0] === 'motionState');

	return updated && data.motionState === motionState ? new Date(updated[1]) : undefined;
}
