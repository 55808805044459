import { IndexTable } from '@shopify/polaris';
import { ReceivingIssueReason } from '@sixriver/fulfillment-api-schema';

import { useLocalization } from 'hooks/useLocalization';

export function IssueCell({ issue }: { issue: ReceivingIssueReason }) {
	const { messages } = useLocalization();

	return (
		<IndexTable.Cell>
			<p>{messages.receivingIssueReason[issue]}</p>
		</IndexTable.Cell>
	);
}
