import { Order, OrderStatus } from '@sixriver/fulfillment-api-schema';

import { countLineExceptions, getExceptionLines } from 'helpers/exception';

export enum SortWallOrderViews {
	All = 'All',
	InProgress = 'InProgress',
	Complete = 'Complete',
	Exceptions = 'Exceptions',
}

export const SortWallOrderViewStateMap: {
	[key in SortWallOrderViews]: (orders?: Order[]) => Order[];
} = {
	[SortWallOrderViews.All]: (orders) => orders || [],
	[SortWallOrderViews.InProgress]: (orders) =>
		(orders || []).filter((order) => order.status === OrderStatus.InProgress),
	[SortWallOrderViews.Complete]: (orders) =>
		(orders || []).filter((order) => order.status === OrderStatus.Completed),
	[SortWallOrderViews.Exceptions]: (orders) =>
		(orders || []).filter((order) => countLineExceptions(getExceptionLines(order)) > 0),
};
