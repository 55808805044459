module.exports = {
	"acceptedFileFormats": "Accepted file formats: JPEG, PNG, PDF",
	"account": "Account",
	"action": "Action",
	"actionLog": "Action Log",
	"active": "Active",
	"activeEmployees": "Active employees",
	"activeOrders": "Active orders",
	"activity": "Activity",
	"actualArrival": "Actual arrival",
	"addAttachments": "Add attachments",
	"addContainer": "Add container",
	"addEmployee": "Add employee",
	"addImage": "Add image",
	"addImageFromUrl": "Add image from URL",
	"addImages": "Add images",
	"addInternalNote": "Add internal note",
	"addLocation": "Add location",
	"addNote": "Add note",
	"addNoteFailed": "There was an error adding the note",
	"addNoteToShipment": "Add note to shipment",
	"addProduct": "Add product",
	"address": "Address",
	"aisleId": "Aisle ID",
	"alert": "Alert",
	"all": "All",
	"allChucks": "All chucks",
	"allContainers": "All containers",
	"allLocations": "All locations",
	"allProducts": "All products",
	"allReworkJobs": "All rework jobs",
	"allWorkAreas": "All work areas",
	"allocationRules": "Allocation rules",
	"allowAccessToDevices": "Allow access to 6RS devices (Chucks, terminals, handhelds)",
	"allowAccessToTheBridge": "Allow access to The Bridge",
	"analytics": "Analytics",
	"apply": "Apply",
	"arrived": "Arrived",
	"arrivingToday": "Arriving today",
	"arrivingLate": "Arriving late",
	"arrivingWithinDays": {
		"one": "Arriving within {count} day",
		"other": "Arriving within {count} days"
	},
	"asnIssueReason": {
		"Barcode": "Missing barcode",
		"CancelledByAPI": "Canceled by API",
		"CancelledByAdmin": "Canceled by Admin",
		"Damaged": "Damaged",
		"Ineligible": "Ineligible SKU",
		"ItemNotOnAsn": "Item not on ASN",
		"MixedCartons": "Mixed cartons",
		"NeedsRework": "Needs rework",
		"NoAppointment": "No appointment",
		"Other": "Other issue",
		"Overage": "Overage",
		"ShipmentNotLabelled": "Shipment not labeled",
		"Shortage": "Shortage",
		"UnsafeToUnload": "Unsafe to unload"
	},
	"asnType": "ASN type",
	"assignBatchId": "Assign Batch ID",
	"assigned": "Assigned",
	"assignedBy": "Assigned by",
	"assignedKiosk": "Assigned kiosk",
	"assignedToBatch": "Assigned to batch",
	"assignedToWall": "Assigned to wall",
	"associate": "Associate",
	"associateName": "Associate name",
	"associateNotFound": "Associate not found",
	"atRiskSLA": "At risk of missing SLA",
	"attachImages": "Attach images",
	"attachments": "Attachments",
	"auditCount": "Audit count",
	"auditRequired": "Audit required",
	"autoRefreshDisabled": "Auto-refresh disabled",
	"autoRefreshEnabled": "Auto-refresh enabled",
	"autoRefreshHint": "Auto-refresh occurs every 20 seconds when enabled",
	"available": "Available",
	"awaitingInventory": "Awaiting inventory",
	"back": "Back",
	"badgeBarcode": "Badge barcode",
	"balance": "Balance",
	"barcode": "Barcode",
	"barcodeHelpText": "Login barcodes must be unique per employee",
	"barcodeUniquePerUser": "Badge barcode must be unique per user",
	"barcodesUniquePerContainer": "Barcodes must be a unique per container",
	"batch": "Batch",
	"batchCreated": "Batch created",
	"batchCreatedPrintFailed": "Batch created but LPN failed to print",
	"batchId": "Batch ID",
	"batchState": "Batch state",
	"battery": "Battery",
	"batteryLevel": "Battery level",
	"binHealth": "Bin health",
	"breakdown": "Breakdown",
	"bridgeAccess": "Bridge access",
	"bridgeAccessDescription": "Bridge users will be sent an email to set up their password to access The Bridge.",
	"browserOffline": "Browser offline",
	"bulkCancelErrors": {
		"alreadyCancelled": "Already canceled",
		"alreadyCompleted": "Already completed",
		"alreadyInducted": "Already inducted",
		"notFound": "ID not found",
		"unknown": "Unknown"
	},
	"bulkCancelStatuses": {
		"pending": "Pending",
		"in-progress": "In Progress",
		"closed": "Closed"
	},
	"bulkOrderWorkNotStarted": "Work has not yet started",
	"bulkOrders": "Bulk orders",
	"cancellationRequested": "Cancellation requested",
	"cancel": "Cancel",
	"cancelJob": "Cancel job",
	"cancelOrder": "Cancel order",
	"cancelOrderSubmitted": "Order cancellation submitted. This can take some time.",
	"cancelOrders": "Cancel orders",
	"cancelReplenJob": "Cancel replenish job",
	"cancelShipment": "Cancel shipment",
	"cancelSortWall": "Cancel sort wall",
	"cancelSortWallConfirm": "Canceled walls will not be processed. Do you want to continue?",
	"cancelWall": "Cancel wall",
	"canceled": "Canceled",
	"canceledToday": "Canceled today",
	"capacityType": "Capacity type",
	"carrier": "Carrier",
	"carrierCutoff": "Carrier cutoff",
	"carrierSort": "Carrier sort",
	"changeFilters": "Try changing the filters or search term",
	"changePassword": "Change password",
	"checkConnection": "Check your network connection",
	"checkNetwork": "Check your Wi-Fi or local network status and refresh the page.",
	"checkYourEmail": "Check your email",
	"choosePasswordForEmail": "Choose a password for {email}",
	"chuck": "Chuck",
	"chuck5Documentation": "Chuck 5 Documentation",
	"chuckFaults": {
		"BACK_EMERGENCY_STOP": "Back emergency stop",
		"BATTERY_LOW": "Low battery",
		"BUMP_SENSOR": "Bump sensor",
		"FRONT_EMERGENCY_STOP": "Front emergency stop",
		"HARD_STOP": "Hard stop",
		"NAVIGATION_ANY": "Navigation failure",
		"PAUSE": "Emergency pause"
	},
	"chuckHighPriorityReasons": {
		"dwellTime": "Idle",
		"isSlaDanger": "SLA at risk",
		"isExpedite": "Expedite"
	},
	"chuckResolutions": {
		"NONE": "None",
		"REBOOT": "Reboot",
		"FATAL": "Fatal",
		"RELEASE": "Release emergency stop",
		"PAUSE": "Pause",
		"CHECK_CABLE": "Check cable",
		"CALIBRATE": "Calibrate",
		"CONTACT_SUPPORT": "Contact support",
		"NETWORK_CONNECTION": "Check network",
		"LOCALIZE": "Localize",
		"CHARGE": "Charge",
		"RELOCATE": "Relocate",
		"CHECK_CHARGE_PORT": "Check charging port",
		"LOCALIZE_HOME_LOCATIONS": "Localize home locations",
		"LOCALIZE_APRIL_TAGS": "Localize April tags",
		"PAIR_BLUETOOTH": "Pair Bluetooth"
	},
	"chuckPhases": {
		"booting": "Booting",
		"induct": "Induct",
		"offline": "Offline",
		"picking": "Picking",
		"prepick": "Meeting point",
		"prereplen": "Pre-replenishment",
		"replen": "Replenishment",
		"replenInduct": "Replenishment induct",
		"restage": "Restage"
	},
	"chuckQueue": "Chuck queue",
	"chuckStates": {
		"charging": "Charging",
		"dwelling": "Idle/paused {minutes}+ min",
		"dwellingIdle": "Idle",
		"idleInAisle": "Idle in aisle",
		"dwellingPaused": "Paused {minutes}+ min",
		"faulty": "Error",
		"lowBattery": "Low battery",
		"offline": "Offline",
		"online": "Online",
		"paused": "Paused",
		"priority": "Priority"
	},
	"chuckWorkspace": "Chuck workspace",
	"chucks": "Chucks",
	"clear": "Clear",
	"cleared": "Cleared",
	"close": "Close",
	"closeBatch": "Close batch",
	"closeReturn": "Close return",
	"closeShipment": "Close shipment",
	"closed": "Closed",
	"collectBatchDescription": "This batch will collect more orders for this product.",
	"collecting": "Collecting",
	"columns": "Columns",
	"completed": "Completed",
	"completedAt": "Completed",
	"completedSince": "Completed since",
	"completedToday": "Completed today",
	"condition": "Condition",
	"confirmArriveShipment": "Are you sure you want to mark this shipment as arrived?",
	"confirmBulkCancelOrders": {
		"one": "You are about to cancel {count} order.",
		"other": "You are about to cancel {count} orders."
	},
	"confirmBulkOrderCloseBatch": "Are you sure you want to stop this batch from collecting orders? This action cannot be undone.",
	"confirmCancelJobs": "Canceled jobs will not be processed. Do you want to continue?",
	"confirmCancelOrder": "Canceled orders will not be processed. Do you want to continue?",
	"confirmCancelReplen": "Items will not be replenished. Do you want to continue?",
	"confirmCancelReplenFromLocation": "If you cancel this job, items will not be replenished. Return items to {location}.",
	"confirmCancelShipment": "Shipment Notice cannot be changed after being canceled.",
	"confirmCloseReturn": "Are you sure you want to close this return?",
	"confirmCloseShipment": "Are you sure you want to close this inbound shipment? Issue notes and attachments will be visible to SFN support upon closing and no changes can be made.",
	"confirmDeactivateEmployee": "Are you sure you want to deactivate this employee?",
	"confirmDeleteContainer": "This container will be deleted. Do you want to continue?",
	"confirmDeleteLocation": "This location will be deleted. Do you want to continue?",
	"confirmDeleteProduct": "This product will be deleted. Do you want to continue?",
	"confirmDeleteUpload": "Are you sure you want to delete this upload?",
	"confirmDeleteWorkLog": "Are you sure you want to delete this work log? This action cannot be undone.",
	"confirmNewPassword": "Confirm new password",
	"confirmJobHistoryLength": "Jobs older than {count} days will take some time to retrieve.  Would you like to proceed?",
	"confirmOrderHistoryLength": "Orders older than {count} days will take some time to retrieve.  Would you like to proceed?",
	"confirmRemoveImage": "Are you sure you want to remove this image?",
	"confirmWait": "This can take some time. Do you want to continue?",
	"conflictState": "Conflict state",
	"conflicts": "Conflicts",
	"connectionInterrupted": "This page couldn't be displayed because the internet connection was interrupted.",
	"contactAdministrator": "Please contact your administrator for assistance.",
	"container": "Container",
	"containerBarcodeMustBeUnique": "Badge barcode must be unique per container",
	"containerCreated": "Container created",
	"containerDetails": "Container details",
	"containerDimensions": "Container dimensions",
	"containerExternalIdPurpose": "External ID is how a container is identified in pickwaves and other calls between 6RS and external software.",
	"containerHasSubections": "This container has subsections",
	"containerId": "Container ID",
	"containerImage": "Container image",
	"containerInactive": "Container inactive",
	"containerName": "Container name",
	"containerNotFound": "Container not found",
	"containerStatus": "Container status",
	"containerTracked": "This shipping container is tracked as inventory.",
	"containerType": "Container type",
	"containerTypes": {
		"picking": "Picking",
		"shipping": "Shipping",
		"storage": "Storage",
		"unknown": "Unknown"
	},
	"containers": "Containers",
	"containersNotFound": "No containers found",
	"containerQty": "Container qty",
	"coordinates": "Coordinates",
	"copiedToClipboard": "Copied to clipboard",
	"copyFailed": "Oops! Copy failed",
	"copyLicensePlate": "Copy license plate",
	"copyOrderNumber": "Copy order number",
	"copyShipmentNumber": "Copy shipment number",
	"copyToClipboard": "Copy to clipboard",
	"countASNs": {
		"one": "{count} ASN",
		"other": "{count} ASNs"
	},
	"countCanceled": {
		"one": "{count} canceled",
		"other": "{count} canceled"
	},
	"countCollected": {
		"one": "{count} collected",
		"other": "{count} collected"
	},
	"countExceptions": {
		"one": "{count} exception",
		"other": "{count} exceptions"
	},
	"countFailedToCancel": {
		"one": "{count} failed to cancel",
		"other": "{count} failed to cancel"
	},
	"countInUseBySkus": {
		"one": "In use by {count} SKU",
		"other": "In use by {count} SKUs"
	},
	"countJobs": {
		"one": "{count} job",
		"other": "{count} jobs"
	},
	"countLastHours": {
		"one": "Last {count} hour",
		"other": "Last {count} hours"
	},
	"countLines": {
		"one": "{count} line",
		"other": "{count} lines"
	},
	"countLocations": {
		"one": "{count} location",
		"other": "{count} locations"
	},
	"countOffline": {
		"one": "{count} offline chuck",
		"other": "{count} offline chucks"
	},
	"countOnline": {
		"one": "{count} online chuck",
		"other": "{count} online chucks"
	},
	"countOrders": {
		"one": "{count} order",
		"other": "{count} orders"
	},
	"countPacked": {
		"one": "{count} packed",
		"other": "{count} packed"
	},
	"countRequired": {
		"one": "{count} required",
		"other": "{count} required"
	},
	"countResults": {
		"one": "{count} result",
		"other": "{count} results"
	},
	"countShipments": {
		"one": "{count} shipment",
		"other": "{count} shipments"
	},
	"countSkus": {
		"one": "{count} SKU",
		"other": "{count} SKUs"
	},
	"countTrackingNumbers": {
		"one": "{count} tracking number",
		"other": "{count} tracking numbers"
	},
	"countUnits": {
		"one": "{count} unit",
		"other": "{count} units"
	},
	"countUnitsAdded": {
		"one": "{count} unit added",
		"other": "{count} units added"
	},
	"countUnitsCounted": {
		"one": "{count} unit counted",
		"other": "{count} units counted"
	},
	"countUnitsRemoved": {
		"one": "{count} unit removed",
		"other": "{count} units removed"
	},
	"create": "Create",
	"createBatch": "Create batch",
	"createBatchDescription": "Create a batch with single-line, single-unit orders.",
	"createCustomBatch": "Create custom batch",
	"createNewTag": "Create a new tag",
	"createWall": "Create wall",
	"createdAt": "Created",
	"createdBatches": "Created batches",
	"cutoff": "Cutoff",
	"damaged": "Damaged",
	"dashCountJobs": "Count jobs",
	"dashMoveJobs": "Move jobs",
	"dashboard": "Dashboard",
	"dataNotFound": "The data you requested was not found.",
	"dataNotSaved": "There was a problem saving your data.",
	"dataSaved": "Your data was saved.",
	"dataUpdated": "{name} updated",
	"date": "Date",
	"dateRange": "Date range",
	"dateRangeOptions": {
		"allTime": "All time",
		"custom": "Custom",
		"last180Days": "Last 180 days",
		"last30Days": "Last 30 days",
		"last3Days": "Last 3 days",
		"last60Days": "Last 60 days",
		"last7Days": "Last 7 days",
		"last90Days": "Last 90 days",
		"today": "Today",
		"yesterday": "Yesterday"
	},
	"deactivateEmployee": "Deactivate employee",
	"delete": "Delete",
	"deleteContainer": "Delete container",
	"deleteDisabledWorkLogHelpText": "System-logged tasks cannot be deleted.",
	"deleteLocation": "Delete location",
	"deleteProduct": "Delete product",
	"deleteUpload": "Delete upload",
	"deleteWorkLog": "Delete work log",
	"description": "Description",
	"destination": "Destination",
	"details": "Details",
	"device": "Device",
	"deviceAccess": "Device access",
	"deviceAccessDescription": "Badge barcode is used to log into 6RS devices and must be unique per employee.",
	"deviceNotFound": "Device not found",
	"deviceTypes": {
		"Chuck": "Chuck",
		"Handheld": "Handheld",
		"PackoutTerminal": "Pack station",
		"SortationKiosk": "Sort wall kiosk",
		"TerminalOnWheels": "Terminal on wheels"
	},
	"devices": "Devices",
	"devicesNotFound": "No devices found",
	"diffDue": "Due {dateDiff}",
	"diffExpected": "Expected {dateDiff}",
	"diffLastMoved": "Last moved {dateDiff}",
	"diffLastSeen": "Last seen {dateDiff}",
	"diffUpdated": "Updated {dateDiff}",
	"dimensions": "Dimensions",
	"disabled": "Disabled",
	"discard": "Discard",
	"disconnected": "Disconnected",
	"discrepancyTypes": {
		"Miscount": "Suspected discrepancy due to a miscount",
		"NegativeQuantity": "Suspected discrepancy due to negative quantity",
		"Other": "Suspected discrepancy at this location",
		"PickShort": "Suspected discrepancy due to a pick short"
	},
	"download": "Download",
	"durationInHours": "{decimalHours} hours",
	"earliestCutoff": "Earliest carrier cutoff",
	"edit": "Edit",
	"editEmployee": "Edit employee",
	"editIssues": "Edit issues",
	"editNote": "Edit note",
	"editWorkLog": "Edit work log",
	"email": "Email",
	"emailNotRecognized": "The email address  \"{emailAddress}\" is invalid or it may not be recognized by our system. Please contact your administrator for assistance.",
	"employee": "Employee",
	"employeeDeactivated": "Employee deactivated",
	"employeeDetails": "Employee details",
	"employeeName": "Employee name",
	"employeeReactivated": "Employee reactivated",
	"employeeRoles": {
		"admin": "Admin",
		"cfs_operator": "Warehouse Manager",
		"cfs_read_only": "Bridge Associate",
		"chuck_operator": "Floor Associate",
		"integration": "Integration",
		"user": "User",
		"user_admin": "Employee Manager"
	},
	"employeeTagsDescription": "Tags can be used to categorize employees into groups.",
	"employees": "Employees",
	"employeesNotFound": "No employees found",
	"enableContainer": "Enable container",
	"enabled": "Enabled",
	"endDate": "End date",
	"endTime": "End time",
	"enterEmailToResetPassword": "Enter your email to reset your password",
	"enterNote": "Enter a note.",
	"error": "Error",
	"errorSubtitle": "This task could not be completed. Please contact 6RS support.",
	"errorTitle": "Something went wrong.",
	"errorToast": "Oops! Something went wrong. Please contact 6RS support.",
	"estimatedArrival": "Estimated arrival",
	"exampleNotToScale": "Example (not to scale)",
	"exception": "Exception",
	"exceptionCleared": "Exception cleared",
	"exceptionReason": "Reason for exception",
	"exceptionReasons": {
		"damaged": "Damaged item",
		"full": "Tote too full",
		"miscount": "Miscount",
		"missing": "Missing item",
		"negativeQuantity": "Negative quantity",
		"offline": "Offline",
		"otherReason": "Other",
		"pickShort": "Pick short"
	},
	"exceptionStatus": "Exception status",
	"exceptionTypes": {
		"DAMAGED_PRODUCT": "Damaged",
		"MISSING_PRODUCT": "Missing",
		"WORKFLOW_EXCEPTION": "Other"
	},
	"exceptions": "Exceptions",
	"exceptionsMissing": "Not all kit parts are used. Report issues before marking project as complete.",
	"exceptionsNotFound": "No exceptions found",
	"exceptionsToday": "Exceptions today",
	"exceptionsTooltip": "Exceptions have been reported for this job.",
	"exit": "Exit",
	"exitDashboard": "Exit dashboard view",
	"expected": "Expected",
	"expectedBalance": "Expected balance",
	"expectedShipDate": "Expected ship date",
	"expired": "Expired",
	"externalId": "External ID",
	"fai": "FAI",
	"faiErrorMessage": "First article inspection must be completed before marking the project as complete.",
	"faiErrorTitle": "First article inspection is required",
	"faiStatusComplete": "First article inspection: complete",
	"faiStatusIncomplete": "First article inspection: incomplete",
	"feedback": "Feedback",
	"feedbackAction": "Give feedback",
	"feedbackHeading": "Tell us your feedback",
	"feedbackRequest": "Answer a few questions about your experience using The Bridge to inform enhancements and new features.",
	"fieldGreaterThanOrEqualToZero": "{field} must be greater than or equal to 0",
	"fieldRequired": "A value is required for {field}",
	"fileTooLarge": "This file is too large. It must be less than 5MB.",
	"fileTypeNotSupported": "This file type is not supported. File type must be .gif, .jpg or .png.",
	"filterContainers": "Filter containers by name or description",
	"filterDevices": "Filter devices by name",
	"filterEmployees": "Filter employees by name, email, badge or tag",
	"filterExceptions": "Filter exceptions by order ID, license plate or initial license plate",
	"filterInboundReturns": "Filter returns by order ID or tracking ID",
	"filterInboundShipments": "Filter shipments by receiving ID or store name",
	"filterLaneContainers": "Filter by container ID, vehicle ID",
	"filterLaneContainerOrdersByID": "Search by order number",
	"filterLaneContainerOrdersByTrackingNumber": "Search by tracking number",
	"filterLanes": "Filter by lane, carrier",
	"filterLocations": "Filter locations by address",
	"filterOrders": "Filter orders by order id, license plate or product id",
	"filterOutboundJobs": "Filter outbound jobs by license plate, order ID or Chuck ID",
	"filterProducts": "Filter products by item ID, SKU or Description",
	"filterReworkJobs": "Filter by ASN or Return Shipment ID",
	"filterReplenJobs": "Filter jobs by license plate, product ID or destination location",
	"filterSortWalls": "Filter sort walls by batch ID",
	"filterSpecialProjects": "Filter special projects by ID",
	"findThisChuck": "Find this chuck",
	"firstArticleInspection": "First article inspection",
	"floorView": "Floor view",
	"forgotPassword": "Forgot password?",
	"forgotYourPassword": "Forgot your password?",
	"frequentlyUsedTags": "Frequently used tags",
	"fullName": "Full name",
	"fullness": "Fullness",
	"fullnessTypes": {
		"empty": "Empty",
		"full": "Full",
		"lessThanFiftyPercentFull": "Less than 50% full",
		"lessThanTenPercentFull": "Less than 10% full"
	},
	"generateBarcode": "Generate barcode",
	"goBack": "Go back",
	"goalStates": {
		"creating": "Creating",
		"created": "Created",
		"ready": "Ready",
		"running": "Running",
		"paused": "Paused",
		"terminateRequested": "Terminate Requested",
		"terminating": "Terminating",
		"terminated": "Terminated",
		"complete": "Complete",
		"cancelRequested": "Cancel Requested",
		"cancelling": "Cancelling",
		"cancelled": "Cancelled"
	},
	"handheld": "Handheld",
	"handhelds": "Handhelds",
	"hasExceptions": "With exceptions",
	"hasValidationErrors": "Please correct the fields marked in red.",
	"height": "Height",
	"hide": "Hide",
	"hideDetails": "Hide details",
	"history": "History",
	"hold": "Hold",
	"hours": "Hours",
	"identifier": "Identifier",
	"identifiers": "Identifiers",
	"idle": "Idle",
	"image": "Image",
	"imageURL": "Image URL",
	"imageUploadError": "The following images couldn’t be uploaded:",
	"images": "Images",
	"inProgress": "In progress",
	"inTransit": "In transit",
	"inUse": "In use",
	"inactive": "Inactive",
	"inactiveEmployees": "Inactive employees",
	"inbound": "Inbound",
	"inboundShipment": "Inbound shipment",
	"inboundShipments": "Inbound shipments",
	"inboundArrivingToday": "Inbound arriving today",
	"internalTransfersArrivingToday": "Internal transfers arriving today",
	"induct": "Induct",
	"inducted": "Inducted",
	"inductedAt": "Inducted",
	"initialJob": "Initial job",
	"initialLicensePlate": "Initial license plate",
	"inspection": "Inspection",
	"inspectionCount": "Inspection count",
	"instructions": "Instructions",
	"instructionsNotFound": "Instructions are not available.",
	"internalNote": "Internal note",
	"internalNotes": "Internal notes",
	"internalTransfer": "Internal transfer",
	"interrupted": "Interrupted",
	"invalidDateFormat": "Invalid date format",
	"invalidEmail": "Invalid email",
	"invalidImage": "Invalid image",
	"invalidTimeRange": "Invalid time range",
	"inventory": "Inventory",
	"inventoryState": "Condition",
	"inventoryStateNames": {
		"damaged": "Damaged",
		"mixed": "Mixed",
		"reworkPending": "Rework pending",
		"sellable": "Sellable"
	},
	"inventoryTracking": "Inventory tracking",
	"inventoryTrackingStates": {
		"all": "All containers",
		"tracked": "Containers tracked as inventory",
		"untracked": "Containers not tracked as inventory"
	},
	"isOverridden": "isOverridden",
	"isNotOverridden": "isNotOverridden",
	"issue": "Issue",
	"issueNotesAndAttachmentsMessage": "Notes and attachments will be visible to the merchant when the inbound transfer is closed.",
	"issueReasons": {
		"MissingItem": "Missing item",
		"UnknownItem": "Unknown item"
	},
	"issues": "Issues",
	"item": "Item",
	"itemPicked": "Item {externalId} picked at {address}",
	"itemPickedWithException": "Item {externalId} picked at {address} with exception",
	"itemRepicked": "Item {externalId} re-picked at {address}",
	"itemRepickedWithException": "Item {externalId} re-picked at {address} with exception",
	"jobAssigned": "Job {jobId} assigned at induct",
	"jobBasedOnBatchingJob": "This job is created to pick outstanding items based on a batching job.",
	"jobCanceled": "Job canceled",
	"jobCreated": "Job created",
	"jobExceptionsCleared": "Exceptions reported for this job have been cleared.",
	"jobExceptionsReported": "Exceptions have been reported for this job.",
	"jobHasUnpickedItems": "This job has unpicked items",
	"jobInterruptedHint": "This job is no longer active because the Chuck shut down unexpectedly.",
	"jobOrigin": "Job origin",
	"jobProgress": "Job progress",
	"jobStatus": "Job status",
	"jobs": "Jobs",
	"justUpdated": "Just updated",
	"keepEditing": "Keep editing",
	"keepJob": "Keep job",
	"keepOrder": "Keep order",
	"keepOrders": "Keep orders",
	"keepSortWall": "Keep sort wall",
	"kit": "Kit",
	"kitIssues": {
		"damagedProduct": "Damaged",
		"missingProduct": "Missing",
		"otherIssue": "Other"
	},
	"kitsRequired": "Kits required",
	"labelPrinted": "Label printed",
	"labels": "Labels",
	"labelsPrinted": "Labels printed",
	"lane": "Lane",
	"laneNotFound": "Lane not found",
	"laneOverride": "Lane override",
	"lanePackageQty": "Lane package qty",
	"lanes": "Lanes",
	"laneStatus": "Status",
	"language": "Language",
	"last30Days": "Last 30 days",
	"last60Days": "Last 60 days",
	"last90Days": "Last 90 days",
	"lastActive": "Last active",
	"lastCalibrated": "Last calibrated",
	"lastHour": "Last hour",
	"lastScan": "Last scan",
	"late": "Late",
	"length": "Length",
	"licensePlate": "License plate",
	"line": "Line",
	"lines": "Lines",
	"linesNotFound": "No lines found",
	"linesPicked": "Lines picked",
	"linesReplenished": "Lines replenished",
	"loadMore": "Load more",
	"loading": "Loading",
	"loadingSlow": "orders older than {count} days may take a while",
	"location": "Location",
	"locationAvailable": "Location available",
	"locationCondition": "Condition",
	"locationDetails": "Location details",
	"locationHistory": "Location history",
	"locationInformation": "Location information",
	"locationNotFound": "Storage location not found",
	"locationType": "Location type",
	"locationTypes": {
		"bin": "Bin",
		"cart": "Cart",
		"damaged": "Damage bin",
		"dock": "Dock",
		"external": "External",
		"mfp": "Chuck",
		"pallet": "Pallet",
		"rack": "Rack",
		"reserve": "Reserve",
		"slot": "Slot",
		"staging": "Staging",
		"unknown": "Unknown",
		"warehouse": "Warehouse"
	},
	"locations": "Locations",
	"locationsEmptyTitle": "Locations empty",
	"locationsNotFound": "No locations found",
	"logWork": "Log work",
	"loggedOff": "Logged off",
	"loginBarcodesDescription": "Login barcodes are used to log into 6RS devices and must be a unique per employee.",
	"logsNotFound": "No logs found",
	"lpn": "LPN",
	"mailClass": "Mail class",
	"manageExceptions": "Manage exceptions",
	"manageExceptionsForOrder": "Manage exceptions for this order",
	"manuallyResolved": "Manually resolved",
	"markAsArrived": "Mark as arrived",
	"markAsComplete": "Mark as complete",
	"markResolved": "Mark resolved",
	"maxCharacter500": "500 character max",
	"meetingPoint": "Meeting point",
	"merchant": "Merchant",
	"missing": "Missing",
	"model": "Model",
	"moreActions": "More actions",
	"motionStates": {
		"charging": "Charging",
		"docking": "Docking",
		"idle": "Idle",
		"loading": "Loading",
		"paused": "Paused",
		"traveling": "Traveling"
	},
	"moveToTop": "Move to top",
	"multiple": "Multiple",
	"name": "Name",
	"network": "Network",
	"new": "New",
	"newContainer": "New container",
	"newPassword": "New password",
	"newProjects": "New projects",
	"no": "No",
	"noAccess": "No access",
	"noContainersFound": "No containers found",
	"noDataFound": "No data found",
	"noIssues": "No issues",
	"noExceptions": "No exceptions",
	"noJobsToAllocate": "No jobs to allocate to mobile sort wall.",
	"noLanesFound": "No lanes found",
	"noLinesFound": "No lines found",
	"noNotesToDisplay": "No notes to display",
	"noOrdersFound": "No orders found",
	"noOutboundJobsFound": "No outbound jobs found",
	"noReplenJobsFound": "No replenish jobs found",
	"noReturnedInventoryFound": "No returned inventory found",
	"noReturnShipmentsFound": "No return shipments found",
	"noSortWallsFound": "No sort walls found",
	"noWorkLogged": "No work logged",
	"notAuthorized": "You are not authorized to view this page.",
	"notInUse": "Not in use",
	"notReceived": "Not received",
	"notes": "Notes",
	"notesAndAttatchmentsVisible": "Notes and attatchments will be visible to SFN when the return shipment is closed.",
	"notesAndAttachmentsSent": "Notes and attatchments have been sent to SFN.",
	"of": "of",
	"offline": "Offline",
	"oops": "Oops!",
	"optional": "Optional",
	"order": "Order",
	"orderAccepted": "Order accepted",
	"orderAwaitingInventory": "This order is awaiting inventory",
	"orderCancelled": "Order canceled",
	"orderCompleted": "Order completed",
	"orderHasExceptions": "This order has exceptions",
	"orderHelperTextCompleted": "Orders received during the selected date range that were completed today",
	"orderHelperTextActive": "Includes orders for the selected date range that are unassigned, in progress, and were completed today.",
	"orderHelperTextActiveWithSortation": "Includes orders for the selected date range that are unassigned, assigned to wall, in progress, and were completed today.",
	"orderHelperTextActiveWithBulkOrder": "Includes orders for the selected date range that are unassigned, assigned to batch, in progress, and were completed today.",
	"orderHelperTextActiveWithBulkOrderAndSortation": "Includes orders for the selected date range that are unassigned, assigned to batch, assigned to wall, in progress, and were completed today.",
	"orderHistory": "Order history",
	"orderId": "Order ID",
	"orderNotFound": "Order not found",
	"orderPicked": "Order picked",
	"orderProgress": "Order progress",
	"orderReceived": "Order received",
	"orderSorted": "Order sorted",
	"orderStatus": "Order status",
	"orderReviewSummary": "Review order summary before marking order as complete",
	"orderReviewTitle": "Review order and mark as complete",
	"orderReviewWarning": "Once marked as complete, this order cannot be edited",
	"orders": "Orders",
	"ordersCollected": "Orders collected",
	"ordersPacked": "Orders packed",
	"originLocation": "Origin location",
	"originId": "Origin ID",
	"originalJob": "Original job",
	"originalOrder": "Original order",
	"otherType": "Other",
	"outbound": "Outbound",
	"outboundJobHistory": "Outbound job history",
	"outboundJobNotFound": "Outbound job not found",
	"outboundJobHasExceptions": "This outbound job has exceptions",
	"outboundJobs": "Outbound jobs",
	"outboundJobsTotalHelperText": "Includes jobs for the selected date excluding interrupted jobs.",
	"overview": "Overview",
	"packStation": "Pack station",
	"packStations": "Pack stations",
	"packageType": "Package type",
	"packageQty": "Package qty",
	"packed": "Packed",
	"packedBy": "Packed by",
	"packing": "Packing",
	"packingDetails": "Packing details",
	"packingEndedAt": "Packing ended",
	"packingStartedAt": "Packing started",
	"packoutCompleted": "Packout completed",
	"packoutCompletedAt": "Packout completed",
	"packoutRequestId": "Packout request ID",
	"packoutRequired": "Packout required",
	"parts": "Parts",
	"password": "Password",
	"passwordAccepted": "Password accepted",
	"passwordsDoNotMatch": "Passwords do not match",
	"passwordConfirmation": "Confirm password",
	"passwordPolicy": "Your password must be at least 8 characters long and include at least two of the following: an uppercase letter, a lowercase letter and a number.",
	"passwordRejected": "Invalid password",
	"passwordResetLinkSentToEmail": "A link to reset your password has been sent to {email}",
	"passwordsMatch": "Passwords match",
	"passwordsShouldMatch": "Passwords should match",
	"pasteImageUrl": "Paste image URL",
	"paused": "Paused",
	"pending": "Pending",
	"percentFull": "{percent} full",
	"performance": "Performance",
	"phoneNumber": "Phone number",
	"pickShortReported": "Pick short reported",
	"pickStrategies": {
		"bulk": "Bulk order",
		"consolidation": "Must ship complete",
		"discrete": "Single order",
		"group": "Allow split shipment",
		"singles": "Single-line batch",
		"sortation": "Sort wall picking"
	},
	"pickStrategy": "Pick strategy",
	"picked": "Picked",
	"pickedOfTotal": "{picked} of {total}",
	"picking": "Picking",
	"pickingCompletedAt": "Picking completed",
	"pickingContainers": "Picking containers",
	"pickingDetails": "Picking details",
	"pickingExceptions": "Picking exceptions",
	"pickingJobs": "Picking jobs",
	"pickingJobsNotFound": "No picking jobs found",
	"pickingLabel": "Picking label",
	"pickingProgress": "Picking progress",
	"plannedRemovals": "Planned removals",
	"pleaseCorrectForm": "Please correct the following items:",
	"possibleResolution": "Possible resolution",
	"printLabel": "Print label",
	"problemLoadingHistory": "There's a problem loading the history.",
	"problemLoadingPage": "There's a problem loading this page.",
	"proceed": "Proceed",
	"processed": "Processed",
	"product": "Product",
	"productAttributes": "Product attributes",
	"productDetails": "Product details",
	"productImage": "Product image",
	"productIsContainer": "This product is a shipping container.",
	"productType": "Product type",
	"productTypes": {
		"packaging": "Packout consumable",
		"product": "Sellable inventory"
	},
	"products": "Products",
	"productsDamagedOrMissing": "Were there any products that are damaged or missing?",
	"productsNotFound": "No products found",
	"profile": "Profile",
	"progress": "Progress",
	"progressLog": "Progress log",
	"projectId": "Project ID",
	"projectNotFound": "Project not found",
	"quantity": "Quantity",
	"quantityAdjusted": "Quantity adjusted to {quantity}",
	"reactivateEmployee": "Reactivate employee",
	"readyToLoad": "Ready to load",
	"readyToPack": "Ready to pack",
	"readyToSort": "Ready to sort",
	"reason": "Reason",
	"reasonForFailure": "Reason for failure",
	"received": "Received",
	"receivedAt": "Received",
	"receiving": "Receiving",
	"receivingId": "Receiving ID",
	"receivingIssue": "Receiving issue",
	"receivingIssueReason": {
		"Barcode": "Barcode",
		"CancelledByAPI": "Canceled by API",
		"CancelledByAdmin": "Canceled by Admin",
		"Damaged": "Damaged",
		"Ineligible": "Ineligible SKU",
		"ItemNotOnAsn": "Item not on ASN",
		"MissingItem": "Missing item",
		"NeedsRework": "Rework",
		"MixedCartons": "Mixed cartons",
		"NoAppointment": "No appointment",
		"Other": "Other issue",
		"Overage": "Overage",
		"ShipmentNotLabelled": "Shipment not labeled",
		"Shortage": "Shortage",
		"UnknownItem": "Unknown item",
		"UnsafeToUnload": "Unsafe to unload"
	},
	"receivingStartedAt": "Receiving started",
	"receivingTime": "Receiving time",
	"regarding": "Regarding",
	"reloadPage": "Reload this page",
	"remaining": "Remaining",
	"remainingCapacity": "Remaining capacity",
	"remove": "Remove",
	"removeAttachmentFailed": "There was an error removing the attachment",
	"removeImage": "Remove image",
	"replenExceptionTypes": {
		"DamagedProduct": "Damaged product",
		"LocationTooFull": "Location too full",
		"MissingProduct": "Missing product",
		"Unknown": "Unknown exception",
		"WrongProduct": "Wrong product"
	},
	"replenishJobs": "Replenish jobs",
	"report": "Report",
	"reportIssues": "Report issues",
	"reportKittingIssues": "Report issues when you mark the project as complete.",
	"reprintBatchBarcode": "Reprint batch barcode",
	"reprintLabel": "Reprint label",
	"reprinted": "Reprinted",
	"requestedAt": "Requested",
	"required": "Required",
	"reserved": "Reserved",
	"resetPassword": "Reset password",
	"resetPasswordEmailSent": "We've sent an email to {email} that contains a link to reset your password.",
	"resolutionJob": "Resolution job",
	"resolutionJobAssigned": "Resolution job {jobId} assigned at induct",
	"resolutionJobs": "Resolution jobs",
	"resolutionJobsCreated": {
		"one": "There are unpicked items. {count} resolution job has been created to process unpicked items.",
		"other": "There are unpicked items. {count} resolution jobs have been created to process unpicked items."
	},
	"resolutionLicensePlate": "Resolution license plate",
	"resolvable": "Resolvable",
	"resolvedToday": "Resolved today",
	"resolving": "Resolving",
	"resourceIsRequired": "{resource} is required",
	"result": "Result",
	"results": "Results",
	"returnDetails": "Return details",
	"returnIssues": "Return issues",
	"returnItemsToLocation": "Return items to {location}",
	"returnShipmentByExternalId": "Return {externalId}",
	"returnShipments": "Return shipments",
	"returnToOrderDetails": "Return to order details",
	"returnType": "Return type",
	"returnTypes": {
		"RMA": "Buyer return",
		"RTS": "RTS"
	},
	"returnedInventory": "Returned inventory",
	"return": "Return",
	"returns": "Returns",
	"rework": "Rework",
	"reworkJobId": "Rework Job ID",
	"reworkJobByExternalId": "Rework job {externalId}",
	"reworkJobsNotFound": "No rework jobs found",
	"reworkJobDetails": "Rework job details",
	"role": "Role",
	"roles": "Roles",
	"rows": "Rows",
	"save": "Save",
	"saveError": "There's a problem saving your changes. Try reloading the page.",
	"saveNoteFailed": "There was an error saving the note",
	"searchBulkOrders": "Search by batch ID, store, SKU, or product barcode",
	"searchFloorView": "Search by Chuck name",
	"searchName": "Search by name",
	"searchProduct": "Search products by item ID, SKU or description",
	"select": "Select",
	"selectProduct": "Select a product",
	"sellable": "Sellable",
	"sendLink": "Send link",
	"sentForRework": "Sent for rework",
	"service": "Service",
	"setUpAccount": "Set up account",
	"shipped": "Shipped",
	"shipping": "Shipping",
	"shipment": "Shipment",
	"shipmentNotFound": "Inbound shipment not found",
	"shipmentsWithIssues": "Shipments with issues",
	"shippingContainers": "Shipping containers",
	"shippingNoticesNotFound": "No shipping notices found",
	"shippedShortBannerHeading": "Order manually closed",
	"shippedShortBannerBody": "This order was manually marked as complete but not all items have been picked.",
	"show": "Show",
	"showChuckList": "Show chuck list",
	"showDetails": "Show details",
	"signIn": "Sign in",
	"signInToTheBridge": "Sign in to The Bridge",
	"signOut": "Sign out",
	"sku": "SKU",
	"skusRequired": "SKUs required",
	"sla": "SLA",
	"slot": "Slot",
	"sort": "Sort",
	"sortBy": "Sort by",
	"sortChucks": "Sort chucks",
	"sortOptions": {
		"actArrivalTimeAsc": "Actual arrival time (ascending)",
		"actArrivalTimeDsc": "Actual arrival time (descending)",
		"addressAsc": "Address (A-Z)",
		"addressDesc": "Address (Z-A)",
		"batchIdAsc": "Batch ID (ascending)",
		"batchIdDesc": "Batch ID (descending)",
		"batteryLevel": "Battery level (low-high)",
		"batteryLevelAsc": "Battery level (ascending)",
		"batteryLevelDesc": "Battery level (descending)",
		"containerNameAsc": "Container name (A-Z)",
		"containerNameDesc": "Container name (Z-A)",
		"cutoffAsc": "Carrier cutoff (earliest)",
		"cutoffDesc": "Carrier cutoff (latest)",
		"dateCreatedAsc": "Date created (oldest)",
		"dateCreatedDesc": "Date created (newest)",
		"dateReceivedAsc": "Date received (oldest)",
		"dateReceivedDesc": "Date received (newest)",
		"estArrivalTimeAsc": "Estimated arrival time (ascending)",
		"estArrivalTimeDsc": "Estimated arrival time (descending)",
		"expectedUnitsAsc": "Expected units (ascending)",
		"expectedUnitsDesc": "Expected units (descending)",
		"expectedUnitsPerShipmentAsc": "Expected units per shipment (ascending)",
		"expectedUnitsPerShipmentDsc": "Expected units per shipment (descending) ",
		"fullnessAsc": "Fullness (low to high)",
		"fullnessDesc": "Fullness (high to low)",
		"idleTime": "Minutes idle (high-low)",
		"laneStatusDesc": "Lane status (Z-A)",
		"laneStatusAsc": "Lane status (A-Z)",
		"lastUsedAsc": "Least recently active",
		"lastUsedDesc": "Most recently active",
		"linesPerShipmentAsc": "Lines per shipment (ascending)",
		"linesPerShipmentDsc": "Lines per shipment (descending)",
		"nameAsc": "Name (A-Z)",
		"nameDesc": "Name (Z-A)",
		"numOfIssuesAsc": "Number of issues (ascending)",
		"numOfIssuesDsc": "Number of issues (descending)",
		"orderNumberAsc": "Order number (ascending)",
		"orderNumberDesc": "Order number (descending)",
		"progressAsc": "Progress (ascending)",
		"progressDesc": "Progress (descending)",
		"projectIdAsc": "Project ID (ascending)",
		"projectIdDesc": "Project ID (descending)",
		"quantityAsc": "Quantity (low to high)",
		"quantityDesc": "Quantity (high to low)",
		"receivingIdAsc": "Receiving ID (ascending)",
		"receivingIdDsc": "Receiving ID (descending)",
		"storeAsc": "Store (A-Z)",
		"storeDsc": "Store (Z-A)"
	},
	"sortWall": "Sort wall",
	"sortWallByName": "Sort wall {name}",
	"sortWallCanceled": "Sort wall canceled",
	"sortWallId": "Sort wall unique ID",
	"sortWallKiosks": "Sort wall kiosks",
	"sortWallNotCreated": "Sort wall not created",
	"sortWallNotFound": "Sort wall not found",
	"sortWalls": "Sort walls",
	"sortationDetails": "Sortation details",
	"sortationExceptions": "Sortation exceptions",
	"sortationRequired": "Sortation required",
	"sorted": "Sorted",
	"sorting": "Sorting",
	"sourceLocation": "Source location",
	"specialInstructions": "Special instructions",
	"specialProject": "Special project",
	"specialProjectByExternalId": "Special project {externalId}",
	"specialProjectModalMessage": "Review project summary before marking project as complete:",
	"specialProjectModalTitle": "Review details before marking as complete",
	"specialProjectModalWarning": "Once saved, details cannot be edited.",
	"specialProjectTypes": {
		"prekit": "Prekit",
		"cycleCount": "Cycle count",
		"inventoryTransferToMerchant": "Inventory transfer to merchant",
		"inventoryTransferToNode": "Inventory transfer to fulfillment center",
		"kitting": "Kitting",
		"otherType": "Other",
		"qualityControl": "Quality control inspection",
		"relabelling": "Relabelling",
		"repackaging": "Repackaging",
		"returns": "Returns"
	},
	"specialProjects": "Special projects",
	"specialProjectsNotFound": "No special projects found",
	"stagingId": "Staging ID",
	"stagingLocations": "Staging locations",
	"startDate": "Start date",
	"startTime": "Start time",
	"status": "Status",
	"storageContainers": "Storage containers",
	"store": "Store",
	"submitted": "Submitted",
	"subsection": "Subsection",
	"subsectionDimensions": "Subsection dimensions",
	"subsectionHeight": "Height of subsection",
	"subsectionLength": "Length of subsection",
	"subsectionWidth": "Width of subsection",
	"suggestedBatches": "Suggested batches",
	"summaryOfIssues": "Summary of issues",
	"support": "Support",
	"supportTicket": "Support ticket",
	"supportTicketStatus": "Support ticket status",
	"supportedLocales": {
		"en-US": "English",
		"es-MX": "Spanish (Mexico)"
	},
	"suspectedDiscrepancies": "Suspected discrepancies",
	"suspectedDiscrepancy": "Suspected discrepancy",
	"tag": "Tag",
	"tags": "Tags",
	"takeoff": "Takeoff",
	"takeoffLocation": "Takeoff location",
	"task": "Task",
	"technicalProblem": "A technical problem prevented this page from loading ({problem}). Try reloading the page or navigating to another page.",
	"terminalOnWheels": "Terminal on wheels",
	"thisEmployeeHasBridgeAccess": "This employee has access to The Bridge.",
	"time": "Time",
	"timeCreated": "Time created",
	"timeline": "Timeline",
	"timeLogged": "Time logged",
	"timeSpent": "Time spent",
	"timestampInfo": "All timestamps are in {timezone}",
	"timestamps": "Timestamps",
	"today": "Today",
	"tools": "Tools",
	"total": "Total",
	"totalCapacity": "Total capacity",
	"totalJobs": "Total jobs",
	"totalLength": "Total length",
	"totalLines": "Total lines",
	"totalOrders": "Total orders",
	"totalRequired": "Total required",
	"totalTimeLogged": "Total time logged",
	"totalUnits": "Total units",
	"totalUnitsRequired": "Total units required",
	"totalWidth": "Total width",
	"toteRemoved": "Tote removed at {takeoffDestination}",
	"toteScanned": "Tote scanned at {mfpId}",
	"totes": "Totes",
	"trackAsInventory": "Track as inventory",
	"trackContainerAsInventory": "Track this container as inventory",
	"trackJobs": "Track jobs",
	"trackingId": "Tracking ID",
	"trackingInformation": "Tracking information",
	"trackingLink": "Tracking link",
	"trackingNumber": "Tracking number",
	"transferDetails": "Transfer details",
	"type": "Type",
	"unassigned": "Unassigned",
	"unavailable": "Unavailable",
	"unexpectedInventory": "Unexpected inventory",
	"unknownItem": "Unknown item",
	"uniqueJobId": "Unique job ID",
	"units": "Units",
	"unitsMoved": "Units moved",
	"unitsPacked": "Units packed",
	"unitsPerKit": "Units per kit",
	"unitsPicked": "Units picked",
	"unitsReceived": "Units received",
	"unitsReplenished": "Units replenished",
	"unitsRequired": "Units required",
	"unitsStaged": "Units staged",
	"unitsWithException": "Units with exception",
	"unitsProcessed": "Units processed",
	"unknown": "Unknown",
	"unknownError": "Unknown error",
	"unnamed": "Unnamed",
	"unprocessedUnits": "Unprocessed units",
	"unresolvable": "Unresolvable",
	"unsavedChanges": "Unsaved changes",
	"unsellable": "Unsellable",
	"updateNoteFailed": "There was an error updating the note",
	"updatedAt": "Updated",
	"upgradingToVersion": "Upgrading to version {version}",
	"upload": "Upload",
	"uploadAttachmentFailed": "There was an error uploading the attachment",
	"useContainerNameAsExternalId": "Use container name for External ID (recommended)",
	"used": "Used",
	"validations": {
		"isDefined": "This value is required",
		"isEmail": "This value must be a valid email address",
		"isEnum": "This value is invalid",
		"isNotEmpty": "This value is required",
		"isPositive": "This value must be a positive number",
		"isRequired": "This value is required",
		"isUuid": "This value must be a UUID",
		"isUniqueValue": "This value is already in use",
		"isUUID": "This value must be a UUID",
		"isValidValue": "This value is invalid",
		"matches": "This value has an invalid pattern",
		"maxLength": "This value is too long",
		"min": "This value is too low",
		"minLength": "This value is too short"
	},
	"vehicleId": "Vehicle ID",
	"viewActionLog": "View Action Log",
	"viewAllItems": "View all items",
	"viewAllLocations": "View all locations",
	"viewBatchingJob": "View batching job {jobId}",
	"viewBulkOrders": "View orders",
	"viewContainer": "View container details",
	"viewInstructions": "View instructions",
	"viewInventoryLevels": "View inventory levels",
	"viewJob": "View job",
	"viewJobHistory": "View job history",
	"viewMoreJobs": "View more jobs",
	"viewMoreOrders": "View more orders",
	"viewOrders": {
		"one": "View order",
		"other": "View {count} orders"
	},
	"viewRelatedJobs": "View related jobs",
	"viewResolutionJob": "View resolution job {jobId}",
	"viewWorkAreas": {
		"one": "View {count} work area",
		"other": "View {count} work areas"
	},
	"volume": "Volume",
	"waitingForContainers": "Waiting for containers",
	"wallCreated": "Wall created",
	"wallId": "Wall ID",
	"weight": "Weight",
	"wholeContainer": "Whole container",
	"width": "Width",
	"withinXDays": "Within {days} days",
	"workArea": "Work area",
	"workAreas": "Work areas",
	"workLog": "Work log",
	"workLogDeleted": "Work log deleted",
	"workLogMissing": "No work logged. Log work before marking project as complete.",
	"workLogTaskHelperText": "Enter a name for this task. For example, \"Moved Inventory\".",
	"workLogTypes": {
		"inventoryMovement": "Inventory movement",
		"otherType": "Other",
		"sopReview": "SOP review",
		"stationCleanup": "Station cleanup",
		"stationSetup": "Station setup"
	},
	"workLogged": "Work logged",
	"workStarted": "Work started",
	"workCompleted": "Work completed",
	"workNotStarted": "Work has not yet started for this project",
	"workflow": "Workflow",
	"workflowOrigin": "Workflow origin",
	"workflowPointDisabled": "Workflow point disabled",
	"workflowPointEnabled": "Workflow point enabled",
	"workflowTypes": {
		"amnesty": "Amnesty Put",
		"move": "Move",
		"picking": "Picking",
		"replen": "Replenishment"
	},
	"workOrderType": "Order type",
	"workOrderTypes": {
		"ecommerce": "E-commerce",
		"transfer": "Transfer"
	},
	"xOfY": "{x} of {y}",
	"xOfYCompleted": "{x} of {y} completed",
	"xOfYUnits": "{x} of {y} units",
	"yes": "Yes"
}
