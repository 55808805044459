import {
	Banner,
	Button,
	DropZone,
	List,
	Modal,
	Spinner,
	Stack,
	TextContainer,
	TextField,
} from '@shopify/polaris';
import { useCallback, useEffect, useState } from 'react';

import styles from './ImageUpload.module.css';
import { UploadedImage } from 'components/UploadedImage/UploadedImage';
import { useLocalization } from 'hooks/useLocalization';

const VALID_IMAGE_TYPES = ['image/gif', 'image/jpeg', 'image/png', 'image/jpg'];
const MAX_IMAGE_SIZE_MB = 5; // Max size in MB

export interface ImageUploadProps {
	defaultImage?: string;
	resourceName: string;
	imgUrl?: string;
	onImgUrl: (url?: string) => void;
	error?: string;
}

export function ImageUpload({ defaultImage, imgUrl, onImgUrl, resourceName }: ImageUploadProps) {
	const { messages } = useLocalization();

	// state
	const [imageModalVisible, setImageModalVisible] = useState(false);
	const [imageDeleteModalVisible, setImageDeleteModalVisible] = useState(false);
	const [file, setFile] = useState<File>();
	const [textFieldURL, setTextFieldURL] = useState<string>(imgUrl || '');
	const [rejectedFiles, setRejectedFiles] = useState<File[]>([]);
	const hasError = rejectedFiles.length > 0;

	// methods
	const toggleImageModal = () => setImageModalVisible((isVisible) => !isVisible);
	const toggleImageDeleteModal = () => setImageDeleteModalVisible((isVisible) => !isVisible);

	const handleAddMediaConfirm = useCallback(() => {
		onImgUrl(textFieldURL);
		toggleImageModal();
	}, [onImgUrl, textFieldURL]);

	const handleAddMediaCancel = () => {
		setTextFieldURL('');
		toggleImageModal();
	};

	const handleImageDelete = () => {
		setTextFieldURL('');
		onImgUrl('');
		setFile(undefined);
		toggleImageDeleteModal();
	};

	const handleDropZoneDrop = useCallback(
		async (_dropFiles, acceptedFiles, rejectedFiles) => {
			setFile(acceptedFiles[0]);
			setRejectedFiles(rejectedFiles);

			if (rejectedFiles.length === 0) {
				try {
					const imageData = await uploadImageToCloudinary(acceptedFiles[0]);
					if (imageData?.imageUrl) {
						onImgUrl(encodeURI(imageData.imageUrl));
					}
				} catch (ex) {
					throw new Error('ERR: ' + ex);
				}
			}
		},
		[onImgUrl],
	);

	const onImageLoadError = () => {
		setTextFieldURL('');
		setFile(undefined);
		onImgUrl('');
	};

	useEffect(() => {
		setFile(undefined);
	}, [imgUrl]);

	// render
	return (
		<>
			<Stack vertical spacing="tight">
				{hasError ? <ImageUploadRejectionErrorMessage rejectedFiles={rejectedFiles} /> : null}

				<div className={styles.DropZoneHeader}>
					<p>
						{resourceName} ({messages.optional})
					</p>

					{defaultImage || imgUrl ? (
						<Modal
							open={imageDeleteModalVisible}
							onClose={toggleImageDeleteModal}
							title={messages.removeImage}
							primaryAction={{
								content: messages.removeImage,
								destructive: true,
								onAction: handleImageDelete,
							}}
							secondaryActions={[
								{
									content: messages.cancel,
									onAction: toggleImageDeleteModal,
								},
							]}
						>
							<Modal.Section>
								<TextContainer>
									<p>{messages.confirmRemoveImage}</p>
								</TextContainer>
							</Modal.Section>
						</Modal>
					) : null}

					{!defaultImage && !file && !imgUrl && (
						<Modal
							activator={
								<Button plain onClick={toggleImageModal}>
									{messages.addImageFromUrl}
								</Button>
							}
							open={imageModalVisible}
							onClose={handleAddMediaCancel}
							title={messages.addImageFromUrl}
							primaryAction={{
								content: messages.addImage,
								onAction: handleAddMediaConfirm,
							}}
							secondaryActions={[
								{
									content: messages.cancel,
									onAction: handleAddMediaCancel,
								},
							]}
						>
							<Modal.Section>
								<TextContainer>
									<p>{messages.pasteImageUrl}</p>
									<TextField
										autoComplete="off"
										label={messages.imageURL}
										labelHidden
										placeholder={'https://'}
										value={textFieldURL}
										onChange={setTextFieldURL}
									/>
								</TextContainer>
							</Modal.Section>
						</Modal>
					)}
				</div>
				<DropZone
					accept="image/*"
					disabled={!!file || !!imgUrl}
					type="file"
					onDrop={handleDropZoneDrop}
					allowMultiple={false}
					customValidator={isLessThanMaxImageSize}
					errorOverlayText={messages.invalidImage}
				>
					{file && !imgUrl ? (
						<div className={styles.DropZoneSpinnerContainer}>
							<Spinner size="large" />
						</div>
					) : imgUrl ? (
						<div className={styles.DropZoneImage}>
							<UploadedImage
								file={file}
								imgURL={imgUrl}
								validImageTypes={VALID_IMAGE_TYPES}
								onRemove={() => {
									setImageDeleteModalVisible(true);
								}}
							/>
						</div>
					) : (
						<DropZone.FileUpload />
					)}
				</DropZone>
			</Stack>
			{/* Hidden image to trigger fallback image for broken thumbnails */}
			{imgUrl ? (
				<img style={{ display: 'none' }} src={imgUrl} alt="" onError={onImageLoadError} />
			) : null}
		</>
	);
}

const ImageUploadRejectionErrorMessage = ({ rejectedFiles = [] }: { rejectedFiles: File[] }) => {
	const { messages } = useLocalization();

	return (
		<Banner title={messages.imageUploadError} status="critical">
			<List type="bullet">
				{rejectedFiles.map((file, index) => {
					const errorMessage = !VALID_IMAGE_TYPES.includes(file.type)
						? messages.fileTypeNotSupported
						: !isLessThanMaxImageSize(file)
						? messages.fileTooLarge
						: messages.unknownError;
					return <List.Item key={index}>{errorMessage}</List.Item>;
				})}
			</List>
		</Banner>
	);
};

const isLessThanMaxImageSize = (file: File) => {
	// if there is no "size" property, this means that it is a DataTransformItem and we should return true
	return file.size ? file.size / 1024 / 1024 < MAX_IMAGE_SIZE_MB : true;
};

interface CloudinaryImageUploadResponse {
	result: {
		version: string;
		public_id: string; // eslint-disable-line camelcase
	};
}

async function uploadImageToCloudinary(file: File) {
	const body = new FormData();
	body.append('public_id', file.name);
	body.append('folder', 'asset_types');
	body.append('tags', 'asset_types');
	body.append('file', file);

	const response = await fetch(`${process.env.REACT_APP_INVENTORY_SERVICES_URL}/v1/images/upload`, {
		body,
		headers: {
			Accept: 'application/json',
		},
		method: 'POST',
	});

	const imageData: CloudinaryImageUploadResponse = await response.json();

	if (imageData.result) {
		const { version, public_id: publicId } = imageData.result;
		const imageUrl = `https://res.cloudinary.com/six-river/image/upload/v${version}/${publicId}.webp`;
		const imageRelativeUrl = `v${version}/${publicId}.webp`;
		return {
			version,
			publicId,
			imageUrl,
			imageRelativeUrl,
		};
	}

	return undefined;
}
