import { PickingJobStatus } from '@sixriver/fulfillment-api-schema';

import { useConfig } from 'hooks/useConfig';

export enum OutboundJobViews {
	All = 'All',
	Cancelled = 'Cancelled',
	Complete = 'Complete',
	Exceptions = 'Exceptions',
	Interrupted = 'Interrupted',
	Packing = 'Packing',
	Picked = 'Picked',
	Picking = 'Picking',
	ReadyToPack = 'ReadyToPack',
	Sorting = 'Sorting',
	InProgress = 'InProgress',
}

type OutboundJobsStatusMap = {
	[key in OutboundJobViews]: PickingJobStatus[];
};

export const useOutboundJobViewStateMap = (): OutboundJobsStatusMap => {
	const { config } = useConfig();

	const isPackoutEnabled = config?.packoutEnabled;

	return {
		[OutboundJobViews.All]: [
			PickingJobStatus.AwaitingInventory,
			PickingJobStatus.Cancelled,
			PickingJobStatus.Exception,
			PickingJobStatus.Offline,
			PickingJobStatus.Packable,
			PickingJobStatus.Packed,
			PickingJobStatus.Packing,
			PickingJobStatus.Picked,
			PickingJobStatus.Picking,
			PickingJobStatus.Sortable,
			PickingJobStatus.Sorted,
			PickingJobStatus.Sorting,
			PickingJobStatus.Unassigned,
		],

		[OutboundJobViews.Cancelled]: [PickingJobStatus.Cancelled],

		// PICKED is not COMPLETE when PACKOUT is ON
		[OutboundJobViews.Complete]: isPackoutEnabled
			? [PickingJobStatus.Packed]
			: [PickingJobStatus.Picked, PickingJobStatus.Packed],

		[OutboundJobViews.Exceptions]: [PickingJobStatus.Exception],

		[OutboundJobViews.Interrupted]: [PickingJobStatus.Offline],

		[OutboundJobViews.Packing]: [PickingJobStatus.Packing],

		[OutboundJobViews.Picked]: [PickingJobStatus.Picked],

		[OutboundJobViews.Picking]: [PickingJobStatus.Picking],

		[OutboundJobViews.ReadyToPack]: [PickingJobStatus.Packable],

		[OutboundJobViews.Sorting]: [PickingJobStatus.Sorting],

		[OutboundJobViews.InProgress]: [
			PickingJobStatus.Picking,
			PickingJobStatus.Packable,
			PickingJobStatus.Packing,
			PickingJobStatus.Sortable,
			PickingJobStatus.Sorting,
		],
	};
};
