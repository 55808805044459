import { IndexTable, TextStyle } from '@shopify/polaris';

interface LaneCarrierSortCellProps {
	carrierSort: string;
}

export function LaneCarrierSortCell({ carrierSort }: LaneCarrierSortCellProps) {
	return (
		<IndexTable.Cell>
			<TextStyle>{carrierSort} </TextStyle>
		</IndexTable.Cell>
	);
}
