import { ChoiceList } from '@shopify/polaris';
import { LaneStatus } from '@sixriver/fulfillment-api-schema';

import { noop } from 'helpers/noop';
import { useLocalization } from 'hooks/useLocalization';

interface LaneStatusesFilterProps {
	selected?: LaneStatus[];
	onChange?: (selected: LaneStatus[], name: string) => void;
}

export function LaneStatusesFilter({ selected = [], onChange = noop }: LaneStatusesFilterProps) {
	const { messages } = useLocalization();

	return (
		<ChoiceList
			title={messages.laneStatus}
			titleHidden
			choices={[
				{
					label: messages.active,
					value: LaneStatus.Active,
				},
				{
					label: messages.waitingForContainers,
					value: LaneStatus.WaitingForContainers,
				},
			]}
			selected={selected}
			onChange={onChange}
			allowMultiple
		/>
	);
}
