import { Count } from '@sixriver/fulfillment-api-schema';
import gql from 'graphql-tag';

export interface JobLineCounts {
	All: Count;
	Exceptions: Count;
	Unassigned: Count;
	InProgress: Count;
	Completed: Count;
}

export const COUNTS_QUERY = gql`
	query JobLinesCount($id: String!, $searchText: String) {
		All: jobsLinesV2Count(id: $id, searchText: $searchText) {
			count
		}

		Exceptions: jobsLinesV2Count(id: $id, searchText: $searchText, view: Exceptions) {
			count
		}

		Unassigned: jobsLinesV2Count(id: $id, searchText: $searchText, view: Unassigned) {
			count
		}

		InProgress: jobsLinesV2Count(id: $id, searchText: $searchText, view: InProgress) {
			count
		}

		Completed: jobsLinesV2Count(id: $id, searchText: $searchText, view: Complete) {
			count
		}
	}
`;
