import { Banner, Button, Modal, Stack, TextContainer, TextStyle } from '@shopify/polaris';
import {
	KittingProject as KittingProjectApi,
	SpecialProject as SpecialProjectApi,
	MutationResponse,
	SpecialProjectType,
} from '@sixriver/fulfillment-api-schema';
import { useMemo, useState } from 'react';
import { CombinedError, useMutation } from 'urql';

import { MARK_AS_COMPLETE_MUTATION, REPORT_ISSUES_MUTATION } from './SpecialProjects.graphql';
import { TimeLogged } from './TimeLogged';
import { FormFeedback } from 'components/FormFeedback';
import { useLocalization } from 'hooks/useLocalization';
import * as routes from 'routes';

export interface SpecialProjectReviewModalProps {
	isOpen: boolean;
	project: SpecialProjectApi;
	onClose: (didMarkComplete: boolean) => void;
}

export function SpecialProjectReviewModal({
	isOpen: isModalOpen,
	onClose,
	project,
}: SpecialProjectReviewModalProps) {
	const [error, setError] = useState<CombinedError>();
	const { messages } = useLocalization();

	// Mutations
	const [, markAsCompleteMutation] = useMutation<
		{ markAsComplete: MutationResponse },
		{ id: string }
	>(MARK_AS_COMPLETE_MUTATION);

	const [, reportIssues] = useMutation<{ reportIssues: MutationResponse }>(REPORT_ISSUES_MUTATION);

	const onCloseModal = (didMarkComplete = false) => {
		setError(undefined);
		onClose(didMarkComplete);
	};

	const showIssuesText = project.type === SpecialProjectType.Kitting;

	const issueLinkText = project?.hasIssues ? messages.editIssues : messages.reportIssues;

	const issues = useMemo(() => {
		switch (project.type) {
			case SpecialProjectType.Kitting:
				return (project as KittingProjectApi).componentProducts
					.map((product) => {
						return product.exceptions.map((exception) => {
							return {
								productId: product.id,
								eachQuantity: exception.eachQuantity,
								reason: exception.reason,
							};
						});
					})
					.flat();
			default:
				return [];
		}
	}, [project]);

	const onMarkAsComplete = async () => {
		// this must happen before marking complete in case the user did not use the "report issues" form. ugh
		const { error: reportIssuesError } = await reportIssues(
			{ input: { goalId: project.id, exceptions: issues } },
			{ additionalTypenames: ['KittingProject', 'PreKitException'] },
		);

		const { error: markCompleteError } = await markAsCompleteMutation(
			{ id: project.id },
			{ additionalTypenames: ['KittingProject', 'PreKitException', 'WorkOrderProject'] },
		);

		if (reportIssuesError || markCompleteError) {
			setError(reportIssuesError || markCompleteError);
		} else {
			onCloseModal(true);
		}
	};

	return (
		<Modal
			open={isModalOpen}
			onClose={() => onCloseModal(false)}
			title={messages.specialProjectModalTitle}
			primaryAction={{ content: messages.markAsComplete, onAction: onMarkAsComplete }}
			secondaryActions={[
				{
					content: messages.close,
					onAction: () => onCloseModal(false),
				},
			]}
		>
			<Modal.Section>
				<TextContainer>
					{error ? (
						<FormFeedback feedback={error} />
					) : (
						<Banner status="warning">{messages.specialProjectModalWarning}</Banner>
					)}
					<p>{messages.specialProjectModalMessage}</p>
					<Stack vertical spacing="extraTight">
						<TextStyle>
							{messages.workLogged}: <TimeLogged timeInMs={project?.totalTimeLogged ?? 0} />
						</TextStyle>
						<Button
							plain
							url={routes.specialProjectWorkLogged(project.id)}
							onClick={() => onCloseModal(false)}
						>
							{messages.editWorkLog}
						</Button>
					</Stack>
					{showIssuesText ? (
						<Stack vertical spacing="extraTight">
							{project?.hasIssues ? (
								<IssueList project={project as KittingProjectApi} />
							) : (
								<IssuesQuestion />
							)}
							<Button
								plain
								url={routes.specialProjectReportIssues(project.id)}
								onClick={() => onCloseModal(false)}
							>
								{issueLinkText}
							</Button>
						</Stack>
					) : null}
				</TextContainer>
			</Modal.Section>
		</Modal>
	);
}

function IssuesQuestion(): JSX.Element {
	const { messages } = useLocalization();

	return <TextStyle>{messages.productsDamagedOrMissing}</TextStyle>;
}

interface Props {
	project: KittingProjectApi;
}

function IssueList({ project }: Props): JSX.Element {
	const { messages } = useLocalization();

	const productsWithIssues = project.componentProducts.filter(
		(component) => component.exceptions.length > 0,
	);

	const kitIssues = productsWithIssues.map((product) => {
		const productIssues = product.exceptions
			.map((exception) => {
				const reason = {
					damagedProduct: messages.kitIssues.damagedProduct,
					missingProduct: messages.kitIssues.missingProduct,
					other: messages.kitIssues.otherIssue,
				}[exception.reason];

				return `${exception.eachQuantity} ${reason}`;
			})
			.join(', ');

		return productIssues ? `${messages.sku} ${product.name}: ${productIssues}` : '';
	});

	return (
		<Stack vertical spacing="extraTight">
			<TextStyle>Issues reported:</TextStyle>
			{kitIssues.map((issue, index) => {
				return <TextStyle key={index}>{issue}</TextStyle>;
			})}
		</Stack>
	);
}
