import { Page, Layout, Link, Stack, Card, Pagination } from '@shopify/polaris';
import { useDebouncedValue, usePrevious } from '@shopify/react-hooks';
import {
	Cutoff,
	DatePrecision,
	JobOrderBy,
	MutationResponse,
	OrderV2Status,
	PickStrategy,
	QueryCutoffForOrdersV2Args,
	QueryOrdersV2Args,
	WorkOrderType,
} from '@sixriver/fulfillment-api-schema';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'urql';

import { BulkCancelFailedBanner } from './BulkCancelFailedBanner';
import { BulkCancelInProgressBanner } from './BulkCancelInProgressBanner';
import { OrdersData, ORDERS_QUERY } from './Orders.graphql';
import { CANCEL_ORDERS_MUTATION } from './OrdersCancel.graphql';
import { OrdersCounts, ORDERS_COUNT_QUERY } from './OrdersCounts.graphql';
import { CUTOFF_DATES_QUERY } from './OrdersCutoffs.graphql';
import { OrdersFilters, Alert } from './OrdersFilters';
import { OrdersTable } from './OrdersTable';
import { OrdersTabs, OrdersTab } from './OrdersTabs';
import { OrdersToteboard } from './OrdersToteboard';
import { AutoRefresh } from 'components/AutoRefresh';
import { DateRangeFilter } from 'components/DateRangeFilter';
import { OptionValues } from 'components/DateRangeSelect';
import { TimezoneFooter } from 'components/TimezoneFooter';
import { WorkAreaMenu } from 'components/WorkAreaMenu';
import { getPageSize } from 'helpers/page-size';
import { MIN_QUERY_LENGTH } from 'helpers/table';
import { getMidnight } from 'helpers/time';
import { useConfig } from 'hooks/useConfig';
import { useLocalization } from 'hooks/useLocalization';
import { usePickStrategies } from 'hooks/usePickStrategies';
import { usePolling } from 'hooks/usePolling';
import { usePollingQuery } from 'hooks/usePollingQuery';
import { useArrayQueryState, useDateQueryState, useQueryState } from 'hooks/useQueryState';
import { useWorkAreas } from 'hooks/useWorkArea';
import * as routes from 'routes';

const MIDNIGHT_TODAY = getMidnight();
const MIDNIGHT_TOMORROW = getMidnight(1);
const DEFAULT_DATE = getMidnight(-7);
const SEARCH_TEXT_KEY = 'query';
const CUTOFF_DATE_KEY = 'cutoff';
const IDS_KEY = 'ids';
const PICK_STRATEGY_KEY = 'pickStrategies';
const HISTORY_DATE_LIMITER_KEY = 'historyDateLimiter';
const PAGINATION_CURSORS_KEY = 'paginationCursors';
const ALERTS_KEY = 'alerts';
const STATUSES_KEY = 'statuses';
const WORK_ORDER_TYPES_KEY = 'workOrderTypes';
const WORK_AREA_IDS = 'workAreaIds';

export function Orders() {
	const { messages } = useLocalization();
	const { pollingEnabled, togglePolling, queryPollInterval } = usePolling();
	const { config } = useConfig();
	const [searchText, setSearchText] = useQueryState(SEARCH_TEXT_KEY, '');
	const [selectedTab, setSelectedTab] = useState<OrdersTab>(OrdersTab.All);
	const [selectedStatuses, setSelectedStatuses] = useArrayQueryState<OrderV2Status[]>(
		STATUSES_KEY,
		[],
	);
	const [selectedAlerts, setSelectedAlerts] = useArrayQueryState<Alert[]>(ALERTS_KEY, []);
	const { workAreas } = useWorkAreas();
	const [selectedSort, setSelectedSort] = useState<JobOrderBy[]>([]);
	const [selectedCutoffDate, setSelectedCutoffDate] = useDateQueryState<Date | undefined>(
		CUTOFF_DATE_KEY,
		undefined,
	);
	const [selectedPickStrategies, setselectedPickStrategies] = useArrayQueryState<PickStrategy[]>(
		PICK_STRATEGY_KEY,
		[],
	);
	const [selectedWorkOrderTypes, setselectedWorkOrderTypes] = useArrayQueryState<WorkOrderType[]>(
		WORK_ORDER_TYPES_KEY,
		[],
	);
	const [paginationCursors, setPaginationCursors] = useArrayQueryState<string[]>(
		PAGINATION_CURSORS_KEY,
		[],
	);
	const [selectedWorkAreaIds, setSelectedWorkAreaIds] = useArrayQueryState<string[]>(
		WORK_AREA_IDS,
		[],
	);
	const [isBulkCancelInProgress, setIsBulkCancelInProgress] = useState<boolean>(false);
	const [didBulkCancelFail, setDidBulkCancelFail] = useState<boolean>(false);
	const [historyDateLimiter, setHistoryDateLimiter] = useDateQueryState(
		HISTORY_DATE_LIMITER_KEY,
		DEFAULT_DATE,
	);
	const [ids, setIds] = useArrayQueryState<string[]>(IDS_KEY, []);
	const availablePickStrategies = usePickStrategies(config?.jobAllocationMethods || []);
	const showPickStrategy = availablePickStrategies.length > 1;
	const showWorkOrderType = config?.inventoryEnabled;
	const showWorkArea = config?.workAreasEnabled;
	const showStore = config?.inventoryEnabled;
	const showTag = config?.orderTagEnabled;
	const showSortWall = config?.sortationEnabled;
	const debouncedSearchText = useDebouncedValue(searchText);

	/**
	 * Queries
	 */
	const queryParamsForOrdersAndCounts: QueryOrdersV2Args = useMemo(
		() => ({
			ids,
			searchText: debouncedSearchText.length >= MIN_QUERY_LENGTH ? debouncedSearchText : undefined,
			statuses: selectedStatuses,
			isLate: (selectedAlerts.length > 0 && selectedAlerts.includes(Alert.Late)) || undefined,
			isShorted:
				(selectedAlerts.length > 0 && selectedAlerts.includes(Alert.Exception)) || undefined,
			isAwaitingInventory:
				(selectedAlerts.length > 0 && selectedAlerts.includes(Alert.AwaitingInventory)) ||
				undefined,
			expectedShipDateFrom: selectedCutoffDate ?? undefined,
			expectedShipDateTo: selectedCutoffDate
				? new Date(selectedCutoffDate.getTime() + 60 * 1000)
				: undefined,
			createdAtFrom: historyDateLimiter,
			pickStrategies: selectedPickStrategies.length > 0 ? selectedPickStrategies : undefined,
			workOrderTypes: selectedWorkOrderTypes.length > 0 ? selectedWorkOrderTypes : undefined,
			workAreaIds: selectedWorkAreaIds.length > 0 ? selectedWorkAreaIds : undefined,
		}),
		[
			debouncedSearchText,
			historyDateLimiter,
			ids,
			selectedAlerts,
			selectedCutoffDate,
			selectedPickStrategies,
			selectedStatuses,
			selectedWorkAreaIds,
			selectedWorkOrderTypes,
		],
	);
	const [{ data: countData }] = usePollingQuery<OrdersCounts, QueryOrdersV2Args>({
		query: ORDERS_COUNT_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			...queryParamsForOrdersAndCounts,
			completedAtFrom: MIDNIGHT_TODAY,
			completedAtTo: MIDNIGHT_TOMORROW,
		},
	});

	const totalCount = countData?.TotalCount.count ?? 0;
	const totalPages = Math.max(Math.ceil(totalCount / getPageSize()), 1);

	const [{ fetching: fetchingOrders, data: ordersData }, refetchOrders] = usePollingQuery<
		OrdersData,
		QueryOrdersV2Args
	>({
		query: ORDERS_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			...queryParamsForOrdersAndCounts,
			first: getPageSize(),
			after: paginationCursors[0],
			orderBy: selectedSort.length > 0 ? selectedSort : undefined,
		},
	});

	const [{ data: cutoffDatesData }] = usePollingQuery<
		{ cutoffForOrdersV2: Cutoff },
		QueryCutoffForOrdersV2Args
	>({
		query: CUTOFF_DATES_QUERY,
		pollInterval: queryPollInterval,
		variables: {
			datePrecision: DatePrecision.Minute,
			createdAtFrom: historyDateLimiter,
		},
	});

	const ordersPageInfo = ordersData?.ordersV2.pageInfo;
	const orders = ordersData?.ordersV2.edges;
	const cutoffDates = cutoffDatesData?.cutoffForOrdersV2.dates.map((date) => new Date(date));

	/**
	 * Mutations
	 * */
	// TODO: how should we handle query and mutation errors
	const [, cancelOrdersMutation] = useMutation<
		{ cancelOrdersV2: MutationResponse },
		QueryOrdersV2Args
	>(CANCEL_ORDERS_MUTATION);

	/**
	 * Filter functions
	 */
	const handleStatusesRemove = useCallback(() => {
		setSelectedStatuses([]);
		setSelectedTab(OrdersTab.All);
	}, [setSelectedStatuses, setSelectedTab]);
	const handleAlertsRemove = useCallback(() => {
		setSelectedAlerts([]);
		setSelectedTab(OrdersTab.All);
	}, [setSelectedAlerts, setSelectedTab]);
	const handleCutoffDateRemove = useCallback(() => {
		setSelectedCutoffDate(undefined);
	}, [setSelectedCutoffDate]);
	const handlePickStrategyRemove = useCallback(() => {
		setselectedPickStrategies([]);
	}, [setselectedPickStrategies]);
	const handleWorkOrderTypeRemove = useCallback(() => {
		setselectedWorkOrderTypes([]);
	}, [setselectedWorkOrderTypes]);
	const handleIdsRemove = useCallback(() => {
		setIds([]);
	}, [setIds]);
	const handleWorkAreaRemove = useCallback(() => {
		setSelectedWorkAreaIds([]);
	}, [setSelectedWorkAreaIds]);

	const handleClearAllFilters = useCallback(() => {
		setSelectedStatuses([]);
		setSelectedAlerts([]);
		// TODO should this clear cutoff date filter when switching tabs?
		// TODO should this clear pick strategy filter when switching tabs?
	}, [setSelectedAlerts, setSelectedStatuses]);

	const handleChangeHistoryLimiter = useCallback(
		(date: Date) => {
			// clearing cutoff filter because the list is dynamically determined by history limitor range
			handleCutoffDateRemove();
			setHistoryDateLimiter(date);
		},
		[handleCutoffDateRemove, setHistoryDateLimiter],
	);

	useEffect(() => {
		handleClearAllFilters();

		switch (selectedTab) {
			case OrdersTab.AwaitingInventory:
				setSelectedAlerts([Alert.AwaitingInventory]);
				return;
			case OrdersTab.Late:
				setSelectedAlerts([Alert.Late]);
				return;
			case OrdersTab.Exceptions:
				setSelectedAlerts([Alert.Exception]);
				return;
			case OrdersTab.Interrupted:
				setSelectedStatuses([OrderV2Status.Interrupted]);
				return;
		}
	}, [handleClearAllFilters, selectedTab, setSelectedAlerts, setSelectedStatuses]);

	/**
	 * Pagination
	 * */
	const handleOnNextPage = useCallback(() => {
		const endCursor = ordersPageInfo?.endCursor ?? undefined;
		if (endCursor) {
			setPaginationCursors((current) => [endCursor].concat(current));
			refetchOrders();
		}
	}, [ordersPageInfo?.endCursor, refetchOrders, setPaginationCursors]);

	const handleOnPreviousPage = useCallback(() => {
		setPaginationCursors((current) => current.slice(1));
		refetchOrders();
	}, [refetchOrders, setPaginationCursors]);

	// Previous filters
	const prevQueryParams = usePrevious(queryParamsForOrdersAndCounts);
	useEffect(() => {
		// Reset pagination when changing views or filters
		if (!isEqual(prevQueryParams, queryParamsForOrdersAndCounts)) {
			setPaginationCursors(() => []);
		}
	}, [prevQueryParams, queryParamsForOrdersAndCounts, setPaginationCursors]);

	/**
	 * Bulk methods
	 */
	const submitBulkCancelRequest = useCallback(
		async (selectedOrderIds: string[]) => {
			setIsBulkCancelInProgress(true);

			// Bulk cancel mutation uses the same filter args that drive the page results
			const filter: QueryOrdersV2Args = {
				searchText:
					debouncedSearchText.length >= MIN_QUERY_LENGTH ? debouncedSearchText : undefined,
				statuses: selectedStatuses,
				isLate: (selectedAlerts.length > 0 && selectedAlerts.includes(Alert.Late)) || undefined,
				isShorted:
					(selectedAlerts.length > 0 && selectedAlerts.includes(Alert.Exception)) || undefined,
				isAwaitingInventory:
					(selectedAlerts.length > 0 && selectedAlerts.includes(Alert.AwaitingInventory)) ||
					undefined,
				expectedShipDateFrom: selectedCutoffDate ?? undefined,
				expectedShipDateTo: selectedCutoffDate
					? new Date(selectedCutoffDate.getTime() + 60 * 1000)
					: undefined,
				createdAtFrom: historyDateLimiter,
				pickStrategies: selectedPickStrategies.length > 0 ? selectedPickStrategies : undefined,
				ids: selectedOrderIds.length > 0 ? selectedOrderIds : undefined,
			};

			const { error, data } = await cancelOrdersMutation(filter);

			// Error
			if (error || !data?.cancelOrdersV2?.success) {
				setIsBulkCancelInProgress(false);
				setDidBulkCancelFail(true);
			}

			// Success
			if (!error && data?.cancelOrdersV2?.success) {
				setDidBulkCancelFail(false);
			}
		},
		[
			cancelOrdersMutation,
			debouncedSearchText,
			historyDateLimiter,
			selectedAlerts,
			selectedCutoffDate,
			selectedPickStrategies,
			selectedStatuses,
		],
	);

	return (
		<>
			<Page
				fullWidth
				title={messages.orders}
				primaryAction={
					showWorkArea ? (
						<WorkAreaMenu
							workAreas={workAreas}
							selectedIds={selectedWorkAreaIds || []}
							onChange={(selected: string[]) => {
								setSelectedWorkAreaIds(selected);
							}}
						/>
					) : null
				}
			>
				<Layout>
					<Layout.Section>
						<Stack distribution="equalSpacing">
							<DateRangeFilter
								options={[
									OptionValues.today,
									OptionValues.last3Days,
									OptionValues.last7Days,
									OptionValues.last30Days,
									OptionValues.last90Days,
									OptionValues.last180Days,
								]}
								selectedOption={historyDateLimiter}
								title={messages.receivedAt}
								onChange={handleChangeHistoryLimiter}
							/>

							<Stack distribution="trailing">
								<Link monochrome url={routes.actionLog()}>
									{messages.actionLog}
								</Link>

								<AutoRefresh
									pollingEnabled={pollingEnabled}
									togglePolling={togglePolling}
									discriminatorData={{}}
								/>
							</Stack>
						</Stack>
						<OrdersToteboard
							unassignedCount={countData?.StaticUnassignedCount.count}
							inProgressCount={countData?.StaticInProgressCount.count}
							completedCount={countData?.StaticCompletedCount.count}
							cancelledCount={countData?.StaticCancelledCount.count}
							interruptedCount={countData?.StaticInterruptedCount.count}
							assignedToBatchCount={countData?.StaticAssignedToBatchCount.count}
							assignedToSortWallCount={countData?.StaticAssignedToSortWallCount.count}
						/>
					</Layout.Section>

					<Layout.Section>
						<Card>
							<div style={{ paddingBottom: '2rem' }}>
								<OrdersTabs
									tabs={
										[
											OrdersTab.All,
											OrdersTab.Late,
											OrdersTab.Exceptions,
											{ ...(config?.inventoryEnabled ? [OrdersTab.AwaitingInventory] : []) },
										] as OrdersTab[]
									}
									lateCount={countData?.LateCount.count ?? 0}
									exceptionsCount={countData?.ShortedCount.count ?? 0}
									awaitingInventoryCount={countData?.AwaitingInventoryCount.count ?? 0}
									interruptedCount={countData?.InterruptedCount.count ?? 0}
									selected={selectedTab}
									onSelect={setSelectedTab}
								/>
							</div>

							<div style={{ paddingLeft: '1rem', paddingRight: '1rem' }}>
								<OrdersFilters
									queryValue={searchText}
									onClearAll={handleClearAllFilters}
									onQueryChange={setSearchText}
									selectedSort={selectedSort}
									onSortChange={setSelectedSort}
									selectedCutoffDate={selectedCutoffDate}
									cutoffDates={cutoffDates}
									onCutoffChange={setSelectedCutoffDate}
									onCutoffRemove={handleCutoffDateRemove}
									selectedStatuses={selectedStatuses}
									onStatusChange={setSelectedStatuses}
									onStatusRemove={handleStatusesRemove}
									selectedAlerts={selectedAlerts}
									onAlertChange={setSelectedAlerts}
									onAlertRemove={handleAlertsRemove}
									showPickStrategy={showPickStrategy}
									selectedPickStrategies={selectedPickStrategies}
									availablePickStrategies={availablePickStrategies}
									onPickStrategyChange={setselectedPickStrategies}
									onPickStrategyRemove={handlePickStrategyRemove}
									showWorkOrderType={showWorkOrderType}
									selectedWorkOrderTypes={selectedWorkOrderTypes}
									onWorkOrderTypeChange={setselectedWorkOrderTypes}
									onWorkOrderTypeRemove={handleWorkOrderTypeRemove}
									ids={ids}
									onIdsRemove={handleIdsRemove}
									selectedWorkAreaIds={selectedWorkAreaIds}
									workAreas={workAreas}
									onWorkAreaRemove={handleWorkAreaRemove}
								/>
							</div>

							<BulkCancelInProgressBanner
								isVisible={isBulkCancelInProgress}
								onDismiss={() => setIsBulkCancelInProgress(false)}
							/>
							<BulkCancelFailedBanner
								isVisible={didBulkCancelFail}
								onDismiss={() => setDidBulkCancelFail(false)}
							/>

							<div style={{ padding: '1rem' }}>
								{`${totalCount} ${
									totalCount === 1 ? messages.result.toLowerCase() : messages.results.toLowerCase()
								}`}
							</div>

							<OrdersTable
								loading={fetchingOrders}
								data={orders}
								showPickStrategyColumn={showPickStrategy}
								showWorkOrderTypeColumn={showWorkOrderType}
								showStoreColumn={showStore}
								showOrderTagColumn={showTag}
								showWorkAreaColumn={showWorkArea}
								pageInfo={ordersPageInfo}
								totalFilteredOrders={totalCount}
								showSortWallColumn={showSortWall}
								onBulkCancel={submitBulkCancelRequest}
							/>
							<Card.Section>
								<Stack distribution="center">
									<Pagination
										label={`${paginationCursors.length + 1} ${messages.of} ${totalPages}`}
										hasNext={ordersPageInfo?.hasNextPage}
										hasPrevious={ordersPageInfo?.hasPreviousPage}
										onNext={handleOnNextPage}
										onPrevious={handleOnPreviousPage}
									/>
								</Stack>
							</Card.Section>
						</Card>
					</Layout.Section>

					<Layout.Section>
						<TimezoneFooter />
					</Layout.Section>
				</Layout>
			</Page>
		</>
	);
}
