import gql from 'graphql-tag';

export const SHIPPING_NOTICE_QUERY = gql`
	query GetShippingNotice($id: String!) {
		shippingNotice(id: $id) {
			id
			externalId
			status
			labelUrl
			merchant
			vendor
			internalTransfer
			comments
			uploads {
				id
			}
			lastEditedAt
			receivedInboundLineCount
			requiredInspectionLinesCount
			inspectedLinesCount
			arrivedAt
			inboundLines {
				id
				externalId
				shippingNoticeId
				status
				receiveStartedAt
				sellableQuantity
				damagedQuantity
				receivedQuantity
				expectedQuantity
				lastInspected
				needsInspection

				issues {
					id
					issueId
					inboundLineId
					quantity
					reason
					comments
					createdAt
					updatedAt
					product {
						id
						customerIdentifier
						name
					}
				}

				product {
					id
					name
					customerIdentifier
					description
				}
			}

			shipments {
				id
				externalId
				estimatedArrival
				slaCompletionBy
				trackings {
					id
					externalId
					method
					carrierCode
					trackingUrl
				}
			}

			issues {
				id
				issueId
				shippingNoticeId
				inboundLineId
				reason
				comments
				userId
				quantity
				createdAt
				updatedAt
			}
		}
	}
`;

export const CLOSE_SHIPPING_NOTICE_MUTATION = gql`
	mutation CloseShippingNotice($input: ShippingNoticeStatusInput!) {
		completeShippingNotice(input: $input) {
			success
		}
	}
`;

export const CANCEL_SHIPPING_NOTICE_MUTATION = gql`
	mutation CancelShippingNotice($input: ShippingNoticeStatusInput!) {
		cancelShippingNotice(input: $input) {
			success
		}
	}
`;

export const ARRIVE_SHIPPING_NOTICE_MUTATION = gql`
	mutation ArriveShippingNotice($input: ShippingNoticeStatusInput!) {
		arriveShippingNotice(input: $input) {
			success
		}
	}
`;

export const UPDATE_SHIPPING_NOTICE_INTERNAL_NOTE_MUTATION = gql`
	mutation updateInternalNote($input: ShippingNoticeInternalNoteInput!) {
		updateInternalNote(input: $input) {
			success
		}
	}
`;

export const ADD_SHIPPING_NOTICE_NOTE_MUTATION = gql`
	mutation addShippingNoticeNote($input: ShippingNoticeNewNoteInput!) {
		addShippingNoticeNote(input: $input) {
			success
		}
	}
`;

export const UPDATE_SHIPPING_NOTICE_NOTE_MUTATION = gql`
	mutation updateShippingNoticeNote($input: ShippingNoticeNoteInput!) {
		updateShippingNoticeNote(input: $input) {
			success
		}
	}
`;

export const UPDATE_SHIPPING_NOTICE_LINE_NOTE_MUTATION = gql`
	mutation updateShippingNoticeNote($input: ShippingNoticeLineNoteInput!) {
		updateShippingNoticeLineNote(input: $input) {
			success
		}
	}
`;

export const ADD_SHIPPING_NOTICE_UPLOADS_MUTATION = gql`
	mutation addShippingNoticeUploads($input: ShippingNoticeUploadInput!) {
		addShippingNoticeUploads(input: $input) {
			success
		}
	}
`;

export const REMOVE_SHIPPING_NOTICE_UPLOADS_MUTATION = gql`
	mutation deleteShippingNoticeUploads($input: ShippingNoticeUploadInput!) {
		deleteShippingNoticeUploads(input: $input) {
			success
		}
	}
`;
