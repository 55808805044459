import { DescriptionList } from '@shopify/polaris';
import { ReactNode } from 'react';

import { PendingText } from '../PendingText';
import styles from './CardDetails.module.css';

interface ListItem {
	label: string;
	content: string | ReactNode;
}

interface Props {
	loading?: boolean;
	primary: Array<ListItem>;
	secondary?: Array<ListItem>;
}

const mapItems = (items: Array<ListItem>, loading?: boolean) => {
	return items.map(({ label, content }) => {
		return { term: label + ':', description: <PendingText loading={loading} value={content} /> };
	});
};

export function CardDetails({ loading, primary, secondary }: Props) {
	return (
		<div className={styles.cardDetails}>
			<DescriptionList items={mapItems(primary, loading)} />
			{secondary ? <DescriptionList items={mapItems(secondary, loading)} /> : null}
		</div>
	);
}
