import { Button } from '@shopify/polaris';
import { Picking, Order } from '@sixriver/fulfillment-api-schema';

import { getPickingJobs } from 'helpers/order';
import * as routes from 'routes';

const getParams = (jobs?: Picking[]) => {
	const searchParams = new URLSearchParams();

	if (jobs) {
		searchParams.set('projectionIds', jobs.map((job) => job.id).join(' '));
	}

	return '?' + searchParams.toString();
};

export function getRelatedJobs(job?: Picking): Picking[] {
	const orders = (job?.servicing || []).filter(
		(servicing) => servicing.__typename === 'Order',
	) as Order[];
	return orders.reduce<Picking[]>(
		(jobs, order) => [
			...jobs,
			...getPickingJobs(order.servicedBy).filter(
				(servicedByJob) => !jobs.some((job) => job.id === servicedByJob.id),
			),
		],
		[],
	);
}

interface ButtonProps {
	relatedJobs: Picking[];
	label: string;
}

export function RelatedJobsButton({ relatedJobs, label }: ButtonProps) {
	return (
		<Button
			plain
			url={`${routes.outboundJobs()}/${getParams(relatedJobs)}`}
			disabled={relatedJobs.length <= 1}
		>
			{label}
		</Button>
	);
}
