import { Heading, Button, Stack } from '@shopify/polaris';
import { useEffect, useState } from 'react';

import { Chuck } from './Chuck.type';
import { ChuckCard } from './ChuckCard';
import styles from './FloorView.module.css';
import { useLocalization } from 'hooks/useLocalization';
import closeImg from 'images/close.svg';

interface Props {
	chucks: Chuck[];
	filtered?: boolean;
	selectedId?: string;
	onSelect(id: string): void;
}

/**
 * This component displays a list of Chucks alongside/above the map. Pass
 * a selectedId to force it open.
 */
export function ChuckList({ chucks, filtered, selectedId, onSelect }: Props) {
	const { messages } = useLocalization();
	const [visible, setVisible] = useState(false);

	const matchedChucks = chucks.filter((chuck) => {
		return chuck.matched !== false;
	});

	useEffect(() => {
		if (selectedId || filtered) {
			setVisible(true);
		}
	}, [selectedId, filtered]);

	if (visible) {
		return (
			<div className={styles.list}>
				<div className={styles.listHead}>
					<Stack distribution="equalSpacing" alignment="center">
						<Heading>{`${messages.chucks} (${matchedChucks.length})`}</Heading>
						<button className={styles.close} onClick={() => setVisible(false)}>
							<img src={closeImg} width="16" height="16" alt={messages.close} />
						</button>
					</Stack>
				</div>
				<div className={styles.listBody}>
					{matchedChucks.map((chuck) => {
						return (
							<ChuckCard
								key={chuck.id}
								chuck={chuck}
								selected={selectedId === chuck.id}
								onSelect={onSelect}
							/>
						);
					})}
				</div>
			</div>
		);
	}

	return (
		<Button primary onClick={() => setVisible(true)} id="listButton">
			{messages.showChuckList}
		</Button>
	);
}
