import { Component, PropsWithChildren } from 'react';

import { Error } from 'components/Error';

interface State {
	hasError: boolean;
}

// convert to a function component for translation purposes
export class ErrorBoundary extends Component<PropsWithChildren<{}>, State> {
	constructor(props: Readonly<PropsWithChildren<{}>>) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	render() {
		const { children } = this.props;

		return this.state.hasError ? (
			<Error heading={"There's a problem loading this page."} message={undefined} />
		) : (
			children
		);
	}
}
