import { TextStyle } from '@shopify/polaris';
import { BulkOrderStatus } from '@sixriver/fulfillment-api-schema';

import { useLocalization } from 'hooks/useLocalization';

interface Props {
	batchState?: BulkOrderStatus | string;
}

export function BatchState({ batchState }: Props) {
	const { messages } = useLocalization();

	let fill;

	switch (batchState) {
		case BulkOrderStatus.Open:
			fill = '#00A47C';
			batchState = messages.collecting;
			break;
		case BulkOrderStatus.Sealed:
			fill = '#E4E5E7';
			batchState = messages.closed;
			break;
		case BulkOrderStatus.Closed:
			fill = '#E4E5E7';
			batchState = messages.closed;
			break;
	}
	return (
		<span data-testid="batchStateIcon">
			<svg width="15" height="15" style={{ marginRight: '10' }}>
				<circle cx="10" cy="10" r="5" fill={fill} />
			</svg>
			<TextStyle>{batchState}</TextStyle>
		</span>
	);
}
