import { InventoryConflictReason } from '@sixriver/fulfillment-api-schema';

import { useLocalization } from 'hooks/useLocalization';

export function useLocationDiscrepancies() {
	const { messages } = useLocalization();

	const translatedLocationDiscrepancies: Record<InventoryConflictReason, string> = {
		Miscount: messages.discrepancyTypes.Miscount,
		NegativeQuantity: messages.discrepancyTypes.NegativeQuantity,
		Other: messages.discrepancyTypes.Other,
		PickShort: messages.discrepancyTypes.PickShort,
	};

	return translatedLocationDiscrepancies;
}
